import type { CamelCasedPropertiesDeep } from 'type-fest';

import type {
  OpportunityKeywordSerializer,
  OpportunityTypeSerializer,
  OpportunityPublicSerializer,
  SimpleShowcasingOpportunitySerializer,
  OpportunityPublicSerializerWithKeywords,
  DetailedShowcasingOutcomeSerializer,
  ShowcasingOutcomeSerializer,
  OpportunityAgeLevelSerializer,
  OpportunityApproximateReviewTimeSerializer,
  ShowcasingOpportunitySerializer,
  ShowcasingOpportunityPreviewSerializer,
} from './data/showcasing';

export const ShowCasingOutcomeStatusOptions = {
  PLANS_TO_SUBMIT: 'plans_to_submit',
  SUBMITTED: 'submitted',
  ACCEPTED: 'accepted',
  PUBLISHED: 'published',
} as const;

export type ShowcasingOutcomeStatuses =
  (typeof ShowCasingOutcomeStatusOptions)[keyof typeof ShowCasingOutcomeStatusOptions];

export type OpportunityKeyword = CamelCasedPropertiesDeep<OpportunityKeywordSerializer>;

export type OpportunityType = CamelCasedPropertiesDeep<OpportunityTypeSerializer>;

export type OpportunityAgeLevel = CamelCasedPropertiesDeep<OpportunityAgeLevelSerializer>;

export type OpportunityApproximateReviewTime =
  CamelCasedPropertiesDeep<OpportunityApproximateReviewTimeSerializer>;

export type OpportunityPublic = CamelCasedPropertiesDeep<OpportunityPublicSerializer>;

export type ShowcasingOpportunityPreview =
  CamelCasedPropertiesDeep<ShowcasingOpportunityPreviewSerializer>;

export type ShowcasingOpportunity = CamelCasedPropertiesDeep<ShowcasingOpportunitySerializer>;

export type OpportunityCardSideBoxProps = Pick<
  OpportunityPublic,
  | 'generalWebsite'
  | 'submissionDetails'
  | 'deadline'
  | 'deadlineAt'
  | 'submissionFee'
  | 'submissionFeeInCents'
  | 'originalResearch'
  | 'reviewPaper'
  | 'peerReviewed'
  | 'fastTrackReview'
  | 'approximateReviewTime'
>;

export type SimpleShowcasingOpportunity =
  CamelCasedPropertiesDeep<SimpleShowcasingOpportunitySerializer>;

export type OpportunityPublicWithKeywords =
  CamelCasedPropertiesDeep<OpportunityPublicSerializerWithKeywords>;

export type DetailedShowcasingOutcome =
  CamelCasedPropertiesDeep<DetailedShowcasingOutcomeSerializer>;

export type ShowcasingOutcome = CamelCasedPropertiesDeep<ShowcasingOutcomeSerializer>;

export interface ShowcasingSearchParams {
  query?: string;
  page?: number;
  page_size?: number;
  keyword?: string;
  type?: string;
}
