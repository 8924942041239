import { ApplicationSectionIds } from 'src/constants/application-sections';
import {
  headingProps,
  headingSpacer,
  illustrationSize,
  textFieldSpacer,
} from 'src/students/student-application/common-section-components';
import type { ApplicationComponentSection } from 'src/students/student-application/student-application-components';

export const OUTPUT_SECTION: ApplicationComponentSection = {
  id: ApplicationSectionIds.OUTPUT_SECTION_ID,
  elements: [
    {
      component: 'ThreeColumnLayout',
      props: {
        children: [
          [{ component: 'AdmissionAdvisorModalInfo' }],
          [
            {
              component: 'CenteredIllustration',
              props: {
                name: 'SpaceMoonLanding',
                size: illustrationSize,
              },
            },
            headingSpacer,
            {
              component: 'ComponentsHeading',
              props: {
                children: 'What is the expected output of your project?',
                ...headingProps,
              },
            },
            headingSpacer,
            {
              component: 'ComponentsText',
              props: {
                children: 'Choose as many as you like',
                fontWeight: 'semibold',
                size: 'medium',
                alignment: 'center',
              },
            },
            textFieldSpacer,
            {
              component: 'MultiToggleSelect',
              tag: 'application',
              props: {
                name: 'expectedOutput',
                options: [
                  { label: 'Research paper', value: 'outputResearchPaper' },
                  { label: 'GitHub Code base', value: 'outputGithubCodeBase' },
                  { label: 'Creative writing', value: 'outputCreativeWriting' },
                  { label: 'Computer Simulation', value: 'outputComputerSimulation' },
                  { label: 'Opinion Editorial', value: 'outputOpinionEditorial' },
                  { label: 'Infographic', value: 'outputInfographic' },
                  { label: 'Video or Mini Docu-series', value: 'outputVideoOrDocuSeries' },
                  { label: 'Podcast', value: 'outputPodcast' },
                  { label: 'Start an organization', value: 'outputOrganization' },
                  { label: 'Art work or Art portfolio', value: 'outputArtWorkOrPortfolio' },
                  { label: 'App/Game', value: 'outputAppGame' },
                  { label: "I'm unsure at this point", value: 'outputUnsure' },
                ],
              },
              validation: {
                atLeastOneOptionSelected: true,
              },
            },
            {
              component: 'Spacer',
              props: { size: 10 },
            },
            {
              component: 'Spacer',
              props: { size: 10 },
            },
            {
              component: 'ComponentsHeading',
              props: {
                children: 'How would you like to share your project outcome?',
                ...headingProps,
              },
            },
            headingSpacer,
            {
              component: 'ComponentsRadioButton',
              validation: {
                required: true,
              },
              tag: 'application',
              props: {
                name: 'expectedProjectOutcome',
                label: 'In a Journal',
                value: 'journal',
              },
            },
            {
              component: 'ComponentsRadioButton',
              validation: {
                required: true,
              },
              tag: 'application',
              props: {
                name: 'expectedProjectOutcome',
                label: 'Online (i.e. Medium, YouTube, etc.)',
                value: 'online',
              },
            },
            {
              component: 'ComponentsRadioButton',
              validation: {
                required: true,
              },
              tag: 'application',
              props: {
                name: 'expectedProjectOutcome',
                label: 'a Podcast (i.e. Spotify, Apple Podcasts, etc.)',
                value: 'podcast',
              },
            },
            {
              component: 'ComponentsRadioButton',
              validation: {
                required: true,
              },
              tag: 'application',
              props: {
                name: 'expectedProjectOutcome',
                label: 'at a Science Fair',
                value: 'science_fair',
              },
            },
            {
              component: 'ComponentsRadioButton',
              validation: {
                required: true,
              },
              tag: 'application',
              props: {
                name: 'expectedProjectOutcome',
                label: 'at a Conference',
                value: 'conference',
              },
            },
            {
              component: 'ComponentsRadioButton',
              validation: {
                required: true,
              },
              tag: 'application',
              props: {
                name: 'expectedProjectOutcome',
                label: 'Unsure at this point',
                value: 'unsure',
              },
            },
            {
              component: 'PSSInfoBox',
              tag: 'application',
              props: {
                topText: 'Great! We have something just for you:',
              },
              display: {
                $and: [
                  { $not: { field: 'expectedProjectOutcome', value: '' } },
                  { $not: { field: 'expectedProjectOutcome', value: null } },
                  { $not: { field: 'expectedProjectOutcome', value: 'unsure' } },
                ],
              },
            },
            {
              component: 'PSSInfoBox',
              tag: 'application',
              props: {
                topText: 'No problem! You might be interested in:',
              },
              display: {
                $and: [
                  { $not: { field: 'expectedProjectOutcome', value: '' } },
                  { $not: { field: 'expectedProjectOutcome', value: null } },
                  { $is: { field: 'expectedProjectOutcome', value: 'unsure' } },
                ],
              },
            },
          ],
        ],
      },
    },
  ],
};

export const OUTPUT_SECTION_PARTNER_PAYS_WORKFLOW: ApplicationComponentSection = {
  id: ApplicationSectionIds.OUTPUT_SECTION_ID,
  elements: [
    {
      component: 'ThreeColumnLayout',
      props: {
        children: [
          [{ component: 'AdmissionAdvisorModalInfo' }],
          [
            {
              component: 'CenteredIllustration',
              props: {
                name: 'SpaceMoonLanding',
                size: illustrationSize,
              },
            },
            headingSpacer,
            {
              component: 'ComponentsHeading',
              props: {
                children: 'What is the expected output of your project?',
                ...headingProps,
              },
            },
            headingSpacer,
            {
              component: 'ComponentsText',
              props: {
                children: 'Choose as many as you like',
                fontWeight: 'semibold',
                size: 'medium',
                alignment: 'center',
              },
            },
            textFieldSpacer,
            {
              component: 'MultiToggleSelect',
              tag: 'application',
              props: {
                name: 'expectedOutput',
                options: [
                  { label: 'Research paper', value: 'outputResearchPaper' },
                  { label: 'GitHub Code base', value: 'outputGithubCodeBase' },
                  { label: 'Creative writing', value: 'outputCreativeWriting' },
                  { label: 'Computer Simulation', value: 'outputComputerSimulation' },
                  { label: 'Opinion Editorial', value: 'outputOpinionEditorial' },
                  { label: 'Infographic', value: 'outputInfographic' },
                  { label: 'Video or Mini Docu-series', value: 'outputVideoOrDocuSeries' },
                  { label: 'Podcast', value: 'outputPodcast' },
                  { label: 'Start an organization', value: 'outputOrganization' },
                  { label: 'Art work or Art portfolio', value: 'outputArtWorkOrPortfolio' },
                  { label: 'App/Game', value: 'outputAppGame' },
                  { label: "I'm unsure at this point", value: 'outputUnsure' },
                ],
              },
              validation: {
                atLeastOneOptionSelected: true,
              },
            },
            {
              component: 'Spacer',
              props: { size: 10 },
            },
            {
              component: 'Spacer',
              props: { size: 10 },
            },
            {
              component: 'ComponentsHeading',
              props: {
                children: 'How would you like to share your project outcome?',
                ...headingProps,
              },
            },
            headingSpacer,
            {
              component: 'ComponentsRadioButton',
              validation: {
                required: true,
              },
              tag: 'application',
              props: {
                name: 'expectedProjectOutcome',
                label: 'In a Journal',
                value: 'journal',
              },
            },
            {
              component: 'ComponentsRadioButton',
              validation: {
                required: true,
              },
              tag: 'application',
              props: {
                name: 'expectedProjectOutcome',
                label: 'Online (i.e. Medium, YouTube, etc.)',
                value: 'online',
              },
            },
            {
              component: 'ComponentsRadioButton',
              validation: {
                required: true,
              },
              tag: 'application',
              props: {
                name: 'expectedProjectOutcome',
                label: 'a Podcast (i.e. Spotify, Apple Podcasts, etc.)',
                value: 'podcast',
              },
            },
            {
              component: 'ComponentsRadioButton',
              validation: {
                required: true,
              },
              tag: 'application',
              props: {
                name: 'expectedProjectOutcome',
                label: 'at a Science Fair',
                value: 'science_fair',
              },
            },
            {
              component: 'ComponentsRadioButton',
              validation: {
                required: true,
              },
              tag: 'application',
              props: {
                name: 'expectedProjectOutcome',
                label: 'at a Conference',
                value: 'conference',
              },
            },
            {
              component: 'ComponentsRadioButton',
              validation: {
                required: true,
              },
              tag: 'application',
              props: {
                name: 'expectedProjectOutcome',
                label: 'Unsure at this point',
                value: 'unsure',
              },
            },
          ],
        ],
      },
    },
  ],
};
