import { pluralize } from '@polygence/common/utils/pluralize';
import { useState } from 'react';
import { useInterval } from 'react-use';

import { dayjs } from 'src/utils/dayjsCustom';

const ONE_SECOND = 1000;
const ONE_HOUR = ONE_SECOND * 60 * 60;

interface PaymentIntentCountdownProps {
  expiresAt: string;
}

export const PaymentIntentCountdown = ({ expiresAt }: PaymentIntentCountdownProps) => {
  const [date, setDate] = useState(new Date());

  const expiresInSeconds = dayjs(expiresAt).diff(dayjs(date), 'seconds');
  const expiresInMinutes = Math.floor(expiresInSeconds / 60);
  const expiresInHours = Math.floor(expiresInMinutes / 60);
  const expiresInDays = Math.floor(expiresInHours / 24);
  const remainingHours = expiresInHours % 24;
  const remainingMinutes = expiresInMinutes % 60;
  const remainingSeconds = expiresInSeconds % 60;
  const lessThanADay = expiresInHours < 24;

  useInterval(() => setDate(new Date()), lessThanADay ? ONE_SECOND : ONE_HOUR);

  if (lessThanADay) {
    return (
      <>
        {remainingHours.toString().padStart(2, '0')}:{remainingMinutes.toString().padStart(2, '0')}:
        {remainingSeconds.toString().padStart(2, '0')}
      </>
    );
  }

  return (
    <>
      {expiresInDays} {pluralize('day', expiresInDays)}
      {remainingHours !== 0 && ` and ${remainingHours} ${pluralize('hour', remainingHours)}`}
    </>
  );
};
