import { getLandingUrl } from '@polygence/common';
import { Button, Heading, Text } from '@polygence/components';

import { PaymentIntentFooter } from 'src/payment/PaymentIntent/PaymentIntentFooter';
import { PaymentIntentPageLayout } from 'src/payment/PaymentIntent/PaymentIntentPageLayout';
import { PaymentPageTerms } from 'src/payment/PaymentPageTerms';

interface PaymentIntentCanceledProps {
  v3Enabled: boolean;
}

export const PaymentIntentCanceled = ({ v3Enabled }: PaymentIntentCanceledProps) => {
  return (
    <PaymentIntentPageLayout className="tw-pt-8">
      <div className="row tw-flex tw-items-center tw-justify-center tw-gap-5">
        <Heading as="h1" size="h3">
          This invoice is cancelled.
        </Heading>
        <Text size="medium" alignment="center" className="tw-text-gray-500">
          Don't worry! You can still browse our available programs and find which one suits you
          best!
        </Text>
        <div className="tw-flex tw-w-1/4 tw-justify-center">
          <Button variant="primary" href={getLandingUrl()}>
            <Text size="medium" fontWeight="bold">
              Explore Programs
            </Text>
          </Button>
        </div>
      </div>
      {v3Enabled ? <PaymentIntentFooter /> : <PaymentPageTerms />}
    </PaymentIntentPageLayout>
  );
};
