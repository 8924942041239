import { CartDiscount } from '@polygence/common/types/payment';
import { cn } from '@polygence/components';

import { PaymentIntentCountdown } from 'src/payment/PaymentIntent/PaymentIntentCountdown';
import { dayjs } from 'src/utils/dayjsCustom';

interface PaymentIntentExpiringDiscountsProps {
  discounts: CartDiscount[];
}

export const PaymentIntentExpiringDiscounts = ({
  discounts,
}: PaymentIntentExpiringDiscountsProps) => {
  if (!discounts.length) {
    return null;
  }

  const highestAmountDiscount =
    discounts.length === 1
      ? discounts[0]
      : discounts.sort((a, b) => b.appliedAmountInCents - a.appliedAmountInCents)[0];

  if (
    !highestAmountDiscount ||
    !highestAmountDiscount.expiresAt ||
    dayjs(highestAmountDiscount.expiresAt).isBefore(dayjs())
  ) {
    return null;
  }

  return (
    <div className="tw-relative tw-rounded-2xl tw-bg-gray-700 tw-px-5 tw-py-4 tw-text-gray-100">
      <div className="tw-font-bold">{highestAmountDiscount.displayName}</div>
      <div className="tw-mr-20">
        expires in{' '}
        <span className="tw-text-lg tw-font-bold tw-tabular-nums">
          <PaymentIntentCountdown expiresAt={highestAmountDiscount.expiresAt} />
        </span>
      </div>
      <div
        className={cn(
          'tw-absolute -tw-top-2 tw-right-4 tw-h-24 tw-w-24',
          'tw-flex tw-items-center tw-justify-center',
          'tw-rounded-full tw-border-2 tw-border-white tw-ring-2 tw-ring-red-500 tw-ring-offset-2',
          'tw-bg-red-500 tw-text-gray-100',
        )}
      >
        <p className="tw-m-0 tw-text-3xl">
          -${Math.round(highestAmountDiscount.appliedAmountInCents / 100)}
        </p>
      </div>
    </div>
  );
};
