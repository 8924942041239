import type { DetailedShowcasingOutcome } from '@polygence/common/types/showcasing';
import { ShowCasingOutcomeStatusOptions } from '@polygence/common/types/showcasing';
import { Button, InputField, Text, Icon } from '@polygence/components';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { DatePickerWithLabel } from 'src/components/common/DatePickerWithLabel';
import InputWrapper from 'src/components/mentor/InputWrapper';
import ShowcasingOpportunitiesSelector from 'src/components/overviews/student/editable/ShowcasingOpportunitiesSelector';
import {
  DEFAULT_MIN_DATE,
  DEFAULT_MAX_DATE,
} from 'src/components/overviews/student/editable/ShowcasingOutcomeModal';
import { ShowcasingStatusSelector } from 'src/components/overviews/student/editable/ShowcasingStatusSelector';

type ShowcasingOutcome = Pick<
  DetailedShowcasingOutcome,
  'outlet' | 'publicationDate' | 'url' | 'status'
> & {
  _id: string;
};

const emptyShowcasingOutcome = {
  outlet: null,
  publicationDate: null,
  url: '',
  status: ShowCasingOutcomeStatusOptions.PLANS_TO_SUBMIT,
} as const;

export const ShowcasingOutcomeInput = ({
  onChange,
  name,
  defaultValue = [],
}: {
  onChange: ({ target }: { target: { name: string; value: ShowcasingOutcome[] } }) => null;
  name: string;
  defaultValue?: ShowcasingOutcome[];
}) => {
  const [showcasingOutcomes, setShowcasingOutcomes] = useState<ShowcasingOutcome[]>(
    defaultValue.length >= 1 ? defaultValue : [{ _id: uuidv4(), ...emptyShowcasingOutcome }],
  );

  const handleChange = (showcasingOutcomes: ShowcasingOutcome[]) => {
    setShowcasingOutcomes(showcasingOutcomes);
    onChange({ target: { name, value: showcasingOutcomes } });
  };

  const updateShowcasingOutcome = (idx: number, showcasingOutcome: Partial<ShowcasingOutcome>) => {
    const newList = [...showcasingOutcomes];
    const updatable = newList[idx];
    if (updatable) {
      // eslint-disable-next-line fp/no-mutation
      newList[idx] = { ...updatable, ...showcasingOutcome };
      handleChange(newList);
    }
  };

  const removeShowcasingOutcome = (idx: number) => {
    const newList = [...showcasingOutcomes];
    const updated = newList.filter((_, index) => index !== idx);
    handleChange(updated);
  };

  const addNewShowcasingOutcome = () => {
    handleChange([...showcasingOutcomes, { _id: uuidv4(), ...emptyShowcasingOutcome }]);
  };

  return (
    <>
      {showcasingOutcomes.map((showcasingOutcome, idx) => (
        <div key={showcasingOutcome._id}>
          <Text size="medium" fontWeight="bold" className="mt-3 required">
            Showcasing outcome {idx + 1}
          </Text>
          <InputWrapper value={showcasingOutcome.status ?? ''} required>
            <ShowcasingStatusSelector
              label="Status"
              onSelect={(status) => updateShowcasingOutcome(idx, { status })}
              defaultOption={showcasingOutcome.status}
            />
          </InputWrapper>
          {showcasingOutcome.status === 'published' && (
            <InputWrapper value={showcasingOutcome.publicationDate ?? ''} required>
              <DatePickerWithLabel
                id="showcasing_outcome_publication_date"
                label="Publication date"
                name="showcasing_outcome_publication_date"
                value={showcasingOutcome.publicationDate}
                minDate={DEFAULT_MIN_DATE}
                maxDate={DEFAULT_MAX_DATE}
                onChange={({ target }) => {
                  updateShowcasingOutcome(idx, {
                    publicationDate: (target as { value: string }).value,
                  });
                }}
                showMonthDropdown
              />
            </InputWrapper>
          )}
          <InputWrapper value={showcasingOutcome.outlet?.name ?? ''} required>
            <ShowcasingOpportunitiesSelector
              onSelect={(outlet) => updateShowcasingOutcome(idx, { outlet })}
              defaultOption={showcasingOutcome.outlet}
            />
          </InputWrapper>
          <InputField
            label="URL"
            type="text"
            name="showcasing_outcome_url"
            onChange={({ target }) => updateShowcasingOutcome(idx, { url: target.value })}
            value={showcasingOutcome.url}
            floating={false}
          />
          {showcasingOutcomes.length > 1 && (
            <Button size="sm" variant="danger" onClick={() => removeShowcasingOutcome(idx)}>
              Remove
            </Button>
          )}
        </div>
      ))}
      <Button
        className="mt-5"
        size="sm"
        startIcon={<Icon id="plus" />}
        onClick={addNewShowcasingOutcome}
      >
        Add new outcome
      </Button>
    </>
  );
};
