import type { CartDiscount } from '@polygence/common/types/payment';
import { cn } from '@polygence/components';

import { PaymentIntentDiscount } from 'src/payment/PaymentIntent/PaymentIntentDiscount';
import {
  getDiscountAmountInDollars,
  getDiscountDisplayName,
  mergeDiscounts,
} from 'src/payment/utils';

interface PaymentIntentDiscountListProps {
  discounts: CartDiscount[];
  className?: string;
}

export const PaymentIntentDiscountList = ({
  discounts,
  className,
}: PaymentIntentDiscountListProps) => {
  if (!discounts.length) {
    return null;
  }
  if (!discounts.some((discount) => discount.appliedAmountInCents > 0)) {
    return null;
  }

  return (
    <div data-cy="discounts" className={cn('tw-flex tw-flex-col tw-gap-1', className)}>
      {mergeDiscounts(discounts).map((discount) => (
        <PaymentIntentDiscount
          key={`${discount.displayName}_${discount.quantity}`}
          name={getDiscountDisplayName(discount)}
          amount={getDiscountAmountInDollars(discount)}
        />
      ))}
    </div>
  );
};
