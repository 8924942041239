import type { Meeting, Session } from '../types/hermes';
import { isMeetingJoinableByTime, isProjectMeetingJoinable } from '../utils/zoomJoinAvailability';

import useSelectedProject from './selectors/useSelectedProject';
import useSelectedWorkspace from './selectors/useSelectedWorkspace';

const isNonProjectMeetingJoinable = (meeting: Meeting) => {
  return isMeetingJoinableByTime(meeting);
};

export const useMeetingJoinable = (
  meeting: Meeting | undefined,
  session: Session | undefined | null,
) => {
  const workspace = useSelectedWorkspace();
  const project = useSelectedProject();

  if (!workspace || workspace.disabled || workspace.zoomDisabled) {
    return false;
  }

  if (!workspace.projectId && meeting) {
    return isNonProjectMeetingJoinable(meeting);
  }

  return isProjectMeetingJoinable(project, session, meeting) && isMeetingJoinableByTime(meeting);
};
