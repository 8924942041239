import { createSlice } from '@reduxjs/toolkit';

const channelSlice = createSlice({
  name: 'channel',
  initialState: {
    value: null,
    label: null,
    partnershipArrangement: null,
  },
  reducers: {
    updateChannel: (state, { payload }) => {
      return { ...state, ...payload };
    },
  },
});

const { actions, reducer } = channelSlice;

export const channelActions = actions;
export const channelReducer = reducer;
