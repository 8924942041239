import { Card, Heading } from '@polygence/components';
import classnames from 'classnames';
import type { ReactElement } from 'react';
import { Image } from 'react-bootstrap';

import { GuidebookLink } from 'src/components/Guidebook/GuidebookLink';
import styles from 'src/components/Guidebook/guidebook.module.sass';

interface GuidebookCardProps {
  cardImage: string;
  url: string;
  title: string;
}

const GuidebookCard = ({ title, cardImage, url }: GuidebookCardProps): ReactElement => {
  return (
    <Card
      as={GuidebookLink}
      className={classnames('px-3 px-sm-7 pb-3 pb-sm-5', styles['card'])}
      href={url}
    >
      <Image
        alt={title}
        src={cardImage}
        className={classnames('mb-1 mb-sm-10', styles['cardImage'])}
      />
      <Heading size="h4" className="d-sm-block mb-0" alignment="left">
        {title}
      </Heading>
    </Card>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default GuidebookCard;
