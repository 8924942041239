import { Button, Icon } from '@polygence/components';
import { Link } from 'react-router-dom';

interface BackButtonProps {
  href: string;
  text: string;
  isExternal?: boolean;
  className?: string;
}

const BackButton = ({ href, text, isExternal = false, className = '' }: BackButtonProps) => {
  if (isExternal) {
    return (
      <Button
        variant="link"
        href={href}
        className={className}
        startIcon={<Icon id="chevron-left" />}
      >
        {text}
      </Button>
    );
  }
  return (
    <Button
      // @ts-expect-error using as=Link is fine
      as={Link}
      variant="link"
      to={href}
      className={className}
      startIcon={<Icon id="chevron-left" />}
    >
      {text}
    </Button>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default BackButton;
