import { Invitation } from '@polygence/common';
import { axiosBaseQuery } from '@polygence/common/api/fetch';
import { UUID } from '@polygence/common/types/common';
import { PublicInvitationPrompt } from '@polygence/common/types/worldstrides';
import { createApi } from '@reduxjs/toolkit/query/react';

export const worldstridesApi = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: 'worldstrides',
  tagTypes: [],
  endpoints: (build) => {
    return {
      invitationPrompt: build.query<PublicInvitationPrompt, { uuid: UUID }>({
        query: ({ uuid }) => ({
          url: `/worldstrides/invitation-prompt/${uuid}/`,
          method: 'GET',
        }),
      }),
      createInvitation: build.mutation<
        { invitationUrl: string },
        { uuid: UUID; payload: Pick<Invitation, 'email'> }
      >({
        query: ({ uuid, payload }) => {
          return {
            url: `/worldstrides/invitation/${uuid}/`,
            method: 'POST',
            data: payload,
          };
        },
      }),
    };
  },
});

export const { useInvitationPromptQuery, useCreateInvitationMutation } = worldstridesApi;
