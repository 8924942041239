import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useGoogleTagManager } from 'src/useGoogleTagManager';
import { getTracker } from 'src/utils/tracking/factory';

export const Tracker = () => {
  const location = useLocation();

  useGoogleTagManager();

  useEffect(() => {
    getTracker().page(location?.pathname);
  }, [location]);

  useEffect(() => {
    const parentVisit = location.pathname.includes('parent-info');
    const parentVisitMarker = localStorage.getItem('parent_visit');

    if (parentVisit && !parentVisitMarker) {
      localStorage.setItem('parent_visit', parentVisit ? 'true' : 'false');
    }
  }, [location.pathname]);

  return null;
};
