import type { Feedback, Meeting, Project, Session } from '../../types/hermes';
import type { UserState } from '../../types/user';
import type { AtLeast } from '../../types/utils';
import { dayjs } from '../dayjsCustom';
import { permissions } from '../permissions';

const hasPreviousSessionSummary = (sessionNumber: number, sessions: Session[] = []): boolean => {
  // eslint-disable-next-line eqeqeq
  if (sessionNumber == 0) {
    return true;
  }

  const previousSession = sessions.find((session) => session.sessionNumber === sessionNumber - 1);
  if (!previousSession) {
    return true;
  }

  if (previousSession.countAs === 'late_cancelled') {
    return hasPreviousSessionSummary(sessionNumber - 1, sessions);
  }

  return !!previousSession.summary;
};

export const getActionForSession = (
  { sessionNumber, summary, countAs, proposing }: AtLeast<Session, 'sessionNumber'>,
  feedback: Feedback | undefined,
  meeting: Meeting | undefined,
  userPermissions: string[],
  isWorkspaceDisabled: boolean,
  project: Project | undefined,
  currentUser: UserState,
  // eslint-disable-next-line sonarjs/cognitive-complexity -- autodisabled
) => {
  if (isWorkspaceDisabled) {
    return 'NoActionState';
  }

  const deadlineInFuture = project && dayjs(project.expectedEndAt).isAfter(dayjs());
  const previousSessionHasSummary = hasPreviousSessionSummary(sessionNumber, project?.sessions);

  if (countAs === 'late_cancelled') {
    return 'LateCancelledState';
  }

  if (!meeting && !summary) {
    if (!deadlineInFuture) {
      return 'DisabledScheduleState';
    }

    if (
      permissions.canSchedule(userPermissions) ||
      permissions.canScheduleLimited(userPermissions)
    ) {
      return 'ScheduleClickableState';
    }

    if (permissions.canPropose(userPermissions)) {
      return 'ProposeClickableState';
    }

    return 'NoActionState';
  }

  if (
    proposing &&
    !summary &&
    (permissions.canSchedule(userPermissions) || permissions.canScheduleLimited(userPermissions))
  ) {
    return deadlineInFuture ? 'ConfirmProposalClickableState' : 'DisabledScheduleState';
  }

  if (
    proposing &&
    !summary &&
    !(permissions.canSchedule(userPermissions) || permissions.canScheduleLimited(userPermissions))
  ) {
    return 'WaitingForResponseState';
  }

  const futureMeeting = meeting && dayjs(meeting.scheduledAt).isAfter(dayjs());

  if (deadlineInFuture && futureMeeting) {
    return 'OfficiallyScheduledState';
  }

  if (
    meeting &&
    !summary &&
    previousSessionHasSummary &&
    permissions.canSubmitSummary(userPermissions)
  ) {
    return 'SubmitSummaryClickableState';
  }

  if (
    meeting &&
    !summary &&
    !previousSessionHasSummary &&
    permissions.canSubmitSummary(userPermissions)
  ) {
    return 'DisabledSubmitSummaryState';
  }

  if (meeting && countAs && countAs === 'no_show') {
    return 'NoShowState';
  }

  if (meeting && !feedback && !proposing && permissions.canStudentFeedback(userPermissions)) {
    return 'SubmitFeedbackClickableState';
  }

  if (meeting && permissions.canViewMeetingRecording(userPermissions, meeting)) {
    if (currentUser.userType === 'mentor' && currentUser.teacherFeedbackGroup === 4) {
      return 'MeetingInsightState';
    }
    return 'MeetingVideoAvailableState';
  }

  return 'DoneState';
};
