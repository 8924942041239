import type { UUID } from '@polygence/common/types/common';
import { Heading, Text, TileSelect } from '@polygence/components';
import classNames from 'classnames';

import { Loader } from 'src/components/Loader';
import styles from 'src/components/product/ProductSelector.module.sass';
import { filterProducts, formatPrice } from 'src/components/product/utils';
import { ApplicationFieldTypes } from 'src/constants/application-field-types';
import { PaymentIntentAmountOrPlaceholder } from 'src/payment/PaymentIntent/PaymentIntentAmountOrPlaceholder';
import {
  useAddProductToCartAdminMutation,
  useGetCartAdminQuery,
  useRemoveProductFromCartAdminMutation,
} from 'src/reducers/adminReducers';
import { useProductsQuery } from 'src/reducers/productReducer';

interface CartProductSelectorAdminProps {
  cartUuid: UUID;
  name?: string;
  disabled?: boolean;
  displayOptions?: {
    displayPitchable?: boolean;
    wrapper?: 'gridWrapper' | 'tileWrapper';
    condensed?: boolean;
  };
  onChange?: (event: { target: { name: string; value: number[] } }, tag?: string) => void;
}

export const CartProductSelectorAdmin = ({
  cartUuid,
  name: productSelectorName,
  disabled,
  displayOptions,
  onChange,
}: CartProductSelectorAdminProps) => {
  const { data: products } = useProductsQuery();
  const { data: cart } = useGetCartAdminQuery(cartUuid);
  const [addProductToCart, addProductToCartResult] = useAddProductToCartAdminMutation();
  const [removeProductFromCart, removeProductFromCartResult] =
    useRemoveProductFromCartAdminMutation();

  if (!products || !cart) {
    return <Loader />;
  }

  const isUpdating = addProductToCartResult.isLoading || removeProductFromCartResult.isLoading;
  const selectedProductIds = cart.cartItems.map((cartItem) => cartItem.product.id);
  const filteredProducts = filterProducts(products, {
    displayProductIds: undefined,
    displayPitchable: displayOptions?.displayPitchable ?? false,
  });

  return (
    <>
      <div
        className={classNames(styles[displayOptions?.wrapper ?? 'gridWrapper'], {
          [styles['condensed'] ?? '']: displayOptions?.condensed,
        })}
      >
        {filteredProducts?.map(({ id, name, totalPriceInCents, deprecated }) => {
          const isPitchableForPartnerStudent =
            displayOptions?.displayPitchable && cart.partnerPitchableProductIds.length > 0
              ? cart.partnerPitchableProductIds.includes(id)
              : true;
          if ((deprecated || !onChange) && !selectedProductIds.includes(id)) {
            return null;
          }

          const cartItem = cart.cartItems.find((cartItem) => cartItem.product.id === id);
          const productPriceFromCart = cartItem?.displayAmountInCents;

          const isRemovable =
            !cartItem || cartItem.isRemovable || !cartItem.product.isRemovableByUser;

          const priceInCents = productPriceFromCart ?? totalPriceInCents;
          const isChecked = cart.cartItems.some((cartItem) => {
            return cartItem.product.id === id;
          });

          return (
            <TileSelect
              key={`${id}-cart`}
              type="checkbox"
              value={`${id}`}
              disabled={
                disabled ||
                !onChange ||
                isUpdating ||
                deprecated ||
                !isRemovable ||
                !isPitchableForPartnerStudent
              }
              onChange={({ target: { checked } }) => {
                const mutation = checked ? addProductToCart : removeProductFromCart;
                mutation({ uuid: cartUuid, productId: id })
                  .then((response) => {
                    if (!('error' in response) && onChange && productSelectorName) {
                      onChange(
                        {
                          target: {
                            name: productSelectorName,
                            value: response.data.cart.cartItems.map(
                              (cartItem) => cartItem.product.id,
                            ),
                          },
                        },
                        ApplicationFieldTypes.APPLICATION,
                      );
                    }
                  })
                  .catch(console.error);
              }}
              checked={isChecked}
            >
              <p className="d-flex flex-column">
                {name}
                <span>{formatPrice(priceInCents / 100)}</span>
              </p>
            </TileSelect>
          );
        })}
      </div>
      {cart.discounts.length > 0 && (
        <div className="w-50">
          <Heading size="h5" as="h2" alignment="left" className="mb-6">
            Discounts
          </Heading>
          <ul>
            {cart.discounts.map((discount) => {
              return (
                <li key={discount.displayName}>
                  <div className="d-flex">
                    <Text size="medium" alignment="left" className="d-inline" fontWeight="bold">
                      {discount.displayName}
                    </Text>
                    <Text size="medium" className="mx-3">
                      {' '}
                      -{' '}
                    </Text>
                    <PaymentIntentAmountOrPlaceholder
                      amount={discount.appliedAmountInCents / 100}
                    />
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      )}
      <div>
        <Text alignment="center" size="medium">
          Total: {formatPrice(cart.totalAmountInCents / 100)}
        </Text>
      </div>
    </>
  );
};
