import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import classNames from 'classnames';
import Loader from 'react-loader-spinner';

export const SpinningWheel = ({
  color,
  height,
  width,
  className,
}: {
  color?: string;
  height?: number;
  width?: number;
  className?: string;
}) => {
  return (
    <span className={classNames('text-center', className)}>
      <Loader
        type="Circles"
        color={color ? color : '#00BFFF'}
        height={height ? height : 24}
        width={width ? width : 80}
      />
    </span>
  );
};
