import type {
  MentorAvailabilityList,
  MentorAvailabilityRetrieveUpdateDestroy,
  MentorProfileId,
} from '@polygence/common';
import { useUpdateMentorAvailabilityMutation } from '@polygence/common/api/mentor-availability';
import { Checkbox, Heading, Spacer, Text } from '@polygence/components';
import { toast } from 'react-toastify';

import NumberSelector from 'src/components/CTA/sessionForm/NumberSelector';
import DateWrapper, { DateFormat } from 'src/components/aux/dateWrapper';
import { DatePickerWithLabel } from 'src/components/common/DatePickerWithLabel';

const MAX_CAPACITY = 5;

interface MentorAvailabilitySetupProps {
  mentorProfileId: MentorProfileId;
  mentorAvailability: MentorAvailabilityList;
}

export const MentorAvailabilitySetup = ({
  mentorProfileId,
  mentorAvailability,
}: MentorAvailabilitySetupProps) => {
  const [updateMentorAvailability, { isLoading }] = useUpdateMentorAvailabilityMutation();

  const changeMentorAvailability = (change: Partial<MentorAvailabilityRetrieveUpdateDestroy>) => {
    updateMentorAvailability({
      mentorProfileId: mentorProfileId,
      mentorAvailabilityId: mentorAvailability.id,
      payload: change,
    })
      .unwrap()
      .catch(() => toast.error('Unable to update availability!'));
  };

  return (
    <div>
      <Heading size="h4" alignment="left" className="tw-mb-3">
        Availability to mentor
      </Heading>
      <Checkbox
        id="available"
        name="available"
        label="I can start mentoring immediately"
        checked={mentorAvailability.available}
        onChange={() => changeMentorAvailability({ available: !mentorAvailability.available })}
        disabled={isLoading}
      />
      {!mentorAvailability.available && (
        <>
          <Spacer size={4} />
          <Text size="medium" fontWeight="normal">
            When will you have more bandwidth to mentor?
          </Text>
          <DatePickerWithLabel
            id="unavailable_until"
            name="unavailable_until"
            placeholder="Please select a date"
            dateFormat={DateFormat.FORMAT_14}
            value={mentorAvailability.unavailableUntil}
            disabled={isLoading}
            onChange={({ target: { value } }) =>
              changeMentorAvailability({
                unavailableUntil: value ? new DateWrapper(value).format(DateFormat.FORMAT_3) : null,
              })
            }
          />
        </>
      )}
      {mentorAvailability.available && (
        <>
          <Spacer size={4} />
          <Text size="medium" fontWeight="normal">
            How many students are you willing to mentor at the same time?
          </Text>
          <NumberSelector
            id="capacity"
            name="capacity"
            numberCount={MAX_CAPACITY}
            defaultValue={mentorAvailability.capacity}
            className="justify-content-start"
            onChange={(capacity: number) => changeMentorAvailability({ capacity })}
            disabled={isLoading}
          />
        </>
      )}
    </div>
  );
};
