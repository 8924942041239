import type { Nullable } from '@polygence/common/types/utils';
import { InputField } from '@polygence/components';
import classNames from 'classnames';
import { useState, forwardRef } from 'react';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import 'src/components/common/datePickerWithLabel.sass';
import { getLocalDateFormatWithTime } from 'src/components/aux/dateStamp';
import DateWrapper, { DateFormat } from 'src/components/aux/dateWrapper';

const INVALID_DATE = 'Invalid Date';

interface CustomInputProps {
  label: string;
  disabled: boolean;
  name: string;
  value?: string;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
}

const CustomInput = forwardRef<HTMLInputElement, CustomInputProps>(
  ({ label, value, onClick, disabled, name }, ref) => {
    return (
      <InputField
        onClick={onClick}
        ref={ref}
        label={label}
        value={value}
        disabled={disabled}
        name={`${name}Input`}
      />
    );
  },
);

export interface DatePickerChangeTarget {
  target: {
    name: string;
    value: Nullable<string>;
  };
}

interface DatePickerWithLabelProps {
  id: string;
  className?: string;
  name: string;
  onChange: (e: DatePickerChangeTarget) => void;
  label?: string;
  value?: Nullable<string>;
  dateFormat?: string;
  localeTime?: boolean;
  minDate?: Nullable<Date>;
  maxDate?: Date;
  required?: boolean;
  placeholder?: string;
  disabled?: boolean;
  labelClassName?: string;
  inputClassName?: string;
  showYearDropdown?: boolean;
  showMonthDropdown?: boolean;
  showTimeSelect?: boolean;
  showYearPicker?: boolean;
  showMonthYearPicker?: boolean;
  shouldSendDateOnly?: boolean;
  onError?: string[];
  customInput?: boolean;
}

export const DatePickerWithLabel = ({
  id,
  name,
  label,
  className = '',
  showYearDropdown = true,
  showMonthDropdown = false,
  showYearPicker = false,
  showMonthYearPicker = false,
  shouldSendDateOnly = false,
  minDate = new Date(),
  maxDate,
  placeholder = 'Please select a date',
  disabled = false,
  labelClassName,
  inputClassName,
  value,
  onChange: handleChange,
  showTimeSelect,
  localeTime,
  dateFormat,
  required = false,
  onError = [],
  customInput = false,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
DatePickerWithLabelProps) => {
  const format = localeTime ? getLocalDateFormatWithTime() : dateFormat;
  const [valueInUTC, setValueInUTC] = useState(
    value && value !== INVALID_DATE && DateWrapper.utc(value).format() !== INVALID_DATE
      ? DateWrapper.utc(value).format()
      : null,
  );
  const isValid = onError.length === 0 || (onError.length === 1 && onError[0] === '');
  const formClassNames = isValid ? 'form-control' : 'form-control is-invalid';
  const isYearSelector = dateFormat === 'YYYY';

  const handleDateChange = (date: Date | null) => {
    const changedDate = date;
    if (changedDate && isYearSelector) {
      changedDate.setMonth(6);
    }
    const newValue = changedDate ? DateWrapper.utc(changedDate).format() : null;
    if (newValue === INVALID_DATE) {
      setValueInUTC(null);
    } else {
      setValueInUTC(newValue);
    }

    if (shouldSendDateOnly) {
      handleChange({
        target: {
          name,
          value: changedDate ? new DateWrapper(changedDate).format(DateFormat.FORMAT_3) : null,
        },
      });

      return;
    }

    handleChange({
      target: {
        name,
        value: newValue === INVALID_DATE ? null : newValue,
      },
    });
  };

  return (
    <div className={`my-5 ${className}`}>
      {!customInput && label && (
        <label className={classNames(labelClassName || 'mb-0 w-100', { required })} htmlFor={name}>
          {label}
        </label>
      )}
      <DatePicker
        id={id}
        name={valueInUTC ? '' : name}
        showTimeSelect={showTimeSelect}
        selected={value && new DateWrapper(value).isValid() ? new Date(value) : null}
        className={inputClassName || formClassNames}
        disabled={disabled}
        onChange={handleDateChange}
        showYearDropdown={showYearDropdown}
        showMonthDropdown={showMonthDropdown}
        dropdownMode="select"
        showYearPicker={showYearPicker}
        showMonthYearPicker={showMonthYearPicker}
        minDate={minDate}
        maxDate={maxDate}
        placeholderText={placeholder}
        dateFormat={format?.replaceAll('Y', 'y')}
        required={required}
        customInput={
          customInput ? (
            <CustomInput label={label || ''} disabled={disabled} name={name} />
          ) : undefined
        }
      />
      {/* A hidden input is used because FormData uses the internal state of the datepicker */}
      {valueInUTC && (
        <input
          type="text"
          name={name}
          value={valueInUTC}
          onChange={() => {
            return null;
          }}
          hidden
        />
      )}
      {!isValid && <span className="text-danger text-small">This field is required.</span>}
    </div>
  );
};
