import { commonThunks } from '@polygence/common';
import { Button, Heading } from '@polygence/components';
import { useState } from 'react';

import VideoAsk from 'src/components/common/VideoAsk/VideoAsk';
import { VideoAskB } from 'src/components/common/VideoAsk/VideoAskB';
import { useAppDispatch } from 'src/store';

interface StudentVideoInterviewProps {
  onSubmit: () => void;
  defaultSubmitted: boolean;
  options: {
    videoAskBaseSrc: string;
  };
  profileId: number;
  applicationId: number;
  applicationRevampEnabled: boolean;
}

export const StudentVideoInterview = ({
  onSubmit,
  defaultSubmitted,
  options: { videoAskBaseSrc },
  profileId,
  applicationId,
  applicationRevampEnabled,
}: StudentVideoInterviewProps) => {
  const [isSubmitted, setIsSubmitted] = useState(defaultSubmitted);
  const src = `${videoAskBaseSrc}?application_id=${applicationId}&profile_id=${profileId}`;
  const dispatch = useAppDispatch();
  const handleSubmit = () => {
    onSubmit();
    void dispatch(
      commonThunks.applicationThunks.updateApplication({
        profileId: profileId,
        applicationId: applicationId,
      }),
    );
  };
  const initRecord = () => {
    setIsSubmitted(false);
  };

  if (isSubmitted) {
    return (
      <div>
        <Heading
          className="mb-10"
          size={{
            base: 'h4',
            lg: 'h3',
          }}
          as="h1"
        >
          You have already submitted the video interview.
        </Heading>
        <div className="d-flex justify-content-center">
          <Button onClick={initRecord} variant="secondary">
            I would like to answer the video interview questions again.
          </Button>
        </div>
      </div>
    );
  }

  return applicationRevampEnabled ? (
    <VideoAskB
      onSubmit={handleSubmit}
      src={src}
      title="Student Video Interview"
      profileId={profileId}
      applicationId={applicationId}
    />
  ) : (
    <VideoAsk onSubmit={handleSubmit} src={src} title="Student Video Interview" />
  );
};
