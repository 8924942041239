import { Button } from '@polygence/components';
import * as Sentry from '@sentry/react';
import { ReactNode } from 'react';
import { Container, Col, Row } from 'react-bootstrap';

const FallbackComponent = ({ resetError }: { resetError: () => void }) => {
  return (
    <Container
      style={{ height: '100vh' }}
      className="d-flex justify-content-center align-items-center"
    >
      <Row>
        <Col xs={12}>
          <span className="fs-2">🙀</span>
          <h1>An unexpected error has occurred</h1>
        </Col>
        <Col xs={12}>
          <Button variant="primary" onClick={resetError}>
            Click here to reload the page
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export const MainErrorBoundary = ({ children }: { children: ReactNode }) => {
  return (
    <Sentry.ErrorBoundary
      fallback={({ resetError }) => {
        return <FallbackComponent resetError={resetError} />;
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};
