import { Nullable } from '@polygence/common';
import classnames from 'classnames';
import { useState, useEffect } from 'react';

import hoursAndMinutes from 'src/components/aux/hoursAndMinutes';
import InputWrapper from 'src/components/mentor/InputWrapper';

interface HoursAndMinutesProps {
  label: string;
  small_font: string;
  name: string;
  onChange: ({ target: { name, value } }: { target: { name: string; value: number } }) => void;
  required: boolean;
  value: Nullable<number>;
}

interface TimeValues {
  hours?: number;
  minutes?: number;
}

export const HoursAndMinutes = ({
  label,
  small_font: smallFont,
  name,
  onChange,
  required,
  value,
}: HoursAndMinutesProps) => {
  const [timeValues, setTimeValues] = useState<TimeValues>({});
  useEffect(() => {
    if (value == null) {
      return;
    }
    const [hours, minutes] = hoursAndMinutes(Number(value));
    setTimeValues({ hours, minutes });
  }, [value]);

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };

  const handleChange = (target: 'hours' | 'minutes') => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      setTimeValues((val) => {
        return { ...val, [target]: Number(e.target.value) };
      });
    };
  };

  const handleBlur = () => {
    const hours = timeValues.hours ?? 0;
    const minutes = timeValues.minutes ?? 0;
    onChange({
      target: {
        name,
        value: Number(hours) * 60 + Number(minutes),
      },
    });
  };

  return (
    <InputWrapper required={required} value={value} name={name}>
      <span className={classnames({ required })}>{label}</span>
      <div className="small text-muted">{smallFont}</div>
      <div className="d-flex">
        <div className="input-group me-3">
          <input
            type="number"
            className="form-control"
            id={`${name}_hours`}
            value={timeValues.hours ?? ''}
            onChange={handleChange('hours')}
            onBlur={handleBlur}
            onFocus={handleFocus}
            min="0"
            step="1"
          />
          <span className="input-group-text">hours</span>
        </div>
        <div className="input-group">
          <input
            type="number"
            className="form-control"
            id={`${name}_minutes`}
            value={timeValues.minutes ?? ''}
            onChange={handleChange('minutes')}
            onBlur={handleBlur}
            onFocus={handleFocus}
            min="0"
            step="1"
          />
          <span className="input-group-text">minutes</span>
        </div>
      </div>
    </InputWrapper>
  );
};
