import * as marketplaceApi from '@polygence/common/api/marketplace';
import type { SymposiumPresenterStatistics } from '@polygence/common/types/marketplace';
import { Heading, Icon, IconVariable, Text } from '@polygence/components';
import classnames from 'classnames';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import styles from 'src/components/Tiles/MyScholarPageTile/MyScholarPageTile.module.scss';
import tileIllustrations from 'src/components/Tiles/tile-illustrations';
import tileStyles from 'src/components/Tiles/tilesDashboard.module.scss';

interface MyScholarPageTileProps {
  scholarPageSlug: string;
  illustration: keyof typeof tileIllustrations;
}

const MyScholarPageTile = ({ scholarPageSlug, illustration }: MyScholarPageTileProps) => {
  const [statistics, setStatistics] = useState<SymposiumPresenterStatistics>();

  useEffect(() => {
    marketplaceApi
      .getSymposiumPresenterStatistics(scholarPageSlug)
      .then(({ data }) => setStatistics(data))
      .catch((err: Error) => {
        console.error(err);
      });
  }, [scholarPageSlug]);

  return (
    <Link to="/student/my-scholar-page">
      <div
        className={classnames(tileStyles['tile'], styles['scholarPageTile'], 'd-flex flex-column')}
      >
        <Heading size="h5" alignment="left" className="mb-4">
          My Scholar Page
        </Heading>
        {statistics && (
          <>
            <Stat
              text="symposium registrants"
              data={statistics.scholarPageRegistrants}
              iconId="user-plus"
            />
            <Stat text="page views" data={statistics.scholarPageViews} iconId="eye" />
          </>
        )}
        <Text size="small" className={classnames(styles['description'], 'mt-4')}>
          There are thousands of Polygence Scholars just like you. Build your Scholar Page and join
          the network to explore other projects.
        </Text>
        <img
          src={tileIllustrations[illustration]}
          alt="Scholar Page"
          className={styles['illustration']}
        />
      </div>
    </Link>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default MyScholarPageTile;

interface StatProps {
  text: string;
  data: number;
  iconId: IconVariable;
}

const Stat = ({ text, data, iconId }: StatProps) => {
  return (
    <div className="d-flex align-items-center gap-2">
      <Icon id={iconId} size="sm" color="var(--grayscale-6)" className="justify-content-between" />
      <span className={classnames('ms-2', styles['data'])}>{data}</span>
      <span className={styles['explainingText']}>{text}</span>
    </div>
  );
};
