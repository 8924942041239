export const schoolGraduationYearProps = Object.freeze({
  type: 'text',
  name: 'schoolGraduationYear',
  showTimeSelect: false,
  localeTime: false,
  showYearPicker: true,
  dateFormat: 'YYYY',
  minDate: new Date(`${new Date().getFullYear()}`),
});

export type SchoolGraduationYearProps = typeof schoolGraduationYearProps;
