/* eslint-disable sonarjs/cognitive-complexity */
import { $isListNode, ListNode } from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $isHeadingNode } from '@lexical/rich-text';
import { $getNearestNodeOfType, mergeRegister } from '@lexical/utils';
import { cn } from '@polygence/components';
import {
  SELECTION_CHANGE_COMMAND,
  $getSelection,
  $isRangeSelection,
  NodeKey,
  COMMAND_PRIORITY_LOW,
} from 'lexical';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  ToolbarContext,
  ToolbarContextValue,
  BlockType,
} from 'src/components/LexicalEditor/plugins/toolbar/ToolbarContext';

const ToolbarPlugin = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  const [editor] = useLexicalComposerContext();
  const toolbarRef = useRef(null);
  const [blockType, setBlockType] = useState<ToolbarContextValue['blockType']>(BlockType.paragraph);
  const [isCode, setIsCode] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);

  const contextState: ToolbarContextValue = useMemo(
    () => ({
      isBold,
      isItalic,
      isStrikethrough,
      isCode,
      blockType,
    }),
    [isBold, isItalic, isStrikethrough, blockType, isCode],
  );

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === 'root' ? anchorNode : anchorNode.getTopLevelElementOrThrow();
      const elementKey: NodeKey = element.getKey();
      const elementDOM: HTMLElement | null = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType<ListNode>(anchorNode, ListNode);
          const type =
            parentList && $isListNode(parentList) ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element) ? element.getTag() : element.getType();
          setBlockType(type);
        }
      }
      // Update text format
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsStrikethrough(selection.hasFormat('strikethrough'));
      setIsCode(selection.hasFormat('code'));
    }
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload) => {
          updateToolbar();
          return false;
        },
        COMMAND_PRIORITY_LOW,
      ),
    );
  }, [editor, updateToolbar]);

  return (
    <ToolbarContext.Provider value={contextState}>
      <div className={cn('toolbar', className)} ref={toolbarRef}>
        {children}
      </div>
    </ToolbarContext.Provider>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default ToolbarPlugin;
