import { ApplicationSectionIds } from 'src/constants/application-sections';
import { schoolGraduationYearProps } from 'src/students/student-application';
import {
  contentSpacer,
  headingProps,
  headingSpacer,
  illustrationSize,
} from 'src/students/student-application/common-section-components';
import { ApplicationComponentSection } from 'src/students/student-application/student-application-components';

export const schoolSection: ApplicationComponentSection = {
  id: ApplicationSectionIds.SCHOOL_SECTION_ID,
  elements: [
    {
      component: 'ThreeColumnLayout',
      props: {
        children: [
          [{ component: 'AdmissionAdvisorModalInfo' }],
          [
            {
              component: 'CenteredIllustration',
              props: {
                name: 'SpaceAstronautStars',
                size: illustrationSize,
              },
            },
            headingSpacer,
            {
              component: 'ComponentsHeading',
              props: {
                children: 'Are you currently a student?',
                ...headingProps,
              },
            },
            contentSpacer,
            {
              component: 'ComponentsRadioButton',
              tag: 'profile',
              props: {
                name: 'schoolGradeOther',
                label: "Yes, I'm a middle school student.",
                value: 'middle_school_student',
              },
              validation: {
                required: true,
              },
            },
            {
              component: 'SchoolSelector',
              tag: 'profile',
              props: {
                label: 'Your school',
                name: 'middleSchool',
                nameLabel: 'middleSchoolLabel',
                otherName: 'middleSchoolOther',
                placeholder: 'Write here',
                defaultOptions: [],
              },
              validation: {
                selectWithOther: true,
              },
              display: {
                $is: {
                  field: 'schoolGradeOther',
                  value: 'middle_school_student',
                },
              },
            },
            {
              component: 'DatePickerWithLabel',
              tag: 'profile',
              props: {
                ...schoolGraduationYearProps,
                label: 'Expected high school grad year',
                customInput: true,
              },
              validation: {
                required: true,
              },
              display: {
                $is: {
                  field: 'schoolGradeOther',
                  value: 'middle_school_student',
                },
              },
            },
            {
              component: 'ComponentsRadioButton',
              tag: 'profile',
              props: {
                name: 'schoolGradeOther',
                label: "Yes, I'm a high school student.",
                value: 'high_school_student',
              },
            },
            {
              component: 'SchoolSelector',
              tag: 'profile',
              props: {
                label: 'Your school',
                name: 'highSchool',
                nameLabel: 'highSchoolLabel',
                otherName: 'highSchoolOther',
                placeholder: 'Write here',
                defaultOptions: [],
              },
              display: {
                $is: {
                  field: 'schoolGradeOther',
                  value: 'high_school_student',
                },
              },
              validation: {
                selectWithOther: true,
              },
            },
            {
              component: 'DatePickerWithLabel',
              tag: 'profile',
              props: {
                ...schoolGraduationYearProps,
                label: 'Expected graduation year',
                customInput: true,
              },
              display: {
                $is: {
                  field: 'schoolGradeOther',
                  value: 'high_school_student',
                },
              },
              validation: {
                required: true,
              },
            },
            {
              component: 'ComponentsRadioButton',
              tag: 'profile',
              props: {
                name: 'schoolGradeOther',
                label: "Yes, I'm a college student.",
                value: 'college_student',
              },
            },
            {
              component: 'DatePickerWithLabel',
              tag: 'profile',
              props: {
                ...schoolGraduationYearProps,
                label: 'High school graduation year',
                customInput: true,
              },
              display: {
                $is: {
                  field: 'schoolGradeOther',
                  value: 'college_student',
                },
              },
              validation: {
                required: true,
              },
            },
            {
              component: 'ComponentsRadioButton',
              tag: 'profile',
              props: {
                name: 'schoolGradeOther',
                label: "No, I'm not currently in school.",
                value: 'not_in_school',
              },
            },
            {
              component: 'DatePickerWithLabel',
              tag: 'profile',
              props: {
                ...schoolGraduationYearProps,
                label: 'High school graduation year',
                customInput: true,
              },
              display: {
                $is: {
                  field: 'schoolGradeOther',
                  value: 'not_in_school',
                },
              },
              validation: {
                required: true,
              },
            },
          ],
        ],
      },
    },
  ],
};
