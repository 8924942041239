import type { UserInfoToken } from '@polygence/common';
import { Token } from '@polygence/common';
import { captureException } from '@sentry/react';
import jwtDecode from 'jwt-decode';

import { getToken } from 'src/utils';

export const getMyInfo = () => {
  try {
    const current_token = getToken(Token.Access);
    if (current_token) {
      return jwtDecode<UserInfoToken>(current_token);
    }
    return {} as Record<string, never>;
  } catch (error) {
    captureException(error);
    return {} as Record<string, never>;
  }
};

export const isMentor = (userType = getMyInfo().user_type) => userType === 'mentor';

export const isStudent = (userType = getMyInfo().user_type) => userType === 'student';
