import classNames from 'classnames';
import { Container } from 'react-bootstrap';

import { ProjectIdeaForm } from 'src/components/projectIdeaGenerator/ProjectIdeaForm';
import styles from 'src/components/projectIdeaGenerator/ProjectIdeaGenerator.module.scss';
import { ProjectIdeaSuggestions } from 'src/components/projectIdeaGenerator/ProjectIdeaSuggestions';
import { usePolyGptProjectIdeaGenerator } from 'src/components/projectIdeaGenerator/useProjectIdeaGenerator';

export const ProjectIdeaGenerator = () => {
  const { loading, data, error, startIdeaGeneration } = usePolyGptProjectIdeaGenerator();

  return (
    <div className="tw-relative tw-flex tw-w-full tw-flex-col tw-justify-center tw-overflow-hidden tw-bg-primary-900">
      <Container
        className={classNames(
          styles['projectIdeaGenerator'],
          'tw-relative tw-flex tw-gap-8 tw-py-24',
        )}
      >
        <ProjectIdeaForm loading={loading} onSubmit={startIdeaGeneration} />
        <ProjectIdeaSuggestions ideas={data} loading={loading} error={error} />
        <div className={styles['bubbleImageWrapper']}>
          <img
            src="https://dpl6hyzg28thp.cloudfront.net/media/poly-gpt.jpeg"
            alt="decorative bubble"
          />
        </div>
      </Container>
    </div>
  );
};
