/* eslint-disable react/jsx-max-depth */
import NiceModal from '@ebay/nice-modal-react';
import * as paymentApi from '@polygence/common/api/payment';
import type { ProductId } from '@polygence/common/types/common';
import {
  PaymentIntentInvoice,
  PaymentIntentStatuses,
  PaymentIntentTypes,
} from '@polygence/common/types/payment';
import type { Product } from '@polygence/common/types/studentApplication';
import { Text, cn } from '@polygence/components';
import { captureException } from '@sentry/react';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';

import { Loader } from 'src/components/Loader';
import { PageLoad } from 'src/components/PageLoad';
import polygenceTrustpilotScore from 'src/components/static/images/polygence-trustpilot-score.svg';
import { useModal } from 'src/components/useModal';
import { useNavItemsVisibility } from 'src/hooks/useNavItemsVisibility';
import { PaymentIntentAddedProducts } from 'src/payment/PaymentIntent/PaymentIntentAddedProducts';
import { PaymentIntentAlreadyPaid } from 'src/payment/PaymentIntent/PaymentIntentAlreadyPaid';
import { PaymentIntentBundleableProducts } from 'src/payment/PaymentIntent/PaymentIntentBundleableProducts';
import { PaymentIntentCanceled } from 'src/payment/PaymentIntent/PaymentIntentCanceled';
import { PaymentIntentDiscounts } from 'src/payment/PaymentIntent/PaymentIntentDiscounts';
import { PaymentIntentError } from 'src/payment/PaymentIntent/PaymentIntentError';
import { PaymentIntentExpiringDiscounts } from 'src/payment/PaymentIntent/PaymentIntentExpiringDiscounts';
import { PaymentIntentFooter } from 'src/payment/PaymentIntent/PaymentIntentFooter';
import { PaymentIntentHeading } from 'src/payment/PaymentIntent/PaymentIntentHeading';
import { PaymentIntentInTransfer } from 'src/payment/PaymentIntent/PaymentIntentInTransfer';
import { PaymentIntentPageLayout } from 'src/payment/PaymentIntent/PaymentIntentPageLayout';
import { PaymentIntentPriceChangeErrorModal } from 'src/payment/PaymentIntent/PaymentIntentPriceChangeErrorModal';
import { PaymentIntentProceedButtonDesktop } from 'src/payment/PaymentIntent/PaymentIntentProceedButtonDesktop';
import { PaymentIntentProceedButtonMobile } from 'src/payment/PaymentIntent/PaymentIntentProceedButtonMobile';
import { PaymentIntentSecuredByStripe } from 'src/payment/PaymentIntent/PaymentIntentSecuredByStripe';
import { PaymentIntentTotal } from 'src/payment/PaymentIntent/PaymentIntentTotal';
import { PaymentIntentTypeSelector } from 'src/payment/PaymentIntent/PaymentIntentTypeSelector';
import { usePaymentIntentPsychTrigger } from 'src/payment/PaymentIntent/usePaymentIntentPsychTrigger';
import { PaymentPageTerms } from 'src/payment/PaymentPageTerms';
import { trackProceed, trackSelectedProductChange } from 'src/payment/tracking';
import {
  useAddProductToCartMutation,
  useGetPaymentIntentQuery,
  useLazyGetCartQuery,
  useRemoveProductFromCartMutation,
  useUpdatePaymentIntentMutation,
} from 'src/reducers/paymentReducer';
import { navigateToWithTimeout } from 'src/utils/navigateTo';

const GENERIC_ERROR_MESSAGE = 'Something went wrong.';

const mapIdToProductName = (id: number, products: Product[]) => {
  const product = products.find((product) => product.id === id);
  return product ? product.name : '';
};

const trackProducts = (
  selected: boolean,
  previouslySelectedProducts: number[],
  id: number,
  paymentIntent: PaymentIntentInvoice,
) => {
  const selectedProducts = selected
    ? [...previouslySelectedProducts, id]
    : previouslySelectedProducts.filter((product) => product !== id);

  trackSelectedProductChange({
    uuid: paymentIntent.uuid,
    selectedProducts: selectedProducts.map((id) =>
      mapIdToProductName(id, paymentIntent.pitchedProducts),
    ),
    previousSelectedProducts: previouslySelectedProducts.map((id) =>
      mapIdToProductName(id, paymentIntent.pitchedProducts),
    ),
  });
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const PaymentIntentPage = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const {
    data: paymentIntent,
    isLoading: isQueryInProgress,
    isFetching: isFetchingInProgress,
    isError,
  } = useGetPaymentIntentQuery(uuid);
  const [updatePaymentIntent, { isLoading: isMutationInProgress }] =
    useUpdatePaymentIntentMutation();
  const [isLoadingInvoice, setIsLoadingInvoice] = useState(false);
  const [isNavigating, setIsNavigating] = useState(false);
  const [isProceedDisabledDebounced, setIsProceedDisabledDebounced] = useState(false);
  const [fetchCart, { data: cart }] = useLazyGetCartQuery();
  const [addProductToCart, addProductToCartResult] = useAddProductToCartMutation();
  const [removeProductFromCart, removeProductFromCartResult] = useRemoveProductFromCartMutation();

  const isProceedDisabled =
    isQueryInProgress ||
    isFetchingInProgress ||
    isMutationInProgress ||
    isLoadingInvoice ||
    isNavigating ||
    addProductToCartResult.isLoading ||
    removeProductFromCartResult.isLoading;

  useEffect(() => {
    if (paymentIntent?.cartUuid) {
      fetchCart(paymentIntent.cartUuid).catch(console.error);
    }
  }, [fetchCart, paymentIntent?.cartUuid]);

  const [RedirectModal, openRedirectModal, closeRedirectModal] = useModal();

  useEffect(() => {
    const timeout = setTimeout(
      () => {
        setIsProceedDisabledDebounced(isProceedDisabled);
      },
      isProceedDisabled ? 200 : 50,
    );

    return () => clearTimeout(timeout);
  }, [isProceedDisabled]);

  useEffect(() => {
    if (paymentIntent?.redirectUrl) {
      openRedirectModal();
      navigateToUrl(paymentIntent.redirectUrl);
    } else if (paymentIntent?.firstPaymentReceivedAt && paymentIntent.invoiceUrl) {
      openRedirectModal();
      navigateToUrl(paymentIntent.invoiceUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentIntent]);

  useNavItemsVisibility(!!paymentIntent && !paymentIntent.featurePaymentIntentPageV3);
  usePaymentIntentPsychTrigger(paymentIntent);

  const handleProductSelected = (productId: ProductId, selected: boolean) => {
    const previouslySelectedProducts = paymentIntent?.selectedProducts ?? [];

    if (!paymentIntent || !cart || isProceedDisabled) {
      return;
    }

    trackProducts(selected, previouslySelectedProducts, productId, paymentIntent);

    if (selected) {
      addProductToCart({ uuid: cart.uuid, productId })
        .unwrap()
        .catch(() => {
          toast.error(GENERIC_ERROR_MESSAGE);
        });
    } else {
      removeProductFromCart({ uuid: cart.uuid, productId })
        .unwrap()
        .catch(() => {
          toast.error(GENERIC_ERROR_MESSAGE);
        });
    }
  };

  const handleAddBundleProduct = (productId: ProductId) => {
    if (!cart || isProceedDisabled) {
      return;
    }
    addProductToCart({ uuid: cart.uuid, productId })
      .unwrap()
      .catch(() => {
        toast.error(GENERIC_ERROR_MESSAGE);
      });
  };

  const handleTypeChange = (type: PaymentIntentTypes) => {
    if (uuid) {
      void updatePaymentIntent([uuid, { type }]);
    }
  };

  const navigateToUrl = (url: string) => {
    setIsNavigating(true);
    navigateToWithTimeout(url, 1000);
  };

  const handleProceed = () => {
    if (!paymentIntent || isProceedDisabled || !uuid) {
      return;
    }

    trackProceed({
      uuid: paymentIntent.uuid,
      selectedProducts: paymentIntent.selectedProducts.map((id) =>
        mapIdToProductName(id, paymentIntent.pitchedProducts),
      ),
      pitchedProducts: paymentIntent.pitchedProducts.map((product) => product.name),
      paymentMethod: paymentIntent.type,
    });

    openRedirectModal();
    setIsLoadingInvoice(true);
    void paymentApi
      .proceedToPaymentIntentInvoice(uuid, { paymentIntentAmount: paymentIntent.amount })
      .then(({ data }) => {
        setIsLoadingInvoice(false);
        navigateToUrl(data.invoiceUrl);
      })
      .catch((e: AxiosError) => {
        closeRedirectModal();
        if ((e?.response?.data as { detail?: string })?.detail === 'Cart price has changed') {
          NiceModal.show(PaymentIntentPriceChangeErrorModal).catch(console.error);
        } else {
          captureException(e);
          toast.error('Something went wrong.');
        }
      })
      .finally(() => {
        setIsLoadingInvoice(false);
      });
  };

  if (isError) {
    return <PaymentIntentError message="Something went wrong." />;
  }

  if (!paymentIntent || (paymentIntent.cartUuid && !cart)) {
    return <PageLoad />;
  }

  const v3Enabled = paymentIntent.featurePaymentIntentPageV3;

  if (!paymentIntent.cartUuid || !cart) {
    return <PaymentIntentError message="Missing cart on payment intent. Please contact support!" />;
  }

  if (paymentIntent.status === PaymentIntentStatuses.IN_TRANSFER) {
    return <PaymentIntentInTransfer paymentIntent={paymentIntent} />;
  }

  if (paymentIntent.firstPaymentReceivedAt) {
    return (
      <PaymentIntentAlreadyPaid
        paymentIntent={paymentIntent}
        subscription={
          paymentIntent.type === PaymentIntentTypes.SUBSCRIPTION &&
          paymentIntent.status === PaymentIntentStatuses.PENDING
        }
      />
    );
  }

  if (paymentIntent.status === PaymentIntentStatuses.CANCELED) {
    return <PaymentIntentCanceled v3Enabled={v3Enabled} />;
  }

  const bundleableProducts = cart.bundleableProducts;
  const discounts = cart.discounts;
  const expiringDiscounts = discounts.filter((discount) => discount.expiresAt);

  return (
    <>
      <PaymentIntentPageLayout className={v3Enabled ? 'tw-pb-16 md:tw-pb-0' : ''}>
        <PaymentIntentHeading description={paymentIntent.description} />
        <div className={cn('container', v3Enabled && 'tw-pb-4 xl:tw-pb-16')}>
          <div
            className={cn(
              'tw-grid tw-grid-cols-1 tw-gap-5',
              cart.addedProducts.length && 'lg:tw-grid-cols-[3fr_2fr]',
            )}
          >
            <div className="tw-flex tw-flex-col tw-gap-3">
              <PaymentIntentAddedProducts
                cart={cart}
                paymentIntent={paymentIntent}
                onProductSelected={handleProductSelected}
              />
              <PaymentIntentBundleableProducts
                v3Enabled={v3Enabled}
                bundleableProducts={bundleableProducts}
                disabled={addProductToCartResult.isLoading}
                onAddBundleProduct={handleAddBundleProduct}
              />
              {!v3Enabled && (
                <>
                  <PaymentIntentDiscounts discounts={discounts} />
                  <PaymentIntentTotal paymentIntent={paymentIntent} />
                </>
              )}
            </div>
            <div className="tw-flex tw-flex-col tw-gap-5">
              <PaymentIntentExpiringDiscounts discounts={expiringDiscounts} />
              <PaymentIntentProceedButtonDesktop
                v3Enabled={v3Enabled}
                disabled={isProceedDisabledDebounced}
                onProceed={handleProceed}
              />
              <PaymentIntentTypeSelector
                paymentIntent={paymentIntent}
                onChange={handleTypeChange}
              />
              {v3Enabled && (
                <>
                  <div className="tw-mx-4 tw-flex tw-flex-col tw-items-center tw-justify-between tw-gap-3 xl:tw-flex-row">
                    <PaymentIntentSecuredByStripe v3Enabled />
                    <img src={polygenceTrustpilotScore} alt="Trustpilot score" />
                  </div>
                  <PaymentIntentFooter />
                </>
              )}
            </div>
          </div>
        </div>
        {!v3Enabled && <PaymentPageTerms />}
        <PaymentIntentProceedButtonMobile
          v3Enabled={v3Enabled}
          disabled={isProceedDisabledDebounced}
          onProceed={handleProceed}
        />
      </PaymentIntentPageLayout>
      <RedirectModal backdrop="static" centered>
        <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-2 tw-p-8">
          <Loader />
          {isLoadingInvoice && <Text size="medium">Generating invoice...</Text>}
          {isNavigating && <Text size="medium">Redirecting...</Text>}
        </div>
      </RedirectModal>
    </>
  );
};
