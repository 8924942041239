import type { ProductId } from '@polygence/common/types/common';
import type { Cart, PaymentIntentInvoice } from '@polygence/common/types/payment';
import { Heading } from '@polygence/components';
import { keyBy } from 'lodash';

import { PaymentIntentAmountOrPlaceholder } from 'src/payment/PaymentIntent/PaymentIntentAmountOrPlaceholder';
import { PaymentIntentBox } from 'src/payment/PaymentIntent/PaymentIntentBox';
import { PaymentIntentDiscountList } from 'src/payment/PaymentIntent/PaymentIntentDiscountList';
import { PaymentIntentMonthlyInstallmentInterest } from 'src/payment/PaymentIntent/PaymentIntentMonthlyInstallmentInterest';
import { PaymentIntentProduct } from 'src/payment/PaymentIntent/PaymentIntentProduct';

interface PaymentIntentAddedProductsProps {
  cart: Cart;
  paymentIntent: PaymentIntentInvoice;
  onProductSelected: (productId: ProductId, selected: boolean) => void;
}

export const PaymentIntentAddedProducts = ({
  cart,
  paymentIntent,
  onProductSelected,
}: PaymentIntentAddedProductsProps) => {
  if (!cart.addedProducts.length) {
    return null;
  }

  const cartItemsByProductId = keyBy(cart.cartItems, 'product.id');

  return (
    <PaymentIntentBox>
      <Heading as="h2" size="h5" alignment="left" className="tw-mb-4">
        Your package includes
      </Heading>
      <div data-cy="selected-products" className="tw-flex tw-flex-col tw-gap-1.5">
        {cart.addedProducts.map((product) => (
          <PaymentIntentProduct
            key={product.id}
            product={product}
            cartItem={cartItemsByProductId[product.id]}
            isSelected={!!cartItemsByProductId[product.id]}
            isRemovable={cartItemsByProductId[product.id]?.isRemovable ?? true}
            onChange={onProductSelected}
          />
        ))}
      </div>
      {paymentIntent.featurePaymentIntentPageV3 && (
        <>
          <PaymentIntentMonthlyInstallmentInterest
            paymentIntent={paymentIntent}
            className="tw-mt-1.5"
          />
          <PaymentIntentDiscountList
            discounts={cart.discounts}
            className="tw-mt-1.5 tw-text-primary-700"
          />
          <hr className="tw-my-4 tw-bg-gray-600" />
          <div className="tw-flex tw-items-center tw-justify-between">
            <Heading as="h2" size="h5" alignment="left" className="tw-inline">
              Total
            </Heading>
            <PaymentIntentAmountOrPlaceholder amount={paymentIntent.amount} size="medium" />
          </div>
        </>
      )}
    </PaymentIntentBox>
  );
};
