import { getRestClient } from '../commonSettings';
import type { Feature } from '../types/featuretoggle';

interface LatestUpdatedAt {
  __latest_updated_at: string;
}
type FeatureResponse = [...Feature[]];
const filterFeatures = (maybeFeature: Feature | LatestUpdatedAt): maybeFeature is Feature => {
  return 'name' in maybeFeature;
};

export const fetchFeatures = () => {
  const internalFetch = getRestClient();

  return internalFetch.get<FeatureResponse>('/featuretoggles/features/').then(({ data }) => {
    return data.filter(filterFeatures); // this shouldn't be necessary, but better be safe
  });
};
