import type { TagCategories } from '@polygence/common/types/data/marketplace';
import type { Tag } from '@polygence/common/types/marketplace';
import { Button } from '@polygence/components';
import classNames from 'classnames';
import { useState } from 'react';

import { TagInput } from 'src/components/admin/components/reusable/TagInput';
import { authFetch } from 'src/components/customFetch';

export interface EditTagProps {
  tags: Tag[];
  category: TagCategories;
  label?: string;
  display?: boolean;
  changeUrl: string;
  minTagCount?: number;
  maxTagCount?: number;
  onSubmit: (tags: Tag[]) => void;
}

export const EditTag = ({
  tags: originalTags,
  category,
  label = 'Edit tags',
  display = true,
  changeUrl,
  minTagCount = 0,
  maxTagCount = 100,
  onSubmit,
}: EditTagProps) => {
  const [sending, setSending] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [tags, setTags] = useState(originalTags);

  const handleSave = () => {
    if (tags.length < minTagCount) {
      setError(`List at least ${minTagCount} areas`);
      return;
    }

    if (tags.length > maxTagCount) {
      setError(`List at most ${maxTagCount} areas`);
      return;
    }

    setError('');
    setSending(true);

    authFetch(changeUrl, {
      method: 'PATCH',
      body: JSON.stringify({ [`${category}s`]: tags.map(({ id }) => id) }),
    })
      .then(() => {
        setSending(false);
        onSubmit(tags);
      })
      .catch((err) => {
        console.error(err);
        setSending(false);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
        setError(err?.message ?? 'Something went wrong');
      });
  };

  return (
    <div className={classNames({ 'd-none': !display })}>
      {error && <div className="alert alert-danger">{error}</div>}
      <div className="form-group">
        <label htmlFor="tag-editing">{label}</label>
        <TagInput
          name="tag-editing"
          category={category}
          value={tags}
          onChange={(e) => setTags(e.target.value)}
        />
      </div>
      <div className="text-end">
        <Button variant="primary" disabled={sending} onClick={handleSave}>
          Save
        </Button>
      </div>
    </div>
  );
};
