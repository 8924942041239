import type { ProjectOutcomeType } from '@polygence/common';
import {
  StudentEndOfPodStatus,
  type StudentEndOfPodStatuses,
} from '@polygence/common/types/data/pods';
import type { EndOfPodStatus } from '@polygence/common/types/pods';
import { Button, Modal, ModalBody } from '@polygence/components';
import { useState } from 'react';

import { PodsStudentFinalSurvey } from 'src/components/student/student-final-survey/PodsStudentFinalSurvey';
import { PodProjectOutcome } from 'src/students/Pods/PodProjectOutcome';
import { UpsellPograms } from 'src/students/Pods/UpsellPrograms/UpsellPrograms';

const endOfProjectLabels: Record<StudentEndOfPodStatuses, string> = {
  survey: 'Complete Pods Feedback',
  project_outcome: 'Submit project outcome',
  view_programs: 'View additional programs',
};

export const PodsEndOfPodAction = ({
  endOfPodData,
  handleAction,
  outcomeType,
}: {
  endOfPodData: EndOfPodStatus;
  handleAction: () => void;
  outcomeType: ProjectOutcomeType;
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  if (!endOfPodData?.uuid || !endOfPodData.status) {
    return null;
  }

  return (
    <>
      <Button
        variant="primary"
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        {endOfProjectLabels[endOfPodData.status]}
      </Button>
      <Modal
        show={isModalOpen}
        onHide={() => {
          setIsModalOpen(false);
        }}
        closeButton
        size={endOfPodData.status === StudentEndOfPodStatus.VIEW_PROGRAMS ? 'xl' : undefined}
      >
        <ModalBody>
          {(() => {
            switch (endOfPodData.status) {
              case StudentEndOfPodStatus.SURVEY:
                return (
                  <PodsStudentFinalSurvey uuid={endOfPodData.uuid} handleSubmit={handleAction} />
                );
              case StudentEndOfPodStatus.PROJECT_OUTCOME:
                return (
                  <PodProjectOutcome
                    portfolioItemId={endOfPodData.portfolioItemId}
                    handleSubmit={handleAction}
                    outcomeType={outcomeType}
                  />
                );
              default:
                return <UpsellPograms />;
            }
          })()}
        </ModalBody>
      </Modal>
    </>
  );
};
