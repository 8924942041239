import * as marketplaceApi from '@polygence/common/api/marketplace';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';

import { PageLoad } from 'src/components/PageLoad';

export const AdmissionAdvisorCalendlyRedirect = () => {
  const { uuid } = useParams<{ uuid: string }>();

  useEffect(() => {
    marketplaceApi
      .getAdmissionAdvisorModalByUserUuid(uuid)
      .then(({ data }) => {
        if (!data.calendlyUrl) {
          window.location.replace(
            'https://calendly.com/d/zvr-bp6-tv5/polygence-responding-to-inquiry',
          );
        } else {
          window.location.replace(data.calendlyUrl);
        }
      })
      .catch(() => {
        toast.error('Something went wrong.');
      });
  }, [uuid]);

  return <PageLoad />;
};
