import type { ProductId } from '@polygence/common/types/common';
import { sum } from 'lodash';

import type { PartnershipArrangement, ProductItem } from 'src/types/zeus';

export const calculatePriceWithDiscount = (
  basePrice: number,
  {
    discountInDollars,
    discountPercentage,
  }: { discountInDollars?: number; discountPercentage?: number } = {},
) => {
  if (discountPercentage) {
    return ((100 - discountPercentage) * basePrice) / 100;
  }
  if (discountInDollars) {
    return basePrice - discountInDollars;
  }
  return basePrice;
};

export const calculatePriceForProducts = (
  selectedProducts: ProductItem[],
  partnershipArrangement?: PartnershipArrangement,
) => {
  const discountablePrice = sum(
    selectedProducts
      .filter(({ canDiscountBeApplied }) => canDiscountBeApplied)
      .map(({ basePriceInDollars }) => basePriceInDollars),
  );
  const notDiscountablePrice = sum(
    selectedProducts
      .filter(({ canDiscountBeApplied }) => !canDiscountBeApplied)
      .map(({ basePriceInDollars }) => basePriceInDollars),
  );
  if (discountablePrice > 0) {
    return (
      calculatePriceWithDiscount(discountablePrice, partnershipArrangement) + notDiscountablePrice
    );
  }
  return notDiscountablePrice;
};

export const generateInvoiceDescription = (
  selectedProducts: Pick<ProductItem, 'publicName'>[],
  student: { firstName: string; lastName: string },
  defaultProjectLength: number,
) => {
  const description =
    selectedProducts.map((product) => product.publicName).join(' + ') +
    ` for ${student.firstName} ${student.lastName}`;
  if (defaultProjectLength === 10) {
    return description;
  }
  return description.replace('10', `${defaultProjectLength}`);
};

export const filterProducts = (
  products: ProductItem[] | undefined,
  {
    displayProductIds,
    displayPitchable,
  }: { displayProductIds: ProductId[] | undefined; displayPitchable: boolean },
) => {
  return products?.filter(({ id, isPitchable }) => {
    if (displayProductIds) {
      return displayProductIds.includes(id);
    }
    if (displayPitchable) {
      return isPitchable;
    }
    return products;
  });
};

export const formatPrice = (price: number) =>
  new Intl.NumberFormat(navigator.language, {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'narrowSymbol',
  }).format(price);
