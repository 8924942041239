import classNames from 'classnames';
import 'src/components/Loader.scss';

export const Loader = ({ className }: { className?: string }) => {
  return (
    <svg
      className={classNames('polygence-loader', className)}
      width="458"
      height="80"
      viewBox="0 0 458 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="loaderPart"
        d="M37.2816 0C27.3939 0 17.9112 3.92785 10.9195 10.9195C3.92785 17.9112 0 27.3939 0 37.2816H37.2816V0Z"
      />
      <path
        className="loaderPart loaderPart1"
        d="M41.9999 0C51.8876 0 61.3703 3.92785 68.362 10.9195C75.3536 17.9112 79.2815 27.3939 79.2815 37.2816H41.9999V0Z"
      />
      <path
        className="loaderPart loaderPart2"
        d="M79.2816 78.6409C69.3939 78.6409 59.9112 74.713 52.9195 67.7214C45.9279 60.7297 42 51.247 42 41.3593L79.2816 41.3593L79.2816 78.6409Z"
      />
      <path
        className="loaderPart loaderPart3"
        d="M83.9999 78.6409C93.8876 78.6409 103.37 74.713 110.362 67.7214C117.354 60.7297 121.281 51.247 121.281 41.3593L83.9999 41.3593L83.9999 78.6409Z"
      />
      <path
        className="loaderPart loaderPart4"
        d="M121.282 0C111.394 0 101.911 3.92785 94.9195 10.9195C87.9279 17.9112 84 27.3939 84 37.2816H121.282V0Z"
      />
      <path
        className="loaderPart loaderPart5"
        d="M126 0C135.888 0 145.37 3.92785 152.362 10.9195C159.354 17.9112 163.281 27.3939 163.281 37.2816H126V0Z"
      />
      <path
        className="loaderPart loaderPart6"
        d="M163.282 78.6409C153.394 78.6409 143.911 74.713 136.92 67.7214C129.928 60.7297 126 51.247 126 41.3593L163.282 41.3593L163.282 78.6409Z"
      />
      <path
        className="loaderPart loaderPart7"
        d="M168 78.6409C177.888 78.6409 187.37 74.713 194.362 67.7214C201.354 60.7297 205.281 51.247 205.281 41.3593L168 41.3593L168 78.6409Z"
      />
      <path
        className="loaderPart loaderPart8"
        d="M205.282 0C195.394 0 185.911 3.92785 178.92 10.9195C171.928 17.9112 168 27.3939 168 37.2816H205.282V0Z"
      />

      <path
        className="loaderPart loaderPart9"
        d="M210 0C219.888 0 229.37 3.92785 236.362 10.9195C243.354 17.9112 247.281 27.3939 247.281 37.2816H210V0Z"
      />
      <path
        className="loaderPart loaderPart10"
        d="M247.282 78.6409C237.394 78.6409 227.911 74.713 220.92 67.7214C213.928 60.7297 210 51.247 210 41.3593L247.282 41.3593L247.282 78.6409Z"
      />
      <path
        className="loaderPart loaderPart11"
        d="M252 78.6409C261.888 78.6409 271.37 74.713 278.362 67.7214C285.354 60.7297 289.281 51.247 289.281 41.3593L252 41.3593L252 78.6409Z"
      />
      <path
        className="loaderPart loaderPart12"
        d="M289.282 0C279.394 0 269.911 3.92785 262.92 10.9195C255.928 17.9112 252 27.3939 252 37.2816H289.282V0Z"
      />

      <path
        className="loaderPart loaderPart13"
        d="M294 0C303.888 0 313.37 3.92785 320.362 10.9195C327.354 17.9112 331.281 27.3939 331.281 37.2816H294V0Z"
      />
      <path
        className="loaderPart loaderPart14"
        d="M373.282 0C363.394 0 353.911 3.92785 346.92 10.9195C339.928 17.9112 336 27.3939 336 37.2816H373.282V0Z"
      />
      <path
        className="loaderPart loaderPart15"
        d="M331.282 78.6409C321.394 78.6409 311.911 74.713 304.92 67.7214C297.928 60.7297 294 51.247 294 41.3593L331.282 41.3593L331.282 78.6409Z"
      />

      <path
        className="loaderPart loaderPart16"
        d="M336 79.2815C345.888 79.2815 355.37 75.3536 362.362 68.362C369.354 61.3703 373.281 51.8876 373.281 41.9999L336 41.9999L336 79.2815Z"
      />

      <path
        className="loaderPart loaderPart17"
        d="M378 0C387.888 0 397.37 3.92785 404.362 10.9195C411.354 17.9112 415.281 27.3939 415.281 37.2816H378V0Z"
      />
      <path
        className="loaderPart loaderPart18"
        d="M415.282 78.6409C405.394 78.6409 395.911 74.713 388.92 67.7214C381.928 60.7297 378 51.247 378 41.3593L415.282 41.3593L415.282 78.6409Z"
      />
      <path
        className="loaderPart loaderPart19"
        d="M420 78.6409C429.888 78.6409 439.37 74.713 446.362 67.7214C453.354 60.7297 457.281 51.247 457.281 41.3593L420 41.3593L420 78.6409Z"
      />
    </svg>
  );
};
