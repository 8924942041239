import { debounce, memoize } from 'lodash';

import { getTracker } from 'src/utils/tracking/factory';

const memoizeDebounce = (func, wait = 0, options = {}) => {
  const mem = memoize(function () {
    return debounce(func, wait, options);
  }, options.resolver);
  return function () {
    mem.apply(this, arguments).apply(this, arguments);
  };
};

const createInputTracker = (formName, label, debounceTime = 1000) => {
  return memoizeDebounce((name, extra) => {
    getTracker().track('Input Filled', {
      category: formName,
      label,
      value: name,
      ...extra,
    });
  }, debounceTime);
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default createInputTracker;
