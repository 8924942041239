import { useEffect, useState } from 'react';

import { sendUnsentMessagesFromLocalStorage } from '../websocket/messageSending';

import { useWorkspacesLoaded } from './selectors/useRooms';

export const useSendUnsentMessagesOnLoad = () => {
  const [unsentMessagesSent, setUnsentMessagesSent] = useState(false);
  const isLoaded = useWorkspacesLoaded();
  useEffect(() => {
    if (isLoaded && !unsentMessagesSent) {
      setUnsentMessagesSent(true);
      sendUnsentMessagesFromLocalStorage();
    }
  }, [isLoaded, unsentMessagesSent]);
};
