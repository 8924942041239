import type { Nullable } from '@polygence/common';
import { Text, Tooltip } from '@polygence/components';

import { LookingForMentorAvatar } from 'src/components/Tiles/StudentJourney/LookingForMentorAvatar';

interface UnderMatchingLaunchpadProjectJourneyTileProps {
  subject: Nullable<string>;
  index: number;
}

export const UnderMatchingLaunchpadProjectJourneyTile = ({
  subject,
  index,
}: UnderMatchingLaunchpadProjectJourneyTileProps) => {
  return (
    <Tooltip placement="top" tip="Stay tuned! We are currently working to match you with a mentor.">
      <div className="d-flex flex-column align-items-center">
        <LookingForMentorAvatar size={36} />
        <Text alignment="center" size="small" className="mt-1">
          Mentor matching...
        </Text>
        <Text fontWeight="bold" size="small" alignment="center" textWrap="balance" className="mt-4">
          {subject ? subject : `Topic ${index}`}
        </Text>
      </div>
    </Tooltip>
  );
};
