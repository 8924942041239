import type { ProductTypes } from '@polygence/common/types/hermes';
import type { Nullable } from '@polygence/common/types/utils';

import { CART_COOKIE_NAME } from 'src/constants';
import { getCookie, setGlobalCookie } from 'src/utils/cookie';

export const isCartCookieProductValid = (type: ProductTypes) => {
  const cartCookie = getCookie(CART_COOKIE_NAME);

  if (!cartCookie) {
    return false;
  }

  const cart = JSON.parse(decodeURIComponent(cartCookie)) as {
    type: ProductTypes;
    product: Nullable<string>;
  };

  return cart.type === type && 'product' in cart;
};

export const storeCartCookie = (type: ProductTypes) => {
  const cookie = {
    type,
  };
  setGlobalCookie(CART_COOKIE_NAME, encodeURIComponent(JSON.stringify(cookie)));
};
