import { AdmissionAdvisorModal } from '@polygence/common/types/marketplace';
import { Sheet, SheetContent } from '@polygence/components';
import { useEffect, useState } from 'react';

import { AdmissionAdvisorModalInfoContent } from 'src/components/admin/components/AdmissionAdvisorModalInfo/AdmissionAdvisorModalInfoContent';

const ADMIN_ADVISOR_MODAL_INFO_SHEET_KEY_CLOSED = 'admin-admission-advisor-modal-info-sheet-closed';

interface AdmissionAdvisorModalInfoSheetProps {
  advisor: AdmissionAdvisorModal;
}

export const AdmissionAdvisorModalInfoSheet = ({
  advisor,
}: AdmissionAdvisorModalInfoSheetProps) => {
  const [isSheetOpen, setIsSheetOpen] = useState(false);

  useEffect(() => {
    const closed = Boolean(localStorage.getItem(ADMIN_ADVISOR_MODAL_INFO_SHEET_KEY_CLOSED));
    if (!closed) {
      setIsSheetOpen(true);
    }
  }, []);

  const handleSheetOpenChange = (open: boolean) => {
    setIsSheetOpen(open);
    if (!open) {
      localStorage.setItem(ADMIN_ADVISOR_MODAL_INFO_SHEET_KEY_CLOSED, 'true');
    }
  };

  return (
    <Sheet open={isSheetOpen} onOpenChange={handleSheetOpenChange}>
      <SheetContent placement="bottom" className="tw-z-[10000] tw-h-[480px] tw-rounded-t-3xl">
        <AdmissionAdvisorModalInfoContent advisor={advisor} />
      </SheetContent>
    </Sheet>
  );
};
