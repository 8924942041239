import { ApplicationSectionIds } from 'src/constants/application-sections';
import {
  contentSpacer,
  headingProps,
  headingSpacer,
  illustrationSize,
} from 'src/students/student-application/common-section-components';
import { ApplicationComponentSection } from 'src/students/student-application/student-application-components';

export const pronounSection: ApplicationComponentSection = {
  id: ApplicationSectionIds.PRONOUNS_SECTION_ID,
  elements: [
    {
      component: 'ThreeColumnLayout',
      props: {
        children: [
          [{ component: 'AdmissionAdvisorModalInfo' }],
          [
            {
              component: 'CenteredIllustration',
              props: {
                name: 'SpaceAstronautOxygenTank',
                size: illustrationSize,
              },
            },
            headingSpacer,
            {
              component: 'ComponentsHeading',
              props: {
                children: 'Choose your pronouns.',
                ...headingProps,
              },
            },
            contentSpacer,
            {
              component: 'ComponentsRadioButton',
              validation: {
                required: true,
              },
              tag: 'profile',
              props: {
                name: 'pronoun',
                label: 'He/ him',
                value: 'he_him',
              },
            },
            {
              component: 'ComponentsRadioButton',
              tag: 'profile',
              props: {
                name: 'pronoun',
                label: 'She/ her',
                value: 'she_her',
              },
            },
            {
              component: 'ComponentsRadioButton',
              tag: 'profile',
              props: {
                name: 'pronoun',
                label: 'They/ them',
                value: 'they_them',
              },
            },
            {
              component: 'ComponentsRadioButton',
              tag: 'profile',
              props: {
                name: 'pronoun',
                label: 'Custom',
                value: 'other',
              },
            },
            {
              component: 'InputField',
              display: {
                $is: {
                  field: 'pronoun',
                  value: 'other',
                },
              },
              tag: 'profile',
              props: {
                label: 'Please specify',
                name: 'pronounOther',
              },
              validation: {
                required: true,
              },
            },
          ],
        ],
      },
    },
  ],
};
