import * as hermesApi from '@polygence/common/api/hermes';
import React, { useState, useEffect } from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router';
import ReactRouterPropTypes from 'react-router-prop-types';

import { PageLoad } from 'src/components/PageLoad';
import { getFinalMentorSurveyQuestions } from 'src/components/hermes/surveys/mentor/finalMentorSurveyQuestions';
import { FormSection } from 'src/components/mentor/FormSection';

const FinalMentorSurvey = () => {
  const [data, setData] = useState({});
  const [project, setProject] = useState();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const { uuid } = useParams();

  useEffect(() => {
    hermesApi
      .getProjectByUuid(uuid)
      .then(({ data: result }) => {
        setProject(result);
      })
      .catch((e) => {
        console.error(e);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [uuid]);

  const handleSubmit = (event) => {
    setLoading(true);
    event.preventDefault();
    const form = event.currentTarget;
    const formData = Object.fromEntries(new FormData(form).entries());

    const payload = JSON.stringify({
      questionnaire: { ...project.questionnaire, ...formData },
    });

    hermesApi
      .updateProject(project.id, payload)
      .then(() => {
        setSubmitted(true);
      })
      .catch((e) => {
        console.error(e);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const onChange = ({ target: { name, value } }) => {
    setData((prevData) => {
      // eslint-disable-next-line sonarjs/prefer-immediate-return -- autodisabled
      const newState = { ...prevData, [name]: value };
      return newState;
    });
  };

  if (error) {
    return (
      // eslint-disable-next-line react/jsx-filename-extension -- autodisabled
      <Container className="my-5">
        <Row>
          <Col className="text-center text-danger">
            Something went wrong. Please contact{' '}
            <a href="mailto:mentors@polygence.org">mentors@polygence.org</a> for assistance.
          </Col>
        </Row>
      </Container>
    );
  }

  if (loading) {
    return <PageLoad />;
  }

  if (submitted || project?.questionnaire?.endOfProgramSurveyCompleted) {
    return (
      <Container className="my-5">
        <Row>
          <Col className="text-center">Thank you for submitting the survey!</Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container className="my-5">
      <Row>
        <Col lg={{ offset: 2, span: 8 }}>
          <form onSubmit={handleSubmit}>
            <FormSection
              fields={getFinalMentorSurveyQuestions({})}
              fieldErrors={[]}
              profile={data}
              onChange={onChange}
              helpColumn={false}
            />
            <div className="text-center">
              <Button type="submit" className="btn btn-primary">
                Submit
              </Button>
            </div>
          </form>
        </Col>
      </Row>
    </Container>
  );
};

FinalMentorSurvey.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default FinalMentorSurvey;
