import { externalLink } from '@polygence/common';
import type { PaymentIntentInvoice } from '@polygence/common/types/payment';
import { Button, Heading, Text } from '@polygence/components';

import DateWrapper, { DateFormat } from 'src/components/aux/dateWrapper';
import { STUDENT_TEAM_EMAIL } from 'src/constants/emails';
import { PaymentIntentFooter } from 'src/payment/PaymentIntent/PaymentIntentFooter';
import { PaymentIntentPageLayout } from 'src/payment/PaymentIntent/PaymentIntentPageLayout';
import { PaymentPageTerms } from 'src/payment/PaymentPageTerms';

interface PaymentIntentInTransferProps {
  paymentIntent: PaymentIntentInvoice;
}

export const PaymentIntentInTransfer = ({ paymentIntent }: PaymentIntentInTransferProps) => {
  return (
    <PaymentIntentPageLayout className="tw-pt-8">
      <div className="row tw-flex tw-items-center tw-justify-center">
        <Heading as="h1" size="h3" className="tw-mb-5">
          Payment in progress
        </Heading>
        <Text size="medium" alignment="center" className="tw-mb-1 tw-text-gray-500">
          You have opted to pay via a bank transfer. If you have already initiated the bank
          transfer, the funds may take several days to reach us and be processed accordingly.
        </Text>
        <Text size="medium" alignment="center" className="tw-mb-1 tw-text-gray-500">
          If you have not yet initiated the transfer, please do so to help move your student into
          the program.
        </Text>
        <Text size="medium" alignment="center" className="tw-mb-6 tw-text-gray-500">
          <span className="tw-font-bold">Bank transfer payment option selected: </span>
          {paymentIntent.transferStartedAt && (
            <Text size="medium" alignment="center" className="tw-mb-6 tw-text-gray-500">
              <span className="tw-font-bold">Payment transfer started at: </span>
              {new DateWrapper(paymentIntent.transferStartedAt).format(DateFormat.FORMAT_6)}
            </Text>
          )}
        </Text>
        <Text size="medium" alignment="center" className="tw-text-gray-500">
          If you have any questions or want to change the payment method, please contact our support
          team at{' '}
          <a
            href={`mailto:${STUDENT_TEAM_EMAIL}`}
            className="tw-font-bold tw-text-gray-400 tw-underline"
          >
            {STUDENT_TEAM_EMAIL}
          </a>
        </Text>
        <Text size="medium" alignment="center" className="tw-mb-3 tw-text-gray-500">
          In the meantime, please note that you won't be able to return to the checkout page for
          this transaction.
        </Text>
        <Text size="medium" alignment="center" className="tw-mb-3 tw-text-gray-500">
          You can find the transfer instructions in your email inbox
          {paymentIntent.bankTransferUrl && (
            <>
              {` or `}
              <Button
                href={paymentIntent.bankTransferUrl}
                size="sm"
                variant="tertiary"
                {...externalLink}
              >
                visit this page
              </Button>
            </>
          )}
        </Text>
        <Text size="medium" alignment="center" className="tw-font-bold tw-text-gray-500">
          Thank you for your patience and understanding!
        </Text>
      </div>
      {paymentIntent.featurePaymentIntentPageV3 ? <PaymentIntentFooter /> : <PaymentPageTerms />}
    </PaymentIntentPageLayout>
  );
};
