import type { CamelCasedPropertiesDeep } from 'type-fest';

import type { Nullable } from '../types/utils';

import type { PathfindersStudentApplicationId, ProjectRequestsGroupId, UUID } from './common';
import type {
  PathfinderApplicationInterestCreateSerializer,
  PathfinderApplicationInterestSerializer,
  PathfinderStudentApplicationInterimSerializer,
} from './data/studentApplication';

export const PathfinderStudentApplicationStatuses = {
  CREATED: 'created',
  COMPLETED: 'completed',
  ACCEPTED: 'accepted',
  DECLINED: 'declined',
  ARCHIVED: 'archived',
} as const;

export type PathfinderStudentApplicationStatus =
  (typeof PathfinderStudentApplicationStatuses)[keyof typeof PathfinderStudentApplicationStatuses];

export type PathfinderApplicationInterest =
  CamelCasedPropertiesDeep<PathfinderApplicationInterestSerializer>;
export type PathfinderApplicationInterestCreate =
  CamelCasedPropertiesDeep<PathfinderApplicationInterestCreateSerializer>;
export type PathfinderStudentApplicationInterim =
  CamelCasedPropertiesDeep<PathfinderStudentApplicationInterimSerializer>;

export interface PathfinderStudentApplication {
  id: PathfindersStudentApplicationId;
  uuid: UUID;
  student: number;
  status: PathfinderStudentApplicationStatus;
  subjectsLimit: number;
  completedApplicationSections: string[];
  interests: number[];
  programExpectation: string;
  outputExpectation: string;
  projectRequestsGroup: Nullable<ProjectRequestsGroupId>;
  projectRequestsGroupStatus: Nullable<string>;
  paymentIntentUuid: Nullable<string>;
  createdAt: string;
  updatedAt: string;
  firstCompletedAt: Nullable<string>;
  completedAt: Nullable<string>;
  acceptedAt: Nullable<string>;
  declinedAt: Nullable<string>;
  archivedAt: Nullable<string>;
}

export interface CompletePathfinderApplication {
  application: PathfinderStudentApplication;
  paymentId: string;
  partnerPaysWorkflow: boolean;
}
