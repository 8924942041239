import { isDevelopment } from '@polygence/common';

const setClarityVariables = (variables: Record<string, unknown>) => {
  if (!isDevelopment()) {
    return;
  }
  if (typeof window !== 'undefined' && typeof window.clarity !== 'undefined') {
    Object.entries(variables).forEach(([key, value]) => {
      // @ts-expect-error hard stuff to type with different types
      window.clarity('set', key, value);
    });
  }
};

export { setClarityVariables };
