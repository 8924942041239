import { getRestClient } from '../commonSettings';
import type {
  AdditionalSessionId,
  AssignmentId,
  AttachmentId,
  ExtensionRequestId,
  FeedbackId,
  MeetingId,
  ProjectId,
  RoomId,
  SessionId,
  SharedResourceId,
  TimeSlotId,
  TodoItemId,
  UUID,
  UserId,
  WorkspaceId,
} from '../types/common';
import type { ErrorInPayload } from '../types/data/common';
import type {
  AttachmentDetailSerializer,
  ConvertedTimeSlotSerializer,
  CreateMessageSerializer,
  ExtensionRequestSerializer,
  ExtensionRequestUpdateSerializer,
  MessageSerializer,
  SessionSerializer,
  ShowcaseAddonInvoice,
  SilentRoomUserReportSerializer,
  SummarySerializer,
  TimeSlotCreateSerializer,
  WorkspaceSerializer,
} from '../types/data/hermes';
import type {
  CancelSessionRequest,
  ExtensionRequestCreatePayload,
  WorkspaceDisplay,
} from '../types/hermes';
import {
  AdditionalSession,
  AdditionalSessionData,
  AdditionalSessionStatus,
  Assignment,
  AssignmentBlueprint,
  AssignmentSolution,
  Meeting,
  MeetingStatus,
  Message,
  NewAssignment,
  NewWorkspace,
  Project,
  ScheduledSession,
  Session,
  SessionSummaryPayload,
  SharedResource,
  SilentReport,
  StudentFeedbackResponse,
  TimeSlot,
} from '../types/hermes';
import { TodoItem } from '../types/todo';

export function submitStudentFeedback(data: {
  content: Record<string, unknown>;
  session: SessionId;
}) {
  const internalFetch = getRestClient();
  return internalFetch.post<{ id: FeedbackId }>(`/hermes/feedback/`, {
    ...data,
  });
}

export function sendMessage(payload: Partial<Message>) {
  const internalFetch = getRestClient();
  return internalFetch.post<CreateMessageSerializer>(`/hermes/messages/`, payload);
}

export function createWorkspace(data: NewWorkspace) {
  const internalFetch = getRestClient();
  return internalFetch.post<{ success: boolean }>('/hermes/workspaces/', data);
}

export function getWorkspaceDisplayList() {
  const internalFetch = getRestClient();
  return internalFetch.get<WorkspaceDisplay[]>('/hermes/workspaces/list/');
}

export function getWorkspaceById(id: WorkspaceId) {
  const internalFetch = getRestClient();
  return internalFetch.get<WorkspaceSerializer>(`/hermes/workspaces/${id}/`);
}

export function getAttachmentsForWorkspace(workspaceId: WorkspaceId) {
  const internalFetch = getRestClient();
  return internalFetch.get<AttachmentDetailSerializer[]>(
    `/hermes/workspaces/${workspaceId}/attachments/`,
  );
}

export function getAttachment(id: AttachmentId) {
  const internalFetch = getRestClient();
  return internalFetch.get<AttachmentDetailSerializer>(`/hermes/attachments/${id}/`);
}

export function updateProject(projectId: ProjectId, data: Partial<Project>) {
  const internalFetch = getRestClient();
  return internalFetch.patch<Project>(`/hermes/projects/${projectId}/`, data);
}

export function reportSilentRoomUser(workspaceId: WorkspaceId, payload: SilentReport) {
  const internalFetch = getRestClient();
  return internalFetch.post<SilentRoomUserReportSerializer>(
    `/hermes/workspaces/${workspaceId}/silent_room_user_report/`,
    payload,
  );
}

export function getProjectByUuid(uuid: UUID) {
  const internalFetch = getRestClient();
  return internalFetch.get<Project>(`/hermes/projects/uuid/${uuid}/`);
}

export function getProject(projectId: ProjectId) {
  const internalFetch = getRestClient();
  return internalFetch.get<Project>(`/hermes/projects/${projectId}/`);
}

export function getSessionsForProject(projectId: ProjectId) {
  const internalFetch = getRestClient();
  return internalFetch.get<SessionSerializer[]>(`/hermes/projects/${projectId}/sessions/`);
}

export function createSessionForProject(projectId: ProjectId, data: Partial<Session>) {
  const internalFetch = getRestClient();
  return internalFetch.post<ErrorInPayload | ScheduledSession>(
    `/hermes/projects/${projectId}/sessions/`,
    data,
  );
}

export function updateSessionForProject(
  projectId: ProjectId,
  sessionId: SessionId,
  data: Partial<Session>,
) {
  const internalFetch = getRestClient();
  return internalFetch.patch<ErrorInPayload | ScheduledSession>(
    `/hermes/projects/${projectId}/sessions/${sessionId}/`,
    data,
  );
}

export function deleteSessionOfProject(projectId: ProjectId, sessionId: SessionId) {
  const internalFetch = getRestClient();
  return internalFetch.delete<ErrorInPayload | ScheduledSession>(
    `/hermes/projects/${projectId}/sessions/${sessionId}/`,
  );
}

export function getSessionForProjectByUuid(projectUuid: UUID, sessionId: SessionId) {
  const internalFetch = getRestClient();
  return internalFetch.get<SessionSerializer>(
    `/hermes/projects/${projectUuid}/session/${sessionId}/`,
  );
}

export function cancelSession({
  projectId,
  sessionId,
  reason,
  proposalDeclineReason,
  declined = false,
  isLate = false,
}: CancelSessionRequest) {
  const extras = { reason, proposalDeclineReason, declined };
  const newStatus = isLate ? MeetingStatus.LATE_CANCELLED : MeetingStatus.CANCELLED;
  return updateSessionForProject(projectId, sessionId, {
    extras,
    meeting: { status: newStatus },
  });
}

export function getMeetingsForWorkspace(workspaceId: WorkspaceId) {
  const internalFetch = getRestClient();
  return internalFetch.get<Meeting[]>(`/hermes/meetings/?workspace_id=${workspaceId}`);
}

export function createMeeting(data: Partial<Meeting>) {
  const internalFetch = getRestClient();
  return internalFetch.post<ErrorInPayload | MessageSerializer>(`/hermes/meetings/`, data);
}

export function cancelMeeting(meetingId: MeetingId) {
  const internalFetch = getRestClient();
  return internalFetch.delete<ErrorInPayload>(`/hermes/meetings/${meetingId}/`);
}

export function updateMeeting(meetingId: MeetingId, data: Partial<Meeting>) {
  const internalFetch = getRestClient();
  return internalFetch.patch<ErrorInPayload | MessageSerializer>(
    `/hermes/meetings/${meetingId}/`,
    data,
  );
}

export function postPurchaseShowcasingSupportAddon(projectId: ProjectId) {
  const internalFetch = getRestClient();
  return internalFetch.post<ErrorInPayload | ShowcaseAddonInvoice>(
    `/hermes/projects/${projectId}/showcasing-addon/`,
  );
}

export function getSharedResourcesForWorkspace(workspaceId: WorkspaceId) {
  const internalFetch = getRestClient();
  return internalFetch.get<SharedResource[]>(`/hermes/workspaces/${workspaceId}/resources/`);
}

export function updateSharedResource(
  sharedResourceId: SharedResourceId,
  payload: Partial<SharedResource>,
) {
  const internalFetch = getRestClient();
  return internalFetch.patch<SharedResource>(`/hermes/resources/${sharedResourceId}/`, payload);
}

export function getTimeSlots() {
  const internalFetch = getRestClient();
  return internalFetch.get<TimeSlot[]>(`/hermes/time-slots/`);
}

export function createTimeSlot(data: TimeSlotCreateSerializer) {
  const internalFetch = getRestClient();
  return internalFetch.post<TimeSlot>(`/hermes/time-slots/`, data);
}

export function updateTimeSlot(id: TimeSlotId, data: Partial<TimeSlotCreateSerializer>) {
  const internalFetch = getRestClient();
  return internalFetch.patch<TimeSlot>(`/hermes/time-slots/${id}/`, data);
}

export function deleteTimeSlot(id: TimeSlotId) {
  const internalFetch = getRestClient();
  return internalFetch.delete<unknown>(`/hermes/time-slots/${id}/`);
}

export function getUserAvailabilityForProject(projectId: ProjectId) {
  const internalFetch = getRestClient();
  return internalFetch.get<Record<number, ConvertedTimeSlotSerializer>>(
    `/hermes/user-availability-for-project/${projectId}/`,
  );
}

export function reportMentorNoShow(meetingId: MeetingId, { comment }: { comment: string }) {
  const internalFetch = getRestClient();
  return internalFetch.post(`/hermes/meetings/${meetingId}/mentor-no-show/`, {
    comment,
  });
}

export function createAssignment(payload: NewAssignment) {
  const internalFetch = getRestClient();
  return internalFetch.post<Assignment>('/hermes/assignments/', payload);
}

export function updateAssignmentSolution(
  assignmentId: AssignmentId,
  data: Partial<AssignmentSolution>,
) {
  const internalFetch = getRestClient();
  return internalFetch.patch<{ assignmentId: AssignmentId; solution: AssignmentSolution }>(
    `/hermes/assignments/${assignmentId}/assignment_solution/`,
    data,
  );
}

export function getMessagesForRoom(roomId: RoomId, page = 1) {
  const internalFetch = getRestClient();
  return internalFetch.get<{ results: MessageSerializer[] }>(
    `/hermes/rooms/${roomId}/messages/?page=${page}`,
  );
}

export function toggleRoomNotification(roomId: RoomId) {
  const internalFetch = getRestClient();
  return internalFetch.patch<{ enabled: boolean }>(`/hermes/rooms/${roomId}/toggle_notification/`);
}

export function submitSessionSummary(
  projectId: ProjectId,
  sessionId: SessionId,
  data: SessionSummaryPayload,
) {
  const internalFetch = getRestClient();
  return internalFetch.post<ErrorInPayload | SummarySerializer>(
    `/hermes/projects/${projectId}/sessions/${sessionId}/summary/`,
    data,
  );
}

export async function getAssignmentBlueprints(workspaceId: WorkspaceId) {
  const internalFetch = getRestClient();
  return internalFetch.get<AssignmentBlueprint[]>(`/hermes/assignment_blueprints/${workspaceId}/`);
}

export function createProjectExtensionRequest(extensionRequest: ExtensionRequestCreatePayload) {
  const internalFetch = getRestClient();
  return internalFetch.post<ExtensionRequestSerializer>(
    '/hermes/project_extension_requests/',
    extensionRequest,
  );
}

export function respondToProjectExtensionRequest(id: ExtensionRequestId, status: string) {
  const internalFetch = getRestClient();
  return internalFetch.put<ExtensionRequestUpdateSerializer>(
    `/hermes/project_extension_requests/${id}/`,
    {
      status,
    },
  );
}

export function sendBulkAdminMessage(payload: {
  workspaceIds: WorkspaceId[];
  bulkMessage: string;
}) {
  const internalFetch = getRestClient();
  return internalFetch.post<{ created: boolean } | { error: string }>(
    '/hermes/bulk_admin_message/',
    payload,
  );
}

export function sendBulkPushNotification(payload: {
  userIds: UserId[];
  title: string;
  bulkMessage: string;
}) {
  const internalFetch = getRestClient();
  return internalFetch.post<{ success: boolean } | { error: string }>(
    '/hermes/bulk_push_notification/',
    payload,
  );
}

export function createAdditionalSessionRequest(payload: AdditionalSessionData) {
  const internalFetch = getRestClient();
  return internalFetch.post<AdditionalSession>('/hermes/project_additional_sessions/', payload);
}

export function updateAdditionalSessionRequest(
  id: AdditionalSessionId,
  payload: { status: AdditionalSessionStatus; respondedBy: UserId },
) {
  const internalFetch = getRestClient();
  return internalFetch.patch<AdditionalSession>(
    `/hermes/project_additional_sessions/${id}/`,
    payload,
  );
}

export function submitStudentFeedbackRequest(data: StudentFeedbackResponse) {
  const internalFetch = getRestClient();
  return internalFetch.post<StudentFeedbackResponse>(`/hermes/projects/feedback_request/`, data);
}

export function getTodoItems() {
  const internalFetch = getRestClient();
  return internalFetch.get<TodoItem[]>('/todo/todo-items/');
}

export function updateTodoItem(id: TodoItemId, payload: Partial<TodoItem>) {
  const internalFetch = getRestClient();
  return internalFetch.patch<TodoItem>(`/todo/todo-items/${id}/`, payload);
}

export function markCompletedTodoItemsAsSeen(todoItemIds: TodoItemId[]) {
  const internalFetch = getRestClient();
  return internalFetch.patch<void>(`/todo/todo-items/mark-completed-seen/`, { todoItemIds });
}
