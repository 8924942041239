import * as hermesApi from '../api/hermes';
import { getCommonStore } from '../commonSettings';
import { commonReducers } from '../store/reducers';
import { MessageType, Message } from '../types/hermes';
import { unsentMessagesLocalStorageHandler } from '../utils/hermes/unsentMessagesStorageManager';

import { sendMessage as sendSocketMessage, getConnection } from './api';

export const sendMessage = ({
  roomId,
  message,
  localMessageId,
  sentBy,
  attachments,
  messageType,
  resend = false,
}: {
  roomId: number;
  message: string;
  localMessageId: string;
  sentBy: { id: number };
  attachments: { id: number }[];
  messageType: MessageType;
  resend?: boolean;
}) => {
  const attachmentIds = handleMessageStatus({
    roomId,
    message,
    localMessageId,
    sentBy,
    attachments,
    resend,
  });

  sendSocketMessage({
    roomId,
    message,
    localMessageId,
    extra: { attachmentIds },
    messageType,
  });

  resendMessageTimeout({
    roomId,
    message,
    localMessageId,
    extra: { attachmentIds },
    messageType,
  });
};

const handleMessageStatus = ({
  roomId,
  message,
  localMessageId,
  sentBy,
  attachments,
  resend,
}: {
  roomId: number;
  message: string;
  localMessageId: string;
  sentBy: { id: number };
  attachments: { id: number }[];
  resend: boolean;
}) => {
  if (resend) {
    getCommonStore().dispatch(
      commonReducers.hermesActions.messageSending({ roomId, localMessageId }),
    );
    return findUnsentMessage(roomId, localMessageId)?.attachmentIds;
  } else {
    const attachmentIds = attachments.map(({ id }) => {
      return id;
    });
    getCommonStore().dispatch(
      commonReducers.hermesActions.messageSent({
        roomId,
        message,
        localMessageId,
        sentBy,
        attachmentIds,
      }),
    );
    return attachmentIds;
  }
};

const TIMEOUT_DURATION = 5000;
const resendMessageTimeout = (message: {
  roomId: number;
  message: string;
  localMessageId: string;
  extra: { attachmentIds?: number[] | undefined };
  messageType: MessageType;
}) =>
  setTimeout(() => {
    const { roomId, localMessageId } = message;
    const unsentMessage = findUnsentMessage(roomId, localMessageId);
    if (unsentMessage) {
      getCommonStore().dispatch(
        commonReducers.hermesActions.messageSending({ roomId, localMessageId }),
      );
      hermesApi
        .sendMessage(message)
        .then((response) => {
          getCommonStore().dispatch(
            commonReducers.hermesActions.messageSentSuccess({
              roomId,
              workspaceId: response.data.workspaceId,
              localMessageId,
              message: response.data.message,
              sharedResources: response.data.sharedResources,
            }),
          );
          getConnection();
        })
        .catch(() => {
          getCommonStore().dispatch(
            commonReducers.hermesActions.messageSentFailure({ roomId, localMessageId }),
          );
        });
    }
  }, TIMEOUT_DURATION);

const findUnsentMessage = (
  roomId: number,
  localMessageId: string,
): Partial<Message> | undefined => {
  const state = getCommonStore().getState().hermes;
  return state.rooms.byId[roomId]?.unsentMessages.find(({ localMessageId: id }) => {
    return id === localMessageId;
  });
};

export const sendUnsentMessagesFromLocalStorage = () => {
  const state = getCommonStore().getState();
  const userId = state.user.id;

  state.hermes.rooms.allIds.forEach((roomId) => {
    unsentMessagesLocalStorageHandler
      .get(roomId, userId || 0)
      .then((localUnsentMessages) => {
        if (localUnsentMessages && Array.isArray(localUnsentMessages)) {
          localUnsentMessages.reverse().forEach((message) =>
            sendMessage({
              attachments: (message.attachmentIds ?? []).map((id) => ({ id })),
              roomId,
              message: message.content ?? '',
              localMessageId: message.localMessageId ?? '',
              sentBy: { id: userId ?? 0 },
              messageType: message.messageType ?? MessageType.MARKDOWN,
              resend: false,
            }),
          );
        }
      })
      .catch(console.error);
  });
};
