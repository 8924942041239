import * as marketplaceApi from '@polygence/common/api/marketplace';
import type { Nullable } from '@polygence/common/types/utils';
import {
  Button,
  Modal,
  Icon,
  InputField,
  Select,
  OptionType,
  Spacer,
  Alert,
  Heading,
  RadioButton,
  ModalTitle,
  ModalBody,
  ModalFooter,
} from '@polygence/components';
import React, { ChangeEvent, useEffect, useState } from 'react';

import { REGEX_VALIDATORS } from 'src/components/constants';
import { FileUploadInput } from 'src/form-builder/elements/FileUploadInput';
import type { ProjectLinkModalProps, ProjectLinkModalValues } from 'src/types/marketplace';

export const validate = (
  _modalValues: Nullable<Partial<ProjectLinkModalValues>>,
  setValidationError: (str: string) => void,
  projectOutcome: string,
  projectShowcase: string,
) => {
  if (!_modalValues?.outcomeType) {
    return setValidationError('You need to select a project outcome type.');
  }

  if (projectShowcase === 'yes' && !_modalValues?.platform) {
    return setValidationError('You need to provide platform.');
  }

  if (projectOutcome === 'url' && !_modalValues?.url) {
    return setValidationError('You need to provide an URL link.');
  }

  if (projectOutcome === 'file' && !_modalValues?.permOutcomeFile) {
    return setValidationError('You need to upload your work.');
  }

  if (projectOutcome === '') {
    return setValidationError('Please upload your work or add an url link to your work.');
  }

  if (projectShowcase === '') {
    return setValidationError('Please tell us if you have showcased your work.');
  }

  const regex = new RegExp(REGEX_VALIDATORS.URL);
  if (_modalValues?.url && !regex.test(_modalValues?.url)) {
    return setValidationError('The URL you provided is not valid.');
  }

  return setValidationError('');
};

const ProjectShowcase = ({
  modalValues,
  projectShowcase,
  handleChange,
  handleShowcaseChange,
}: {
  modalValues: ProjectLinkModalValues | null;
  projectShowcase: string;
  handleChange: (name: string, value: string) => void;
  handleShowcaseChange: React.ChangeEventHandler<HTMLInputElement>;
}) => {
  return (
    <>
      <RadioButton
        name="projectShowcase"
        label="Yes"
        value="yes"
        defaultChecked={projectShowcase === 'yes'}
        onChange={handleShowcaseChange}
      />
      {projectShowcase === 'yes' && (
        <InputField
          type="text"
          label="Where did you showcase your work?"
          name="platform"
          value={modalValues?.platform || ''}
          onChange={({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
            handleChange(name, value);
          }}
        />
      )}
      <RadioButton
        name="projectShowcase"
        label="No"
        value="no"
        onChange={handleShowcaseChange}
        defaultChecked={projectShowcase === 'no'}
      />
    </>
  );
};

export const ProjectOutcome = ({
  modalValues,
  projectOutcome,
  handleChange,
  handleOutcomeChange,
}: {
  modalValues: Nullable<Partial<ProjectLinkModalValues>>;
  projectOutcome: string;
  handleChange: (name: string, value: string | string[]) => void;
  handleOutcomeChange: React.ChangeEventHandler<HTMLInputElement>;
}) => {
  return (
    <>
      <RadioButton
        name="projectOutcome"
        label="File Upload"
        value="file"
        defaultChecked={projectOutcome === 'file'}
        onChange={handleOutcomeChange}
      />
      {projectOutcome === 'file' && (
        <>
          <FileUploadInput
            multiple={false}
            accept={['.pdf']}
            name="permOutcomeFile"
            // @ts-expect-error https://polygence.atlassian.net/browse/BSH-7657
            onUpload={marketplaceApi.uploadFile}
            onDelete={() => {
              handleChange('permOutcomeFile', '');
            }}
            // @ts-expect-error https://polygence.atlassian.net/browse/BSH-7657
            onChange={({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
              handleChange(name, value[1] ? [value[1]] : value);
              handleChange('url', '');
            }}
            data={{
              // @ts-expect-error https://polygence.atlassian.net/browse/BSH-7657
              permOutcomeFile:
                Array.isArray(modalValues?.permOutcomeFile) && modalValues?.permOutcomeFile?.length
                  ? [{ url: modalValues?.permOutcomeFile[0]?.url }]
                  : [],
            }}
          />
          <Spacer size={5} />
        </>
      )}
      <RadioButton
        name="projectOutcome"
        label="URL link"
        value="url"
        defaultChecked={projectOutcome === 'url'}
        onChange={handleOutcomeChange}
      />
      {projectOutcome === 'url' && (
        <InputField
          type="text"
          label="URL link"
          name="url"
          value={modalValues?.url || ''}
          onChange={({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
            handleChange(name, value);
            handleChange('permOutcomeFile', '');
          }}
        />
      )}
    </>
  );
};

const getInitialProjectShowcase = (modalValues: ProjectLinkModalValues | null) => {
  if (!modalValues) {
    return '';
  }
  return modalValues.platform ? 'yes' : 'no';
};

const getInitialProjectOutcome = (modalValues: ProjectLinkModalValues | null) => {
  if (modalValues?.permOutcomeFile) {
    return 'file';
  }
  if (modalValues?.url) {
    return 'url';
  }

  return '';
};

const ProjectLinkModal = ({
  onChange: handleChange,
  onClose: handleClose,
  onSave: handleSave,
  options = [],
  modalValues,
}: ProjectLinkModalProps) => {
  const [showValidation, setShowValidation] = useState<boolean>(false);
  const [validationError, setValidationError] = useState<string>('');
  const [projectShowcase, setProjectShowcase] = useState<string>(
    getInitialProjectShowcase(modalValues),
  );
  const [projectOutcome, setProjectOutcome] = useState<string>(
    getInitialProjectOutcome(modalValues),
  );

  const handleShowcaseChange: React.ChangeEventHandler<HTMLInputElement> = ({
    target: { value },
  }) => {
    setProjectShowcase(value);
  };

  const handleOutcomeChange: React.ChangeEventHandler<HTMLInputElement> = ({
    target: { value },
  }) => {
    setProjectOutcome(value);
  };

  useEffect(() => {
    validate(modalValues, setValidationError, projectOutcome, projectShowcase);
  }, [modalValues, projectShowcase, projectOutcome]);

  return (
    <Modal show onHide={handleClose} closeButton>
      <ModalTitle>
        <Heading alignment="left" as="h1" size="h4">
          Project link
        </Heading>
      </ModalTitle>
      <ModalBody>
        <div className="p-8 d-flex flex-column gap-3">
          <Select
            name="outcomeType"
            options={options}
            value={modalValues?.outcomeType || null}
            placeholder="What is your project outcome?"
            onChange={(newValue) => {
              handleChange('outcomeType', newValue as OptionType);
            }}
          />
          <Spacer size={2} />
          <Heading alignment="left" as="h3" size="h4">
            Have you showcased your project outcome?
          </Heading>
          <Spacer size={2} />
          <ProjectShowcase
            modalValues={modalValues}
            projectShowcase={projectShowcase}
            handleChange={handleChange}
            handleShowcaseChange={handleShowcaseChange}
          />
          <Spacer size={2} />
          <Heading alignment="left" as="h3" size="h4">
            Upload your work here:
          </Heading>
          <Spacer size={2} />
          <ProjectOutcome
            modalValues={modalValues}
            projectOutcome={projectOutcome}
            handleChange={handleChange}
            handleOutcomeChange={handleOutcomeChange}
          />
          {showValidation && validationError && (
            <Alert variant="danger">
              <div className="d-flex align-items-center gap-5">
                <Icon id="alert-circle" />
                {validationError}
              </div>
            </Alert>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button variant="link" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            setShowValidation(true);
            if (!validationError) {
              handleSave();
            }
          }}
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default ProjectLinkModal;
