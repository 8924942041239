import type { PaymentIntentTypes } from '@polygence/common/types/payment';

import { getTracker } from 'src/utils/tracking/factory';

const trackingCategory = 'payment_page';

export const trackProceed = ({
  uuid,
  selectedProducts,
  paymentMethod,
  pitchedProducts,
}: {
  uuid: string;
  selectedProducts: string[];
  paymentMethod: PaymentIntentTypes;
  pitchedProducts?: string[];
}) => {
  getTracker().track('Proceed with payment clicked', {
    category: trackingCategory,
    paymentIntentUuid: uuid,
    selectedProducts,
    paymentMethod,
  });

  if (pitchedProducts && pitchedProducts.length > selectedProducts.length) {
    trackProceedWithProductsChange({
      uuid,
      removedProducts: pitchedProducts.filter((product) => !selectedProducts.includes(product)),
    });
  }
};

export const trackProceedWithProductsChange = ({
  uuid,
  removedProducts,
}: {
  uuid: string;
  removedProducts: string[];
}) => {
  getTracker().track('Proceed with payment with products removed', {
    category: trackingCategory,
    paymentIntentUuid: uuid,
    removedProducts,
  });
};

export const trackSelectedProductChange = ({
  uuid,
  selectedProducts,
  previousSelectedProducts,
}: {
  uuid: string;
  selectedProducts: string[];
  previousSelectedProducts: string[];
}) => {
  getTracker().track('Selected products changed', {
    category: trackingCategory,
    paymentIntentUuid: uuid,
    previous: previousSelectedProducts,
    new: selectedProducts,
  });
};
