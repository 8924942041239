import type { ApplicationComponentSection } from 'src/students/student-application';
import { counselorSection } from 'src/students/student-application/counselor-section';
import {
  EXPERIMENT_SECTIONS_A,
  EXPERIMENT_SECTIONS_A_PARTNER_PAYS_WORKFLOW,
} from 'src/students/student-application/experiment-sections';
import { financialAidSection } from 'src/students/student-application/financial-aid-section';
import { interestSection } from 'src/students/student-application/interest-section';
import { launchpadPitchSection } from 'src/students/student-application/launchpad-pitch-section';
import {
  OUTPUT_SECTION,
  OUTPUT_SECTION_PARTNER_PAYS_WORKFLOW,
} from 'src/students/student-application/output-sections';
import {
  PARENT_SECTION,
  PARENT_SECTION_PARTNER_PAYS_WORKFLOW,
} from 'src/students/student-application/parent-sections';
import {
  POST_PROJECT_IDEA_SECTIONS_A,
  POST_PROJECT_IDEA_SECTIONS_B,
  POST_PROJECT_IDEA_SECTIONS_C,
} from 'src/students/student-application/post-project-idea-sections';
import {
  PROJECT_IDEA_SECTIONS_A,
  PROJECT_IDEA_SECTIONS_A_PARTNER_PAYS_WORKFLOW,
  PROJECT_IDEA_SECTIONS_B,
} from 'src/students/student-application/project-idea-sections';
import { pronounSection } from 'src/students/student-application/pronoun-section';
import { referralSection } from 'src/students/student-application/referral-section';
import { schoolSection } from 'src/students/student-application/school-section';
import { visualInterviewSection } from 'src/students/student-application/visual-interview-section';

export const studentApplicationStudentPaysWorkflowSections = Object.freeze<
  ApplicationComponentSection[]
>([
  interestSection,
  pronounSection,
  referralSection,
  schoolSection,
  counselorSection,
  PARENT_SECTION,
  financialAidSection,
  OUTPUT_SECTION,
  launchpadPitchSection,
  ...EXPERIMENT_SECTIONS_A,
  ...PROJECT_IDEA_SECTIONS_A,
  ...PROJECT_IDEA_SECTIONS_B,
  ...POST_PROJECT_IDEA_SECTIONS_A,
  ...POST_PROJECT_IDEA_SECTIONS_B,
  ...POST_PROJECT_IDEA_SECTIONS_C,
  visualInterviewSection,
]);

export const studentApplicationPartnerPaysWorkflowSections = Object.freeze<
  ApplicationComponentSection[]
>([
  interestSection,
  pronounSection,
  schoolSection,
  PARENT_SECTION_PARTNER_PAYS_WORKFLOW,
  OUTPUT_SECTION_PARTNER_PAYS_WORKFLOW,
  ...EXPERIMENT_SECTIONS_A_PARTNER_PAYS_WORKFLOW,
  ...PROJECT_IDEA_SECTIONS_A_PARTNER_PAYS_WORKFLOW,
  ...PROJECT_IDEA_SECTIONS_B,
  ...POST_PROJECT_IDEA_SECTIONS_A,
  ...POST_PROJECT_IDEA_SECTIONS_B,
  ...POST_PROJECT_IDEA_SECTIONS_C,
]);
