import type { UserType } from '@polygence/common';
import { axiosBaseQuery } from '@polygence/common/api/fetch';
import type { GuidebookCategory } from '@polygence/common/types/guidebooks';
import { createApi } from '@reduxjs/toolkit/query/react';

import type { ProjectSupportVideo } from 'src/types/guidebooks';

export const guidebookApi = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: 'guidebook',
  endpoints: (build) => {
    return {
      categories: build.query<GuidebookCategory[], UserType[]>({
        query: (userTypes) => {
          const searchParams = new URLSearchParams();
          for (const userType of userTypes) {
            searchParams.append('user_types', userType);
          }

          return `/guidebooks/categories/?${searchParams.toString()}`;
        },
      }),
      projectSupportVideos: build.query<ProjectSupportVideo, string>({
        query: (slug) => {
          return `/guidebooks/project-support-videos/${slug}/`;
        },
      }),
    };
  },
});

export const { useCategoriesQuery, useProjectSupportVideosQuery } = guidebookApi;
