import { axiosBaseQuery } from '@polygence/common/api/fetch';
import type {
  MyPartnerCompany,
  PartnershipManagerInfo,
  PartnerCompany,
} from '@polygence/common/types/partnerships';
import { createApi } from '@reduxjs/toolkit/query/react';

export const partnershipsApi = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: 'partnerships',
  endpoints: (build) => {
    return {
      myCompany: build.query<MyPartnerCompany, void>({
        query: () => {
          return '/partnerships/my-company/';
        },
      }),
      checkPartner: build.query<{ partnership: boolean; partner?: PartnerCompany }, string>({
        query: (storedChannel) => {
          return `/partnerships/check-partner/?partnerTAG=${storedChannel}`;
        },
      }),
      partnershipManagerInfo: build.query<PartnershipManagerInfo, string>({
        query: (email) => {
          return `/partnerships/partnership-manager-info/${email}/`;
        },
      }),
    };
  },
});

export const {
  useLazyMyCompanyQuery,
  useMyCompanyQuery,
  useLazyCheckPartnerQuery,
  usePartnershipManagerInfoQuery,
} = partnershipsApi;
