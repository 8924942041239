import { Icon } from '@polygence/components';
import type { ReactNode } from 'react';

export const CompletedAvatarOverlay = ({
  show,
  children,
}: {
  show: boolean;
  children: ReactNode;
}) => {
  return (
    <div className="position-relative">
      {children}
      {show && (
        <>
          <div className="position-absolute top-0 left-0 w-100 h-100 bg-primary-bg rounded-circle opacity-75" />
          <div className="position-absolute top-0 left-0 w-100 h-100 rounded-circle d-flex justify-content-center align-items-center">
            <Icon color="var(--primary-not-so-dark)" id="check" size="lg" strokeWidth={4} />
          </div>
        </>
      )}
    </div>
  );
};
