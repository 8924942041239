import { dayjs } from 'src/utils/dayjsCustom';

// eslint-disable-next-line import/no-default-export -- autodisabled
export default function showToast(type, frequencyInDays) {
  const toasts = JSON.parse(localStorage.getItem('toasts'));
  if (
    !toasts ||
    !toasts[type] ||
    dayjs.duration(dayjs().diff(dayjs(toasts[type]))).asDays() >= frequencyInDays
  ) {
    localStorage.setItem('toasts', JSON.stringify({ ...toasts, [type]: new Date() }));
    return true;
  }
  return false;
}
