/* eslint-disable fp/no-mutation */
import camelCaseKeys from 'camelcase-keys';
import ReconnectingWebSocket, { type Options } from 'reconnecting-websocket';
import { parse } from 'valibot';

import { getCommonStore } from '../../commonSettings';
import { getAiWebsocketClientUrl } from '../../utils/baseUrls';

import { WebSocketMessage, WebSocketMessageSchema } from './messageTypes';

type MessageEventHandler = (message: WebSocketMessage) => void;
let _aiWs: WebSocketWithSubscriptions | null = null;
const _aiWs_subscriptions: Set<MessageEventHandler> = new Set();

class WebSocketWithSubscriptions extends ReconnectingWebSocket {
  subscribe(subscription: MessageEventHandler) {
    _aiWs_subscriptions.add(subscription);
  }

  unsubscribe(subscription: MessageEventHandler) {
    if (_aiWs_subscriptions.delete(subscription) && _aiWs_subscriptions.size === 0) {
      this.close();
    }
  }
}

export const getConnection = () => {
  if (
    !_aiWs ||
    [ReconnectingWebSocket.CLOSING, ReconnectingWebSocket.CLOSED].includes(_aiWs.readyState)
  ) {
    const token = getCommonStore().getState().user.token;

    // eslint-disable-next-line fp/no-mutation
    _aiWs = new WebSocketWithSubscriptions(getAiWebsocketClientUrl(token ?? ''));

    _aiWs.addEventListener('message', (event: MessageEvent) => {
      try {
        const parsedMessage = parse(
          WebSocketMessageSchema,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          camelCaseKeys(JSON.parse(event.data), {
            deep: true,
          }),
        );
        _aiWs_subscriptions.forEach((callback) => {
          callback(parsedMessage);
        });
      } catch (error) {
        console.error('Failed to parse websocket message', error);
      }
    });

    _aiWs.addEventListener('error', () => {
      if (!_aiWs) {
        return;
      }

      if (
        _aiWs.retryCount >= ((_aiWs as unknown as { _options: Options })._options.maxRetries ?? 3)
      ) {
        _aiWs.close();
      }
    });
  }

  return _aiWs;
};

export const sendMessage = (message: Record<string, unknown>) => {
  const connection = getConnection();
  const messageString = JSON.stringify(message);
  connection.send(messageString);

  if (!_aiWs_subscriptions.size) {
    connection.close();
  }
};
