import type { Nullable } from '@polygence/common';
import { externalLink } from '@polygence/common';
import * as siteContentApi from '@polygence/common/api/site-content';
import { Tooltip, Button } from '@polygence/components';
import type { AxiosError } from 'axios';
import { useState } from 'react';
import { toast } from 'react-toastify';

import type { CaseStudyStatus } from 'src/types/marketplace';
import { getLandingUrl } from 'src/utils';

interface CaseStudyConsiderationButtonProps {
  currentCaseStudyStatus: Nullable<CaseStudyStatus>;
  setCurrentCaseStudyStatus: (param: Nullable<CaseStudyStatus>) => void;
  portfolioItemId: number;
}

const CaseStudyConsiderationButton = ({
  currentCaseStudyStatus,
  setCurrentCaseStudyStatus,
  portfolioItemId,
}: CaseStudyConsiderationButtonProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const isUnderConsideration =
    currentCaseStudyStatus === 'draft' || currentCaseStudyStatus === 'update_request';

  const handleCaseStudyConsideration = () => {
    if (!isUnderConsideration && !isLoading) {
      setIsLoading(true);
      siteContentApi
        .createOrUpdateCaseStudy(portfolioItemId)
        .then(() => {
          if (currentCaseStudyStatus === 'private') {
            setCurrentCaseStudyStatus('draft');
          } else {
            setCurrentCaseStudyStatus('update_request');
          }
          toast.success('Sent for review!');
        })
        .catch((error: AxiosError) => {
          if (error.response && error.response.status === 400) {
            toast.update('apiError', { autoClose: 5000 });
          } else {
            toast.error('Something went wrong updating the project');
          }
        })
        .finally(() => setIsLoading(false));
    }
  };

  const beforeTooltip = (
    <span>
      We proudly showcase student projects as case studies on our public{' '}
      <a className="text-green" href={`${getLandingUrl()}/projects`} {...externalLink}>
        Projects page
      </a>
      . Complete your project portfolio below, then click this button to submit your project for
      case study publication consideration.{' '}
    </span>
  );

  const submittedTooltip =
    'Your submitted project portfolio is under review for case study publication.';

  const publishedTooltip = (
    <span>
      Your project is published on our{' '}
      <a className="text-green" href={`${getLandingUrl()}/projects`} {...externalLink}>
        Projects page
      </a>
      . When you submit a new version, we will review it again. The old version of case study will
      remain published until we accept and replace it with the new version.{' '}
    </span>
  );

  const getTooltip = () => {
    if (!currentCaseStudyStatus || currentCaseStudyStatus === 'private') {
      return beforeTooltip;
    } else if (isUnderConsideration) {
      return submittedTooltip;
    } else {
      return publishedTooltip;
    }
  };

  return (
    <Button
      className="col-4 col-sm-4 col-md-4 w-auto"
      size="sm"
      variant="tertiary"
      onClick={handleCaseStudyConsideration}
      disabled={isUnderConsideration}
    >
      <Tooltip tip={getTooltip()} placement="top" delay={{ show: 0, hide: 1000 }}>
        <span>
          {currentCaseStudyStatus === 'published' || currentCaseStudyStatus === 'update_request'
            ? 'Submit New Version for Consideration'
            : 'Submit for Case Study Consideration'}
        </span>
      </Tooltip>
    </Button>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default CaseStudyConsiderationButton;
