import { externalLink, getLandingUrl } from '@polygence/common';
import { Col, Container, Row } from 'react-bootstrap';

import { UpsellCard } from 'src/students/Pods/UpsellPrograms/UpsellCard';
import explorePod from 'src/students/Pods/assets/explore-pod.svg';
import pursueCore from 'src/students/Pods/assets/pursue-core.svg';
import referAFriend from 'src/students/Pods/assets/refer-a-friend.svg';

export const UpsellCardRow = () => {
  return (
    <Container>
      <Row className="justify-content-center gap-5 gap-xl-0">
        <Col md={6} xl={4}>
          <UpsellCard
            text="Explore our upcoming Pods"
            buttonLabel="Check out a new Pod"
            imgSrc={explorePod}
            redirect={{ href: getLandingUrl('/pods'), ...externalLink }}
          />
        </Col>
        <Col md={6} xl={4}>
          <UpsellCard
            text="Start a unique research project on a topic of your choice"
            buttonLabel="Pursue a Core project"
            imgSrc={pursueCore}
            redirect={{ href: '/student/profile' }}
          />
        </Col>
        <Col md={6} xl={4}>
          <UpsellCard
            text="Share your Polygence experience with your friends!"
            buttonLabel="Refer a friend"
            imgSrc={referAFriend}
            redirect={{ href: '/referral' }}
          />
        </Col>
      </Row>
    </Container>
  );
};
