import * as backendApi from '@polygence/common/api/backend';
import { EMAIL_VALIDATION_RESULT } from '@polygence/common/types/backend';
import React, { useEffect, useState } from 'react';

import mistypedDomain from 'src/utils/emailCheck';

const DEFAULT_VALIDATION_STATE = {
  warning: false,
  email: '',
  message: '',
};

const DEFAULT_WARNING_MESSAGE =
  "This email doesn't seem to be valid. Are you sure you want to continue with it?";

const FormEmailInput = ({ base, value, onChange, onBlur = undefined, ...props }) => {
  const [mistypedValidation, setMistypedValidation] = useState(DEFAULT_VALIDATION_STATE);
  const [emailValidation, setEmailValidation] = useState(DEFAULT_VALIDATION_STATE);

  useEffect(() => {
    props.validateInitialState && validateEmail(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setWarningEmailValidation = (email, message = DEFAULT_WARNING_MESSAGE) => {
    setEmailValidation({ warning: true, email, message });
  };

  const resetEmailValidation = (email) => {
    setEmailValidation({ ...DEFAULT_VALIDATION_STATE, email });
  };

  const setWarningMistypedValidation = (email, message) => {
    setMistypedValidation({ warning: true, email, message });
  };

  const resetMistypedValidation = (email) => {
    setMistypedValidation({ ...DEFAULT_VALIDATION_STATE, email });
  };

  const isWarningResult = (result) => {
    return [EMAIL_VALIDATION_RESULT.RISKY, EMAIL_VALIDATION_RESULT.INVALID].includes(result);
  };

  const validateEmail = (email) => {
    if (mistypedValidation.email !== email) {
      const domainSuggestion = mistypedDomain(email);

      if (domainSuggestion) {
        setWarningMistypedValidation(email, `Did you mean ${domainSuggestion}?`);
      } else {
        resetMistypedValidation(email);
      }
    }

    if (emailValidation.email !== email) {
      backendApi
        .getEmailValidation(email)
        .then(({ data }) => {
          if (isWarningResult(data['result'])) {
            setWarningEmailValidation(email);
          } else {
            resetEmailValidation(email);
          }
        })
        .catch(() => {
          setWarningEmailValidation(email);
        });
    }
  };

  return (
    <>
      {React.createElement(base, {
        type: 'email',
        value,
        onChange: function (...params) {
          if (typeof onChange === 'function') {
            return onChange(...params);
          }
        },
        onBlur: function (...params) {
          validateEmail(value);

          if (typeof onBlur === 'function') {
            return onBlur(...params);
          }
        },
        ...props,
      })}
      {mistypedValidation.warning && (
        <p className="alert alert-warning mt-2">{mistypedValidation.message}</p>
      )}
      {emailValidation.warning && (
        <p className="alert alert-warning mt-2">{emailValidation.message}</p>
      )}
    </>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default FormEmailInput;
