import * as marketplaceApi from '@polygence/common/api/marketplace';
import React, { useEffect, useState } from 'react';
import { Button, Container, Row, Col, Alert } from 'react-bootstrap';

import { BaseTagInput } from 'src/components/admin/components/reusable/BaseTagInput';
import { getCookie, deleteGlobalCookie } from 'src/utils';

const AddInterestTags = () => {
  const [value, setValue] = useState([]);
  const [uuid, setUuid] = useState('');
  const [subjectTags, setSubjectTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filled, setFilled] = useState(false);
  const [error, setError] = useState(false);

  const save = () => {
    setLoading(true);
    marketplaceApi
      .updateProspectiveMentor(uuid, { subjectTags: value })
      .then(() => {
        setFilled(true);
        deleteGlobalCookie('interest-form-uuid');
      })
      .catch(() => {
        return setError(true);
      })
      .finally(() => {
        return setLoading(false);
      });
  };

  useEffect(() => {
    setUuid(getCookie('interest-form-uuid'));

    marketplaceApi
      .getTags('subject_tag', false)
      .then(({ data }) => {
        setSubjectTags(data);
      })
      .catch(() => {
        return setError(true);
      });
    if (uuid) {
      marketplaceApi
        .getProspectiveMentor(uuid)
        .then(({ data: { subjectTags } }) => {
          return setValue(subjectTags);
        })
        .catch(() => {
          return setError(true);
        });
    }
  }, [uuid]);

  return (
    // eslint-disable-next-line react/jsx-filename-extension -- autodisabled
    <Container>
      <Row className="my-huge">
        <Col>
          {error && (
            <Alert variant="danger">
              Something went wrong. Please contact mentors@polygence.org
            </Alert>
          )}
          {filled && (
            <>
              <h2 className="mb-7 font-cormorant polygence-blue">Thank you!</h2>
              <h1>We&apos;ll be in touch shortly.</h1>
            </>
          )}
          {!filled && (
            <h2 className="mb-7 font-cormorant polygence-blue">
              Thank you for booking a meeting with us!
            </h2>
          )}
          {!filled && uuid && (
            <>
              <h1>
                <span role="img" aria-label="mortarboard">
                  🎓
                </span>{' '}
                Please select the subject(s) you specialize in
              </h1>
              <p>
                Type in key words or phrases (e.g. astrophysics, Alzheimer’s disease, NLP, medicine,
                immunology, software, etc)
              </p>
              <BaseTagInput
                name="subject_tags"
                category="subject_tag"
                tags={subjectTags}
                setTags={setSubjectTags}
                value={value}
                onChange={(e) => {
                  return setValue(e.target.value);
                }}
                className="mb-7"
              />
              <Button onClick={save} size="sm" disabled={loading}>
                Save
              </Button>
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default AddInterestTags;
