import classnames from 'classnames';
import React from 'react';
import type { ReactNode, ChangeEventHandler } from 'react';

interface FormInputWithValidationProps {
  type?: string;
  name: string;
  label?: string;
  labelComponent?: ReactNode;
  value?: string | number;
  onError?: string | string[];
  required?: boolean;
  inputType?: string;
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLSelectElement>;
  onBlur?: ChangeEventHandler<HTMLInputElement | HTMLSelectElement>;
  options?: string[];
  small_font?: string;
  id?: string;
  className?: string;
  containerClassName?: string;
  placeholder?: string;
  htmlRequired?: boolean;
  isDisabled?: boolean;
  noRequiredStyle?: boolean;
  rows?: number;
  checked?: boolean;
}

/* eslint-disable react/no-array-index-key */
export const FormInputWithValidation = React.forwardRef(
  (
    {
      onError: errors = [],
      inputType,
      id,
      name,
      options,
      label,
      labelComponent,
      small_font: smallFont,
      required,
      className = '',
      containerClassName = '',
      htmlRequired = false,
      isDisabled = false,
      noRequiredStyle = false,
      placeholder = '',
      ...props
    }: FormInputWithValidationProps,
    ref,
    // eslint-disable-next-line sonarjs/cognitive-complexity
  ) => {
    const showError =
      errors.length > 0 && errors[0] !== undefined && errors[0] !== null && errors[0] !== '';
    const requiredStyle = (required || htmlRequired) && !noRequiredStyle;
    const InputTag = inputType === 'textarea' ? 'textarea' : 'input';
    const computedClassName = className + (showError ? ' is-invalid' : '');
    const domId = id || name;
    const divClass = props.type === 'checkbox' ? 'form-check form-check-inline' : 'form-group';
    const inputClass = props.type === 'checkbox' ? 'form-check-input' : 'form-control';
    return (
      <div className={classnames(divClass, containerClassName)}>
        {label && (
          <label
            htmlFor={domId}
            className={requiredStyle ? 'required' : ''}
            // eslint-disable-next-line react/forbid-dom-props
            style={{ cursor: 'pointer', marginBottom: '-5px' }}
          >
            {label}
          </label>
        )}
        {/* eslint-disable-next-line sonarjs/no-identical-expressions */}
        {labelComponent && labelComponent}
        {smallFont && <div className="text-muted small mb-2">{smallFont}</div>}

        {options && (
          <select id={domId} name={name} className={`form-control ${computedClassName}`} {...props}>
            {options.map((option, idx) => {
              return <option key={idx}>{option}</option>;
            })}
          </select>
        )}
        {!options && (
          <InputTag
            // @ts-expect-error We should fix this in BSH-6711
            ref={ref}
            id={domId}
            name={name}
            disabled={isDisabled}
            className={`${inputClass} ${computedClassName}`}
            required={htmlRequired}
            placeholder={placeholder}
            {...props}
          />
        )}

        {showError && errors.length > 1 && (
          <>
            {Array.isArray(errors) &&
              errors.map((object, idx) => {
                return (
                  <div className="form-validation-error" key={idx}>
                    {object}
                  </div>
                );
              })}
          </>
        )}
        {showError && errors.length === 1 && <div className="text-danger small">{errors}</div>}
      </div>
    );
  },
);

// eslint-disable-next-line fp/no-mutation
FormInputWithValidation.displayName = 'FormInput';
