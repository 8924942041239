import { externalLink, type Nullable } from '@polygence/common';
import { Button, Card, Icon, Heading, Spacer, Text, Tooltip } from '@polygence/components';
import { useState } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { DisplayTags } from 'src/components/admin/components/aux/DisplayTags';
import { dateStamp } from 'src/components/aux/dateStamp';
import { ReactMarkdown } from 'src/components/aux/markdown/ReactMarkdown';
import { RenderIfAnyValue } from 'src/components/common/RenderIfAny';
import CaseStudyConsiderationButton from 'src/components/student/ScholarPage/PortfolioItem/CaseStudyConsiderationButton';
import EditablePortfolioItem from 'src/components/student/ScholarPage/PortfolioItem/EditablePortfolioItem';
import styles from 'src/components/student/ScholarPage/PortfolioItem/portfolioItem.module.sass';
import { ProjectLinkGrid } from 'src/components/student/ScholarPage/ProjectLink/ProjectLinkGrid';
import { useUnpublishCaseStudyMutation } from 'src/reducers/siteContentReducers';
import type {
  CaseStudyStatus,
  ChangingPortfolioItemProps,
  PortfolioItemProps,
} from 'src/types/marketplace';
import { getLandingUrl, isArrayWithItems } from 'src/utils';

const PortfolioItem = ({
  id,
  title = 'Title',
  startedAt,
  image,
  abstract,
  tags,
  outcome,
  projectLinks,
  caseStudyStatus: initalCaseStudyStatus,
  editable,
  mentorReview,
  studentReview,
}: PortfolioItemProps) => {
  const [editing, setEditing] = useState(false);
  const [data, setData] = useState<ChangingPortfolioItemProps>({
    abstract,
    image,
    projectLinks,
    tags,
    outcome,
    title,
    mentorReview,
    studentReview,
  });
  const [unpublishCaseStudy] = useUnpublishCaseStudyMutation();
  const [caseStudyStatus, setCaseStudyStatus] =
    useState<Nullable<CaseStudyStatus>>(initalCaseStudyStatus);

  const handleUnpublishCaseStudy = () => {
    unpublishCaseStudy({ portfolioItemId: id })
      .unwrap()
      .then(() => {
        setCaseStudyStatus('private');
      })
      .catch(() => {
        toast.error('Something went wrong.');
      });
  };

  const UNPUBLISH_TOOLTIP = (
    <span>
      Remove your case study from{' '}
      <a className="text-green" href={`${getLandingUrl()}/projects`} {...externalLink}>
        Polygence’s Project page.
      </a>{' '}
      Note that this should take 24 hours!
    </span>
  );

  if (editing) {
    return (
      <EditablePortfolioItem
        id={id}
        title={data.title}
        image={data.image}
        abstract={data.abstract}
        projectLinks={data.projectLinks}
        tags={data.tags}
        outcome={data.outcome}
        mentorReview={data.mentorReview}
        studentReview={data.studentReview}
        onDataChange={setData}
        setEditing={setEditing}
      />
    );
  }

  return (
    <Card className="p-5 p-md-8">
      <Row className="justify-content-between mb-6">
        <Heading alignment="left" className="col-6 col-sm-4 text-primary" size="h4">
          Project
        </Heading>
        <span className="col-12 col-sm-12 col-md-8 gap-3 d-flex justify-content-end p-0">
          <CaseStudyConsiderationButton
            currentCaseStudyStatus={caseStudyStatus}
            setCurrentCaseStudyStatus={setCaseStudyStatus}
            portfolioItemId={id}
          />
          {caseStudyStatus === 'published' && (
            <Tooltip tip={UNPUBLISH_TOOLTIP} placement="top">
              <Button variant="secondary" size="sm" onClick={handleUnpublishCaseStudy}>
                Unpublish my featured project
              </Button>
            </Tooltip>
          )}
          {editable && (
            <Button
              className="col-3 col-sm-3 col-md-3 w-auto"
              onClick={() => {
                setEditing(true);
              }}
              size="sm"
              startIcon={<Icon id="edit-2" />}
              variant="secondary"
            >
              Edit project
            </Button>
          )}
        </span>
      </Row>
      <Row className="mb-8">
        <Heading as="h1" size="h3">
          {data.title}
        </Heading>
        {startedAt && (
          <Text alignment="center" className="mt-3 text-muted" size="small">
            Started {dateStamp(startedAt)}
          </Text>
        )}
      </Row>
      {data.image && (
        <Row className="mb-8">
          <Image alt={data.title} className={styles['portfolioItemCoverImage']} src={data.image} />
        </Row>
      )}
      {data.abstract && (
        <Row className="mb-10">
          <Heading alignment="left" as="h2" size="h4">
            Abstract or project description
          </Heading>
          <Spacer size={6} />
          <ReactMarkdown>{data.abstract}</ReactMarkdown>
        </Row>
      )}
      {isArrayWithItems(data.tags) && (
        <Row className="mb-10">
          <Heading alignment="left" as="h2" size="h4">
            Project Tags
          </Heading>
          <Spacer size={6} />
          <DisplayTags
            tags={data.tags}
            category="subject_tag"
            label=""
            color="info"
            changeUrl=""
            forceReload={() => {
              window.location.reload();
            }}
            minTagCount={1}
            maxTagCount={3}
          />
        </Row>
      )}
      <RenderIfAnyValue values={[data.studentReview, data.mentorReview]}>
        <Row className="mb-10">
          <Col>
            <Heading alignment="left" as="h2" size="h4">
              Your Polygence Project Review
            </Heading>
          </Col>
        </Row>
      </RenderIfAnyValue>
      <RenderIfAnyValue values={[data.studentReview]}>
        <Row className="mb-10">
          <Col>
            <Heading alignment="left" as="h3" size="h5">
              How was your experience with your project?
            </Heading>
            <Spacer size={6} />
            <Text size="medium">{data.studentReview}</Text>
          </Col>
        </Row>
      </RenderIfAnyValue>
      <RenderIfAnyValue values={[data.mentorReview]}>
        <Row className="mb-10">
          <Col>
            <Heading alignment="left" as="h3" size="h5">
              How was your experience with your mentor?
            </Heading>
            <Spacer size={6} />
            <Text size="medium">{data.mentorReview}</Text>
          </Col>
        </Row>
      </RenderIfAnyValue>
      <RenderIfAnyValue values={[data.outcome]}>
        <Row className="mb-10">
          <Col>
            <Heading alignment="left" as="h2" size="h4">
              Project outcome
            </Heading>
            <Spacer size={6} />
            <Text size="medium">{data.outcome}</Text>
          </Col>
        </Row>
      </RenderIfAnyValue>
      {isArrayWithItems(data.projectLinks) && (
        <Row className="mb-6">
          <Heading alignment="left" as="h2" size="h4">
            Project links
          </Heading>
          <Spacer size={6} />
          <ProjectLinkGrid projectLinks={data.projectLinks} />
        </Row>
      )}
    </Card>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default PortfolioItem;
