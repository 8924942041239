import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { Icon } from 'src/components/Icon';
import joshGraduated from 'src/components/static/images/josh-graduated.svg';
import 'src/components/student/ScholarPage/scholar.sass';

const ScholarListItem = ({ fullName, profilePicture, highSchool, projectTitle, year, url }) => {
  return (
    // eslint-disable-next-line react/jsx-filename-extension -- autodisabled
    <Link to={`/scholars/${url}`} className="d-block">
      <div className="d-flex flex-column justify-content-between pt-5 rounded-corners text-center text-h-grey-dark item">
        <div className="d-flex justify-content-center align-items-end mx-auto mb-3 rounded-circle border border-h-grey-2 overflow-hidden image">
          <div>
            <img src={profilePicture || joshGraduated} alt={fullName} />
          </div>
        </div>
        <div className="mb-2 font-weight-bold">{fullName}</div>
        <div className="mb-3 fs-0_7">{highSchool || 'ㅤ'}</div>
        <div className="d-flex justify-content-center mb-7 py-2 fs-0_7 badge">
          {/* eslint-disable-next-line react/forbid-dom-props -- autodisabled */}
          <span style={{ flex: 1 }}>
            <Icon name="cup" size="16px" local style={{ verticalAlign: 'text-top' }} />
          </span>
          {/* eslint-disable-next-line react/forbid-dom-props -- autodisabled */}
          <span className="my-auto text-start" style={{ flex: 2 }}>
            Polygence Scholar
          </span>
          {/* eslint-disable-next-line react/forbid-dom-props -- autodisabled */}
          <span className="my-auto border-start border-h-grey-dark" style={{ flex: 1 }}>
            {year}
          </span>
        </div>
        <p className="mb-7 mx-3 fs-0_7 text-start">
          {projectTitle ? `Project: “${projectTitle}“` : 'ㅤ'}
        </p>
        <div className="d-block py-3 bg-green-transparent font-weight-bold fs-0_8">
          View project portfolio
        </div>
      </div>
    </Link>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default ScholarListItem;

ScholarListItem.propTypes = {
  fullName: PropTypes.string,
  profilePicture: PropTypes.string,
  highSchool: PropTypes.string,
  projectTitle: PropTypes.string,
  year: PropTypes.string,
  url: PropTypes.string,
};
