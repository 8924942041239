import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from '../../store/store';
import { useCommonSelector } from '../../store/store';
import {
  ProjectCategory,
  ProjectStatus,
  ProjectType,
  WorkspaceDisplay,
  WorkspaceDisplayCategory,
  WorkspacesPerCategory,
  WorkspaceType,
} from '../../types/hermes';
import { dayjs } from '../../utils/dayjsCustom';
import { WORKSPACE_DISPLAY_CATEGORIES } from '../../utils/hermes/workspaceDisplayCategories';

const selectIsMentor = (state: RootState) => state.user.utilities.isMentor;
const selectWorkspaceList = (state: RootState) => state.hermes.workspaceList ?? [];

const projectTypesPerCategories: Record<ProjectCategory, WorkspaceType[]> = {
  Pathfinders: [ProjectType.PATHFINDER_DEPRECATED, ProjectType.PATHFINDER_STANDALONE],
  Launchpad: [ProjectType.PATHFINDER_LAUNCHPAD],
  Reflection: [ProjectType.PATHFINDER_DIAGNOSTIC],
  Projects: [ProjectType.FLAGSHIP, ProjectType.ADVANCED_SCHOLARS_PROGRAM],
  Showcasing: [
    ProjectType.PREMIUM_SHOWCASING_PUBLISHING,
    ProjectType.PREMIUM_SHOWCASING_PRESENTING,
    ProjectType.PREMIUM_SHOWCASING_COMPETING,
    ProjectType.PREMIUM_SHOWCASING_MULTIMEDIA,
  ],
  Pods: [ProjectType.PODS],
  'Research Coach': [ProjectType.RESEARCH_COACH],
};

const shouldArchiveWorkspace = (workspace: WorkspaceDisplay, isMentor: boolean) => {
  if (!isMentor) {
    return false;
  }

  if (
    workspace.type === ProjectType.PATHFINDER_DIAGNOSTIC ||
    dayjs(workspace.lastMessageAt) < dayjs().subtract(1, 'weeks')
  ) {
    return (
      workspace.status &&
      [ProjectStatus.COMPLETE, ProjectStatus.TERMINATED].includes(workspace.status)
    );
  }

  return false;
};

const getCategoryForWorkspace = (workspace: WorkspaceDisplay, isMentor: boolean) => {
  if (shouldArchiveWorkspace(workspace, isMentor)) {
    return WORKSPACE_DISPLAY_CATEGORIES.Completed;
  }

  const category = Object.keys(projectTypesPerCategories).find((key) =>
    projectTypesPerCategories[key as ProjectCategory].includes(workspace.type),
  );

  return (category as WorkspaceDisplayCategory) ?? WORKSPACE_DISPLAY_CATEGORIES.Other;
};

const selectWorkspaceDisplayList = createSelector(
  selectIsMentor,
  selectWorkspaceList,
  (isMentor, workspaceList) => {
    return workspaceList.reduce<{ [key in WorkspaceDisplayCategory]?: WorkspaceDisplay[] }>(
      (workspacesPerCategory, workspace) => {
        const workspaceCategory = getCategoryForWorkspace(workspace, isMentor);

        if (workspacesPerCategory[workspaceCategory]) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- TS can't infer this correctly :(
          workspacesPerCategory[workspaceCategory]!.push(workspace);
        } else {
          // eslint-disable-next-line fp/no-mutation
          workspacesPerCategory[workspaceCategory] = [workspace];
        }

        return workspacesPerCategory;
      },
      {},
    );
  },
);

export const useWorkspaceDisplayList = (): WorkspacesPerCategory[] => {
  const workspacesPerCategory = useCommonSelector(selectWorkspaceDisplayList);

  return Array.from(
    Object.entries(workspacesPerCategory).sort(
      ([categoryA, _workspacesA], [categoryB, _workspacesB]) => {
        return (
          Object.keys(WORKSPACE_DISPLAY_CATEGORIES).indexOf(categoryA) -
          Object.keys(WORKSPACE_DISPLAY_CATEGORIES).indexOf(categoryB)
        );
      },
    ),
    ([key, workspaces]) => ({
      category: key as WorkspaceDisplayCategory,
      workspaces: workspaces.sort(
        (workspaceA, workspaceB) =>
          -dayjs(workspaceA.lastMessageAt).diff(dayjs(workspaceB.lastMessageAt)),
      ),
    }),
  );
};
