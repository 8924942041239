import { createTheme } from '@material-ui/core/styles';
import _ from 'lodash';

// eslint-disable-next-line import/no-default-export -- autodisabled
export default function createPolygenceTheme(extra = {}) {
  return createTheme(
    _.merge(extra, {
      palette: {
        primary: { main: '#253459' },
        secondary: { main: '#02E0C0' },
      },
      spacing: (factor) => {
        return `${0.25 * factor}rem`;
      },
      zIndex: {
        modal: 2000,
      },
      typography: {
        fontFamily: ['Lexend Deca', 'Arial', 'sans-serif'].join(','),
      },
      props: {
        MuiCheckbox: {
          size: 'small',
        },
        MuiRadio: {
          size: 'small',
        },
      },
      overrides: {
        MuiButton: {
          root: {
            borderRadius: '50px',
            padding: '5px 40px',
          },
        },
        MuiCheckbox: {
          root: {
            padding: '5px 9px',
          },
        },
        MuiRadio: {
          root: {
            padding: '5px 9px',
          },
        },
      },
    }),
  );
}
