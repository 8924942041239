import { getRestClient } from '../commonSettings';
import type { UserType } from '../types/backend';
import type { GuidebookPage } from '../types/guidebooks';

import { getQueryParams } from './utils';

export const getGuidebookPages = ({
  search = '',
  userTypes = [],
}: { search?: string; userTypes?: UserType[] } = {}) => {
  const internalFetch = getRestClient();
  return internalFetch.get<GuidebookPage[]>(
    `/guidebooks/pages/?${getQueryParams({ search, userTypes })}`,
  );
};
