import { Alert } from '@polygence/components';

import { PARTNERSHIPS_EMAIL } from 'src/constants/emails';

export const DisabledPartnerAlert = ({ partnerName }: { partnerName: string }) => {
  return (
    <div className="tw-mt-5 tw-flex tw-flex-col tw-justify-center tw-px-5">
      <Alert variant="danger" className="tw-text-center">
        Unfortunately, enrollments from {partnerName} are currently disabled. For more information,
        please contact us at <a href={`mailto:${PARTNERSHIPS_EMAIL}`}>{PARTNERSHIPS_EMAIL}</a>.
      </Alert>
    </div>
  );
};
