import type { BaseQueryFn } from '@reduxjs/toolkit/query/react';
import type { AxiosRequestConfig } from 'axios';
import axios from 'axios';

import { internalFetch } from 'src/fetch';

export const axiosBaseQuery =
  (): BaseQueryFn<
    | {
        url: string;
        method: AxiosRequestConfig['method'];
        data?: AxiosRequestConfig['data'];
        params?: AxiosRequestConfig['params'];
      }
    | string,
    unknown,
    unknown
  > =>
  async (args) => {
    const requestConfig = typeof args === 'string' ? { url: args } : args;
    try {
      const result = await internalFetch(requestConfig);
      return { data: result.data };
    } catch (err) {
      if (axios.isAxiosError(err)) {
        return {
          error: {
            status: err.response?.status,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            data: err.response?.data || err.message,
          },
        };
      }
      throw err;
    }
  };
