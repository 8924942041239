import { Loader } from 'src/components/Loader';
import styles from 'src/components/PageLoad.module.scss';

export const PageLoad = () => {
  return (
    <div className={styles['pageLoad']}>
      <Loader />
    </div>
  );
};
