import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isbetween from 'dayjs/plugin/isBetween';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(localizedFormat);
dayjs.extend(isbetween);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

export { dayjs };
