import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import { Loader } from 'src/components/Loader';
import webAPI from 'src/components/student/webAPI';
import type { PodsFinalSurveySectionId } from 'src/constants/pods-final-survey-sections';
import {
  PODS_FINAL_SURVEY_SECTIONS,
  PodsFinalSurvey,
} from 'src/students/Pods/pods-final-survey-questions';
import MultiPageForm from 'src/students/form/MultiPageForm';
import { validateSectionsAsRequired } from 'src/students/validateSectionsAsRequired';

const ERROR_MESSAGE =
  'Something went wrong. If the issue persists after reloading the page, please contact us at students@polygence.org.';

interface PodsStudentFinalSurveyProps {
  uuid: string;
  handleSubmit: () => void;
}

const parseEventValue = (event: ChangeEvent<HTMLInputElement>) => {
  const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

  if (value === 'true') {
    return true;
  }

  if (value === 'false') {
    return false;
  }

  return value;
};

export const PodsStudentFinalSurvey = ({ uuid, handleSubmit }: PodsStudentFinalSurveyProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState<Partial<PodsFinalSurvey>>({});

  const handleApplicationFieldsChange = (
    event: ChangeEvent<HTMLInputElement>,
    _pageId: PodsFinalSurveySectionId,
  ) => {
    const eventName = event.target.name;
    const eventValue = parseEventValue(event);

    setFormData((prev) => ({
      ...prev,
      [eventName]: eventValue,
    }));
  };

  const updatePodsStudentFinalSurvey = (data: Partial<PodsFinalSurvey>) => {
    return webAPI.updateStudentFinalSurvey(uuid, data);
  };

  const handleApplicationFieldsUpdate = () => {
    return updatePodsStudentFinalSurvey(formData).catch((error) => {
      toast.error(ERROR_MESSAGE);
      return Promise.reject(error);
    });
  };

  const handleApplicationFieldsSubmit = () => {
    return webAPI
      .completeStudentFinalSurvey(uuid, formData)
      .then(() => handleSubmit())
      .catch((error) => {
        toast.error(ERROR_MESSAGE);
        return Promise.reject(error);
      });
  };

  const initialPage = useMemo(() => {
    const firstFail = validateSectionsAsRequired(PODS_FINAL_SURVEY_SECTIONS, formData);
    // Display intro page only if the student hasn't submitted any data
    const firstPage = firstFail === 1 ? 0 : firstFail;

    return firstPage ?? PODS_FINAL_SURVEY_SECTIONS.length - 1;
  }, [formData]);

  useEffect(() => {
    webAPI
      .getStudentFinalSurvey(uuid)
      .then(({ data }) => setFormData(data as Partial<PodsFinalSurvey>))
      .catch((error) => {
        toast.error(ERROR_MESSAGE);
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [uuid]);

  if (isLoading) {
    return <Loader className="mx-auto" />;
  }

  return (
    <MultiPageForm
      data={formData}
      handleChange={handleApplicationFieldsChange}
      pages={PODS_FINAL_SURVEY_SECTIONS}
      initialPage={initialPage}
      BeforeForm={<></>}
      onBeforeNext={handleApplicationFieldsUpdate}
      onBeforeSubmit={() => {
        return Promise.resolve();
      }}
      submitLabel="Next"
      onSubmit={handleApplicationFieldsSubmit}
      onNext={() => {
        return;
      }}
      onPrevious={() => {
        return;
      }}
      addIdsToRouteFragments
      fullScreen={false}
    />
  );
};
