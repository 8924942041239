import { UserType } from '@polygence/common';
import useCurrentUser from '@polygence/common/hooks/selectors/useCurrentUser';

import { useStudentJourneyQuery } from 'src/reducers/marketplaceReducer';

export const useStudentJourney = () => {
  const { userType, profileId } = useCurrentUser();
  return useStudentJourneyQuery(profileId, {
    skip: userType !== UserType.STUDENT || !profileId,
  });
};
