/* eslint-disable react/jsx-max-depth */
import { commonHooks, externalLink, Nullable, StudentScholarPage } from '@polygence/common';
import * as marketplaceApi from '@polygence/common/api/marketplace';
import { Button, Card, cn, Heading, Icon as ComponentsIcon, Tooltip } from '@polygence/components';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import ReactPlayer from 'react-player';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Icon } from 'src/components/Icon';
import { PageLoad } from 'src/components/PageLoad';
import { ReactMarkdown } from 'src/components/aux/markdown/ReactMarkdown';
import { LinkRenderer, HeadingRenderer } from 'src/components/aux/markdown/markdownHelperFunctions';
import possessive from 'src/components/aux/possessive';
import defaultCover from 'src/components/static/images/default-cover.png';
import defaultProfileImage from 'src/components/static/images/generic-profile.jpg';
import EditableImage from 'src/components/student/ScholarPage/EditableImage';
import PortfolioItem from 'src/components/student/ScholarPage/PortfolioItem/PortfolioItem';
import ProjectListItem from 'src/components/student/ScholarPage/ProjectListItem';
import { ScholarBadge } from 'src/components/student/ScholarPage/ScholarBadge';
import ScholarBasicInfo from 'src/components/student/ScholarPage/ScholarBasicInfo';
import { urls } from 'src/urls';
import { navigateTo } from 'src/utils/navigateTo';

import 'src/components/student/ScholarPage/scholar.sass';

export const ScholarPage = () => {
  const history = useHistory();
  const { slug } = useParams<{ slug: string }>();
  const [pageData, setPageData] = useState<Nullable<StudentScholarPage>>(null);
  const [editingCover, setEditingCover] = useState(false);
  const [count, setCount] = useState(0);
  const currentUser = commonHooks.useCurrentUser();

  const forceReload = () => {
    setCount((prev) => {
      return prev + 1;
    });
  };

  useEffect(() => {
    marketplaceApi
      .getScholarPage(slug)
      .then((response) => {
        setPageData(response.data);
      })
      .catch(() => {
        navigateTo(urls.symposiumRegistration());
      });
  }, [slug, count]);

  if (!pageData) {
    return <PageLoad />;
  }

  const {
    fullName,
    firstName,
    coverImage,
    profilePicture,
    highSchool,
    gradYear,
    url,
    previewKey,
    portfolioItems = [],
    published,
    presentationUrl,
    about,
    studentLocation = '',
    polygenceGraduationYear,
  } = pageData;

  const editable = currentUser.id === pageData?.userId;

  const handlePublish = () => {
    marketplaceApi
      .updateScholarPage(slug, { published: !published })
      .then(({ data: { published: updatedPublished } }) => {
        forceReload();
        toast.success(`Page ${updatedPublished ? 'published' : 'unpublished'}!`);
      })
      .catch(() => {
        toast.error('Something went wrong.');
      });
  };

  const description = `${firstName} has been selected to present at the Fifth Polygence Symposium of Rising Scholars to be held on September 18, 2021, 8am PT - 1pm PT. Register for the presentation of ${firstName}`;
  const projectsTooltip =
    'Your Project Title will automatically appear here once it is determined between you and your mentor!';
  const publishTooltip = (
    <span>
      Publish your Scholar Page to{' '}
      <a
        className="text-green"
        href="https://www.polygence.org/scholars"
        target="_blank"
        rel="noreferrer"
      >
        Polygence's Scholar Webpage.
      </a>
    </span>
  );
  const unPublishTooltip = (
    <span>
      Remove your Scholar Page from{' '}
      <a
        className="text-green"
        href="https://www.polygence.org/scholars"
        target="_blank"
        rel="noreferrer"
      >
        Polygence’s Scholars Webpage.
      </a>{' '}
      Note that this should take 24 hours!
    </span>
  );
  const previewTooltip = (
    <span>
      Preview what your Scholar Page will look like once published on{' '}
      <a
        className="text-green"
        href="https://www.polygence.org/scholars"
        target="_blank"
        rel="noreferrer"
      >
        Polygence’s Scholar Webpage.
      </a>
    </span>
  );
  const editProfileTooltip = `Update your Profile Picture, About Information, and Location here.`;

  return (
    <>
      <Helmet>
        <title>{fullName} - Polygence Scholar</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={`Register for the presentation of ${firstName}`} />
        <meta property="og:description" content={description} />
        <meta
          name="keywords"
          content="virtual,online,research,opportunities,program,high school,students,science,summer,symposium,conference,scholar"
        />
        <meta
          property="og:image"
          content="https://dpl6hyzg28thp.cloudfront.net/media/symposium_banner.png"
        />
        <meta property="og:url" content={`https://app.polygence.org/scholars/${url}`} />
        <meta property="og:type" content="website" />
      </Helmet>
      <Container fluid className="bg-lightblue">
        <Container as="main" className="scholar py-7">
          <Card className="header overflow-hidden mb-5">
            <div className="cover">
              <EditableImage
                src={coverImage || defaultCover}
                editing={editingCover}
                alt={`${fullName}'s Polygence scholar profile`}
                reload={forceReload}
                name="cover_image"
                uploadUrl={`/api/scholars/${slug}/`}
                removeUrl={`/api/scholars/${slug}/cover`}
              />
              <div className="d-none d-sm-block">
                <ScholarBadge polygenceGraduationYear={polygenceGraduationYear} />
              </div>
              <div className="position-absolute d-flex flex-wrap gap-4 justify-content-between px-7 w-100 top-button-container">
                {editable && !editingCover && (
                  <>
                    <div className="d-flex flex-row gap-4">
                      <Tooltip
                        tip={published ? unPublishTooltip : publishTooltip}
                        placement="top"
                        delay={{ show: 0, hide: 1000 }}
                      >
                        <Button variant="primary" size="sm" onClick={handlePublish}>
                          {published ? 'Unpublish My Scholar Page' : 'Publish My Scholar Page'}
                        </Button>
                      </Tooltip>
                      <Tooltip tip={previewTooltip} placement="top" delay={{ show: 0, hide: 1000 }}>
                        <Button
                          variant="secondary"
                          size="sm"
                          href={urls.previewScholarPage(url, previewKey)}
                          {...externalLink}
                        >
                          Preview My Scholar Page
                        </Button>
                      </Tooltip>
                    </div>
                    <Button
                      variant="secondary"
                      size="sm"
                      startIcon={<ComponentsIcon id="edit-2" />}
                      onClick={() => {
                        setEditingCover(true);
                      }}
                    >
                      <span className="text-nowrap">Edit image</span>
                    </Button>
                  </>
                )}
                {editingCover && (
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={() => {
                      setEditingCover(false);
                    }}
                  >
                    Save
                  </Button>
                )}
              </div>
            </div>
            <Row className="scholar-profile-container">
              <Col xs={12} lg={4} xl={3}>
                <div
                  className={cn('profile-picture-container circle green-outline mx-5 mx-md-5', {
                    'd-none': editingCover,
                  })}
                >
                  <img
                    src={profilePicture || defaultProfileImage}
                    alt={fullName}
                    className="profile-picture circle white-outline"
                  />
                  {editable && (
                    <Link className="p-3 circle bg-green edit-profile-picture" to="/user-settings">
                      <Icon name="pencil" color="black" local />
                    </Link>
                  )}
                </div>
              </Col>
              <Col xs={12} lg={8} xl={9} className="name mt-12 mt-md-5 mx-5 mx-md-5 mx-lg-0">
                <ScholarBasicInfo
                  fullName={fullName}
                  highSchool={highSchool}
                  gradYear={gradYear}
                  location={studentLocation}
                />
                <Tooltip tip={editProfileTooltip} placement="top">
                  <Button
                    variant="secondary"
                    size="sm"
                    startIcon={<ComponentsIcon id="edit-2" />}
                    className="position-absolute edit-button edit-profile"
                    onClick={() => {
                      history.push('/user-settings');
                    }}
                  >
                    Edit profile
                  </Button>
                </Tooltip>
              </Col>
            </Row>
            <Row className="px-5 px-md-5">
              <Col xs={12} lg={6} className="mb-7">
                <Row className="d-flex flex-row justify-content-between">
                  <Col xs={5} sm={8} className="px-0 mb-5">
                    <Heading alignment="left" size="h4">
                      About
                    </Heading>
                  </Col>
                  <ReactMarkdown components={{ a: LinkRenderer, h1: HeadingRenderer }}>
                    {about}
                  </ReactMarkdown>
                </Row>
              </Col>
              <Col xs={12} lg={6}>
                <Col xs={5} sm={8} className="mb-5">
                  <Tooltip tip={projectsTooltip} placement="top">
                    <Heading alignment="left" size="h4">
                      Projects
                    </Heading>
                  </Tooltip>
                </Col>
                <ul>
                  {portfolioItems.map((item) => {
                    return (
                      <div className="mb-5" key={item.id}>
                        <ProjectListItem key={item.id} item={item} />
                      </div>
                    );
                  })}
                </ul>
              </Col>
            </Row>
          </Card>
          {presentationUrl && (
            <section className="bg-white rounded-corners d-flex flex-wrap justify-content-center p-sm-5 my-5 ps-md-5">
              <h3 className="text-primary w-100 mb-7">
                {possessive(firstName)} Symposium Presentation
              </h3>
              <ReactPlayer url={presentationUrl} controls />
            </section>
          )}
          {portfolioItems.map((item) => {
            return (
              <div className="mb-5 w-100" key={item.id}>
                <PortfolioItem {...item} editable={editable} />
              </div>
            );
          })}
        </Container>
      </Container>
    </>
  );
};
