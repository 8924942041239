import { useEffect } from 'react';

export const useAppHeight = () => {
  useEffect(() => {
    const setAppHeight = () => {
      document.documentElement.style.setProperty('--appHeight', `${window.innerHeight}px`);
    };
    setAppHeight();
    window.addEventListener('resize', setAppHeight);

    return () => {
      window.removeEventListener('resize', setAppHeight);
    };
  }, []);
};

export const AppHeight = () => {
  useAppHeight();

  return null;
};
