import { InputField, TextFieldProps } from '@polygence/components';
import { useEffect, useState } from 'react';

import { REGEX_VALIDATORS } from 'src/components/constants';

type FormURLInputProps = Pick<
  TextFieldProps,
  | 'label'
  | 'name'
  | 'value'
  | 'onChange'
  | 'onBlur'
  | 'floating'
  | 'validateInitialState'
  | 'errorMessage'
> & { updateValidationError: (arg: boolean) => void };

const DEFAULT_VALIDATION_STATE = {
  warning: false,
  url: '',
  message: '',
};

const DEFAULT_WARNING_MESSAGE =
  'The provided URL is invalid. The URL should start with “http(s)://“ e.g. https://example.org';

export const FormURLInput = ({
  label,
  name,
  value,
  onChange,
  updateValidationError,
  floating = false,
  validateInitialState = false,
}: FormURLInputProps) => {
  const [urlValidation, setUrlValidation] = useState(DEFAULT_VALIDATION_STATE);

  useEffect(() => {
    validateInitialState && validateRegex(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateRegex = (url: string | undefined) => {
    const regex = new RegExp(REGEX_VALIDATORS.URL);
    if (url && !regex.test(url)) {
      setWarningUrlValidation(url);
      updateValidationError(true);
    } else {
      resetUrlValidation(url);
    }
  };

  const setWarningUrlValidation = (url: string, message = DEFAULT_WARNING_MESSAGE) => {
    setUrlValidation({ warning: true, url, message });
  };

  const resetUrlValidation = (url = '') => {
    setUrlValidation({ ...DEFAULT_VALIDATION_STATE, url });
    updateValidationError(false);
  };

  return (
    <>
      <InputField
        label={label}
        name={name}
        type="url"
        value={value}
        onChange={(event) => {
          validateRegex(value);
          if (typeof onChange === 'function') {
            return onChange(event);
          }
        }}
        onBlur={() => {
          validateRegex(value);
        }}
        isInvalid={urlValidation.warning}
        floating={floating}
        errorMessage={urlValidation.message}
      />
    </>
  );
};
