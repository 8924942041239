import { createContext, useContext, useEffect, useMemo, useState, PropsWithChildren } from 'react';

export const DEFAULT_HOME_URL = '/';

export interface NavigationBarContextValue {
  homeUrl: string;
  navItemsVisible: boolean;
  setHomeUrl: (homeUrl: string) => void;
  setNavItemsVisible: (visible: boolean) => void;
}

export const NavigationBarContext = createContext<NavigationBarContextValue | undefined>(undefined);

export const NavigationBarContextProvider = ({ children }: PropsWithChildren) => {
  const [homeUrl, setHomeUrl] = useState(DEFAULT_HOME_URL);
  const [navItemsVisible, setNavItemsVisible] = useState(true);

  const contextValue = useMemo(
    () => ({ homeUrl, navItemsVisible, setHomeUrl, setNavItemsVisible }),
    [homeUrl, navItemsVisible, setHomeUrl, setNavItemsVisible],
  );

  return (
    <NavigationBarContext.Provider value={contextValue}>{children}</NavigationBarContext.Provider>
  );
};

export const useNavigationBarContext = () => {
  const context = useContext(NavigationBarContext);
  if (!context) {
    throw new Error('useNavigationBarContext must be used within an NavigationBarContextProvider');
  }

  return context;
};

// export hook as component for legacy class component usage :(
export const CustomNavigationBarHomeUrl = ({ homeUrl }: { homeUrl: string }) => {
  const { setHomeUrl } = useNavigationBarContext();

  useEffect(() => {
    setHomeUrl(homeUrl);
    return () => setHomeUrl(DEFAULT_HOME_URL);
  }, [homeUrl, setHomeUrl]);

  return null;
};
