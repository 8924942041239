import type { Nullable, School } from '@polygence/common';
import * as marketplaceApi from '@polygence/common/api/marketplace';
import type { OptionType } from '@polygence/components';

import { AsyncSelectWithOther } from 'src/components/AsyncSelectWithOther';
import type { SchoolTypes, SchoolChangeTarget } from 'src/types/common';

interface SchoolSelectorProps {
  label: string;
  name: SchoolTypes;
  value: Nullable<OptionType>;
  otherLabel?: string;
  otherName: SchoolTypes;
  otherValue: string | null;
  placeholder?: string;
  defaultOptions?: boolean;
  onChange: (event: SchoolChangeTarget) => void | null;
}

const getListOfSchools = (search: string) =>
  marketplaceApi.getListOfSchools(search).then(({ data: { results } }) => results);

export const SchoolSelector = ({
  label,
  name,
  value,
  otherLabel = 'Please specify:',
  otherName,
  otherValue,
  placeholder = 'Select...',
  defaultOptions = true,
  onChange: handleChange,
}: SchoolSelectorProps) => {
  return (
    <AsyncSelectWithOther
      mapOptions={({ id, name, city }: School) => ({
        value: id,
        label: `${name} (${city})`,
      })}
      loadSource={getListOfSchools}
      label={label}
      name={name}
      placeholder={placeholder}
      defaultValue={value}
      otherName={otherName}
      otherLabel={otherLabel}
      otherValue={otherValue}
      onChange={handleChange}
      defaultOptions={defaultOptions}
    />
  );
};
