import NiceModal from '@ebay/nice-modal-react';
import { commonHooks, externalLink } from '@polygence/common';
import * as marketplaceApi from '@polygence/common/api/marketplace';
import { Badge, Button, Heading, Icon, Text } from '@polygence/components';
import classNames from 'classnames';
import { useEffect } from 'react';

import { CounselorOnboardingVideoModal } from 'src/components/Tiles/Counselor/CounselorOnboardingVideoModal';
import styles from 'src/components/Tiles/Counselor/PamsTile.module.scss';
import tileStyles from 'src/components/Tiles/tilesDashboard.module.scss';
import {
  useMyCompanyQuery,
  usePartnershipManagerInfoQuery,
} from 'src/reducers/partnershipsReducer';

export const PamsTile = () => {
  const {
    firstName,
    otherInfo: { pamEmail },
    welcomeVideoSeenAt,
  } = commonHooks.useCurrentUser();

  const { data: pamInfo } = usePartnershipManagerInfoQuery(pamEmail as string);
  const { data: myCompany } = useMyCompanyQuery();

  useEffect(() => {
    if (!welcomeVideoSeenAt && pamInfo?.onboardingVideo) {
      NiceModal.show(CounselorOnboardingVideoModal, {
        onboardingVideo: pamInfo.onboardingVideo,
        firstName: pamInfo.firstName,
      }).catch(console.error);

      marketplaceApi
        .updateCounselorProfile({ welcomeVideoSeenAt: new Date().toISOString() })
        .catch(console.error);
    }
  }, [pamInfo?.onboardingVideo, welcomeVideoSeenAt, pamInfo?.firstName]);

  return (
    <div
      className={classNames(
        tileStyles['tile'],
        styles['pamsTile'],
        'd-flex flex-column justify-content-evenly p-8 gap-6',
      )}
    >
      {myCompany?.arrangementExternalName && (
        <Text size="large" fontWeight="semibold" className="mb-7">
          Your Partner Benefit:{' '}
          <Badge variant="primary" size="medium">
            {myCompany.arrangementExternalName}
          </Badge>
        </Text>
      )}
      <Text size="medium" fontWeight="semibold" className="text-primary text-uppercase">
        Welcome, {firstName}!
      </Text>
      {pamInfo && (
        <>
          <div className="d-flex flex-row">
            <div className="d-flex flex-column gap-12">
              <Heading size="h4" as="h4" fontWeight="bold" alignment="left">
                I'm {pamInfo.firstName}, your Partnerships Manager here at Polygence.
              </Heading>
              <Text size="medium" fontWeight="normal">
                Got questions about our programs, platform, or Partnership Arrangement? Feel free to
                schedule a call with me anytime!
              </Text>
            </div>
            <div className={styles['image']}>
              <img src={pamInfo.imageUrl} alt="pam" />
            </div>
          </div>
          <div className="d-flex gap-3">
            <Button
              variant="primary"
              size="lg"
              className="align-self-start py-3 px-9"
              href={pamInfo.meetingUrl}
              {...externalLink}
            >
              Schedule a call
            </Button>
            {pamInfo.onboardingVideo && (
              <Button
                startIcon={<Icon id="video" />}
                onClick={() => {
                  void NiceModal.show(CounselorOnboardingVideoModal, {
                    onboardingVideo: pamInfo.onboardingVideo!,
                    firstName: pamInfo.firstName,
                  });
                }}
              >
                Watch welcome video
              </Button>
            )}
          </div>
        </>
      )}
    </div>
  );
};
