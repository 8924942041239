import { useState, useCallback } from 'react';
import sha1 from 'sha1';

import { useCommonDispatch, useCommonSelector } from '../store/store';
import { commonThunks } from '../store/thunks/index';
import type { FileUploadFile } from '../types/hermes';
import { MessageType } from '../types/hermes';
import { websocketApi } from '../websocket/index';

import useCurrentUser from './selectors/useCurrentUser';
import { useRoomById } from './selectors/useRooms';

// eslint-disable-next-line import/no-default-export -- autodisabled
export default function useChat(roomId: number) {
  const [page, setPage] = useState(2);
  const dispatch = useCommonDispatch();
  const currentUser = useCurrentUser();
  const loadingMessages = useCommonSelector((state) => state.hermes.loadingMessages);

  const room = useRoomById(roomId);
  const { messages = [], messagesCount = 0 } = room ?? {};
  const canLoadMoreMessages = messages.length < messagesCount;

  const getMessageId = useCallback((message: string) => {
    return sha1(`${message}-${Math.random() * 1000}-${new Date().getTime()}`);
  }, []);

  const sendMessagesSeen = () => {
    websocketApi.sendMessagesSeen({ roomId });
  };

  const getNextMessages = useCallback(() => {
    if (!canLoadMoreMessages) {
      return;
    }
    void dispatch(commonThunks.hermesThunks.getMessagesForRoom({ roomId: roomId, page }));
    setPage((current) => {
      return current + 1;
    });
  }, [dispatch, roomId, page, canLoadMoreMessages]);

  const sendMessage = useCallback(
    (message: string, files: FileUploadFile[]) => {
      websocketApi.sendMessage({
        attachments: files,
        localMessageId: getMessageId(message),
        message: message,
        messageType: MessageType.MARKDOWN,
        roomId: roomId,
        sentBy: {
          id: currentUser.id ?? -1,
        },
      });
    },
    [roomId, getMessageId, currentUser?.id],
  );

  const sendGif = useCallback(
    (gif: string) => {
      websocketApi.sendMessage({
        attachments: [],
        localMessageId: getMessageId(gif),
        message: gif,
        messageType: MessageType.GIF,
        roomId,
        sentBy: {
          id: currentUser.id ?? -1,
        },
        resend: false,
      });
    },
    [roomId, getMessageId, currentUser.id],
  );

  if (!room) {
    return null;
  }

  return {
    sendMessage,
    sendGif,
    getNextMessages,
    room,
    loadingMessages,
    sendMessagesSeen,
  };
}
