import { Button, Icon, Spacer } from '@polygence/components';
import classNames from 'classnames';
import type { CSSProperties } from 'react';
import { useSize, useToggle } from 'react-use';

import styles from 'src/components/common/LimitedHeightExpandable.module.scss';

export const LimitedHeightExpandable = ({
  maxHeight,
  children,
}: {
  maxHeight: number;
  children: JSX.Element;
}) => {
  const [isExpanded, toggleIsExpanded] = useToggle(false);
  const [sized, { height }] = useSize(children);

  const isExpandable = height > maxHeight;

  return (
    <div
      // eslint-disable-next-line react/forbid-dom-props
      style={{ '--max-height': `${maxHeight}px` } as CSSProperties}
      className={classNames('position-relative', { [styles['maxHeight'] as string]: !isExpanded })}
    >
      {sized}
      {isExpandable && isExpanded && <Spacer size={9} />}
      {isExpandable && (
        <div className={styles['overlay']}>
          <Button
            className="m-auto"
            variant="link"
            size="sm"
            onClick={toggleIsExpanded}
            endIcon={<Icon id={`chevron-${isExpanded ? 'up' : 'down'}`} />}
          >
            Show {isExpanded ? 'less' : 'more'}
          </Button>
        </div>
      )}
    </div>
  );
};
