import type { GuidebookPage } from '@polygence/common/types/guidebooks';
import classnames from 'classnames';
import { Pagination, Mousewheel } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import GuidebookCard from 'src/components/Guidebook/GuidebookCard';
import styles from 'src/components/Guidebook/guidebook.module.sass';
import 'swiper/css';
import 'swiper/css/pagination';

interface GuidebookSwiperProps {
  cards: GuidebookPage[];
}

export const GuidebookSwiper = ({ cards }: GuidebookSwiperProps) => {
  const swiperSlides = cards.map((card) => {
    return (
      <SwiperSlide key={card.id} className={styles['swiper-slide']}>
        <GuidebookCard title={card.title} url={card.url} cardImage={card.cardImage} />
      </SwiperSlide>
    );
  });

  return (
    <div className={classnames('mb-5 mb-sm-12', styles['swiper-container'])}>
      <Swiper
        className={classnames('pb-10 pb-sm-11 pt-6 pt-sm-8', styles['swiper'])}
        effect="coverflow"
        modules={[Pagination, Mousewheel]}
        spaceBetween={20}
        slidesPerView="auto"
        grabCursor
        mousewheel={{
          forceToAxis: true,
        }}
        pagination={{
          clickable: true,
          dynamicBullets: true,
        }}
      >
        {swiperSlides}
      </Swiper>
    </div>
  );
};
