import { ProjectType, type ProjectTypes } from '@polygence/common/types/hermes';
import type { PSSMatchingDeclineReason } from '@polygence/common/types/marketplace';
import { ChangeEvent, FormEvent, useState } from 'react';

import NumberSelector from 'src/components/CTA/sessionForm/NumberSelector';
import { SpinningWheel } from 'src/components/SpinningWheel';
import { DatePickerWithLabel } from 'src/components/common/DatePickerWithLabel';
import { isPSSTrackProjectType } from 'src/components/hermes/utils';

const ACCEPT = 'accept';
const REJECT = 'reject';
const DECLINE = 'decline';

type Responses = typeof ACCEPT | typeof REJECT | typeof DECLINE;

interface HandleReasonChangeEvent {
  target: { value: string | number | null; checked?: boolean; type?: string; name: string };
}

interface FeedbackFormProps {
  uuid: string;
  response: Responses;
  projectType: ProjectTypes;
  updateResponse: (
    uuid: string,
    response: Responses | undefined,
    payload: { matchRating: number | undefined; reason: ReasonProps },
  ) => Promise<unknown>;
}

interface ReasonProps extends PSSMatchingDeclineReason {
  no_good_fit?: boolean;
}

interface RejectFormFieldsProps {
  reason: ReasonProps;
  setMatchRating: (arg: number | undefined) => void;
  handleReasonChange: (event: HandleReasonChangeEvent) => void;
  handleTextAreaReasonChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  projectType: ProjectTypes;
}

export const FeedbackForm = ({
  uuid,
  response,
  projectType,
  updateResponse,
}: FeedbackFormProps) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [responseRecorded, setResponseRecorded] = useState(false);
  const [reason, setReason] = useState({});
  const [matchRating, setMatchRating] = useState<number | undefined>();

  const handleReasonChange = (event: HandleReasonChangeEvent) => {
    const { value, checked } = event.target;
    const isCheckbox = event.target.type === 'checkbox';

    setReason({ ...reason, [event.target.name]: isCheckbox ? checked : value });
  };

  const handleTextAreaReasonChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setReason({ ...reason, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const payload: { matchRating: number | undefined; reason: ReasonProps } = {
      matchRating,
      reason,
    };
    if (response === REJECT || response === DECLINE) {
      // eslint-disable-next-line fp/no-mutation -- autodisabled
      payload.reason = reason;
    }

    const updatedResponsePromise = isPSSTrackProjectType(projectType)
      ? updateResponse(uuid, response, payload)
      : updateResponse(uuid, undefined, payload);

    updatedResponsePromise
      .then(() => {
        return setResponseRecorded(true);
      })
      .catch(() => {
        return setError(true);
      })
      .finally(() => {
        return setLoading(false);
      });
  };

  return (
    <>
      {loading && <SpinningWheel />}
      {error && (
        <div className="alert alert-danger">
          Something went wrong. Please contact us at{' '}
          <a href="mailto:mentors@polygence.org">mentors@polygence.org</a>.
        </div>
      )}
      {responseRecorded && <p>Thank you!</p>}
      {!loading && !responseRecorded && !error && (
        <form onSubmit={handleSubmit}>
          {response === ACCEPT && projectType === ProjectType.FLAGSHIP && (
            <MatchRatingField setMatchRating={setMatchRating} />
          )}
          {(response === REJECT || response === DECLINE) && (
            <RejectFormFields
              setMatchRating={setMatchRating}
              handleReasonChange={handleReasonChange}
              handleTextAreaReasonChange={handleTextAreaReasonChange}
              reason={reason}
              projectType={projectType}
            />
          )}
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </form>
      )}
    </>
  );
};

const RejectFormFields = ({
  setMatchRating,
  handleReasonChange,
  handleTextAreaReasonChange,
  reason,
  projectType,
}: RejectFormFieldsProps) => {
  return (
    <>
      <p>
        No problem! Can you let us know why you declined working with this student so we can do a
        better job next time? Thank you!
      </p>
      {projectType === ProjectType.FLAGSHIP && (
        <div className="form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="no_good_fit"
            name="no_good_fit"
            onChange={handleReasonChange}
            checked={reason.no_good_fit}
          />
          <label className="form-check-label" htmlFor="no_good_fit">
            Student didn’t seem like a good fit for me
          </label>
        </div>
      )}
      {projectType === ProjectType.PREMIUM_SHOWCASING_PUBLISHING && (
        <>
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="no_good_fit_subject_area"
              name="no_good_fit_subject_area"
              onChange={handleReasonChange}
              checked={reason.no_good_fit_subject_area}
            />
            <label className="form-check-label" htmlFor="no_good_fit_subject_area">
              The subject area of this student’s project isn’t a good fit for my expertise
            </label>
          </div>
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="no_good_fit_showcasing_goal"
              name="no_good_fit_showcasing_goal"
              onChange={handleReasonChange}
              checked={reason.no_good_fit_showcasing_goal}
            />
            <label className="form-check-label" htmlFor="no_good_fit_showcasing_goal">
              The student’s showcasing goals aren’t a good fit for my expertise
            </label>
          </div>
        </>
      )}
      <div className="form-check">
        <input
          type="checkbox"
          className="form-check-input"
          id="no_bandwidth"
          name="no_bandwidth"
          onChange={handleReasonChange}
          checked={reason.no_bandwidth}
        />
        {projectType === ProjectType.FLAGSHIP ? (
          <label className="form-check-label" htmlFor="no_bandwidth">
            I don’t have the bandwidth during that time frame
          </label>
        ) : (
          <label className="form-check-label" htmlFor="no_bandwidth">
            I do not currently have the bandwidth to work with this student
          </label>
        )}
      </div>
      <div className="form-check">
        <input
          type="checkbox"
          className="form-check-input"
          id="timezone_issue"
          name="timezone_issue"
          onChange={handleReasonChange}
          checked={reason.timezone_issue}
        />
        <label className="form-check-label" htmlFor="timezone_issue">
          The student’s timezone would make scheduling sessions hard.
        </label>
      </div>
      {reason.no_bandwidth && (
        <DatePickerWithLabel
          id="unavailable_until"
          name="unavailable_until"
          label="When will you have more bandwidth to mentor?"
          value={reason.unavailable_until}
          onChange={handleReasonChange}
        />
      )}
      <MatchRatingField setMatchRating={setMatchRating} />
      <div className="form-group">
        <label htmlFor="other">Any other things we should know?</label>
        <textarea
          className="form-control"
          id="other"
          name="other"
          rows={5}
          onChange={handleTextAreaReasonChange}
          value={reason.other}
        />
      </div>
    </>
  );
};

const MatchRatingField = ({
  setMatchRating,
}: {
  setMatchRating: (arg: number | undefined) => void;
}) => {
  return (
    <div className="mb-3">
      <label htmlFor="match_rating">
        We are constantly improving how we match students to mentors. On a scale of 0-10, how would
        you rate this match?{' '}
      </label>
      {/* eslint-disable-next-line react/forbid-dom-props -- autodisabled */}
      <div style={{ maxWidth: '500px' }}>
        <NumberSelector
          onChange={setMatchRating}
          startFrom={0}
          numberCount={11}
          defaultValue={undefined}
          className={undefined}
          name={undefined}
        />
      </div>
    </div>
  );
};
