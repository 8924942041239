import type { Session } from '../../types/hermes';
import type { AtLeast } from '../../types/utils';

import useSelectedProject from './useSelectedProject';

export function useSessionBySessionId(sessionId: number): Session | null {
  const selectedProject = useSelectedProject();

  if (!sessionId) {
    return null;
  }

  return (
    selectedProject?.sessions?.find((session) => {
      return session.id === sessionId;
    }) || null
  );
}

export function useSessionBySessionIdOrNotNull(
  sessionId: number,
): AtLeast<Session, 'sessionNumber'> {
  const session = useSessionBySessionId(sessionId);
  if (session == null) {
    return { sessionNumber: -1 };
  }
  return session;
}
