/* eslint-disable react/prop-types */
import { Select } from '@polygence/components';
import React, { useState, useEffect } from 'react';

import { FormInputWithValidation as FormInput } from 'src/components/FormInputWithValidation';

const SelectWithOther = ({
  options,
  helpText = '',
  label,
  otherLabel = 'Please specify:',
  name,
  otherName,
  value,
  otherValue,
  onChange: handleChange,
  onError = {},
  required = false,
  otherRequired = true,
  async = false,
  addOtherOption = true,
  isClearable = true,
}) => {
  const [internalOptions, setInternalOptions] = useState(
    addOtherOption ? [{ label: 'Other', value: 'other' }] : [],
  );
  const [internalValue, setInternalValue] = useState();

  useEffect(() => {
    if (async) {
      options.then((result) => {
        setInternalOptions([...result, ...internalOptions]);
      });
    } else {
      setInternalOptions([...options, ...internalOptions]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  useEffect(() => {
    setInternalValue(
      internalOptions.find((e) => {
        return e.value === value;
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalOptions]);

  const error = onError[name];
  const otherError = onError[otherName];

  const handleOnChange = (event, fieldName) => {
    const { value = null, label } = event || {};
    const { name } = fieldName;

    if (event) {
      handleChange({ target: { name, value } });
      setInternalValue({ value, label });
      return;
    }

    handleChange({ target: { name, value } });
    setInternalValue(undefined);
  };

  return (
    // eslint-disable-next-line react/jsx-filename-extension -- autodisabled
    <div className="form-group">
      <label
        htmlFor={name}
        className={required ? 'required' : ''}
        // eslint-disable-next-line react/forbid-dom-props -- autodisabled
        style={{ cursor: 'pointer', marginBottom: 0 }}
      >
        {label}
      </label>
      {helpText && <div className="text-muted small">{helpText}</div>}
      <Select
        inputId={name}
        name={name}
        value={internalValue}
        isClearable={isClearable}
        defaultValue={
          async
            ? null
            : options.find((e) => {
                return e.value === value;
              })
        }
        options={internalOptions}
        // eslint-disable-next-line no-shadow
        onChange={handleOnChange}
        isInvalid={error}
      />
      {error && <div className="text-danger small">{error}</div>}
      {value === 'other' && (
        <FormInput
          type="text"
          label={otherLabel}
          name={otherName}
          value={otherValue}
          onChange={handleChange}
          onError={[otherError]}
          required={otherRequired}
          htmlRequired={otherRequired}
        />
      )}
    </div>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default SelectWithOther;
