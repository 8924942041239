import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import * as googleApi from '../../api/google';
import * as hermesApi from '../../api/hermes';
import type { SharedResource } from '../../types/hermes';
import type { ByIds, Modal, Nullable } from '../../types/utils';
import type { RootState } from '../store';

import { hermesActions } from './hermesReducer';

export interface SharedResourceState {
  workspaces: {
    [workspaceId: number]: ByIds<SharedResource>;
  };
  sharedResourceModal: Modal<{
    selectedResourceId: Nullable<number>;
  }>;
}

const initialState: SharedResourceState = {
  workspaces: {},
  sharedResourceModal: {
    open: false,
    selectedResourceId: null,
  },
};

export const getSharedResources = createAsyncThunk<
  SharedResource[],
  { workspaceId: number },
  { state: RootState }
>('sharedResources/getSharedResources', async ({ workspaceId }, { getState }) => {
  const state = getState();

  if (state.sharedResources.workspaces[workspaceId]) {
    return Promise.reject();
  }

  const response = await hermesApi.getSharedResourcesForWorkspace(workspaceId);
  return response.data;
});

const updateStateWithSharedResource = (
  state: SharedResourceState,
  workspaceId: number,
  resource: SharedResource,
) => {
  if (!state.workspaces[workspaceId]) {
    state.workspaces[workspaceId] = {
      byId: {},
      allIds: [],
    };
  }

  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  state.workspaces[workspaceId]!.byId[resource.id] = {
    ...(state.workspaces[workspaceId]!.byId[resource.id] ?? {}),
    ...resource,
  };
  state.workspaces[workspaceId]!.allIds = [
    ...new Set([...state.workspaces[workspaceId]!.allIds, resource.id]),
  ];
  /* eslint-enable @typescript-eslint/no-non-null-assertion */
};

export const selectResourcesForWorkspace = (state: SharedResourceState, workspaceId: number) => {
  if (!state.workspaces[workspaceId]) {
    return [];
  }

  return state.workspaces[workspaceId]?.allIds.map(
    (id) => state.workspaces[workspaceId]?.byId[id],
  ) as SharedResource[];
};

export const selectSharedResource = (state: SharedResourceState, workspaceId: number | null) => {
  if (
    !workspaceId ||
    !state.workspaces[workspaceId] ||
    !state.sharedResourceModal.selectedResourceId
  ) {
    return null;
  }

  return state.workspaces[workspaceId]?.byId[
    state.sharedResourceModal.selectedResourceId
  ] as SharedResource;
};

export const refreshGoogleFiles = createAsyncThunk<
  void,
  { workspaceId: number },
  { state: RootState }
>('sharedResources/refreshGoogleFiles', async ({ workspaceId }, { getState, dispatch }) => {
  const state = getState();
  const response = await googleApi.getGoogleFiles(workspaceId);
  const files = response.data.files;
  const workspaceResources = selectResourcesForWorkspace(state.sharedResources, workspaceId);

  for (const file of files) {
    const resource = workspaceResources.find(
      (resource) => resource.type === 'google' && resource.googleId === file.id,
    );

    if (resource && file.name !== resource.displayName) {
      await dispatch(
        updateSharedResource({
          workspaceId,
          resourceId: resource.id,
          payload: { displayName: file.name },
        }),
      );
    }
  }
});

export const updateSharedResource = createAsyncThunk<
  { resource: SharedResource; workspaceId: number },
  { resourceId: number; workspaceId: number; payload: Partial<SharedResource> }
>('sharedResources/updateSharedResource', async ({ resourceId, workspaceId, payload }) => {
  const response = await hermesApi.updateSharedResource(resourceId, payload);
  const resource = response.data;

  if (resource.type === 'google' && payload.displayName) {
    try {
      await googleApi.updateGoogleFile(resource.googleId, workspaceId, {
        name: payload.displayName,
      });
    } catch (error) {
      console.error('Error updating google file', error);
    }
  }

  return { workspaceId, resource };
});

const sharedResourcesSlice = createSlice({
  name: 'sharedResources',
  initialState,
  reducers: {
    openSharedResourceEditModal: (state, { payload }: PayloadAction<number>) => {
      state.sharedResourceModal.open = true;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      state.sharedResourceModal.selectedResourceId = payload;
    },
    closeSharedResourceEditModal: (state) => {
      state.sharedResourceModal.open = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getSharedResources.fulfilled,
      (
        state,
        {
          payload,
          meta: {
            arg: { workspaceId },
          },
        },
      ) => {
        payload.forEach((resource) => updateStateWithSharedResource(state, workspaceId, resource));
      },
    );

    builder.addCase(
      hermesActions.messageSentSuccess,
      (state, { payload: { workspaceId, sharedResources } }) => {
        if (workspaceId && sharedResources && sharedResources.length > 0) {
          sharedResources.forEach((resource) =>
            updateStateWithSharedResource(state, workspaceId, resource),
          );
        }
      },
    );

    builder.addCase(
      updateSharedResource.fulfilled,
      (state, { payload: { workspaceId, resource } }) => {
        updateStateWithSharedResource(state, workspaceId, resource);
      },
    );
  },
});
const { actions, reducer } = sharedResourcesSlice;

export const sharedResourcesActions = actions;
export const sharedResourcesReducer = reducer;
