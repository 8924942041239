import { Alert } from '@polygence/components';

const ErrorPage = ({ error = 'Something went wrong' }) => {
  return (
    <div className="container">
      <Alert variant="danger" className="text-center mt-7">
        {error}
      </Alert>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default ErrorPage;
