import type { StudentApplicationId, StudentProfileId } from '@polygence/common';
import { Alert, Text } from '@polygence/components';
import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';

import { isVideoAskMessage } from 'src/components/common/VideoAsk/isVideoAskMessage';
import { getTracker } from 'src/utils/tracking/factory';

const tracker = getTracker();

const VIDEOASK_EVENTS = {
  QUESTION_PRESENTED: 'videoask_question_presented',
  RECORDING_STARTED: 'videoask_video_recording_started',
  SUBMITTED: 'videoask_submitted',
} as const;

type VideoAskStage =
  | 'initial'
  | 'intro'
  | 'question_1'
  | 'answer_1'
  | 'question_2'
  | 'answer_2'
  | 'submitted';

const MessagesPerStage: Partial<Record<VideoAskStage, string>> = {
  initial: 'The more casual and relaxed, the better! Simply say hi 👋 to us!',
  intro: 'The more casual and relaxed, the better! Simply say hi 👋 to us!',
  question_1: 'You got this! Take a deep breath and just say a bit about yourself!',
  answer_1: 'You got this! Take a deep breath and just say a bit about yourself!',
  question_2: 'Nice work! On to Question 2...',
  answer_2: "You're doing great!",
} as const;

export const VideoAskB = ({
  onSubmit,
  src,
  title,
  profileId,
  applicationId,
}: {
  onSubmit: () => void;
  src: string;
  title: string;
  profileId: StudentProfileId;
  applicationId: StudentApplicationId;
}) => {
  const [stage, setStage] = useState<VideoAskStage>('initial');

  const message = MessagesPerStage[stage];

  const handleMessage = (message: MessageEvent<{ type?: string }>) => {
    if (!isVideoAskMessage(message)) {
      return;
    }

    const { data: { type } = {} } = message;

    switch (stage) {
      case 'initial':
        if (type === VIDEOASK_EVENTS.QUESTION_PRESENTED) {
          setStage('intro');
        }
        break;
      case 'intro':
        if (type === VIDEOASK_EVENTS.QUESTION_PRESENTED) {
          setStage('question_1');
        }
        break;
      case 'question_1':
        if (type === VIDEOASK_EVENTS.RECORDING_STARTED) {
          setStage('answer_1');
        }
        break;
      case 'answer_1':
        if (type === VIDEOASK_EVENTS.QUESTION_PRESENTED) {
          setStage('question_2');
        }
        break;
      case 'question_2':
        if (type === VIDEOASK_EVENTS.RECORDING_STARTED) {
          setStage('answer_2');
        }
        break;
    }

    if (type === VIDEOASK_EVENTS.SUBMITTED) {
      setStage('submitted');
      onSubmit();
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  });

  const handleVideoPlay = (video: string) => {
    tracker.track(`VideoAsk example: ${video} video started`, {
      category: 'student_application_v2',
      studentProfileId: profileId,
      applicationId: applicationId,
    });
  };

  return (
    <>
      {!!message && (
        <Text size="medium" alignment="center" className="mb-5" fontWeight="bold">
          {message}
        </Text>
      )}
      <iframe
        title={title}
        src={src}
        allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
        width="100%"
        height="600px"
        // eslint-disable-next-line react/forbid-dom-props -- autodisabled
        style={{ border: 'none', borderRadius: 'var(--base-border-radius)' }}
      />
      {stage !== 'submitted' && (
        <>
          <Alert variant="info" title="Why do we do this?" className="mt-5">
            We're really just putting a face to your name! Having a "good" video does not help or
            hurt your acceptance chances. Instead, we just want to get a sense of who you are and
            why you're excited about doing a Core project. Simple as that!
          </Alert>
          <Text size="large" fontWeight="bold" className="my-4">
            Past student examples
          </Text>
          <Text size="medium">
            This will give you a taste, but remember we are most excited about YOU! Don’t try to
            copy these, make it your own however you think is best. There are no wrong answers!
          </Text>
          <Text size="medium" className="my-4">
            <b>Sample of Question 1:</b> Tell us about yourself.
          </Text>
          <ReactPlayer
            width="100%"
            url="https://boshi-storage.s3.us-west-1.amazonaws.com/media/first_answer.mp4"
            controls
            onStart={() => handleVideoPlay('first')}
          />
          <Text size="medium" className="my-4">
            <b>Sample of Question 2:</b> What motivates you to do research?
          </Text>
          <ReactPlayer
            width="100%"
            url="https://boshi-storage.s3.us-west-1.amazonaws.com/media/second_answer.mp4"
            controls
            onStart={() => handleVideoPlay('second')}
          />
        </>
      )}
    </>
  );
};
