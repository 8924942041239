import { ProjectType } from '@polygence/common';
import type { MentorProfileId, MentorAvailabilityList } from '@polygence/common';
import { useGetMentorAvailabilitiesQuery } from '@polygence/common/api/mentor-availability';

export const useCoreMentorAvailability = ({
  mentorProfileId,
}: {
  mentorProfileId: MentorProfileId;
}): MentorAvailabilityList | undefined => {
  const { data: mentorAvailabilities = [] } = useGetMentorAvailabilitiesQuery({ mentorProfileId });
  return mentorAvailabilities.find(({ projectType }) => projectType.value === ProjectType.FLAGSHIP);
};

export const usePSSPublishingMentorAvailability = ({
  mentorProfileId,
}: {
  mentorProfileId: MentorProfileId;
}): MentorAvailabilityList | undefined => {
  const { data: mentorAvailabilities = [] } = useGetMentorAvailabilitiesQuery({ mentorProfileId });
  return mentorAvailabilities.find(
    ({ projectType }) => projectType.value === ProjectType.PREMIUM_SHOWCASING_PUBLISHING,
  );
};
