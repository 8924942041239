import type { Nullable } from '@polygence/common';
import { RadioButton, Text } from '@polygence/components';
import classNames from 'classnames';
import type { FormEvent, ReactNode } from 'react';

import styles from 'src/components/common/form/LaunchpadPitch/LaunchpadPitch.module.scss';
import PolygenceLogo from 'src/components/common/form/LaunchpadPitch/assets/polygence-logo.svg';

interface LaunchpadPitchProps {
  beforeHeader: ReactNode;
  header: ReactNode;
  description: ReactNode;
  question: ReactNode;
  value: Nullable<boolean>;
  onChange: (value: string) => void;
}

const MentorCard = ({ mentorNumber, pictureUrl }: { mentorNumber: number; pictureUrl: string }) => {
  return (
    <div
      className={classNames(
        styles['mentorCard'],
        mentorNumber === 1 && styles['leftCard'],
        mentorNumber === 3 && styles['rightCard'],
        'd-flex flex-column align-items-center',
      )}
    >
      <img src={PolygenceLogo} alt="logo" className={styles['logo']} />
      <Text
        size="medium"
        fontWeight="semibold"
        className={classNames(styles['text'], 'mt-7 text-white')}
      >
        Mentor
        <br />#{mentorNumber}
      </Text>
      <img src={pictureUrl} alt="mentor" className={classNames(styles['picture'], 'mt-4')} />
    </div>
  );
};

export const LaunchpadPitch = ({
  beforeHeader,
  header,
  description,
  question,
  value,
  onChange,
}: LaunchpadPitchProps) => {
  const handleChange = (event: FormEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    onChange(target.value);
  };

  return (
    <div className={styles['wrapper']}>
      <div className="d-flex flex-column gap-6">
        {beforeHeader}
        <div className={styles['header']}>{header}</div>
        <div className={styles['description']}>{description}</div>
      </div>
      <div className={classNames(styles['mentorDeck'], 'd-flex justify-content-center my-8')}>
        <MentorCard
          mentorNumber={1}
          pictureUrl="https://dpl6hyzg28thp.cloudfront.net/media/lp-pitch-mentor-1.svg"
        />
        <MentorCard
          mentorNumber={2}
          pictureUrl="https://dpl6hyzg28thp.cloudfront.net/media/lp-pitch-mentor-2.svg"
        />
        <MentorCard
          mentorNumber={3}
          pictureUrl="https://dpl6hyzg28thp.cloudfront.net/media/lp-pitch-mentor-3.svg"
        />
      </div>
      {question}
      <RadioButton
        name="interestedInLaunchpad"
        label="Yes, I’m curious to learn more later."
        value="yes"
        onChange={handleChange}
        checked={value === true}
      />
      <RadioButton
        name="interestedInLaunchpad"
        label="I don’t think this is for me."
        value="no"
        onChange={handleChange}
        checked={value === false}
      />
    </div>
  );
};
