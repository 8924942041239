import { useAdditionalSessionModal } from './selectors/useAdditionalSessionModal';
import { useAssignmentsByIds } from './selectors/useAssignmentsByIds';
import { useCurrentMeeting } from './selectors/useCurrentMeeting';
import useCurrentUser from './selectors/useCurrentUser';
import { useExtensionRequestById } from './selectors/useExtensionRequestById';
import { useIsUserOnlineHermes } from './selectors/useIsUserOnlineHermes';
import { useLastProjectPauseRequest } from './selectors/useLatestProjectPauseRequest';
import { useLoungeByWorkspaceId } from './selectors/useLounges';
import useMeetingById from './selectors/useMeetingById';
import { useMobileNavigationTab } from './selectors/useMobileNavigationTab';
import { useOtherParticipants } from './selectors/useOtherParticipants';
import { useProfileCompletionRequired } from './selectors/useProfileCompletionRequired';
import { useProjectRooms } from './selectors/useRooms';
import useSelectedProject from './selectors/useSelectedProject';
import useSelectedRoom, { useSelectedRoomOrThrow } from './selectors/useSelectedRoom';
import useSelectedRoomId from './selectors/useSelectedRoomId';
import { useSelectedSharedResource } from './selectors/useSelectedSharedResource';
import useSelectedWorkspace, {
  useSelectedWorkspaceOrThrow,
} from './selectors/useSelectedWorkspace';
import useSelectedWorkspaceId from './selectors/useSelectedWorkspaceId';
import { useSessionByMeetingId } from './selectors/useSessionByMeetingId';
import {
  useSessionBySessionId,
  useSessionBySessionIdOrNotNull,
} from './selectors/useSessionBySessionId';
import { useTodoItems } from './selectors/useTodoItems';
import { useWorkspaceDisplayList } from './selectors/useWorkspaceDisplayList';
import useChat from './useChat';
import useDebounce from './useDebounce';
import { useMediaQuery } from './useMediaQuery';
import { useMeetingJoinable } from './useMeetingJoinable';
import useNextSessionMeeting from './useNextSessionMeeting';
import { usePageVisibility } from './usePageVisibility';
import { usePageVisibilityWithTimeout } from './usePageVisibilityWithTimeout';
import usePagination from './usePagination';
import usePaging from './usePaging';
import { usePermissions } from './usePermissions';
import { useScrollLock } from './useScrollLock';
import { useSelfPropose } from './useSelfPropose';
import { useSendUnsentMessagesOnLoad } from './useSendUnsentMessagesOnLoad';
import { useSession } from './useSession';
import { useSessionList } from './useSessionList';
import useSharedResources from './useSharedResources';
import { useWritingFeedbackEnabled } from './useWritingFeedbackEnabled';

export const commonHooks = {
  useAdditionalSessionModal,
  useAssignmentsByIds,
  useChat,
  useCurrentMeeting,
  useCurrentUser,
  useDebounce,
  useExtensionRequestById,
  useIsUserOnlineHermes,
  useLastProjectPauseRequest,
  useLoungeByWorkspaceId,
  useMediaQuery,
  useMeetingById,
  useMeetingJoinable,
  useMobileNavigationTab,
  useNextSessionMeeting,
  useOtherParticipants,
  usePageVisibility,
  usePageVisibilityWithTimeout,
  usePagination,
  usePaging,
  usePermissions,
  useProfileCompletionRequired,
  useProjectRooms,
  useScrollLock,
  useSelectedProject,
  useSelectedRoom,
  useSelectedRoomId,
  useSelectedRoomOrThrow,
  useSelectedSharedResource,
  useSelectedWorkspace,
  useSelectedWorkspaceId,
  useSelectedWorkspaceOrThrow,
  useSelfPropose,
  useSendUnsentMessagesOnLoad,
  useSession,
  useSessionByMeetingId,
  useSessionBySessionId,
  useSessionBySessionIdOrNotNull,
  useSessionList,
  useSharedResources,
  useTodoItems,
  useWorkspaceDisplayList,
  useWritingFeedbackEnabled,
};
