import type { DayOfWeek, TimeSlot as TimeSlotType } from '@polygence/common';
import { Text, Spacer } from '@polygence/components';

import styles from 'src/components/usersettings/MentorWorkingHours/MentorWorkingDay.module.scss';
import { TimeSlot } from 'src/components/usersettings/MentorWorkingHours/TimeSlot';
interface MentorWorkingDayProps {
  day: DayOfWeek;
  slots: TimeSlotType[];
  isDisabled: boolean;
  changeStartAt: (newTime: string, slotId: number) => void;
  changeEndAt: (newTime: string, slotId: number) => void;
  createNextTimeSlot: (slot: TimeSlotType) => void;
  deleteTimeSlot: (id: number) => void;
}

export const MentorWorkingDay = ({
  day,
  slots,
  isDisabled,
  changeStartAt,
  changeEndAt,
  createNextTimeSlot,
  deleteTimeSlot,
}: MentorWorkingDayProps) => {
  const slotsForDay = slots.filter((slot) => slot.day === day);

  return (
    <div>
      <div className={styles['dayWrapper']}>
        <Text size="medium" fontWeight="bold">
          {day.substring(0, 3).toUpperCase()}
        </Text>
      </div>
      {slotsForDay.map((slot, index) => {
        const isLast = slotsForDay.length === index + 1;
        const previousTimeSlot = index > 0 ? slotsForDay[index - 1] : undefined;
        const nextTimeSlot = index < slots.length ? slotsForDay[index + 1] : undefined;
        return (
          <div key={slot.id} className="flex-wrap">
            <TimeSlot
              timeSlot={slot}
              isDisabled={isDisabled}
              changeStartAt={changeStartAt}
              changeEndAt={changeEndAt}
              isLast={isLast}
              createNextTimeSlot={createNextTimeSlot}
              previousTimeSlot={previousTimeSlot}
              nextTimeSlot={nextTimeSlot}
              deleteTimeSlot={deleteTimeSlot}
            />
            <Spacer size={4} vertical />
          </div>
        );
      })}
      <Spacer size={2} vertical />
    </div>
  );
};
