import 'src/components/DropdownMenu/dropdownMenu.sass';
import { commonHooks } from '@polygence/common/hooks';
import { externalLink } from '@polygence/common/utils/externalLink';
import { Icon } from '@polygence/components';
import { Menu, MenuList, MenuItem, MenuLink, MenuContextValue } from '@reach/menu-button';
import { isFunction } from '@reach/utils';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import { WarningIndicatorIcon } from 'src/components/NavigationBar/WarningIndicatorIcon';
import type { NavbarNavigationItem } from 'src/utils/navigation';

export interface DropdownMenuProps {
  children: React.ReactNode | (({ isExpanded }: { isExpanded: boolean }) => React.ReactNode);
  items: Omit<NavbarNavigationItem, 'items'>[];
  size?: 'sm' | 'md' | 'lg';
}

const DropdownMenuLink = ({ href, children }: { href: string; children: React.ReactNode }) => {
  const isExternal = href.startsWith('http');
  return (
    <MenuLink
      as={isExternal ? 'a' : NavLink}
      key={href}
      to={href}
      href={href}
      {...(isExternal ? externalLink : {})}
    >
      {children}
    </MenuLink>
  );
};

export const DropdownMenu = ({ items, children, size = 'md' }: DropdownMenuProps) => {
  const currentUser = commonHooks.useCurrentUser();
  const isProfileCompletionRequired = commonHooks.useProfileCompletionRequired();

  const isWarningIndicatorVisible = (label: string | undefined): boolean => {
    if (label === 'Settings') {
      return currentUser.timeZoneMismatch || currentUser.missingDemographyData;
    }

    if (label === 'Profile') {
      return isProfileCompletionRequired;
    }

    return false;
  };

  return (
    <Menu className={classNames('dropdownMenu', size)}>
      {({ isExpanded }: MenuContextValue) => (
        <>
          {isFunction(children) ? children({ isExpanded }) : children}
          {items && (
            <MenuList className={classNames('menuItems', size)}>
              {items.map(({ label, href, iconId, onClick }) => {
                if (typeof onClick === 'function') {
                  return (
                    <MenuItem onSelect={onClick} key={label}>
                      {iconId && <Icon id={iconId} size={size} color="var(--grayscale-6)" />}
                      {label}
                    </MenuItem>
                  );
                } else {
                  return (
                    href && (
                      <DropdownMenuLink href={href} key={label}>
                        {iconId && <Icon id={iconId} size={size} color="var(--grayscale-6)" />}
                        {label}
                        {isWarningIndicatorVisible(label) && (
                          <WarningIndicatorIcon className="position-absolute top-auto right-4" />
                        )}
                      </DropdownMenuLink>
                    )
                  );
                }
              })}
            </MenuList>
          )}
        </>
      )}
    </Menu>
  );
};
