import type { UserType } from '../types/backend';

export function getQueryParams({
  search = '',
  limit = undefined,
  userTypes = [],
}: { search?: string; limit?: number; userTypes?: UserType[] } = {}) {
  const searchParams = new URLSearchParams();
  if (search) {
    searchParams.append('search', search);
  }
  if (limit) {
    searchParams.append('limit', limit.toString());
  }
  for (const userType of userTypes) {
    searchParams.append('user_types', userType);
  }

  return searchParams.toString();
}
