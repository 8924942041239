const levenshteinenator = (a, b) => {
  /* eslint-disable fp/no-mutation */
  let cost;

  // get values
  let m = a.length;
  let n = b.length;

  // make sure a.length >= b.length to use O(min(n,m)) space, whatever that is
  if (m < n) {
    let c = a;
    a = b;
    b = c;
    let o = m;
    m = n;
    n = o;
  }

  let r = [];
  r[0] = [];
  for (let c = 0; c < n + 1; c++) {
    r[0][c] = c;
  }

  for (let i = 1; i < m + 1; i++) {
    r[i] = [];
    r[i][0] = i;
    for (let j = 1; j < n + 1; j++) {
      cost = a.charAt(i - 1) === b.charAt(j - 1) ? 0 : 1;
      r[i][j] = minimator(r[i - 1][j] + 1, r[i][j - 1] + 1, r[i - 1][j - 1] + cost);
    }
  }

  return r[m][n];
  /* eslint-enable fp/no-mutation */
};

// return the smallest of the three values passed in
const minimator = (x, y, z) => {
  if (x < y && x < z) {
    return x;
  }
  if (y < x && y < z) {
    return y;
  }
  return z;
};

const commonEmailProviders = [
  'gmail.com',
  'yahoo.com',
  'hotmail.com',
  'aol.com',
  'hotmail.co.uk',
  'msn.com',
  'yahoo.co.uk',
  'live.com',
];

const mistypedDomain = (email) => {
  const parts = email.split('@');
  if (parts.length !== 2) {
    return null;
  }
  let dist;
  // eslint-disable-next-line fp/no-mutation
  for (let x = 0; x < commonEmailProviders.length; x++) {
    // eslint-disable-next-line fp/no-mutation
    dist = levenshteinenator(commonEmailProviders[x], parts[1]);
    if (dist === 1 || dist === 2) {
      return commonEmailProviders[x];
    }
  }
  return null;
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default mistypedDomain;
