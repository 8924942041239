import { Heading } from '@polygence/components';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import styles from 'src/components/Tiles/Mentors/MentorTiles.module.scss';
import tileIllustrations from 'src/components/Tiles/tile-illustrations';
import tileStyles from 'src/components/Tiles/tilesDashboard.module.scss';

export const JobBoard = () => {
  return (
    <Link to="/n/mentor-guidebook/974d3698-de91-4698-a963-ec28b45fd74b">
      <div className={classNames(tileStyles['tile'], styles['jobBoardTile'], 'd-flex flex-column')}>
        <Heading size="h4" alignment="left" className="mb-2">
          Mentor Job Board
        </Heading>
        <img
          className={classNames(styles['illustration'])}
          src={tileIllustrations['mentor-job-board']}
          alt="Mentor Job Board"
        />
      </div>
    </Link>
  );
};
