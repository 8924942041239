export enum HermesMobileTab {
  RoomList = 'rooms',
  Chat = 'chat',
  Sessions = 'sessions',
  FullScreen = 'fullScreen',
}

export const defaultMobileNavigationTab: HermesMobileTab = HermesMobileTab.FullScreen;

export const isValidMobileNavigationTab = (tab: string): tab is HermesMobileTab => {
  return Object.values(HermesMobileTab).includes(tab as HermesMobileTab);
};

export const hermesMobileTabs = [
  HermesMobileTab.RoomList,
  HermesMobileTab.Chat,
  HermesMobileTab.Sessions,
];
