/* eslint-disable sonarjs/no-duplicate-string */

import {
  getPathfinderApplicationInterim,
  Nullable,
  PathfinderStudentApplicationInterim,
  PathfinderStudentApplicationStatuses,
} from '@polygence/common';
import { Alert, Button, Card, Heading, Illustration, Spacer, Text } from '@polygence/components';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { useParams } from 'react-router';

import { DashedBox } from 'src/components/common/DashedBox';
import { PathfindersCongrats } from 'src/components/student/pathfinders/illustrations';
import styles from 'src/components/student/pathfinders/pathfinderApplicationInterimPage.module.scss';
import classes from 'src/students/form/PagedFormSection.module.sass';
import {
  trackPathfinderInterimPageCheckout,
  trackPathfinderInterimPageOpened,
} from 'src/students/pathfinder-application';
import { navigateTo } from 'src/utils/navigateTo';

interface PathParams {
  applicationUuid: string;
}

const parseAmount = (amount: number) => {
  return new Intl.NumberFormat('en-HOSSDDG', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  }).format(amount);
};

const CheckoutActionButton = ({ id, uuid, paymentIntent }: PathfinderStudentApplicationInterim) => {
  const [isLoading, setIsLoading] = useState(false);

  function handleClick() {
    setIsLoading(true);
    trackPathfinderInterimPageCheckout(id, uuid);
    navigateTo(paymentIntent.paymentUrl);
  }

  return (
    <Button
      variant="primary"
      className={classNames(styles['button'], 'py-5')}
      size="lg"
      onClick={handleClick}
      disabled={isLoading}
    >
      Proceed to checkout
    </Button>
  );
};

export const PathfinderApplicationInterimPage = () => {
  const { applicationUuid } = useParams<PathParams>();

  const [pathfindersApplicationInterim, setPathfindersApplicationInterim] =
    useState<Nullable<PathfinderStudentApplicationInterim>>(null);

  useEffect(() => {
    getPathfinderApplicationInterim(applicationUuid)
      .then(({ data }) => {
        setPathfindersApplicationInterim(data);
        trackPathfinderInterimPageOpened(data.id, data.uuid);
      })
      .catch(console.error);
  }, [applicationUuid]);

  if (!pathfindersApplicationInterim) {
    return <></>;
  }

  const { paymentIntent, interests, status } = pathfindersApplicationInterim;

  const shouldShowCTA = status !== PathfinderStudentApplicationStatuses.ACCEPTED;

  return (
    <div className={classNames('pt-9', classes['background-triangle'])}>
      <div className="container d-flex flex-column align-items-center">
        <Illustration illustration={PathfindersCongrats} size={68} />
        <Spacer size={8} />
        <Heading className="mb-6" size={{ base: 'h4', lg: 'h3' }} as="h1">
          You are only one step away
        </Heading>
        <Heading fontWeight="light" size={{ base: 'h5', lg: 'h4' }} as="h1">
          We have over 2,000 Polygence mentors that are eager to meet you.
          <br />
          Don’t wait - connect with them today by proceeding to checkout.
        </Heading>
        <Spacer size={10} />
        <ReactPlayer
          url="https://youtu.be/ypbio2rUfC4?si=ArLsgX6wxsYSoRu5"
          width="576px"
          height="324px"
          light
        />
        <Spacer size={10} />
        {shouldShowCTA && (
          <>
            <CheckoutActionButton {...pathfindersApplicationInterim} />
            <Spacer size={9} />
          </>
        )}
        <div className={classNames(styles['cartCards'], 'd-flex flex-column gap-5')}>
          <Card className={classNames(styles['cartCard'], 'p-7 gap-6')}>
            <Text size="large" fontWeight="bold">
              In your cart:
            </Text>
            <div className="d-flex flex-row">
              <Text
                size="medium"
                fontWeight="bold"
                className={classNames(styles['sessions'], 'me-auto')}
              >
                Pathfinders Program
              </Text>
              <Text size="medium" fontWeight="normal" className={styles['grayscale-9']}>
                {parseAmount(paymentIntent.amount)}
              </Text>
            </div>
          </Card>
          <DashedBox className="p-7 gap-6">
            <Text size="large" fontWeight="bold">
              What’s included in Pathfinders:
            </Text>
            <ul className={classNames(styles['grayscale-9'], 'd-flex flex-column gap-1 mb-0')}>
              <Text as="li" size="medium" fontWeight="normal">
                3 one hour session with three mentors of your chosen topic of interest
              </Text>
              <Text as="li" size="medium" fontWeight="normal">
                Reflection session with Polygence Educator
              </Text>
              <Text as="li" size="medium" fontWeight="normal">
                Downloadable 50 Sample Questions to prepare for your sessions
              </Text>
              <Text as="li" size="medium" fontWeight="normal">
                Access to Polygence platform and community
              </Text>
            </ul>
          </DashedBox>
          <Card
            className={classNames(
              styles['cartCard'],
              'd-flex flex-row p-7 gap-6 align-items-center',
            )}
          >
            <Text size="large" fontWeight="bold" className="me-auto">
              Total
            </Text>
            <Text size="medium" fontWeight="normal" className={styles['grayscale-9']}>
              {parseAmount(paymentIntent.amount)}
            </Text>
          </Card>
        </div>
        <Spacer size={12} />
        <Spacer size={12} />
        <Heading fontWeight="bold" size={{ base: 'h5', lg: 'h4' }} as="h1">
          Here is what you have submitted:
        </Heading>
        <Spacer size={8} />
        <div className="w-75">
          <Alert className="mt-5 text-center" variant="primary">
            This is a read only view of your submitted application.
          </Alert>
          <Spacer size={8} />
          <div className="d-flex flex-column flex-md-row gap-4">
            {interests.map((interest) => (
              <Card
                key={interest.id}
                className={classNames(
                  styles['interestCard'],
                  'col d-flex flex-column align-items-center p-6',
                )}
              >
                <img
                  src="https://dpl6hyzg28thp.cloudfront.net/media/unknown_mentor.svg"
                  alt="unknown mentor"
                  className="mb-2"
                />
                <Text
                  size="large"
                  fontWeight="bold"
                  className={classNames(styles['interestText'], 'mb-6 text-center')}
                >
                  {interest.name}
                </Text>
                {interest.notes && (
                  <>
                    <Text
                      size="large"
                      fontWeight="light"
                      className={classNames(styles['interestText'], 'mb-4')}
                    >
                      Notes:
                    </Text>
                    <Text
                      size="medium"
                      fontWeight="light"
                      className={classNames(styles['interestText'], 'text-center')}
                    >
                      {interest.notes}
                    </Text>
                  </>
                )}
              </Card>
            ))}
          </div>
        </div>
        <Spacer size={10} />
        {shouldShowCTA && (
          <>
            <CheckoutActionButton {...pathfindersApplicationInterim} />
            <Spacer size={10} />
          </>
        )}
      </div>
    </div>
  );
};
