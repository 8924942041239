import { PaymentIntentInvoice, PaymentIntentTypes } from '@polygence/common/types/payment';
import { Text, cn } from '@polygence/components';

import { PaymentIntentAmountOrPlaceholder } from 'src/payment/PaymentIntent/PaymentIntentAmountOrPlaceholder';

interface PaymentIntentMonthlyInstallmentInterestProps {
  paymentIntent: PaymentIntentInvoice;
  className?: string;
}

export const PaymentIntentMonthlyInstallmentInterest = ({
  paymentIntent,
  className,
}: PaymentIntentMonthlyInstallmentInterestProps) => {
  if (paymentIntent.type !== PaymentIntentTypes.SUBSCRIPTION || !paymentIntent.riskPremium) {
    return null;
  }

  return (
    <div className={cn('tw-flex tw-items-center tw-justify-between', className)}>
      <Text size="medium" alignment="left" fontWeight="bold" className="tw-inline tw-text-sm">
        Monthly installment interest ({paymentIntent.riskPremium}%)
      </Text>
      <PaymentIntentAmountOrPlaceholder
        amount={paymentIntent.riskPremiumAmount}
        size="medium"
        className="tw-text-sm"
      />
    </div>
  );
};
