import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import { Announcement } from 'src/components/EditableContent/Announcement';

// eslint-disable-next-line react/prefer-stateless-function -- autodisabled
class PublicPosts extends React.Component {
  render() {
    return (
      // eslint-disable-next-line react/jsx-filename-extension -- autodisabled
      <div>
        <Switch>
          <Route
            // eslint-disable-next-line react/destructuring-assignment -- autodisabled
            path={`${this.props.match.url}/:mid`}
            render={({ match }, props) => (
              <Announcement
                match={match}
                {...props}
                // eslint-disable-next-line react/destructuring-assignment -- autodisabled
                logged_in={this.props.logged_in}
                guidebook_tag={match.params.mid}
              />
            )}
          />
        </Switch>
      </div>
    );
  }
}

// eslint-disable-next-line import/no-default-export -- autodisabled
export default withRouter(PublicPosts);
