import classNames from 'classnames';
import type { CSSProperties } from 'react';

import styles from 'src/components/FullPageBackground/FullPageBackground.module.scss';
import { getStaticAsset } from 'src/utils';

export const FullPageBackground = ({
  className,
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) => {
  return (
    <div
      className={classNames(styles['fullPageBg'], className)}
      // eslint-disable-next-line react/forbid-dom-props
      style={{ '--app-bg': `url("${getStaticAsset('appbg.jpg')}")` } as CSSProperties}
    >
      {children}
    </div>
  );
};
