import type { PodsCohortId } from '@polygence/common';
import { Button, Icon } from '@polygence/components';

import { useEnrollWithTokenMutation } from 'src/reducers/podsReducers';

export const EnrollNowButton = ({ cohortId }: { cohortId: PodsCohortId }) => {
  const [enrollWithToken, { isLoading }] = useEnrollWithTokenMutation();

  return (
    <Button
      variant="tertiary"
      endIcon={<Icon id="chevron-right" />}
      onClick={() => {
        enrollWithToken({ cohortId }).unwrap().catch(console.error);
      }}
      disabled={isLoading}
    >
      Enroll now
    </Button>
  );
};
