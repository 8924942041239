import { Text, Heading } from '@polygence/components';
import { Link } from 'react-router-dom';

import SuccessIcon from 'src/components/static/images/undraw_maker_launch_re.svg?react';
import { urls } from 'src/urls';

export const PaymentSuccessTokenWorkflowMessage = () => {
  return (
    <>
      <Heading as="h1" size="h3" className="tw-mb-12">
        We successfully received your payment.
      </Heading>
      <SuccessIcon className="tw-mb-9" />
      <Text size="large" alignment="center">
        Let's start inviting students{' '}
        <Link to={`${urls.getCounselorStudents()}#inviteSection`}>here</Link>!
      </Text>
    </>
  );
};
