import { getEnvironment, getBaseDomain } from '../commonSettings';

const DEVELOPMENT = 'development';
const STAGING = 'staging';
const ONDEMAND = 'ondemand';
const PRODUCTION = 'production';
const TEST = 'test';

export { getEnvironment, getBaseDomain };

export function isDevelopment() {
  return getEnvironment() === DEVELOPMENT;
}

export function isStaging() {
  return getEnvironment() === STAGING;
}

export function isOndemand() {
  return getEnvironment() === ONDEMAND;
}

export function isProduction() {
  return getEnvironment() === PRODUCTION;
}

export function isTest() {
  return getEnvironment() === TEST;
}

export { DEVELOPMENT, STAGING, ONDEMAND, PRODUCTION, TEST };
