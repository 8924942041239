import { Button, Icon } from '@polygence/components';

interface PaymentIntentProceedButtonDesktopProps {
  v3Enabled: boolean;
  disabled: boolean;
  onProceed: VoidFunction;
}

export const PaymentIntentProceedButtonDesktop = ({
  v3Enabled,
  disabled,
  onProceed,
}: PaymentIntentProceedButtonDesktopProps) => {
  if (v3Enabled) {
    return (
      <Button
        variant="tertiary"
        endIcon={<Icon id="chevron-right" />}
        disabled={disabled}
        className="tw-hidden tw-h-[50px] tw-w-full tw-bg-primary-900 tw-font-bold tw-text-white md:tw-block"
        onClick={onProceed}
      >
        Proceed with payment
      </Button>
    );
  }

  return (
    <div className="tw-hidden tw-justify-center md:tw-flex">
      <Button
        variant="primary"
        endIcon={<Icon id="chevron-right" />}
        disabled={disabled}
        onClick={onProceed}
      >
        Proceed with payment
      </Button>
    </div>
  );
};
