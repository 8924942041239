import { Heading } from '@polygence/components';
import type React from 'react';

import DateWrapper, { DateFormat } from 'src/components/aux/dateWrapper';

interface ScholarBasicInfoProps {
  fullName: string;
  highSchool: string;
  gradYear: string;
  location: string;
}

const ScholarBasicInfo: React.FC<ScholarBasicInfoProps> = ({
  fullName,
  highSchool,
  gradYear,
  location,
}) => {
  return (
    <>
      <Heading size="h3" as="h1" alignment="left">
        {fullName}
      </Heading>
      <p className="mr-3">
        {highSchool}
        <span>Class of {new DateWrapper(gradYear).format(DateFormat.FORMAT_9)}</span>
        <span>{location}</span>
      </p>
    </>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default ScholarBasicInfo;
