export const PodsFinalSurveySectionIds = {
  FINAL_SURVEY_INTRO: 'final-survey-intro',
  FINAL_SURVEY_1: 'final-survey-1',
  FINAL_SURVEY_2: 'final-survey-2',
  FINAL_SURVEY_3: 'final-survey-3',
  FINAL_SURVEY_4: 'final-survey-4',
  // PROJECT_OUTCOME: 'project-outcome',
} as const;

export type PodsFinalSurveySectionId =
  (typeof PodsFinalSurveySectionIds)[keyof typeof PodsFinalSurveySectionIds];
