import { getCommonStorage } from '../../commonSettings';
import type { Message } from '../../types/hermes';

const UNSENT_MESSAGES_KEY = 'unsentMessages';

export const unsentMessagesLocalStorageHandler = {
  set: (roomId: number, userId: number, value: Partial<Message>[]) =>
    getCommonStorage().set<Partial<Message>[]>(
      `${UNSENT_MESSAGES_KEY}__${userId}__${roomId}`,
      value,
    ),
  get: async (roomId: number, userId: number) => {
    const unsentMessages = await getCommonStorage().get<Partial<Message>[]>(
      `${UNSENT_MESSAGES_KEY}__${userId}__${roomId}`,
    );
    if (unsentMessages) {
      return unsentMessages;
    }
    return [];
  },
  remove: (roomId: number, userId: number) =>
    getCommonStorage().remove(`${UNSENT_MESSAGES_KEY}__${userId}__${roomId}`),
};
