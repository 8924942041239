import { RadioButton, Spacer } from '@polygence/components';
import { useState } from 'react';
import type React from 'react';

import { SchoolSelector } from 'src/components/SchoolSelector';
import {
  DatePickerWithLabel,
  DatePickerChangeTarget,
} from 'src/components/common/DatePickerWithLabel';
import type { Profile } from 'src/components/usersettings/PersonalDetails';
import { TopCollegesSelector } from 'src/components/usersettings/TopCollegesSelector';
import { schoolGraduationYearProps } from 'src/students/student-application';
import type { SchoolChangeTarget } from 'src/types/common';

interface SchoolSectionProps {
  profileForm: Partial<Profile>;
  handleChange: (event: SchoolChangeTarget) => void | null;
  onSelectChange: (name: string, target: DatePickerChangeTarget['target']) => void;
}

const SchoolSection: React.FC<SchoolSectionProps> = ({
  profileForm,
  handleChange,
  onSelectChange,
}) => {
  const getDefaultSchoolState = () => {
    if (
      profileForm.schoolGradeOther === 'middle_school_student' &&
      (profileForm.middleSchoolOther || profileForm.middleSchool)
    ) {
      return 'middleSchool' as const;
    } else if (
      profileForm.schoolGradeOther === 'high_school_student' &&
      (profileForm.highSchoolOther || profileForm.highSchool)
    ) {
      return 'highSchool' as const;
    } else if (profileForm.schoolGradeOther === 'college_student') {
      return 'college_student' as const;
    } else if (profileForm.schoolGradeOther === 'not_in_school') {
      return 'not_in_school' as const;
    }
    return '' as const;
  };

  const [school, setSchool] = useState(getDefaultSchoolState());

  return (
    <>
      <RadioButton
        name="school"
        label="I'm in middle school"
        value="middleSchool"
        onChange={() => {
          handleChange({ target: { name: 'schoolGradeOther', value: 'middle_school_student' } });
          setSchool('middleSchool');
        }}
        checked={school === 'middleSchool'}
      />
      {school === 'middleSchool' && (
        <>
          <SchoolSelector
            label="Your school"
            name="middleSchool"
            value={
              profileForm.middleSchool && profileForm.middleSchoolLabel
                ? { value: profileForm.middleSchool, label: profileForm.middleSchoolLabel }
                : null
            }
            otherValue={`${profileForm.middleSchoolOther ?? ''}`}
            otherName="middleSchoolOther"
            onChange={handleChange}
          />
          <DatePickerWithLabel
            {...schoolGraduationYearProps}
            id="middle_school_year_of_graduation"
            label="Year of graduation"
            value={profileForm.schoolGraduationYear}
            customInput
            onChange={(event) => onSelectChange('schoolGraduationYear', event.target)}
          />
        </>
      )}
      <RadioButton
        name="school"
        label="I'm in high school"
        value="highSchool"
        onChange={() => {
          handleChange({ target: { name: 'schoolGradeOther', value: 'high_school_student' } });
          setSchool('highSchool');
        }}
        checked={school === 'highSchool'}
      />
      {school === 'highSchool' && (
        <>
          <SchoolSelector
            label="Your school"
            name="highSchool"
            value={
              profileForm.highSchool && profileForm.highSchoolLabel
                ? { value: profileForm.highSchool, label: profileForm.highSchoolLabel }
                : null
            }
            otherValue={`${profileForm.highSchoolOther ?? ''}`}
            otherName="highSchoolOther"
            onChange={handleChange}
          />
          <DatePickerWithLabel
            {...schoolGraduationYearProps}
            id="high_school_year_of_graduation"
            label="Year of graduation"
            value={profileForm.schoolGraduationYear}
            customInput
            onChange={(event) => onSelectChange('schoolGraduationYear', event.target)}
          />
        </>
      )}
      <RadioButton
        name="schoolGradeOther"
        label="I'm in college"
        value="college_student"
        onChange={() => {
          handleChange({ target: { name: 'schoolGradeOther', value: 'college_student' } });
          setSchool('college_student');
        }}
        checked={school === 'college_student'}
      />
      {school === 'college_student' && (
        <DatePickerWithLabel
          {...schoolGraduationYearProps}
          id="college_year_of_graduation"
          label="Year of graduation"
          value={profileForm.schoolGraduationYear}
          customInput
          onChange={(event) => onSelectChange('schoolGraduationYear', event.target)}
        />
      )}
      <RadioButton
        name="schoolGradeOther"
        label="I'm not currently in school"
        value="not_in_school"
        onChange={() => {
          handleChange({ target: { name: 'schoolGradeOther', value: 'not_in_school' } });
          setSchool('not_in_school');
        }}
        checked={school === 'not_in_school'}
      />
      {school === 'not_in_school' && (
        <DatePickerWithLabel
          {...schoolGraduationYearProps}
          id="not_in_school_year_of_graduation"
          label="Year of graduation"
          value={profileForm.schoolGraduationYear}
          customInput
          onChange={(event) => onSelectChange('schoolGraduationYear', event.target)}
        />
      )}
      <Spacer size={6} />
      {(school === 'middleSchool' || school === 'highSchool') && <TopCollegesSelector />}
    </>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default SchoolSection;
