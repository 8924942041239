import { $createCodeNode } from '@lexical/code';
import { $wrapLeafNodesInElements } from '@lexical/selection';
import classNames from 'classnames';
import { $getSelection, $isRangeSelection, $createParagraphNode } from 'lexical';
import type { FC } from 'react';

import { Icon } from 'src/components/Icon';
import {
  useToolbarContext,
  BlockType,
} from 'src/components/LexicalEditor/plugins/toolbar/ToolbarContext';

const CodeBlock: FC = () => {
  const { editor, blockType } = useToolbarContext();

  const formatCode = () => {
    if (blockType !== BlockType.code) {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          $wrapLeafNodesInElements(selection, () => $createCodeNode());
        }
      });
    } else {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          $wrapLeafNodesInElements(selection, () => $createParagraphNode());
        }
      });
    }
  };

  return (
    <button
      type="button"
      title="Code Block"
      onClick={formatCode}
      className={classNames('toolbar-item', { active: blockType === BlockType.code })}
      aria-label="Format Quote"
    >
      <Icon name="codeBlock" local color="currentColor" size="24px" />
    </button>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default CodeBlock;
