import { getRestClient } from '../commonSettings';
import type { WorkspaceId } from '../types/common';
import type { EmptyResponse } from '../types/data/common';
import type { GoogleFile } from '../types/google';

export function getGoogleFiles(workspaceId: WorkspaceId) {
  const internalFetch = getRestClient();
  return internalFetch.get<{ files: GoogleFile[] }>(
    `/google/v2/files/?workspace_id=${workspaceId}`,
  );
}

export function updateGoogleFile(
  id: string,
  workspaceId: WorkspaceId,
  payload: Partial<{ name: string }>,
) {
  const internalFetch = getRestClient();
  return internalFetch.patch<EmptyResponse>(`/google/v2/files/${id}/`, { workspaceId, ...payload });
}

export function createGoogleFile({
  fileName,
  mimeType,
  workspaceId,
}: {
  fileName: string;
  mimeType: string;
  workspaceId: number;
}) {
  const internalFetch = getRestClient();
  return internalFetch.post<{ webViewLink: string }>(`/google/v2/files/`, {
    name: fileName,
    mimeType,
    workspaceId,
  });
}
