import { externalLink } from '@polygence/common';

import TeamMemberTile from 'src/components/Tiles/TeamMemberTile';
import studentTeamMemberImage from 'src/components/static/images/team_member/Geordyn.png';
import { STUDENT_TEAM_EMAIL } from 'src/constants';

export const studentTeamMember = {
  firstName: 'Geordyn',
  role: 'Project Support Manager',
};

const StudentTeamMemberTile = () => {
  return (
    <TeamMemberTile
      imageUrl={studentTeamMemberImage}
      firstName={studentTeamMember.firstName}
      role={studentTeamMember.role}
    >
      <a href={`mailto:${STUDENT_TEAM_EMAIL}`} {...externalLink}>
        Reach out to {studentTeamMember.firstName}
      </a>
    </TeamMemberTile>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default StudentTeamMemberTile;
