import type { PaymentIntentInvoice } from '@polygence/common/types/payment';
import { PaymentIntentTypes } from '@polygence/common/types/payment';
import { Heading, Text, cn } from '@polygence/components';

import { PaymentIntentAmountOrPlaceholder } from 'src/payment/PaymentIntent/PaymentIntentAmountOrPlaceholder';
import { PaymentIntentTypeOption } from 'src/payment/PaymentIntent/PaymentIntentTypeOption';

interface PaymentIntentTypeSelectorProps {
  paymentIntent: PaymentIntentInvoice;
  onChange: (type: PaymentIntentTypes) => void;
}

export const PaymentIntentTypeSelector = ({
  paymentIntent: {
    type: paymentIntentType,
    featureTypeChangeByUser,
    baseAmount,
    installmentAmount,
    installmentCount,
    riskPremium,
    pitchedProducts,
  },
  onChange,
}: PaymentIntentTypeSelectorProps) => {
  const availablePaymentTypes = [
    PaymentIntentTypes.ONE_TIME,
    PaymentIntentTypes.SUBSCRIPTION,
  ].filter((type) => featureTypeChangeByUser || type === paymentIntentType);

  return (
    <div className="tw-flex tw-flex-col tw-gap-5">
      {availablePaymentTypes.length > 1 && (
        <Heading as="h2" size="h5" alignment="center">
          Choose your payment method:
        </Heading>
      )}
      <div
        className={cn(
          !pitchedProducts.length && 'md:tw-flex-row md:tw-justify-center',
          'flex-md-row flex-lg-column tw-flex tw-flex-col tw-gap-5',
        )}
      >
        {availablePaymentTypes.includes(PaymentIntentTypes.ONE_TIME) && (
          <PaymentIntentTypeOption
            name="One-Time Payment"
            value={PaymentIntentTypes.ONE_TIME}
            active={paymentIntentType === PaymentIntentTypes.ONE_TIME}
            onChange={onChange}
          >
            <PaymentIntentAmountOrPlaceholder size="large" fontWeight="light" amount={baseAmount} />
            <ul className="tw-mb-0 tw-inline-block tw-text-xs tw-leading-normal">
              <li>Pay in a single payment now</li>
              {riskPremium > 0 && <li>You save {riskPremium}% with this option</li>}
            </ul>
          </PaymentIntentTypeOption>
        )}
        {availablePaymentTypes.includes(PaymentIntentTypes.SUBSCRIPTION) && (
          <PaymentIntentTypeOption
            name="Monthly Installment Payment"
            value={PaymentIntentTypes.SUBSCRIPTION}
            active={paymentIntentType === PaymentIntentTypes.SUBSCRIPTION}
            onChange={onChange}
          >
            <div className="tw-flex tw-items-center tw-justify-center tw-gap-3">
              <PaymentIntentAmountOrPlaceholder
                size="large"
                fontWeight="light"
                amount={installmentAmount}
              />
              <Text size="large" fontWeight="light">
                x {installmentCount}
              </Text>
            </div>
            <ul className="tw-mb-0 tw-inline-block tw-text-xs tw-leading-normal">
              <li>Pay in smaller installments over {installmentCount} consecutive months</li>
              <li>Automatically charged each month</li>
            </ul>
          </PaymentIntentTypeOption>
        )}
      </div>
    </div>
  );
};
