import * as backendApi from '@polygence/common/api/backend';
import { useEffect, useRef, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { StringParam, useQueryParams } from 'use-query-params';

import { PageLoad } from 'src/components/PageLoad';

export const DiscourseRedirect = ({ loggedIn }: { loggedIn: boolean }) => {
  const [query] = useQueryParams({ sso: StringParam, sig: StringParam });
  const [loading, setLoading] = useState(true);
  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      const { sso, sig } = query;
      if (!loggedIn || !sso || !sig) {
        setLoading(false);
        return;
      }
      backendApi
        .discourseSso(sso, sig)
        .then(({ data: { url } }) => {
          window.location.assign(url);
        })
        .catch(() => {
          toast.error('Something went wrong');
          setLoading(false);
        });
    }
  }, [loggedIn, query]);

  if (loading) {
    return <PageLoad />;
  }

  if (!loggedIn && query.sso && query.sig) {
    return (
      <Redirect
        to={{
          pathname: '/user/login',
          state: { from: `/discourse-sso?sso=${query.sso}&sig=${query.sig}` },
        }}
      />
    );
  } else if (!loggedIn) {
    return <Redirect to="/user/login" />;
  } else if (!query.sso || !query.sig) {
    toast.error('Missing parameters!');
  }
  return <Redirect to="/" />;
};
