import {
  type MentorAvailabilityList,
  type MentorAvailabilityRetrieveUpdateDestroy,
  type MentorProfileId,
} from '@polygence/common';
import { useUpdateMentorAvailabilityMutation } from '@polygence/common/api/mentor-availability';
import { Text, ToggleSwitch } from '@polygence/components';
import { toast } from 'react-toastify';

import DateWrapper, { DateFormat } from 'src/components/aux/dateWrapper';
import { DatePickerWithLabel } from 'src/components/common/DatePickerWithLabel';
import styles from 'src/components/mentor/Availability/MentorAvailability.module.scss';
import { getLabelForProjectType } from 'src/components/mentor/Availability/getLabelForProjectType';

const CAPACITY_OPTIONS: number[] = [1, 2, 3, 4, 5];

interface MentorAvailabilityProps {
  mentorProfileId: MentorProfileId;
  mentorAvailability: MentorAvailabilityList;
  skipCapacity?: boolean;
  condensed?: boolean;
}

const CapacitySetting = ({
  mentorAvailability,
  changeMentorAvailability,
  isLoading,
}: {
  mentorAvailability: MentorAvailabilityList;
  changeMentorAvailability: (change: Partial<MentorAvailabilityRetrieveUpdateDestroy>) => void;
  isLoading: boolean;
}) => {
  return (
    <>
      <Text size="small" className="tw-p-0">
        Number of {getLabelForProjectType(mentorAvailability.projectType.value)} students
      </Text>
      <select
        name="capacity"
        title="capacity"
        onChange={({ target: { value } }) =>
          changeMentorAvailability({ capacity: parseInt(value) })
        }
        className="form-control tw-p-1 tw-text-center"
        value={mentorAvailability.capacity ?? ''}
        disabled={isLoading}
      >
        <option key="unlimited" value="" disabled hidden>
          Unlimited
        </option>
        {CAPACITY_OPTIONS.map((capacityOption: number) => (
          <option key={capacityOption} value={capacityOption}>
            {capacityOption}
          </option>
        ))}
      </select>
    </>
  );
};

const BandwidthSetting = ({
  mentorAvailability,
  changeMentorAvailability,
  isLoading,
}: {
  mentorAvailability: MentorAvailabilityList;
  changeMentorAvailability: (change: Partial<MentorAvailabilityRetrieveUpdateDestroy>) => void;
  isLoading: boolean;
}) => {
  return (
    <>
      <Text size="small" className="tw-p-0">
        When will you have more bandwidth?
      </Text>
      <DatePickerWithLabel
        id="unavailable_until"
        name="unavailable_until"
        className="tw-max-w-42 tw-m-0 tw-w-44 tw-p-1"
        inputClassName="form-control tw-p-1 tw-text-center"
        disabled={isLoading}
        value={mentorAvailability.unavailableUntil}
        dateFormat={DateFormat.FORMAT_14}
        onChange={({ target: { value } }) =>
          changeMentorAvailability({
            unavailableUntil: value ? new DateWrapper(value).format(DateFormat.FORMAT_3) : null,
          })
        }
      />
    </>
  );
};

export const MentorAvailability = ({
  mentorProfileId,
  mentorAvailability,
  skipCapacity,
  condensed = false,
}: MentorAvailabilityProps) => {
  const [updateMentorAvailability, { isLoading }] = useUpdateMentorAvailabilityMutation();

  const changeMentorAvailability = (change: Partial<MentorAvailabilityRetrieveUpdateDestroy>) => {
    updateMentorAvailability({
      mentorProfileId: mentorProfileId,
      mentorAvailabilityId: mentorAvailability.id,
      payload: change,
    })
      .unwrap()
      .catch(() => toast.error('Unable to update availability!'));
  };

  if (condensed) {
    return (
      <div className="tw-m-2 tw-flex tw-items-center tw-gap-2">
        <ToggleSwitch
          aria-label="availability"
          checked={mentorAvailability.available}
          onCheckedChange={() =>
            changeMentorAvailability({ available: !mentorAvailability.available })
          }
          disabled={isLoading}
        />
        <Text
          size="medium"
          fontWeight={mentorAvailability.available ? 'bold' : 'normal'}
          className="tw-flex-1 tw-p-0"
        >
          {getLabelForProjectType(mentorAvailability.projectType.value)}
        </Text>
        {mentorAvailability.available && !skipCapacity && (
          <div className="tw-flex tw-gap-2">
            <CapacitySetting
              mentorAvailability={mentorAvailability}
              changeMentorAvailability={changeMentorAvailability}
              isLoading={isLoading}
            />
          </div>
        )}
        {!mentorAvailability.available && (
          <BandwidthSetting
            mentorAvailability={mentorAvailability}
            changeMentorAvailability={changeMentorAvailability}
            isLoading={isLoading}
          />
        )}
      </div>
    );
  }

  return (
    <>
      <div>
        {skipCapacity ? (
          <Text size={{ base: 'small', md: 'medium' }}>
            Are you currently available to mentor <br />
            <b>{getLabelForProjectType(mentorAvailability.projectType.value)}</b> students?
          </Text>
        ) : (
          <Text size={{ base: 'small', md: 'medium' }}>
            Are you currently available to mentor <br />
            <b>{getLabelForProjectType(mentorAvailability.projectType.value)}</b> students and if
            so, how many?
          </Text>
        )}
      </div>
      <div className={styles['grid']}>
        <Text size="small" className="tw-p-0">
          Available to mentor students
        </Text>
        <ToggleSwitch
          aria-label="availability"
          checked={mentorAvailability.available}
          onCheckedChange={() =>
            changeMentorAvailability({ available: !mentorAvailability.available })
          }
          disabled={isLoading}
        />
        {mentorAvailability.available && !skipCapacity && (
          <CapacitySetting
            mentorAvailability={mentorAvailability}
            changeMentorAvailability={changeMentorAvailability}
            isLoading={isLoading}
          />
        )}
        {!mentorAvailability.available && (
          <BandwidthSetting
            mentorAvailability={mentorAvailability}
            changeMentorAvailability={changeMentorAvailability}
            isLoading={isLoading}
          />
        )}
      </div>
    </>
  );
};

export const PremiumShowcasingAvailability = ({
  mentorAvailabilities,
}: {
  mentorAvailabilities: MentorAvailabilityList[];
}) => {
  const [updateMentorAvailability, { isLoading }] = useUpdateMentorAvailabilityMutation();

  const changeMentorAvailability = (change: Partial<MentorAvailabilityRetrieveUpdateDestroy>) => {
    Promise.all(
      mentorAvailabilities.map((mentorAvailability) =>
        updateMentorAvailability({
          mentorProfileId: mentorAvailability.mentorProfile,
          mentorAvailabilityId: mentorAvailability.id,
          payload: change,
        }).unwrap(),
      ),
    ).catch(() => toast.error('Unable to update availability!'));
  };

  const mentorAvailability = mentorAvailabilities.length > 0 ? mentorAvailabilities[0] : null;
  if (!mentorAvailability) {
    return null;
  }

  return (
    <div className="tw-m-2 tw-flex tw-items-center tw-gap-2">
      <ToggleSwitch
        aria-label="availability"
        checked={mentorAvailability.available}
        onCheckedChange={() =>
          changeMentorAvailability({ available: !mentorAvailability.available })
        }
        disabled={isLoading}
      />
      <Text
        size="medium"
        fontWeight={mentorAvailability.available ? 'bold' : 'normal'}
        className="tw-flex-1 tw-p-0"
      >
        Premium Showcasing
      </Text>
      {!mentorAvailability.available && (
        <BandwidthSetting
          mentorAvailability={mentorAvailability}
          changeMentorAvailability={changeMentorAvailability}
          isLoading={isLoading}
        />
      )}
    </div>
  );
};
