import { axiosBaseQuery } from '@polygence/common/api/fetch';
import type { Paginated } from '@polygence/common/types/data/common';
import type {
  OpportunityPublic,
  ShowcasingSearchParams,
  OpportunityKeyword,
  OpportunityType,
  OpportunityAgeLevel,
  OpportunityApproximateReviewTime,
} from '@polygence/common/types/showcasing';
import { createApi } from '@reduxjs/toolkit/query/react';

export const showcasingApi = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: 'showcasing',
  endpoints: (builder) => {
    return {
      getTypes: builder.query<OpportunityType[], void>({
        query: () => {
          return '/showcasing/types/';
        },
      }),
      getKeywords: builder.query<OpportunityKeyword[], void>({
        query: () => {
          return '/showcasing/keywords/';
        },
      }),
      getAgeLevels: builder.query<OpportunityAgeLevel[], void>({
        query: () => {
          return '/showcasing/age-levels/';
        },
      }),
      getReviewTimes: builder.query<OpportunityApproximateReviewTime[], void>({
        query: () => {
          return '/showcasing/review-times/';
        },
      }),
      getOpportunities: builder.query<Paginated<OpportunityPublic>, ShowcasingSearchParams>({
        query: (params) => {
          return { url: '/showcasing/opportunity/', method: 'get', params };
        },
      }),
    };
  },
});

export const {
  useGetTypesQuery,
  useGetKeywordsQuery,
  useGetAgeLevelsQuery,
  useGetReviewTimesQuery,
  useGetOpportunitiesQuery,
} = showcasingApi;
