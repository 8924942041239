import { GptProjectIdeaWidget } from 'src/components/common/form/GptWidget/GptProjectIdeaWidget';

interface ProjectIdeaAssistProps {
  openWidgetTitle?: string;
  disabled?: boolean;
}

export const ProjectIdeaAssist = ({
  openWidgetTitle,
  disabled = false,
}: ProjectIdeaAssistProps) => {
  if (disabled) {
    return <></>;
  }

  return <GptProjectIdeaWidget openWidgetTitle={openWidgetTitle} />;
};
