import type { DayOfWeek, TimeSlot } from '@polygence/common';
import * as hermesApi from '@polygence/common/api/hermes';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { modifyEndTime, modifyStartTime } from 'src/utils/mentorWorkingHours';

const UPDATED = 'Updated successfully!';
const ERROR = 'Something went wrong!';

export const daysOfWeek: DayOfWeek[] = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

export const useMentorWorkingHours = () => {
  const [timeSlots, setTimeSlots] = useState<TimeSlot[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    hermesApi
      .getTimeSlots()
      .then(({ data }) => {
        setTimeSlots(data);
      })
      .catch(() => {
        toast.error('Something went wrong while retrieving working hours!');
      })
      .finally(() => setLoading(false));
  }, []);

  const isDayEnabled = (day: DayOfWeek) => {
    const enabledDays = timeSlots.map((slot) => slot.day);
    return enabledDays.includes(day);
  };

  /* eslint-disable fp/no-mutation */
  const createNextTimeSlot = (currentTimeSlot: TimeSlot) => {
    let startHour = parseInt(currentTimeSlot.endAt.split(':')[0] as string);
    let startMinute = parseInt(currentTimeSlot.endAt.split(':')[1] as string);
    let endHour = 0;
    let endMinute = 0;
    if (startMinute === 0) {
      startMinute = 30;
    } else {
      startHour = startHour + 1;
      startMinute = 0;
    }
    endHour = startHour + 1;
    endMinute = startMinute;
    if (endHour >= 24) {
      endHour = 0;
      endMinute = 0;
    }
    if (startHour >= 24) {
      toast.error("Can't add more working hours to the selected day!");
      return;
    }
    const startAt = `${startHour}:${startMinute}:00`;
    const endAt = `${endHour}:${endMinute}:00`;
    createTimeSlotsFor(currentTimeSlot.day, startAt, endAt);
  };
  /* eslint-enable fp/no-mutation */

  const createTimeSlotsFor = (day: DayOfWeek, startAt = '09:00:00', endAt = '17:00:00') => {
    setLoading(true);

    hermesApi
      .createTimeSlot({
        startAt: startAt,
        endAt: endAt,
        day,
      })
      .then(({ data }) => {
        setTimeSlots((prevTimeSlots) => {
          return [...prevTimeSlots, data];
        });
        toast.success(UPDATED);
      })
      .catch(() => {
        toast.error(ERROR);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const disableTimeSlotsFor = (day: DayOfWeek) => {
    const timeSlotsToDelete = timeSlots.filter((slot) => slot.day === day);
    setLoading(true);
    const promises = timeSlotsToDelete.map((timeSlot) => {
      return hermesApi.deleteTimeSlot(timeSlot.id);
    });
    Promise.all(promises)
      .then(() => {
        setTimeSlots((prevState) => {
          return prevState.filter((slot) => slot.day !== day);
        });
        toast.success(UPDATED);
      })
      .catch(() => {
        toast.error(ERROR);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteTimeSlot = (id: number) => {
    setLoading(true);
    hermesApi
      .deleteTimeSlot(id)
      .then(() => {
        setTimeSlots((prevState) => {
          return prevState.filter((slot) => slot.id !== id);
        });
        toast.success(UPDATED);
      })
      .catch(() => {
        toast.error(ERROR);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const toggleTimeSlotsFor = (day: DayOfWeek) =>
    isDayEnabled(day) ? disableTimeSlotsFor(day) : createTimeSlotsFor(day);

  const getTimeSlotsFor = (day: DayOfWeek) => {
    return timeSlots.filter((slot) => slot.day === day);
  };

  /* eslint-disable fp/no-mutation */
  const updateSlot = (slotId: number, startAt: string | null, endAt: string | null) => {
    const slotToUpdate = timeSlots.find((slot) => slot.id === slotId);
    if (!slotToUpdate) {
      return;
    }
    const payload: Record<'startAt' | 'endAt', string> = {
      startAt: slotToUpdate.startAt,
      endAt: slotToUpdate.endAt,
    };
    if (startAt) {
      payload['startAt'] = startAt;
      payload['endAt'] = modifyEndTime(startAt, slotToUpdate.endAt);
    }
    if (endAt) {
      payload['startAt'] = modifyStartTime(slotToUpdate.startAt, endAt);
      payload['endAt'] = endAt;
    }

    hermesApi
      .updateTimeSlot(slotId, payload)
      .then(({ data }) => {
        setTimeSlots((prevState) => {
          const updatedIndex = prevState.findIndex((slot) => slot.id === slotId);
          const newState = prevState.slice(0);
          newState[updatedIndex] = data;
          return newState;
        });
        toast.success(UPDATED);
      })
      .catch((e) => {
        console.error(e);
      });
  };
  /* eslint-enable fp/no-mutation */

  const changeStartAt = (newTime: string, slotId: number) => {
    updateSlot(slotId, newTime, null);
  };

  const changeEndAt = (newTime: string, slotId: number) => {
    updateSlot(slotId, null, newTime);
  };

  return {
    createNextTimeSlot,
    deleteTimeSlot,
    timeSlots,
    setTimeSlots,
    isDayEnabled,
    getTimeSlotsFor,
    toggleTimeSlotsFor,
    loading,
    changeStartAt,
    changeEndAt,
  };
};
