import type { StudentProject } from '@polygence/common/types/data/marketplace';
import type { Project } from '@polygence/common/types/hermes';
import type { Nullable } from '@polygence/common/types/utils';
import { Select, OptionType } from '@polygence/components';
import type { CamelCasedPropertiesDeep } from 'type-fest';

type ProjectOption = Pick<Project, 'id' | 'title'>;

interface SelectorProps {
  projects: CamelCasedPropertiesDeep<StudentProject>[];
  defaultOption: Nullable<ProjectOption>;
  onSelect: (_: Nullable<ProjectOption>) => void;
}

const convertOptionToProject = (option: Nullable<OptionType>): Nullable<ProjectOption> => {
  return option ? { id: parseInt(`${option.value}`), title: option.label } : option;
};

const convertProjectToOption = (project: ProjectOption): OptionType => {
  return {
    label: project.title ? project.title : `Project #${project.id}`,
    value: `${project.id}`,
  };
};

const ShowcasingProjectsSelector = ({ projects, defaultOption, onSelect }: SelectorProps) => {
  const internalDefaultOption = defaultOption ? convertProjectToOption(defaultOption) : undefined;

  return (
    <div className="select-element form-group">
      <label className="form-label" htmlFor="project-selector">
        Project
      </label>
      <Select
        name="project-selector"
        options={projects.map(convertProjectToOption)}
        defaultValue={internalDefaultOption}
        onChange={(option) => {
          onSelect(convertOptionToProject(option as Nullable<OptionType>));
        }}
        placeholder="Select..."
        isClearable
      />
    </div>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default ShowcasingProjectsSelector;
