/* eslint-disable fp/no-mutation */
import { useEffect } from 'react';

import { getMyInfo } from 'src/components/getMyInfo';
import { isDevelopment } from 'src/utils';
import { setClarityVariables } from 'src/utils/tracking/clarity';

declare global {
  interface Window {
    clarity: { q: IArguments[] };
  }
}

const initializeClarity = () => {
  const doesElementExists = document.getElementById('clarity-init');
  if (!doesElementExists) {
    window.clarity =
      window.clarity ||
      function () {
        // eslint-disable-next-line prefer-rest-params
        (window.clarity.q = window.clarity.q || []).push(arguments);
      };
    const clarity = document.createElement('script');
    clarity.id = 'clarity-init';
    clarity.type = 'text/javascript';
    clarity.async = true;
    clarity.src = 'https://www.clarity.ms/tag/mxhdkb8dqm';
    document?.getElementsByTagName('head')?.[0]?.appendChild(clarity);
  }
};

export const Clarity = () => {
  const myInfo = getMyInfo();

  if (!isDevelopment()) {
    initializeClarity();
  }

  useEffect(() => {
    if (!isDevelopment()) {
      setClarityVariables({
        fullName: `${myInfo?.first_name} ${myInfo?.last_name}`,
        userId: myInfo?.user_id,
        email: myInfo?.email,
      });
    }
  }, [myInfo?.first_name, myInfo?.last_name, myInfo?.user_id, myInfo?.email]);

  return null;
};
