import { commonHooks } from '@polygence/common';
import { cn, Heading, Text } from '@polygence/components';

import styles from 'src/components/common/form/GptWidget/GptProjectIdeaWidget.module.scss';
import { ProjectIdeaCopyToClipboardCTA } from 'src/components/projectIdeaGenerator/ProjectIdeaCopyToClipboardCTA';
import { ProjectIdeaStartApplicationCTA } from 'src/components/projectIdeaGenerator/ProjectIdeaStartApplicationCTA';

interface ProjectIdeaCardProps {
  idea: string;
  title: string;
  index: number;
}

export const ProjectIdeaCard = ({ title, idea, index }: ProjectIdeaCardProps) => {
  const user = commonHooks.useCurrentUser();

  return (
    <div className={cn('tw-flex tw-flex-col tw-gap-5', styles['ideaWrapper'])}>
      <Heading as="h2" size="h3" alignment="left">
        {title}
      </Heading>
      <Text size="medium">{idea}</Text>
      {user.utilities.isStudent ? (
        <ProjectIdeaStartApplicationCTA index={index} />
      ) : (
        <ProjectIdeaCopyToClipboardCTA idea={idea} />
      )}
    </div>
  );
};
