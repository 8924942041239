import { toOption } from '@polygence/common';
import * as marketplaceApi from '@polygence/common/api/marketplace';
import type { OptionType } from '@polygence/components';
import { AsyncCreatableSelect, Text } from '@polygence/components';
import { debounce } from 'lodash';
import { useState } from 'react';
import type { MultiValue, SingleValue, ActionMeta } from 'react-select';

export const SingleScholarPageSelector = ({
  label,
  defaultValue,
  onChange,
  isInvalid,
  errorMessage,
}: {
  label: string;
  defaultValue: SingleValue<OptionType> | undefined;
  onChange: (event: {
    target: { name: string; value: string | { value: number; label: string } | null };
  }) => void;
  isInvalid: boolean;
  errorMessage?: string;
}) => {
  const [value, setValue] = useState(defaultValue);
  const [isDirty, setIsDirty] = useState(false);

  const loadOptions = debounce(
    (inputValue: string, callback: (options: MultiValue<OptionType>) => void) => {
      if (!inputValue) {
        return;
      }

      marketplaceApi
        .getScholarPages(inputValue)
        .then(({ data }) =>
          callback(
            data.results.map(({ fullName, id, availableSchool }) => {
              const label = availableSchool ? `${fullName} | ${availableSchool}` : fullName;

              return {
                label,
                value: id,
              };
            }),
          ),
        )
        .catch(console.error);
    },
    500,
  );

  const onCreateOption = (inputValue: string) => {
    onChange({ target: { name: 'wordOfMouthReferral', value: inputValue } });
    onChange({ target: { name: 'wordOfMouthReferralExistingScholar', value: null } });
    setValue(toOption(inputValue));
  };

  const handleOnSelect = (
    newValue: SingleValue<OptionType>,
    { action }: ActionMeta<OptionType>,
  ) => {
    if (action === 'clear') {
      setValue(null);
      onChange({ target: { name: 'wordOfMouthReferral', value: null } });
      onChange({ target: { name: 'wordOfMouthReferralExistingScholar', value: null } });
      return;
    }

    if (newValue) {
      onChange({
        target: {
          name: 'wordOfMouthReferralExistingScholar',
          value: { value: +newValue.value, label: newValue.label },
        },
      });
      onChange({ target: { name: 'wordOfMouthReferral', value: null } });
      setValue(newValue);
    }
  };

  return (
    <>
      <AsyncCreatableSelect
        isClearable
        isInvalid={isInvalid && isDirty}
        defaultValue={defaultValue}
        loadOptions={loadOptions}
        onCreateOption={onCreateOption}
        label={label}
        value={value}
        onChange={handleOnSelect}
        onBlur={() => setIsDirty(true)}
        placeholder="Search for your friend's name"
        formatCreateLabel={(inputValue: string) => (
          <div>
            <span className="text-muted text-small d-block">
              Didn't find your referrer in the list?
            </span>
            <span>Add "{inputValue}"</span>
          </div>
        )}
      />
      {errorMessage && (
        <Text size="small" className="text-muted">
          {errorMessage}
        </Text>
      )}
    </>
  );
};
