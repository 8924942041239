import { dayjs } from 'src/utils/dayjsCustom';

export const enum DateTimeFormat {
  FORMAT_1 = 'MMM. D YYYY, h:mm A',
  FORMAT_2 = 'LLLL z',
  FORMAT_3 = 'LLLL',
  FORMAT_4 = 'YYYY-MM-DDTH:m',
  FORMAT_5 = 'MMM. Do [at] h:mma',
  FORMAT_6 = 'MMM. Do, YYYY [at] h:mma (z)',
  FORMAT_7 = 'MM/DD/YYYY (ddd), HH:mmA Z',
  FORMAT_8 = 'MMMM D, YYYY H:mma (z)',
  FORMAT_9 = 'MMMM DD, YYYY, h:mma',
  FORMAT_10 = 'MMM. D YYYY, h:mm A (z)',
  FORMAT_11 = 'MMM. Do [at] h:mma (z)',
  FORMAT_12 = 'llll (z)',
  FORMAT_13 = 'LLLL (zzz)',
  FORMAT_14 = 'MMMM D, hh:mm A',
  FORMAT_15 = 'MMMM D, hh:mm A (dddd)',
  FORMAT_16 = 'h:mma, dddd, MMMM D, YYYY',
  FORMAT_17 = 'MMMM DD, YYYY [at] h:mma z',
  FORMAT_18 = 'YYYY-MM-DDTHH:mm:ss.SSSZ',
  FORMAT_19 = 'YYYY-MM-DDTHH:mm',
}

export const enum DateFormat {
  FORMAT_1 = 'MMM YYYY',
  FORMAT_2 = 'MMM. Do, YYYY',
  FORMAT_3 = 'YYYY-MM-DD',
  FORMAT_4 = 'll',
  FORMAT_5 = 'MMM',
  FORMAT_6 = 'MM/DD/YYYY',
  FORMAT_7 = 'MMMM YYYY',
  FORMAT_8 = 'dddd, MMMM D, YYYY',
  FORMAT_9 = 'YYYY',
  FORMAT_10 = 'MMM D, YYYY',
  FORMAT_11 = 'MMMM D',
  FORMAT_12 = 'MMMM D, YYYY',
  FORMAT_13 = 'MMM D',
  FORMAT_14 = 'YYYY-MM-dd',
}

export const enum TimeFormat {
  FORMAT_1 = 'HH:mm',
  FORMAT_2 = 'h:mma (z)',
  FORMAT_3 = 'h:mma',
}

export const enum TimezoneFormat {
  FORMAT_1 = 'Z',
}

export const DEFAULT_TIMEZONE = 'America/Los_Angeles';

class DateWrapper {
  date: dayjs.Dayjs;

  constructor(date?: string | number | Date | dayjs.Dayjs) {
    this.date = dayjs(date);
  }

  static utc(date?: string | number | Date | dayjs.Dayjs): DateWrapper {
    return new DateWrapper(dayjs(date).utc());
  }

  static guessTimezone(): string {
    return dayjs.tz.guess();
  }

  isValid(): boolean {
    return this.date.isValid();
  }

  setTimezone(timezone: string | undefined, fallback: 'guess' | 'default' = 'guess'): DateWrapper {
    try {
      this.date = this.date.tz(timezone);
    } catch (e) {
      if (e instanceof RangeError) {
        if (fallback === 'default') {
          this.date = this.date.tz(DEFAULT_TIMEZONE);
        } else {
          this.date = this.date.tz(DateWrapper.guessTimezone());
        }
      }
    }
    return this;
  }

  format(format?: DateTimeFormat | DateFormat | TimeFormat | TimezoneFormat): string {
    return this.date.format(format);
  }
}

// eslint-disable-next-line import/no-default-export -- autodisabled
export default DateWrapper;
