/* eslint-disable react/prop-types */
import { Select } from '@polygence/components';
import React, { useState, useEffect, useMemo } from 'react';

import { FormInputWithValidation as FormInput } from 'src/components/FormInputWithValidation';

const SelectWithClarification = ({
  options,
  helpText = '',
  label,
  clarificationLabel = 'Please elaborate below',
  name,
  clarificationName,
  value,
  clarificationValue,
  onChange: handleChange,
  onBlur: handleBlur,
  onError = {},
  required = false,
  clarificationRequired = true,
  async = false,
  isMulti = false,
  size = 'small',
}) => {
  const [internalOptions, setInternalOptions] = useState([]);
  const [internalValue, setInternalValue] = useState();

  useEffect(() => {
    if (async) {
      options
        .then((result) => {
          setInternalOptions(result);
        })
        .catch(() => {
          setInternalOptions([]);
        });
    } else {
      setInternalOptions(options);
    }
  }, [options, async]);

  useEffect(() => {
    if (isMulti) {
      setInternalValue(
        internalOptions.filter((e) => {
          return value?.includes(e.value);
        }),
      );
    } else {
      setInternalValue(
        internalOptions.find((e) => {
          return e.value === value;
        }),
      );
    }
  }, [internalOptions, isMulti, value]);

  const error = onError[name];
  const clarificationError = onError[clarificationName];

  const defaultValue = useMemo(() => {
    if (async) {
      return null;
    }
    if (isMulti) {
      return options.filter((e) => {
        return value?.includes(e.value);
      });
    }
    return options.find((e) => {
      return e.value === value;
    });
  }, [async, isMulti, options, value]);

  return (
    // eslint-disable-next-line react/jsx-filename-extension -- autodisabled
    <div className="form-group">
      <label
        htmlFor={name}
        className={required ? 'required' : ''}
        // eslint-disable-next-line react/forbid-dom-props -- autodisabled
        style={{ cursor: 'pointer', marginBottom: 0 }}
      >
        {label}
      </label>
      {helpText && <div className="text-muted small">{helpText}</div>}
      <Select
        inputId={name}
        name={name}
        value={internalValue}
        isMulti={isMulti}
        defaultValue={defaultValue}
        options={internalOptions}
        // eslint-disable-next-line no-shadow
        onChange={(value, { name }) => {
          if (isMulti) {
            handleChange({
              target: {
                name,
                value: Array.isArray(value)
                  ? value.map(({ value: v }) => {
                      return v;
                    })
                  : [],
              },
            });
          } else {
            handleChange({ target: { name, value: value.value } });
          }
          setInternalValue(value);
        }}
        onBlur={handleBlur}
        size={size}
        isInvalid={error}
      />
      {error && <div className="text-danger small">{error}</div>}
      {(isMulti ? value?.length > 0 : value) && (
        <div className="mt-3">
          <FormInput
            type="text"
            label={
              internalOptions.find((e) => {
                return e.value === value;
              })?.question || clarificationLabel
            }
            name={clarificationName}
            value={clarificationValue}
            onChange={handleChange}
            onBlur={handleBlur}
            onError={[clarificationError]}
            required={clarificationRequired}
            htmlRequired={clarificationRequired}
          />
        </div>
      )}
    </div>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default SelectWithClarification;
