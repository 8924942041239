import { User, UserType } from '@polygence/common/types/backend';
import type { AttachmentDetailSerializer } from '@polygence/common/types/data/hermes';
import {
  ProjectType,
  type Assignment,
  type AssignmentBlueprint,
  type ExtensionRequest,
  type Lounge,
  type Project,
  type ProjectTypes,
  type Session,
  type Summary,
} from '@polygence/common/types/hermes';
import type { UserState } from '@polygence/common/types/user';
import type { Nullable } from '@polygence/common/types/utils';
import { camelCase } from 'lodash';

import { isStudent } from 'src/components/getMyInfo';
import {
  ASSIGNMENT_ACTIONS,
  EXTENSION_REQUEST_ACTIONS,
  MILESTONE_ACTIONS,
  PROJECT_STATUS,
} from 'src/components/hermes/constants';
import { studentPositiveFeedbackQuestions } from 'src/components/hermes/surveys/mentor/metaQuestions';
import { dayjs } from 'src/utils/dayjsCustom';

export const ENTER = 13;

export const getFilenameFromPath = (path: string) => {
  return path.split('/').pop();
};

export const splitFileNameAndExtension = (fileNameWithExtension: string) => {
  const indexOfExtension = fileNameWithExtension.lastIndexOf('.');
  const fileName = fileNameWithExtension.slice(0, indexOfExtension);
  const extension = fileNameWithExtension.slice(indexOfExtension + 1).toUpperCase();

  return [fileName, extension];
};

export const getCompletedSessionCount = (sessions: Session[] = []) => {
  const sessionCountedAsToNumber = {
    full_session: 1,
    half_session: 0.5,
    no_show: 0,
    not_session: 0,
    late_cancelled: 0,
  };

  return sessions.reduce((sum, session) => {
    if (session.summary && session.countAs) {
      return sum + sessionCountedAsToNumber[session.countAs];
    }
    return sum;
  }, 0);
};

export const isResearchQuestionAndAbstractAssignment = (blueprint: AssignmentBlueprint) => {
  return (
    blueprint?.assignmentNames?.includes('research_question') &&
    blueprint?.assignmentNames?.includes('research_description')
  );
};

export const getAttachmentIdByName = (
  attachments: AttachmentDetailSerializer[],
  attachmentName: string,
) => {
  return attachments.find((attachment) => {
    return attachment.content === attachmentName;
  })?.id;
};

const isMilestoneComplete = (
  assignmentBlueprint: AssignmentBlueprint,
  project: Project | undefined,
) => {
  if (assignmentBlueprint.dueSessionNumber !== 10) {
    return assignmentBlueprint.assignmentNames.every((assignmentName) => {
      return project?.details[camelCase(assignmentName)];
    });
  }
  return assignmentBlueprint.assignmentNames.some((assignmentName) => {
    if (project?.details) {
      return project.details[camelCase(assignmentName)];
    }
    return false;
  });
};

export const getMilestoneAssignmentAction = (
  assignmentBlueprint: AssignmentBlueprint | undefined,
  project: Project | undefined,
) => {
  if (!assignmentBlueprint) {
    return null;
  }
  if (isMilestoneComplete(assignmentBlueprint, project)) {
    return isStudent()
      ? MILESTONE_ACTIONS.submittedAssignment
      : MILESTONE_ACTIONS.viewSubmittedAssignment;
  }
  return isStudent()
    ? MILESTONE_ACTIONS.submitAssignment
    : MILESTONE_ACTIONS.toBeSubmittedAssignment;
};

export const getAssignmentAction = (assignments: Assignment[]) => {
  const submittedAssignments = assignments.filter(
    ({ assignmentSolution }) => !!assignmentSolution?.submittedAt,
  );
  const isAllSubmitted = assignments.length === submittedAssignments.length;

  if (isAllSubmitted) {
    return {
      ...ASSIGNMENT_ACTIONS.submittedAssignment,
      sessionCardButtonLabel: `${submittedAssignments.length}/${assignments.length} Submitted`,
    };
  }

  return isStudent()
    ? ASSIGNMENT_ACTIONS.submitAssignment
    : {
        ...ASSIGNMENT_ACTIONS.toBeSubmittedAssignment,
        sessionCardButtonLabel: `${submittedAssignments.length}/${assignments.length} Submitted`,
      };
};

export const getExtensionRequestAction = (
  extensionRequest: ExtensionRequest | undefined,
  currentUser: UserState,
) => {
  const isActive = extensionRequest?.status === 'requested';
  const isRequestedByUser = currentUser.userType === extensionRequest?.requestedByUserType;

  if (isActive) {
    if (isRequestedByUser) {
      // eslint-disable-next-line eqeqeq -- autodisabled
      return currentUser.userType == UserType.STUDENT
        ? EXTENSION_REQUEST_ACTIONS.student_view
        : EXTENSION_REQUEST_ACTIONS.mentor_view;
    }
    return EXTENSION_REQUEST_ACTIONS.respond;
  }

  return currentUser.userType === UserType.STUDENT
    ? EXTENSION_REQUEST_ACTIONS.request
    : EXTENSION_REQUEST_ACTIONS.extend_deadline;
};

export const getMonogramOf = (user: User) => {
  let monogram = '';

  if (user.firstName) {
    // eslint-disable-next-line fp/no-mutation -- autodisabled
    monogram += user.firstName[0];
  }

  if (user.lastName) {
    // eslint-disable-next-line fp/no-mutation -- autodisabled
    monogram += user.lastName[0];
  }

  return monogram;
};

export const getOtherParticipants = (participants: User[], currentUser: Nullable<UserState>) => {
  return participants.filter(({ id }) => id !== currentUser?.id);
};

export const getRoomName = (
  lounge: Lounge | undefined | null,
  participants: User[],
  currentUser: Nullable<UserState>,
) => {
  if (lounge) {
    return lounge.name;
  }

  const otherParticipants = getOtherParticipants(participants, currentUser);
  return otherParticipants[0]?.firstName;
};

export const isProjectActive = (project: Project | undefined) => {
  return (
    project?.status === PROJECT_STATUS.ACTIVE && dayjs().isAfter(project?.expectedStartAt, 'minute')
  );
};

export const getSummaryContent = (summary?: Summary) =>
  summary?.content ||
  summary?.aiSessionSummary ||
  summary?.extras?.['pathfinderSessionDetails'] ||
  summary?.extras?.['sessionRecap'];

export const getPositiveFeedbackBadges = (summary?: Summary) => {
  if (!summary) {
    return [];
  }

  const positiveFeedbacksCheckboxes = studentPositiveFeedbackQuestions.props.children.filter(
    (element) => element.component === 'Checkbox',
  ) as { props: { label: string; name: string; id: string } }[];

  const positiveFeedbackProperties = positiveFeedbacksCheckboxes.map((element) => ({
    key: element.props.name,
    label: element.props.label,
  }));

  return positiveFeedbackProperties.filter(({ key }) => Boolean(summary.extras?.[key]));
};

export const getPositiveFeedbackNote = (summary?: Summary): string | undefined => {
  return (
    (summary?.extras?.['studentStarNote'] as string) ||
    (summary?.extras?.['studentEncouragement'] as string)
  );
};

export const PSS_TRACK_PROJECT_TYPES = [
  ProjectType.PREMIUM_SHOWCASING_PUBLISHING,
  ProjectType.PREMIUM_SHOWCASING_PRESENTING,
  ProjectType.PREMIUM_SHOWCASING_COMPETING,
  ProjectType.PREMIUM_SHOWCASING_MULTIMEDIA,
] as readonly ProjectTypes[];

export const isPSSTrackProjectType = (projectType: ProjectTypes): boolean => {
  return PSS_TRACK_PROJECT_TYPES.includes(projectType);
};
