import { InputField, Text } from '@polygence/components';
import { ChangeEvent, useState } from 'react';
import { Form } from 'react-bootstrap';

interface StudentInterestsProperties extends Record<string, boolean> {
  no_interests: boolean;
  college_admissions: boolean;
  recommendation_letters: boolean;
  mental_health: boolean;
  non_traditional_activities: boolean;
  publish_opportunities: boolean;
  pursue_passion: boolean;
  student_engagement: boolean;
  other_interests: boolean;
}

const EMPTY_INTEREST_STATE: StudentInterestsProperties = {
  no_interests: false,
  college_admissions: false,
  recommendation_letters: false,
  mental_health: false,
  non_traditional_activities: false,
  publish_opportunities: false,
  pursue_passion: false,
  student_engagement: false,
  other_interests: false,
};

interface AdmissionAdvisorStudentsInterestsProps {
  aaStudentsInterests: string[];
  aaStudentsInterestOther: string;
  onChange: (_: Record<string, unknown>) => void;
}

export const AdmissionAdvisorStudentsInterests = ({
  aaStudentsInterests = [],
  aaStudentsInterestOther,
  onChange,
}: AdmissionAdvisorStudentsInterestsProps) => {
  const [interests, setInterests] = useState(() => {
    const parsedObj = { ...EMPTY_INTEREST_STATE };
    for (const value of aaStudentsInterests) {
      // eslint-disable-next-line fp/no-mutation
      parsedObj[value] = true;
    }
    return parsedObj;
  });

  const [otherInterest, setOtherInterest] = useState(aaStudentsInterestOther);

  const submitChange = (updatedInterests: StudentInterestsProperties) => {
    const interestsArray = Object.keys(updatedInterests).filter((key) => updatedInterests[key]);
    onChange({
      target: { name: 'admission_advisor_students_interests', value: interestsArray },
    });
  };

  const handleOtherInterestChange = ({
    target: { name, value },
  }: ChangeEvent<HTMLInputElement>) => {
    setOtherInterest(value);
    onChange({ target: { name, value } });
  };

  const handleCheckboxChange = ({ target: { name, checked } }: ChangeEvent<HTMLInputElement>) => {
    const defaultInterests = { ...EMPTY_INTEREST_STATE, no_interests: true };
    if (name === 'no_interests' && checked) {
      setInterests(defaultInterests);
      submitChange(defaultInterests);
    } else {
      setInterests((prevState) => {
        const updatedInterests: StudentInterestsProperties = {
          ...prevState,
          no_interests: false,
          [name]: checked,
        };
        submitChange(updatedInterests);
        return updatedInterests;
      });
    }
  };

  return (
    <div className="mb-5">
      <Text alignment="left" size="medium" fontWeight="bold" className="mb-5">
        What reasons did the student share for why they are considering enrolling? (Select all that
        apply.)
      </Text>
      <Form.Check
        type="checkbox"
        label="No interests"
        id="no-interests"
        name="no_interests"
        checked={interests['no_interests']}
        onChange={handleCheckboxChange}
      />
      <Form.Check
        type="checkbox"
        label="College admissions"
        id="college-admissions"
        name="college_admissions"
        checked={interests['college_admissions']}
        onChange={handleCheckboxChange}
      />
      <Form.Check
        type="checkbox"
        label="Letters of recommendation"
        id="recommendation-letters"
        name="recommendation_letters"
        checked={interests['recommendation_letters']}
        onChange={handleCheckboxChange}
      />
      <Form.Check
        type="checkbox"
        label="Mental health"
        id="mental-health"
        name="mental_health"
        checked={interests['mental_health']}
        onChange={handleCheckboxChange}
      />
      <Form.Check
        type="checkbox"
        label="Non-traditional activities"
        id="non-traditional-activities"
        name="non_traditional_activities"
        checked={interests['non_traditional_activities']}
        onChange={handleCheckboxChange}
      />
      <Form.Check
        type="checkbox"
        label="Opportunities to publish"
        id="publish-opportunities"
        name="publish_opportunities"
        checked={interests['publish_opportunities']}
        onChange={handleCheckboxChange}
      />
      <Form.Check
        type="checkbox"
        label="Pursue passion with 1:1 mentorship"
        id="pursue-passion"
        name="pursue_passion"
        checked={interests['pursue_passion']}
        onChange={handleCheckboxChange}
      />
      <Form.Check
        type="checkbox"
        label="Student engagement/Challenge"
        id="student-engagement"
        name="student_engagement"
        checked={interests['student_engagement']}
        onChange={handleCheckboxChange}
      />
      <Form.Check
        type="checkbox"
        label="Other interests"
        id="other-interest"
        name="other_interests"
        checked={interests['other_interests']}
        onChange={handleCheckboxChange}
      />
      {interests['other_interests'] && (
        <InputField
          label="Please specify"
          name="admission_advisor_students_interest_other"
          type="textarea"
          onChange={handleOtherInterestChange}
          value={otherInterest}
        />
      )}
    </div>
  );
};
