import { additionalSessionActions } from './additionalSessionReducer';
// eslint-disable-next-line import/no-cycle
import { attachmentsActions } from './attachmentsReducer';
import { channelActions } from './channelReducer';
import { hermesActions } from './hermesReducer';
import { pathfinderStudentApplicationActions } from './pathfinderStudentApplicationReducer';
import { projectExtensionActions } from './projectExtensionReducer';
import { selfProposeActions } from './selfProposeReducer';
import { sharedResourcesActions } from './sharedResourcesReducer';
import { showcasingAddonActions } from './showcasingAddonReducer';
import { studentApplicationActions } from './studentApplicationReducer';
import { userActions } from './userReducer';

export const commonReducers = {
  additionalSessionActions,
  studentApplicationActions,
  pathfinderStudentApplicationActions,
  attachmentsActions,
  channelActions,
  hermesActions,
  projectExtensionActions,
  userActions,
  selfProposeActions,
  showcasingAddonActions,
  sharedResourcesActions,
};
