import * as marketplaceApi from '@polygence/common/api/marketplace';
import type { ProjectLink } from '@polygence/common/types/marketplace';
import type { Nullable } from '@polygence/common/types/utils';
import { Button, Icon, OptionType } from '@polygence/components';
import classnames from 'classnames';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import ProjectLinkModal from 'src/components/student/ScholarPage/ProjectLink/ProjectLinkModal';
import styles from 'src/components/student/ScholarPage/ProjectLink/projectLink.module.sass';
import type { ProjectLinkModalValues } from 'src/types/marketplace';

export interface EditableProjectLinkListProps {
  projectLinks: ProjectLink[];
  portfolioItemId: number;
}

const EditableProjectLinkList = ({
  projectLinks: initialLinks,
  portfolioItemId,
}: EditableProjectLinkListProps) => {
  const [options, setOptions] = useState<OptionType[] | []>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [projectLinks, setProjectLinks] = useState(initialLinks);
  const [modalValues, setModalValues] = useState<Nullable<ProjectLinkModalValues>>(null);

  useEffect(() => {
    marketplaceApi
      .getProjectOutcomeTypes()
      .then(({ data }) => {
        const _options = data.map(({ name, id }: { name: string; id: string | number }) => ({
          label: name,
          value: id,
        }));
        setOptions(_options);
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  const openModal = (link?: ProjectLink) => {
    setModalValues(() => {
      return link
        ? {
            id: link.id,
            url: link.url,
            platform: link.platform,
            outcomeType: { label: link.outcomeTypeDetails.name, value: link.outcomeTypeDetails.id },
            permOutcomeFile: link.permOutcomeFile,
          }
        : null;
    });
    setIsOpen(true);
  };

  const handleChange = (name: string, value: string | OptionType | string[]) => {
    setModalValues((prevState) => {
      return {
        ...prevState,
        [name]: value,
      } as ProjectLinkModalValues;
    });
  };

  const handleDelete = (id: number) => {
    marketplaceApi
      .deleteProjectLink(id)
      .then(() => {
        const updatedLinks = projectLinks.filter((link) => link.id !== id);
        setProjectLinks(() => updatedLinks);
        toast.success('Project link was successfully deleted!');
      })
      .catch(() => {
        toast.error('Project link could not be deleted!');
      });
  };

  const handleSave = () => {
    if (modalValues?.id) {
      marketplaceApi
        .updateProjectLink(modalValues.id, {
          ...modalValues,
          outcomeType: modalValues.outcomeType?.value,
        })
        .then(({ data }) => {
          const updatedLinks = projectLinks.map((link) => {
            return link.id === modalValues.id ? data : link;
          });
          setProjectLinks(() => {
            return updatedLinks;
          });
          toast.success('Project link was successfully updated!');
          setIsOpen(false);
        })
        .catch(() => {
          toast.error('Project link could not be updated!');
        });
    } else {
      marketplaceApi
        .createProjectLink({
          ...modalValues,
          outcomeType: modalValues?.outcomeType?.value,
          portfolioItem: portfolioItemId,
        })
        .then(({ data }) => {
          setProjectLinks((prevState) => {
            return [...prevState, data];
          });
          toast.success('Project link was successfully created!');
          setIsOpen(false);
        })
        .catch(() => toast.error('Project link could not be created!'));
    }
  };

  return (
    <>
      <div className={styles['projectLinkGrid']}>
        {projectLinks.map((link) => (
          <div
            key={link.id}
            className={classnames(
              'border p-5 d-flex align-items-center justify-content-between',
              styles['editableProjectLink'],
            )}
          >
            <span>
              Your {link.outcomeTypeDetails?.name} {link.platform && `on ${link.platform}`}
            </span>
            <div className="d-flex align-items-center">
              <Button
                variant="link"
                size="sm"
                onClick={() => openModal(link)}
                title="Edit Outcome link"
              >
                <Icon id="edit-3" />
              </Button>
              <Button
                variant="link"
                size="sm"
                onClick={() => handleDelete(link.id)}
                title="Delete Outcome link"
              >
                <Icon id="trash" color="var(--danger)" />
              </Button>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-4">
        <Button
          onClick={() => openModal()}
          size="sm"
          startIcon={<Icon id="plus" />}
          variant="secondary"
        >
          Add new link
        </Button>
      </div>
      {isOpen && (
        <ProjectLinkModal
          onChange={handleChange}
          onClose={() => {
            setIsOpen(false);
          }}
          onSave={handleSave}
          options={options}
          modalValues={modalValues}
        />
      )}
    </>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default EditableProjectLinkList;
