import { FormTemplate } from '@data-driven-forms/mui-component-mapper';
import useFormApi from '@data-driven-forms/react-form-renderer/use-form-api';
import lodash from 'lodash';
import React, { useEffect } from 'react';

import { useFormContext } from 'src/providers/FormProvider';

const persistDecorator = (form, uuid) => {
  const debounceTime = 400;
  const storage = localStorage;
  const name = uuid;

  const persistedValues = storage.getItem(name) || '{}';
  const { initialValues } = form.getState();

  form.initialize({ ...initialValues, ...JSON.parse(persistedValues) });

  return form.subscribe(
    lodash.debounce(
      ({ values, pristine }) => {
        const valuesKeys = Object.keys(values);
        const valuesObject = valuesKeys.reduce((acc, key) => {
          return {
            ...acc,
            [key]: values[key],
          };
        }, {});

        if (!pristine) {
          storage.setItem(name, JSON.stringify(valuesObject));
        }
      },
      debounceTime,
      { leading: true, trailing: true },
    ),
    { values: true, pristine: true },
  );
};

const StudentFinalSurveyTemplate = (props) => {
  const formApi = useFormApi();
  const formContext = useFormContext();

  useEffect(() => {
    return persistDecorator(formApi, formContext.name);
  }, [formApi, formContext.name]);

  return <FormTemplate {...props} />;
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default StudentFinalSurveyTemplate;
