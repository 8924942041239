import React from 'react';
import { Link } from 'react-router-dom';

import { isFunction } from 'src/utils';

/* eslint-disable-next-line react/function-component-definition, import/no-default-export -- autodisabled, autodisabled */
export default function UniLink({ to, href, children, ...props }) {
  const linkUrl = to || href || '';
  const linkProps = { ...props };
  let LinkComponent = null;
  if (isFunction(linkUrl)) {
    // eslint-disable-next-line fp/no-mutation -- autodisabled
    LinkComponent = 'span';
    // eslint-disable-next-line fp/no-mutation -- autodisabled
    linkProps.onClick = linkUrl;
    // eslint-disable-next-line fp/no-mutation -- autodisabled
    linkProps.className = 'link clickable';
  } else {
    // eslint-disable-next-line fp/no-mutation -- autodisabled
    LinkComponent = linkUrl.startsWith('http://') || linkUrl.startsWith('https://') ? 'a' : Link;
    const propName =
      linkUrl.startsWith('http://') || linkUrl.startsWith('https://') ? 'href' : 'to';
    // eslint-disable-next-line fp/no-mutation -- autodisabled
    linkProps[propName] = linkUrl;
  }

  // eslint-disable-next-line react/jsx-filename-extension -- autodisabled
  return <LinkComponent {...linkProps}>{children}</LinkComponent>;
}
