import { useEffect, useState } from 'react';

import { featureDecisions } from '../feature-toggles/featureDecisions';
import { permissions } from '../utils/permissions';

export const useWritingFeedbackEnabled = (userPermissions: string[]) => {
  const [writingFeedbackEnabled, setWritingFeedbackEnabled] = useState(false);

  useEffect(() => {
    featureDecisions
      .getInstance()
      .then((featureDecisionsInstance) => {
        setWritingFeedbackEnabled(featureDecisionsInstance.writingFeedbackEnabled());
      })
      .catch(console.error);
  }, []);

  return writingFeedbackEnabled && permissions.canSubmitFeedbackRequest(userPermissions);
};
