import type { MentorProfileId, UserId } from '@polygence/common/types/common';
import type { ShowcasingSpecialty, Tag } from '@polygence/common/types/marketplace';
import { Heading, Spacer } from '@polygence/components';

import { DisplayTags } from 'src/components/admin/components/aux/DisplayTags';
import { AllMentorAvailabilities } from 'src/components/mentor/Availability/AllMentorAvailabilities';
import { ShowcasingSpecialties } from 'src/components/usersettings/ShowcasingSpecialties';

export interface MentoringInfoProps {
  userId: UserId;
  profileId: MentorProfileId;
  tags?: Tag[];
  showcasingSpecialties?: ShowcasingSpecialty[];
}

export const MentoringInfo = ({
  userId,
  profileId,
  tags = [],
  showcasingSpecialties = [],
}: MentoringInfoProps) => {
  const subjectTags = tags.filter((tag) => {
    return tag['category'] === 'subject_tag';
  });

  return (
    <>
      <h2 className="mt-5">Mentoring information</h2>
      <Heading size="p" alignment="left">
        Your subject tags
      </Heading>
      <Spacer size={3} />
      <DisplayTags
        tags={subjectTags}
        category="subject_tag"
        label=""
        color="info"
        editable="inline"
        changeUrl={`/api/mentors/${userId}/tags/`}
        minTagCount={3}
        maxTagCount={5}
        forceReload={() => window.location.reload()}
      />
      <AllMentorAvailabilities mentorProfileId={profileId} />
      <ShowcasingSpecialties
        mentorProfileId={profileId}
        showcasingSpecialties={showcasingSpecialties}
      />
    </>
  );
};
