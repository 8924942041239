import { ApplicationSectionIds } from 'src/constants/application-sections';
import {
  contentSpacer,
  headingSpacer,
  illustrationSize,
  headingProps,
} from 'src/students/student-application/common-section-components';
import { referralQuestions } from 'src/students/student-application/referral-questions';
import { ApplicationComponentSection } from 'src/students/student-application/student-application-components';

export const referralSection: ApplicationComponentSection = {
  id: ApplicationSectionIds.REFERRAL_SECTION_ID,
  elements: [
    {
      component: 'ThreeColumnLayout',
      props: {
        children: [
          [{ component: 'AdmissionAdvisorModalInfo' }],
          [
            {
              component: 'CenteredIllustration',
              props: {
                name: 'SpaceRabbitSpaceship',
                size: illustrationSize,
              },
            },
            headingSpacer,
            {
              component: 'ComponentsHeading',
              props: {
                children: 'How did you hear about Polygence?',
                ...headingProps,
              },
            },
            contentSpacer,
            ...referralQuestions,
          ],
        ],
      },
    },
  ],
};
