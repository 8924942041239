import { Icon, Tooltip } from '@polygence/components';
import classNames from 'classnames';
import type { ReactNode } from 'react';

export const LabelWithTooltip = ({
  children,
  tooltip,
  labelFor,
  className = 'mt-3',
}: {
  children: ReactNode;
  tooltip: string | JSX.Element;
  labelFor: string;
  className?: string;
}) => {
  return (
    <label className={classNames('form-label', className)} htmlFor={labelFor}>
      {children}
      {tooltip && (
        <Tooltip tip={tooltip}>
          <Icon
            id="info"
            className="ms-3 align-middle"
            fill="var(--primary)"
            color="white"
            size="lg"
            tabIndex={0}
          />
        </Tooltip>
      )}
    </label>
  );
};
