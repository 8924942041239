/* eslint-disable sonarjs/no-identical-functions */
import type { Nullable, UUID } from '@polygence/common';
import { axiosBaseQuery } from '@polygence/common/api/fetch';
import type { UploadedFile } from '@polygence/components';
import { createApi } from '@reduxjs/toolkit/query/react';

import type {
  PolyPilotProjectId,
  MessageId,
  PolyPilotProjectDetails,
  PolyPilotProjectList,
  PolyPilotProjectMessages,
  ValidationDeliverableOutcome,
  ValidationDeliverableOutcomeCreate,
  ActivePolyPilotProject,
} from 'src/types/polygence/polygpt';

export const polyGptApi = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: 'polygpt',
  tagTypes: ['ProjectDetails'],
  endpoints: (build) => {
    return {
      exportProject: build.query<
        { conversation: string },
        { projectId: string | number; includeSystemMessages: boolean }
      >({
        query: ({ projectId, includeSystemMessages }) => ({
          url: `polygpt/projects/${projectId}/export/`,
          method: 'GET',
          params: new URLSearchParams({ includes_system_messages: String(includeSystemMessages) }),
        }),
      }),
      cloneProject: build.mutation<
        ActivePolyPilotProject,
        { projectId: PolyPilotProjectId; messageId: MessageId }
      >({
        query: ({ projectId, messageId }) => {
          return {
            url: `/polygpt/projects/${projectId}/clone/${messageId}/`,
            method: 'POST',
          };
        },
      }),
      listPolyPilotProjects: build.query<PolyPilotProjectList[], void>({
        query: () => {
          return {
            url: `/polygpt/projects/`,
            method: 'GET',
          };
        },
      }),
      getPolyPilotProjectMessages: build.query<PolyPilotProjectMessages, number>({
        query: (projectId) => {
          return {
            url: `/polygpt/projects/${projectId}/messages/`,
            method: 'GET',
          };
        },
      }),
      getPolyPilotProjectDetails: build.query<PolyPilotProjectDetails, string | number>({
        query: (projectId) => {
          return {
            url: `/polygpt/projects/${projectId}/details/`,
            method: 'GET',
          };
        },
        providesTags: (_res, _err, projectId) => [{ type: 'ProjectDetails', id: projectId }],
      }),
      getProjectByUuid: build.query<
        {
          id: PolyPilotProjectId;
          unlockedAt: Nullable<string>;
          userId: number;
          paymentIntentId: Nullable<UUID>;
        },
        UUID
      >({
        query: (projectUuid) => {
          return {
            url: `/polygpt/projects/${projectUuid}/get-id/`,
            method: 'GET',
          };
        },
      }),
      createValidationDeliverableOutcome: build.mutation<
        ValidationDeliverableOutcomeCreate,
        {
          projectId: number;
          projectStageId: number;
          validationDeliverableId: number;
          outcomeFile: UploadedFile[];
        }
      >({
        query: ({ projectStageId, validationDeliverableId, outcomeFile }) => ({
          url: '/polygpt/validation-deliverable-outcomes/',
          method: 'POST',
          data: {
            project_stage: projectStageId,
            validation_deliverable: validationDeliverableId,
            outcomeFile,
          },
        }),
        invalidatesTags: (_res, _err, { projectId }) => [{ type: 'ProjectDetails', id: projectId }],
      }),
      updateValidationDeliverableOutcome: build.mutation<
        ValidationDeliverableOutcome,
        [number, number, { outcomeFile: UploadedFile[] }]
      >({
        query: ([_, projectStageId, payload]) => ({
          url: `/polygpt/validation-deliverable-outcome-file/${projectStageId}/`,
          method: 'PATCH',
          data: payload,
        }),
        invalidatesTags: (_res, _err, [projectId, ..._]) => [
          { type: 'ProjectDetails', id: projectId },
        ],
      }),
    };
  },
});

export const {
  useLazyExportProjectQuery,
  useCloneProjectMutation,
  useListPolyPilotProjectsQuery,
  useGetPolyPilotProjectDetailsQuery,
  useGetPolyPilotProjectMessagesQuery,
  useLazyGetProjectByUuidQuery,
  useCreateValidationDeliverableOutcomeMutation,
  useUpdateValidationDeliverableOutcomeMutation,
} = polyGptApi;
