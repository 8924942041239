import { Button, Heading, Text } from '@polygence/components';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import tileStyles from 'src/components/Tiles/tilesDashboard.module.scss';

export const ResellPartnerInvitationTile = () => {
  return (
    <div className={classNames(tileStyles['tile'], 'tw-flex tw-flex-col tw-gap-4 tw-p-8')}>
      <Heading size="h4" as="h4" fontWeight="semibold" alignment="left" className="">
        Enroll a student
      </Heading>
      <Text size="medium">
        After a student purchased a Polygence program through you, invite them to fill out their
        enrollment form here! Once they register, you will be able to track their progress.
      </Text>
      <img
        src="https://dpl6hyzg28thp.cloudfront.net/media/resell-dashboard-tile.svg"
        alt="Resell partner student invitation"
        className="tw-w-[50%] tw-self-center"
      />
      <Link to="/counselor/students">
        <Button variant="primary" className="tw-w-full">
          Enroll a student
        </Button>
      </Link>
    </div>
  );
};
