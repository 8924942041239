import 'src/polyfills';
import '@polygence/components/dist/index.css';
import 'src/styles/bootstrap-polyfill.css';
import 'src/configureCommon';
import 'src/setup-sentry.ts';
import 'src/tailwind.css';

import NiceModal from '@ebay/nice-modal-react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from 'src/App';
import { MainErrorBoundary } from 'src/components/MainErrorBoundary';
import { NavigationBarContextProvider } from 'src/components/NavigationBar/NavigationBarContext';
import { store } from 'src/store';
import 'src/fetch';

export const ROOT_CONTAINER = 'root';
const container = document.getElementById(ROOT_CONTAINER);
const root = createRoot(container);
root.render(
  <MainErrorBoundary>
    <BrowserRouter>
      <Provider store={store}>
        <NiceModal.Provider>
          <NavigationBarContextProvider>
            <App />
          </NavigationBarContextProvider>
        </NiceModal.Provider>
      </Provider>
    </BrowserRouter>
  </MainErrorBoundary>,
);
