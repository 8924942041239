import { UserType } from '@polygence/common';
import type { GuidebookCategory } from '@polygence/common/types/guidebooks';
import { Heading, Text, Alert } from '@polygence/components';
import classnames from 'classnames';

import { FullPageBackground } from 'src/components/FullPageBackground/FullPageBackground';
import { GuidebookCardSection } from 'src/components/Guidebook/GuidebookCardSection';
import { GuidebookSearch } from 'src/components/Guidebook/GuidebookSearch/GuidebookSearch';
import { GuidebookStackedCardSection } from 'src/components/Guidebook/GuidebookStackedCardSection';
import { PageLoad } from 'src/components/PageLoad';
import { useCategoriesQuery } from 'src/reducers/guidebookReducers';
import { GuidebookCategoryStyles } from 'src/types/guidebooks';

export const Guidebook = ({
  userTypes = [],
  title = '',
}: {
  userTypes: UserType[];
  title: string;
}) => {
  const { data: categories = [], isLoading } = useCategoriesQuery(userTypes);

  return (
    <FullPageBackground>
      {isLoading ? (
        <PageLoad />
      ) : (
        <div className="d-flex flex-column pt-10 pt-sm-12 ps-9 ps-sm-0">
          <Heading size={{ base: 'h4', sm: 'h2' }} as="h1" className="mx-9">
            {title}
          </Heading>
          <Heading size="h2" as="h2" fontWeight="normal" className="d-none d-sm-block mt-3">
            How can we help?
          </Heading>
          <Text size="large" as="span" className="d-block d-sm-none mt-3" alignment="center">
            How can we help?
          </Text>
          <GuidebookSearch userTypes={userTypes} />
          {categories.map((category: GuidebookCategory) => (
            <div key={category.id}>
              {category.style === GuidebookCategoryStyles.HORIZONTAL_SCROLL && (
                <GuidebookCardSection title={category.name} cards={category.pages} />
              )}
              {category.style === GuidebookCategoryStyles.STACKED && (
                <GuidebookStackedCardSection
                  title={category.name}
                  categoryImage={category.categoryImage}
                  cards={category.pages}
                />
              )}
            </div>
          ))}
          <div className={classnames('d-flex justify-content-center mx-4')}>
            <Alert variant="success">
              <Text size="medium" as="span">
                If you can’t find the information you need here, feel free to reach us at&nbsp;
              </Text>
              <Text size="medium" as="span">
                {userTypes.includes(UserType.MENTOR) && (
                  <a href="mailto:mentors@polygence.org" className="text-bold">
                    mentors@polygence.org
                  </a>
                )}
                {userTypes.includes(UserType.STUDENT) && (
                  <a href="mailto:students@polygence.org" className="text-bold">
                    students@polygence.org
                  </a>
                )}
              </Text>
              <Text size="medium" as="span">
                .
              </Text>
            </Alert>
          </div>
        </div>
      )}
    </FullPageBackground>
  );
};
