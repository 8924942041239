import type { EmptyResponse, UUID } from '@polygence/common';
import { axiosBaseQuery } from '@polygence/common/api/fetch';
import type { Cart, PaymentIntentInvoice } from '@polygence/common/types/payment';
import { createApi } from '@reduxjs/toolkit/query/react';

import { podsApi } from 'src/reducers/podsReducers';

const transformResponse = (res: PaymentIntentInvoice) => {
  const riskPremium = parseFloat(String(res.riskPremium));

  return {
    ...res,
    selectedProducts: res.selectedProducts ?? [],
    pitchedProducts: res.pitchedProducts ?? [],
    riskPremium: Number.isNaN(riskPremium) ? 0 : riskPremium,
  };
};

const updatePaymentIntentWithUnknownAmounts = (paymentIntentUuid: UUID) => {
  return paymentApi.util.updateQueryData('getPaymentIntent', paymentIntentUuid, (draft) => {
    const unknownAmountsPayload: Partial<PaymentIntentInvoice> = {
      amount: undefined,
      baseAmount: undefined,
      installmentAmount: undefined,
      riskPremiumAmount: undefined,
    };

    Object.assign(draft, unknownAmountsPayload);
  });
};

export const paymentApi = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: 'payment',
  tagTypes: ['PaymentIntent', 'Cart'],
  endpoints: (build) => {
    return {
      getPaymentIntent: build.query<PaymentIntentInvoice, UUID>({
        query: (uuid) => {
          return `/payment/intent/${uuid}/invoice/`;
        },
        providesTags: ['PaymentIntent'],
        transformResponse,
      }),
      updatePaymentIntent: build.mutation<
        PaymentIntentInvoice,
        [UUID, Pick<Partial<PaymentIntentInvoice>, 'type' | 'selectedProducts'>]
      >({
        query: ([uuid, payload]) => {
          return {
            url: `/payment/intent/${uuid}/invoice/`,
            method: 'PATCH',
            data: payload,
          };
        },
        transformResponse,
        onQueryStarted: async ([uuid, payload], { dispatch, queryFulfilled }) => {
          // optimistic update to the state while waiting for the server
          dispatch(
            paymentApi.util.updateQueryData('getPaymentIntent', uuid, (draft) => {
              const optimisticUpdatePayload: Partial<PaymentIntentInvoice> = {
                ...payload,
                amount: undefined,
                ...(payload.selectedProducts
                  ? {
                      baseAmount: undefined,
                      installmentAmount: undefined,
                      riskPremiumAmount: undefined,
                    }
                  : {}),
              };

              Object.assign(draft, optimisticUpdatePayload);
            }),
          );

          // update state with data from server
          const { data } = await queryFulfilled;
          dispatch(
            paymentApi.util.updateQueryData('getPaymentIntent', uuid, (draft) => {
              Object.assign(draft, data);
            }),
          );
        },
      }),
      createPaymentIntentForPod: build.mutation<EmptyResponse, void>({
        query: () => {
          return {
            url: `/payment/intent/pods/`,
            method: 'POST',
          };
        },
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          try {
            await queryFulfilled;
            dispatch(podsApi.util.invalidateTags(['PodsOfStudent']));
          } catch {
            console.error('Error while creating payment intent for pod');
          }
        },
      }),
      createPaymentIntentForPolyPilot: build.mutation<{ uuid: UUID }, number>({
        query: (id) => {
          return {
            url: `/payment/intent/${id}/polypilot/`,
            method: 'POST',
          };
        },
      }),
      getCart: build.query<Cart, UUID>({
        query: (uuid) => {
          return `/payment/cart/${uuid}/`;
        },
        providesTags: [
          {
            type: 'Cart',
          },
        ],
      }),
      addProductToCart: build.mutation<{ cart: Cart }, { uuid: UUID; productId: number }>({
        query: ({ uuid, productId }) => {
          return {
            url: `/payment/cart/${uuid}/add-product/`,
            method: 'PATCH',
            data: { productId },
          };
        },
        onQueryStarted: async ({ uuid, productId }, { dispatch, queryFulfilled }) => {
          try {
            dispatch(
              paymentApi.util.updateQueryData('getCart', uuid, (cartDraft) => {
                if (cartDraft.paymentIntentUuid) {
                  dispatch(updatePaymentIntentWithUnknownAmounts(cartDraft.paymentIntentUuid));
                }

                const product = cartDraft.pitchedProducts.find(
                  (product) => product.id === productId,
                );
                if (product) {
                  cartDraft.cartItems.push({
                    amountInCents: +product.totalPriceInCents,
                    discountedAmountInCents: +product.totalPriceInCents,
                    displayAmountInCents: +product.totalPriceInCents,
                    isRemovable: true,
                    product,
                    id: -1,
                    manualAmountInCents: null,
                    quantity: 1,
                    description: product.name,
                  });
                }
              }),
            );
            const { data } = await queryFulfilled;
            dispatch(
              paymentApi.util.updateQueryData('getCart', uuid, (draft) => {
                Object.assign(draft, data.cart);
              }),
            );
          } catch {
            dispatch(paymentApi.util.invalidateTags(['Cart']));
          }
        },
        invalidatesTags: [
          {
            type: 'PaymentIntent',
          },
        ],
      }),
      removeProductFromCart: build.mutation<{ cart: Cart }, { uuid: UUID; productId: number }>({
        query: ({ uuid, productId }) => {
          return {
            url: `/payment/cart/${uuid}/remove-product/`,
            method: 'PATCH',
            data: { productId },
          };
        },
        onQueryStarted: async ({ uuid, productId }, { dispatch, queryFulfilled }) => {
          try {
            dispatch(
              paymentApi.util.updateQueryData('getCart', uuid, (cartDraft) => {
                if (cartDraft.paymentIntentUuid) {
                  dispatch(updatePaymentIntentWithUnknownAmounts(cartDraft.paymentIntentUuid));
                }

                Object.assign(cartDraft, {
                  cartItems: cartDraft.cartItems.filter(
                    (cartItem) => cartItem.product.id !== productId,
                  ),
                });
              }),
            );
            const { data } = await queryFulfilled;
            dispatch(
              paymentApi.util.updateQueryData('getCart', uuid, (draft) => {
                Object.assign(draft, data.cart);
              }),
            );
          } catch {
            dispatch(paymentApi.util.invalidateTags(['Cart']));
          }
        },
        invalidatesTags: [
          {
            type: 'PaymentIntent',
          },
        ],
      }),
      getStripeId: build.query<
        { connected: boolean; url: string; stripeCountries: [string, string][] },
        void
      >({
        query: () => {
          return '/payment/stripe-id/';
        },
      }),
      getStripeTaxReport: build.query<
        { taxReportUrl: string | null } | { message: string } | { error: string },
        void
      >({
        query: () => {
          return '/payment/tax-reports/';
        },
      }),
      onboardStripeUser: build.mutation<{ url: string }, { country: string }>({
        query: ({ country }) => {
          return {
            url: '/payment/onboard-stripe-user/',
            method: 'POST',
            data: { country },
          };
        },
      }),
      onboardedUserToStripe: build.mutation<{ onboarded: boolean }, void>({
        query: () => {
          return {
            url: '/payment/onboarded-user-to-stripe/',
            method: 'PATCH',
          };
        },
      }),
      getProductsPurchased: build.query<{ purchasedProducts: string[] }, void>({
        query: () => {
          return {
            url: `/payment/products-purchased/`,
            method: 'GET',
          };
        },
      }),
    };
  },
});

export const {
  useGetPaymentIntentQuery,
  useUpdatePaymentIntentMutation,
  useCreatePaymentIntentForPodMutation,
  useCreatePaymentIntentForPolyPilotMutation,
  useGetCartQuery,
  useLazyGetCartQuery,
  useAddProductToCartMutation,
  useRemoveProductFromCartMutation,
  useGetStripeIdQuery,
  useGetStripeTaxReportQuery,
  useOnboardStripeUserMutation,
  useOnboardedUserToStripeMutation,
  useGetProductsPurchasedQuery,
} = paymentApi;
