import { UserId, createTrackingActivity } from '@polygence/common';
import {
  isOndemand,
  isDevelopment,
  isProduction,
  isStaging,
} from '@polygence/common/utils/environment';

import { getMyInfo } from 'src/components/getMyInfo';
import type { Tracker, TrackerInitParams, TrackingTarget } from 'src/utils/tracking/types';

const missingAnalyticsTracker: Gtag.Gtag = () => {
  console.error('gtag is not initialized!');
};

const initTracker = ({
  gtag,
  storeInBackend = false,
  verbose = false,
}: TrackerInitParams): Tracker => {
  return {
    identify: (userId: UserId) => {
      if (gtag) {
        gtag('set', { user_id: `${userId}` });
      }
    },
    page: (url: string) => {
      const userInfo = getMyInfo();

      if (storeInBackend) {
        createTrackingActivity({
          actor: userInfo.user_id,
          action: 'pageview',
          platform: 'web',
          target: url,
        });
      }

      if (verbose) {
        // eslint-disable-next-line no-console
        console.table({
          actor: userInfo.user_id,
          action: 'pageview',
          platform: 'web',
          target: url,
        });
      }
    },
    track: <T extends string | number | boolean>(action: string, target: TrackingTarget<T>) => {
      const userInfo = getMyInfo();

      if (gtag) {
        gtag('event', action.replaceAll(' ', '_'), target);
      }

      if (storeInBackend) {
        createTrackingActivity({
          actor: userInfo.user_id,
          action,
          platform: 'web',
          target,
        });
      }

      if (verbose) {
        // eslint-disable-next-line no-console
        console.table({
          actor: userInfo.user_id,
          action,
          platform: 'web',
          target,
        });
      }
    },
  };
};

export const getTracker = () => {
  if (isProduction()) {
    return initTracker({
      gtag: window.gtag || missingAnalyticsTracker,
      storeInBackend: true,
      verbose: false,
    });
  }

  if (isStaging() || isOndemand()) {
    return initTracker({ storeInBackend: true, verbose: false });
  }

  if (isDevelopment() && import.meta.env.VITE_DEV_TRACKING_ENABLED) {
    return initTracker({ storeInBackend: true, verbose: true });
  }

  return initTracker({ storeInBackend: false, verbose: false });
};
