import { ApplicationSectionIds } from 'src/constants/application-sections';
import {
  contentSpacer,
  headingProps,
  headingSpacer,
  illustrationSize,
} from 'src/students/student-application/common-section-components';
import { counselorDetailsFollowup } from 'src/students/student-application/counselor-details-followup';
import { ApplicationComponentSection } from 'src/students/student-application/student-application-components';

export const counselorSection: ApplicationComponentSection = {
  id: ApplicationSectionIds.COUNSELOR_SECTION_ID,
  elements: [
    {
      component: 'ThreeColumnLayout',
      props: {
        children: [
          [{ component: 'AdmissionAdvisorModalInfo' }],
          [
            {
              component: 'CenteredIllustration',
              props: {
                name: 'SpaceProject',
                size: illustrationSize,
              },
            },
            headingSpacer,
            {
              component: 'ComponentsHeading',
              props: {
                children: 'Do you have a guidance counselor?',
                ...headingProps,
              },
            },
            contentSpacer,
            {
              component: 'ComponentsRadioButton',
              tag: 'profile',
              props: {
                name: 'counselorType',
                label: 'Yes, I work with a private counselor.',
                value: 'external',
              },
              validation: {
                required: true,
              },
            },
            {
              component: 'ComponentsText',
              display: {
                $is: {
                  field: 'counselorType',
                  value: 'external',
                },
              },
              props: {
                children:
                  'It is super important to provide the correct counselor information to ensure your counselor discount is applied. We recommend copy and pasting from your last communications with them.',
                fontWeight: 'light',
                size: 'small',
              },
            },
            {
              component: 'Box',
              display: {
                $is: {
                  field: 'counselorType',
                  value: 'external',
                },
              },
              props: {
                children: counselorDetailsFollowup,
              },
            },
            {
              component: 'ComponentsRadioButton',
              tag: 'profile',
              props: {
                name: 'counselorType',
                label: 'Yes, I work with my school counselor but not with a private counselor.',
                value: 'school',
              },
            },
            {
              component: 'Box',
              display: {
                $is: {
                  field: 'counselorType',
                  value: 'school',
                },
              },
              props: {
                children: counselorDetailsFollowup,
              },
            },
            {
              component: 'ComponentsRadioButton',
              tag: 'profile',
              props: {
                name: 'counselorType',
                label: "No, I'm not working with any guidance counselor.",
                value: 'none',
              },
            },
          ],
        ],
      },
    },
  ],
};
