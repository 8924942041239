import type { Nullable } from '@polygence/common';
import { Col, Row } from 'react-bootstrap';

import { FormSection } from 'src/components/mentor/FormSection';
import type { ApplicationComponentDescription } from 'src/students/student-application';

interface ThreeColumnLayoutProps {
  children:
    | Array<Nullable<Array<ApplicationComponentDescription>>>
    | Array<ApplicationComponentDescription>
    | string;
  layoutId?: string;
}

export const ThreeColumnLayout = ({
  children = [],
  layoutId,
  ...layoutFieldProps
}: ThreeColumnLayoutProps) => {
  const [startChildren, centerChildren, endChildren] = children;
  return (
    <Row id={layoutId ? `layout-${layoutId}` : undefined}>
      <Col md={3} className="d-flex flex-column p-0 px-5">
        {Array.isArray(startChildren) && (
          <FormSection helpColumn={false} fields={startChildren} {...layoutFieldProps} />
        )}
      </Col>
      <Col md={6} className="d-flex flex-column p-0 px-5">
        {Array.isArray(centerChildren) && (
          <FormSection helpColumn={false} fields={centerChildren} {...layoutFieldProps} />
        )}
      </Col>
      <Col md={3} className="d-flex flex-column p-0 px-5">
        {Array.isArray(endChildren) && (
          <FormSection helpColumn={false} fields={endChildren} {...layoutFieldProps} />
        )}
      </Col>
    </Row>
  );
};
