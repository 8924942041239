import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import {
  TRANSFORMERS,
  $convertToMarkdownString,
  $convertFromMarkdownString,
} from '@lexical/markdown';
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { cn } from '@polygence/components';
import type { EditorState } from 'lexical';
import { useMemo } from 'react';

import AutoLinkPlugin from 'src/components/LexicalEditor/plugins/AutoLinkPlugin';
import ListMaxIndentLevelPlugin from 'src/components/LexicalEditor/plugins/ListMaxIndentLevelPlugin';
import { SHORT_LINK } from 'src/components/LexicalEditor/plugins/MarkdownTransformers';
import ReadOnlyPlugin from 'src/components/LexicalEditor/plugins/ReadOnlyPlugin';
import theme from 'src/components/LexicalEditor/theme';

import 'src/components/LexicalEditor/styles.sass';

export interface LexicalTextEditorProps {
  onChange: (markdown: string) => void;
  defaultValue?: string | undefined;
  readOnly?: boolean | undefined;
  children?: React.ReactNode;
  className?: string;
}

const initialEditorState = (defaultValue?: string) => () => {
  return defaultValue ? $convertFromMarkdownString(defaultValue, TRANSFORMERS) : undefined;
};

const LexicalTextEditor: React.FC<LexicalTextEditorProps> = ({
  defaultValue,
  onChange,
  children,
  className,
  readOnly = false,
}) => {
  const editorConfig = useMemo(
    () => ({
      namespace: 'LexicalTextEditor',
      theme,
      editorState: initialEditorState(defaultValue),
      onError(error: Error) {
        throw error;
      },
      nodes: [
        ListNode,
        ListItemNode,
        HeadingNode,
        QuoteNode,
        CodeNode,
        CodeHighlightNode,
        AutoLinkNode,
        LinkNode,
      ],
    }),
    [defaultValue],
  );

  const handleChange = (editorState: EditorState) => {
    editorState.read(() => {
      const markdown = $convertToMarkdownString([SHORT_LINK, ...TRANSFORMERS]);
      onChange(markdown);
    });
  };

  return (
    <div className={cn('lexical-editor__wrapper', className)}>
      <LexicalComposer initialConfig={editorConfig}>
        <HistoryPlugin />
        <ListPlugin />
        <ClearEditorPlugin />
        <AutoLinkPlugin />
        <OnChangePlugin onChange={handleChange} />
        <ListMaxIndentLevelPlugin maxDepth={7} />
        <ReadOnlyPlugin readOnly={readOnly} />
        <>{children}</>
      </LexicalComposer>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default LexicalTextEditor;
