import { Text } from '@polygence/components';

export const PurchasedCoreJourney = () => {
  return (
    <Text size="small" alignment="center" textWrap="balance" className="pt-4 pb-5">
      This workspace will be open after you've completed your Launchpad program and have been
      matched with your Core Program mentor.
    </Text>
  );
};
