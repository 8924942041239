import { Button, Spacer } from '@polygence/components';
import classnames from 'classnames';
import { ChangeEventHandler, useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import Dropzone from 'react-dropzone';

import { SpinningWheel } from 'src/components/SpinningWheel';
import styles from 'src/components/UploadImage.module.scss';
import { isMentor } from 'src/components/getMyInfo';
import { getCssVariableWithoutUnit } from 'src/utils';

interface UploadImageProps {
  profile_img: string;
  changeProfilePic: (image: string) => void;
  upload_in_progress?: boolean;
  removeProfilePic?: () => void;
  missingFields?: string[];
  edit_mode_on?: boolean;
  editModeOff?: () => void;
  editModeOn?: () => void;
}

export const UploadImage = ({
  changeProfilePic,
  editModeOn,
  editModeOff,
  missingFields,
  edit_mode_on: isEditModeOn,
  profile_img: profileImage,
  removeProfilePic,
  upload_in_progress: uploadInProgress,
}: UploadImageProps) => {
  const editorRef = useRef<AvatarEditor | null>(null);
  const [state, setState] = useState({
    scale: 0,
    image_for_upload: '',
  });

  const onDrop = (files: File[]) => {
    try {
      const file = files[0];
      const reader = new FileReader();
      if (!file || !reader) {
        return;
      }
      reader.readAsDataURL(file);
      // eslint-disable-next-line fp/no-mutation -- autodisabled
      reader.onloadend = () => {
        changeProfilePic(reader.result as string);
        if (editModeOn) {
          editModeOn();
        }
      };
    } catch {
      console.error('an error occured!');
    }
  };

  const handleChangeFile: ChangeEventHandler<HTMLInputElement> = (event) => {
    const target = event.target;
    if (target.files) {
      const files: File[] = Array.from(target.files);
      onDrop(files);
    }
  };

  const onClickSave = () => {
    if (editModeOff) {
      editModeOff();
    }
    if (editorRef.current) {
      const canvas = editorRef.current.getImage();

      changeProfilePic(canvas.toDataURL('image/jpeg'));
      setState({
        ...state,
        image_for_upload: canvas.toDataURL(),
      });
    }
  };

  const handleScale: ChangeEventHandler<HTMLInputElement> = (event) => {
    const scaleValue = event.target.value;
    setState({
      ...state,
      scale: Number(scaleValue),
    });
  };

  const isMissing = Array.isArray(missingFields) && missingFields.includes('profile_picture');
  const imageDisplay = isEditModeOn ? (
    <div>
      <AvatarEditor
        ref={editorRef}
        image={profileImage}
        width={120}
        height={120}
        border={25}
        borderRadius={getCssVariableWithoutUnit('--base-border-radius')}
        color={[128, 128, 128, 0.8]}
        scale={1 + state.scale / 100}
        rotate={0}
      />
      <div>
        <input type="range" onChange={handleScale} value={state.scale} />
        <Button variant="secondary" onClick={onClickSave}>
          Finish cropping
        </Button>
      </div>
    </div>
  ) : (
    <div className={styles['notEditorImage']}>
      <img src={profileImage} width={120} height={120} alt="profile" />
      <button onClick={removeProfilePic} type="button" className="close" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  );

  return (
    <>
      <div
        className={classnames('text-muted', styles['uploadText'], {
          required: isMentor(),
        })}
      >
        Upload or drag-and-drop a profile picture:
      </div>
      <br />
      <label htmlFor="file_upload" className="btn btn-outline-primary file-upload-buttons btn-sm">
        Select file
      </label>
      <input
        type="file"
        accept="image/*"
        id="file_upload"
        className="hidden"
        multiple={false}
        onChange={handleChangeFile}
      />
      <Spacer size={6} />
      <div
        className="p-6"
        // eslint-disable-next-line react/forbid-dom-props -- autodisabled
        style={{
          border: `1px dashed ${isMissing ? 'red' : 'var(--grayscale-6)'}`,
          borderRadius: 'var(--base-border-radius)',
        }}
      >
        <Dropzone accept="image/*" onDrop={onDrop}>
          {({ getRootProps, getInputProps }) => {
            return (
              <div
                className="dropzone"
                {...getRootProps({
                  onClick: (evt) => {
                    return evt.preventDefault();
                  },
                })}
              >
                <input {...getInputProps()} />
                {imageDisplay}
              </div>
            );
          }}
        </Dropzone>
        {uploadInProgress && (
          <div className="text-lefy">
            <div>Upload in progress</div>
            <SpinningWheel />
          </div>
        )}
      </div>
    </>
  );
};
