import classnames from 'classnames';
import type { CSSProperties } from 'react';

import styles from 'src/components/PolygenceLogo/PolygenceLogo.module.scss';
import polygenceLogoBlack from 'src/components/PolygenceLogo/polygence-logo-black.svg';

/* eslint-disable react/forbid-dom-props */
interface PolygenceLogoProps {
  width?: string;
  className?: string;
}

const PolygenceLogo = ({ width = '200px', className = '' }: PolygenceLogoProps) => {
  return (
    <img
      className={classnames(styles['logo'], className)}
      src={polygenceLogoBlack}
      alt="Polygence"
      style={{ '--logo-width': width } as CSSProperties}
    />
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default PolygenceLogo;
