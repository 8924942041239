/* eslint-disable fp/no-mutation */
import { createApi } from '@reduxjs/toolkit/query/react';

import {
  HermesAIMessageThreadId,
  OpenAIMessageId,
  UserSnippetId,
  WorkspaceId,
} from '../types/common';
import { MessageReaction } from '../types/data/openai';
import {
  AIFeatureStatus,
  HermesAIMessageThread,
  HermesAIMessageThreadMessages,
  UserSnippet,
} from '../types/hermes';
import { handleWebsocketMessage, getConnection } from '../websocket/hermes-ai';

import { axiosBaseQuery } from './fetch';

export const hermesAiApi = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: 'hermesAIApi',
  tagTypes: ['UserSnippets', 'AIFeatureStatus'],
  endpoints: (build) => ({
    createAiMessageThread: build.mutation<HermesAIMessageThread, WorkspaceId>({
      query: (workspaceId) => {
        return {
          url: '/hermes/ai-message-threads/',
          method: 'POST',
          data: {
            workspace: workspaceId,
          },
        };
      },
    }),
    listMessageThreads: build.query<HermesAIMessageThread[], WorkspaceId>({
      query: (workspaceId) => {
        return {
          url: `/hermes/ai-message-threads/`,
          method: 'GET',
          params: { workspace_id: workspaceId },
        };
      },
    }),
    getMessages: build.query<HermesAIMessageThreadMessages, HermesAIMessageThreadId>({
      query: (id) => {
        return {
          url: `/hermes/ai-message-threads/${id}/`,
          method: 'GET',
        };
      },
      onCacheEntryAdded: async (
        hermesAiMessageThreadId,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved },
      ) => {
        // create a websocket connection when the cache subscription starts
        const ws = getConnection();

        const listener = handleWebsocketMessage(hermesAiMessageThreadId, updateCachedData);

        try {
          // wait for the initial query to resolve before proceeding
          await cacheDataLoaded;
          ws.subscribe(listener);
        } catch {
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
        }
        // cacheEntryRemoved will resolve when the cache subscription is no longer active
        await cacheEntryRemoved;
        // perform cleanup steps once the `cacheEntryRemoved` promise resolves
        ws.unsubscribe(listener);
      },
    }),
    createUserSnippet: build.mutation<UserSnippet, string>({
      query: (content) => {
        return {
          url: `/hermes/ai-message-threads/user-snippets/`,
          method: 'POST',
          data: {
            content,
          },
        };
      },
    }),
    getUserSnippets: build.query<UserSnippet[], HermesAIMessageThreadId>({
      query: (id) => {
        return {
          url: `/hermes/ai-message-threads/${id}/user-snippets/`,
          method: 'GET',
        };
      },
      providesTags: (_, __, id) => [{ type: 'UserSnippets', id: id }],
    }),
    editUserSnippet: build.mutation<UserSnippet, { id: UserSnippetId; content: string }>({
      query: ({ id, content }) => {
        return {
          url: `/hermes/ai-message-threads/user-snippets/${id}/`,
          method: 'PATCH',
          data: { content },
        };
      },
    }),
    deleteUserSnippet: build.mutation<void, UserSnippetId>({
      query: (id) => {
        return {
          url: `/hermes/ai-message-threads/user-snippets/${id}/`,
          method: 'DELETE',
        };
      },
    }),
    getUnsentUserSnippet: build.query<UserSnippet, UserSnippetId>({
      query: (id) => {
        return {
          url: `/hermes/ai-message-threads/user-snippets/${id}/`,
          method: 'GET',
        };
      },
    }),
    shareAssistantMessage: build.mutation<
      void,
      { messageId: number; threadId: HermesAIMessageThreadId }
    >({
      query: ({ messageId, threadId }) => {
        return {
          url: `/hermes/ai-message-threads/${threadId}/share/${messageId}/`,
          method: 'POST',
        };
      },
    }),
    getFeatureStatus: build.query<AIFeatureStatus, WorkspaceId>({
      query: (workspaceId) => {
        return {
          url: '/hermes/ai-message-threads/feature-status/',
          method: 'GET',
          params: { workspace_id: workspaceId },
        };
      },
      providesTags: () => {
        return ['AIFeatureStatus'];
      },
    }),
    setFeatureIntroductionModalSeen: build.mutation<void, void>({
      query: () => {
        return {
          url: '/hermes/ai-message-threads/feature-status/',
          method: 'POST',
        };
      },
      invalidatesTags: () => {
        return ['AIFeatureStatus'];
      },
    }),
    reactToMessage: build.mutation<
      void,
      {
        messageId: OpenAIMessageId;
        messageThreadId: HermesAIMessageThreadId;
        reaction: MessageReaction | null;
      }
    >({
      query: ({ messageId, messageThreadId, reaction }) => {
        return {
          url: `/hermes/ai-message-threads/${messageThreadId}/react/${messageId}/`,
          method: 'POST',
          data: { reaction },
        };
      },
      onQueryStarted: async (
        { messageId, messageThreadId, reaction },
        { dispatch, queryFulfilled },
      ) => {
        const patchResult = dispatch(
          hermesAiApi.util.updateQueryData('getMessages', messageThreadId, (draft) => {
            draft.messages = draft.messages.map((message) => {
              if (message.id === messageId) {
                message.reaction = reaction || undefined;
              }

              return message;
            });
          }),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
  }),
});

export const {
  useCreateAiMessageThreadMutation,
  useGetMessagesQuery,
  usePrefetch,
  useCreateUserSnippetMutation,
  useGetUserSnippetsQuery,
  useDeleteUserSnippetMutation,
  useEditUserSnippetMutation,
  useGetUnsentUserSnippetQuery,
  useListMessageThreadsQuery,
  useShareAssistantMessageMutation,
  useGetFeatureStatusQuery,
  useSetFeatureIntroductionModalSeenMutation,
  useReactToMessageMutation,
} = hermesAiApi;
