import type { Nullable } from '@polygence/common';
import { AsyncSelect, InputField, OptionType, SingleValue } from '@polygence/components';
import { debounce } from 'lodash';
import { useState } from 'react';

import type { SelectorChangeTarget } from 'src/types/common';

const OTHER_OPTION: OptionType = { value: 'other', label: 'Other' };

interface AsyncSelectWithOtherProps<ResourceType, InputName> {
  label: string;
  name: InputName;
  defaultValue: Nullable<OptionType>;
  otherLabel?: string;
  otherName: InputName;
  otherValue: Nullable<string>;
  placeholder?: string;
  defaultOptions?: boolean;
  disabled?: boolean;
  required?: boolean;
  isInvalid?: boolean;
  onChange: (event: SelectorChangeTarget<InputName>) => void | null;
  onBlur?: () => void;
  loadSource: (search: string) => Promise<ResourceType[]>;
  nullifyLogic?: (handleChange: (event: SelectorChangeTarget<InputName>) => void | null) => void;
  mapOptions?: (_: ResourceType) => OptionType;
}

export const AsyncSelectWithOther = <ResourceType extends object, InputName extends string>({
  label,
  name,
  defaultValue,
  otherLabel = 'Please specify:',
  otherName,
  otherValue,
  placeholder = 'Select...',
  defaultOptions = true,
  disabled,
  required = false,
  isInvalid = false,
  onChange: handleChange,
  onBlur,
  loadSource,
  nullifyLogic,
  mapOptions = (option) => option as OptionType,
}: AsyncSelectWithOtherProps<ResourceType, InputName>) => {
  const getInitialValue = (value: Nullable<OptionType>, otherValue: Nullable<string>) => {
    if (value) {
      return value;
    } else {
      if (otherValue) {
        return OTHER_OPTION;
      }
    }
    return null;
  };

  const [internalValue, setInternalValue] = useState<Nullable<OptionType>>(
    getInitialValue(defaultValue, otherValue),
  );
  const [options, setOptions] = useState<OptionType[]>([]);

  const selectOnChange = (newValue: SingleValue<OptionType>) => {
    if (typeof nullifyLogic === 'function') {
      nullifyLogic(handleChange);
    }

    if (newValue && newValue?.value !== OTHER_OPTION.value) {
      handleChange({ target: { name, value: newValue.value } });

      const selectedOption = options.find((item) => item.value === newValue.value);
      if (selectedOption) {
        setInternalValue(selectedOption);
      }
    } else {
      handleChange({ target: { name, value: null } });
      setInternalValue(OTHER_OPTION);
    }
  };

  const loadOptions = debounce((search: string, callback: (options: OptionType[]) => void) => {
    loadSource(search)
      .then((responseData) => {
        const mappedResults = [...responseData.map(mapOptions), OTHER_OPTION];

        setOptions(mappedResults);
        callback(mappedResults);
      })
      .catch(console.error);
  }, 500);

  return (
    <div className="form-group">
      <AsyncSelect
        id="school-select"
        label={label}
        onChange={selectOnChange}
        onBlur={onBlur}
        name={name}
        value={internalValue}
        placeholder={placeholder}
        defaultOptions={defaultOptions}
        loadOptions={loadOptions}
        isDisabled={disabled}
        required={required}
        isInvalid={isInvalid}
      />
      {internalValue?.value === OTHER_OPTION.value && (
        <InputField
          type="text"
          label={otherLabel}
          name={otherName}
          value={otherValue ?? ''}
          onChange={({ target: { name, value } }) => {
            return handleChange({ target: { name: name as InputName, value } });
          }}
          onBlur={onBlur}
          floating={false}
          disabled={disabled}
        />
      )}
    </div>
  );
};
