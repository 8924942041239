import { PaymentIntentInvoice } from '@polygence/common/types/payment';
import { Heading } from '@polygence/components';

import { PaymentIntentAmountOrPlaceholder } from 'src/payment/PaymentIntent/PaymentIntentAmountOrPlaceholder';
import { PaymentIntentBox } from 'src/payment/PaymentIntent/PaymentIntentBox';
import { PaymentIntentMonthlyInstallmentInterest } from 'src/payment/PaymentIntent/PaymentIntentMonthlyInstallmentInterest';

interface PaymentIntentTotalProps {
  paymentIntent: PaymentIntentInvoice;
}

export const PaymentIntentTotal = ({ paymentIntent }: PaymentIntentTotalProps) => {
  return (
    <PaymentIntentBox>
      <PaymentIntentMonthlyInstallmentInterest paymentIntent={paymentIntent} className="tw-mb-4" />
      <div className="tw-flex tw-items-center tw-justify-between">
        <Heading as="h2" size="h5" alignment="left" className="tw-inline">
          Total
        </Heading>
        <PaymentIntentAmountOrPlaceholder amount={paymentIntent.amount} size="medium" />
      </div>
    </PaymentIntentBox>
  );
};
