import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEffect } from 'react';

const ReadOnlyPlugin = ({ readOnly }: { readOnly: boolean }) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    editor.setReadOnly(readOnly);
  }, [editor, readOnly]);

  return null;
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default ReadOnlyPlugin;
