import type { CartDiscount } from '@polygence/common/types/payment';
import { Heading } from '@polygence/components';

import { PaymentIntentBox } from 'src/payment/PaymentIntent/PaymentIntentBox';
import { PaymentIntentDiscountList } from 'src/payment/PaymentIntent/PaymentIntentDiscountList';

interface PaymentIntentDiscountsProps {
  discounts: CartDiscount[];
}

export const PaymentIntentDiscounts = ({ discounts }: PaymentIntentDiscountsProps) => {
  if (!discounts.length) {
    return null;
  }

  return (
    <PaymentIntentBox>
      <Heading as="h2" size="h5" alignment="left" className="tw-mb-4">
        Discounts
      </Heading>
      <PaymentIntentDiscountList discounts={discounts} />
    </PaymentIntentBox>
  );
};
