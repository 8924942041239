import { PaymentIntentStatuses, type PaymentIntentInvoice } from '@polygence/common/types/payment';
import { Icon } from '@polygence/components';
import { useEffect, useRef } from 'react';
import { toast } from 'react-toastify';

import { generatePsychTriggerMessage } from 'src/payment/utils';
import { getCssVariable, setCssVariable } from 'src/utils/common';
import { dismissDelayedToast } from 'src/utils/toast';

const TOAST_ID = 'psych-trigger-toast';
const TOAST_WIDTH_VAR = '--toastify-toast-width';
const POSITION_TOP_RIGHT_TOP_VAR = '--toastify-position-top-right-top';
const POSITION_TOP_RIGHT_RIGHT_VAR = '--toastify-position-top-right-right';

export const usePaymentIntentPsychTrigger = (paymentIntent: PaymentIntentInvoice | undefined) => {
  const isPsychTriggerAlreadyDisplayed = useRef(false);
  const isPsychTriggerUnmounted = useRef(false);
  const isPsychTriggerDismissedManually = useRef(false);

  useEffect(() => {
    if (
      !paymentIntent ||
      !paymentIntent.featurePaymentIntentPageV3 ||
      !paymentIntent.enrollmentCountSinceCreation ||
      paymentIntent.status !== PaymentIntentStatuses.REQUESTED ||
      isPsychTriggerAlreadyDisplayed.current
    ) {
      return;
    }

    const originalToastWidth = getCssVariable(TOAST_WIDTH_VAR);
    const originalToastTop = getCssVariable(POSITION_TOP_RIGHT_TOP_VAR);
    const originalToastRight = getCssVariable(POSITION_TOP_RIGHT_RIGHT_VAR);

    setCssVariable(TOAST_WIDTH_VAR, '400px');
    setCssVariable(POSITION_TOP_RIGHT_TOP_VAR, '0px');
    setCssVariable(POSITION_TOP_RIGHT_RIGHT_VAR, '0px');

    toast.info(
      <div className="tw-flex tw-justify-center tw-gap-2.5 tw-font-semibold tw-text-indigo-950">
        <Icon id="flagBanner" size="lg" />
        <span data-cy="psych-trigger-message">{generatePsychTriggerMessage(paymentIntent)}</span>
      </div>,
      {
        toastId: TOAST_ID,
        delay: 5000,
        autoClose: 20000,
        closeButton: false,
        className: 'tw-rounded-full tw-mx-2 tw-my-1 tw-bg-indigo-200 md:tw-min-h-11',
        onClose: () => {
          setCssVariable(TOAST_WIDTH_VAR, originalToastWidth);
          setCssVariable(POSITION_TOP_RIGHT_TOP_VAR, originalToastTop);
          setCssVariable(POSITION_TOP_RIGHT_RIGHT_VAR, originalToastRight);
        },
      },
    );

    toast.onChange(({ id }) => {
      if (
        id === TOAST_ID &&
        isPsychTriggerAlreadyDisplayed.current &&
        isPsychTriggerUnmounted.current &&
        !isPsychTriggerDismissedManually.current
      ) {
        isPsychTriggerDismissedManually.current = true;
        dismissDelayedToast(id);
      }
    });

    isPsychTriggerAlreadyDisplayed.current = true;

    return () => {
      isPsychTriggerUnmounted.current = true;
      toast.dismiss(TOAST_ID);
    };
  }, [paymentIntent]);
};
