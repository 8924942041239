import useCurrentUser from '@polygence/common/hooks/selectors/useCurrentUser';
import { Text } from '@polygence/components';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import styles from 'src/components/Tiles/Products/productTile.module.scss';
import tileIllustrations from 'src/components/Tiles/tile-illustrations';
import tileStyles from 'src/components/Tiles/tilesDashboard.module.scss';

export const CoreApplicationTile = () => {
  const {
    otherInfo: { isPartnerPaysWorkflow },
  } = useCurrentUser();

  return (
    <Link to="/student/profile" id="application-tile">
      <div className={classNames(tileStyles['tile'], styles['productTile'], 'd-flex flex-column')}>
        <Text size="large" fontWeight="bold" className="mb-4">
          <span className="text-primary">Core Program</span>{' '}
          {isPartnerPaysWorkflow ? 'Enrollment' : 'Application'}
        </Text>
        <Text size="medium" fontWeight="normal" className={isPartnerPaysWorkflow ? 'w-75' : 'w-50'}>
          {isPartnerPaysWorkflow ? 'Enroll' : 'Apply or reapply'} to our{' '}
          <strong>Core Program</strong> here
        </Text>
        <img
          className={classNames(styles['illustration'])}
          src={tileIllustrations['core-application']}
          alt="People around Earth"
        />
      </div>
    </Link>
  );
};
