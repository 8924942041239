import { ProjectType } from '@polygence/common';
import { Heading } from '@polygence/components';
import classNames from 'classnames';

import styles from 'src/components/Tiles/StudentJourney/StudentJourneyTile.module.scss';
import { CoreJourneyTile } from 'src/components/Tiles/StudentJourney/core/CoreJourneyTile';
import { LaunchpadJourneyTile } from 'src/components/Tiles/StudentJourney/launchpad/LaunchpadJourneyTile';
import { PSSJourneyTile } from 'src/components/Tiles/StudentJourney/pss/PSSJourneyTile';
import tileStyles from 'src/components/Tiles/tilesDashboard.module.scss';

export const StudentJourneyTile = ({ sections = [] }: { sections?: string[] }) => {
  return (
    <div className={classNames(tileStyles['tile'], styles['studentJourney'])}>
      <Heading size="h4" alignment="left" fontWeight="bold">
        My Polygence Journey
      </Heading>
      {sections.includes(ProjectType.PATHFINDER_LAUNCHPAD) && <LaunchpadJourneyTile />}
      {sections.includes(ProjectType.FLAGSHIP) && <CoreJourneyTile />}
      {sections.includes(ProjectType.PREMIUM_SHOWCASING_PUBLISHING) && <PSSJourneyTile />}
    </div>
  );
};
