import { OptionType, SingleValue, Text, cn } from '@polygence/components';
import { MultiValue } from 'react-select';

import { TagSelector } from 'src/components/common/Selectors/TagSelector';
import InputWrapper from 'src/components/mentor/InputWrapper';

interface SingleTagSelectorProps {
  label: string;
  onChange: (value: SingleValue<OptionType> | MultiValue<OptionType>) => void;
  value: number;
  required: boolean;
  displayError: boolean;
}

export const SingleTagSelector = ({
  label,
  onChange,
  value,
  required,
  displayError,
}: SingleTagSelectorProps) => {
  return (
    <div id="single-tag">
      <InputWrapper required={required} value={value}>
        <span className={cn({ required })}>{label}</span>
        <TagSelector value={value} onChange={onChange} isInvalid={displayError} isMulti={false} />
        {displayError && (
          <Text size="intermediate" className="tw-text-red-500">
            This field is required.
          </Text>
        )}
      </InputWrapper>
    </div>
  );
};
