import useSelectedWorkspaceId from '../../hooks/selectors/useSelectedWorkspaceId';
import { selectSharedResource } from '../../store/reducers/sharedResourcesReducer';
import { useCommonSelector } from '../../store/store';
import type { SharedResource } from '../../types/hermes';

export function useSelectedSharedResource(): SharedResource | null {
  const workspaceId = useSelectedWorkspaceId() || null;
  return useCommonSelector((state) => {
    return selectSharedResource(state.sharedResources, workspaceId);
  });
}
