import type { UUID } from '@polygence/common/types/common';
import { Alert, Button, Card, Spacer, Text } from '@polygence/components';
import { useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';

import { PageLoad } from 'src/components/PageLoad';
import { WritingFeedbackDetails } from 'src/components/WritingFeedback/WritingFeedbackDetails';
import {
  useFeedbackRequestReplyMutation,
  usePublicFeedbackRequestQuery,
} from 'src/reducers/feedbackRequestReducers';

export const WritingFeedbackMentorReply = () => {
  const { uuid } = useParams<{ uuid: UUID }>();
  const [feedbackRequestResponded, setFeedbackRequestResponded] = useState(false);

  const { data: feedbackRequest, isLoading: isLoadingFeedbackRequest } =
    usePublicFeedbackRequestQuery({ uuid: uuid });

  const [replyFeedbackRequest, { isLoading }] = useFeedbackRequestReplyMutation();

  const handleAccept = () => {
    if (uuid) {
      replyFeedbackRequest({ uuid: uuid, action: 'accept' })
        .unwrap()
        .then(() => setFeedbackRequestResponded(true))
        .catch(() => {
          toast.error('Response could not be saved. Please try again or contact support!');
        });
    }
  };

  const handleDecline = () => {
    if (uuid) {
      replyFeedbackRequest({ uuid: uuid, action: 'decline' })
        .unwrap()
        .then(() => setFeedbackRequestResponded(true))
        .catch(() =>
          toast.error('Response could not be saved. Please try again or contact support!'),
        );
    }
  };

  if (isLoadingFeedbackRequest) {
    return <PageLoad />;
  }

  if (feedbackRequest?.alreadyMatched && feedbackRequest?.alreadyMatchedWithRequestUser) {
    return (
      <div className="d-flex justify-content-center p-7 mb-7">
        <Alert variant="success" className="my-5">
          You already accepted this writing feedback request. Thank you!
        </Alert>
      </div>
    );
  }

  if (feedbackRequest?.alreadyMatched && !feedbackRequest?.alreadyMatchedWithRequestUser) {
    return (
      <div className="d-flex justify-content-center p-7 mb-7">
        <Alert variant="secondary" className="my-5">
          Thank you for your interest! {feedbackRequest.studentFirstName} is no longer looking for a
          mentor to review their writing.
        </Alert>
      </div>
    );
  }

  if (feedbackRequest) {
    return (
      <div className="d-flex justify-content-center">
        <Card className="p-7 mb-7">
          <div className="d-flex-column">
            {feedbackRequestResponded && (
              <Text size="medium" className="alert alert-success">
                Your response has been recorded.
              </Text>
            )}
            <Spacer size={3} />
            <WritingFeedbackDetails feedbackRequest={feedbackRequest} />
            <div className="d-flex justify-content-evenly">
              <Button
                variant="primary"
                disabled={isLoading || feedbackRequestResponded}
                onClick={() => handleAccept()}
              >
                Accept
              </Button>
              <Button
                variant="primary"
                disabled={isLoading || feedbackRequestResponded}
                onClick={() => handleDecline()}
              >
                Decline
              </Button>
            </div>
          </div>
        </Card>
      </div>
    );
  } else {
    return (
      <div className="d-flex justify-content-center p-7 mb-7">
        <Alert variant="danger" className="my-5">
          Something went wrong. The requested writing feedback was not found.
        </Alert>
      </div>
    );
  }
};
