import { commonReducers } from '@polygence/common';
import { Text } from '@polygence/components';
import { Button } from 'react-bootstrap';

import { useStudentJourney } from 'src/components/Tiles/StudentJourney/useStudentJourney';
import { PurchaseShowcasingAddonModal } from 'src/components/hermes/premium-showcasing-support/PurchaseShowcasingAddonModal';
import { useAppDispatch, useAppSelector } from 'src/store';
import { getTracker } from 'src/utils/tracking/factory';

export const PurchasablePSSJourneyTile = () => {
  const dispatch = useAppDispatch();
  const tracker = getTracker();
  const showcasingAddon = useAppSelector((state) => {
    return state.showcasingAddon.showcasingPurchaseModal;
  });
  const { data } = useStudentJourney();

  if (!data || !data.pss) {
    return null;
  }

  const handleClick = () => {
    dispatch(commonReducers.showcasingAddonActions.openPurchaseModal(data.pss.coreProjectId));

    tracker.track('Purchase to Enroll', {
      category: 'student_journey_tile',
      projectId: data.pss.coreProjectId,
    });
  };

  return (
    <div className="d-flex flex-column flex-fill align-items-center justify-content-center">
      <Text size="small" alignment="center" textWrap="balance" className="pt-4 pb-5">
        Do you want support showcasing and publishing your Core project? <br />
        If so, Premium Showcasing is for you! <br />
        Click the button below to enroll in this program.
      </Text>
      <Button size="sm" variant="secondary" onClick={handleClick}>
        Purchase to Enroll
      </Button>
      {showcasingAddon.open && <PurchaseShowcasingAddonModal />}
    </div>
  );
};
