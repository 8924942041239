import useFieldApi from '@data-driven-forms/react-form-renderer/use-field-api';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Rating from '@material-ui/lab/Rating';
import classnames from 'classnames';
import React from 'react';

/* eslint-disable-next-line react/function-component-definition, import/no-default-export -- autodisabled, autodisabled */
export default function DataDrivenFormsMUIRating(props) {
  const { label, input, meta, isRequired, ...rest } = useFieldApi(props);
  const { value } = input;
  return (
    // eslint-disable-next-line react/jsx-filename-extension -- autodisabled
    <Box component="fieldset" my={3} borderColor="transparent">
      <Typography
        component="legend"
        className={classnames('MuiFormLabel-root', {
          'Mui-error': meta.error && meta.touched,
        })}
      >
        {label}
      </Typography>
      <Rating {...input} value={parseInt(value, 10)} {...rest} />
      {meta.error && meta.touched && (
        <p className="MuiFormHelperText-root Mui-error Mui-required">{meta.error}</p>
      )}
    </Box>
  );
}
