import { commonHooks } from '@polygence/common/hooks';
import { HamburgerMenu, Icon } from '@polygence/components';
import {
  Menu,
  MenuButton,
  MenuContextValue,
  MenuItem,
  MenuLink,
  MenuList,
} from '@reach/menu-button';
import type { MutableRefObject } from 'react';
import React, { CSSProperties, useRef } from 'react';
import { NavLink } from 'react-router-dom';

import 'src/components/NavigationBar/MobileMenu/mobileMenu.sass';

import { ScrollLock } from 'src/components/NavigationBar/MobileMenu/ScrollLock';
import { WarningIndicatorIcon } from 'src/components/NavigationBar/WarningIndicatorIcon';
import { PROFILE_MAIN } from 'src/components/constants';
import { getProfilePicture, getStaticAsset } from 'src/utils';
import type { NavbarNavigationItem } from 'src/utils/navigation';

interface MobileMenuProps {
  generalNavItems: NavbarNavigationItem[];
}

export const MobileMenu = ({ generalNavItems }: MobileMenuProps) => {
  const currentUser = commonHooks.useCurrentUser();
  const isProfileCompletionRequired = commonHooks.useProfileCompletionRequired();
  const menuItemsRef = useRef() as MutableRefObject<HTMLDivElement>;
  const profileMenu = generalNavItems.find((item) => item.name === PROFILE_MAIN);
  const profileNavItems = profileMenu?.items ?? [];
  const avatar = currentUser.profilePicture ? getProfilePicture(currentUser.profilePicture) : '';

  const handleToggleMobileMenu = (isExpanded: boolean, callback?: () => void) => {
    if (callback) {
      callback();
    }
    if (isExpanded) {
      menuItemsRef.current['scrollTop'] = 0;
    }
  };

  const isWarningIndicatorVisible = (label: string | undefined): boolean => {
    if (label === 'Settings') {
      return currentUser.timeZoneMismatch || currentUser.missingDemographyData;
    }

    if (label === 'Profile') {
      return isProfileCompletionRequired;
    }

    return false;
  };

  return (
    <Menu as="div" className="mobileMenu">
      {({
        isExpanded,
      }: // eslint-disable-next-line sonarjs/cognitive-complexity
      MenuContextValue) => (
        <>
          <ScrollLock shouldLock={isExpanded} />
          {generalNavItems && generalNavItems.length > 0 && (
            <MenuButton
              className="hamburgerMenuButton position-relative"
              onClick={() => handleToggleMobileMenu(isExpanded)}
            >
              <HamburgerMenu open={isExpanded} />
              {(currentUser.timeZoneMismatch ||
                currentUser.missingDemographyData ||
                isProfileCompletionRequired) &&
                !isExpanded && <WarningIndicatorIcon />}
            </MenuButton>
          )}
          <MenuList
            className="mobileMenuItems"
            ref={menuItemsRef}
            style={{ '--app-bg': `url("${getStaticAsset('appbg.jpg')}")` } as CSSProperties}
          >
            {currentUser.firstName && (
              <div className="mobileProfileMenu">
                <div className="mobileAvatar">
                  <img src={avatar} alt={currentUser.firstName} className="mobileAvatarPicture" />
                  <div>
                    <p className="firstName text-bold mb-0">{currentUser.firstName}</p>
                  </div>
                </div>
                {Array.isArray(profileNavItems) &&
                  profileNavItems.map(({ label, href, iconId, onClick }) => {
                    if (typeof onClick === 'function') {
                      return (
                        <MenuItem
                          onSelect={() => handleToggleMobileMenu(!isExpanded, onClick)}
                          className="mobileMenuItem"
                          key={href ?? label}
                        >
                          {iconId && <Icon id={iconId} color="var(--grayscale-6)" size="lg" />}
                          {label}
                        </MenuItem>
                      );
                    } else {
                      return (
                        href && (
                          <MenuLink
                            as={NavLink}
                            to={href}
                            className="mobileMenuItem"
                            key={href}
                            onSelect={() => handleToggleMobileMenu(!isExpanded)}
                          >
                            {iconId && <Icon id={iconId} color="var(--grayscale-6)" size="lg" />}
                            {label}
                            {isWarningIndicatorVisible(label) && (
                              <WarningIndicatorIcon className="position-absolute top-auto right-4" />
                            )}
                          </MenuLink>
                        )
                      );
                    }
                  })}
              </div>
            )}
            <div className="mobileGeneralMenu">
              {generalNavItems.map(({ href, name, DropdownComponent, items, label, iconId }) => {
                if (DropdownComponent && name !== PROFILE_MAIN) {
                  return (
                    <React.Fragment key={href}>
                      {items?.map(
                        ({ href, label, iconId }) =>
                          href && (
                            <MenuLink
                              as={NavLink}
                              to={href}
                              isActive={() => false}
                              className="mobileMenuItem"
                              key={href}
                            >
                              {iconId && <Icon id={iconId} size="lg" color="var(--primary-dark)" />}
                              {label}
                            </MenuLink>
                          ),
                      )}
                    </React.Fragment>
                  );
                }
                return (
                  href && (
                    <MenuLink as={NavLink} to={href} className="mobileMenuItem" key={href}>
                      {iconId && <Icon id={iconId} size="lg" color="var(--primary-dark)" />}
                      {label}
                    </MenuLink>
                  )
                );
              })}
            </div>
          </MenuList>
        </>
      )}
    </Menu>
  );
};
