import type { Session } from '../../types/hermes';

import useSelectedProject from './useSelectedProject';

export function useSessionByMeetingId(meetingId: number | undefined): Session | null | undefined {
  const selectedProject = useSelectedProject();
  if (!meetingId) {
    return null;
  }
  return selectedProject?.sessions?.find((session) => {
    return session.meetingId === meetingId;
  });
}
