import type { PathfindersStudentApplicationId, StudentProfileId } from '@polygence/common';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import * as pathfinderStudentApplicationApi from '../../api/pathfinder-student-application';
import type {
  PathfinderStudentApplication,
  CompletePathfinderApplication,
} from '../../types/pathfinderStudentApplication';
import type { RootState } from '../store';

export interface PathfinderStudentApplicationState {
  data: Partial<PathfinderStudentApplication>;
  changedFields: Array<keyof PathfinderStudentApplication>;
}

const getUpdatePayload = (
  state: PathfinderStudentApplicationState,
): Partial<PathfinderStudentApplication> => {
  return state.changedFields.reduce((updatable, field) => {
    return {
      ...updatable,
      [field]:
        field === 'interests'
          ? state.data[field]?.map(Math.abs).filter(Boolean)
          : state.data[field],
    };
  }, {});
};

const initialState: PathfinderStudentApplicationState = {
  data: {},
  changedFields: [],
};

export const getPathfinderApplication = createAsyncThunk(
  'application/getPathfinderApplication',
  async ({
    profileId,
    applicationId,
  }: {
    profileId: StudentProfileId;
    applicationId: PathfindersStudentApplicationId;
  }) => {
    const response = await pathfinderStudentApplicationApi.getPathfinderApplication(
      profileId,
      applicationId,
    );
    return response.data;
  },
);

export const getLatestPathfinderApplication = createAsyncThunk(
  'application/getLatestPathfinderApplication',
  async ({ profileId }: { profileId: StudentProfileId }) => {
    const response = await pathfinderStudentApplicationApi.getPathfinderApplications(profileId);
    if (response.data.length > 0) {
      return response.data[0];
    }
    return undefined;
  },
);

export const updatePathfinderApplication = createAsyncThunk<
  PathfinderStudentApplication | null,
  { profileId: StudentProfileId; applicationId: PathfindersStudentApplicationId },
  { state: RootState }
>(
  'application/updatePathfinderApplication',
  async ({ profileId, applicationId }, { getState, dispatch }) => {
    const { pathfinderStudentApplication } = getState();

    const updatePayload = getUpdatePayload(pathfinderStudentApplication);

    if (!updatePayload || Object.keys(updatePayload).length === 0) {
      return Promise.resolve(null);
    }

    const response = await pathfinderStudentApplicationApi.updatePathfinderApplication(
      profileId,
      applicationId,
      updatePayload,
    );

    dispatch(actions.resetChangedFields());

    return response.data;
  },
);

export const submitPathfinderApplication = createAsyncThunk<
  CompletePathfinderApplication,
  { profileId: StudentProfileId; applicationId: PathfindersStudentApplicationId },
  { state: RootState }
>(
  'application/submitPathfinderApplication',
  async ({ profileId, applicationId }, { getState, dispatch }) => {
    const { pathfinderStudentApplication } = getState();

    const updatePayload = getUpdatePayload(pathfinderStudentApplication);

    await pathfinderStudentApplicationApi.updatePathfinderApplication(
      profileId,
      applicationId,
      updatePayload,
    );

    dispatch(actions.resetChangedFields());

    const response = await pathfinderStudentApplicationApi.completePathfinderApplication(
      profileId,
      applicationId,
    );

    return response.data;
  },
);

const pathfinderStudentApplicationSlice = createSlice({
  name: 'pathfinderStudentApplication',
  initialState,
  reducers: {
    updateApplicationData: (
      state,
      {
        payload,
      }: { payload: { pathfinderStudentApplicationUpdate: Partial<PathfinderStudentApplication> } },
    ) => {
      state.data = {
        ...state.data,
        ...payload.pathfinderStudentApplicationUpdate,
      };

      const changedKeys = Object.keys(payload.pathfinderStudentApplicationUpdate) as Array<
        keyof PathfinderStudentApplication
      >;
      state.changedFields = [...new Set([...state.changedFields, ...changedKeys])];
    },
    resetChangedFields: (state) => {
      state.changedFields = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(submitPathfinderApplication.fulfilled, (state, { payload }) => {
      state.data = payload.application;
    });
    builder.addCase(getLatestPathfinderApplication.fulfilled, (state, { payload }) => {
      state.data = payload ? payload : initialState.data;
    });
    builder.addCase(getLatestPathfinderApplication.rejected, (state) => {
      state.data = initialState.data;
    });
    builder.addCase(getPathfinderApplication.fulfilled, (state, { payload }) => {
      state.data = payload;
    });
    builder.addCase(getPathfinderApplication.rejected, (state) => {
      state.data = initialState.data;
    });
  },
});

const { actions, reducer } = pathfinderStudentApplicationSlice;

export const pathfinderStudentApplicationActions = actions;
export const pathfinderStudentApplicationReducer = reducer;
