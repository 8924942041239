import { AdmissionAdvisorModal, externalLink } from '@polygence/common';
import useCurrentUser from '@polygence/common/hooks/selectors/useCurrentUser';
import { Text, cn } from '@polygence/components';

import { STUDENT_TEAM_EMAIL } from 'src/constants/emails';
import { AdmissionAdvisorAction } from 'src/types/marketplace';
import { getAdmissionAdvisorCalendlyUrl } from 'src/utils/calendlyUtils';

interface AdmissionAdvisorModalInfoContentProps {
  advisor: AdmissionAdvisorModal;
}

export const AdmissionAdvisorModalInfoContent = ({
  advisor,
}: AdmissionAdvisorModalInfoContentProps) => {
  const {
    firstName,
    lastName,
    email,
    otherInfo: { isPartnerPaysWorkflow },
  } = useCurrentUser();

  return (
    <div className="tw-flex tw-flex-col tw-gap-4">
      <Text size="large" alignment="left" fontWeight="bold">
        Welcome to Polygence, {firstName}!
      </Text>
      <Text size="medium" alignment="left">
        {isPartnerPaysWorkflow ? advisor.textTemplatePartnerPays : advisor.textTemplate}
      </Text>
      <div className={cn('tw-flex tw-gap-4', 'md:tw-flex-col', 'xl:tw-flex-row')}>
        <img alt="Advisor illustration" src={advisor.imageUrl} width={120} height={120} />
        <div className="tw-flex tw-flex-col tw-justify-center tw-gap-2">
          <Text size="large" fontWeight="bold">
            {advisor.firstName}
          </Text>
          <Text size="medium">{advisor.role}</Text>
          {advisor.action === AdmissionAdvisorAction.GeneralEmail && (
            <a
              href={`mailto:${STUDENT_TEAM_EMAIL}`}
              className="tw-font-bold tw-text-primary hover:tw-text-primary-500"
              {...externalLink}
            >
              Reach out to {advisor.firstName}
            </a>
          )}
          {advisor.action === AdmissionAdvisorAction.CalendlyUrl && (
            <a
              className="tw-font-bold tw-text-primary hover:tw-text-primary-500"
              href={getAdmissionAdvisorCalendlyUrl(
                advisor,
                email,
                firstName,
                lastName,
                Boolean(isPartnerPaysWorkflow),
              )}
              {...externalLink}
            >
              Set up a call with {advisor.firstName}
            </a>
          )}
        </div>
      </div>
    </div>
  );
};
