// eslint-disable-next-line import/no-default-export -- autodisabled
export default function evaluateFormElementsOperations(operations, data, defaultValue = true) {
  if (operations == null) {
    return defaultValue;
  }

  /* eslint-disable arrow-body-style */
  const operators = {
    $includes: (operands, values) => operands.value.includes(values[operands.field]),
    $is: (operands, values) => operands.value === values[operands.field],
    $not: (operands, values) => operands.value !== values[operands.field],
    $and: (operands, values) =>
      operands.map((item) => evaluateFormElementsOperations(item, values)).every((item) => item),
    $or: (operands, values) =>
      operands.map((item) => evaluateFormElementsOperations(item, values)).some((item) => item),
    $gt: (operands, values) => operands.value < values[operands.field],
    $gte: (operands, values) => operands.value <= values[operands.field],
    $lt: (operands, values) => operands.value > values[operands.field],
    $exists: (operands, values) => Object.keys(values).includes(operands.field),
  };
  /* eslint-enable arrow-body-style */

  let final = defaultValue;
  Object.entries(operations).forEach(([operator, value]) => {
    const operatorFn = operators[operator];
    const result = operatorFn(value, data);

    if (defaultValue) {
      // eslint-disable-next-line fp/no-mutation
      final = final && result;
    } else {
      // eslint-disable-next-line fp/no-mutation
      final = final || result;
    }
  });
  return final;
}
