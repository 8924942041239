import type { GuidebookPage } from '@polygence/common/types/guidebooks';
import { Heading, Text } from '@polygence/components';

import { GuidebookSwiper } from 'src/components/Guidebook/GuidebookSwiper';

interface GuidebookCardSectionProps {
  title: string;
  cards: GuidebookPage[];
}

export const GuidebookCardSection = ({ title, cards }: GuidebookCardSectionProps) => {
  return (
    <>
      <div className="ps-9 ps-sm-0">
        <Heading size="h3" className="d-none d-sm-block">
          {title}
        </Heading>
        <Text size="large" as="h3" alignment="left" fontWeight="bold" className="d-block d-sm-none">
          {title}
        </Text>
      </div>
      <GuidebookSwiper cards={cards} />
    </>
  );
};
