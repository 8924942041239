import { commonHooks } from '@polygence/common/hooks';
import { MenuButton } from '@reach/menu-button';
import classnames from 'classnames';

import { DropdownMenu } from 'src/components/DropdownMenu';
import 'src/components/NavigationBar/profileMenu.sass';
import { WarningIndicatorIcon } from 'src/components/NavigationBar/WarningIndicatorIcon';
import defaultProfileImage from 'src/components/static/images/generic-profile.jpg';
import { getProfilePicture } from 'src/utils';
import type { NavbarNavigationItem } from 'src/utils/navigation';

export interface ProfileMenuProps {
  items?: NavbarNavigationItem[];
}

export interface ProfileMenuButtonProps {
  isExpanded: boolean;
}

const ProfileMenuButton = ({ isExpanded }: ProfileMenuButtonProps) => {
  const currentUser = commonHooks.useCurrentUser();
  const isProfileCompletionRequired = commonHooks.useProfileCompletionRequired();

  const avatar = currentUser.profilePicture
    ? getProfilePicture(currentUser.profilePicture)
    : defaultProfileImage;

  return (
    <MenuButton className={classnames('profileMenuButton', { isExpanded })}>
      <div className="avatar">
        <img src={avatar} alt={currentUser.firstName} />
      </div>
      <div className="avatar-name">{currentUser.firstName}</div>
      {(currentUser.timeZoneMismatch ||
        currentUser.missingDemographyData ||
        isProfileCompletionRequired) && <WarningIndicatorIcon />}
    </MenuButton>
  );
};

export const ProfileMenu = ({ items = [] }: ProfileMenuProps) => {
  return (
    <DropdownMenu items={items}>
      {({ isExpanded }) => <ProfileMenuButton isExpanded={isExpanded} />}
    </DropdownMenu>
  );
};
