import { useState, useEffect, useRef } from 'react';

import { usePageVisibility } from './usePageVisibility';

export const usePageVisibilityWithTimeout = () => {
  const isVisible = usePageVisibility();
  const [visibilityStatus, setVisibilityStatus] = useState(isVisible);
  const timeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    const visibilityTimeout = 1000 * 1.5;
    if (isVisible) {
      timeoutRef.current = setTimeout(() => setVisibilityStatus(true), visibilityTimeout);
    } else {
      setVisibilityStatus(false);
    }
    return () => clearTimeout(timeoutRef.current);
  }, [isVisible]);

  return visibilityStatus;
};
