import { Heading, Button, Alert, Text } from '@polygence/components';
import { useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';

import { FormURLInput } from 'src/components/FormURLInput';
import { PageLoad } from 'src/components/PageLoad';
import {
  usePublicFeedbackRequestQuery,
  useFeedbackRequestSubmitMutation,
} from 'src/reducers/feedbackRequestReducers';

export const WritingFeedbackSubmit = () => {
  const { uuid } = useParams<{ uuid: string }>();

  const { data, isLoading, isError } = usePublicFeedbackRequestQuery({ uuid });
  const [feedbackFileUrl, setFeedbackFileUrl] = useState('');
  const [isInvalid, setIsInvalid] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [feedbackRequestSubmit, { isLoading: isMutationInProgress }] =
    useFeedbackRequestSubmitMutation();

  if (isError) {
    return (
      <div className="p-4">
        <Alert variant="danger">Something went wrong.</Alert>
      </div>
    );
  }

  if (data && data.feedbackFileUrl) {
    return (
      <div className="p-4">
        <Heading as="h1" size="h4" className="mb-6">
          Feedback has already been submitted
        </Heading>
      </div>
    );
  }

  if (submitted) {
    return (
      <div className="p-4">
        <Heading as="h1" size="h4" className="mb-6">
          Thank you for submitting your feedback!
        </Heading>
        <Text size="large" alignment="center">
          After reviewing your submission, we will pass it along to the student and process your
          payment.
        </Text>
      </div>
    );
  }

  if (isLoading || !data) {
    return <PageLoad />;
  }

  return (
    <div className="p-4">
      <Heading as="h1" size="h4" className="mb-6">
        Please submit your feedback
      </Heading>
      <FormURLInput
        name="feedbackFileUrl"
        label="Feedback file URL"
        value={feedbackFileUrl}
        onChange={({ target: { value } }) => setFeedbackFileUrl(value)}
        updateValidationError={setIsInvalid}
      />
      <Button
        variant="primary"
        disabled={!feedbackFileUrl || isInvalid || isMutationInProgress}
        className="mt-4"
        onClick={() => {
          feedbackRequestSubmit({ uuid, feedbackFileUrl })
            .unwrap()
            .then(() => setSubmitted(true))
            .catch(() => toast.error('Something went wrong.'));
        }}
      >
        Submit
      </Button>
    </div>
  );
};
