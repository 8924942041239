import { memo } from 'react';
import Markdown from 'react-markdown';
import type { Options } from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';
import remarkGfm from 'remark-gfm';

const defaultRemarkPlugins = [remarkGfm];
const defaultRehypePlugins = [rehypeRaw, rehypeSanitize];

export const ReactMarkdown = memo(
  ({ children, remarkPlugins, rehypePlugins, ...props }: Options) => {
    return (
      <Markdown
        remarkPlugins={[...defaultRemarkPlugins, ...(remarkPlugins ?? [])]}
        rehypePlugins={[...defaultRehypePlugins, ...(rehypePlugins ?? [])]}
        {...props}
      >
        {children}
      </Markdown>
    );
  },
);
