import { Token } from '@polygence/common/utils/auth';

export function getAuthorizationHeader() {
  const token = localStorage.getItem('token');
  if (token) {
    return { Authorization: `Bearer ${token}` };
  }
  return {};
}

export function getImpersonatorAuthorizationHeader() {
  const token = localStorage.getItem(Token.OriginalAccess);
  return { 'X-Polygence-Impersonator-Authorization': token };
}

export function getSearchParam(name: string) {
  const { search } = window.location;
  const params = new URLSearchParams(search);
  if (params.has(name)) {
    return params.get(name);
  }
  return null;
}

export function getAutoSaveInterval() {
  const result = getSearchParam('save_interval');
  if (result) {
    return Number(result);
  }
  return 10000;
}

export function getQueryParams(locationSearch: string) {
  const searchParams = new URLSearchParams(locationSearch);
  const params: { [key: string]: string } = {};

  searchParams.forEach((value, key) => {
    // eslint-disable-next-line fp/no-mutation
    params[key] = value;
  });

  return params;
}
