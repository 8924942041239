import { Icon, Tooltip } from '@polygence/components';

interface PaymentIntentProductDetailsProps {
  details?: string;
}

export const PaymentIntentProductDetails = ({ details }: PaymentIntentProductDetailsProps) => {
  if (!details) {
    return null;
  }

  return (
    <Tooltip tip={details} placement="top">
      <Icon id="info" size="sm" className="tw-text-gray-400" />
    </Tooltip>
  );
};
