import { useCommonSelector } from '../store/store';
import type { Meeting, Session } from '../types/hermes';
import { dayjs } from '../utils/dayjsCustom';
import { permissions } from '../utils/permissions';

import useCurrentUser from './selectors/useCurrentUser';
import useSelectedProject from './selectors/useSelectedProject';
import { usePermissions } from './usePermissions';

function useUpcomingSessions(): Session[] {
  const selectedProject = useSelectedProject();
  const sessions = selectedProject?.sessions ? selectedProject.sessions : [];
  const userPermissions = usePermissions();
  const userId = useCurrentUser()?.id;

  return sessions.filter((session) => {
    return (
      (permissions.canSubmitSummary(userPermissions) && !session.summary) ||
      (permissions.canStudentFeedback(userPermissions) &&
        !session.feedbacks.find((fb) => {
          return fb.user === userId;
        }))
    );
  });
}

// eslint-disable-next-line import/no-default-export -- autodisabled
export default function useNextSessionMeeting(): Meeting | null | undefined {
  const upcomingSessions = useUpcomingSessions();
  const meetings = useCommonSelector((state) => {
    return state.hermes.meetings.byId;
  });
  const session = upcomingSessions.find((sessionItem) => {
    const meeting = meetings[sessionItem.meetingId];
    return meeting && dayjs(meeting.scheduledAt).isAfter(dayjs());
  });
  if (session) {
    return meetings[session.meetingId];
  }
  return null;
}
