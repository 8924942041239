import { Heading, Text } from '@polygence/components';

import { PathfinderApplicationInterestTab } from 'src/components/student/pathfinders/PathfinderApplicationInterestTab';
import { PathfinderInterestPicker } from 'src/components/student/pathfinders/PathfinderInterestPicker';

export const PathfinderApplicationInterests = ({
  interests,
  applicationId,
  interestIndex,
  onChange,
}: {
  interests: number[];
  applicationId: number;
  interestIndex: number;
  onChange: ({ target }: { target: { name: string; value: unknown } }) => void;
}) => {
  const handleChange = (interestId: number) => {
    const newInterests = interests.slice();
    // eslint-disable-next-line fp/no-mutation
    newInterests[interestIndex] = interestId;

    onChange({ target: { name: 'interests', value: newInterests } });
  };

  return (
    <>
      <Heading size="h5" as="h1" className="mt-4 mb-7">
        Choose 3 topics that you're interested in
      </Heading>
      <Text size="medium" alignment="center" fontWeight="light" className="mt-4 mb-7">
        You can select the same topics more than once
      </Text>
      <div className="d-flex flex-column flex-md-row flex-wrap gap-3 justify-content-around align-items-center mb-9">
        {interests.map((interest, index) => {
          return (
            <PathfinderApplicationInterestTab
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              index={index}
              interestId={interest}
              activeIndex={interestIndex}
            />
          );
        })}
      </div>
      <PathfinderInterestPicker
        interestId={interests[interestIndex] ?? 0}
        interestIndex={interestIndex}
        applicationId={applicationId}
        onChange={handleChange}
      />
    </>
  );
};
