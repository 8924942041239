import * as backendApi from '@polygence/common/api/backend';
import useCurrentUser from '@polygence/common/hooks/selectors/useCurrentUser';
import { Text, Heading, Button, Spacer } from '@polygence/components';
import { MouseEventHandler, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { UnverifiedApplicationSubmitPage } from 'src/components/auth/UnverifiedUnauthorizedPage/UnverifiedApplicationSubmitPage';
import styles from 'src/components/auth/UnverifiedUnauthorizedPage/UnverifiedUnauthorized.module.scss';
import Logo from 'src/components/static/images/unverified-unauthorized.svg';
import { urls } from 'src/urls';

export const UnverifiedUnauthorizedPage = () => {
  const currentUser = useCurrentUser();
  const location = useLocation<{ from?: string }>();
  const [disabled, setDisabled] = useState(false);

  const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    setDisabled(true);
    backendApi
      .requestAccountVerification()
      .then(() => toast.success('Email has been sent!'))
      .catch(() => {
        toast.error('Something went wrong, please try again');
        setDisabled(false);
      });
  };

  if (location?.state?.from === 'student-application') {
    return <UnverifiedApplicationSubmitPage />;
  }

  return (
    <div className="d-flex justify-content-center">
      <div>
        <Spacer size={6} />
        <div className="d-flex justify-content-center">
          <img alt="" src={Logo} />
        </div>
        <Spacer size={9} />
        <Heading size="h3">You don’t have access to this page.</Heading>

        <Spacer size={7} />
        <Text size="large" fontWeight="bold" alignment="center">
          Please verify your account to view this page.
        </Text>
        <Text size="large" alignment="center">
          Click on the link below to send a verification email to {currentUser.email}.
        </Text>
        <Text size="medium" alignment="center" className={styles['smallText']}>
          If you're experiencing any issues, make sure your email address is correct{' '}
          <Link to={urls.settingsPage()}>here</Link>.
        </Text>
        <Spacer size={9} />
        <div className="d-flex justify-content-center">
          <Button variant="tertiary" onClick={handleClick} disabled={disabled}>
            Send verification email
          </Button>
        </div>
      </div>
    </div>
  );
};
