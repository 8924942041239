import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { FormSection } from 'src/components/mentor/FormSection';

const TwoColumnLayout = ({ children = [], ...layoutFieldProps }) => {
  const [startChildren, endChildren] = children;
  return (
    // eslint-disable-next-line react/jsx-filename-extension -- autodisabled
    <Row>
      <Col sm={5} className="d-flex flex-column p-0">
        {Array.isArray(startChildren) && (
          <FormSection helpColumn={false} fields={startChildren} {...layoutFieldProps} />
        )}
      </Col>
      <Col sm={7} className="d-flex flex-column" style={{ padding: '0 var(--space-11)' }}>
        {Array.isArray(endChildren) && (
          <FormSection helpColumn={false} fields={endChildren} {...layoutFieldProps} />
        )}
      </Col>
    </Row>
  );
};

TwoColumnLayout.propTypes = {
  children: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)),
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default TwoColumnLayout;
