import { useLayoutEffect } from 'react';

import { useNavigationBarContext } from 'src/components/NavigationBar/NavigationBarContext';

export const useNavItemsVisibility = (visible: boolean) => {
  const { setNavItemsVisible } = useNavigationBarContext();

  useLayoutEffect(() => {
    setNavItemsVisible(false);
    return () => setNavItemsVisible(true);
  }, [setNavItemsVisible]);

  useLayoutEffect(() => setNavItemsVisible(visible), [setNavItemsVisible, visible]);
};
