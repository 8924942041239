import { Product } from '@polygence/common';
import { axiosBaseQuery } from '@polygence/common/api/fetch';
import { createApi } from '@reduxjs/toolkit/query/react';

import type { ProductItem } from 'src/types/zeus';

const transformProduct = (product: Product): ProductItem => {
  return {
    ...product,
    basePriceInDollars: parseFloat(product.basePriceInDollars),
    totalPriceInCents: product.totalPriceInCents,
  };
};

export const productApi = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: 'product',
  endpoints: (build) => {
    return {
      products: build.query<ProductItem[], void>({
        query: () => {
          return '/student-application/products/';
        },
        transformResponse: (response: Product[]) => {
          return response.map(transformProduct);
        },
      }),
      pitchableProducts: build.query<ProductItem[], void>({
        query: () => {
          return '/partnerships/pitchable_products/';
        },
        transformResponse: (response: Product[]) => {
          return response.map(transformProduct);
        },
      }),
    };
  },
});

export const { useProductsQuery, useLazyProductsQuery, usePitchableProductsQuery } = productApi;
