import NiceModal, { useModal, bootstrapDialog } from '@ebay/nice-modal-react';
import { Modal, ModalTitle, ModalBody } from '@polygence/components';
import ReactPlayer from 'react-player';

export const CounselorOnboardingVideoModal = NiceModal.create(
  ({ onboardingVideo, firstName }: { onboardingVideo: string; firstName: string }) => {
    const modal = useModal();

    return (
      <Modal {...bootstrapDialog(modal)} closeButton>
        <ModalTitle className="ps-10">
          I'm {firstName}, your Partnerships Manager here at Polygence.
        </ModalTitle>
        <ModalBody className="pt-8">
          <div className="d-flex justify-content-center">
            <ReactPlayer url={onboardingVideo} playing controls />
          </div>
        </ModalBody>
      </Modal>
    );
  },
);
