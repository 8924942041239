import { UserType } from '@polygence/common';
import type { CartUserDataVerification } from '@polygence/common/types/payment';
import { Text, Heading } from '@polygence/components';

import SuccessIcon from 'src/components/static/images/undraw_maker_launch_re.svg?react';
import { PaymentSuccessConfirmationMessage } from 'src/payment/PaymentSuccess/PaymentSuccessConfirmationMessage';

export interface PaymentSuccessDefaultMessageProps {
  containsFlagship: boolean;
  cartUser?: CartUserDataVerification;
}

export const PaymentSuccessDefaultMessage = ({
  containsFlagship,
  cartUser,
}: PaymentSuccessDefaultMessageProps) => {
  return (
    <>
      <Heading as="h1" size="h3" className="tw-mb-12">
        Congratulations, let's start building your project!
      </Heading>
      <SuccessIcon className="tw-mb-9" />
      <Text size="large" alignment="center">
        {containsFlagship
          ? "Thank you for your payment, we'll start looking for your mentor!"
          : 'We successfully received your payment.'}
      </Text>
      {containsFlagship && cartUser?.userType === UserType.STUDENT && (
        <PaymentSuccessConfirmationMessage cartUser={cartUser} />
      )}
    </>
  );
};
