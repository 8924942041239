import type { FeedbackRequestPublic } from '@polygence/common/types/hermes';
import { externalLink } from '@polygence/common/utils/externalLink';
import { Heading, Text } from '@polygence/components';

import { formatDateAsLocal } from 'src/components/aux/dateStamp';

export const WritingFeedbackDetails = ({
  feedbackRequest,
}: {
  feedbackRequest: FeedbackRequestPublic;
}) => {
  return (
    // eslint-disable-next-line react/forbid-dom-props
    <div className="d-flex flex-column gap-6" style={{ width: 'min-content' }}>
      <Heading size="h3" alignment="left" className="mb-4">
        Feedback request of {feedbackRequest.studentFirstName}
      </Heading>
      <div>
        <Text as="span" size="medium" fontWeight="bold">
          Submission Type:
        </Text>{' '}
        <span>{feedbackRequest.feedbackSubject}</span>
      </div>
      <div>
        <Text as="span" size="medium" fontWeight="bold">
          Paper due date:
        </Text>{' '}
        <span>{formatDateAsLocal(feedbackRequest.paperDueDate)}</span>
      </div>
      <div>
        <Text as="span" size="medium" fontWeight="bold">
          Student would like feedback on:
        </Text>{' '}
        <span>{feedbackRequest.wantedTopics}</span>
      </div>
      {feedbackRequest.pages && (
        <div>
          <Text as="span" size="medium" fontWeight="bold">
            Pages to focus on, if more than 10 pages:
          </Text>{' '}
          <span>{feedbackRequest.pages}</span>
        </div>
      )}
      <div>
        <Text as="span" size="medium" fontWeight="bold">
          Citation style:
        </Text>{' '}
        <span>{feedbackRequest.citationStyle}</span>
      </div>
      <div>
        <Text as="span" size="medium" fontWeight="bold">
          Rubric/style guide for desired publication:
        </Text>{' '}
        {feedbackRequest.fileStyleGuides === 'Not provided' ? (
          <span>{feedbackRequest.fileStyleGuides}</span>
        ) : (
          <a href={feedbackRequest.fileStyleGuides}>{feedbackRequest.fileStyleGuides}</a>
        )}
      </div>
      {feedbackRequest.paperLink && (
        <div>
          <Text as="span" size="medium" fontWeight="bold">
            Paper (Google doc) URL:
          </Text>{' '}
          <a href={feedbackRequest.paperLink} {...externalLink}>
            {feedbackRequest.paperLink}
          </a>
        </div>
      )}
    </div>
  );
};
