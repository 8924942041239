import type { Nullable } from '@polygence/common';
import type { ShowcasingOutcomeStatuses } from '@polygence/common/types/showcasing';
import { OptionType, Select } from '@polygence/components';
import { capitalize } from 'lodash';

interface ShowcasingStatusSelectorProps {
  label: string;
  onSelect: (_: ShowcasingOutcomeStatuses) => void;
  defaultOption: Nullable<ShowcasingOutcomeStatuses>;
}

const statusOptions: OptionType[] = [
  { value: 'plans_to_submit', label: 'Plans to submit' },
  { value: 'submitted', label: 'Submitted' },
  { value: 'accepted', label: 'Accepted' },
  { value: 'published', label: 'Published' },
];

const convertValueToLabel = (value: string) => {
  return capitalize(value).replace('_', ' ');
};

const convertOptionToValue = (option: OptionType) => {
  return option.value as ShowcasingOutcomeStatuses;
};

export const ShowcasingStatusSelector = ({
  label,
  onSelect,
  defaultOption,
}: ShowcasingStatusSelectorProps) => {
  const internalValue = defaultOption
    ? { label: convertValueToLabel(defaultOption), value: defaultOption }
    : undefined;

  return (
    <div className="my-5">
      <label htmlFor="status">{label}</label>
      <Select
        name="status"
        options={statusOptions}
        defaultValue={internalValue}
        onChange={(option) => onSelect(convertOptionToValue(option as OptionType))}
      />
    </div>
  );
};
