import { Button, Icon, InputField, Select, SingleValue, TileSelect } from '@polygence/components';
import { motion } from 'framer-motion';
import { debounce } from 'lodash';
import { useCallback, type ChangeEvent } from 'react';

import { Loader } from 'src/components/Loader';
import styles from 'src/components/student/pathfinders/PathfinderApplicationInterestPicker.module.scss';
import { TagOptionType, useTagsQuery } from 'src/reducers/marketplaceReducer';
import {
  pathfinderApplicationInterestApi,
  useCreatePathfinderApplicationInterestMutation,
  useEditPathfinderApplicationInterestMutation,
  usePathfinderApplicationInterestQuery,
} from 'src/reducers/pathfinderApplicationInterestReducer';
import { useAppDispatch } from 'src/store';

const MotionTile = motion(TileSelect);

export const PathfinderInterestPicker = ({
  interestId,
  interestIndex,
  applicationId,
  onChange,
}: {
  interestId: number;
  interestIndex: number;
  applicationId: number;
  onChange: (interestId: number) => void;
}) => {
  const dispatch = useAppDispatch();
  const { currentData: parentTags, isLoading: isParentTagsLoading } = useTagsQuery({
    category: 'subject_tag',
    type: 'parent',
  });

  const { currentData: childTags } = useTagsQuery({
    category: 'subject_tag',
    type: 'child',
  });

  // using negative ID for interests that should be considered invalid (still choosing parent tag)
  const { currentData: interest, isLoading: isInterestsLoading } =
    usePathfinderApplicationInterestQuery(interestId, { skip: interestId <= 0 });
  const [createInterest, { isLoading: isCreateInterestLoading }] =
    useCreatePathfinderApplicationInterestMutation();
  const [editInterest] = useEditPathfinderApplicationInterestMutation();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedEdit = useCallback(debounce(editInterest, 400), [editInterest]);

  const selectedTag = interest?.subjectTag[0];
  const selectedChildTag = childTags?.find(({ value }) => value === selectedTag);
  const selectedParentTag = selectedChildTag
    ? parentTags?.find(({ value }) => value === selectedChildTag.parentId)
    : parentTags?.find(({ value }) => value === selectedTag);
  const hasParentTagSelected = interestId > 0 && selectedParentTag;
  const selectedOption = selectedChildTag || selectedParentTag;

  const childTagsForSelectedParent = childTags?.filter(
    ({ parentId }) => parentId === selectedParentTag?.value,
  );

  const handleSubjectChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const { target } = event;

    const newValue = selectedOption?.value === +target.name ? null : +target.name;

    if (!newValue) {
      onChange(-interestId);
      return;
    }

    if (!interestId) {
      const { id } = await createInterest({
        subjectTag: [newValue],
        studentApplication: applicationId,
      }).unwrap();
      onChange(id);
    } else {
      await editInterest({ id: Math.abs(interestId), payload: { subjectTag: [newValue] } });
      onChange(Math.abs(interestId));
    }
  };

  const handleChildTagChange = async (value: SingleValue<TagOptionType>) => {
    const newValue = value ? value.value : selectedParentTag?.value;

    await handleSubjectChange({
      target: { name: newValue },
    } as ChangeEvent<HTMLInputElement>);
  };

  const handleNotesChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(
      pathfinderApplicationInterestApi.util.updateQueryData(
        'pathfinderApplicationInterest',
        Math.abs(interestId),
        (draft) => {
          Object.assign(draft, { notes: e.target.value });
        },
      ),
    );

    void debouncedEdit({ id: Math.abs(interestId), payload: { notes: e.target.value } });
  };

  const resetParentTag = () => {
    onChange(-interestId);
  };

  if (isParentTagsLoading) {
    return <Loader className="mx-auto my-8" />;
  }

  return (
    <>
      <motion.div className={styles['gridWrapper']} id="selectTopic" layout>
        {selectedOption && hasParentTagSelected ? (
          <>
            <motion.div
              initial={{ x: -50, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              layout="position"
            >
              <Button
                variant="link"
                onClick={() => resetParentTag()}
                startIcon={<Icon id="chevron-left" />}
              >
                {`Change topic #${interestIndex + 1} selection`}
              </Button>
            </motion.div>
            <MotionTile
              key={selectedOption.parentId ?? selectedOption.value}
              value={selectedOption.value}
              type="checkbox"
              onChange={(e) => void handleSubjectChange(e)}
              checked
              layout="position"
            >
              {selectedOption.label}
            </MotionTile>
          </>
        ) : (
          parentTags?.map(({ value, label }) => {
            return (
              <MotionTile
                disabled={isInterestsLoading || isCreateInterestLoading}
                key={value}
                value={value}
                type="checkbox"
                checked={value === selectedOption?.value}
                onChange={(e) => void handleSubjectChange(e)}
                layout="position"
              >
                {label}
              </MotionTile>
            );
          })
        )}
      </motion.div>
      {hasParentTagSelected && (
        <motion.div initial={{ y: 120, opacity: 0 }} animate={{ y: 0, opacity: 1 }}>
          {childTagsForSelectedParent && childTagsForSelectedParent.length > 0 && (
            <div className="row mt-5">
              <div className="col">
                <div className="form-group">
                  <label htmlFor="childTag" className="form-label">
                    What subtopic, if any, best matches your interest?
                  </label>
                  <Select
                    options={childTagsForSelectedParent}
                    name="childTag"
                    value={selectedChildTag}
                    placeholder="Select subtopic"
                    onChange={(e) => void handleChildTagChange(e as SingleValue<TagOptionType>)}
                    backspaceRemovesValue
                    isClearable
                  />
                </div>
              </div>
            </div>
          )}
          <div className="row mt-5">
            <div className="col">
              <InputField
                type="textarea"
                name="notes"
                label="Is there anything specific you want to learn about in this field?"
                floating={false}
                value={interest?.notes}
                onChange={handleNotesChange}
                placeholder="Feel free to add short bullet point answers, no need for full sentences here."
              />
            </div>
          </div>
        </motion.div>
      )}
    </>
  );
};
