import { Alert } from '@polygence/components';

interface StudentReferralBannerProps {
  firstProfileCompletionAt: string;
  bannerText: string;
}

const StudentReferralBanner = ({
  firstProfileCompletionAt,
  bannerText,
}: StudentReferralBannerProps) => {
  if (firstProfileCompletionAt || !bannerText) {
    return null;
  }

  return (
    <Alert variant="success" className="d-flex justify-content-center" dismissible>
      {bannerText}
    </Alert>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default StudentReferralBanner;
