import type { ComponentSection } from 'src/students/student-application';
import { collectFieldsToValidate, isSectionValid } from 'src/students/validation';

type ValidationResultPerSection = Record<
  string,
  {
    valid: boolean;
    fieldResults: { valid: boolean; message: string }[];
  }
>;

type InvalidSection = [
  keyof ValidationResultPerSection,
  ValidationResultPerSection[keyof ValidationResultPerSection],
];

const getSectionIndexByName = <TSectionID extends string>(
  sections: readonly ComponentSection<TSectionID>[],
  sectionId: string,
) => {
  const index = sections.findIndex(({ id }) => {
    return id === sectionId;
  });
  return index > -1 ? index : 0;
};

export const validateSectionsAsRequired = <
  TData extends Record<string, unknown>,
  TSectionID extends string,
>(
  sections: readonly ComponentSection<TSectionID>[],
  data: TData,
) => {
  const fieldsToValidate = sections.reduce(
    (acc, section) => ({ ...acc, [section.id]: collectFieldsToValidate(section.elements) }),
    {},
  );

  const validationResultPerSections = Object.entries(fieldsToValidate).reduce(
    (acc, [sectionId, fields]) => {
      return { ...acc, [sectionId]: isSectionValid(fields, data) };
    },
    {},
  ) as ValidationResultPerSection;

  const firstInvalidSection = Object.entries(validationResultPerSections).find(
    ([_, result]) => !result.valid,
  ) as InvalidSection | undefined;

  return firstInvalidSection ? getSectionIndexByName(sections, firstInvalidSection[0]) : undefined;
};
