import { commonReducers, commonThunks, StudentApplication } from '@polygence/common';
import { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from 'src/store';

export const useStudentApplication = () => {
  const dispatch = useAppDispatch();
  const studentApplication = useAppSelector((state) => state.studentApplication.data);

  const getStudentApplication = useCallback(
    (profileId: number, applicationId: number) => {
      return dispatch(
        commonThunks.applicationThunks.getStudentApplication({
          profileId,
          applicationId,
        }),
      );
    },
    [dispatch],
  );

  const getLatestStudentApplication = useCallback(
    (profileId: number) => {
      return dispatch(commonThunks.applicationThunks.getLatestStudentApplication({ profileId }));
    },
    [dispatch],
  );

  const updateStudentApplication = (profileId: number, applicationId: number) => {
    return dispatch(
      commonThunks.applicationThunks.updateApplication({
        profileId,
        applicationId,
      }),
    ).unwrap();
  };

  const updateStudentApplicationState = (partialState: Partial<StudentApplication>) => {
    return dispatch(
      commonReducers.studentApplicationActions.updateApplicationData({
        studentApplicationUpdate: partialState,
      }),
    );
  };

  const submitStudentApplication = (profileId: number, applicationId: number) => {
    return dispatch(
      commonThunks.applicationThunks.submitApplication({
        profileId: profileId,
        applicationId: applicationId,
      }),
    ).unwrap();
  };

  return {
    studentApplication,
    getStudentApplication,
    getLatestStudentApplication,
    updateStudentApplicationState,
    updateStudentApplication,
    submitStudentApplication,
  };
};
