import { useDispatch } from 'react-redux';

import * as marketplaceApi from '../api/marketplace';
import { userActions } from '../store/reducers/userReducer';

export const useRefetchCurrentUser = () => {
  const dispatch = useDispatch();
  const refetch = () => {
    marketplaceApi
      .getUserInfo()
      .then(({ data }) => {
        dispatch(userActions.loadUser(data));
      })
      .catch(console.error);
  };
  return { refetch };
};
