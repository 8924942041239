import { Card, Heading } from '@polygence/components';
import classNames from 'classnames';
import type { ReactNode } from 'react';

import styles from 'src/components/Tiles/StudentJourney/common.module.scss';
import { useStudentJourney } from 'src/components/Tiles/StudentJourney/useStudentJourney';

export const StudentJourneyProduct = ({
  title,
  children,
  loader,
  dashed = false,
}: {
  title: string;
  children: ReactNode;
  loader: ReactNode;
  dashed?: boolean;
}) => {
  const { isLoading } = useStudentJourney();

  return (
    <Card
      as="section"
      className={classNames(styles['studentJourneyProductCard'], dashed ? styles['pitchCard'] : '')}
    >
      <Heading as="h1" size="h4" alignment="center" className="mb-4">
        {title}
      </Heading>
      {isLoading ? loader : children}
    </Card>
  );
};
