import { PREMIUM_SHOWCASING_PUBLISHING } from '@polygence/common/constants/productNames';
import type { ProductId } from '@polygence/common/types/common';
import type { Product } from '@polygence/common/types/studentApplication';
import { Badge, Button, Icon, Text, Tooltip } from '@polygence/components';

import { PaymentIntentAmountOrPlaceholder } from 'src/payment/PaymentIntent/PaymentIntentAmountOrPlaceholder';

interface PaymentIntentAddableProductProps {
  v3Enabled?: boolean;
  product: Product;
  disabled: boolean;
  onClick: (productId: ProductId) => void;
}

export const PaymentIntentAddableProduct = ({
  v3Enabled,
  product,
  disabled,
  onClick,
}: PaymentIntentAddableProductProps) => {
  return (
    <div className="tw-flex tw-flex-wrap tw-items-start tw-gap-3">
      <div>
        <Text
          as="span"
          size="medium"
          alignment="left"
          fontWeight="bold"
          className="tw-text-sm tw-text-inherit"
        >
          {product.publicName}
        </Text>
        {product.publicDetails && (
          <Tooltip tip={product.publicDetails} placement="top">
            <Icon id="info" size="sm" className="-tw-mt-1 tw-ml-1 tw-text-gray-400" />
          </Tooltip>
        )}
        {v3Enabled && product.name === PREMIUM_SHOWCASING_PUBLISHING && (
          <Badge
            pill
            size="small"
            variant="primary"
            className="tw-ml-2 tw-w-fit tw-rounded-3xl tw-bg-yellow-400 tw-px-2 tw-py-1.5 tw-font-bold tw-text-yellow-900"
          >
            70% of students add this
          </Badge>
        )}
      </div>
      <div className="tw-ms-auto tw-flex tw-items-center tw-gap-4">
        {Number(product.basePriceInDollars) !== product.totalPriceInCents / 100 && (
          <PaymentIntentAmountOrPlaceholder
            amount={+product.totalPriceInCents / 100}
            size="small"
            className="tw-text-sm tw-line-through tw-opacity-60"
          />
        )}
        <PaymentIntentAmountOrPlaceholder
          amount={+product.basePriceInDollars}
          className="tw-text-sm tw-text-inherit"
        />
        <Button
          data-cy={`add-${product.publicName}`}
          variant="secondary"
          size="sm"
          disabled={disabled}
          className="tw-font-bold"
          onClick={() => onClick(product.id)}
        >
          Add
        </Button>
      </div>
    </div>
  );
};
