import * as paymentApi from '@polygence/common/api/payment';
import { UserId } from '@polygence/common/types/common';
import { Button } from '@polygence/components';
import { ReactNode, useState } from 'react';
import type { ComponentProps } from 'react';
import { toast } from 'react-toastify';

import { STUDENT_TEAM_EMAIL } from 'src/constants';
import { navigateTo } from 'src/utils/navigateTo';

export const ManagePaymentMethodsButton = ({
  children,
  userId,
  buttonVariant = 'primary',
  redirect = true,
}: {
  children: ReactNode;
  userId?: UserId;
  buttonVariant?: ComponentProps<typeof Button>['variant'];
  buttonSize?: ComponentProps<typeof Button>['size'];
  redirect?: boolean;
}) => {
  const [sending, setSending] = useState(false);
  const handleClick = () => {
    setSending(true);
    paymentApi
      .getCustomerPortalUrl(userId)
      .then((value) => {
        if (redirect) {
          toast.success('Redirecting to Stripe Customer Portal...');
          navigateTo(value.data.customerPortalUrl);
        } else {
          toast.success('Copied to clipboard');
          void navigator.clipboard.writeText(value.data.customerPortalUrl);
        }
      })
      .catch(() => {
        toast.error(`Something went wrong. Please contact ${STUDENT_TEAM_EMAIL}`);
      })
      .finally(() => {
        setSending(false);
      });
  };
  return (
    <Button variant={buttonVariant} disabled={sending} onClick={handleClick} size="sm">
      {children}
    </Button>
  );
};
