import {
  AutoLinkPlugin as LexicalAutoLinkPlugin,
  LinkMatcher,
} from '@lexical/react/LexicalAutoLinkPlugin';

/* eslint-disable unicorn/no-unsafe-regex */
const URL_MATCHER =
  /((https?:\/\/(www\.)?)|(www\.))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.[\],|~#?&//=]*)/;

const EMAIL_MATCHER =
  /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;

const matchUrl: LinkMatcher = (text) => {
  const match = URL_MATCHER.exec(text);
  if (!match) {
    return null;
  }

  const url = match[0];
  return {
    index: match.index,
    length: url.length,
    text: url,
    url,
  };
};

const matchEmail: LinkMatcher = (text) => {
  const match = EMAIL_MATCHER.exec(text);
  if (!match) {
    return null;
  }

  const email = match[0];
  return {
    index: match.index,
    length: email.length,
    text: email,
    url: `mailto:${email}`,
  };
};

const MATCHERS: Array<LinkMatcher> = [matchUrl, matchEmail];

const AutoLinkPlugin = () => {
  return <LexicalAutoLinkPlugin matchers={MATCHERS} />;
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default AutoLinkPlugin;
