import { getBaseApiUrl } from '@polygence/common';
import camelcase from 'camelcase-keys';
import { toast } from 'react-toastify';
import snakecase from 'snakecase-keys';

import { getAuthorizationHeader } from 'src/utils';
import { isImpersonator } from 'src/utils/auth';

const ABORT_ERROR = 'AbortError';
const APPLICATION_JSON = 'application/json';

const handleUnauthorizedRequest = (response) => {
  if (response.status === 401) {
    if (!isImpersonator()) {
      localStorage.removeItem('token');
    }
    toast.error('You are not logged in.');
    return new Promise(() => {});
  }
  return response;
};

export const customFetch = (url, opts) => {
  // handle network error:
  const handleError = (error) => {
    if (error.name === ABORT_ERROR) {
      throw error;
    }
    console.error(error);
    // eslint-disable-next-line no-throw-literal
    throw {
      status: 0,
      ok: false,
      statusText: 'connection error',
      body: {
        non_field_errors: 'Cannot connect. Please make sure you are connected to internet.',
      },
    };
  };

  // check if server responded with ok
  const checkStatus = (response) => {
    if (response.status >= 200 && response.status < 300) {
      return response;
    }

    return response.json().then((json) => {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        status: response.status,
        ok: false,
        statusText: response.statusText,
        body: json,
      });
    });
  };

  // parse JSON if there is any:
  const parseJSON = (response) => {
    if (response.status === 204 || response.status === 205) {
      return response;
    }
    return response.json();
  };

  return fetch(url, opts)
    .catch(handleError) // handle network issues
    .then(handleUnauthorizedRequest)
    .then(checkStatus)
    .then(parseJSON);
};

const controllers = {};

/**
 *
 * @param {string} url
 * @param {RequestInit} options
 * @returns
 */
export const authFetch = (url, { headers, ...opts } = {}, baseUrlGetter = undefined) => {
  const getBaseUrl = baseUrlGetter || getBaseApiUrl;

  return customFetch(`${getBaseUrl()}${url}`, {
    method: 'GET',
    headers: {
      ...getAuthorizationHeader(),
      Accept: APPLICATION_JSON,
      'Content-Type': APPLICATION_JSON,
      'X-Polygence-client': 'web',
      ...headers,
    },
    ...opts,
  });
};

export const abortableAuthFetch = async (url, opts = {}) => {
  const baseUrl = new URL(url, getBaseApiUrl());
  const { method = 'GET' } = opts;
  const key =
    `${method}${baseUrl.pathname}` +
    (baseUrl.searchParams.has('type') ? `-${baseUrl.searchParams.get('type')}` : '');
  try {
    if (controllers[key]) {
      controllers[key].abort();
    }
    // eslint-disable-next-line fp/no-mutation
    controllers[key] = new AbortController();
    const { signal } = controllers[key];
    return await authFetch(url, {
      signal,
      ...opts,
    });
  } catch (error) {
    console.error(error);
    return { next: null, results: [], error };
  }
};

export const authFileUploadFetch = (url, { headers, ...opts } = {}) => {
  return customFetch(`${getBaseApiUrl()}${url}`, {
    method: 'PUT',
    headers: {
      ...getAuthorizationHeader(),
      'X-Polygence-client': 'web',
      ...headers,
    },
    ...opts,
  });
};

export const caseConvertFetch = (url, { body, ...options } = {}, ...params) => {
  const convertedOptions = body
    ? {
        ...options,
        body: JSON.stringify(snakecase(body, { deep: true })),
      }
    : options;
  return abortableAuthFetch(url, convertedOptions).then((response) => {
    return camelcase(response, { deep: true });
  });
};
