import { validationError } from '@data-driven-forms/mui-component-mapper';
import useFieldApi from '@data-driven-forms/react-form-renderer/use-field-api';
import Box from '@material-ui/core/Box';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';

import styles from 'src/components/data-driven-forms/DataDrivenFormNPSInput.module.scss';

const RadioOption = ({ name, option, RadioProps }) => {
  const { input } = useFieldApi({
    name,
    type: 'radio',
    value: option.value,
  });
  return (
    <label className={styles['container']}>
      <input {...input} name={name} onChange={() => input.onChange(option.value)} {...RadioProps} />
      <span>{option.label}</span>
    </label>
  );
};

const DataDrivenFormNPSInput = ({ name, ...props }) => {
  const {
    options,
    isDisabled,
    label,
    isRequired,
    helperText,
    description,
    isReadOnly,
    meta,
    validateOnMount,
    FormFieldGridProps,
    FormControlProps,
    FormLabelProps,
    FormHelperTextProps,
    ...rest
  } = useFieldApi({
    ...props,
    name,
    type: 'radio',
  });
  const invalid = validationError(meta, validateOnMount);
  const text =
    invalid || ((meta.touched || validateOnMount) && meta.warning) || helperText || description;
  return (
    <Box component="fieldset" my={3} borderColor="transparent">
      <Typography
        component="legend"
        className={classnames('MuiFormLabel-root', {
          'Mui-error': meta.error && meta.touched,
        })}
      >
        {props.label}
      </Typography>
      {text && <FormHelperText {...FormHelperTextProps}>{text}</FormHelperText>}
      <div className={styles.wrapper}>
        {Array.from({ length: 11 }).map((_, index) => {
          const value = index;
          return (
            // eslint-disable-next-line react/no-array-index-key
            <RadioOption key={index} name={name} option={{ label: value, value }} {...rest} />
          );
        })}
      </div>
    </Box>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default DataDrivenFormNPSInput;
