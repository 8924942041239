import { PortfolioItemId } from '@polygence/common';
import { axiosBaseQuery } from '@polygence/common/api/fetch';
import type { CaseStudy } from '@polygence/common/types/site-content';
import { createApi } from '@reduxjs/toolkit/query/react';

export const siteContentApi = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: 'sitecontent',
  endpoints: (builder) => {
    return {
      getLatestCaseStudy: builder.query<CaseStudy, void>({
        query: () => {
          return '/sitecontent/case-study/latest/';
        },
      }),
      unpublishCaseStudy: builder.mutation<void, { portfolioItemId: PortfolioItemId }>({
        query: ({ portfolioItemId }) => {
          return {
            url: `/sitecontent/case-study/${portfolioItemId}/unpublish/`,
            method: 'PATCH',
          };
        },
      }),
    };
  },
});

export const { useGetLatestCaseStudyQuery, useUnpublishCaseStudyMutation } = siteContentApi;
