import classnames from 'classnames';
import intlTelInput from 'intl-tel-input';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';

import 'intl-tel-input/build/css/intlTelInput.css';
import { FormInputWithValidation } from 'src/components/FormInputWithValidation';

const ERROR_MAP = [
  // eslint-disable-next-line sonarjs/no-duplicate-string -- autodisabled
  'Invalid number',
  'Invalid country code',
  'Too short',
  'Too long',
  'Invalid number',
];

const PhoneNumberInput = ({
  handleChange,
  onBlur,
  name,
  value,
  label,
  required,
  small_font: smallFont,
  component: Component = FormInputWithValidation,
  shouldValidate,
  onError = [],
  wrapperClassNames = ['mt-5'],
  htmlRequired,
}) => {
  const inputRef = useRef();
  const [iti, setIti] = useState(null);

  const validate = () => {
    if (!shouldValidate) {
      return;
    }
    if (iti.isValidNumber()) {
      inputRef.current.setCustomValidity('');
    } else {
      const errorMessage = ERROR_MAP[iti.getValidationError()];
      if (errorMessage) {
        inputRef.current.setCustomValidity(errorMessage);
      } else {
        inputRef.current.setCustomValidity('Invalid number');
      }
    }
  };

  const onChange = () => {
    validate();
    handleChange({ target: { name, value: iti.getNumber() } });
  };

  const getPlaceholder = (selectedCountryPlaceholder, selectedCountryData) => {
    if (selectedCountryData.iso2 === 'us') {
      return '(000) 000-0000';
    }
    if (selectedCountryData.iso2 === 'in') {
      return '000000 00000';
    }
    return selectedCountryPlaceholder;
  };

  useEffect(() => {
    let itiRef;
    if (inputRef.current) {
      // eslint-disable-next-line fp/no-mutation -- autodisabled
      itiRef = intlTelInput(inputRef.current, {
        autoPlaceholder: 'polite',
        initialCountry: 'US',
        customPlaceholder: getPlaceholder,
        utilsScript:
          'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.13/js/utils.min.js',
      });

      setIti(itiRef);
    }

    return () => {
      if (itiRef) {
        itiRef.destroy();
      }
    };
  }, []);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = value;
    }
  }, [value]);

  useEffect(() => {
    if (!value && iti) {
      iti.setNumber('');
      inputRef.current.setCustomValidity('');
    }
  }, [value, iti]);

  return (
    // eslint-disable-next-line react/jsx-filename-extension -- autodisabled
    <div className={classnames('d-inline-block w-100', wrapperClassNames)}>
      <Component
        type="tel"
        name={name}
        label={label}
        small_font={smallFont}
        onChange={onChange}
        onBlur={onBlur}
        onError={onError}
        ref={inputRef}
        required={required}
        htmlRequired={htmlRequired}
      />
    </div>
  );
};

PhoneNumberInput.propTypes = {
  handleChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  small_font: PropTypes.string,
  value: PropTypes.string,
  wrapperClassNames: PropTypes.arrayOf(PropTypes.string),
  required: PropTypes.bool,
  shouldValidate: PropTypes.bool,
  component: PropTypes.elementType,
  onError: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.object)]),
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default PhoneNumberInput;
