import { useState, useEffect } from 'react';

export const usePageVisibility = () => {
  const [visibilityStatus, setVisibilityStatus] = useState(!document.hidden);

  useEffect(() => {
    const handleVisibilityChange = () => setVisibilityStatus(!document.hidden);

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return visibilityStatus;
};
