import { ProductId, InvitationId } from '@polygence/common';
import { axiosBaseQuery } from '@polygence/common/api/fetch';
import type { Invitation, ProspectiveUser } from '@polygence/common/types/backend';
import { createApi } from '@reduxjs/toolkit/query/react';

export const backendApi = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: 'backend',
  tagTypes: ['Invitation'],
  endpoints: (builder) => {
    return {
      getProspectiveUser: builder.query<ProspectiveUser, { token: string }>({
        query: ({ token }) => {
          return { url: `/user/prospective-users/${token}/`, method: 'get' };
        },
      }),
      counselorRegistrationEnabled: builder.query<{ enabled: boolean }, void>({
        query: () => {
          return { url: '/user/counselor-registration-enabled/', method: 'get' };
        },
      }),
      getInvitations: builder.query<Invitation[], { isPending?: boolean }>({
        providesTags: ['Invitation'],
        query: ({ isPending }) => {
          return { url: '/user/invitation/', params: { is_pending: isPending }, method: 'get' };
        },
      }),
      sendInvitation: builder.mutation<
        Invitation,
        {
          email: string;
          firstName: string;
          phoneNumber?: string;
          inviterUserId?: number;
          productType?: string;
          podSlug?: string;
          ccEmails?: string[];
          products?: ProductId[];
        }
      >({
        invalidatesTags: ['Invitation'],
        query: ({
          email,
          firstName,
          phoneNumber,
          inviterUserId,
          productType,
          podSlug,
          ccEmails,
          products,
        }) => {
          return {
            url: '/user/invitation/',
            method: 'post',
            data: {
              email,
              firstName,
              phoneNumber,
              inviterUserId,
              productType,
              podSlug,
              ccEmails,
              products,
            },
          };
        },
      }),
      resendInvitation: builder.mutation<void, { invitationId: InvitationId }>({
        query: ({ invitationId }) => ({
          url: `/user/resend-invitation/${invitationId}/`,
          method: 'POST',
        }),
      }),
    };
  },
});

export const {
  useGetProspectiveUserQuery,
  useCounselorRegistrationEnabledQuery,
  useGetInvitationsQuery,
  useSendInvitationMutation,
  useResendInvitationMutation,
} = backendApi;
