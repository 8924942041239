import React, { useState } from 'react';

import tileIllustrations from 'src/components/Tiles/tile-illustrations';
import UniLink from 'src/components/UniLink';

/* eslint-disable react/prop-types,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
/* eslint-disable sonarjs/no-gratuitous-expressions, react/forbid-dom-props */
export const Label = ({ label, name, children }) => {
  return (
    <div className="tile position-relative">
      {children || (
        <>
          <h2 className="p-5 position-absolute">{label}</h2>
          {name && <img src={tileIllustrations[name]} alt={label} className="position-absolute" />}
        </>
      )}
    </div>
  );
};

export const Tile = ({ name, label, href, items, enabled = true, onClick, col = 1, children }) => {
  if ((name && (href || items)) || children) {
    const checkedHref = enabled ? href : '#';
    return (
      <div
        onClick={(...params) => {
          return enabled && typeof onClick === 'function' && onClick(...params);
        }}
        className={`tile-container col-sm-${col * 6} col-md-${col * 4} col-lg-${col * 3} my-5 ${
          enabled ? 'clickable' : 'disabled-tile'
        } ${name}`}
      >
        {items && <TileItems label={label} name={name} items={items} enabled={enabled} />}
        {!items && href && (
          <UniLink to={checkedHref} className={enabled ? '' : 'disabled'}>
            <Label label={label} name={name}>
              {children}
            </Label>
          </UniLink>
        )}
        {!items && !href && <div className="tile-dashboard tile cursor-auto">{children}</div>}
      </div>
    );
  }
  return null;
};

export const TileItems = ({
  name,
  label: mainLabel,
  items,
  enabled,
  children,
  Container,
  ...rest
}) => {
  const [open, setOpen] = useState(false);

  if (open) {
    if (Container) {
      return (
        <Container {...rest} clickable={enabled && !open} Container="div">
          <div className="p-5 position-absolute" style={{ bottom: 0 }}>
            {items.map(({ label, href }) => {
              return (
                <UniLink key={`link-${label}-${href}`} href={href} className="d-block">
                  <h4>{label}</h4>
                </UniLink>
              );
            })}
          </div>
        </Container>
      );
    }
    return (
      <div className="tile p-5">
        {items.map(({ label, href }) => {
          return (
            <UniLink key={`link-${label}-${href}`} href={href} className="d-block">
              {label}
            </UniLink>
          );
        })}
      </div>
    );
  }
  if (items.length === 1) {
    const { label: itemLabel } = items[0];
    const href = enabled ? items[0].href : '#';
    return (
      <UniLink to={href}>
        <Label label={itemLabel} name={name} />
      </UniLink>
    );
  }
  return (
    <div
      onClick={() => {
        return enabled && setOpen(true);
      }}
    >
      {mainLabel ? <Label label={mainLabel} name={name} /> : children}
    </div>
  );
};
