import { commonHooks } from '@polygence/common';
import * as marketplaceApi from '@polygence/common/api/marketplace';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import Modal from 'react-modal';

import { PageLoad } from 'src/components/PageLoad';
import { TileItems } from 'src/components/Tiles/BasicTiles';
import tileIllustrations from 'src/components/Tiles/tile-illustrations';
import tileStyles from 'src/components/Tiles/tilesDashboard.module.scss';
import UniLink from 'src/components/UniLink';
import getResizedImageUrl from 'src/components/aux/getResizedImageUrl';
import { MentorOverview } from 'src/components/mentor/MentorOverview';
import defaultProfileImage from 'src/components/static/images/generic-profile.jpg';
import { getApplicationUrl, getProfileUrl } from 'src/utils/navigation';

/* eslint-disable react/prop-types,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */

export const GeneralProfileTile = ({
  pictureUrl,
  userType,
  containerUrl,
  Container = UniLink,
  clickable = true,
  children,
  id = '',
}) => {
  const url = containerUrl || getProfileUrl(userType);
  return (
    // eslint-disable-next-line react/jsx-filename-extension -- autodisabled
    <Container
      className={classNames(tileStyles['tile'], `d-flex ${clickable ? 'clickable' : ''}`)}
      to={url}
      id={id}
    >
      <img src={pictureUrl} alt="Profile" className="profile-pic" />
      <div className="position-relative profile-container">
        {children || <h2 className="p-5 position-absolute">Profile</h2>}
        <img
          src={tileIllustrations.rocket}
          alt="Application and profile"
          className={classNames(tileStyles['profileIllustration'], 'position-absolute top-0 end-0')}
        />
      </div>
    </Container>
  );
};

export const StudentProfileTile = (props) => {
  return (
    <GeneralProfileTile
      {...props}
      containerUrl={getApplicationUrl('student')}
      id="application-tile"
    >
      <h2 className="p-5 position-absolute">Core Application</h2>
    </GeneralProfileTile>
  );
};

export const MentorProfileTile = ({ screened, ...props }) => {
  const [mentor, setMentor] = useState(null);
  const [showProfile, setShowProfile] = useState(false);

  useEffect(() => {
    marketplaceApi
      .getHomeProfile()
      .then(({ data }) => {
        if (data) {
          setMentor(data);
        }
      })
      .catch(console.error);
  }, []);

  const openModal = () => {
    return setShowProfile(true);
  };
  const items = [
    {
      label: 'Edit Profile',
      href: getProfileUrl('mentor', screened),
    },
    {
      label: 'Preview Profile',
      href: openModal,
    },
    {
      label: 'Payment info',
      href: '/dashboard/payment-setup',
    },
  ];
  const profileUrl = getProfileUrl('mentor', screened);

  if (!screened) {
    return (
      <GeneralProfileTile {...props} containerUrl={profileUrl}>
        <h2 className="p-5 position-absolute">Application</h2>
      </GeneralProfileTile>
    );
  }

  return (
    <>
      <Modal
        isOpen={showProfile}
        shouldCloseOnOverlayClick
        onRequestClose={() => {
          return setShowProfile(false);
        }}
        overlayClassName="ReactModal__Overlay"
      >
        <button
          type="button"
          onClick={() => {
            return setShowProfile(false);
          }}
          className="close"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        {!mentor ? (
          <PageLoad />
        ) : (
          <div className="modal-center-mentor-overview">
            <MentorOverview profile={mentor} />
          </div>
        )}
      </Modal>
      <TileItems enabled items={items} {...props} Container={GeneralProfileTile}>
        <GeneralProfileTile {...props} containerUrl={profileUrl} Container="div">
          <h2 className="p-5 position-absolute">Profile</h2>
        </GeneralProfileTile>
      </TileItems>
    </>
  );
};

const ProfileTile = ({ userType, screened, completed, onClick }) => {
  const { profilePicture } = commonHooks.useCurrentUser();

  const pictureUrl =
    profilePicture !== ''
      ? getResizedImageUrl(`https://dpl6hyzg28thp.cloudfront.net/media/${profilePicture}`, 'x180')
      : defaultProfileImage;

  const tileTypes = {
    student: StudentProfileTile,
    mentor: MentorProfileTile,
  };
  const UserProfileTile = tileTypes[userType] || GeneralProfileTile;

  return (
    <div
      onClick={onClick}
      className={`profile profile-${userType} h-100 ${
        // eslint-disable-next-line sonarjs/no-all-duplicated-branches -- autodisabled
        completed ? 'completed' : 'completed'
      }`}
    >
      <UserProfileTile pictureUrl={pictureUrl} userType={userType} screened={screened} />
    </div>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default ProfileTile;
