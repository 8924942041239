import NiceModal from '@ebay/nice-modal-react';
import { Button, Card, Heading, Text } from '@polygence/components';
import classNames from 'classnames';

import styles from 'src/components/student/ApplicationHubPage/PSSInfoBox.module.scss';
import { PSSInfoModal } from 'src/components/student/ApplicationHubPage/PSSInfoModal';

interface PSSInfoBoxProps {
  topText: string;
  onClick: () => void;
}

export const PSSInfoBox = ({ topText, onClick }: PSSInfoBoxProps) => {
  const handleClick = () => {
    NiceModal.show(PSSInfoModal, {}).catch(console.error);
    onClick();
  };

  return (
    <Card className={styles['pss-info-box']}>
      <Text size="medium" fontWeight="bold" className="mb-2">
        {topText}
      </Text>
      <Heading size="h4" as="h4" fontWeight="bold" alignment="left" className="mb-7">
        Premium Showcasing Support
      </Heading>
      <Text size="medium" fontWeight="light" className={classNames(styles['description'], 'mb-9')}>
        This three session add-on is designed to support
        <br />
        you in your showcasing and publishing journey.
      </Text>
      <Button
        type="button"
        variant="primary"
        onClick={handleClick}
        className="align-self-start px-10"
      >
        Learn more
      </Button>
    </Card>
  );
};
