import { externalLink } from '@polygence/common';
import { Link } from 'react-router-dom';

const isExternalLink = (url: string) => url.startsWith('http');

export const GuidebookLink = ({
  href,
  children,
  ...props
}: {
  href: string;
  children: React.ReactNode;
}) => {
  return isExternalLink(href) ? (
    <a href={href} {...externalLink} {...props}>
      {children}
    </a>
  ) : (
    <Link to={href} {...props}>
      {children}
    </Link>
  );
};
