import type { AxiosResponse } from 'axios';
import { useEffect, useRef, useCallback } from 'react';
import { toast } from 'react-toastify';

import { internalFetch } from 'src/fetch';

const VERSION_CHECK_INTERVAL = 5 * 60 * 1000;
const SOFTWARE_UPDATE_TOAST_ID = 'software_update';

const useVersionChecker = () => {
  const initialVersion = useRef<string | null>(null);
  const intervalId = useRef<ReturnType<typeof setInterval> | null>(null);

  useEffect(() => {
    const unsubscribe = toast.onChange((payload) => {
      if (payload.id === SOFTWARE_UPDATE_TOAST_ID && payload.status === 'removed') {
        window.location.reload();
      }
    });

    return () => unsubscribe();
  });

  const fetchVersion = () => {
    return internalFetch('/version/').then(
      ({ data: { releaseVersion } }: AxiosResponse<{ releaseVersion: string }>) => {
        return releaseVersion;
      },
      () => {
        return Promise.reject(Error('failed to fetch version'));
      },
    );
  };

  const setInitialVersion = useCallback((version: string) => {
    if (!initialVersion.current) {
      initialVersion.current = version;
    }
    return version;
  }, []);

  const checkVersion = useCallback((version: string) => {
    if (version !== initialVersion.current) {
      toast.warning(
        'There was a software update affecting this page. Please refresh now to avoid errors.',
        { toastId: SOFTWARE_UPDATE_TOAST_ID, autoClose: false, closeButton: false },
      );
      intervalId.current && clearInterval(intervalId.current);
    }
  }, []);

  useEffect(() => {
    fetchVersion()
      .then(setInitialVersion)
      .catch(() => null);

    intervalId.current = setInterval(() => {
      fetchVersion()
        .then(checkVersion)
        .catch(() => null);
    }, VERSION_CHECK_INTERVAL);
    return () => {
      intervalId.current && clearInterval(intervalId.current);
    };
  }, [setInitialVersion, checkVersion]);
};

const VersionChecker = () => {
  useVersionChecker();

  return null;
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default VersionChecker;
