import { useEffect, useState } from 'react';

export const Autosave = ({ saving }: { saving: boolean }) => {
  const [show, setShow] = useState(saving);
  useEffect(() => {
    if (saving) {
      setShow(true);
      return;
    }
    const timer = setTimeout(() => {
      setShow(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, [saving]);

  return show ? <div className="tw-sticky tw-bottom-0 tw-ml-2">Autosaving...</div> : null;
};
