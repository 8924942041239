import type { PathfindersStudentApplicationId, StudentProfileId } from '@polygence/common';

import { getRestClient } from '../commonSettings';
import type {
  CompletePathfinderApplication,
  PathfinderStudentApplication,
  PathfinderStudentApplicationInterim,
} from '../types/pathfinderStudentApplication';

export const startNewPathfinderApplication = (profileId: StudentProfileId) => {
  const internalFetch = getRestClient();
  return internalFetch.post<PathfinderStudentApplication>(
    `/student-application/students/${profileId}/applications/pathfinders/`,
  );
};

export const getPathfinderApplication = (
  profileId: StudentProfileId,
  applicationId: PathfindersStudentApplicationId,
) => {
  const internalFetch = getRestClient();
  return internalFetch.get<PathfinderStudentApplication>(
    `/student-application/students/${profileId}/applications/pathfinders/${applicationId}/`,
  );
};

export const updatePathfinderApplication = (
  profileId: StudentProfileId,
  applicationId: PathfindersStudentApplicationId,
  payload: Partial<PathfinderStudentApplication>,
) => {
  const internalFetch = getRestClient();
  return internalFetch.patch<PathfinderStudentApplication>(
    `/student-application/students/${profileId}/applications/pathfinders/${applicationId}/`,
    payload,
  );
};

export const getPathfinderApplications = (profileId: StudentProfileId) => {
  const internalFetch = getRestClient();
  return internalFetch.get<PathfinderStudentApplication[]>(
    `/student-application/students/${profileId}/applications/pathfinders/`,
  );
};

export const completePathfinderApplication = (
  profileId: StudentProfileId,
  applicationId: PathfindersStudentApplicationId,
) => {
  const internalFetch = getRestClient();
  return internalFetch.post<CompletePathfinderApplication>(
    `/student-application/students/${profileId}/applications/pathfinders/${applicationId}/complete/`,
  );
};

export const getPathfinderApplicationInterim = (applicationUuid: string) => {
  const internalFetch = getRestClient();
  return internalFetch.get<PathfinderStudentApplicationInterim>(
    `/student-application/students/applications/pathfinders/${applicationUuid}/interim/`,
  );
};
