/* eslint-disable fp/no-mutation */
import useSelectedProject from './selectors/useSelectedProject';
import useSelectedWorkspace from './selectors/useSelectedWorkspace';

export function usePermissions() {
  const project = useSelectedProject();
  const workspace = useSelectedWorkspace();

  let permissions: string[] = [];

  if (project && project.permissions) {
    permissions = [...project.permissions];
  }
  if (workspace && workspace.permissions) {
    permissions = [...permissions, ...workspace.permissions];
  }

  return permissions;
}
