const FOLDER = '/media/';

type Maybe<T> = T | null | undefined;

const getResizedImageUrl = (url: Maybe<string>, size: string): string => {
  return (url || '').split(FOLDER).join(`/${size}${FOLDER}`);
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default getResizedImageUrl;
