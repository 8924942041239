import { useEffect } from 'react';

import { useCommonDispatch } from '../store/store';
import { commonThunks } from '../store/thunks/index';
import { dayjs } from '../utils/dayjsCustom';

import useSelectedProject from './selectors/useSelectedProject';
import useNextSessionMeeting from './useNextSessionMeeting';

export function useSessionList() {
  const dispatch = useCommonDispatch();
  const selectedProject = useSelectedProject();
  const sessions = selectedProject?.sessions ?? [];
  const nextSessionMeeting = useNextSessionMeeting();

  useEffect(() => {
    if (selectedProject?.id) {
      void dispatch(
        commonThunks.hermesThunks.getSessionsForProject({ projectId: selectedProject.id }),
      );
    }
  }, [dispatch, selectedProject?.id]);

  useEffect(() => {
    if (selectedProject?.id && nextSessionMeeting?.id) {
      const nextSessionMeetingIn =
        dayjs(nextSessionMeeting.scheduledAt).valueOf() - dayjs().valueOf();
      setTimeout(() => {
        void dispatch(
          commonThunks.hermesThunks.getSessionsForProject({ projectId: selectedProject.id }),
        );
      }, nextSessionMeetingIn);
    }
  }, [dispatch, selectedProject?.id, nextSessionMeeting?.id, nextSessionMeeting?.scheduledAt]);

  return { selectedProject, sessions };
}
