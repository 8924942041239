/* eslint-disable sonarjs/no-duplicate-string */

import type { Environment } from '../types/utils';

import {
  getEnvironment,
  getBaseDomain,
  DEVELOPMENT,
  PRODUCTION,
  STAGING,
  TEST,
  ONDEMAND,
} from './environment';

export function getBaseDomainFallback(environment: Environment): string {
  const byEnv = {
    [DEVELOPMENT]: 'localhost:8000',
    [TEST]: 'localhost:8000',
    [STAGING]: 'polygence.dev',
    [ONDEMAND]: 'polygence.co',
    [PRODUCTION]: 'polygence.org',
  };
  return byEnv[environment] || byEnv[DEVELOPMENT];
}

export function getBaseApiUrl(): string {
  const environment = getEnvironment();
  if ([DEVELOPMENT, TEST].includes(environment)) {
    return 'http://localhost:8000';
  }
  const baseDomain = getBaseDomain() || getBaseDomainFallback(environment);
  return `https://api.${baseDomain}`;
}

export function getBaseGeoApiUrl(): string {
  const environment = getEnvironment();
  if ([DEVELOPMENT, TEST].includes(environment)) {
    return 'http://localhost:8000';
  }
  const baseDomain = getBaseDomain() || getBaseDomainFallback(environment);
  return `https://geo-api.${baseDomain}`;
}

export function getBaseAdminUrl(): string {
  const environment = getEnvironment();
  if ([DEVELOPMENT, TEST].includes(environment)) {
    return 'http://localhost:8000/admin';
  }
  const baseDomain = getBaseDomain() || getBaseDomainFallback(environment);
  if (environment === ONDEMAND) {
    return `https://api.${baseDomain}/admin`;
  }
  return `https://admin.${baseDomain}`;
}

export function getBaseUrl(path = ''): string {
  return `${window.location.protocol}//${window.location.host}${path}`;
}

export function getBaseSocketUrl(path = ''): string {
  const environment = getEnvironment();
  if ([DEVELOPMENT, TEST].includes(environment)) {
    return `ws://localhost:8000${path}`;
  }
  const baseDomain = getBaseDomain() || getBaseDomainFallback(environment);
  return `wss://socket.${baseDomain}${path}`;
}

export function getLandingUrl(path = '', subdomain = 'www'): string {
  const environment = getEnvironment();
  if ([DEVELOPMENT, TEST].includes(environment)) {
    return `http://localhost:3000${path}`;
  }
  const baseDomain = getBaseDomain() || getBaseDomainFallback(environment);
  return `https://${subdomain}.${baseDomain}${path}`;
}

export const getCurrentUrl = (fullUrl: string): string | undefined => {
  return fullUrl.split('?')[0];
};

export function getBoshiClientUrl(): string {
  const environment = getEnvironment();
  if ([DEVELOPMENT, TEST].includes(environment)) {
    return 'http://localhost:3001';
  }
  const baseDomain = getBaseDomain() || getBaseDomainFallback(environment);
  return `https://app.${baseDomain}`;
}

export const getWebsocketClientUrl = (token = '') => {
  return getBaseSocketUrl(`/hermes/stream/?token=${token}`);
};

export const getAiWebsocketClientUrl = (token = '') => {
  return getBaseSocketUrl(`/hermes-ai/stream/?token=${token}`);
};
