import { Icon } from '@polygence/components';
import classNames from 'classnames';
import { FORMAT_TEXT_COMMAND } from 'lexical';
import type { FC } from 'react';

import {
  useToolbarContext,
  BlockType,
} from 'src/components/LexicalEditor/plugins/toolbar/ToolbarContext';

const Bold: FC = () => {
  const { editor, isBold, blockType } = useToolbarContext();

  return (
    <button
      type="button"
      title="Bold"
      onClick={() => {
        editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
      }}
      className={classNames('toolbar-item', {
        active: isBold,
      })}
      aria-label="Format Bold"
      disabled={blockType === BlockType.code}
    >
      <Icon id="bold" />
    </button>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default Bold;
