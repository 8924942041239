import { GeneralInterestWithoutPrefix } from '@polygence/common';
import { SelectOption } from '@polygence/common/types/common';
import { Button, Heading, InputField, Select, Text } from '@polygence/components';
import classNames from 'classnames';
import { useState } from 'react';

import styles from 'src/components/projectIdeaGenerator/ProjectIdeaForm.module.scss';

const GENERAL_INTEREST_OPTIONS: SelectOption<GeneralInterestWithoutPrefix>[] = [
  { label: 'AI, Machine Learning', value: 'ai' },
  {
    label: 'Fine Arts, Fashion, Architecture, Music, Visual Arts, Writing',
    value: 'fine_arts',
  },
  { label: 'Biomedical, Biological, Chemistry', value: 'biology' },
  { label: 'Law, Government, Politics, History, Literature', value: 'law' },
  { label: 'Computer Science, App Development', value: 'engineering' },
  { label: 'Business, Finance, Economy', value: 'economics' },
  { label: 'Neuroscience', value: 'neuroscience' },
  { label: 'Psychology', value: 'psychology' },
  { label: 'Physics', value: 'physics' },
  { label: 'Other', value: 'other' },
];

interface PolyGptProjectIdeaFormProps {
  loading: boolean;
  onSubmit: (generalInterest: string, interests: string) => void;
}

export const ProjectIdeaForm = ({ loading, onSubmit }: PolyGptProjectIdeaFormProps) => {
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);

    const generalInterest = formData.get('generalInterest')?.toString() ?? '';
    const interests = formData.get('interests')?.toString() ?? '';

    if (!generalInterest || !interests) {
      setError('Please fill out all the fields!');
      return;
    }

    if (interests.length < 5) {
      setError('Please provide more information about your interests!');
      return;
    }

    if (interests.length > 200) {
      setError('Maximum 200 characters are allowed!');
      return;
    }

    setError(null);
    onSubmit(generalInterest, interests);
  };

  return (
    <form
      className={classNames(styles['projectIdeaForm'], 'd-flex flex-column tw-z-[1]')}
      onSubmit={handleSubmit}
    >
      <Heading
        as="h3"
        size="h3"
        alignment="left"
        className={classNames(styles['projectIdeaFormHeading'], 'tw-mb-4')}
      >
        Meet PolyGPT, our AI-powered Project Idea Generator
      </Heading>
      <Text size="medium" className="tw-mb-10 tw-font-bold tw-text-gray-50">
        Tell PolyGPT your favorite subject and a few hobbies you're passionate about to generate
        personalized project ideas.
      </Text>
      <Text size="medium" className="tw-mb-2 tw-text-gray-50">
        What is your favorite subject?
      </Text>
      <Select
        name="generalInterest"
        placeholder="What field are you most interested in?"
        options={GENERAL_INTEREST_OPTIONS}
        size="small"
        isDisabled={loading}
        required
        className="tw-mb-2"
      />
      <Text size="medium" className="tw-mb-2 tw-text-gray-50">
        What are some hobbies, interests or fields that you're passionate about?
      </Text>
      <InputField
        type="textarea"
        name="interests"
        placeholder="I am interested in..."
        className={classNames(styles['projectIdeaFormInput'], 'd-flex mb-7')}
        disabled={loading}
        required
        rows={4}
      />
      {error && (
        <Text size="small" className="tw-mb-4 tw-text-yellow-300">
          {error}
        </Text>
      )}
      <div className="tw-flex tw-gap-5">
        <Button
          type="submit"
          disabled={loading}
          className={classNames(styles['projectIdeaFormSubmitButton'], 'tw-w-full lg:tw-w-fit')}
        >
          Let's see PolyGPT's ideas
        </Button>
      </div>
    </form>
  );
};
