import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import { Announcement } from 'src/components/EditableContent/Announcement';
import LegacyMarkdownGuidebook from 'src/components/EditableContent/LegacyMarkdownGuidebook';

// eslint-disable-next-line react/prefer-stateless-function -- autodisabled
class PressReleases extends React.Component {
  render() {
    return (
      // eslint-disable-next-line react/jsx-filename-extension -- autodisabled
      <div>
        <Switch>
          <Route
            // eslint-disable-next-line react/destructuring-assignment -- autodisabled
            path={`${this.props.match.url}/funding-announcement`}
            render={(props) => (
              <Announcement
                {...props}
                // eslint-disable-next-line react/destructuring-assignment -- autodisabled
                logged_in={this.props.logged_in}
                guidebook_tag="funding-announcement"
              />
            )}
          />
          <Route
            // eslint-disable-next-line react/destructuring-assignment -- autodisabled
            path={`${this.props.match.url}/funding-announcement-zh`}
            render={(props) => (
              <Announcement
                {...props}
                // eslint-disable-next-line react/destructuring-assignment -- autodisabled
                logged_in={this.props.logged_in}
                guidebook_tag="funding-announcement-zh"
              />
            )}
          />
          <Route
            // eslint-disable-next-line react/destructuring-assignment -- autodisabled
            path={`${this.props.match.url}/statement-of-solidarity`}
            render={(props) => (
              <LegacyMarkdownGuidebook
                {...props}
                // eslint-disable-next-line react/destructuring-assignment -- autodisabled
                logged_in={this.props.logged_in}
                guidebook_tag="statement-of-solidarity"
              />
            )}
          />
        </Switch>
      </div>
    );
  }
}

// eslint-disable-next-line import/no-default-export -- autodisabled
export default withRouter(PressReleases);
