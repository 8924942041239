import type { MentorProfileId } from '@polygence/common';

import { MentorAvailabilitySetup } from 'src/components/mentor/Availability/MentorAvailabilitySetup';
import { useCoreMentorAvailability } from 'src/hooks/useMentorAvailability';

interface CoreMentorAvailabilitySetupProps {
  mentorProfileId: MentorProfileId;
}

export const CoreMentorAvailabilitySetup = ({
  mentorProfileId,
}: CoreMentorAvailabilitySetupProps) => {
  const coreMentorAvailability = useCoreMentorAvailability({ mentorProfileId });

  if (!coreMentorAvailability) {
    return null;
  }

  return (
    <MentorAvailabilitySetup
      mentorProfileId={mentorProfileId}
      mentorAvailability={coreMentorAvailability}
    />
  );
};
