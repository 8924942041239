import { Button, Icon, Text } from '@polygence/components';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import styles from 'src/components/Tiles/Hermes/myWorkspaces.module.scss';
import tileIllustrations from 'src/components/Tiles/tile-illustrations';
import tileStyles from 'src/components/Tiles/tilesDashboard.module.scss';

interface MyStudentsProps {
  isActive: boolean;
}

export const MyStudents = ({ isActive }: MyStudentsProps) => {
  return (
    <Link
      to="/dashboard/hermes/"
      className={classNames(
        tileStyles['tile'],
        styles['myWorkspacesTile'],
        styles['myStudentsTile'],
        !isActive && styles['inactive'],
        'd-flex flex-column',
      )}
    >
      <Text
        size="large"
        fontWeight="bold"
        as="h4"
        className={`mb-4 ${isActive ? 'text-white' : 'text-black-50'}`}
      >
        My Students
      </Text>
      {isActive ? (
        <Button
          //@ts-expect-error using as=Link is fine See BSH-5057
          as={Link}
          endIcon={<Icon id="chevron-right" />}
          className="align-self-start py-2 px-6"
          to="/dashboard/hermes/"
          disabled={!isActive}
        >
          Go to Workspaces
        </Button>
      ) : (
        <Text
          size="small"
          fontWeight="semibold"
          className={`${isActive ? 'text-white' : 'text-black-50'} w-75`}
        >
          This tile will be unlocked when you are matched with a student.
        </Text>
      )}
      <img
        className={classNames(styles['illustration'], 'pb-3')}
        src={tileIllustrations['my-students-graduation']}
        alt="Students graduating"
      />
    </Link>
  );
};
