import type { ProjectStatus, ProjectType } from '../hermes';
import type { Nullable, ToUnion } from '../utils';

import type { UciGatiApplicationStatus } from './college-credits';

export interface ErrorInPayload {
  error?: {
    body: Record<string, unknown>;
  };
}

export const isErrorInPayload = <T>(payload: ErrorInPayload | T): payload is ErrorInPayload => {
  if (
    payload &&
    typeof payload === 'object' &&
    Object.prototype.hasOwnProperty.call(payload, 'error')
  ) {
    return true;
  }

  return false;
};

export interface Paginated<T> {
  count: number;
  next: Nullable<string>;
  previous: Nullable<string>;
  results: T[];
}

export interface FetchPaginatedData<T> {
  (
    pageProp: { pageSize: number; page: number },
    fetchedFn: (fetchedPages: T[], fetchedCount: number) => void,
  ): void;
}

export type EmptyResponse = '';

export interface UuidResponse {
  uuid: string;
}

export interface SearchParams {
  search?: string;
  page?: number;
  page_size?: number;
}

export interface OrderedSearchParams extends SearchParams {
  ordering?: string;
}

export type EmailSearchParams = SearchParams;

export interface UserSearchParams extends SearchParams {
  user_type?: string;
  q?: string;
}

export interface MentorRequestsSearchParams extends SearchParams {
  type?: string;
}

export interface StudentPipelineApplicationsSearchParams extends SearchParams {
  type?: string;
}

export type StringProjectStatus = ToUnion<typeof ProjectStatus>;

export type StringProjectType = ToUnion<typeof ProjectType>;

export interface ProjectSearchParams extends SearchParams {
  status?: StringProjectStatus;
  type?: StringProjectType;
}

export interface UciGatiApplicationSearchParams extends SearchParams {
  status?: UciGatiApplicationStatus;
}

export interface MentorQueryParams {
  fields?: string[];
  limit?: number;
  search?: string;
}
