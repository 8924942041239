import PropTypes from 'prop-types';
import React from 'react';

const InputWrapper = ({ id, children, value, required, name }) => {
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <div className="position-relative mb-5">
      <input
        id={id}
        tabIndex={-1}
        autoComplete="off"
        // eslint-disable-next-line react/forbid-dom-props
        style={{ opacity: 0, height: 0, bottom: 0, position: 'absolute' }}
        value={value ?? ''}
        required={required}
        onChange={() => {
          return null;
        }}
        name={name}
      />
      {children}
    </div>
  );
};

InputWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.any]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  required: PropTypes.bool,
  name: PropTypes.string,
  id: PropTypes.string,
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default InputWrapper;
