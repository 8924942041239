import type { TimeSlot as TimeSlotType } from '@polygence/common';
import { Text, Select, Spacer, Button, Icon } from '@polygence/components';
import classnames from 'classnames';

import styles from 'src/components/usersettings/MentorWorkingHours/TimeSlot.module.scss';
import { timeOptions } from 'src/utils/mentorWorkingHours';

const convertTimeSlotToOption = (time: string) => {
  return timeOptions.find((option) => {
    return option.value === time;
  });
};

interface TimeSlotProps {
  timeSlot: TimeSlotType;
  previousTimeSlot: TimeSlotType | undefined;
  nextTimeSlot: TimeSlotType | undefined;
  isDisabled: boolean;
  isLast: boolean;
  changeStartAt: (newTime: string, slotId: number) => void;
  changeEndAt: (newTime: string, slotId: number) => void;
  createNextTimeSlot: (slot: TimeSlotType) => void;
  deleteTimeSlot: (id: number) => void;
}

const parseTime = (time: string, value: 'hour' | 'minute') => {
  const index = value === 'hour' ? 0 : 1;
  return parseInt(time.split(':')[index] as string);
};

const disableInvalidOptions = (
  previousTimeSlot: TimeSlotType | undefined,
  nextTimeSlot: TimeSlotType | undefined,
  allowMidnight = false,
) => {
  const previouslySelectedHour = previousTimeSlot ? parseTime(previousTimeSlot.endAt, 'hour') : -1;
  const previouslySelectedMinute = previousTimeSlot
    ? parseTime(previousTimeSlot.endAt, 'minute')
    : -1;
  const nextSelectedHour = nextTimeSlot ? parseTime(nextTimeSlot.startAt, 'hour') : 25;
  const nextSelectedMinute = nextTimeSlot ? parseTime(nextTimeSlot.startAt, 'minute') : 70;
  return timeOptions.map((option) => {
    const optionHour = parseTime(option.value, 'hour');
    const optionMinute = parseTime(option.value, 'minute');
    const enableMidnight = allowMidnight && optionHour === 0 && optionMinute === 0;
    const lowerThanPreviouslySelectedSlot =
      (!enableMidnight && previouslySelectedHour > optionHour) ||
      (previouslySelectedHour === optionHour && previouslySelectedMinute >= optionMinute);
    const higherThanNextTimeSlot =
      nextSelectedHour < optionHour ||
      (nextSelectedHour === optionHour && nextSelectedMinute === optionHour);
    const isDisabled = lowerThanPreviouslySelectedSlot || higherThanNextTimeSlot;
    return { ...option, isDisabled };
  });
};

export const TimeSlot = ({
  timeSlot,
  previousTimeSlot,
  nextTimeSlot,
  isDisabled,
  isLast,
  changeStartAt,
  changeEndAt,
  createNextTimeSlot,
  deleteTimeSlot,
}: TimeSlotProps) => {
  const currentEndHour = parseTime(timeSlot.endAt, 'hour');
  const currentEndMinute = parseTime(timeSlot.endAt, 'minute');
  const isMidnight = currentEndHour === 0 && currentEndMinute === 0;
  return (
    <div className={classnames(styles['timeSlot'], 'd-flex align-items-center')}>
      <Select
        options={disableInvalidOptions(previousTimeSlot, nextTimeSlot)}
        value={convertTimeSlotToOption(timeSlot.startAt)}
        isDisabled={isDisabled}
        isMulti={false}
        onChange={(newTime) => {
          if (newTime && 'value' in newTime && timeSlot.id) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
            changeStartAt(newTime.value as string, timeSlot.id);
          }
        }}
      />
      <Spacer size={5} />
      <Text size="medium" fontWeight="normal">
        TO
      </Text>
      <Spacer size={5} />
      <Select
        options={disableInvalidOptions(previousTimeSlot, nextTimeSlot, isLast)}
        value={convertTimeSlotToOption(timeSlot.endAt)}
        isDisabled={isDisabled}
        onChange={(newTime) => {
          if (newTime && 'value' in newTime && timeSlot.id) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
            changeEndAt(newTime.value as string, timeSlot.id);
          }
        }}
      />
      {isLast && (
        <>
          <Spacer size={5} vertical />
          <Button
            className={styles['round-button']}
            variant="secondary"
            size="sm"
            onClick={() => createNextTimeSlot(timeSlot)}
            title="+"
            disabled={isMidnight}
          >
            <Icon id="plus" />
          </Button>
          <Spacer size={3} />
          <Button
            className={styles['round-button']}
            variant="secondary"
            size="sm"
            onClick={() => deleteTimeSlot(timeSlot.id)}
            title="-"
          >
            <Icon id="minus" />
          </Button>
        </>
      )}
    </div>
  );
};
