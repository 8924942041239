import type { RootState } from '../store/store';
import type { Room } from '../types/hermes';

const getRoomByWorkspaceId = (workspaceId: number, state: RootState): Room => {
  const workspace = state.hermes.workspaces.byId[workspaceId];
  if (workspace == null) {
    throw Error(`Workspace with id ${workspaceId} was not found`);
  }
  const roomId = workspace.roomId;
  const room = state.hermes.rooms.byId[roomId];
  if (room == null) {
    throw Error(`Room with id ${roomId} was not found`);
  }

  return room;
};

export const hookUtils = {
  getRoomByWorkspaceId,
};
