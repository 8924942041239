import { Button } from '@polygence/components';
import type { CustomButtonProps } from '@polygence/components';
import classNames from 'classnames';

import googleLogo from 'src/components/static/images/google-logo.png';

interface GoogleButtonProps {
  text?: string;
  disabled?: CustomButtonProps['disabled'];
  onClick?: CustomButtonProps['onClick'];
}

export const GoogleButton = ({ text, disabled, onClick }: GoogleButtonProps) => {
  const logoOnly = !text;

  return (
    <Button
      onClick={onClick}
      variant="secondary"
      size="lg"
      disabled={disabled}
      className={classNames({ 'px-5': logoOnly })}
      style={logoOnly ? { width: 56 } : {}}
      data-testid="google-login"
    >
      <img className="google-icon" src={googleLogo} alt="Google sign-in" width={24} />
      {text}
    </Button>
  );
};
