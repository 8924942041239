import type { ExtensionRequest } from '../../types/hermes';

import useSelectedProject from './useSelectedProject';

export function useExtensionRequestById(extensionRequestId: number): ExtensionRequest | undefined {
  const selectedProject = useSelectedProject();

  return selectedProject?.extensionRequests?.find((extensionRequest) => {
    return extensionRequest.id === extensionRequestId;
  });
}
