import { TRANSFORMERS } from '@lexical/markdown';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import type { FC } from 'react';

import 'src/components/LexicalEditor/plugins/RichTextInputPlugin.sass';

const Placeholder = ({ placeholder }: { placeholder: string }) => {
  return <div className="editor-placeholder">{placeholder}</div>;
};

export interface RichTextInputPluginProps {
  placeholder?: string | undefined;
}

const RichTextInputPlugin: FC<RichTextInputPluginProps> = ({ placeholder = '' }) => {
  return (
    <div className="editor-container">
      <div className="editor-inner">
        <RichTextPlugin
          contentEditable={<ContentEditable className="editor-input" />}
          placeholder={<Placeholder placeholder={placeholder} />}
        />
        <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
      </div>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default RichTextInputPlugin;
