import { useEffect } from 'react';
import { toast } from 'react-toastify';

import { selectResourcesForWorkspace } from '../store/reducers/sharedResourcesReducer';
import { useCommonSelector, useCommonDispatch } from '../store/store';
import { commonThunks } from '../store/thunks';
import type { SharedResource } from '../types/hermes';

import useSelectedWorkspaceId from './selectors/useSelectedWorkspaceId';

// eslint-disable-next-line import/no-default-export -- autodisabled
export default function useSharedResources() {
  const dispatch = useCommonDispatch();
  const workspaceId = useSelectedWorkspaceId() || 0;
  const resources = useCommonSelector((state) =>
    selectResourcesForWorkspace(state.sharedResources, workspaceId),
  );

  useEffect(() => {
    if (workspaceId) {
      dispatch(commonThunks.sharedResourcesThunks.getSharedResources({ workspaceId }))
        .then(() =>
          dispatch(commonThunks.sharedResourcesThunks.refreshGoogleFiles({ workspaceId })),
        )
        .catch(() => {
          toast.error('Error loading shared resources');
        });
    }
  }, [workspaceId, dispatch]);

  const sharedResources = [...resources];

  return sharedResources.sort(sortSharedResource);
}

const sortSharedResource = (a: SharedResource, b: SharedResource) => {
  if (a.favourite) {
    if (b.favourite) {
      return (a.favouriteAt ?? '') < (b.favouriteAt ?? '') ? 1 : -1;
    }

    return -1;
  }

  if (b.favourite) {
    return 1;
  }

  return a.createdAt < b.createdAt ? -1 : 1;
};
