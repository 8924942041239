import classnames from 'classnames';

import styles from 'src/components/mentor/MentorOverview/MentorOverview.module.sass';

/* eslint-disable-next-line react/function-component-definition, import/no-default-export -- autodisabled */
export default function CredentialsSection({
  children,
  title,
}: {
  children: React.ReactElement;
  title: string;
}) {
  return (
    <div className={classnames(styles['mb-10'])}>
      <h4 className={classnames('text-bold mb-5', styles['fs-1'])}>{title}</h4>
      <div className="row mx-0">{children}</div>
    </div>
  );
}
