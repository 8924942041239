import { commonHooks } from '@polygence/common';
import classNames from 'classnames';

import styles from 'src/components/Tiles/MentorAvailabilityTile/MentorAvailabilityTile.module.scss';
import tileIllustrations from 'src/components/Tiles/tile-illustrations';
import tileStyles from 'src/components/Tiles/tilesDashboard.module.scss';
import { CoreMentorAvailability } from 'src/components/mentor/Availability';

export const MentorAvailabilityTile = () => {
  const currentUser = commonHooks.useCurrentUser();

  return (
    <div
      className={classNames(tileStyles['tile'], 'd-flex flex-column justify-content-between p-6')}
    >
      {currentUser && currentUser.profileId && (
        <CoreMentorAvailability mentorProfileId={currentUser.profileId} />
      )}
      <img
        className={classNames(styles['illustration'], 'pb-3')}
        src={tileIllustrations['mentor-availability']}
        alt="Mentor Availability"
      />
    </div>
  );
};
