import type { ProductGroup, ProductId } from '@polygence/common/types/common';
import type { Product } from '@polygence/common/types/studentApplication';
import { Heading, Text } from '@polygence/components';

import { Icon as SimpleIcon } from 'src/components/Icon';
import { DashedBox } from 'src/components/common/DashedBox';
import { PaymentIntentAddableProduct } from 'src/payment/PaymentIntent/PaymentIntentAddableProduct';
import { GROUP_HEADERS } from 'src/payment/PaymentIntent/PaymentIntentProduct';

interface GroupedProducts {
  group: ProductGroup;
  products: Product[];
}

interface PaymentIntentBundleableProductsProps {
  v3Enabled: boolean;
  bundleableProducts: Product[];
  disabled: boolean;
  onAddBundleProduct: (productId: ProductId) => void;
}

export const PaymentIntentBundleableProducts = ({
  v3Enabled,
  bundleableProducts,
  disabled,
  onAddBundleProduct,
}: PaymentIntentBundleableProductsProps) => {
  if (!bundleableProducts.length) {
    return null;
  }

  const groups = bundleableProducts.reduce<Array<Product | GroupedProducts>>(
    (previousValue, current) => {
      if (!current.group) {
        return [...previousValue, current];
      } else {
        const index = previousValue.findIndex((item) => item.group === current.group);
        if (index > -1) {
          const foundGroup = previousValue[index];
          if (foundGroup && 'products' in foundGroup) {
            const products = [...foundGroup.products, current];
            return [
              ...previousValue.slice(0, index),
              {
                group: current.group,
                products,
              },
              ...previousValue.slice(index + 1, previousValue.length),
            ];
          }
          return previousValue;
        } else {
          return [...previousValue, { group: current.group, products: [current] }];
        }
      }
    },
    [],
  );

  return (
    <DashedBox className="tw-rounded-lg tw-p-5">
      {v3Enabled ? (
        <Heading as="h2" alignment="left" className="tw-mb-4 tw-text-lg tw-text-inherit">
          Complete your Polygence journey with these student favorites
        </Heading>
      ) : (
        <Heading as="h2" size="h5" alignment="left" className="tw-mb-4 tw-text-inherit">
          Complete your Polygence Journey{' '}
          <SimpleIcon name="rocket" style={{ verticalAlign: 'bottom' }} />
        </Heading>
      )}
      <div className="tw-flex tw-flex-col tw-gap-3 md:tw-gap-1">
        {groups.map((product) => {
          if ('products' in product) {
            return (
              <div key={product.group} className="tw-flex tw-flex-col tw-gap-2">
                <Text
                  as="span"
                  size="medium"
                  alignment="left"
                  fontWeight="bold"
                  className="tw-text-sm tw-text-inherit"
                >
                  {GROUP_HEADERS[product.group]}
                </Text>
                <div className="tw-flex tw-flex-col tw-gap-1 tw-border-0 tw-border-l-2 tw-border-solid tw-border-primary-800 tw-pl-2">
                  {product.products.map((p) => (
                    <PaymentIntentAddableProduct
                      key={p.id}
                      product={p}
                      disabled={disabled}
                      v3Enabled={v3Enabled}
                      onClick={onAddBundleProduct}
                    />
                  ))}
                </div>
              </div>
            );
          }

          return (
            <PaymentIntentAddableProduct
              key={product.id}
              product={product}
              disabled={disabled}
              onClick={onAddBundleProduct}
            />
          );
        })}
      </div>
    </DashedBox>
  );
};
