import { getRestClient } from '../commonSettings';
import type { OpportunityId, StudentProfileId, ShowcasingOutcomeId } from '../types/common';
import type { EmptyResponse } from '../types/data/common';
import type {
  OpportunityPublic,
  SimpleShowcasingOpportunity,
  OpportunityPublicWithKeywords,
  DetailedShowcasingOutcome,
  ShowcasingOutcome,
} from '../types/showcasing';

export const getOpportunitiesWith = ({
  page,
  pageSize,
  keywords,
  types,
  query,
  liked,
}: {
  page: number;
  pageSize: number;
  keywords: string[];
  types: string[];
  query?: string;
  liked?: boolean | undefined;
}) => {
  const internalFetch = getRestClient();
  return internalFetch.get<{ results: OpportunityPublic[]; count: number }>(
    '/showcasing/opportunity/',
    {
      params: { page_size: pageSize, page, keyword: keywords, type: types, liked, query },
    },
  );
};

export const getOpportunities = ({ search }: { search: string }) => {
  const internalFetch = getRestClient();
  return internalFetch.get<SimpleShowcasingOpportunity[]>('/showcasing/opportunities/', {
    params: { search },
  });
};

export const getOpportunity = (opportunityId: OpportunityId) => {
  const internalFetch = getRestClient();
  return internalFetch.get<OpportunityPublicWithKeywords[]>(`opportunity/${opportunityId}/`);
};

export const addOpportunityLike = (opportunityId: OpportunityId) => {
  const internalFetch = getRestClient();
  return internalFetch.post<EmptyResponse>(`/showcasing/opportunity/${opportunityId}/like/`);
};

export const removeOpportunityLike = (opportunityId: OpportunityId) => {
  const internalFetch = getRestClient();
  return internalFetch.delete<EmptyResponse>(`/showcasing/opportunity/${opportunityId}/like/`);
};

export const getShowcasingOutcomes = (studentProfileId: StudentProfileId) => {
  const internalFetch = getRestClient();
  return internalFetch.get<DetailedShowcasingOutcome[]>(
    `/showcasing/outcomes/${studentProfileId}/`,
  );
};

export const deleteShowcasingOutcome = (outcomeId: ShowcasingOutcomeId) => {
  const internalFetch = getRestClient();
  return internalFetch.delete<EmptyResponse>(`/showcasing/outcome/${outcomeId}/`);
};

export const createShowcasingOutcome = (payload: Omit<ShowcasingOutcome, 'id'>) => {
  const internalFetch = getRestClient();
  return internalFetch.post<ShowcasingOutcome>('/showcasing/outcome/', payload);
};

export const updateShowcasingOutcome = (
  outcomeId: ShowcasingOutcomeId,
  payload: Omit<ShowcasingOutcome, 'id'>,
) => {
  const internalFetch = getRestClient();
  return internalFetch.patch<ShowcasingOutcome>(`/showcasing/outcome/${outcomeId}/`, payload);
};
