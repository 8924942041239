import classnames from 'classnames';

interface RadioButtonProps {
  options: {
    label: string;
    value: string;
    disabled?: boolean;
    default?: boolean;
  }[];
  name: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  profile?: Record<string, unknown>;
  hidden?: boolean;
  label?: string;
  showLabel?: boolean;
  smallFont?: string;
  required?: boolean;
  onError?: string[];
}

export const RadioButton = ({
  options,
  name,
  onChange,
  onBlur,
  profile,
  hidden,
  label,
  showLabel = true,
  smallFont,
  required = false,
  onError = [],
}: RadioButtonProps) => {
  const isSelected = profile && profile[name] !== undefined;
  const defaultValue = options.find(({ default: _default }) => {
    return _default;
  })?.value;
  const error = onError[0];

  return (
    <div className={classnames('form-group tw-ms-2', { 'tw-hidden': hidden })}>
      {showLabel && <span className={required ? 'required' : ''}>{label}</span>}
      {smallFont && <div className="text-muted tw-mb-4">{smallFont}</div>}
      {error && <div className="text-danger">{error}</div>}
      {options.map(({ label: optionLabel, value, disabled }) => {
        const checked = isSelected ? profile[name] === value : defaultValue === value;

        return (
          <div className="form-check" key={`${name}-${value}`}>
            <input
              className="form-check-input"
              type="radio"
              name={name}
              id={`${name}-${value}`}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              disabled={disabled}
              checked={checked}
              hidden={hidden}
              required={required}
            />
            <label className="form-check-label" htmlFor={`${name}-${value}`}>
              {optionLabel}
            </label>
          </div>
        );
      })}
    </div>
  );
};
