import { commonHooks } from '@polygence/common';
import { Alert, Heading } from '@polygence/components';
import ReactPlayer from 'react-player';
import { useParams } from 'react-router';

import { FullPageBackground } from 'src/components/FullPageBackground/FullPageBackground';
import { CardList } from 'src/components/Guidebook/GuidebookStackedCardSection';
import styles from 'src/components/Guidebook/ProjectSupportVideo/ProjectSupportVideo.module.scss';
import { PageLoad } from 'src/components/PageLoad';
import BackButton from 'src/components/auth/BackButton';
import { useProjectSupportVideosQuery } from 'src/reducers/guidebookReducers';
import { getGuidebookUrl } from 'src/utils/navigation';

export const ProjectSupportVideoPage = () => {
  const { slug } = useParams<{ slug: string }>();
  const { data, isLoading } = useProjectSupportVideosQuery(slug);
  const { userType, otherInfo, hasActiveProject } = commonHooks.useCurrentUser() ?? {};
  const guidebookUrl = getGuidebookUrl({
    userType,
    hasActiveProject,
    isEnrolled: Boolean(otherInfo?.['enrolledAt']),
  });

  if (isLoading) {
    return (
      <FullPageBackground>
        <div className="container">
          <PageLoad />
        </div>
      </FullPageBackground>
    );
  }

  if (!data) {
    return (
      <FullPageBackground>
        <div className="container py-8">
          <Alert variant="warning" className="text-center">
            Video not found.
          </Alert>
        </div>
      </FullPageBackground>
    );
  }

  return (
    <FullPageBackground>
      <div className="container py-8 px-4 px-lg-13 d-flex flex-column align-items-center gap-4">
        {guidebookUrl && (
          <BackButton href={guidebookUrl} text="Back to Guidebook" className="ps-0 me-auto" />
        )}
        <div className="d-flex gap-4 me-auto">
          <Heading size="h3" as="h1" alignment="left">
            Guidebook
          </Heading>
          <Heading size="h3" as="h2" alignment="left" fontWeight="light">
            {data.title}
          </Heading>
        </div>
        <div className={styles['videoContainer']}>
          <ReactPlayer
            url={data.videoUrl}
            controls
            width="100%"
            height="100%"
            config={{ youtube: { playerVars: { rel: 0 } } }}
          />
        </div>
        <div className="w-50 vstack gap-5 mt-6 mx-auto">
          {data.relatedGuidebookPages && <CardList cards={data.relatedGuidebookPages} />}
        </div>
      </div>
    </FullPageBackground>
  );
};
