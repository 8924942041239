export const FinalSurveyIllustration = {
  viewBox: '0 0 156 130',
  children: (
    <>
      <path
        d="M94.9715 97.1301H7.91429C5.81601 97.1278 3.80435 96.2924 2.32065 94.8072C0.836939 93.3221 0.00235795 91.3085 0 89.2082V39.2653C0.00238522 37.165 0.836976 35.1514 2.32068 33.6663C3.80438 32.1812 5.81602 31.3458 7.91429 31.3434H94.9715C97.0698 31.3458 99.0814 32.1812 100.565 33.6663C102.049 35.1514 102.883 37.165 102.886 39.2653V89.2082C102.883 91.3085 102.049 93.3221 100.565 94.8072C99.0814 96.2924 97.0698 97.1278 94.9715 97.1301Z"
        fill="white"
      />
      <path
        d="M94.9715 97.1301H7.91429C5.81601 97.1278 3.80435 96.2924 2.32065 94.8072C0.836939 93.3221 0.00235795 91.3085 0 89.2082V39.2653C0.00238522 37.165 0.836976 35.1514 2.32068 33.6663C3.80438 32.1812 5.81602 31.3458 7.91429 31.3434H94.9715C97.0698 31.3458 99.0814 32.1812 100.565 33.6663C102.049 35.1514 102.883 37.165 102.886 39.2653V89.2082C102.883 91.3085 102.049 93.3221 100.565 94.8072C99.0814 96.2924 97.0698 97.1278 94.9715 97.1301ZM7.91429 32.0323C5.99847 32.0344 4.16174 32.7971 2.80705 34.1532C1.45236 35.5092 0.690348 37.3477 0.688199 39.2653V89.2082C0.690376 91.1258 1.45239 92.9643 2.80708 94.3203C4.16176 95.6763 5.99848 96.4391 7.91429 96.4413H94.9715C96.8873 96.4391 98.724 95.6763 100.079 94.3203C101.433 92.9643 102.195 91.1258 102.198 89.2082V39.2653C102.195 37.3477 101.433 35.5092 100.079 34.1532C98.724 32.7971 96.8873 32.0344 94.9715 32.0323H7.91429Z"
        fill="#E6E6E6"
      />
      <path
        d="M102.198 67.6261H0.3441C0.252839 67.6261 0.16531 67.5898 0.100779 67.5252C0.0362477 67.4606 0 67.373 0 67.2816C0 67.1903 0.0362477 67.1027 0.100779 67.0381C0.16531 66.9735 0.252839 66.9372 0.3441 66.9372H102.198C102.289 66.9372 102.377 66.9735 102.441 67.0381C102.506 67.1027 102.542 67.1903 102.542 67.2816C102.542 67.373 102.506 67.4606 102.441 67.5252C102.377 67.5898 102.289 67.6261 102.198 67.6261Z"
        fill="#E6E6E6"
      />
      <path
        d="M94.6281 16.1884C96.3385 16.1884 97.725 14.8005 97.725 13.0885C97.725 11.3765 96.3385 9.98859 94.6281 9.98859C92.9178 9.98859 91.5312 11.3765 91.5312 13.0885C91.5312 14.8005 92.9178 16.1884 94.6281 16.1884Z"
        fill="#F2F2F2"
      />
      <path
        d="M45.1731 118.589C46.8834 118.589 48.27 117.201 48.27 115.489C48.27 113.777 46.8834 112.389 45.1731 112.389C43.4627 112.389 42.0762 113.777 42.0762 115.489C42.0762 117.201 43.4627 118.589 45.1731 118.589Z"
        fill="#F2F2F2"
      />
      <path
        d="M26.4692 82.7675H15.7181C15.6269 82.7675 15.5393 82.7312 15.4748 82.6667C15.4103 82.6021 15.374 82.5145 15.374 82.4231C15.374 82.3318 15.4103 82.2441 15.4748 82.1796C15.5393 82.115 15.6269 82.0787 15.7181 82.0787H26.4692C26.5605 82.0787 26.648 82.115 26.7125 82.1796C26.7771 82.2441 26.8133 82.3318 26.8133 82.4231C26.8133 82.5145 26.7771 82.6021 26.7125 82.6667C26.648 82.7312 26.5605 82.7675 26.4692 82.7675Z"
        fill="#CCCCCC"
      />
      <path
        d="M26.4692 88.278H15.7181C15.6269 88.278 15.5393 88.2417 15.4748 88.1771C15.4103 88.1125 15.374 88.0249 15.374 87.9335C15.374 87.8422 15.4103 87.7546 15.4748 87.69C15.5393 87.6254 15.6269 87.5891 15.7181 87.5891H26.4692C26.5605 87.5891 26.648 87.6254 26.7125 87.69C26.7771 87.7546 26.8133 87.8422 26.8133 87.9335C26.8133 88.0249 26.7771 88.1125 26.7125 88.1771C26.648 88.2417 26.5605 88.278 26.4692 88.278Z"
        fill="#CCCCCC"
      />
      <path
        d="M85.31 82.7675H74.5589C74.4677 82.7675 74.3802 82.7312 74.3156 82.6667C74.2511 82.6021 74.2148 82.5145 74.2148 82.4231C74.2148 82.3318 74.2511 82.2441 74.3156 82.1796C74.3802 82.115 74.4677 82.0787 74.5589 82.0787H85.31C85.4013 82.0787 85.4888 82.115 85.5534 82.1796C85.6179 82.2441 85.6541 82.3318 85.6541 82.4231C85.6541 82.5145 85.6179 82.6021 85.5534 82.6667C85.4888 82.7312 85.4013 82.7675 85.31 82.7675Z"
        fill="#CCCCCC"
      />
      <path
        d="M85.31 88.2777H74.5589C74.4677 88.2777 74.3802 88.2414 74.3156 88.1768C74.2511 88.1123 74.2148 88.0246 74.2148 87.9333C74.2148 87.842 74.2511 87.7543 74.3156 87.6897C74.3802 87.6252 74.4677 87.5889 74.5589 87.5889H85.31C85.4013 87.5889 85.4888 87.6252 85.5534 87.6897C85.6179 87.7543 85.6541 87.842 85.6541 87.9333C85.6541 88.0246 85.6179 88.1123 85.5534 88.1768C85.4888 88.2414 85.4013 88.2777 85.31 88.2777Z"
        fill="#CCCCCC"
      />
      <path
        d="M65.6966 82.7675H54.9455C54.8543 82.7675 54.7668 82.7312 54.7022 82.6667C54.6377 82.6021 54.6014 82.5145 54.6014 82.4231C54.6014 82.3318 54.6377 82.2441 54.7022 82.1796C54.7668 82.115 54.8543 82.0787 54.9455 82.0787H65.6966C65.7879 82.0787 65.8754 82.115 65.94 82.1796C66.0045 82.2441 66.0407 82.3318 66.0407 82.4231C66.0407 82.5145 66.0045 82.6021 65.94 82.6667C65.8754 82.7312 65.7879 82.7675 65.6966 82.7675Z"
        fill="#CCCCCC"
      />
      <path
        d="M65.6966 88.278H54.9455C54.8543 88.278 54.7668 88.2417 54.7022 88.1771C54.6377 88.1125 54.6014 88.0249 54.6014 87.9335C54.6014 87.8422 54.6377 87.7546 54.7022 87.69C54.7668 87.6254 54.8543 87.5891 54.9455 87.5891H65.6966C65.7879 87.5891 65.8754 87.6254 65.94 87.69C66.0045 87.7546 66.0407 87.8422 66.0407 87.9335C66.0407 88.0249 66.0045 88.1125 65.94 88.1771C65.8754 88.2417 65.7879 88.278 65.6966 88.278Z"
        fill="#CCCCCC"
      />
      <path
        d="M46.0824 82.7675H35.3313C35.24 82.7675 35.1525 82.7312 35.088 82.6667C35.0234 82.6021 34.9872 82.5145 34.9872 82.4231C34.9872 82.3318 35.0234 82.2441 35.088 82.1796C35.1525 82.115 35.24 82.0787 35.3313 82.0787H46.0824C46.1736 82.0787 46.2612 82.115 46.3257 82.1796C46.3902 82.2441 46.4265 82.3318 46.4265 82.4231C46.4265 82.5145 46.3902 82.6021 46.3257 82.6667C46.2612 82.7312 46.1736 82.7675 46.0824 82.7675Z"
        fill="#CCCCCC"
      />
      <path
        d="M46.0824 88.278H35.3313C35.24 88.278 35.1525 88.2417 35.088 88.1771C35.0234 88.1125 34.9872 88.0249 34.9872 87.9335C34.9872 87.8422 35.0234 87.7546 35.088 87.69C35.1525 87.6254 35.24 87.5891 35.3313 87.5891H46.0824C46.1736 87.5891 46.2612 87.6254 46.3257 87.69C46.3902 87.7546 46.4265 87.8422 46.4265 87.9335C46.4265 88.0249 46.3902 88.1125 46.3257 88.1771C46.2612 88.2417 46.1736 88.278 46.0824 88.278Z"
        fill="#CCCCCC"
      />
      <path
        d="M53.5389 18.4746C58.6356 18.4746 62.7673 14.339 62.7673 9.23732C62.7673 4.13569 58.6356 0 53.5389 0C48.4422 0 44.3105 4.13569 44.3105 9.23732C44.3105 14.339 48.4422 18.4746 53.5389 18.4746Z"
        fill="#02E0C0"
      />
      <path
        d="M53.2143 12.9429C53.0904 12.9429 52.9683 12.914 52.8575 12.8586C52.7467 12.8031 52.6504 12.7226 52.5761 12.6234L50.6194 10.0119C50.5563 9.92804 50.5104 9.83252 50.4842 9.73085C50.458 9.62918 50.4522 9.52334 50.4669 9.41939C50.4816 9.31543 50.5167 9.21541 50.5701 9.12505C50.6235 9.03468 50.6942 8.95574 50.7781 8.89275C50.862 8.82975 50.9575 8.78394 51.0592 8.75793C51.1608 8.73193 51.2665 8.72624 51.3704 8.74118C51.4742 8.75613 51.574 8.79143 51.6642 8.84505C51.7544 8.89867 51.8331 8.96957 51.8959 9.05368L53.176 10.762L56.4639 5.82555C56.5815 5.64976 56.7639 5.52784 56.9712 5.48655C57.1785 5.44527 57.3936 5.48798 57.5695 5.60532C57.7453 5.72266 57.8674 5.90504 57.9091 6.11243C57.9507 6.31982 57.9085 6.53528 57.7916 6.71151L53.8782 12.5873C53.8074 12.6935 53.7122 12.7813 53.6005 12.8431C53.4889 12.9049 53.3641 12.9391 53.2365 12.9426L53.2143 12.9429Z"
        fill="white"
      />
      <path
        d="M71.2101 35.6229H31.6753C30.7122 35.6229 29.7885 35.2399 29.1075 34.5582C28.4265 33.8765 28.0439 32.952 28.0439 31.988C28.0439 31.0239 28.4265 30.0994 29.1075 29.4177C29.7885 28.7361 30.7122 28.3531 31.6753 28.3531H71.2101C71.6873 28.3526 72.1599 28.4463 72.6009 28.6288C73.0419 28.8112 73.4426 29.0789 73.7802 29.4165C74.1178 29.754 74.3856 30.1549 74.5683 30.5961C74.751 31.0374 74.845 31.5103 74.845 31.988C74.845 32.4656 74.751 32.9386 74.5683 33.3798C74.3856 33.821 74.1178 34.2219 73.7802 34.5595C73.4426 34.897 73.0419 35.1647 72.6009 35.3472C72.1599 35.5296 71.6873 35.6233 71.2101 35.6229Z"
        fill="#02E0C0"
      />
      <path
        d="M126.964 25.8799C126.658 27.0131 127.009 28.7974 127.142 29.8551C127.283 30.986 125.325 31.9981 124.204 32.062C124.232 31.6877 124.127 31.3156 123.908 31.0111C123.905 31.3733 123.83 31.7313 123.688 32.0643H114.338C113.16 29.846 112.158 26.8143 113.24 23.6799C113.295 23.0504 113.534 22.4511 113.928 21.9573C114.322 21.4634 114.853 21.097 115.454 20.9041L120.553 19.2706C122.563 19.2706 125.031 19.5562 126.142 21.0549C126.634 21.7449 126.964 22.5374 127.106 23.3731C127.248 24.2087 127.2 25.0658 126.964 25.8799Z"
        fill="#2F2E41"
      />
      <path
        d="M135.916 123.907C136.083 123.913 136.247 123.868 136.387 123.777C136.527 123.687 136.636 123.555 136.699 123.4C136.762 123.246 136.776 123.076 136.739 122.913C136.702 122.75 136.616 122.603 136.492 122.49C136.471 122.409 136.456 122.351 136.434 122.269C136.59 121.862 136.863 121.511 137.22 121.261C137.576 121.01 137.999 120.872 138.434 120.864C138.869 120.856 139.297 120.978 139.662 121.214C140.028 121.451 140.314 121.792 140.485 122.192C141.471 124.442 142.741 126.768 142.144 129.261C146.159 120.528 144.663 109.726 138.535 102.349C136.852 101.442 135.48 99.8146 135.108 97.9136C135.94 98.1867 136.966 97.5023 136.335 96.6346C136.062 96.3033 135.786 95.9747 135.513 95.6433C138.628 92.2581 142.428 97.4956 139.007 102.057C140.154 103.469 141.15 104.996 141.98 106.615C141.775 104.89 141.983 103.14 142.586 101.511C143.198 99.9918 144.36 98.7064 145.382 97.3885C146.624 95.7828 149.22 96.4934 149.408 98.5143C148.251 98.9071 148.116 100.587 149.446 100.804C149.134 104.569 146.711 108.05 143.345 109.733C144.627 113.229 145.15 116.958 144.88 120.673C145.435 116.329 150.077 115.232 153.312 113.477C153.517 113.343 153.758 113.271 154.004 113.27C154.249 113.27 154.49 113.341 154.696 113.475C154.902 113.61 155.065 113.801 155.164 114.026C155.263 114.251 155.295 114.5 155.255 114.743C154.808 114.93 154.381 115.161 153.98 115.432C153.792 115.562 153.648 115.746 153.569 115.96C153.489 116.174 153.478 116.407 153.536 116.628C153.595 116.849 153.72 117.046 153.895 117.193C154.069 117.339 154.285 117.428 154.512 117.448C152.912 121.523 148.701 124.29 144.349 124.316C143.949 126.139 143.359 127.915 142.592 129.616L136.133 129.678C136.109 129.606 136.088 129.532 136.066 129.461C136.664 129.493 137.264 129.451 137.852 129.337C136.253 127.478 135.02 126.533 135.916 123.907L135.916 123.907Z"
        fill="#F2F2F2"
      />
      <path d="M122.586 125.208H120.067L118.868 115.481H122.586V125.208Z" fill="#FFB6B6" />
      <path
        d="M123.915 129.577H122.109L121.786 127.869L120.96 129.577H116.169C115.939 129.577 115.715 129.503 115.53 129.367C115.345 129.231 115.209 129.039 115.14 128.819C115.072 128.6 115.076 128.364 115.151 128.147C115.225 127.93 115.368 127.742 115.557 127.611L119.383 124.966V123.24L123.408 123.48L123.915 129.577Z"
        fill="#2F2E41"
      />
      <path
        d="M126.541 107.591L124.772 109.387L117.011 103.413L119.621 100.763L126.541 107.591Z"
        fill="#FFB6B6"
      />
      <path
        d="M130.582 109.711L129.314 110.998L127.873 110.03L128.508 111.817L125.144 115.232C124.982 115.396 124.773 115.504 124.546 115.54C124.32 115.576 124.087 115.539 123.883 115.434C123.679 115.328 123.514 115.16 123.412 114.954C123.31 114.748 123.276 114.515 123.316 114.289L124.12 109.704L122.892 108.492L125.889 105.792L130.582 109.711Z"
        fill="#2F2E41"
      />
      <path
        d="M106.388 54.9717L114.008 42.8362C114.33 42.3211 114.436 41.7002 114.304 41.1075C114.172 40.5148 113.812 39.9978 113.303 39.668C113.039 39.4975 112.744 39.3824 112.434 39.3299C112.125 39.2773 111.808 39.2884 111.503 39.3625C111.198 39.4365 110.911 39.572 110.66 39.7606C110.409 39.9492 110.199 40.187 110.043 40.4595L102.514 53.3081L97.6098 60.9224C97.2018 60.8511 96.7818 60.9072 96.4067 61.0832C96.0317 61.2591 95.7199 61.5464 95.5137 61.9059C95.3075 62.2654 95.2168 62.6797 95.254 63.0926C95.2912 63.5055 95.4545 63.8969 95.7217 64.2137C95.9889 64.5305 96.347 64.7572 96.7474 64.8631C97.1479 64.9691 97.5711 64.949 97.9598 64.8058C98.3484 64.6625 98.6836 64.403 98.9198 64.0624C99.1559 63.7218 99.2816 63.3168 99.2798 62.9022L106.388 54.9717Z"
        fill="#FFB6B6"
      />
      <path
        d="M113.57 46.0099L114.212 45.8886C114.126 45.7308 114.09 45.5502 114.109 45.3712C114.128 45.1923 114.201 45.0234 114.319 44.8875C114.437 44.7515 114.594 44.655 114.768 44.6108C114.942 44.5667 115.126 44.577 115.294 44.6405C114.52 43.8324 114.896 42.5019 115.394 41.4994C115.754 40.7748 116.159 40.0588 116.335 39.269C116.602 38.07 116.257 36.6189 114.66 36.3318C114.007 36.2295 113.339 36.3649 112.777 36.7134C111.773 37.3073 111.547 37.0487 110.914 38.0046L110.552 38.6762C110.089 39.4033 109.535 40.0689 108.906 40.6575C108.455 41.1065 108.092 41.6356 107.835 42.2176C107.801 42.2897 107.751 42.3529 107.689 42.4025C107.442 42.5937 107.106 42.6032 106.796 42.6439C106.406 42.6953 105.96 42.8607 105.97 43.3453C105.975 43.4611 106.005 43.5746 106.057 43.6784C106.108 43.7823 106.181 43.8744 106.269 43.9489C107.917 45.3924 109.846 46.4792 111.933 47.1409C112.397 47.2876 112.918 47.4091 113.354 47.1939C113.791 46.9786 113.978 46.275 113.57 46.0099Z"
        fill="#02E0C0"
      />
      <path
        d="M123.238 69.075L108.403 68.8466L107.946 65.4197L109.316 57.6521H120.956L123.238 69.075Z"
        fill="#FFB6B6"
      />
      <path
        d="M107.947 65.1912L107.491 71.3596L98.8785 86.8016C98.4649 87.4891 98.2834 88.2917 98.361 89.0906C98.4386 89.8894 98.7711 90.642 99.3093 91.2369L112.815 103.486C112.815 103.486 115.47 103.573 115.246 104.829C115.022 106.085 116.441 106.026 116.441 106.026L118.038 107.181L118.902 100.602C115.999 94.1128 111.693 92.1331 107.947 89.1394C107.947 89.1394 109.527 88.6694 109.193 88.1248C108.86 87.5802 109.907 87.544 109.907 87.544C109.907 87.544 110.601 87.4503 110.644 86.9441C110.686 86.4379 111.359 86.3614 111.359 86.3614L115.138 82.5541L125.065 82.0972C125.065 82.0972 129.266 71.5629 122.326 64.7343L107.947 65.1912Z"
        fill="#2F2E41"
      />
      <path
        d="M115.159 79.6929L118.445 118.879L123.285 118.585L125.064 82.0967L115.159 79.6929Z"
        fill="#2F2E41"
      />
      <path
        d="M115.395 32.9543L120.935 32.6686L123.508 36.7386C123.508 36.7386 128.35 37.5488 128.014 43.2596C127.678 48.9704 122.123 53.9816 122.123 53.9816L122.361 56.9555L121.948 58.1351L122.001 58.7974L122.564 59.4988L122.622 60.2242L122.123 61.372L122.361 64.8742C122.361 64.8742 119.106 66.0442 114.816 65.6542C111.697 65.6542 107.797 65.2642 107.797 65.2642L108.028 59.1884L108.788 58.168L108.364 56.837L109.002 55.5567L109.335 51.4902C109.335 51.4902 105.693 46.0133 109.874 41.8882L111.552 39.0328L113.429 36.7399L115.395 32.9543Z"
        fill="#02E0C0"
      />
      <path
        d="M128.981 57.7215L128.573 43.3939C128.554 42.7869 128.298 42.2118 127.859 41.7926C127.42 41.3734 126.834 41.1438 126.227 41.1533C125.913 41.1582 125.604 41.227 125.317 41.3556C125.031 41.4842 124.774 41.6697 124.561 41.9011C124.349 42.1324 124.186 42.4045 124.082 42.701C123.978 42.9974 123.936 43.312 123.957 43.6254L124.837 58.4953L124.99 67.5534C124.612 67.7212 124.294 68.0016 124.08 68.3566C123.866 68.7115 123.766 69.1238 123.795 69.5374C123.823 69.951 123.978 70.3459 124.238 70.6683C124.498 70.9908 124.851 71.2252 125.249 71.3398C125.648 71.4544 126.071 71.4435 126.463 71.3087C126.854 71.1739 127.195 70.9216 127.439 70.5862C127.682 70.2509 127.816 69.8486 127.824 69.4341C127.831 69.0196 127.71 68.613 127.478 68.2694L128.981 57.7215Z"
        fill="#FFB6B6"
      />
      <path
        d="M129.971 46.2762C130.075 46.1698 130.142 46.0318 130.161 45.8836C130.18 45.7354 130.15 45.5851 130.075 45.4558C129.986 45.2946 129.949 45.1094 129.97 44.9263C129.975 44.8909 129.981 44.8559 129.99 44.8214C130.05 44.615 130.059 44.3972 130.016 44.1866C129.974 43.976 129.881 43.7787 129.746 43.6117C129.294 43.0682 129.076 42.2454 128.981 41.512C128.878 40.7094 128.817 39.8891 128.524 39.1346C128.103 38.0462 127.095 37.0763 125.72 37.5405C125.138 37.747 124.645 38.148 124.324 38.6759C123.638 39.7706 123.631 41.0942 123.636 42.3605L123.389 41.8601C123.419 43.2431 123.169 44.6179 122.655 45.9019C122.961 45.8424 123.178 46.2546 123.079 46.551C122.98 46.8474 122.706 47.0422 122.471 47.2484C122.175 47.5083 121.896 47.8944 122.174 48.2915C122.243 48.3846 122.33 48.4625 122.431 48.5201C122.531 48.5777 122.643 48.614 122.758 48.6264C124.93 48.909 127.136 48.739 129.239 48.1271C129.706 47.991 130.206 47.8019 130.449 47.3802C130.692 46.9585 130.457 46.2695 129.971 46.2762Z"
        fill="#02E0C0"
      />
      <path
        d="M118.446 31.8358C121.345 31.8358 123.695 29.4832 123.695 26.5812C123.695 23.6792 121.345 21.3267 118.446 21.3267C115.546 21.3267 113.196 23.6792 113.196 26.5812C113.196 29.4832 115.546 31.8358 118.446 31.8358Z"
        fill="#FFB6B6"
      />
      <path
        d="M155.104 129.93L96.8218 130C96.75 130 96.6813 129.971 96.6307 129.92C96.5801 129.869 96.5516 129.8 96.5516 129.728C96.5516 129.656 96.5801 129.587 96.6307 129.536C96.6813 129.485 96.75 129.456 96.8218 129.456L155.104 129.386C155.176 129.386 155.245 129.415 155.295 129.466C155.346 129.517 155.374 129.586 155.374 129.658C155.374 129.73 155.346 129.799 155.295 129.85C155.245 129.901 155.176 129.929 155.104 129.93Z"
        fill="#CACACA"
      />
      <path
        d="M125.064 25.211V26.3533H120.522C120.568 26.1328 120.569 25.9051 120.523 25.6846C120.477 25.464 120.386 25.2554 120.255 25.0717C120.252 25.5187 120.14 25.9583 119.931 26.3533C117.305 27.1307 115.101 27.1158 113.294 26.3533C113.026 26.3533 112.769 26.2467 112.579 26.0568C112.389 25.867 112.283 25.6095 112.283 25.3411C112.283 21.8388 115.041 18.8878 118.539 18.8156C119.389 18.7976 120.235 18.9497 121.026 19.263C121.817 19.5763 122.537 20.0445 123.145 20.6402C123.753 21.2358 124.235 21.947 124.565 22.7319C124.895 23.5168 125.064 24.3596 125.064 25.211Z"
        fill="#2F2E41"
      />
      <path
        d="M54.7117 46.8428H40.9477C40.5826 46.8428 40.2326 46.6977 39.9744 46.4393C39.7163 46.1809 39.5713 45.8305 39.5713 45.4651C39.5713 45.0997 39.7163 44.7492 39.9744 44.4909C40.2326 44.2325 40.5826 44.0873 40.9477 44.0873H54.7117C55.0767 44.0873 55.4268 44.2325 55.6849 44.4909C55.9431 44.7492 56.0881 45.0997 56.0881 45.4651C56.0881 45.8305 55.9431 46.1809 55.6849 46.4393C55.4268 46.6977 55.0767 46.8428 54.7117 46.8428Z"
        fill="#CCCCCC"
      />
      <path
        d="M58.1532 76.2232H44.3892C44.0242 76.2232 43.6741 76.078 43.416 75.8197C43.1578 75.5613 43.0128 75.2108 43.0128 74.8454C43.0128 74.48 43.1578 74.1296 43.416 73.8712C43.6741 73.6129 44.0242 73.4677 44.3892 73.4677H58.1532C58.5182 73.4677 58.8683 73.6129 59.1265 73.8712C59.3846 74.1296 59.5296 74.48 59.5296 74.8454C59.5296 75.2108 59.3846 75.5613 59.1265 75.8197C58.8683 76.078 58.5182 76.2232 58.1532 76.2232Z"
        fill="#CCCCCC"
      />
      <path
        d="M84.3045 54.4206H40.6038C40.2388 54.4206 39.8887 54.2755 39.6306 54.0171C39.3724 53.7587 39.2274 53.4083 39.2274 53.0429C39.2274 52.6775 39.3724 52.3271 39.6306 52.0687C39.8887 51.8103 40.2388 51.6652 40.6038 51.6652H84.3045C84.6695 51.6652 85.0196 51.8103 85.2777 52.0687C85.5359 52.3271 85.6809 52.6775 85.6809 53.0429C85.6809 53.4083 85.5359 53.7587 85.2777 54.0171C85.0196 54.2755 84.6695 54.4206 84.3045 54.4206Z"
        fill="#CCCCCC"
      />
      <path
        d="M24.6024 58.4913C29.6991 58.4913 33.8308 54.3556 33.8308 49.254C33.8308 44.1524 29.6991 40.0167 24.6024 40.0167C19.5057 40.0167 15.374 44.1524 15.374 49.254C15.374 54.3556 19.5057 58.4913 24.6024 58.4913Z"
        fill="#E6E6E6"
      />
    </>
  ),
};
