import type { TagCategories } from '@polygence/common/types/data/marketplace';
import type { Tag } from '@polygence/common/types/marketplace';
import Modal from 'react-modal';

import { EditTag } from 'src/components/admin/components/aux/EditTag';

export interface TagEditModalProps {
  tags: Tag[];
  category: TagCategories;
  label?: string;
  changeUrl: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  onSubmit: (tags: Tag[]) => void;
}

export const TagEditModal = ({
  tags,
  category,
  label,
  changeUrl,
  open,
  setOpen,
  onSubmit,
}: TagEditModalProps) => {
  return (
    <Modal
      isOpen={open}
      shouldCloseOnOverlayClick
      className="modal-overlay policy-overlay session-style lead-action-overlay"
      overlayClassName="ReactModal__Overlay"
      onRequestClose={() => setOpen(false)}
    >
      <EditTag
        tags={tags}
        category={category}
        label={label}
        changeUrl={changeUrl}
        onSubmit={onSubmit}
      />
    </Modal>
  );
};
