import { featureDecisions } from '@polygence/common';
import * as backendApi from '@polygence/common/api/backend';
import { Alert, Button } from '@polygence/components';
import classNames from 'classnames';
import { useEffect, useRef } from 'react';
import { toast } from 'react-toastify';

import { getMyInfo } from 'src/components/getMyInfo';
import useRefreshPage from 'src/hooks/useRefreshPage';
import { urls } from 'src/urls';
import styles from 'src/user-switcher/ImpersonatorNotice.module.scss';
import impostor from 'src/user-switcher/impostor.png';
import { revertImpersonatorTokens } from 'src/utils';

const useBroadcastStopImpersonatorSession = (onMessageReceived: () => void) => {
  const broadcastChannelRef = useRef<BroadcastChannel>();

  useEffect(() => {
    broadcastChannelRef.current = new BroadcastChannel('impersonator');
    broadcastChannelRef.current.addEventListener('message', onMessageReceived);
    return () => broadcastChannelRef.current?.close();
  }, [onMessageReceived]);

  return () => broadcastChannelRef.current?.postMessage('stop-session');
};

export const ImpersonatorNotice = () => {
  const navigateToManageUsers = useRefreshPage(urls.manageUsersPage());
  const myInfo = getMyInfo() as { first_name: string; last_name: string };
  const name = `${myInfo.first_name} ${myInfo.last_name}`;
  const broadcastStopImpersonatorSession = useBroadcastStopImpersonatorSession(() =>
    navigateToManageUsers(),
  );

  const stopImpersonation = async () => {
    await backendApi.stopImpersonatorSession();
    revertImpersonatorTokens();
    featureDecisions.clearInstance();
    broadcastStopImpersonatorSession();
    navigateToManageUsers();
  };

  return (
    <Alert
      variant="danger"
      className="d-flex justify-content-between align-items-center position-sticky top-0"
    >
      <p>
        <img
          src={impostor}
          alt="Red from Among Us"
          className={classNames('me-4', styles['impostor'])}
        />
        You are an impostor and currently acting as {name}.
      </p>
      <Button
        variant="danger"
        onClick={(event) => {
          event.preventDefault();
          stopImpersonation().catch(() => toast.error('Something went wrong'));
        }}
      >
        Stop being an impostor
      </Button>
    </Alert>
  );
};
