import { Text } from '@polygence/components';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import styles from 'src/components/Tiles/Mentors/MentorTiles.module.scss';
import tileIllustrations from 'src/components/Tiles/tile-illustrations';
import tileStyles from 'src/components/Tiles/tilesDashboard.module.scss';

export const MentorTile = () => {
  return (
    <Link to="/dashboard/mentors">
      <div className={classNames(tileStyles['tile'], styles['mentorsTile'], 'd-flex flex-column')}>
        <Text size="large" fontWeight="bold" className="mb-2">
          Mentors
        </Text>
        <img
          className={classNames(styles['mentorsIllustration'])}
          src={tileIllustrations['selfie']}
          alt="Mentors taking selfie"
        />
      </div>
    </Link>
  );
};
