import * as marketplaceApi from '@polygence/common/api/marketplace';
import { ProjectType } from '@polygence/common/types/hermes';
import { useEffect, useState } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';

import { SpinningWheel } from 'src/components/SpinningWheel';
import { FeedbackForm } from 'src/components/specialURLs/MatchingFeedbackForm';

export const ReviewStudent = ({
  match: {
    params: { uuid },
  },
  location: { pathname },
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [responseRecorded, setResponseRecorded] = useState(false);
  const [isMatched, setIsMatched] = useState(false);
  const [studentName, setStudentName] = useState('The student');

  const response = pathname.includes('accept') ? 'accept' : 'reject';

  useEffect(() => {
    setLoading(true);
    Promise.all([
      marketplaceApi.getProposalActive(uuid).then(({ data }) => {
        setIsMatched(data.isMatched);
        setStudentName(data.studentName);
      }),
      marketplaceApi.updateReviewStudent(uuid, { response }),
    ])
      .then(() => {
        return setResponseRecorded(true);
      })
      .catch((error) => {
        if (error.response && error.response.data.error === 'Response already recorded') {
          setResponseRecorded(true);
        } else {
          setError(true);
        }
      })
      .finally(() => {
        return setLoading(false);
      });
  }, [uuid, response]);

  return (
    <div className="container my-5">
      {loading && <SpinningWheel />}
      {error && (
        <div className="alert alert-danger">
          Something went wrong. Please contact us at{' '}
          <a href="mailto:mentors@polygence.org">mentors@polygence.org</a>.
        </div>
      )}
      {responseRecorded && response === 'accept' && !isMatched && (
        <div className="alert alert-success">Your response has been recorded.</div>
      )}
      {responseRecorded && response === 'accept' && isMatched && (
        <div className="alert alert-warning">
          Thank you for your interest! {studentName} is no longer looking for a mentor, so we’ll be
          on the lookout for another stellar student for you!
        </div>
      )}
      {responseRecorded && (
        <FeedbackForm
          uuid={uuid}
          response={response}
          projectType={ProjectType.FLAGSHIP}
          updateResponse={marketplaceApi.partialUpdateReviewStudent}
        />
      )}
    </div>
  );
};

ReviewStudent.propTypes = {
  match: ReactRouterPropTypes.match,
  location: ReactRouterPropTypes.location,
};
