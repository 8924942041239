import type { CamelCasedPropertiesDeep } from 'type-fest';

import { getRestClient } from '../commonSettings';
import type { UUID, UserId } from '../types/common';

export const proceedToPaymentIntentInvoice = (
  uuid: UUID,
  data?: { paymentIntentAmount: number },
) => {
  const internalFetch = getRestClient();

  return internalFetch.post<{
    invoiceUrl: string;
  }>(`/payment/intent/${uuid}/proceed/`, data);
};

export const generateCheckoutSession = (uuid: string) => {
  const internalFetch = getRestClient();

  return internalFetch.post<CamelCasedPropertiesDeep<{ checkout_session_url: string }>>(
    `/payment/intent/${uuid}/generate-checkout-session`,
  );
};

export const getCustomerPortalUrl = (userId?: UserId) => {
  const internalFetch = getRestClient();

  return internalFetch.get<{ customerPortalUrl: string }>('/payment/stripe-customer-portal/', {
    params: userId ? { student_id: userId } : undefined,
  });
};

export const getOneTimeCustomerPortalUrl = (uuid: UUID) => {
  const internalFetch = getRestClient();

  return internalFetch.get<{ customerPortalUrl: string }>(
    `/payment/stripe-customer-portal/${uuid}/`,
  );
};
