import { Text } from '@polygence/components';
import classNames from 'classnames';
import { useState } from 'react';

import styles from 'src/components/Tiles/TeamMemberTile.module.sass';
import tileStyles from 'src/components/Tiles/tilesDashboard.module.scss';

interface TeamMemberTileProps {
  imageUrl: string | undefined;
  firstName: string | undefined;
  role: string | undefined;
  children: JSX.Element;
}

const TeamMemberTile = ({ children, ...props }: TeamMemberTileProps) => {
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);

  return (
    <div
      className={classNames(
        tileStyles['tile'],
        'p-4 d-flex flex-column justify-content-space-between position-relative',
      )}
    >
      <div className="align-self-end position-absolute" id={styles['advisorImageContainer']}>
        <img
          alt="Advisor illustration"
          src={props.imageUrl}
          onLoad={() => {
            setImageLoaded(true);
          }}
          className={classNames({
            'd-none': !imageLoaded,
          })}
        />
      </div>
      <div className="mt-auto">
        <Text className="mb-2" fontWeight="bold" size="large">
          {props.firstName}
        </Text>
        <Text className="mb-2" size="small">
          {props.role}
        </Text>
        <Text
          className="mb-2"
          fontWeight="bold"
          style={{
            color: 'var(--primary)',
          }}
          size="small"
        >
          {children}
        </Text>
      </div>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default TeamMemberTile;
