import { InputField } from '@polygence/components';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Calendar from 'react-calendar';

import 'src/components/common/dateRangeSelector.sass';
import DateWrapper, { DateFormat } from 'src/components/aux/dateWrapper';
import { dayjs } from 'src/utils/dayjsCustom';

const DateRangeSelector = ({
  timeline,
  startAt,
  endAt,
  handleChange,
  isInvalid,
  errorMessage,
  disabled,
}) => {
  const [isEmpty, setIsEmpty] = useState(() => {
    return (timeline[startAt] && timeline[endAt]) ?? true;
  });

  if (disabled) {
    const timelineString = `${new DateWrapper(timeline[startAt]).format(
      DateFormat.FORMAT_1,
    )} - ${new DateWrapper(timeline[endAt]).format(DateFormat.FORMAT_1)}`;

    // eslint-disable-next-line react/jsx-filename-extension -- autodisabled
    return <InputField value={timelineString} name="readonly_timeline" disabled />;
  }

  return (
    <div>
      <Calendar
        returnValue="range"
        selectRange
        minDetail="year"
        maxDetail="year"
        value={[
          timeline[startAt] ? dayjs(timeline[startAt]).toDate() : null,
          timeline[endAt] ? dayjs(timeline[endAt]).toDate() : null,
        ]}
        prev2Label={null}
        next2Label={null}
        minDate={dayjs().set('date', 1).startOf('day').toDate()}
        maxDate={dayjs().add(2, 'years').toDate()}
        formatMonth={(_, date) => {
          return new DateWrapper(date).format(DateFormat.FORMAT_5);
        }}
        onChange={(dates) => {
          setIsEmpty(false);
          let [startDate, endDate] = dates;
          const saveValue = (value, name) => {
            let formattedValue;
            if (value) {
              // eslint-disable-next-line fp/no-mutation -- autodisabled
              formattedValue = new DateWrapper(dayjs(value).set('date', 15)).format(
                DateFormat.FORMAT_3,
              );
            }
            handleChange({
              target: {
                name,
                value: formattedValue,
              },
            });
          };
          saveValue(startDate, startAt);
          saveValue(endDate, endAt);
        }}
      />
      {isInvalid && !isEmpty && <div className="form-validation-error">{errorMessage}</div>}
    </div>
  );
};

DateRangeSelector.propTypes = {
  startAt: PropTypes.string,
  endAt: PropTypes.string,
  timeline: PropTypes.objectOf(PropTypes.string),
  handleChange: PropTypes.func.isRequired,
  isInvalid: PropTypes.bool,
  errorMessage: PropTypes.string,
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default DateRangeSelector;
