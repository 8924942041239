import { getLandingUrl } from '@polygence/common';
import { Button, Heading, Text } from '@polygence/components';
import classNames from 'classnames';

import DateWrapper, { DateFormat } from 'src/components/aux/dateWrapper';
import { PaymentPageTerms } from 'src/payment/PaymentPageTerms';
import styles from 'src/students/Pods/PodFullPage.module.scss';

interface PodFullPageProps {
  startDate: string;
  title: string;
}

export const PodFullPage = ({ startDate, title }: PodFullPageProps) => {
  return (
    <div className={classNames(styles['container'], 'd-flex row align-items-end')}>
      <div className="d-flex row justify-content-center align-items-center gap-6">
        <Heading as="h3" size="h3">
          Spots fill up fast!
        </Heading>
        <Text size="medium" alignment="center" className={styles['grayscale-6']}>
          All available spots for the {new DateWrapper(startDate).format(DateFormat.FORMAT_11)}{' '}
          {title} Pod filled up.
        </Text>
        <div className="d-flex justify-content-center w-25">
          <Button variant="primary" size="sm" href={getLandingUrl('/polygence-pods')}>
            <Text size="small" fontWeight="bold">
              Browse available pods
            </Text>
          </Button>
        </div>
      </div>
      <PaymentPageTerms />
    </div>
  );
};
