import PropTypes from 'prop-types';
import React from 'react';

import { FormInputWithValidation as FormInput } from 'src/components/FormInputWithValidation';

const options = [
  {
    value: 'NOT_ENOUGH_TIME',
    label: 'I have competing commitments and won’t have enough time to dedicate to my project',
  },
  {
    value: 'ALREADY_DOING_SIMILAR_PROGRAM',
    label: 'I am already doing a similar program to Polygence',
  },
  {
    value: 'COST_OF_THE_PROGRAM',
    label: 'I did not realize the cost of the program',
  },
  {
    value: 'MENTOR_IS_NOT_GOOD_FIT',
    label: 'I do not think my mentor is a good fit',
  },
  {
    value: 'NOT_SURE_ABOUT_THE_PROJECT',
    label: 'I am not sure I want to do the project I proposed',
  },
  { value: 'OTHER', label: 'Other' },
];

class OptOutFeedback extends React.Component {
  constructor() {
    super();
    this.state = {
      checkedItems: {},
      other: '',
      similarProgram: '',
    };
  }

  handleCheckboxChange = (e) => {
    const { name } = e.target;
    const isChecked = e.target.checked;
    this.setState((prevState) => {
      return {
        ...prevState,
        checkedItems: {
          ...prevState.checkedItems,
          [name]: isChecked,
        },
      };
    });
  };

  handleTextareaChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  onSubmit = (e) => {
    const { onSubmit } = this.props;
    e.preventDefault();
    const { checkedItems, other, similarProgram } = this.state;
    const items = Object.entries(checkedItems)
      .filter((entry) => {
        return entry[1];
      })
      .map((entry) => {
        return entry[0];
      });
    onSubmit({ items, other, similarProgram });
  };

  render() {
    const { checkedItems, similarProgram, other } = this.state;
    return (
      // eslint-disable-next-line react/jsx-filename-extension -- autodisabled
      <form className="text-start" onSubmit={this.onSubmit}>
        {options.map(({ value, label }) => {
          return (
            // eslint-disable-next-line react/jsx-key -- autodisabled
            <>
              <div>
                <label htmlFor={value} onChange={this.handleCheckboxChange}>
                  <input className="me-2" type="checkbox" id={value} name={value} />
                  {label}
                </label>
              </div>
              {value === 'ALREADY_DOING_SIMILAR_PROGRAM' &&
              checkedItems.ALREADY_DOING_SIMILAR_PROGRAM ? (
                <FormInput
                  inputType="text"
                  type="text"
                  value={similarProgram}
                  name="similarProgram"
                  onChange={this.handleTextareaChange}
                  onError={[]}
                  label="Which program?"
                />
              ) : null}
            </>
          );
        })}
        {checkedItems.OTHER ? (
          <FormInput
            inputType="textarea"
            type="textarea"
            value={other}
            name="other"
            onChange={this.handleTextareaChange}
            onError={[]}
            label="Please specify"
          />
        ) : null}
        <div>
          <button type="submit" className="btn btn-success">
            Submit
          </button>
        </div>
      </form>
    );
  }
}
// eslint-disable-next-line import/no-default-export -- autodisabled
export default OptOutFeedback;

OptOutFeedback.propTypes = {
  onSubmit: PropTypes.func,
};
