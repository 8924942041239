import { axiosBaseQuery } from '@polygence/common/api/fetch';
import type { UciGatiApplication } from '@polygence/common/types/college-credits';
import type { UCICohort } from '@polygence/common/types/college-credits';
import type { UUID } from '@polygence/common/types/common';
import { createApi } from '@reduxjs/toolkit/query/react';

export const collegeCreditsApiBase = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: 'collegeCredits',
  endpoints: () => {
    return {};
  },
});

const collegeCreditsApiWithTags = collegeCreditsApiBase.enhanceEndpoints({
  addTagTypes: ['UciGatiApplication'],
});

export const collegeCreditsApi = collegeCreditsApiWithTags.injectEndpoints({
  endpoints: (build) => {
    return {
      uciCohorts: build.query<UCICohort[], void>({
        query: () => {
          return { url: '/college-credits/uci-cohorts/', method: 'get' };
        },
      }),
      uciGatiApplication: build.query<UciGatiApplication, UUID>({
        query: (uuid) => {
          return {
            url: `/college-credits/uci-gati-application/${uuid}/`,
            method: 'GET',
          };
        },
        providesTags: [{ type: 'UciGatiApplication' }],
      }),
      updateUciGatiApplication: build.mutation<
        UciGatiApplication,
        { uuid: UUID; payload: Partial<Omit<UciGatiApplication, 'uuid'>> }
      >({
        query: ({ uuid, payload }) => {
          return {
            url: `/college-credits/uci-gati-application/${uuid}/`,
            method: 'PATCH',
            data: payload,
          };
        },
        invalidatesTags: [
          {
            type: 'UciGatiApplication',
          },
        ],
      }),
    };
  },
});

export const {
  useUciCohortsQuery,
  useUciGatiApplicationQuery,
  useUpdateUciGatiApplicationMutation,
} = collegeCreditsApi;
