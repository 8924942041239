import { INSERT_UNORDERED_LIST_COMMAND, REMOVE_LIST_COMMAND } from '@lexical/list';
import { Icon } from '@polygence/components';
import classNames from 'classnames';
import type { FC } from 'react';

import {
  useToolbarContext,
  BlockType,
} from 'src/components/LexicalEditor/plugins/toolbar/ToolbarContext';

const UnorderedList: FC = () => {
  const { editor, blockType } = useToolbarContext();

  const formatBulletList = () => {
    if (blockType !== BlockType.ul) {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  return (
    <button
      type="button"
      title="Bulleted List"
      onClick={formatBulletList}
      className={classNames('toolbar-item', {
        active: blockType === BlockType.ul,
      })}
      aria-label="Format BulletedList"
    >
      <Icon id="list" />
    </button>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default UnorderedList;
