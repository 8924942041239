/**
 * String.prototype.replaceAll() polyfill
 * https://gomakethings.com/how-to-replace-a-section-of-a-string-with-another-one-with-vanilla-js/
 * @author Chris Ferdinandi
 * @license MIT
 */
if (!String.prototype.replaceAll) {
  /* eslint-disable-next-line no-extend-native, fp/no-mutation -- autodisabled */
  String.prototype.replaceAll = function replaceAll(str, newStr) {
    // If a regex pattern
    if (Object.prototype.toString.call(str).toLowerCase() === '[object regexp]') {
      return this.replace(str, newStr);
    }

    // If a string
    return this.replace(new RegExp(str, 'g'), newStr);
  };
}

// eslint-disable-next-line fp/no-mutation
'hasOwn' in Object || (Object.hasOwn = Object.call.bind(Object.hasOwnProperty));
