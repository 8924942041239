import { cn } from '@polygence/components';
import type { ReactNode } from 'react';

interface PaymentIntentBoxProps {
  children: ReactNode;
  className?: string;
}

export const PaymentIntentBox = ({ children, className }: PaymentIntentBoxProps) => {
  return <div className={cn('tw-rounded-lg tw-bg-gray-200/50 tw-p-5', className)}>{children}</div>;
};
