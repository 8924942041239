const positiveFeedbackCondition = {
  $and: [
    { $not: { field: 'countAs', value: 'no_show' } },
    { $gt: { field: 'studentPerformanceRating', value: 3 } },
  ],
};

const isNormalSessionCountAsQuestion = {
  $or: [
    { $is: { field: '__completedSessionCount', value: 0 } },
    { $not: { field: '__sessionsRemaining', value: 0.5 } },
  ],
};

const isHalfSessionCountAsQuestion = {
  $and: [
    { $not: { field: '__completedSessionCount', value: 0 } },
    { $is: { field: '__sessionsRemaining', value: 0.5 } },
  ],
};

export const reflectionSessionCountAsQuestion = {
  component: 'Box',
  props: {
    children: [
      {
        component: 'ComponentsText',
        props: {
          children: 'Report a no-show session:',
          size: 'medium',
          fontWeight: 'bold',
          className: 'mb-3',
        },
      },
      {
        component: 'ComponentsRadioButton',
        validation: {
          required: true,
        },
        props: {
          name: 'countAs',
          label: 'This was a full session',
          value: 'full_session',
          className: 'mb-3',
        },
      },
      {
        component: 'ComponentsRadioButton',
        validation: {
          required: true,
        },
        props: {
          name: 'countAs',
          label: 'Student violated the no-show policy',
          value: 'no_show',
          className: 'mb-3',
        },
      },
    ],
  },
};

export const sessionCountAsQuestion = {
  component: 'Box',
  props: {
    children: [
      {
        component: 'ComponentsText',
        props: {
          children: 'Report a no-show or partial session:',
          size: 'medium',
          fontWeight: 'bold',
          className: 'mb-3',
        },
      },
      {
        component: 'ComponentsRadioButton',
        validation: {
          required: true,
        },
        props: {
          name: 'countAs',
          label: 'This was a full session (Approx 60 minutes)',
          value: 'full_session',
          className: 'mb-3',
        },
        display: isNormalSessionCountAsQuestion,
      },
      {
        component: 'ComponentsRadioButton',
        props: {
          name: 'countAs',
          label: 'This was a full session (Approx 60 minutes)',
          value: 'full_session',
          className: 'mb-3',
          disabled: true,
        },
        display: isHalfSessionCountAsQuestion,
      },
      {
        component: 'ComponentsRadioButton',
        validation: {
          required: true,
        },
        props: {
          name: 'countAs',
          label: 'Student violated the no-show policy',
          value: 'no_show',
          className: 'mb-3',
        },
      },
      {
        component: 'ComponentsRadioButton',
        validation: {
          required: true,
        },
        props: {
          name: 'countAs',
          label:
            "This counts as half a session (Approx 30 minutes, you'll be paid 50% of your rate)",
          value: 'half_session',
          className: 'mb-3',
        },
        display: {
          $is: {
            field: '__halfSessionsEnabled',
            value: true,
          },
        },
      },
      {
        component: 'ComponentsRadioButton',
        validation: {
          required: true,
        },
        props: {
          name: 'countAs',
          label: "This meeting doesn't count as a session (you will not be paid)",
          value: 'not_session',
          className: 'mb-3',
        },
      },
    ],
  },
};

export const studentPositiveFeedbackQuestions = {
  component: 'Box',
  props: {
    children: [
      {
        component: 'LabelWithTooltip',
        props: {
          children:
            'What positive traits did the student exhibit in this session? (select all that apply)',
          tooltip:
            "We'll share this with your student. Positive reinforcement can help keeping them engaged in their learning journey.",
        },
      },
      {
        component: 'Checkbox',
        props: {
          label: 'Effort',
          name: 'studentStarEffort',
          id: 'studentStarEffort',
        },
      },
      {
        component: 'Checkbox',
        props: {
          label: 'Growth',
          name: 'studentStarGrowth',
          id: 'studentStarGrowth',
        },
      },
      {
        component: 'Checkbox',
        props: {
          label: 'Creativity',
          name: 'studentStarCreativity',
          id: 'studentStarCreativity',
        },
      },
      {
        component: 'Checkbox',
        props: {
          label: 'Perseverance',
          name: 'studentStarPerseverance',
          id: 'studentStarPerseverance',
        },
      },
      {
        component: 'Checkbox',
        props: {
          label: 'Ownership / Independence',
          name: 'studentStarIndependence',
          id: 'studentStarIndependence',
        },
      },
    ],
  },
} as const;

const metaQuestions = [
  sessionCountAsQuestion,
  {
    component: 'StarRating',
    props: {
      label: 'Rate student performance in this session:',
      name: 'studentPerformanceRating',
      starCount: 5,
      required: true,
    },
    display: {
      $and: [{ $not: { field: 'countAs', value: 'no_show' } }],
    },
  },
  {
    ...studentPositiveFeedbackQuestions,
    display: positiveFeedbackCondition,
  },
  {
    component: 'FormInput',
    props: {
      inputType: 'textarea',
      label:
        'If you have any highlights or feedback we can share with your student, let us know! We always want to champion them when possible. Send your student additional encouragement by leaving a note below:',
      name: 'studentStarNote',
    },
    display: positiveFeedbackCondition,
  },
];

export { metaQuestions };
