import { Nullish } from '@polygence/common/types/utils';
import { Skeleton, Text } from '@polygence/components';
import { ComponentProps, useEffect, useRef, useState } from 'react';

import { formatPrice } from 'src/payment/utils';

interface PaymentIntentAmountOrPlaceholderProps {
  amount: Nullish<number | string>;
  size?: ComponentProps<typeof Text>['size'];
  fontWeight?: ComponentProps<typeof Text>['fontWeight'];
  className?: string;
}

export const PaymentIntentAmountOrPlaceholder = ({
  amount,
  size = 'medium',
  fontWeight = 'normal',
  className,
}: PaymentIntentAmountOrPlaceholderProps) => {
  const [showPlaceholder, setShowPlaceholder] = useState(false);
  const previousAmount = useRef(amount);

  useEffect(() => {
    previousAmount.current = amount;
    const timer = setTimeout(() => setShowPlaceholder(amount == null), 200);
    return () => clearTimeout(timer);
  }, [amount]);

  if (showPlaceholder) {
    return (
      <Skeleton
        width="60px"
        height={`${size === 'large' ? 30 : 24}px`}
        color="var(--grayscale-5)"
      />
    );
  }

  return (
    <Text size={size} fontWeight={fontWeight} alignment="center" className={className}>
      {formatPrice(amount ?? previousAmount.current ?? 0)}
    </Text>
  );
};
