import * as marketplaceApi from '@polygence/common/api/marketplace';
import { UserType } from '@polygence/common/types/backend';
import type { UserProfileResponse } from '@polygence/common/types/marketplace';
import type { Nullable } from '@polygence/common/types/utils';
import { Heading, OptionType, Select, Spacer, Text } from '@polygence/components';
import { useEffect, useState } from 'react';
import type { MultiValue } from 'react-select';
import { toast } from 'react-toastify';
import { StringParam, useQueryParams } from 'use-query-params';

import { Autosave } from 'src/components/CollegeAdmissionSurvey/Autosave';
import { FEATURED_US_AS_OPTIONS } from 'src/components/CollegeAdmissionSurvey/constants';
import {
  getCalculatedFeaturedUsAsOptions,
  getCurrentFeaturedUsAsOption,
  getSurveyCollegesQuestion,
  getSurveyDescription,
} from 'src/components/CollegeAdmissionSurvey/utils';
import { PageLoad } from 'src/components/PageLoad';
import {
  DatePickerChangeTarget,
  DatePickerWithLabel,
} from 'src/components/common/DatePickerWithLabel';
import { TopCollegesSelector } from 'src/components/usersettings/TopCollegesSelector';
import { schoolGraduationYearProps } from 'src/students/student-application';

export const StudentCollegeAdmissionSurvey = () => {
  const [query] = useQueryParams({ stage: StringParam });
  const stage = query.stage ?? '';
  const [profile, setProfile] = useState<Nullable<UserProfileResponse<UserType.STUDENT>>>(null);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    marketplaceApi
      .getUserProfile(UserType.STUDENT)
      .then(({ data }) => {
        setProfile(data);
      })
      .catch(() => {
        toast.error("Couldn't fetch your profile. Please contact support!");
      });
  }, []);

  const onGraduationYearChange = ({ target: { value } }: DatePickerChangeTarget) => {
    setSaving(true);
    marketplaceApi
      .updateUserProfile({ schoolGraduationYear: value }, UserType.STUDENT)
      .then(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore Dear next programmer i couldn't solve this, so if you want feel free to try.
        setProfile((prevState) => {
          return {
            ...prevState,
            schoolGraduationYear: value,
          };
        });
      })
      .catch(() => toast.error("Couldn't save your graduation year."))
      .finally(() => setSaving(false));
  };

  const handleFeaturedUsAsSelectChange = (selectedOptions: MultiValue<OptionType>) => {
    const featuredUsAs = getCalculatedFeaturedUsAsOptions(selectedOptions as OptionType[]).map(
      (option) => option.value,
    );

    setSaving(true);
    marketplaceApi
      .updateUserProfile({ featuredUsAs }, UserType.STUDENT)
      .then(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore Dear next programmer i couldn't solve this, so if you want feel free to try.
        setProfile((prevState) => {
          return {
            ...prevState,
            featuredUsAs,
          };
        });
      })
      .catch(() => toast.error("Couldn't save how you featured us as."))
      .finally(() => setSaving(false));
  };

  if (!profile) {
    return <PageLoad />;
  }

  return (
    <div>
      <div className="d-flex column flex-column justify-content-center align-items-center mb-8 container">
        <Heading as="h2" className="my-5">
          Polygence 2024 College Admissions Survey
        </Heading>
        <Text size="medium" alignment="center" className="mb-3 w-75">
          Thank you for participating in our college survey!
        </Text>
        <Text size="medium" alignment="center" className="mb-3 w-75">
          {getSurveyDescription(stage)}
        </Text>
        <Text size="medium" alignment="center" className="mb-10 w-75">
          To help you prepare for the next step in your journey, you will be entered to win a $1000
          scholarship upon completion of the survey! You must complete all questions to be entered.*
        </Text>
        <div className="w-75">
          <Spacer size={6} />
          <Text size="medium" fontWeight="bold">
            Year of graduation
          </Text>
          <DatePickerWithLabel
            {...schoolGraduationYearProps}
            id="year_of_graduation"
            customInput
            onChange={onGraduationYearChange}
            value={profile.schoolGraduationYear}
          />
          <Spacer size={6} />

          <Text size="medium" fontWeight="bold" className="mb-5">
            How did you feature Polygence on your college applications?
          </Text>
          <Select
            name="featuredUsAs"
            isMulti
            options={FEATURED_US_AS_OPTIONS}
            value={getCurrentFeaturedUsAsOption(profile.featuredUsAs)}
            onChange={handleFeaturedUsAsSelectChange}
          />
          <Spacer size={8} />

          <TopCollegesSelector
            stage={stage}
            title={getSurveyCollegesQuestion(stage)}
            setSaving={setSaving}
          />

          <Text size="medium" alignment="center" className="my-8">
            Thank you so much for your response. We're so excited to see where the next steps of
            your academic journey are taking you! Once you've submitted your complete results,
            including where you've applied, been accepted, and will be attending, you'll be entered
            to win a $1000 scholarship. We'll announce the winner after June 1!
          </Text>
          <Spacer size={13} />
        </div>
      </div>
      <Autosave saving={saving} />
    </div>
  );
};
