import { months } from 'src/components/aux/months';
import { dayjs } from 'src/utils/dayjsCustom';

export const dateStamp = (datetime: string | number | Date) => {
  if (datetime) {
    const timestampDatetime = new Date(datetime);
    return `${
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      months[timestampDatetime.getMonth()]
    } ${timestampDatetime.getDate()}, ${timestampDatetime.getUTCFullYear()}`;
  }
  return null;
};

export const getLocalDateFormat = () => {
  return navigator.language === 'en-US' ? 'MM/dd/yyyy' : 'yyyy-MM-dd';
};
export const getLocalDateFormatWithTime = () => {
  return navigator.language === 'en-US' ? 'MM/dd/yyyy h:mm aa' : 'yyyy-MM-dd HH:mm';
};
export const getLocalDayjsTimeFormat = () => {
  return navigator.language === 'en-US' ? 'h:mm A' : 'HH:mm';
};

export const getLocalDayjsDateFormat = () => {
  return navigator.language === 'en-US' ? 'MM/DD/YYYY' : 'YYYY-MM-DD';
};

export const getLocalDayjsDateTimeFormat = () => {
  return `${getLocalDayjsDateFormat()} ${getLocalDayjsTimeFormat()}`;
};

export const formatDateAsLocal = (
  datetime?: string | number | Date | dayjs.Dayjs | null | undefined,
) => {
  const format = getLocalDayjsDateFormat();
  return dayjs(datetime).format(format);
};

export const formatTimeAsLocal = (
  time?: string | number | Date | dayjs.Dayjs | null | undefined,
) => {
  const format = getLocalDayjsTimeFormat();
  return dayjs(time).format(format);
};

export const formatDatetimeAsLocal = (
  datetime?: string | number | Date | dayjs.Dayjs | null | undefined,
) => {
  const format = `${getLocalDayjsDateFormat()} ${getLocalDayjsTimeFormat()}`;
  return dayjs(datetime).format(format);
};

export const fromNow = (datetime?: string | number | Date | dayjs.Dayjs | null | undefined) => {
  return dayjs(datetime).fromNow();
};
