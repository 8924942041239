import type { UUID, UCICohortId } from '@polygence/common/types/common';
import { Select } from '@polygence/components';
import type { Size } from '@polygence/components';
import type { SingleValue, OptionType } from '@polygence/components';
import { toast } from 'react-toastify';

import { formatDateAsLocal } from 'src/components/aux/dateStamp';
import InputWrapper from 'src/components/mentor/InputWrapper';
import { useUpdateUciGatiApplicationAdminMutation } from 'src/reducers/adminReducers';
import { useUpdateUciGatiApplicationMutation } from 'src/reducers/collegeCreditsReducer';
import { useUciCohortsQuery } from 'src/reducers/collegeCreditsReducer';

export const CohortSelector = ({
  uuid,
  uciCohort,
  required = false,
  value,
  admin = false,
  label,
  size = 'medium',
  callback = () => undefined,
  extras,
}: {
  uuid: UUID;
  uciCohort: UCICohortId;
  required?: boolean;
  value: string;
  admin?: boolean;
  size?: Size;
  label?: string;
  callback?: () => void;
  extras?: Record<string, unknown>;
}) => {
  const { data: uciCohorts } = useUciCohortsQuery();
  const [updateApplication] = useUpdateUciGatiApplicationMutation();
  const [updateApplicationAdmin] = useUpdateUciGatiApplicationAdminMutation();

  const options = uciCohorts?.map(({ name, finalPaperDue, id }) => ({
    label: `${name} (Final Paper Due: ${formatDateAsLocal(finalPaperDue)})`,
    value: id,
    isDisabled: id === uciCohort,
  }));

  const updateCohort = admin ? updateApplicationAdmin : updateApplication;

  const handleChange = (newValue: SingleValue<OptionType>) => {
    updateCohort({
      uuid,
      payload: {
        uciCohort: newValue?.value as number,
        extras: { ...extras, cohortEmailSentAt: null },
      },
    })
      .then(callback)
      .catch(() => toast.error('Could not update UCI Cohort. Please try again.'));
  };

  return (
    <InputWrapper required={required} value={value}>
      <Select
        label={label}
        options={options}
        value={options?.find(({ value }) => value === uciCohort)}
        size={size || 'medium'}
        onChange={handleChange}
        isDisabled={!uciCohorts}
      />
    </InputWrapper>
  );
};
