import {
  getConnection,
  sendMessagesSeen,
  sendMessage as sendMessageWithoutRetry,
  closeConnection,
} from './api';
import { sendMessage, sendUnsentMessagesFromLocalStorage } from './messageSending';

export const websocketApi = {
  getConnection,
  sendMessagesSeen,
  sendMessage,
  sendMessageWithoutRetry,
  closeConnection,
  sendUnsentMessagesFromLocalStorage,
};
