import type { MentorProfileId } from '@polygence/common';
import { Heading } from '@polygence/components';

import { MentorAvailability } from 'src/components/mentor/Availability/MentorAvailability';
import { useCoreMentorAvailability } from 'src/hooks/useMentorAvailability';

interface CoreMentorAvailabilityProps {
  mentorProfileId: MentorProfileId;
}

export const CoreMentorAvailability = ({ mentorProfileId }: CoreMentorAvailabilityProps) => {
  const coreMentorAvailability = useCoreMentorAvailability({ mentorProfileId });

  if (!coreMentorAvailability) {
    return null;
  }

  return (
    <>
      <Heading size="h5" alignment="left" className="tw-mb-1">
        Availability
      </Heading>
      <MentorAvailability
        mentorProfileId={mentorProfileId}
        mentorAvailability={coreMentorAvailability}
      />
    </>
  );
};
