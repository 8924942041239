import type { StudentRelatedPod } from '@polygence/common/types/data/pods';
import { Badge, Button, Card, Heading, Text } from '@polygence/components';

import DateWrapper, { DateFormat } from 'src/components/aux/dateWrapper';
import { EnrollNowButton } from 'src/students/Pods/EnrollNowButton';
import { PayNowButton } from 'src/students/Pods/PayNowButton';

export const PendingPodInfoCard = ({
  pod,
  tokenCount,
}: {
  pod: StudentRelatedPod;
  tokenCount: number;
}) => {
  const {
    id: cohortId,
    startDate,
    remainingSeats,
    isPodFull,
    course: { title },
    active,
  } = pod.pod;
  const paymentIntent = pod.paymentIntent;
  const formattedStartDate = new DateWrapper(startDate).format(DateFormat.FORMAT_12);
  const hasAvailableToken = tokenCount > 0;

  const getCheckoutLink = () => {
    if (paymentIntent) {
      return `/payment/${paymentIntent.uuid}`;
    }
    return '#';
  };

  const getButtonComponent = () => {
    if (!isPodFull) {
      if (hasAvailableToken) {
        return <EnrollNowButton cohortId={cohortId} />;
      } else {
        return <PayNowButton disabled={!paymentIntent} link={getCheckoutLink()} />;
      }
    } else {
      return (
        <Button variant="tertiary" disabled>
          This pod is full.
        </Button>
      );
    }
  };

  return (
    <Card className="my-5 p-6">
      <div className="d-flex gap-5 justify-content-between">
        <div className="d-flex flex-column gap-5">
          <Heading size="h5" alignment="left">
            {title}
          </Heading>
          <div className="d-flex flex-row align-items-center gap-5">
            <Text size="small" className="text-muted">
              Starts {formattedStartDate}
            </Text>
            <Badge variant="secondary" size="small" pill>
              {`${remainingSeats} seats left!`}
            </Badge>
          </div>
          {!active && <Text size="medium">We are sorry, but this Pod has been cancelled.</Text>}
        </div>
        {active && <div>{getButtonComponent()}</div>}
      </div>
    </Card>
  );
};
