import { FormSection } from 'src/components/mentor/FormSection';

/* Used to group children into one shared display rule */
export const Box = ({
  children,
  disabled,
  helpColumn = false,
  containerClassName,
  ...rest
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any[];
  disabled: boolean;
  helpColumn: boolean;
  containerClassName?: string;
}) => {
  return (
    <FormSection
      fields={children}
      readOnly={disabled}
      containerClassName={containerClassName}
      helpColumn={helpColumn}
      {...rest}
    />
  );
};
