type TimeoutId = NodeJS.Timeout;

const navigateTo = (url: string) => {
  window.location.assign(url);
};

const navigateToWithTimeout = (url: string, timeout: number): TimeoutId => {
  return setTimeout(() => {
    navigateTo(url);
  }, timeout);
};

export { navigateTo, navigateToWithTimeout };
