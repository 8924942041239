export const ApplicationSectionIds = {
  ADMISSION_ADVISOR_FIELDS_SECTION_ID: 'section-admission-advisor-fields',
  COMPUTER_SCIENCE_DETAIL_SECTION_ID: 'section-computer-science-detail',
  COMPUTER_SCIENCE_SECTION_ID: 'section-computer-science',
  COUNSELOR_SECTION_ID: 'section-counselor',
  EXPERIENCE_SECTION_ID: 'section-experience',
  EXPERIMENT_DETAIL_SECTION_ID: 'section-experiment-detail',
  EXPERIMENT_SECTION_ID: 'section-experiment',
  FINANCIAL_AID_SECTION_ID: 'section-financial-aid',
  INTERESTS_SECTION_ID: 'section-interests',
  OUTPUT_SECTION_ID: 'section-output',
  PARENT_SECTION_ID: 'section-parent',
  PROJECT_IDEA_DECISION_ID: 'section-project-idea-decision',
  PROJECT_IDEA_SECTION_ID: 'section-project-idea',
  LAUNCHPAD_PITCH_ID: 'section-launchpad-pitch',
  PRONOUNS_SECTION_ID: 'section-pronouns',
  REFERRAL_SECTION_ID: 'section-referrral',
  SCHOOL_SECTION_ID: 'section-school',
  TIMELINE_SECTION_ID: 'section-timeline',
  VIRTUAL_INTERVIEW_SECTION_ID: 'section-virtual-interview',
  PATHFINDER_INTEREST_1: 'section-pathfinder-interest-1',
  PATHFINDER_INTEREST_2: 'section-pathfinder-interest-2',
  PATHFINDER_INTEREST_3: 'section-pathfinder-interest-3',
  EXPECTATIONS_SECTION_ID: 'section-expectations',
} as const;

export type ApplicationSectionId =
  (typeof ApplicationSectionIds)[keyof typeof ApplicationSectionIds];

export const ApplicationSections = {
  [ApplicationSectionIds.ADMISSION_ADVISOR_FIELDS_SECTION_ID]: 'Admission advisor fields',
  [ApplicationSectionIds.COMPUTER_SCIENCE_DETAIL_SECTION_ID]: 'Computer science detail',
  [ApplicationSectionIds.COMPUTER_SCIENCE_SECTION_ID]: 'Computer science',
  [ApplicationSectionIds.COUNSELOR_SECTION_ID]: 'Counselor',
  [ApplicationSectionIds.EXPERIENCE_SECTION_ID]: 'Experience',
  [ApplicationSectionIds.EXPERIMENT_DETAIL_SECTION_ID]: 'Experiment detail',
  [ApplicationSectionIds.EXPERIMENT_SECTION_ID]: 'Experiment',
  [ApplicationSectionIds.FINANCIAL_AID_SECTION_ID]: 'Financial aid',
  [ApplicationSectionIds.INTERESTS_SECTION_ID]: 'Interests',
  [ApplicationSectionIds.OUTPUT_SECTION_ID]: 'Output',
  [ApplicationSectionIds.PARENT_SECTION_ID]: 'Parent',
  [ApplicationSectionIds.PROJECT_IDEA_DECISION_ID]: 'Project idea decision',
  [ApplicationSectionIds.PROJECT_IDEA_SECTION_ID]: 'Project idea',
  [ApplicationSectionIds.LAUNCHPAD_PITCH_ID]: 'Launchpad pitch',
  [ApplicationSectionIds.PRONOUNS_SECTION_ID]: 'Pronouns',
  [ApplicationSectionIds.REFERRAL_SECTION_ID]: 'Referral',
  [ApplicationSectionIds.SCHOOL_SECTION_ID]: 'School',
  [ApplicationSectionIds.TIMELINE_SECTION_ID]: 'Timeline',
  [ApplicationSectionIds.VIRTUAL_INTERVIEW_SECTION_ID]: 'Virtual interview',
  [ApplicationSectionIds.PATHFINDER_INTEREST_1]: 'Pathfinder interest 1',
  [ApplicationSectionIds.PATHFINDER_INTEREST_2]: 'Pathfinder interest 2',
  [ApplicationSectionIds.PATHFINDER_INTEREST_3]: 'Pathfinder interest 3',
  [ApplicationSectionIds.EXPECTATIONS_SECTION_ID]: 'Expectations',
};
