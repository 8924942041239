import { MentorProfileId, Tag } from '@polygence/common';

import { DisplayTags } from 'src/components/admin/components/aux/DisplayTags';

interface SubjectSelectorProps {
  profile: { tags: Tag[]; id: MentorProfileId };
  missingFields: string[];
}

export const SubjectSelector = ({ profile: { tags, id }, missingFields }: SubjectSelectorProps) => {
  const subjectTags = tags.filter((tag) => {
    return tag.category === 'subject_tag';
  });
  const isInvalid = missingFields.includes('tags');
  const error = isInvalid && tags.length < 3 ? 'List at least 3 areas' : 'List at most 5 areas';

  return (
    <div className="mb-5 position-relative" id="mentor-tags">
      <span className="required">
        List of subjects that best describe your expertise (between 3-5).
      </span>

      <DisplayTags
        tags={subjectTags}
        category="subject_tag"
        label=""
        color="info"
        editable="inline"
        changeUrl={`/api/mentors/${id}/tags/`}
        forceReload={() => {
          window.location.reload();
        }}
        className=""
        minTagCount={3}
        maxTagCount={5}
      />
      {isInvalid && error && <div className="row col text-danger small">{error}</div>}
    </div>
  );
};
