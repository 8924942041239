import { SimpleUser, commonHooks } from '@polygence/common';
import * as zeusApi from '@polygence/common/api/zeus';
import * as roleUtils from '@polygence/common/utils/roles';
import { OptionType, Select, Text } from '@polygence/components';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { useAdmissionAdvisorsQuery } from 'src/reducers/adminReducers';

interface EditableAdmissionAdvisorProps {
  studentId: number;
  admissionAdvisor: SimpleUser;
}

export const EditableAdmissionAdvisor = ({
  studentId,
  admissionAdvisor,
}: EditableAdmissionAdvisorProps) => {
  const [loading, setIsLoading] = useState(false);
  const { data, isSuccess } = useAdmissionAdvisorsQuery();
  const admissionAdvisors = isSuccess
    ? data.map((aa) => ({ value: aa.id, label: `${aa.firstName} ${aa.lastName}` }))
    : [];

  const user = commonHooks.useCurrentUser();
  const [currentValue, setCurrentValue] = useState(() => {
    return admissionAdvisor
      ? ({
          label: `${admissionAdvisor.firstName} ${admissionAdvisor.lastName}`,
          value: admissionAdvisor.id,
        } as OptionType)
      : null;
  });

  const disabled = loading || !roleUtils.isAdmin({ roles: user.roles });

  const handleSelect = (selectedOption: OptionType) => {
    setIsLoading(true);
    zeusApi
      .changeAdmissionAdvisor(
        studentId,
        selectedOption !== null ? (selectedOption.value as number) : null,
      )
      .then(() => {
        setCurrentValue(selectedOption);
      })
      .catch(() => toast.error('Failed to change admission advisor'))
      .finally(() => setIsLoading(false));
  };

  return (
    <div className="form-group">
      <Text size="medium">Admission Advisor</Text>
      <Select
        onChange={(option) => handleSelect(option as OptionType)}
        defaultValue={currentValue}
        options={admissionAdvisors}
        isDisabled={disabled}
        isMulti={false}
        isClearable
      />
    </div>
  );
};
