import type { ArrayValueType } from '@polygence/common/types/utils';
import React from 'react';
import type { Components } from 'react-markdown';

export function flatten(
  text: string,
  child: ArrayValueType<ReturnType<typeof React.Children.toArray>>,
): string {
  return typeof child === 'string'
    ? text + child
    : // @ts-expect-error Preserving functionality: note that 'child' can also be a number
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      React.Children.toArray(child.props.children).reduce(flatten, text);
}

export const HeadingRenderer: Components['h1'] = ({ node, ...props }) => {
  const children = React.Children.toArray(props.children);
  const text = children.reduce(flatten, '');
  const slug = text.toLowerCase().replace(/\W/g, '-');
  return React.createElement(node?.tagName ?? 'h1', { id: slug }, props.children);
};

export const LinkRenderer: Components['a'] = ({ node, ...props }) => {
  if (props?.href?.startsWith('#')) {
    return <a href={props.href}>{props.children}</a>;
  }

  const href =
    props?.href?.startsWith('http') || props?.href?.startsWith('mailto:')
      ? props.href
      : `https://${props?.href ?? ''}`;

  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {props.children}
    </a>
  );
};
