import { Illustration, TileSelect } from '@polygence/components';
import PropTypes from 'prop-types';
import React from 'react';

import styles from 'src/components/common/TileRadioButtonGroup/TileRadioButtonGroup.module.sass';
import { HELPER_TEXTS } from 'src/components/constants';
import { HelperText } from 'src/components/mentor/FormElements';

const TileRadioButtonGroup = ({
  name,
  options,
  onChange,
  value,
  helperText = HELPER_TEXTS.RADIO_BUTTON,
  disabled,
}) => {
  return (
    <>
      <HelperText text={helperText} />
      <div className={styles.gridWrapper}>
        {options.map(({ label, value: optionValue, illustration = '' }) => {
          return (
            <TileSelect
              key={optionValue}
              value={optionValue}
              name={name}
              type="radio"
              onChange={onChange}
              checked={optionValue === value}
              disabled={disabled}
            >
              {illustration && <Illustration name={illustration} size={50} />}
              {label}
            </TileSelect>
          );
        })}
      </div>
    </>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default TileRadioButtonGroup;

TileRadioButtonGroup.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  onChange: PropTypes.func,
  value: PropTypes.string,
  helperText: PropTypes.string,
};
