import { commonHooks } from '@polygence/common';
import { useMediaQuery } from '@polygence/common/hooks/useMediaQuery';

import { AdmissionAdvisorModalInfoContent } from 'src/components/admin/components/AdmissionAdvisorModalInfo/AdmissionAdvisorModalInfoContent';
import { AdmissionAdvisorModalInfoSheet } from 'src/components/admin/components/AdmissionAdvisorModalInfo/AdmissionAdvisorModalInfoSheet';
import { useAdmissionAdvisorModalQuery } from 'src/reducers/marketplaceReducer';

export const AdmissionAdvisorModalInfo = () => {
  const currentUser = commonHooks.useCurrentUser();
  const isMobileOrTablet = useMediaQuery('(max-width: 767px)');

  const { data: advisor, isLoading: isAdvisorLoading } = useAdmissionAdvisorModalQuery({
    aaEmail: String(currentUser?.otherInfo['admissionAdvisorEmail']),
  });

  if (isAdvisorLoading || !advisor) {
    return null;
  }

  if (isMobileOrTablet) {
    return <AdmissionAdvisorModalInfoSheet advisor={advisor} />;
  }

  return (
    <div className="tw-flex tw-justify-center">
      <div className="tw-flex tw-w-full tw-max-w-[412px] tw-flex-col tw-gap-4 tw-rounded-xl tw-bg-white tw-p-8 tw-shadow-xl">
        <AdmissionAdvisorModalInfoContent advisor={advisor} />
      </div>
    </div>
  );
};
