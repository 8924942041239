import { componentMapper } from '@data-driven-forms/mui-component-mapper';

import DataDrivenFormNPSInput from 'src/components/data-driven-forms/DataDrivenFormNPSInput';
import Rating from 'src/components/data-driven-forms/Rating';

/* eslint-disable-next-line import/no-anonymous-default-export, import/no-default-export -- autodisabled, autodisabled */
export default {
  ...componentMapper,
  rating: Rating,
  npsInput: DataDrivenFormNPSInput,
};
