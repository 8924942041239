import { ApplicationFieldTypes } from 'src/constants/application-field-types';
import {
  influencerTextProps,
  textFieldSpacer,
} from 'src/students/student-application/common-section-components';
import { counselorDetailsFollowup } from 'src/students/student-application/counselor-details-followup';
import type { ApplicationComponentDescription } from 'src/students/student-application/student-application-components';

export const referralQuestions = Object.freeze<ApplicationComponentDescription[]>([
  {
    component: 'ComponentsRadioButton',
    tag: ApplicationFieldTypes.PROFILE,
    props: {
      name: 'referral',
      label: 'My counselor',
      value: 'counselor',
    },
    validation: {
      required: true,
    },
  },
  {
    component: 'Box',
    tag: ApplicationFieldTypes.PROFILE,
    display: {
      $is: {
        field: 'referral',
        value: 'counselor',
      },
    },
    props: {
      children: counselorDetailsFollowup,
    },
  },
  {
    component: 'ComponentsRadioButton',
    tag: ApplicationFieldTypes.PROFILE,
    props: {
      name: 'referral',
      label: 'My parent',
      value: 'parent',
    },
  },
  {
    component: 'Box',
    tag: ApplicationFieldTypes.PROFILE,
    display: {
      $is: {
        field: 'referral',
        value: 'parent',
      },
    },
    props: {
      children: [
        {
          component: 'InputField',
          tag: ApplicationFieldTypes.PROFILE,
          props: {
            label: 'Where did your parent hear about Polygence?',
            floating: false,
            placeholder: 'e.g. Google or friend',
            name: 'parentReferral',
          },
          validation: {
            required: false,
          },
        },
        textFieldSpacer,
      ],
    },
  },
  {
    component: 'ComponentsRadioButton',
    tag: ApplicationFieldTypes.PROFILE,
    props: {
      name: 'referral',
      label: 'Word of mouth / My friend or family member did Polygence',
      value: 'word_of_mouth_friend',
    },
  },
  {
    component: 'Box',
    tag: ApplicationFieldTypes.PROFILE,
    display: {
      $is: {
        field: 'referral',
        value: 'word_of_mouth_friend',
      },
    },
    props: {
      children: [
        {
          component: 'SelectInput',
          tag: ApplicationFieldTypes.PROFILE,
          props: {
            label: 'Were you referred by a friend or a family member?',
            options: [
              { label: 'Friend', value: 'friend' },
              { label: 'Family', value: 'family' },
              { label: 'Other', value: 'other' },
            ],
            name: 'wordOfMouthReferralType',
            id: 'wordOfMouthReferralType',
          },
          validation: {
            required: true,
          },
        },
        {
          component: 'ComponentsText',
          props: {
            children: 'What is the name of the person who referred you? ',
            size: 'medium',
            className: 'ms-4',
          },
        },
        {
          component: 'SingleScholarPageSelector',
          tag: ApplicationFieldTypes.PROFILE,
          props: {
            name: 'wordOfMouthReferral',
            label: 'You can search for existing Polygence scholars or add your own referrer.',
          },
          validation: {
            required: false,
          },
        },
        {
          component: 'ComponentsText',
          props: {
            children:
              'This person will not be notified that you are applying or contacted in any way.',
            fontWeight: 'light',
            size: 'small',
            className: 'ms-4',
          },
        },
        textFieldSpacer,
      ],
    },
  },
  {
    component: 'ComponentsRadioButton',
    tag: ApplicationFieldTypes.PROFILE,
    props: {
      name: 'referral',
      label: 'Google search',
      value: 'google_search',
    },
  },
  {
    component: 'Box',
    tag: ApplicationFieldTypes.PROFILE,
    display: {
      $is: {
        field: 'referral',
        value: 'google_search',
      },
    },
    props: {
      children: [
        {
          component: 'InputField',
          tag: ApplicationFieldTypes.PROFILE,
          props: {
            label: 'What search term did you use?',
            name: 'googleReferral',
          },
          validation: {
            required: true,
          },
        },
        textFieldSpacer,
      ],
    },
  },
  {
    component: 'ComponentsRadioButton',
    tag: ApplicationFieldTypes.PROFILE,
    props: {
      name: 'referral',
      label: 'Webinar',
      value: 'webinar',
    },
  },
  {
    component: 'Box',
    tag: ApplicationFieldTypes.PROFILE,
    display: {
      $is: {
        field: 'referral',
        value: 'webinar',
      },
    },
    props: {
      children: [
        {
          component: 'InputField',
          tag: ApplicationFieldTypes.PROFILE,
          props: {
            label: 'What webinar was it?',
            name: 'webinarReferral',
          },
          validation: {
            required: true,
          },
        },
        textFieldSpacer,
      ],
    },
  },
  {
    component: 'ComponentsRadioButton',
    tag: ApplicationFieldTypes.PROFILE,
    props: {
      name: 'referral',
      label: 'Podcast',
      value: 'podcast',
    },
  },
  {
    component: 'Box',
    tag: ApplicationFieldTypes.PROFILE,
    display: {
      $is: {
        field: 'referral',
        value: 'podcast',
      },
    },
    props: {
      children: [
        {
          component: 'InputField',
          tag: ApplicationFieldTypes.PROFILE,
          props: {
            label: 'Which one?',
            name: 'podcastName',
          },
          validation: {
            required: true,
          },
        },
        textFieldSpacer,
      ],
    },
  },
  {
    component: 'ComponentsRadioButton',
    tag: ApplicationFieldTypes.PROFILE,
    props: {
      name: 'referral',
      label: 'Polygence Symposium',
      value: 'symposium',
    },
  },
  {
    component: 'ComponentsRadioButton',
    tag: ApplicationFieldTypes.PROFILE,
    props: {
      name: 'referral',
      label: 'Polygence Mentor',
      value: 'mentor',
    },
  },
  {
    component: 'Box',
    tag: ApplicationFieldTypes.PROFILE,
    display: {
      $is: {
        field: 'referral',
        value: 'mentor',
      },
    },
    props: {
      children: [
        {
          component: 'InputField',
          tag: ApplicationFieldTypes.PROFILE,
          props: {
            label: 'What is the name of the mentor who referred you?',
            floating: false,
            name: 'mentorReferral',
          },
          validation: {
            required: true,
          },
        },
        textFieldSpacer,
      ],
    },
  },
  {
    component: 'ComponentsRadioButton',
    tag: ApplicationFieldTypes.PROFILE,
    props: {
      checkedIfInArray: [
        'social_media',
        'instagram',
        'facebook',
        'tiktok',
        'youtube',
        'snapchat',
        'twitter',
        'influencer',
      ],
      name: 'referral',
      label: 'Social Media',
      value: 'social_media',
    },
    validation: {
      socialMedia: true,
      required: true,
    },
  },
  {
    component: 'Box',
    tag: ApplicationFieldTypes.PROFILE,
    display: {
      $includes: {
        field: 'referral',
        value: [
          'social_media',
          'instagram',
          'facebook',
          'tiktok',
          'youtube',
          'snapchat',
          'twitter',
          'influencer',
        ],
      },
    },
    props: {
      className: 'ms-10',
      children: [
        {
          component: 'ComponentsText',
          tag: ApplicationFieldTypes.PROFILE,
          props: {
            children: 'Please specify',
            fontWeight: 'light',
            size: 'medium',
            className: 'mt-n3 mb-6',
          },
        },
        {
          component: 'ComponentsRadioButton',
          tag: ApplicationFieldTypes.PROFILE,
          props: {
            name: 'referral',
            label: 'Instagram',
            value: 'instagram',
          },
        },
        {
          component: 'ComponentsRadioButton',
          tag: ApplicationFieldTypes.PROFILE,
          props: {
            name: 'referral',
            label: 'Facebook',
            value: 'facebook',
          },
        },
        {
          component: 'ComponentsRadioButton',
          tag: ApplicationFieldTypes.PROFILE,
          props: {
            name: 'referral',
            label: 'TikTok',
            value: 'tiktok',
          },
        },
        {
          component: 'Box',
          tag: ApplicationFieldTypes.PROFILE,
          display: {
            $is: {
              field: 'referral',
              value: 'tiktok',
            },
          },
          props: {
            children: [
              {
                component: 'InputField',
                tag: ApplicationFieldTypes.PROFILE,
                props: {
                  label: 'Please specify the Tiktok channel if you can',
                  name: 'tiktokChannel',
                },
                validation: {
                  required: false,
                },
              },
              {
                component: 'ComponentsText',
                tag: ApplicationFieldTypes.PROFILE,
                props: influencerTextProps,
              },
              textFieldSpacer,
            ],
          },
        },
        {
          component: 'ComponentsRadioButton',
          tag: ApplicationFieldTypes.PROFILE,
          props: {
            name: 'referral',
            label: 'Youtube',
            value: 'youtube',
          },
        },
        {
          component: 'Box',
          tag: ApplicationFieldTypes.PROFILE,
          display: {
            $is: {
              field: 'referral',
              value: 'youtube',
            },
          },
          props: {
            children: [
              {
                component: 'InputField',
                tag: ApplicationFieldTypes.PROFILE,
                props: {
                  label: 'What Video did you watch?',
                  name: 'youtubeVideoReferral',
                },
                validation: {
                  required: false,
                },
              },
              {
                component: 'ComponentsText',
                tag: ApplicationFieldTypes.PROFILE,
                props: influencerTextProps,
              },
              textFieldSpacer,
            ],
          },
        },
        {
          component: 'ComponentsRadioButton',
          tag: ApplicationFieldTypes.PROFILE,
          props: {
            name: 'referral',
            label: 'Snapchat',
            value: 'snapchat',
          },
        },
        {
          component: 'ComponentsRadioButton',
          tag: ApplicationFieldTypes.PROFILE,
          props: {
            name: 'referral',
            label: 'Twitter',
            value: 'twitter',
          },
        },
        {
          component: 'ComponentsRadioButton',
          tag: ApplicationFieldTypes.PROFILE,
          props: {
            name: 'referral',
            label: 'Social media influencer',
            value: 'influencer',
          },
        },
        {
          component: 'Box',
          tag: ApplicationFieldTypes.PROFILE,
          display: {
            $is: {
              field: 'referral',
              value: 'influencer',
            },
          },
          props: {
            children: [
              {
                component: 'InputField',
                tag: ApplicationFieldTypes.PROFILE,
                props: {
                  label: 'Please specify influencer name if you can',
                  name: 'influencerName',
                },
                validation: {
                  required: false,
                },
              },
              {
                component: 'ComponentsText',
                tag: ApplicationFieldTypes.PROFILE,
                props: influencerTextProps,
              },
              textFieldSpacer,
            ],
          },
        },
      ],
    },
  },
  {
    component: 'ComponentsRadioButton',
    tag: ApplicationFieldTypes.PROFILE,
    props: {
      name: 'referral',
      label: 'Other',
      value: 'other',
    },
  },
  {
    component: 'InputField',
    tag: ApplicationFieldTypes.PROFILE,
    display: {
      $is: {
        field: 'referral',
        value: 'other',
      },
    },
    props: {
      label: 'Please specify',
      name: 'otherReferral',
    },
    validation: {
      required: true,
    },
  },
]);
