import { OptionType, Select } from '@polygence/components';
import type { MultiValue, PropsValue } from 'react-select';

interface MultiToggleSelectProps {
  name?: string;
  className?: string;
  id?: string;
  value: PropsValue<OptionType>;
  isSearchable?: boolean;
  isClearable?: boolean;
  disabled?: boolean;
  onChange: (event: unknown) => void;
  options: OptionType[];
}

export const MultiToggleSelect = ({
  name,
  id,
  value,
  className = '',
  isSearchable,
  disabled = false,
  onChange: handleChange,
  options,
}: MultiToggleSelectProps) => {
  const mapValues = (values: MultiValue<OptionType>) => {
    const things = options.map((option) => ({
      name: option.value,
      value: values.some((value) => value.value === option.value),
    }));
    handleChange(things);
  };

  return (
    <Select
      name={name}
      id={id}
      value={value}
      className={className}
      options={options}
      isSearchable={isSearchable}
      isDisabled={disabled}
      onChange={(values) => {
        mapValues(values);
      }}
      isMulti
    />
  );
};
