import type { Nullable } from '@polygence/common';
import * as showcasingApi from '@polygence/common/api/showcasing';
import type { SimpleShowcasingOpportunity } from '@polygence/common/types/showcasing';
import { AsyncSelect, OptionType } from '@polygence/components';
import { debounce } from 'lodash';

interface SelectorProps {
  defaultOption: Nullable<SimpleShowcasingOpportunity>;
  onSelect: (_: Nullable<SimpleShowcasingOpportunity>) => void;
}

const convertOptionToOpportunity = (
  option: Nullable<OptionType>,
): Nullable<SimpleShowcasingOpportunity> => {
  return option ? { id: parseInt(option.value as string), name: option.label } : option;
};

const convertOpportunityToOption = (
  opportunity: Nullable<SimpleShowcasingOpportunity>,
): Nullable<OptionType> => {
  return opportunity ? { label: opportunity.name, value: `${opportunity.id}` } : opportunity;
};

const ShowcasingOpportunitiesSelector = ({ defaultOption, onSelect }: SelectorProps) => {
  const internalDefaultOption = defaultOption
    ? convertOpportunityToOption(defaultOption)
    : undefined;

  return (
    <AsyncSelect
      label="Outlet"
      defaultValue={internalDefaultOption}
      loadOptions={debounce((search: string, callback: (options: OptionType[]) => void) => {
        showcasingApi
          .getOpportunities({ search })
          .then(({ data: opportunities }) => {
            return opportunities.map(convertOpportunityToOption) as OptionType[];
          })
          .then(callback)
          .catch(console.error);
      }, 500)}
      onChange={(option) => onSelect(convertOptionToOpportunity(option))}
      placeholder="Select..."
      isClearable
      defaultOptions
    />
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default ShowcasingOpportunitiesSelector;
