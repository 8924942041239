import { MentorProfileId } from '@polygence/common';
import { useGetMentorAvailabilitiesQuery } from '@polygence/common/api/mentor-availability';
import { ProjectType } from '@polygence/common/types/hermes';
import { Heading, Spacer, Text } from '@polygence/components';

import { Loader } from 'src/components/Loader';
import {
  MentorAvailability,
  PremiumShowcasingAvailability,
} from 'src/components/mentor/Availability/MentorAvailability';

const PREMIUM_SHOWCASING_PROJECT_TYPES: string[] = [
  ProjectType.PREMIUM_SHOWCASING_PUBLISHING,
  ProjectType.PREMIUM_SHOWCASING_PRESENTING,
  ProjectType.PREMIUM_SHOWCASING_COMPETING,
  ProjectType.PREMIUM_SHOWCASING_MULTIMEDIA,
];

export const AllMentorAvailabilities = ({
  mentorProfileId,
}: {
  mentorProfileId: MentorProfileId;
}) => {
  const { data: mentorAvailabilities, isLoading } = useGetMentorAvailabilitiesQuery({
    mentorProfileId,
  });

  if (isLoading) {
    return <Loader />;
  }

  if (!mentorAvailabilities) {
    return null;
  }

  const displayedAvailabilities = mentorAvailabilities.filter((mentorAvailability) => {
    return !PREMIUM_SHOWCASING_PROJECT_TYPES.includes(mentorAvailability.projectType.value);
  });

  const pssAvailabilities = mentorAvailabilities.filter((mentorAvailability) =>
    PREMIUM_SHOWCASING_PROJECT_TYPES.includes(mentorAvailability.projectType.value),
  );

  return (
    <>
      <Heading size="h5" alignment="left" className="tw-mb-1">
        Availability
      </Heading>
      <Text size={{ base: 'small', md: 'medium' }}>
        Which project type{mentorAvailabilities?.length > 1 ? 's' : ''} are you available to mentor
        students?
      </Text>
      <Spacer size={2} />
      {displayedAvailabilities &&
        displayedAvailabilities.length > 0 &&
        displayedAvailabilities.map((mentorAvailability) => (
          <MentorAvailability
            key={mentorAvailability.id}
            mentorProfileId={mentorProfileId}
            mentorAvailability={mentorAvailability}
            skipCapacity={mentorAvailability.projectType.value !== ProjectType.FLAGSHIP}
            condensed
          />
        ))}
      {pssAvailabilities && pssAvailabilities.length > 0 && (
        <PremiumShowcasingAvailability mentorAvailabilities={pssAvailabilities} />
      )}
    </>
  );
};
