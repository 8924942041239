import PropTypes from 'prop-types';
import React, { Children } from 'react';

/* eslint-disable-next-line react/function-component-definition, import/no-default-export -- autodisabled, autodisabled */
export default function SeparatorInserterCaptain({
  children,
  separator = ', ',
  lastSeparator = ' and ',
}) {
  const childrenArray = Children.toArray(children);
  if (childrenArray.length <= 1) {
    // eslint-disable-next-line react/jsx-filename-extension -- autodisabled
    return <>{children}</>;
  }
  return (
    <>
      {childrenArray.map((child, index, array) => {
        if (index < array.length - 2) {
          return (
            // eslint-disable-next-line react/jsx-key -- autodisabled
            <>
              {child}
              {separator}
            </>
          );
        }
        if (index === array.length - 2) {
          return (
            // eslint-disable-next-line react/jsx-key -- autodisabled
            <>
              {child}
              {lastSeparator}
            </>
          );
        }
        return child;
      })}
    </>
  );
}

SeparatorInserterCaptain.propTypes = {
  children: PropTypes.arrayOf(PropTypes.any),
  separator: PropTypes.string,
  lastSeparator: PropTypes.string,
};
