import type { StudentJourneyLaunchpadProject } from '@polygence/common';
import { Avatar, Badge, Icon, Text } from '@polygence/components';
import classNames from 'classnames';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { CompletedAvatarOverlay } from 'src/components/Tiles/StudentJourney/CompletedAvatarOverlay';
import styles from 'src/components/Tiles/StudentJourney/launchpad/LaunchpadJourney.module.scss';
import { dayjs } from 'src/utils/dayjsCustom';
import { getTracker } from 'src/utils/tracking/factory';

export const ActiveLaunchpadProjectJourneyTile = ({
  data,
}: {
  data: StudentJourneyLaunchpadProject;
}) => {
  const history = useHistory();
  const tracker = getTracker();

  if (!data || data.status === 'under_matching') {
    return null;
  }

  const { status, subject, workspaceId, mentor, upcomingSession } = data;

  const handleGoToWorkspace = () => {
    tracker.track('Go To Workspace', {
      category: 'student_journey_tile',
      workspaceId: workspaceId,
    });
    history.push(`/dashboard/hermes/${workspaceId}`);
  };

  return (
    <div
      className={classNames(
        'd-flex flex-column gap-4 align-items-center',
        styles['launchpadProjectContainer'],
      )}
    >
      {mentor && (
        <div className="d-flex flex-column align-items-center">
          <CompletedAvatarOverlay show={status === 'completed'}>
            <Avatar
              size={36}
              user={{
                firstName: mentor.firstName,
                lastName: mentor.lastName,
                profilePicture: mentor.profilePicture,
              }}
            />
          </CompletedAvatarOverlay>
          <Text size="small" className="mt-2">{`${mentor.firstName} ${mentor.lastName}`}</Text>
        </div>
      )}
      {subject && (
        <Text alignment="center" fontWeight="bold" size="small" className="mb-auto">
          {subject}
        </Text>
      )}
      {status === 'terminated' && <Badge variant="danger">TERMINATED</Badge>}
      {status === 'completed' && <Badge variant="success">COMPLETED</Badge>}
      {status === 'active' && upcomingSession && (
        <div className="d-flex flex-column align-items-center gap-1">
          {upcomingSession.scheduledAt ? (
            <>
              <div className="hstack align-self-center">
                <Text size="small" alignment="center" fontWeight="normal">
                  <Icon id="calendar" size="xs" className="me-1" />
                  {dayjs(upcomingSession.scheduledAt).format('ddd, MMM D')}
                </Text>
              </div>
              <Text size="small" alignment="center" fontWeight="normal">
                {dayjs(upcomingSession.scheduledAt).format('LT')}
              </Text>
            </>
          ) : (
            <Badge variant="warning">NOT SCHEDULED</Badge>
          )}
        </div>
      )}
      {workspaceId && (
        <Button
          size="sm"
          variant={status === 'active' ? 'primary' : 'secondary'}
          onClick={handleGoToWorkspace}
        >
          Workspace
        </Button>
      )}
    </div>
  );
};
