import { Card, Text, Button, Spacer } from '@polygence/components';

import styles from 'src/students/Pods/PodsFinalSurveyCard.module.scss';

export const UpsellCard = ({
  text,
  buttonLabel,
  imgSrc,
  redirect,
}: {
  text: string;
  buttonLabel: string;
  imgSrc: string;
  redirect: {
    href: string;
    target?: string;
    rel?: string;
  };
}) => {
  return (
    <Card className="h-100">
      <img src={imgSrc} alt={buttonLabel} className={styles['image']} />
      <div className="p-8 d-flex flex-grow-1 flex-column align-items-start justify-content-between">
        <Text size="large" fontWeight="bold">
          {text}
        </Text>
        <Spacer size={6} />
        <Button variant="tertiary" {...redirect}>
          {buttonLabel}
        </Button>
      </div>
    </Card>
  );
};
