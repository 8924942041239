import useSelectedWorkspace from './useSelectedWorkspace';

// eslint-disable-next-line import/no-default-export -- autodisabled
export default function useSelectedRoomId(): number | undefined {
  const workspace = useSelectedWorkspace();
  if (workspace == null) {
    return undefined;
  }

  return workspace.roomId;
}
