import React from 'react';

const FormContext = React.createContext<{ name: string | null }>({
  name: null,
});

export const FormContextProvider = ({
  children,
  name,
}: {
  children: React.ReactNode;
  name: string;
}) => {
  const value = React.useMemo(() => ({ name }), [name]);
  return <FormContext.Provider value={value}>{children}</FormContext.Provider>;
};

export const useFormContext = () => {
  return React.useContext(FormContext);
};
