import { getRestClient } from '../commonSettings';
import type { Certificate } from '../types/certificates';
import type { CertificateUUID, WorkspaceId } from '../types/common';

export const getCertificateOfProjectCompletion = (certificateUuid: CertificateUUID) => {
  const internalFetch = getRestClient();
  return internalFetch.get<Certificate>(`/certificates/${certificateUuid}/`);
};

export const getCertificateForStudent = (workspaceId: WorkspaceId) => {
  const internalFetch = getRestClient();
  return internalFetch.get<CertificateUUID>(
    `/certificates/workspaces/${workspaceId}/certificate-id/`,
  );
};
