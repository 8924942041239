import classNames from 'classnames';
import { FORMAT_TEXT_COMMAND } from 'lexical';
import type { FC } from 'react';

import { Icon } from 'src/components/Icon';
import {
  useToolbarContext,
  BlockType,
} from 'src/components/LexicalEditor/plugins/toolbar/ToolbarContext';

const Strikethrough: FC = () => {
  const { editor, isStrikethrough, blockType } = useToolbarContext();

  return (
    <button
      type="button"
      title="Strikethrough"
      onClick={() => {
        editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough');
      }}
      className={classNames('toolbar-item', { active: isStrikethrough })}
      aria-label="Format Strikethrough"
      disabled={blockType === BlockType.code}
    >
      <Icon name="strikethrough" local color="currentColor" size="24px" />
    </button>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default Strikethrough;
