import about from 'src/components/Tiles/tile-illustrations/tile_about.svg';
import blog from 'src/components/Tiles/tile-illustrations/tile_blog.svg';
import coreApplication from 'src/components/Tiles/tile-illustrations/tile_core-application.svg';
import counselorHome from 'src/components/Tiles/tile-illustrations/tile_counselor-home.svg';
import guides from 'src/components/Tiles/tile-illustrations/tile_guides.svg';
import mentorAvailability from 'src/components/Tiles/tile-illustrations/tile_mentor-availability.svg';
import mentorJobBoard from 'src/components/Tiles/tile-illustrations/tile_mentor-job-board.svg';
import mentors from 'src/components/Tiles/tile-illustrations/tile_mentors.svg';
import messages from 'src/components/Tiles/tile-illustrations/tile_messages.svg';
import mobileScreenshot from 'src/components/Tiles/tile-illustrations/tile_mobile-screenshot.svg';
import myChildren from 'src/components/Tiles/tile-illustrations/tile_my-children.svg';
import myProjectsAstronaut from 'src/components/Tiles/tile-illustrations/tile_my-projects-astronaut.svg';
import myStudentsGraduation from 'src/components/Tiles/tile-illustrations/tile_my-students-graduation.svg';
import myStudents from 'src/components/Tiles/tile-illustrations/tile_my-students.svg';
import onboardingHub from 'src/components/Tiles/tile-illustrations/tile_onboarding-hub.svg';
import pastprojects from 'src/components/Tiles/tile-illustrations/tile_pastprojects.svg';
import pathfinders from 'src/components/Tiles/tile-illustrations/tile_pathfinders.svg';
import pods from 'src/components/Tiles/tile-illustrations/tile_pods.svg';
import program from 'src/components/Tiles/tile-illustrations/tile_program.svg';
import reapply from 'src/components/Tiles/tile-illustrations/tile_reapply.svg';
import referral from 'src/components/Tiles/tile-illustrations/tile_referral.svg';
import rocket from 'src/components/Tiles/tile-illustrations/tile_rocket.svg';
import scholarPage from 'src/components/Tiles/tile-illustrations/tile_scholar-page.svg';
import scholarpage from 'src/components/Tiles/tile-illustrations/tile_scholarpage.svg';
import selfie from 'src/components/Tiles/tile-illustrations/tile_selfie.svg';
import settings from 'src/components/Tiles/tile-illustrations/tile_settings.svg';
import showcases from 'src/components/Tiles/tile-illustrations/tile_showcases.svg';
import symposium from 'src/components/Tiles/tile-illustrations/tile_symposium.svg';
import talkTime from 'src/components/Tiles/tile-illustrations/tile_talk-time.svg';

const tileIllustrations = {
  about,
  blog,
  'counselor-home': counselorHome,
  'core-application': coreApplication,
  guides,
  mentors,
  'mentor-availability': mentorAvailability,
  messages,
  'my-children': myChildren,
  'my-students': myStudents,
  pastprojects,
  program,
  reapply,
  referral,
  rocket,
  showcases,
  'talk-time': talkTime,
  scholarpage,
  'scholar-page': scholarPage,
  selfie: selfie,
  'symposium-hub-for-mentors': symposium,
  'symposium-hub-for-students': symposium,
  settings,
  pods,
  pathfinders,
  'my-students-graduation': myStudentsGraduation,
  'my-projects-astronaut': myProjectsAstronaut,
  'mobile-screenshot': mobileScreenshot,
  'mentor-job-board': mentorJobBoard,
  'onboarding-hub': onboardingHub,
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default tileIllustrations;
