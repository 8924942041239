import type { TimeSlot } from '@polygence/common/types/hermes';
import { Icon, Spacer, Text, ToggleSwitch, Tooltip } from '@polygence/components';

import { useAutoScheduleSetting } from 'src/components/usersettings/AutoScheduleSetting/useAutoScheduleSetting';

interface AutoScheduleSettingProps {
  isAutoAccepting: boolean;
  timeSlots: TimeSlot[] | undefined;
}

export const AutoScheduleSetting = ({ isAutoAccepting, timeSlots }: AutoScheduleSettingProps) => {
  const { isAutoScheduleChecked, isAutoScheduleEnabled, handleAutoScheduleChange } =
    useAutoScheduleSetting(isAutoAccepting, timeSlots);

  return (
    <Tooltip
      tip="You must enable Working Hours and connect a Google calendar to enable auto-acceptance of student session proposals."
      placement="right"
      trigger={isAutoScheduleEnabled ? [] : ['hover', 'focus']}
    >
      <div className="d-flex align-items-center justify-content-between p-6 bg-grayscale-3 border base-border-radius">
        <Icon id="calendar" size="lg" color="var(--grayscale-6)" />
        <Spacer size={4} />
        <div className="d-flex-column align-items-center gap-5">
          <Text size="medium" fontWeight="semibold" alignment="left">
            Auto-accept the session scheduling proposals from students you're matched with
          </Text>
          <Text size="small">
            (Students can only schedule sessions more than 24 hours in the future)
          </Text>
        </div>
        <ToggleSwitch
          aria-label="enable accepting scheduling proposals automatically"
          checked={isAutoScheduleChecked}
          disabled={!isAutoScheduleEnabled}
          onCheckedChange={handleAutoScheduleChange}
        />
      </div>
    </Tooltip>
  );
};
