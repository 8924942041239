import { commonHooks, commonReducers } from '@polygence/common';
import type {
  RequiredStudentInformation,
  StudentRelatedPod,
} from '@polygence/common/types/data/pods';
import { PaymentIntentStatuses } from '@polygence/common/types/payment';
import { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { toast } from 'react-toastify';

import { PageLoad } from 'src/components/PageLoad';
import { CART_COOKIE_NAME, STUDENT_TEAM_EMAIL } from 'src/constants';
import { useCreatePaymentIntentForPodMutation } from 'src/reducers/paymentReducer';
import {
  useRegisterStudentToPodFromCookieMutation,
  useGetListOfStudentRelatedPodsQuery,
  useGetRequiredStudentInformationQuery,
  useGetTokenCountQuery,
} from 'src/reducers/podsReducers';
import { useAppDispatch } from 'src/store';
import { PodFullPage } from 'src/students/Pods/PodFullPage';
import { PodHubPage } from 'src/students/Pods/PodHubPage';
import { RequiredPodInformation } from 'src/students/Pods/RequiredPodInformation';
import { getCookie } from 'src/utils';

const validateRequiredInfos = (infos: RequiredStudentInformation) => {
  return (
    infos.isParentEmailMissing ||
    infos.isReferralMissing ||
    infos.isSchoolMissing ||
    (infos.isUpsellStudent && infos.isPodsReferralMissing)
  );
};

export const PodPage = () => {
  const dispatch = useAppDispatch();
  const currentUser = commonHooks.useCurrentUser();
  const [isRequiredInfoMissing, setIsRequiredInfoMissing] = useState(true);
  const [createPaymentIntentForPod] = useCreatePaymentIntentForPodMutation();
  const { data: pods, isFetching: isPodsLoading } = useGetListOfStudentRelatedPodsQuery();
  const { data: requiredInfo, refetch: refetchRequiredInfo } =
    useGetRequiredStudentInformationQuery();
  const [registerStudentToPod, { isLoading: isRegisterLoading }] =
    useRegisterStudentToPodFromCookieMutation();
  const mostRecentPod = pods?.[pods.length - 1];
  const { data: { count: availableTokenCount = 0 } = {} } = useGetTokenCountQuery();
  const hasAvailableToken = availableTokenCount > 0;

  const hasMultipleOrEnrolled = (pods: StudentRelatedPod[]) => {
    return pods.length === 1 ? pods[0]?.isEnrolled : true;
  };

  const handleRequiredInfoSubmit = () => {
    refetchRequiredInfo().catch(console.error);
    if (!hasAvailableToken) {
      createPaymentIntentForPod().catch(() =>
        toast.error(`Couldn't create invoice. Please contact support! ${STUDENT_TEAM_EMAIL}`),
      );
    }
    return Promise.resolve(null);
  };

  useEffect(() => {
    const podCookie = getCookie(CART_COOKIE_NAME);
    if (podCookie) {
      registerStudentToPod()
        .then(() => {
          dispatch(
            commonReducers.userActions.updateUser({
              otherInfo: { ...currentUser.otherInfo, isInterestedInPod: true },
            }),
          );
        })
        .catch(() =>
          toast.error(
            `Couldn't handle your registration. Please contact support! ${STUDENT_TEAM_EMAIL}`,
          ),
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerStudentToPod, dispatch]);

  useEffect(() => {
    if (!requiredInfo) {
      return;
    }
    setIsRequiredInfoMissing(validateRequiredInfos(requiredInfo));
  }, [requiredInfo, createPaymentIntentForPod]);

  if (isPodsLoading || isRegisterLoading) {
    return <PageLoad />;
  }

  if (!mostRecentPod) {
    return <PodHubPage pods={[]} tokenCount={availableTokenCount} />;
  }

  if (mostRecentPod && hasAvailableToken && !isRequiredInfoMissing) {
    return <PodHubPage pods={pods} tokenCount={availableTokenCount} />;
  }

  if (
    mostRecentPod &&
    !mostRecentPod.isEnrolled &&
    requiredInfo &&
    ((!mostRecentPod.paymentIntent && mostRecentPod.pod.active) || isRequiredInfoMissing)
  ) {
    return (
      <RequiredPodInformation
        title={mostRecentPod.pod.course.title}
        startDate={mostRecentPod.pod.startDate}
        requiredInfo={requiredInfo}
        handleRequiredInfoSubmit={handleRequiredInfoSubmit}
        submitLabel={hasAvailableToken ? 'Submit' : 'Proceed to payment'}
      />
    );
  }

  if (hasMultipleOrEnrolled(pods)) {
    return <PodHubPage pods={pods} tokenCount={availableTokenCount} />;
  }

  if (mostRecentPod.pod.isPodFull) {
    return (
      <PodFullPage startDate={mostRecentPod.pod.startDate} title={mostRecentPod.pod.course.title} />
    );
  }

  if (
    mostRecentPod.paymentIntent &&
    (mostRecentPod.paymentIntent.status === PaymentIntentStatuses.REQUESTED ||
      mostRecentPod.paymentIntent.status === PaymentIntentStatuses.PENDING)
  ) {
    return <Redirect to={`/payment/${mostRecentPod.paymentIntent.uuid}`} />;
  }

  return <PodHubPage pods={pods} tokenCount={availableTokenCount} />;
};
