import { ProjectType } from '@polygence/common';
import type { UserId, ProjectTypes } from '@polygence/common';
import { axiosBaseQuery } from '@polygence/common/api/fetch';
import type { FormName } from '@polygence/common/types/data/survey';
import type { EditableForm, DemographyFormTemplateType } from '@polygence/common/types/survey';
import { createApi } from '@reduxjs/toolkit/query/react';

export const surveyApi = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: 'survey',
  endpoints: (build) => {
    return {
      getDemographyData: build.query<Record<string, unknown>, UserId>({
        query: (userId) => {
          return `survey/users/${userId}/demography-data/`;
        },
      }),
      submitDemographyData: build.mutation<void, { userId: UserId; data: Record<string, unknown> }>(
        {
          query: ({ userId, data }) => {
            return {
              url: `survey/users/${userId}/demography-data/`,
              method: 'PATCH',
              data: data,
            };
          },
        },
      ),
      getFormTemplate: build.query<DemographyFormTemplateType, FormName>({
        query: (formName) => {
          return `/survey/forms/${formName}/`;
        },
      }),
      getFinalSurveyForm: build.query<EditableForm, ProjectTypes>({
        query: (projectType) => {
          if (projectType === ProjectType.PATHFINDER_DEPRECATED) {
            return '/survey/forms/pathfinder_student_final_survey/';
          } else {
            return '/survey/forms/student_final_survey/';
          }
        },
      }),
      submitCompetingPremiumShowcasingMatchingSurvey: build.mutation<
        void,
        { data: Record<string, unknown> }
      >({
        query: ({ data }) => {
          return {
            url: `/survey/premium-showcasing-matching-survey/competing/`,
            method: 'POST',
            data,
          };
        },
      }),
      getPremiumShowcasingMatchingSurvey: build.query<{ id: number }, void>({
        query: () => {
          return {
            url: `/survey/premium-showcasing-matching-survey/competing/`,
            method: 'GET',
          };
        },
      }),
    };
  },
});

export const {
  useGetDemographyDataQuery,
  useGetFormTemplateQuery,
  useGetFinalSurveyFormQuery,
  useSubmitDemographyDataMutation,
  useSubmitCompetingPremiumShowcasingMatchingSurveyMutation,
  useGetPremiumShowcasingMatchingSurveyQuery,
} = surveyApi;
