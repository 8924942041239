import type { CamelCasedPropertiesDeep } from 'type-fest';

import type {
  ChannelMedal,
  DateTime,
  ProductId,
  StudentApplicationId,
  StudentProfileId,
  UserId,
  UUID,
} from '../types/common';
import type { Nullable } from '../types/utils';

import type { MentorRequestStatus, UserCardPartnerTag } from './data/marketplace';
import type {
  AcceptedStudentApplicationSerializer,
  PartnerShipArrangementDiscountSerializer,
  ProductSerializer,
} from './data/studentApplication';

export const StudentApplicationStatuses = {
  CREATED: 'created',
  COMPLETED: 'completed',
  ACCEPTED: 'accepted',
  DECLINE_PENDING: 'decline_pending',
  DECLINED: 'declined',
  ARCHIVED: 'archived',
} as const;

export const AdmissionsReviewStatuses = {
  APP_COMPLETED: 'app_completed',
  WORKSHOPPING: 'workshopping',
  READY_FOR_REVIEW: 'ready_for_review',
} as const;

export type AdmissionsReviewStatusType =
  (typeof AdmissionsReviewStatuses)[keyof typeof AdmissionsReviewStatuses];

export type PartnerShipArrangementDiscount =
  CamelCasedPropertiesDeep<PartnerShipArrangementDiscountSerializer>;

export type Product = CamelCasedPropertiesDeep<ProductSerializer>;

export type AcceptedStudentApplication =
  CamelCasedPropertiesDeep<AcceptedStudentApplicationSerializer>;

export type ExpectedOutput =
  | 'research_paper'
  | 'github_code_base'
  | 'creative_writing'
  | 'computer_simulation'
  | 'opinion_editorial'
  | 'infographic'
  | 'video_or_docu_series'
  | 'webinar'
  | 'podcast'
  | 'organization'
  | 'art_work_or_portfolio'
  | 'science_fair_project'
  | 'app_game'
  | 'unsure';

export type ExpectedProjectOutcomes =
  | 'journal'
  | 'online'
  | 'podcast'
  | 'science_fair'
  | 'conference'
  | 'unsure';

export type GeneralInterest = `subject_${GeneralInterestWithoutPrefix}`;
export type GeneralInterestWithoutPrefix =
  | 'ai'
  | 'fine_arts'
  | 'biology'
  | 'law'
  | 'engineering'
  | 'economics'
  | 'neuroscience'
  | 'psychology'
  | 'physics'
  | 'other';

export interface CompletedSection {
  section: string;
  completedAt: DateTime;
}

export interface StudentApplication {
  id: StudentApplicationId;
  student: StudentProfileId;
  status: (typeof StudentApplicationStatuses)[keyof typeof StudentApplicationStatuses];
  admissionAdvisorCalendlyUrl: Nullable<string>;
  cartUuid: Nullable<UUID>;
  completedSections: CompletedSection[];
  computerScienceLanguageProficiencies: string;
  computerScienceCompletedProgramsApCompSciA: boolean;
  computerScienceCompletedProgramsApCompSciPrinciples: boolean;
  computerScienceCompletedProgramsAi: boolean;
  computerScienceCompletedProgramsAiDetail: string;
  computerScienceCompletedProgramsUsaco: boolean;
  computerScienceCompletedProgramsUsacoDetail: string;
  expectedOutput: ExpectedOutput;
  expectedProjectOutcome: ExpectedProjectOutcomes;
  firstPaymentReceivedAt: Nullable<string>;
  mentorRequestStatus: MentorRequestStatus;
  generalInterest: string;
  intendToDoCs: boolean;
  intendToDoSciFair: boolean;
  interests: string;
  openaiSuggestions: {
    mustHaveTags?: { name: string; slug: string }[];
    keywords?: { label: string; value: number }[];
  };
  outputResearchPaper: boolean;
  outputGithubCodeBase: boolean;
  outputCreativeWriting: boolean;
  outputComputerSimulation: boolean;
  outputOpinionEditorial: boolean;
  outputInfographic: boolean;
  outputVideoOrDocuSeries: boolean;
  outputWebinar: boolean;
  outputPodcast: boolean;
  outputOrganization: boolean;
  outputArtWorkOrPortfolio: boolean;
  outputScienceFairProject: boolean;
  outputAppGame: boolean;
  outputUnsure: boolean;
  partnershipArrangement: PartnerShipArrangementDiscount;
  paymentIntentUuid: Nullable<UUID>;
  podcastName: string;
  relevantExperience: string;
  relevantExperienceExample: string;
  scholarshipApplicant: boolean;
  financialAidElaboration: string;
  financialAidDiscount: number;
  selectedProducts: ProductId[];
  scienceExperimentCanConductFromHome: Nullable<boolean>;
  scienceExperimentConsidersOtherOption: boolean;
  scienceExperimentDescription: string;
  scienceExperimentLocation: string;
  scienceExperimentMaterials: string;
  subjectBiology: boolean;
  subjectEconomics: boolean;
  subjectEngineering: boolean;
  subjectFineArts: boolean;
  subjectLaw: boolean;
  subjectOther: boolean;
  hasProjectIdea: Nullable<boolean>;
  interestedInLaunchpad: Nullable<boolean>;
  interestedInPss: boolean;
  timeCommitment: string;
  timelineStartAt: Nullable<string>;
  timelineEndAt: Nullable<string>;
  virtualInterviewSubmittedAt: Nullable<string>;
  virtualInterviewReceivedAt: Nullable<string>;
  virtualInterviewResponse: Nullable<string>;
  virtualInterviewUrl: Nullable<string>;
  completedAt: Nullable<string>;
  acceptedAt: Nullable<string>;
  declinePendingAt: Nullable<string>;
  declinedAt: Nullable<string>;
  archivedAt: Nullable<string>;
  createdAt: string;
  updatedAt: string;
  reviewNote: string;
  aaCallScheduledAt: DateTime;
  aaCallEndAt: DateTime;
  aaCallReschedulingUrl: string;
  aaCallCanceled: boolean;
  admissionsReviewStatus: AdmissionsReviewStatusType;
  projectPivot: boolean;
  projectPivotDescription: string;
}

export interface StudentOverviewStudentApplication extends StudentApplication {
  applicationStatus: {
    enrolledAt: Nullable<string>;
    introducedToMentorAt: Nullable<string>;
  };
}

export interface StudentCohortPipeline {
  cohortName: string;
  cohortStartedAt: string;
  cohortDeadline: string;
  studentProfileId: StudentProfileId;
  studentApplicationId: StudentApplicationId;
}

export interface StudentWithChannel {
  channelMedal: ChannelMedal;
  channel: string;
}

export interface StudentProfilePipeline extends StudentWithChannel {
  id: StudentProfileId;
  userId: UserId;
  userStatus: string;
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
  phoneNumber: string;
  admissionAdvisor: Nullable<{ pk: UserId; firstName: string; lastName: string }>;
  hsDealForAdmissionAdvisorWorkflow: boolean;
  consultativeCallHappened: boolean;
  parents: { name: string; email: string; phone: string }[];
  usherer: { name: string };
  declineDetails: {
    note: string;
    decliningUser: UserId;
    previousStatus: string;
    declinedAt: string;
  };
}

export interface StudentApplicationPipeline {
  id: StudentApplicationId;
  status: (typeof StudentApplicationStatuses)[keyof typeof StudentApplicationStatuses];
  student: StudentProfilePipeline;
  cohortApplication: StudentCohortPipeline;
  scholarshipApplicant: boolean;
  virtualInterviewReceivedAt: string;
  virtualInterviewRequired: boolean;
  virtualInterviewSubmittedAt: string;
  virtualInterviewUrl: string;
  acceptedAt: string;
  archivedAt: string;
  completedAt: string;
  declinePendingAt: string;
  declinedAt: string;
  reviewNote: string;
  applicationUnhandled: boolean;
  aaCallScheduledAt: DateTime;
  admissionsReviewStatus: AdmissionsReviewStatusType;
  cartUuid: Nullable<UUID>;
  partnerTags: UserCardPartnerTag[];
}

export interface ProjectIdea {
  id: number;
  title: string;
  description: string;
}
