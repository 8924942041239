import type { ProjectLink } from '@polygence/common/types/marketplace';

import { ProjectLinkCard } from 'src/components/student/ScholarPage/ProjectLink/ProjectLinkCard';
import styles from 'src/components/student/ScholarPage/ProjectLink/projectLink.module.sass';

export const ProjectLinkGrid = ({ projectLinks }: { projectLinks: Array<ProjectLink> }) => {
  return (
    <div className={styles['projectLinkGrid']}>
      {projectLinks.map((link: ProjectLink) => {
        const outcomeFileLink = link?.permOutcomeFile?.[0]?.url ?? '';
        return (
          <ProjectLinkCard
            key={link.url}
            url={link.url ? link.url : outcomeFileLink}
            outcomeTypeDetails={link.outcomeTypeDetails}
          />
        );
      })}
    </div>
  );
};
