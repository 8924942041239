import { GeneralInterest } from '@polygence/common/types/studentApplication';
import shuffle from 'lodash/shuffle';

export type GeneralInterestIllustration =
  | 'InterestEngineering'
  | 'InterestChemistry'
  | 'InterestFinance'
  | 'InterestNeuroscience'
  | 'InterestPsychology'
  | 'InterestAppDev'
  | 'InterestFineArts'
  | 'InterestLaw'
  | 'InterestLightBulb'
  | 'InterestPhysics';

export interface GeneralInterestOption {
  value: GeneralInterest;
  label: string;
  illustration: GeneralInterestIllustration;
}

const shuffledGeneralInterests: readonly GeneralInterestOption[] = shuffle([
  {
    value: 'subject_ai',
    label: 'AI, Machine Learning',
    illustration: 'InterestEngineering',
  },
  {
    value: 'subject_biology',
    label: 'Biomedical, Biology, Chemistry',
    illustration: 'InterestChemistry',
  },
  {
    value: 'subject_economics',
    label: 'Business, Finance, Economics',
    illustration: 'InterestFinance',
  },
  {
    value: 'subject_neuroscience',
    label: 'Neuroscience',
    illustration: 'InterestNeuroscience',
  },
  {
    value: 'subject_psychology',
    label: 'Psychology',
    illustration: 'InterestPsychology',
  },
  {
    value: 'subject_engineering',
    label: 'Computer Science, App Development',
    illustration: 'InterestAppDev',
  },
  {
    value: 'subject_fine_arts',
    label: 'Fine Arts, Fashion, Architecture, Music, Visual Arts, Writing',
    illustration: 'InterestFineArts',
  },
  {
    value: 'subject_law',
    label: 'Law, Government, Politics, History, Literature',
    illustration: 'InterestLaw',
  },
  { value: 'subject_physics', label: 'Physics', illustration: 'InterestPhysics' },
]);

export const generalInterestOptions: readonly GeneralInterestOption[] = [
  ...shuffledGeneralInterests,
  {
    value: 'subject_other',
    label: 'Other',
    illustration: 'InterestLightBulb',
  },
];
