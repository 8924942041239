import type { SelectOption, StudentProfileId, UUID } from '@polygence/common';
import * as marketplaceApi from '@polygence/common/api/marketplace';
import {
  type StudentCollegeChoiceStatus,
  type CollegeStatusOption,
  type StudentCollegeChoiceSchoolLevels,
  studentCollegeChoiceSchoolLevelsOptions,
  studentCollegeChoiceStatusOptions,
} from '@polygence/common/types/data/marketplace';
import type { InstitutionPublic, StudentCollegeChoice } from '@polygence/common/types/marketplace';
import { AsyncSelect, Checkbox, InputField, Select, Spacer, Text, cn } from '@polygence/components';
import type { OptionType } from '@polygence/components';
import { debounce } from 'lodash';
import { useCallback } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import type { DropResult } from 'react-beautiful-dnd';
import { createFilter } from 'react-select';
import { toast } from 'react-toastify';

import { Icon } from 'src/components/Icon';
import { DEFAULT_TOP_COLLEGES_QUESTION } from 'src/components/usersettings/constants';
import {
  useGetTopCollegesQuery,
  useUpdateTopCollegesMutation,
} from 'src/reducers/marketplaceReducer';

const INSTITUTION_LOAD_LIMIT = 100;

const statusToStatusOption = (status: StudentCollegeChoiceStatus) => {
  return studentCollegeChoiceStatusOptions.find(({ value }) => value === status);
};

const toLevelOfSchoolingOption = (level: StudentCollegeChoiceSchoolLevels) => {
  return studentCollegeChoiceSchoolLevelsOptions.find(({ value }) => value === level);
};

const reorder = (list: StudentCollegeChoice[], startIndex: number, endIndex: number) => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  if (removed) {
    result.splice(endIndex, 0, removed);
  }

  return result;
};

const searchInstitutions = debounce(
  (savedIds: number[], inputValue: string, callback: (options: OptionType[]) => void) => {
    marketplaceApi
      .getInstitutions({ search: inputValue, limit: INSTITUTION_LOAD_LIMIT })
      .then(({ data }) => {
        const filtered = data.results.filter((institution) => !savedIds.includes(institution.id));
        return callback(filtered);
      })
      .catch(console.error);
  },
  300,
);

interface TopCollegesSelectorProps {
  profileId?: StudentProfileId;
  profileUuid?: UUID;
  stage?: string;
  title?: string;
  setSaving?: (saving: boolean) => void;
}

export const TopCollegesSelector = ({
  profileId,
  profileUuid,
  stage,
  setSaving,
  title = DEFAULT_TOP_COLLEGES_QUESTION,
}: TopCollegesSelectorProps) => {
  const { currentData: topColleges, isLoading } = useGetTopCollegesQuery({
    profileId,
    profileUuid,
  });
  const [updateTopColleges] = useUpdateTopCollegesMutation();
  const updateWithCallback = useCallback(
    (payload: StudentCollegeChoice[]) => {
      setSaving?.(true);
      updateTopColleges({
        profileId,
        profileUuid,
        payload,
      })
        .catch(() => toast.error("Couldn't update college choice."))
        .finally(() => setSaving?.(false));
    },
    [setSaving, updateTopColleges, profileId, profileUuid],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdate = useCallback(debounce(updateWithCallback, 500), [updateWithCallback]);

  const onSelectChange = (college: InstitutionPublic) => {
    if (!topColleges) {
      return;
    }

    const getNewState = () => {
      return [
        ...topColleges,
        {
          college,
          status: studentCollegeChoiceStatusOptions[0].value,
          statusLabel: studentCollegeChoiceStatusOptions[0].label,
          rank: topColleges.length + 1,
          stage: stage ?? '',
          earlyAction: false,
          earlyDecision: false,
          gotScholarship: false,
          fullScholarship: false,
          scholarshipAmount: 0,
          major: '',
          levelOfSchooling: '' as StudentCollegeChoiceSchoolLevels,
        },
      ];
    };
    updateWithCallback(getNewState());
  };

  const onStatusChange = (id: number, statusOption: CollegeStatusOption) => {
    if (!topColleges) {
      return;
    }

    const getNewState = () => {
      const index = topColleges.findIndex((studentCollegeChoice) => {
        return studentCollegeChoice.college.id === id;
      });
      const before = topColleges.slice(0, index);
      const toUpdate = topColleges[index];
      const after = topColleges.slice(index + 1);

      if (!toUpdate) {
        return topColleges;
      }

      const updated = {
        ...toUpdate,
        status: statusOption.value,
        rank: index + 1,
        stage: stage ?? '',
      };
      return [...before, updated, ...after];
    };
    updateWithCallback(getNewState());
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination || !topColleges) {
      return;
    }

    updateWithCallback(reorder(topColleges, result.source.index, result.destination.index));
  };

  const remove = (collegeId: number) => {
    if (!topColleges) {
      return;
    }

    const getNewState = () => {
      return topColleges.filter((collegeChoice) => {
        return collegeChoice.college.id !== collegeId;
      });
    };
    updateWithCallback(getNewState());
  };

  const handleCheckboxChange = (id: number, event: React.ChangeEvent<HTMLInputElement>) => {
    if (!topColleges) {
      return;
    }

    const getNewState = () => {
      const index = topColleges.findIndex((studentCollegeChoice) => {
        return studentCollegeChoice.college.id === id;
      });

      const before = topColleges.slice(0, index);
      const toUpdate = topColleges[index];
      const after = topColleges.slice(index + 1);

      if (!toUpdate) {
        return topColleges;
      }

      const updated = {
        ...toUpdate,
        rank: index + 1,
        stage: stage ?? '',
        [event.target.name]: event.target.checked,
      };
      return [...before, updated, ...after];
    };
    updateWithCallback(getNewState());
  };

  const handleInputChange = (id: number, event: React.ChangeEvent<HTMLInputElement>) => {
    if (!topColleges) {
      return;
    }

    if (Number(event.target.value) > 1000000) {
      toast.error('Scholarship amount cannot exceed $1,000,000.');
      return event.target.value;
    }

    const getNewState = () => {
      const index = topColleges.findIndex((studentCollegeChoice) => {
        return studentCollegeChoice.college.id === id;
      });

      const before = topColleges.slice(0, index);
      const toUpdate = topColleges[index];
      const after = topColleges.slice(index + 1);

      if (!toUpdate) {
        return topColleges;
      }

      const updated = {
        ...toUpdate,
        stage: stage ?? '',
        scholarshipAmount: Number(event.target.value),
      };
      return [...before, updated, ...after];
    };
    void debouncedUpdate(getNewState());

    return event.target.value;
  };

  const handleRadioButtonChange = (id: number, event: React.ChangeEvent<HTMLInputElement>) => {
    if (!topColleges) {
      return;
    }

    const getNewState = () => {
      const index = topColleges.findIndex((studentCollegeChoice) => {
        return studentCollegeChoice.college.id === id;
      });

      const before = topColleges.slice(0, index);
      const toUpdate = topColleges[index];
      const after = topColleges.slice(index + 1);

      if (!toUpdate) {
        return topColleges;
      }
      const fieldsToUpdate =
        event.target.name === 'earlyAction'
          ? {
              earlyAction: true,
              earlyDecision: false,
            }
          : {
              earlyAction: false,
              earlyDecision: true,
            };

      const clear =
        (toUpdate.earlyAction && event.target.name === 'earlyAction') ||
        (toUpdate.earlyDecision && event.target.name === 'earlyDecision');

      const update = clear
        ? {
            earlyAction: false,
            earlyDecision: false,
          }
        : fieldsToUpdate;

      const updated = {
        ...toUpdate,
        rank: index + 1,
        stage: stage ?? '',
        ...update,
      };
      return [...before, updated, ...after];
    };
    updateWithCallback(getNewState());
  };

  const handleMajorChange = (id: number, event: React.ChangeEvent<HTMLInputElement>) => {
    if (!topColleges) {
      return;
    }

    const getNewState = () => {
      const index = topColleges.findIndex((studentCollegeChoice) => {
        return studentCollegeChoice.college.id === id;
      });

      const before = topColleges.slice(0, index);
      const toUpdate = topColleges[index];
      const after = topColleges.slice(index + 1);

      if (!toUpdate) {
        return topColleges;
      }

      const updated = {
        ...toUpdate,
        major: event.target.value,
      };
      return [...before, updated, ...after];
    };
    void debouncedUpdate(getNewState());

    return event.target.value;
  };

  const handleLevelOfSchoolingChange = (
    id: number,
    level: SelectOption<StudentCollegeChoiceSchoolLevels>,
  ) => {
    if (!topColleges) {
      return;
    }

    const getNewState = () => {
      const index = topColleges.findIndex((studentCollegeChoice) => {
        return studentCollegeChoice.college.id === id;
      });
      const before = topColleges.slice(0, index);
      const toUpdate = topColleges[index];
      const after = topColleges.slice(index + 1);

      if (!toUpdate) {
        return topColleges;
      }

      const updated = {
        ...toUpdate,
        levelOfSchooling: level.value,
      };
      return [...before, updated, ...after];
    };
    updateWithCallback(getNewState());
  };

  if (isLoading || !topColleges) {
    return null;
  }

  return (
    <>
      <span className="tw-font-semibold">College Applications</span>
      <div className="tw-text-gray-400">{title}</div>
      <Spacer size={4} />
      <AsyncSelect
        id="college-selector"
        name="college-selector"
        value={null}
        placeholder="Search Colleges..."
        noOptionsMessage={() => 'Type to search'}
        filterOption={createFilter({ ignoreAccents: false })}
        isSearchable
        onChange={(college) => onSelectChange(college as InstitutionPublic)}
        loadOptions={(input, callback) =>
          searchInstitutions(
            topColleges.map(({ college }) => college.id),
            input,
            callback,
          )
        }
      />
      <Spacer size={4} />
      {!!topColleges.length && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="college">
            {(droppable) => (
              <div ref={droppable.innerRef} className="tw-p-2">
                {topColleges.map((collegeChoice, index) => (
                  <Draggable
                    key={collegeChoice.college.id}
                    draggableId={collegeChoice.college.id.toString()}
                    index={index}
                  >
                    {({ innerRef, draggableProps, dragHandleProps }) => (
                      <div
                        ref={innerRef}
                        {...draggableProps}
                        className="tw-mb-3 tw-flex tw-flex-col tw-gap-3 tw-rounded-2xl tw-bg-white tw-px-5 tw-py-2 tw-shadow-xl"
                      >
                        <div className="tw-relative tw-flex tw-items-center tw-justify-between">
                          {index === 0 && (
                            <div className="tw-absolute -tw-left-5 -tw-top-2 tw-rounded tw-rounded-tl-[28px] tw-bg-primary tw-py-1 tw-pl-4 tw-pr-3">
                              <Text size="small" fontWeight="bold">
                                First choice
                              </Text>
                            </div>
                          )}
                          <div className="tw-flex tw-items-center">
                            <Icon
                              {...dragHandleProps}
                              name="sixDots"
                              local
                              size="20px"
                              color="gray"
                              style={{ minWidth: 20 }}
                              className="tw-mx-2"
                            />
                            {collegeChoice.college.label}
                          </div>
                          <div className="tw-flex tw-items-center">
                            <Select
                              options={studentCollegeChoiceStatusOptions}
                              name={`college-choice-status-${collegeChoice.college.id}`}
                              id={`college-choice-status-${collegeChoice.college.id}`}
                              onChange={(event) => {
                                return onStatusChange(
                                  collegeChoice.college.id,
                                  event as CollegeStatusOption,
                                );
                              }}
                              value={statusToStatusOption(collegeChoice.status)}
                            />
                            <Icon
                              name="trash"
                              local
                              size="24px"
                              color="gray"
                              style={{ minWidth: 24 }}
                              className="tw-mx-2"
                              onClick={() => remove(collegeChoice.college.id)}
                            />
                          </div>
                        </div>
                        <div
                          className={cn(
                            'tw-flex tw-flex-col tw-items-end tw-gap-5',
                            collegeChoice.gotScholarship ? 'tw-justify-between' : 'tw-justify-end',
                          )}
                        >
                          <div className="tw-mt-2 tw-flex tw-justify-end tw-gap-3">
                            <Checkbox
                              label="Early Action"
                              name="earlyAction"
                              checked={collegeChoice.earlyAction}
                              onChange={(event) => {
                                return handleRadioButtonChange(collegeChoice.college.id, event);
                              }}
                            />
                            <Checkbox
                              label="Early Decision"
                              name="earlyDecision"
                              checked={collegeChoice.earlyDecision}
                              onChange={(event) => {
                                return handleRadioButtonChange(collegeChoice.college.id, event);
                              }}
                            />
                            <Checkbox
                              label="Scholarship"
                              name="gotScholarship"
                              checked={collegeChoice.gotScholarship}
                              onChange={(event) => {
                                return handleCheckboxChange(collegeChoice.college.id, event);
                              }}
                            />
                          </div>
                          {collegeChoice.gotScholarship && (
                            <div className="tw-flex tw-flex-row tw-gap-5">
                              <div className="tw-max-w-[150px]">
                                <InputField
                                  label="Amount ($)"
                                  name="scholarshipAmount"
                                  type="number"
                                  defaultValue={collegeChoice.scholarshipAmount.toString() ?? '0'}
                                  onChange={(event) => {
                                    return handleInputChange(collegeChoice.college.id, event);
                                  }}
                                />
                              </div>
                              <Checkbox
                                label="Full Scholarship"
                                name="fullScholarship"
                                checked={collegeChoice.fullScholarship}
                                onChange={(event) => {
                                  return handleCheckboxChange(collegeChoice.college.id, event);
                                }}
                              />
                            </div>
                          )}
                        </div>
                        {collegeChoice.status === 'going' && (
                          <div className="tw-mt-4 tw-flex tw-items-center tw-gap-3">
                            <div className="tw-mb-4 tw-w-full">
                              <InputField
                                label="Major"
                                name="major"
                                defaultValue={collegeChoice.major ?? ''}
                                onChange={(event) =>
                                  handleMajorChange(collegeChoice.college.id, event)
                                }
                              />
                            </div>
                            <div className="tw-min-w-64">
                              <Select
                                options={studentCollegeChoiceSchoolLevelsOptions}
                                id={`college-choice-level-of-schooling-${collegeChoice.college.id}`}
                                name={`college-choice-level-of-schooling-${collegeChoice.college.id}`}
                                placeholder="Level of schooling..."
                                onChange={(event) => {
                                  return handleLevelOfSchoolingChange(
                                    collegeChoice.college.id,
                                    event as SelectOption<StudentCollegeChoiceSchoolLevels>,
                                  );
                                }}
                                value={
                                  collegeChoice.levelOfSchooling
                                    ? toLevelOfSchoolingOption(collegeChoice.levelOfSchooling)
                                    : null
                                }
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </Draggable>
                ))}
                {droppable.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </>
  );
};
