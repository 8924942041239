import { timezones } from '@polygence/common';
import type { CustomEventTarget, DateTime, Nullable } from '@polygence/common';
import type {
  CountryId,
  MentorProfileId,
  SchoolId,
  SelectOption,
  TimeZone,
  UserId,
} from '@polygence/common';
import * as backendApi from '@polygence/common/api/backend';
import * as marketplaceApi from '@polygence/common/api/marketplace';
import type { ShowcasingSpecialty, Tag } from '@polygence/common/types/marketplace';
import { Button, Heading, InputField, PhoneInput, Select, Spacer } from '@polygence/components';
import debounce from 'lodash/debounce';
import isString from 'lodash/isString';
import type React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { toSelectObject } from 'src/components/aux/toSelectObject';
import MarkdownTextEditor from 'src/components/common/MarkdownTextEditor';
import { isMentor, isStudent } from 'src/components/getMyInfo';
import { MentoringInfo } from 'src/components/usersettings/MentoringInfo';
import SchoolSection from 'src/components/usersettings/SchoolSection';
import {
  GRADUATION_INSTRUCTIONS,
  LOCATION_INSTRUCTIONS,
  STUDENT_PROFILE_ABOUT_DEFAULT,
  TIME_ZONE_INSTRUCTIONS,
} from 'src/components/usersettings/constants';
import type { SchoolChangeTarget } from 'src/types/common';
import { urls } from 'src/urls';
import { isNullNumber, isNullString } from 'src/utils/formHelpFunctions';

export interface Profile {
  id: UserId;
  profileId?: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: Nullable<string>;
  zip?: Nullable<string>;
  city?: Nullable<string>;
  country?: Nullable<CountryId>;
  state?: Nullable<string>;
  timeZone: TimeZone;
  school?: Nullable<string>;
  middleSchool?: Nullable<SchoolId>;
  middleSchoolLabel?: Nullable<string>;
  middleSchoolOther?: Nullable<string>;
  highSchool?: Nullable<SchoolId>;
  highSchoolLabel?: Nullable<string>;
  highSchoolOther?: Nullable<string>;
  schoolGradeOther?: Nullable<string>;
  schoolGraduationYear?: Nullable<string>;
  profilePicture?: Nullable<string>;
  tags?: Tag[];
  about?: string;
  stripeIdPresent?: boolean;
  autoAcceptingSessionProposalsAt?: Nullable<DateTime>;
  showcasingSpecialties?: ShowcasingSpecialty[];
}

interface PersonalDetailsProps {
  profile: Profile;
  removeError: (_: string) => void;
  submitForm: (_: null, data: Partial<Profile>, __: boolean) => void;
}

export const PersonalDetails: React.FC<PersonalDetailsProps> = ({
  profile,
  removeError,
  submitForm,
}) => {
  const [profileForm, setProfileForm] = useState<Partial<Profile>>(() => ({
    id: profile.id,
    firstName: isNullString(profile.firstName),
    lastName: isNullString(profile.lastName),
    email: isNullString(profile.email),
    phoneNumber: isNullString(profile.phoneNumber),
    zip: isNullString(profile.zip),
    city: isNullString(profile.city),
    country: isNullNumber(profile.country) as number,
    state: isNullString(profile.state),
    timeZone: isNullString(profile.timeZone) as TimeZone,
    school: isNullString(profile.school),
    middleSchool: profile.middleSchool,
    middleSchoolLabel: profile.middleSchoolLabel,
    middleSchoolOther: profile.middleSchoolOther,
    highSchool: profile.highSchool,
    highSchoolLabel: profile.highSchoolLabel,
    highSchoolOther: profile.highSchoolOther,
    schoolGradeOther: profile.schoolGradeOther,
    schoolGraduationYear: isNullString(profile.schoolGraduationYear),
    tags: profile.tags,
    about: profile.about,
    showcasingSpecialties: profile.showcasingSpecialties,
  }));
  const [fieldErrors, setFieldErrors] = useState<{ firstName: string; lastName: string }>({
    firstName: '',
    lastName: '',
  });
  const [countryOptions, setCountryOptions] = useState<SelectOption<number>[]>([]);
  const timeZoneOptions = toSelectObject(Object.entries(timezones)) as SelectOption[];
  const smallFontClass = 'var(--font-size-small)';

  const selectedCountryOption = useMemo(
    () => countryOptions.find((option) => option.value === profileForm.country),
    [countryOptions, profileForm.country],
  );

  useEffect(() => {
    marketplaceApi
      .getCountries()
      .then((response) => {
        const countries = response.data.map((country) => ({
          label: country.name,
          value: country.id,
        }));
        setCountryOptions(countries);
      })
      .catch(console.error);
  }, []);

  const debouncedProfileFormSubmit = useMemo(
    () =>
      debounce((name: keyof Profile, value: Profile[keyof Profile]) => {
        submitForm(null, { [name]: value }, true);
      }, 750),
    [submitForm],
  );

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement> | string | CustomEventTarget<string, unknown>,
    isValid?: boolean,
  ) => {
    const { name, value } = (isString(event) ? { name: 'about', value: event } : event.target) as {
      name: keyof Profile;
      value: Profile[keyof Profile];
    };

    setProfileForm((previousState: Partial<Profile>) => {
      return { ...previousState, [name]: value };
    });

    if (name === 'phoneNumber' && !isValid) {
      return null;
    }

    if (name === 'about' && profileForm.about === value) {
      return null;
    }

    removeError(name);

    return debouncedProfileFormSubmit(name, value);
  };

  const schoolChange = ({ target: { name, value } }: SchoolChangeTarget) => {
    setProfileForm((previousState: Partial<Profile>) => {
      return { ...previousState, [name]: value };
    });

    removeError(name);

    return submitForm(null, { [name]: value }, true);
  };

  const onSelectChange = (
    name: string,
    selection: { value: Nullable<number> | Nullable<string> },
  ) => {
    removeError(name);
    setProfileForm((previousState) => {
      return { ...previousState, [name]: selection.value };
    });
    submitForm(null, { [name]: selection.value }, true);
  };

  const debouncedUserInfoUpdate = useMemo(
    () =>
      debounce((name: string, value: string) => {
        void backendApi.updateUserInfo({ [name]: value });
      }, 750),
    [],
  );

  const handleNameChange = useCallback(
    ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
      setProfileForm((previousState: Partial<Profile>) => {
        return { ...previousState, [name]: value };
      });
      if (value.trim().length === 0) {
        setFieldErrors((previousState) => {
          return { ...previousState, [name]: 'This field cannot be empty' };
        });
        return;
      }
      setFieldErrors((previousState) => {
        return { ...previousState, [name]: '' };
      });

      debouncedUserInfoUpdate(name, value);
    },
    [debouncedUserInfoUpdate],
  );

  return (
    <>
      <Heading size="h3" alignment="left">
        Account information
      </Heading>
      <Spacer size={4} />
      <Heading size="p" alignment="left">
        Email
      </Heading>
      <Spacer size={3} />
      <InputField label="Email" name="email" value={profileForm.email} disabled />
      <Spacer size={3} />
      {/* @ts-expect-error using as=Link is fine See BSH-5057 */}
      <Button as={Link} to={urls.emailChange()}>
        Change email address
      </Button>
      <Spacer size={4} />
      <Heading size="p" alignment="left">
        Name
      </Heading>
      <Spacer size={3} />
      <InputField
        label="First name"
        name="firstName"
        value={profileForm.firstName}
        onChange={handleNameChange}
        isInvalid={Boolean(fieldErrors.firstName)}
        errorMessage={fieldErrors.firstName}
      />
      <Spacer size={3} />
      <InputField
        label="Last name"
        name="lastName"
        value={profileForm.lastName}
        onChange={handleNameChange}
        isInvalid={Boolean(fieldErrors.lastName)}
        errorMessage={fieldErrors.lastName}
      />
      <Spacer size={6} />
      <Heading size="p" alignment="left">
        Phone number
      </Heading>
      {/* eslint-disable-next-line react/forbid-dom-props */}
      <div className="text-muted" style={{ fontSize: smallFontClass }}>
        We will only contact you by phone if there is an emergency{' '}
        {isMentor() ? 'or your student has had trouble reaching you' : ''}.
      </div>
      <Spacer size={4} />
      <PhoneInput
        name="phoneNumber"
        label="Phone number"
        shouldValidate
        onChange={handleChange}
        value={profileForm.phoneNumber as string}
      />
      <Spacer size={6} />
      <Heading size="p" alignment="left">
        Your time zone
      </Heading>
      {/* eslint-disable-next-line react/forbid-dom-props */}
      <div className="text-muted" style={{ fontSize: smallFontClass }}>
        {TIME_ZONE_INSTRUCTIONS}
      </div>
      <Spacer size={4} />
      <Select
        options={timeZoneOptions}
        isSearchable
        name="timeZone"
        id="time_zone"
        onChange={(event) => {
          onSelectChange('timeZone', event as { value: string });
        }}
        placeholder="Time zone"
        value={{
          label: timezones[profileForm.timeZone as TimeZone],
          value: profileForm.timeZone as TimeZone,
        }}
      />
      <Spacer size={6} />
      {isMentor() && (
        <MentoringInfo
          userId={profile.id}
          profileId={profile.profileId as MentorProfileId}
          tags={profile.tags}
          showcasingSpecialties={profile.showcasingSpecialties}
        />
      )}
      {isStudent() && (
        <>
          <Heading size="p" alignment="left">
            About
          </Heading>
          <Spacer size={4} />
          <MarkdownTextEditor
            name="about"
            onChange={handleChange}
            value={profileForm.about as string}
            defaultValue={profileForm.about as string}
            placeholder={STUDENT_PROFILE_ABOUT_DEFAULT}
          />
          <Spacer size={6} />
          <Heading size="p" alignment="left">
            Location data
          </Heading>
          {/* eslint-disable-next-line react/forbid-dom-props */}
          <div className="text-muted" style={{ fontSize: smallFontClass }}>
            {LOCATION_INSTRUCTIONS}
          </div>
          <Spacer size={4} />
          <InputField
            label="ZIP Code"
            name="zip"
            onChange={handleChange}
            value={profileForm.zip as string}
          />
          <Spacer size={4} />
          <InputField
            label="City"
            name="city"
            onChange={handleChange}
            value={profileForm.city as string}
          />
          <Spacer size={4} />
          <InputField
            label="State"
            name="state"
            onChange={handleChange}
            value={profileForm.state as string}
          />
          <Spacer size={4} />
          <Select
            id="country"
            name="country"
            value={selectedCountryOption}
            placeholder="Country"
            options={countryOptions}
            isSearchable
            onChange={(event) => onSelectChange('country', event as { value: string })}
          />
          <Spacer size={6} />
          <Heading size="p" alignment="left">
            Education
          </Heading>
          {/* eslint-disable-next-line react/forbid-dom-props */}
          <div className="text-muted" style={{ fontSize: smallFontClass }}>
            {GRADUATION_INSTRUCTIONS}
          </div>
          <Spacer size={4} />
          <SchoolSection
            profileForm={profileForm}
            handleChange={schoolChange}
            onSelectChange={(name, target) => onSelectChange(name, target as { value: string })}
          />
        </>
      )}
    </>
  );
};
