import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import * as marketplaceApi from '../../api/marketplace';
import type { MentorRequestUid } from '../../types/data/marketplace';
import type {
  SelfProposableMentorRequest,
  selfProposedMentorRequest,
} from '../../types/marketplace';
import type { ByUids } from '../../types/utils';

export interface SelfProposeState {
  selfProposableMentorRequests: ByUids<SelfProposableMentorRequest>;
  selfProposedMentorRequests: ByUids<selfProposedMentorRequest>;
}

const defaults: SelfProposeState = {
  selfProposableMentorRequests: {
    byUid: {},
    allUids: [],
  },
  selfProposedMentorRequests: {
    byUid: {},
    allUids: [],
  },
};

export const getSelfProposableMentorRequests = createAsyncThunk<{
  selfProposableMentorRequests: SelfProposableMentorRequest[];
}>('api/getSelfProposableMentorRequests', async () => {
  const response = await marketplaceApi.getSelfProposableMentorRequests();
  return { selfProposableMentorRequests: response.data };
});

export const getSelfProposedMentorRequests = createAsyncThunk<{
  selfProposedMentorRequests: selfProposedMentorRequest[];
}>('api/getSelfProposedMentorRequests', async () => {
  const response = await marketplaceApi.getSelfProposedMentorRequests();
  return { selfProposedMentorRequests: response.data };
});

export const selfProposeForMentorRequest = createAsyncThunk(
  'api/selfProposeForMentorRequest',
  async (mentorRequestUid: MentorRequestUid) => {
    const response = await marketplaceApi.selfPropose(mentorRequestUid);
    const updatedMentorRequest = response.data;
    return { updatedMentorRequest };
  },
);

const selfProposeSlice = createSlice({
  name: 'selfPropose',
  initialState: defaults,
  extraReducers: (builder) => {
    builder.addCase(
      getSelfProposableMentorRequests.fulfilled,
      (state, { payload: { selfProposableMentorRequests } }) => {
        selfProposableMentorRequests.forEach((mentorRequest) => {
          state.selfProposableMentorRequests.byUid[mentorRequest.uuid] = mentorRequest;
          if (!state.selfProposableMentorRequests.allUids.includes(mentorRequest.uuid)) {
            state.selfProposableMentorRequests.allUids.push(mentorRequest.uuid);
          }
        });
      },
    );
    builder.addCase(
      getSelfProposedMentorRequests.fulfilled,
      (state, { payload: { selfProposedMentorRequests } }) => {
        selfProposedMentorRequests.forEach((mentorRequest) => {
          state.selfProposedMentorRequests.byUid[mentorRequest.uuid] = mentorRequest;
          if (!state.selfProposedMentorRequests.allUids.includes(mentorRequest.uuid)) {
            state.selfProposedMentorRequests.allUids.push(mentorRequest.uuid);
          }
        });
      },
    );
    builder.addCase(
      selfProposeForMentorRequest.fulfilled,
      (state, { payload: { updatedMentorRequest } }) => {
        const mrId = updatedMentorRequest.uuid;
        delete state.selfProposableMentorRequests.byUid[mrId];
        state.selfProposableMentorRequests.allUids =
          state.selfProposableMentorRequests.allUids.filter((id) => id !== mrId);
        state.selfProposedMentorRequests.byUid[mrId] = updatedMentorRequest;
        if (!state.selfProposedMentorRequests.allUids.includes(mrId)) {
          state.selfProposedMentorRequests.allUids.push(mrId);
        }
      },
    );
  },
  reducers: {},
});

const { actions, reducer } = selfProposeSlice;

export const selfProposeActions = actions;
export const selfProposeReducer = reducer;
