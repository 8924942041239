import { PaymentIntentTypes } from '@polygence/common/types/payment';
import { Card, Text } from '@polygence/components';

interface PaymentIntentTypeOptionProps {
  name: string;
  value: PaymentIntentTypes;
  active: boolean;
  children: React.ReactNode;
  onChange: (type: PaymentIntentTypes) => void;
}

export const PaymentIntentTypeOption = ({
  name,
  value,
  active,
  children,
  onChange,
}: PaymentIntentTypeOptionProps) => {
  return (
    <div data-cy={value} className="tw-flex tw-flex-col lg:tw-max-w-[500px] lg:tw-flex-1">
      <input
        id={value}
        name="paymentMethod"
        type="radio"
        value={value}
        className="tw-peer tw-appearance-none"
        onChange={() => onChange(value)}
      />
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label
        htmlFor={value}
        className="peer-focus-visible:tw-outline peer-focus-visible:tw-outline-2 peer-focus-visible:tw-outline-gray-700"
      >
        <Card body isActive={active} className="tw-cursor-pointer tw-rounded-2xl">
          <div className="tw-flex tw-flex-col tw-items-center tw-gap-2">
            <Text size="large" alignment="center" fontWeight="bold">
              {name}
            </Text>
            {children}
          </div>
        </Card>
      </label>
    </div>
  );
};
