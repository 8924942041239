import type { CSSProperties } from 'react';

import styles from 'src/components/Tiles/StudentJourney/LookingForMentorAvatar.module.scss';
import defaultProfileImage from 'src/components/static/images/generic-profile.jpg';

const arrayOfTen = Array(10)
  .fill(null)
  .map((_, i) => i);

export const LookingForMentorAvatar = ({ size = 40 }: { size?: number }) => {
  return (
    // eslint-disable-next-line react/forbid-dom-props
    <div className={styles['avatarContainer']} style={{ '--size': `${size}px` } as CSSProperties}>
      <div className={styles['images']}>
        {arrayOfTen.map((index) => {
          return (
            <img
              className={styles['img']}
              src={defaultProfileImage}
              key={index}
              // eslint-disable-next-line react/forbid-dom-props
              style={{ '--d': `0.${index}turn` } as CSSProperties}
              alt="Placeholder"
            />
          );
        })}
      </div>
    </div>
  );
};
