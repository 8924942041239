import type {
  SessionIntroPopup,
  LegacyMarkdownGuidebook,
  CaseStudy,
} from '@polygence/common/types/site-content';

import { getRestClient } from '../commonSettings';
import type {
  SitePolicyResponseId,
  PortfolioItemId,
  CaseStudyId,
  ProjectId,
} from '../types/common';
import type { EmptyResponse } from '../types/data/common';
import { type ProjectTypes, ProjectType } from '../types/hermes';

export const getSessionIntroPopup = (
  sessionNumber: number,
  projectType: ProjectTypes = ProjectType.FLAGSHIP,
  projectId?: ProjectId,
) => {
  const internalFetch = getRestClient();
  return internalFetch.get<SessionIntroPopup | { status: string }>(
    `/sitecontent/session-intro-popups/${sessionNumber}/?project_type=${projectType}&project_id=${projectId}`,
  );
};

export const getLegacyMarkdownGuidebooks = (guidebookTag: string) => {
  const internalFetch = getRestClient();
  return internalFetch.get<LegacyMarkdownGuidebook>(`/sitecontent/guidebooks/?tag=${guidebookTag}`);
};

export const getLegacyMarkdownPublicGuidebook = (guidebookTag: string) => {
  const internalFetch = getRestClient();
  return internalFetch.get<LegacyMarkdownGuidebook>(
    `sitecontent/public-document/?tag=${guidebookTag}`,
  );
};

export const acceptPolicy = (sitePolicyResponseId: SitePolicyResponseId) => {
  const internalFetch = getRestClient();
  return internalFetch.post<EmptyResponse>(`/sitecontent/accept-policy/${sitePolicyResponseId}`);
};

export const rejectPolicy = (sitePolicyResponseId: SitePolicyResponseId) => {
  const internalFetch = getRestClient();
  return internalFetch.post<EmptyResponse>(`/sitecontent/reject-policy/${sitePolicyResponseId}`);
};

export const createOrUpdateCaseStudy = (portfolioItemId: PortfolioItemId) => {
  const internalFetch = getRestClient();
  return internalFetch.post<{ id: CaseStudyId }>(`/sitecontent/case-study/${portfolioItemId}/`);
};

export const getCaseStudies = (query = '') => {
  const internalFetch = getRestClient();
  return internalFetch.get<{ results: CaseStudy[] }>(`/sitecontent/case-studies/?q=${query}`);
};
