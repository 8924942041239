import 'src/components/LexicalEditor/styles.sass';
import { $createCodeNode } from '@lexical/code';
import { $createHeadingNode, HeadingTagType, $createQuoteNode } from '@lexical/rich-text';
import { $wrapLeafNodesInElements } from '@lexical/selection';
import { $getSelection, $isRangeSelection, $createParagraphNode } from 'lexical';
import type { FC } from 'react';
import { useState, useEffect } from 'react';
import Select from 'react-select';
import type { SingleValue } from 'react-select';

import {
  useToolbarContext,
  BlockType,
} from 'src/components/LexicalEditor/plugins/toolbar/ToolbarContext';

const options = [
  { value: BlockType.paragraph, label: 'Normal' },
  { value: BlockType.h1, label: 'H1' },
  { value: BlockType.h2, label: 'H2' },
  { value: BlockType.h3, label: 'H3' },
  { value: BlockType.h4, label: 'H4' },
  { value: BlockType.h5, label: 'H5' },
  { value: BlockType.code, label: 'Code Block' },
];

const BlockTypeSelect: FC = () => {
  const { editor, blockType } = useToolbarContext();
  const [type, setType] = useState(options[0]);

  useEffect(() => {
    const newBlockType = options.find((e) => e.value === blockType);
    setType(() => newBlockType);
  }, [blockType]);

  const formatParagraph = () => {
    if (blockType !== BlockType.paragraph) {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapLeafNodesInElements(selection, () => $createParagraphNode());
        }
      });
    }
  };

  const formatHeading = (headingTag: HeadingTagType) => {
    if (blockType !== headingTag) {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          $wrapLeafNodesInElements(selection, () => $createHeadingNode(headingTag));
        }
      });
    }
  };

  const formatQuote = () => {
    if (blockType !== BlockType.quote) {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapLeafNodesInElements(selection, () => $createQuoteNode());
        }
      });
    }
  };

  const formatCode = () => {
    if (blockType !== BlockType.code) {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapLeafNodesInElements(selection, () => $createCodeNode());
        }
      });
    }
  };

  const handleChange = (option: SingleValue<{ label: string; value: BlockType }>) => {
    const value = option?.value;
    switch (value) {
      case BlockType.paragraph:
        return formatParagraph();
      case BlockType.h1:
        return formatHeading(BlockType.h1);
      case BlockType.h2:
        return formatHeading(BlockType.h2);
      case BlockType.h3:
        return formatHeading(BlockType.h3);
      case BlockType.h4:
        return formatHeading(BlockType.h4);
      case BlockType.h5:
        return formatHeading(BlockType.h5);
      case BlockType.quote:
        return formatQuote();
      case BlockType.code:
        return formatCode();
      default:
        return formatParagraph();
    }
  };

  return (
    <div className="block-type-selector">
      <Select
        options={options}
        onChange={(value) => handleChange(value)}
        defaultValue={options[0]}
        value={type}
      />
    </div>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default BlockTypeSelect;
