import { Heading, Spacer, Text } from '@polygence/components';

import { UpsellCardRow } from 'src/students/Pods/UpsellPrograms/UpsellCardRow';
import greenCheckMark from 'src/students/Pods/assets/green-check-mark.svg';

export const UpsellPograms = () => {
  return (
    <>
      <img
        src={greenCheckMark}
        alt="green check mark"
        width="42px"
        height="42px"
        className="mx-auto w-100"
      />
      <Spacer size={8} />
      <Heading size="h4">Next steps</Heading>
      <Spacer size={7} />
      <Text size="medium" alignment="center" className="mx-xl-13">
        We hope you enjoyed your Polygence experience! Continue learning through a new Pod, starting
        your own Research project, or share Pods with your friend!
      </Text>
      <Spacer size={9} />
      <UpsellCardRow />
    </>
  );
};
