export const POST_COLLEGE_APPLICATION = 'post-college-application';
export const POST_COLLEGE_ADMISSION = 'post-college-admission';
export const POST_COLLEGE_SELECTION = 'post-college-selection';

export const FEATURED_US_AS_NOT_FEATURED_VALUE = 'not_featured';
export const FEATURED_US_AS_NOT_FEATURED_OPTION = {
  label: 'I did not feature Polygence',
  value: FEATURED_US_AS_NOT_FEATURED_VALUE,
};
export const FEATURED_US_AS_OPTIONS = [
  {
    label: 'Personal Essay',
    value: 'personal_essay',
  },
  {
    label: 'Supplemental Essays',
    value: 'supplemental_essays',
  },
  {
    label: 'Resume / CV',
    value: 'resume_cv',
  },
  {
    label: 'Activities List',
    value: 'activities_list',
  },
  FEATURED_US_AS_NOT_FEATURED_OPTION,
];
