import type { Tag } from '@polygence/common/types/marketplace';
import { AsyncSelect, OptionType } from '@polygence/components';
import { useState } from 'react';

interface EditableTagsProps {
  caseStudyTags: Tag[];
  tags: Tag[];
  handleChange: (name: string, value: string | Tag[] | null) => void;
}

interface TagOption extends Tag, OptionType {}

export const EditableTags = ({ caseStudyTags, tags, handleChange }: EditableTagsProps) => {
  const [selectedTags, setSelectedTags] = useState<TagOption[]>(
    tags.map((tag: Tag) => {
      return { ...tag, label: tag.name, value: tag.id };
    }),
  );

  const tagOptions = caseStudyTags.map((tag) => ({ ...tag, label: tag.name, value: tag.id }));

  const filterCaseStudyTags = (inputValue: string) => {
    return [
      ...tagOptions.filter((i) => {
        return i.label.toLowerCase().includes(inputValue.toLowerCase());
      }),
    ];
  };

  const loadOptions = (inputValue: string, callback: (options: TagOption[]) => void) => {
    if (!inputValue) {
      return;
    }
    callback(filterCaseStudyTags(inputValue));
  };

  return (
    <AsyncSelect
      isMulti
      defaultOptions={tagOptions}
      loadOptions={loadOptions}
      value={selectedTags}
      onChange={(selected) => {
        setSelectedTags(Array.isArray(selected) ? selected : [selected]);
        handleChange('tags', Array.isArray(selected) ? selected : [selected]);
      }}
    />
  );
};
