/* eslint-disable sonarjs/no-duplicate-string */
import { ApplicationSectionIds } from 'src/constants/application-sections';
import {
  contentSpacer,
  headingProps,
  headingSpacer,
  illustrationSize,
  narrowFieldSpacer,
  textFieldSpacer,
} from 'src/students/student-application/common-section-components';
import { EXPERIMENT_SECTIONS_B } from 'src/students/student-application/experiment-sections';
import type { ApplicationComponentSection } from 'src/students/student-application/student-application-components';

export const PROJECT_IDEA_SECTIONS_A: ApplicationComponentSection[] = [
  {
    id: ApplicationSectionIds.PROJECT_IDEA_SECTION_ID,
    display: {
      $and: [
        { $is: { field: 'applicationRevampEnabled', value: false } },
        { $not: { field: 'intendToDoSciFair', value: true } },
      ],
    },
    elements: [
      {
        component: 'ThreeColumnLayout',
        props: {
          children: [
            [{ component: 'AdmissionAdvisorModalInfo' }],
            [
              {
                component: 'CenteredIllustration',
                props: {
                  name: 'SpaceTelescope',
                  size: illustrationSize,
                },
              },
              headingSpacer,
              {
                component: 'ComponentsHeading',
                props: {
                  children: 'What would you like to explore at Polygence?',
                  ...headingProps,
                },
                display: {
                  $is: {
                    field: 'interestedInLaunchpad',
                    value: false,
                  },
                },
              },
              {
                component: 'ComponentsHeading',
                props: {
                  children: `What’s your best guess of what you might focus on for your Polygence project?`,
                  ...headingProps,
                },
                display: {
                  $is: {
                    field: 'interestedInLaunchpad',
                    value: true,
                  },
                },
              },
              contentSpacer,
              {
                component: 'ComponentsText',
                props: {
                  children: `In order to find your perfect mentor, we need to know a little bit about what you’d like to work on. Explain your project idea here as if you were describing it to your mentor in 1-2 paragraphs (between 100 to 300 words).`,
                  className: 'mb-7',
                  size: 'medium',
                },
                display: {
                  $is: {
                    field: 'interestedInLaunchpad',
                    value: false,
                  },
                },
              },
              {
                component: 'Spacer',
                props: { size: 2 },
                display: {
                  $is: {
                    field: 'interestedInLaunchpad',
                    value: true,
                  },
                },
              },
              {
                component: 'ComponentsText',
                props: {
                  children: `It’s okay if your idea is a little abstract and broad at this point. Your mentor will help you refine your project scope and crystalize your thoughts.`,
                  className: 'mb-7',
                  size: 'medium',
                },
                display: {
                  $is: {
                    field: 'interestedInLaunchpad',
                    value: false,
                  },
                },
              },
              {
                component: 'ComponentsText',
                props: {
                  children: `It’s okay if you only have a general sense of the topic(s) you’re considering doing a project on. Please give a brief explanation of what you’re thinking so far (min 100 words).`,
                  className: 'mb-7',
                  size: 'medium',
                },
                display: {
                  $is: {
                    field: 'interestedInLaunchpad',
                    value: true,
                  },
                },
              },
              {
                component: 'ProjectIdeas',
              },
              {
                component: 'Spacer',
                props: { size: 7 },
              },
              {
                component: 'InputField',
                tag: 'application',
                props: {
                  type: 'textarea',
                  name: 'interests',
                  showWordCount: true,
                  rows: 10,
                  placeholder: `I would like to...\nSo that I can...\nI am interested in this because...`,
                },
                validation: {
                  required: true,
                  wordCount: {
                    minimum: 100,
                    maximum: 300,
                  },
                },
              },
              {
                component: 'Spacer',
                props: { size: 7 },
              },
              {
                component: 'ProjectIdeaAssist',
              },
            ],
            null,
          ],
        },
      },
    ],
  },
];

export const PROJECT_IDEA_SECTIONS_A_PARTNER_PAYS_WORKFLOW: ApplicationComponentSection[] = [
  {
    id: ApplicationSectionIds.PROJECT_IDEA_SECTION_ID,
    display: {
      $and: [
        { $is: { field: 'applicationRevampEnabled', value: false } },
        { $not: { field: 'intendToDoSciFair', value: true } },
      ],
    },
    elements: [
      {
        component: 'ThreeColumnLayout',
        props: {
          children: [
            [{ component: 'AdmissionAdvisorModalInfo' }],
            [
              {
                component: 'CenteredIllustration',
                props: {
                  name: 'SpaceTelescope',
                  size: illustrationSize,
                },
              },
              headingSpacer,
              {
                component: 'ComponentsHeading',
                props: {
                  children: 'What would you like to explore at Polygence?',
                  ...headingProps,
                },
              },
              contentSpacer,
              {
                component: 'ComponentsText',
                props: {
                  children: `In order to find your perfect mentor, we need to know a little bit about what you’d like to work on. Explain your project idea here as if you were describing it to your mentor in 1-2 paragraphs (between 100 to 300 words).`,
                  className: 'mb-7',
                  size: 'medium',
                },
              },
              {
                component: 'ComponentsText',
                props: {
                  children: `It’s okay if your idea is a little abstract and broad at this point. Your mentor will help you refine your project scope and crystalize your thoughts.`,
                  className: 'mb-7',
                  size: 'medium',
                },
              },
              {
                component: 'ProjectIdeas',
              },
              {
                component: 'Spacer',
                props: { size: 7 },
              },
              {
                component: 'InputField',
                tag: 'application',
                props: {
                  type: 'textarea',
                  name: 'interests',
                  showWordCount: true,
                  rows: 10,
                  placeholder: `I would like to...\nSo that I can...\nI am interested in this because...`,
                },
                validation: {
                  required: true,
                  wordCount: {
                    minimum: 100,
                    maximum: 300,
                  },
                },
              },
              {
                component: 'Spacer',
                props: { size: 7 },
              },
              {
                component: 'ProjectIdeaAssist',
              },
            ],
            null,
          ],
        },
      },
    ],
  },
];

const PROJECT_IDEA_SECTIONS_B_BASE: ApplicationComponentSection[] = [
  {
    id: ApplicationSectionIds.PROJECT_IDEA_DECISION_ID,
    display: {
      $is: { field: 'applicationRevampEnabled', value: true },
    },
    elements: [
      {
        component: 'ThreeColumnLayout',
        props: {
          children: [
            [{ component: 'AdmissionAdvisorModalInfo' }],
            [
              {
                component: 'CenteredIllustration',
                props: {
                  name: 'SpaceTelescope',
                  size: illustrationSize,
                },
              },
              headingSpacer,
              {
                component: 'ComponentsHeading',
                props: {
                  children: 'Do you have an idea of what you want to explore at Polygence?',
                  ...headingProps,
                },
              },
              {
                component: 'Spacer',
                props: { size: { base: 8, lg: 12 } },
              },
              {
                component: 'ComponentsRadioButton',
                tag: 'application',
                props: {
                  name: 'hasProjectIdea',
                  label: 'Yes, I have a project idea.',
                  value: true,
                },
                validation: {
                  required: true,
                },
              },
              {
                component: 'ComponentsRadioButton',
                tag: 'application',
                props: {
                  name: 'hasProjectIdea',
                  label: "No, I'm not sure at this point.",
                  value: false,
                },
                validation: {
                  required: true,
                },
              },
            ],
            null,
          ],
        },
      },
    ],
  },
];

const PROJECT_IDEA_SECTIONS_B_YES: ApplicationComponentSection[] = [
  ...EXPERIMENT_SECTIONS_B,
  {
    id: ApplicationSectionIds.PROJECT_IDEA_SECTION_ID,
    display: {
      $and: [
        { $is: { field: 'applicationRevampEnabled', value: true } },
        { $is: { field: 'hasProjectIdea', value: true } },
        { $not: { field: 'intendToDoSciFair', value: true } },
      ],
    },
    elements: [
      {
        component: 'ThreeColumnLayout',
        props: {
          children: [
            [{ component: 'AdmissionAdvisorModalInfo' }],
            [
              {
                component: 'CenteredIllustration',
                props: {
                  name: 'SpaceSaturn',
                  size: illustrationSize,
                },
              },
              headingSpacer,
              {
                component: 'ComponentsHeading',
                props: {
                  children: 'Explain your project idea',
                  ...headingProps,
                },
              },
              {
                component: 'Spacer',
                props: { size: 7 },
              },
              {
                component: 'ComponentsText',
                props: {
                  children: `Imagine you are describing it to your mentor in 1-2 paragraphs (100-300 words)`,
                  size: 'large',
                  fontWeight: 'normal',
                  alignment: 'center',
                },
              },
              {
                component: 'Spacer',
                props: { size: 7 },
              },
              {
                component: 'InputField',
                tag: 'application',
                props: {
                  type: 'textarea',
                  name: 'interests',
                  showWordCount: true,
                  rows: 10,
                  placeholder: `I would like to...\nSo that I can...\nI am interested in this because...`,
                },
                validation: {
                  required: true,
                  wordCount: {
                    minimum: 100,
                    maximum: 300,
                  },
                },
              },
              {
                component: 'Spacer',
                props: { size: 7 },
              },
              {
                component: 'ProjectIdeas',
              },
              narrowFieldSpacer,
              {
                component: 'ComponentsText',
                props: {
                  children: 'It’s okay if your idea is a little abstract and broad at this point.',
                  size: 'large',
                  fontWeight: 'semibold',
                  alignment: 'center',
                },
              },
              {
                component: 'Spacer',
                props: { size: 2 },
              },
              {
                component: 'ProjectIdeaAssist',
                props: {
                  openWidgetTitle: 'Bring your project idea to life with PolyGPT',
                },
              },
            ],
            null,
          ],
        },
      },
    ],
  },
];

const PROJECT_IDEA_SECTIONS_B_YES_WITH_LAUNCHPAD_PITCH: ApplicationComponentSection[] = [
  ...PROJECT_IDEA_SECTIONS_B_YES,
  {
    id: ApplicationSectionIds.LAUNCHPAD_PITCH_ID,
    display: {
      $and: [
        { $is: { field: 'applicationRevampEnabled', value: true } },
        { $is: { field: 'hasProjectIdea', value: true } },
      ],
    },
    elements: [
      {
        component: 'ThreeColumnLayout',
        props: {
          children: [
            [{ component: 'AdmissionAdvisorModalInfo' }],
            [
              headingSpacer,
              {
                component: 'LaunchpadPitchWithProjectIdea',
                tag: 'application',
                props: {
                  name: 'interestedInLaunchpad',
                },
                validation: {
                  required: true,
                },
              },
            ],
            null,
          ],
        },
      },
    ],
  },
];

const PROJECT_IDEA_SECTIONS_B_NO: ApplicationComponentSection[] = [
  {
    id: ApplicationSectionIds.PROJECT_IDEA_SECTION_ID,
    display: {
      $and: [
        { $is: { field: 'applicationRevampEnabled', value: true } },
        { $is: { field: 'hasProjectIdea', value: false } },
        { $is: { field: 'interestedInLaunchpad', value: false } },
        { $not: { field: 'intendToDoSciFair', value: true } },
      ],
    },
    elements: [
      {
        component: 'ThreeColumnLayout',
        props: {
          children: [
            [{ component: 'AdmissionAdvisorModalInfo' }],
            [
              {
                component: 'CenteredIllustration',
                props: {
                  name: 'SpaceTelescope',
                  size: illustrationSize,
                },
              },
              headingSpacer,
              {
                component: 'ComponentsHeading',
                props: {
                  size: 'h4',
                  as: 'h4',
                  children: 'Need help defining your project idea? Try PolyGPT',
                },
              },
              textFieldSpacer,
              {
                component: 'ProjectIdeaAssist',
                props: {
                  openWidgetTitle: 'Brainstorm your project idea with PolyGPT',
                },
              },
              { component: 'Spacer', props: { size: 4 } },
              {
                component: 'ComponentsText',
                props: {
                  children: `It’s okay if your idea is a little abstract and broad at this point. Add the best you can here:`,
                  size: 'large',
                  fontWeight: 'bold',
                  alignment: 'center',
                },
              },
              textFieldSpacer,
              {
                component: 'InputField',
                tag: 'application',
                props: {
                  type: 'textarea',
                  name: 'interests',
                  showWordCount: true,
                  rows: 10,
                  placeholder: 'Type here...',
                },
                validation: {
                  required: true,
                  wordCount: {
                    minimum: 100,
                    maximum: 300,
                  },
                },
              },
              textFieldSpacer,
              {
                component: 'ComponentsText',
                props: {
                  children: `If you’re still stuck, we recommend our Launchpad program to find a project idea you’re excited about.`,
                  size: 'medium',
                  fontWeight: 'semibold',
                  alignment: 'center',
                  className: 'mb-4',
                },
              },
              {
                component: 'ComponentsText',
                props: {
                  children: `You can add your best guess of a project idea above and chat more about Launchpad with Admissions as part of your interview.`,
                  size: 'medium',
                  fontWeight: 'normal',
                  alignment: 'center',
                },
              },
            ],
            null,
          ],
        },
      },
    ],
  },
];

const PROJECT_IDEA_SECTIONS_B_NO_WITH_LAUNCHPAD_PITCH: ApplicationComponentSection[] = [
  {
    id: ApplicationSectionIds.LAUNCHPAD_PITCH_ID,
    display: {
      $and: [
        { $is: { field: 'applicationRevampEnabled', value: true } },
        { $is: { field: 'hasProjectIdea', value: false } },
      ],
    },
    elements: [
      {
        component: 'ThreeColumnLayout',
        props: {
          children: [
            [{ component: 'AdmissionAdvisorModalInfo' }],
            [
              headingSpacer,
              {
                component: 'LaunchpadPitchWithoutProjectIdea',
                tag: 'application',
                props: {
                  name: 'interestedInLaunchpad',
                },
                validation: {
                  required: true,
                },
              },
            ],
            null,
          ],
        },
      },
    ],
  },
  ...PROJECT_IDEA_SECTIONS_B_NO,
];

export const PROJECT_IDEA_SECTIONS_B: ApplicationComponentSection[] = [
  ...PROJECT_IDEA_SECTIONS_B_BASE,
  ...PROJECT_IDEA_SECTIONS_B_YES_WITH_LAUNCHPAD_PITCH,
  ...PROJECT_IDEA_SECTIONS_B_NO_WITH_LAUNCHPAD_PITCH,
];

export const PROJECT_IDEA_SECTIONS_B_PARTNER_PAYS_WORKFLOW: ApplicationComponentSection[] = [
  ...PROJECT_IDEA_SECTIONS_B_BASE,
  ...PROJECT_IDEA_SECTIONS_B_YES,
  ...PROJECT_IDEA_SECTIONS_B_NO,
];
