import { Heading, Spacer } from '@polygence/components';
import classNames from 'classnames';

import { CaseStudyTile } from 'src/components/Tiles/Counselor/CaseStudyTile';
import tileStyles from 'src/components/Tiles/tilesDashboard.module.scss';
import { useGetLatestCaseStudyQuery } from 'src/reducers/siteContentReducers';

export const HighlightedStudentProject = () => {
  const { data: caseStudy } = useGetLatestCaseStudyQuery();

  if (!caseStudy) {
    return null;
  }

  return (
    <div className={classNames(tileStyles['tile'], 'd-flex flex-column p-8 gap-3')}>
      <Heading as="h4" size="h4" alignment="left" fontWeight="semibold">
        Highlighted Student Project
      </Heading>
      <Spacer size={5} />
      <CaseStudyTile
        url={caseStudy.url}
        projectTitle={caseStudy.projectTitle}
        projectImage={caseStudy.projectImage}
        projectImageAlt={caseStudy.projectImageAlt}
        mentorDetails={caseStudy.mentorDetails}
        student={caseStudy.student}
      />
    </div>
  );
};
