export const WORKSPACE_DISPLAY_CATEGORIES = {
  Pathfinders: 'Pathfinders',
  Launchpad: 'Launchpad',
  Reflection: 'Reflection',
  Projects: 'Projects',
  Showcasing: 'Showcasing',
  Pods: 'Pods',
  'Research Coach': 'Research Coach',
  Other: 'Other',
  Completed: 'Completed',
} as const;
