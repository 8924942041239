import dayjs from 'dayjs';
import type { UnitType, QUnitType } from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import duration from 'dayjs/plugin/duration';
import isbetween from 'dayjs/plugin/isBetween';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import minMax from 'dayjs/plugin/minMax';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(isbetween);
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.extend(minMax);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.extend(advancedFormat);
dayjs.extend(quarterOfYear);

export const dayJsNumberToDayMap = {
  0: 'sunday',
  1: 'monday',
  2: 'tuesday',
  3: 'wednesday',
  4: 'thursday',
  5: 'friday',
  6: 'saturday',
} as const;

export const numberToDayMap = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
} as const;

export const getFirstDayOf = (unit: UnitType | QUnitType, relativeNumber = 0) => {
  if (relativeNumber < 0) {
    return dayjs().startOf(unit).subtract(Math.abs(relativeNumber), unit);
  } else if (relativeNumber > 0) {
    return dayjs().startOf(unit).add(relativeNumber, unit);
  } else {
    return dayjs().startOf(unit);
  }
};

export const getLastDayOf = (unit: UnitType | QUnitType, relativeNumber = 0) => {
  if (relativeNumber < 0) {
    return dayjs().endOf(unit).subtract(Math.abs(relativeNumber), unit);
  } else if (relativeNumber > 0) {
    return dayjs().endOf(unit).add(relativeNumber, unit);
  } else {
    return dayjs().endOf(unit);
  }
};

export { dayjs };
