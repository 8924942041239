import { Text } from '@polygence/components';
import { useEffect } from 'react';

import { Loader } from 'src/components/Loader';
import { navigateToWithTimeout } from 'src/utils/navigateTo';

export const RedirectNotification = ({ url }: { url: string }) => {
  useEffect(() => {
    if (url) {
      navigateToWithTimeout(url, 1000);
    }
  }, [url]);

  if (!url) {
    return null;
  }

  return (
    <span className="text-center">
      <div className="my-10 py-10">
        <Text
          size="large"
          alignment="center"
          fontWeight="bold"
          className="my-5"
          style={{ color: 'var(--primary)' }}
        >
          Redirecting to an external page...
        </Text>
        <Loader className="mx-auto" />
      </div>
      <hr />
    </span>
  );
};
