import { UserType } from '@polygence/common';
import useCurrentUser from '@polygence/common/hooks/selectors/useCurrentUser';
import * as roleUtils from '@polygence/common/utils/roles';
import type { ComponentProps } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';

import { useAppSelector } from 'src/store';
import { urls } from 'src/urls';

// this wrapper makes sure that you don't access landing page and sign-ups if you are logged in
/* eslint-disable */
export function PublicRoute<T extends React.ElementType>({
  component: Component,
  logged_in,
  user_type,
  path,
  ...componentProps
}: {
  component: T;
  logged_in: boolean;
  user_type: UserType;
  path: string;
} & ComponentProps<T>) {
  let path_url;
  if (user_type === 'mentor') {
    path_url = urls.homePage();
  } else {
    path_url = urls.getDashboardPath();
  }
  return (
    <Route
      path={path}
      render={(props) =>
        logged_in === true ? (
          <Redirect to={{ pathname: path_url, state: { from: props.location } }} />
        ) : (
          <Component {...props} {...componentProps} />
        )
      }
    />
  );
}

// this wrapper redirects to login if accessing page that needs authentication
export function PrivateRoute<T extends React.ElementType>({
  component: Component,
  ...componentProps
}: { component: T; bool: boolean } & ComponentProps<T>) {
  const loggedIn = useAppSelector((state) => state.user.loggedIn);
  return (
    <Route
      {...componentProps}
      render={(props) =>
        loggedIn ? (
          <Component {...props} {...componentProps} />
        ) : (
          <Redirect
            to={{
              pathname: '/user/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

export function AdminRoute<T extends React.ElementType>({
  component: Component,
  path,
  exact,
  ...componentProps
}: { component: T; path: string; exact?: boolean } & ComponentProps<T>) {
  const currentUser = useCurrentUser();
  return (
    <Route
      path={path}
      exact={exact}
      render={(props) => {
        if (!currentUser.loggedIn) {
          return (
            <Redirect
              to={{
                pathname: '/user/login',
                state: { from: props.location },
              }}
            />
          );
        }
        if (roleUtils.hasAdminDashboardAccess(currentUser) || roleUtils.isIntern(currentUser)) {
          return <Component {...props} {...componentProps} />;
        }
        return <Redirect to={{ pathname: '/dashboard' }} />;
      }}
    />
  );
}

export function ReapplyPrivateRoute<T extends React.ElementType>({
  component,
  ...componentProps
}: { component: T } & ComponentProps<T>) {
  const currentUser = useCurrentUser();
  const location = useLocation();

  const isReapplyAllowed = currentUser.otherInfo?.['isReapplyAllowed'];
  if (isReapplyAllowed) {
    return <PrivateRoute component={component} {...componentProps} />;
  } else {
    return (
      <Redirect
        to={{
          pathname: '/dashboard',
          state: { from: location },
        }}
      />
    );
  }
}

export function StudentRoute<T extends React.ElementType>({
  component: Component,
  userType,
  loggedIn,
  channel,
  applicationJourneyEnabled,
  path,
  ...componentProps
}: {
  component: T;
  userType: UserType;
  loggedIn: boolean;
  channel: string;
  applicationJourneyEnabled: boolean;
  path: string;
} & ComponentProps<T>) {
  return (
    <Route
      path={path}
      render={(props) =>
        userType === UserType.STUDENT ? (
          <Component
            {...props}
            {...componentProps}
            loggedIn={loggedIn}
            channel={channel}
            applicationJourneyEnabled={applicationJourneyEnabled}
          />
        ) : (
          <Redirect
            to={{
              pathname: '/dashboard',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}
