export function isNullNumber(prop: number | null | undefined) {
  return prop ? prop : undefined;
}

export function isNullString(prop: string | null | undefined) {
  return prop ? prop : '';
}

export const isNullOption = (value: string | null, label: string | null) => {
  return value && label ? { value: value, label: label } : null;
};

export function generateRange(lowEnd: number, highEnd: number) {
  // eslint-disable-next-line no-var
  var list = [];
  // eslint-disable-next-line fp/no-mutation, no-var
  for (var i = lowEnd; i <= highEnd; i++) {
    list.push(i);
  }
  return list;
}

export function checkForError(field: string, errorArray: string[]) {
  return errorArray && errorArray.includes(field) ? 'This field is required.' : '';
}

export function checkForMissingError(field: string, errorArray: string[], value: string) {
  return errorArray && errorArray.includes(field) && value === '' ? 'This field is required.' : '';
}
