import { caseConvertFetch } from 'src/components/customFetch';

function getStudentFinalSurvey(uuid) {
  return caseConvertFetch(`/survey/student-final-survey/${uuid}/`);
}

function updateStudentFinalSurvey(uuid, payload) {
  return caseConvertFetch(`/survey/student-final-survey/${uuid}/`, {
    method: 'PUT',
    body: { data: payload },
  });
}

function completeStudentFinalSurvey(uuid, payload) {
  return caseConvertFetch(`/survey/student-final-survey/${uuid}/complete/`, {
    method: 'PUT',
    body: { data: payload },
  });
}

/* eslint-disable-next-line import/no-anonymous-default-export, import/no-default-export -- autodisabled, autodisabled */
export default {
  getStudentFinalSurvey,
  updateStudentFinalSurvey,
  completeStudentFinalSurvey,
};
