import { CertificateUUID, ProjectStatus, Nullable } from '@polygence/common';
import * as certificatesApi from '@polygence/common/api/certificates';
import { StudentEndOfPodStatus } from '@polygence/common/types/data/pods';
import type { EndOfPodStatus, StudentPod } from '@polygence/common/types/pods';
import { Button, Card, Heading, Icon, Text, Tooltip } from '@polygence/components';
import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';

import DateWrapper, { DateFormat } from 'src/components/aux/dateWrapper';
import { useLazyGetStudentEndOfPodStatusQuery } from 'src/reducers/podsReducers';
import styles from 'src/students/Pods/ActivePodInfoCard.module.scss';
import { PodsEndOfPodAction } from 'src/students/Pods/PodsEndOfPodAction';

export const ActivePodInfoCard = ({ pod }: { pod: StudentPod }) => {
  const {
    id,
    signUpDeadline,
    startDate,
    workspaceId,
    projectStatus,
    projectEndDate,
    outcomeType,
    course: { image, title },
  } = pod;
  const formattedStartDate = new DateWrapper(startDate).format(DateFormat.FORMAT_12);
  const formattedSignUpDeadline = new DateWrapper(signUpDeadline).format(DateFormat.FORMAT_12);
  const formattedProjectEndDate = new DateWrapper(projectEndDate).format(DateFormat.FORMAT_7);
  const [certificate, setCertificate] = useState<Nullable<CertificateUUID>>(null);
  const [endOfPodData, setEndOfPodData] = useState<Nullable<EndOfPodStatus>>(null);
  const referLabel = workspaceId ? 'Refer a friend' : 'Invite a friend';
  const [getEndOfPodStatus] = useLazyGetStudentEndOfPodStatusQuery();

  const workspaceButton = workspaceId ? (
    <Button variant="primary" href={`/dashboard/hermes/${workspaceId}`}>
      Go to workspace
    </Button>
  ) : (
    <Tooltip
      tip={`Workspace will be available on ${formattedSignUpDeadline}`}
      trigger={['hover']}
      placement="top"
    >
      <Button variant="primary" disabled>
        Go to workspace
      </Button>
    </Tooltip>
  );

  const handleEndOfPodStatus = useCallback(() => {
    getEndOfPodStatus(id)
      .unwrap()
      .then((endOfPodData) => setEndOfPodData(endOfPodData))
      .catch(console.error);
  }, [getEndOfPodStatus, id]);

  useEffect(() => {
    if (projectStatus === ProjectStatus.COMPLETE) {
      certificatesApi
        .getCertificateForStudent(workspaceId)
        .then(({ data: certificateUuid }) => {
          return setCertificate(certificateUuid);
        })
        .catch(console.error);
      handleEndOfPodStatus();
    }
  }, [workspaceId, projectStatus, handleEndOfPodStatus]);

  return (
    <Card className="my-5">
      <div className="d-flex flex-row">
        <img src={image} alt={title} className={styles['image']} />

        <div className="w-100 p-8 d-flex flex-column gap-5 justify-content-center">
          <Text size="small" fontWeight="semibold" className="text-primary">
            {projectStatus !== ProjectStatus.COMPLETE
              ? 'Your upcoming pod:'
              : `Completed - ${formattedProjectEndDate}`}
          </Text>
          <Heading size="h5" alignment="left">
            {title}
          </Heading>
          <div className="w-100 d-flex flex-column flex-md-row align-items-center justify-content-between">
            {!projectStatus && (
              <Text size="small" className="text-muted">
                Starts {formattedStartDate}
              </Text>
            )}
            {projectStatus === ProjectStatus.COMPLETE && certificate && (
              <Text size="small">
                <a href={`/certificates/${certificate}`}>
                  <Text size="small" fontWeight="bold">
                    Download your certificate <Icon id="award" size="sm" />
                  </Text>
                </a>
              </Text>
            )}
            <div>
              <Button variant="secondary" href="/referral?product=pods" className="me-4">
                {referLabel}
              </Button>
              {workspaceButton}
            </div>
          </div>
        </div>
      </div>
      {projectStatus === ProjectStatus.COMPLETE && endOfPodData && (
        <div
          className={classNames(
            'd-flex flex-row justify-content-between align-items-center gap-4 my-8 mx-9 px-8 py-7',
            styles['completedBackground'],
          )}
        >
          <Text size="large" fontWeight="bold">
            {endOfPodData.status === StudentEndOfPodStatus.VIEW_PROGRAMS
              ? 'Congratulations on completing your Pod!'
              : 'Complete the following steps to wrap up your Pod!'}
          </Text>
          <PodsEndOfPodAction
            endOfPodData={endOfPodData}
            handleAction={handleEndOfPodStatus}
            outcomeType={outcomeType}
          />
        </div>
      )}
    </Card>
  );
};
