import { Alert } from '@polygence/components';

import { PaymentIntentPageLayout } from 'src/payment/PaymentIntent/PaymentIntentPageLayout';

interface PaymentIntentErrorProps {
  message: string;
}

export const PaymentIntentError = ({ message }: PaymentIntentErrorProps) => {
  return (
    <PaymentIntentPageLayout className="container tw-bg-transparent">
      <Alert variant="danger">{message}</Alert>
    </PaymentIntentPageLayout>
  );
};
