import { useCommonSelector } from '../../store/store';
import type { Room } from '../../types/hermes';

import useSelectedRoomId from './useSelectedRoomId';

// eslint-disable-next-line import/no-default-export -- autodisabled
export default function useSelectedRoom(): Room | undefined {
  const selectedRoomId = useSelectedRoomId();
  return useCommonSelector((state) => {
    if (selectedRoomId == null) {
      return undefined;
    }
    const rooms = state.hermes.rooms;
    return rooms.byId[selectedRoomId];
  });
}

export function useSelectedRoomOrThrow(): Room {
  const room = useSelectedRoom();
  if (room == null) {
    throw Error('Room is not selected.');
  }
  return room;
}
