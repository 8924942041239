import { Pager, PagerNavigation, PagerProgress, Spacer } from '@polygence/components';
import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { filterPagesBasedOnDisplay, validateInitialInputState } from 'src/students/fieldUtils';
import styles from 'src/students/form/MultiPageForm.module.scss';
import PagedFormSection from 'src/students/form/PagedFormSection';

const recursiveMap = (children, fn) => {
  return children.map((child) => {
    if (child == null) {
      return child;
    }
    if (Array.isArray(child)) {
      return recursiveMap(child, fn);
    }
    if (Array.isArray(child?.props?.children)) {
      return {
        ...child,
        props: { ...child.props, children: recursiveMap(child.props.children, fn) },
      };
    }
    return fn(child);
  });
};

const DefaultPager = () => {
  return (
    <div className="col-md-8 col-lg-6 mx-auto px-5">
      <Spacer size={6} />
      <PagerProgress pagesRemainingLabel="questions ahead!" />
      <Spacer size={2} />
    </div>
  );
};

const MultiPageForm = ({
  pages,
  data,
  handleChange,
  initialPage,
  addIdsToRouteFragments = false,
  onNext,
  onBeforeNext,
  onPrevious,
  onBeforeSubmit,
  submitLabel = 'Submit',
  onSubmit,
  BeforeForm = <DefaultPager />,
  fullScreen = true,
  autoStepTransitionEnabled = false,
}) => {
  const [filteredPages, setFilteredPages] = useState(pages);
  useEffect(() => {
    const filtered = pages.filter(filterPagesBasedOnDisplay(data));
    const pagesChanged =
      filtered.length !== filteredPages.length ||
      filtered.find((el, i) => {
        const page = filteredPages[i];
        if (page === undefined) {
          return false;
        }

        return el.id !== page.id;
      });
    if (pagesChanged) {
      setFilteredPages(filtered);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pages, data]);

  const history = useHistory();

  const forceValidatePage = ({ index }) => {
    const currentPage = {
      ...filteredPages[index],
      elements: recursiveMap(filteredPages[index].elements, validateInitialInputState),
    };

    setFilteredPages([
      ...filteredPages.slice(0, index),
      currentPage,
      ...filteredPages.slice(index + 1),
    ]);
  };

  const handlePageChange = useCallback(
    (activePage) => {
      if (addIdsToRouteFragments && filteredPages[activePage]) {
        const page = filteredPages[activePage];
        if (page) {
          history.push(`#${page.id}`);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filteredPages],
  );

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <Pager initialPage={initialPage} onPageChanged={handlePageChange}>
      {filteredPages.map(({ id, elements }) => {
        return (
          <PagedFormSection
            key={id}
            fields={elements}
            data={data}
            handleChange={(...args) => {
              handleChange(...args, id);
            }}
            fullScreen={fullScreen}
            beforeForm={BeforeForm}
            afterForm={
              <>
                {fullScreen && (
                  <>
                    <Spacer size={5} />
                    <Spacer size={12} />
                  </>
                )}
                <div
                  className={classNames(
                    'w-100',
                    fullScreen && 'fixed-bottom bg-white',
                    fullScreen && styles['bottomNavigationBar'],
                  )}
                >
                  <div className={classNames('mx-auto px-5', fullScreen && 'col-md-8 col-lg-6')}>
                    <PagerNavigation
                      submitLabel={submitLabel}
                      autoStepTransitionEnabled={autoStepTransitionEnabled}
                      onNext={({ index }) => onNext({ index }, id)}
                      onNextHover={forceValidatePage}
                      onSubmit={({ index }) => onSubmit({ index }, id)}
                      onPrevious={({ index }) => onPrevious({ index }, id)}
                      onBeforeNext={({ index }) => onBeforeNext({ index }, id)}
                      onBeforeSubmit={onBeforeSubmit}
                    />
                  </div>
                </div>
              </>
            }
          />
        );
      })}
    </Pager>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default MultiPageForm;
