import type {
  PathfinderApplicationInterest,
  PathfinderApplicationInterestCreate,
} from '@polygence/common';
import { axiosBaseQuery } from '@polygence/common/api/fetch';
import { createApi } from '@reduxjs/toolkit/query/react';

export const pathfinderApplicationInterestApi = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: 'pathfinderApplicationInterest',
  endpoints: (build) => {
    return {
      pathfinderApplicationInterest: build.query<PathfinderApplicationInterest, number>({
        query: (id) => {
          return `/student-application/pathfinder-application-interests/${id}/`;
        },
      }),
      createPathfinderApplicationInterest: build.mutation<
        PathfinderApplicationInterest,
        PathfinderApplicationInterestCreate
      >({
        query: (payload) => {
          return {
            url: `/student-application/pathfinder-application-interests/`,
            method: 'POST',
            data: payload,
          };
        },
      }),
      editPathfinderApplicationInterest: build.mutation<
        PathfinderApplicationInterest,
        { id: number; payload: Partial<Omit<PathfinderApplicationInterest, 'id'>> }
      >({
        query: ({ id, payload }) => {
          return {
            url: `/student-application/pathfinder-application-interests/${id}/`,
            method: 'PATCH',
            data: payload,
          };
        },
        onQueryStarted: async ({ id, payload }, { dispatch, queryFulfilled }) => {
          dispatch(
            pathfinderApplicationInterestApi.util.updateQueryData(
              'pathfinderApplicationInterest',
              id,
              (draft) => {
                Object.assign(draft, payload);
              },
            ),
          );

          const { data } = await queryFulfilled;
          if (!payload.notes) {
            dispatch(
              pathfinderApplicationInterestApi.util.updateQueryData(
                'pathfinderApplicationInterest',
                id,
                (draft) => {
                  Object.assign(draft, data);
                },
              ),
            );
          }
        },
      }),
    };
  },
});

export const {
  usePathfinderApplicationInterestQuery,
  useCreatePathfinderApplicationInterestMutation,
  useEditPathfinderApplicationInterestMutation,
} = pathfinderApplicationInterestApi;
