import type { UserInfoToken } from '@polygence/common';
import { Token } from '@polygence/common';
export { Token } from '@polygence/common';

interface AuthenticationTokenPair {
  access: string;
  refresh: string;
}

export const isLoggedIn = (): boolean => {
  return getToken(Token.Access) !== null;
};

export const isImpersonator = (): boolean => {
  return getToken(Token.OriginalAccess) !== null;
};

export const revertImpersonatorTokens = (): void => {
  const originalAccessToken = getToken(Token.OriginalAccess);
  const originalRefreshToken = getToken(Token.OriginalRefresh);

  if (originalAccessToken != null) {
    setToken(Token.Access, originalAccessToken);
  } else {
    removeToken(Token.Access);
  }

  if (originalRefreshToken != null) {
    setToken(Token.Refresh, originalRefreshToken);
  } else {
    removeToken(Token.Refresh);
  }

  removeToken(Token.OriginalAccess);
  removeToken(Token.OriginalRefresh);
};

export const setImpersonatorTokens = ({ access, refresh }: AuthenticationTokenPair): void => {
  const originalAccessToken = getToken(Token.Access);
  const originalRefreshToken = getToken(Token.Refresh);

  if (originalAccessToken !== null) {
    setToken(Token.OriginalAccess, originalAccessToken);
  }

  if (originalRefreshToken !== null) {
    setToken(Token.OriginalRefresh, originalRefreshToken);
  }

  setToken(Token.Access, access);
  setToken(Token.Refresh, refresh);
};

export const removeAuthenticationTokens = (): void => {
  removeToken(Token.Access);
  removeToken(Token.Refresh);
  removeToken(Token.OriginalAccess);
  removeToken(Token.OriginalRefresh);
};

export const getToken = (type: Token): string | null => {
  return localStorage.getItem(type);
};

export const getAccessToken = (): string | null => localStorage.getItem(Token.Access);
export const getRefreshToken = (): string | null => localStorage.getItem(Token.Refresh);

const setToken = (type: Token, token: string): void => {
  localStorage.setItem(type, token);
};

export const setAccessToken = (token: string): void => localStorage.setItem(Token.Access, token);
export const setRefreshToken = (token: string): void => localStorage.setItem(Token.Refresh, token);

const removeToken = (type: Token): void => {
  localStorage.removeItem(type);
};

export const userNameFromToken = (token: UserInfoToken): string =>
  `${token.first_name} ${token.last_name}`;
