/* eslint-disable fp/no-mutation */
import { PatchCollection, Recipe } from '@reduxjs/toolkit/dist/query/core/buildThunks';

import { hermesAiApi } from '../../api/hermes-ai';
import { getCommonStore } from '../../commonSettings';
import { HermesAIMessageThreadId } from '../../types/common';
import { HermesAIMessageThread, HermesAIMessageThreadMessages } from '../../types/hermes';

import { WebSocketMessage } from './messageTypes';

function checkForUnhandledValue(value: never): never {
  throw new Error(`Unhandled value in Hermes AI websocket: ${JSON.stringify(value)}`);
}

const updateCachedThreadList = (
  aiThreadId: HermesAIMessageThreadId,
  payload: Partial<HermesAIMessageThread>,
  incrementMessageCount = false,
) => {
  const store = getCommonStore();
  const selectedWorkspaceId = store.getState().hermes.selectedWorkspaceId;

  if (!selectedWorkspaceId) {
    return;
  }

  store.dispatch(
    hermesAiApi.util.updateQueryData('listMessageThreads', selectedWorkspaceId, (draft) => {
      const threadIdx = draft.findIndex((thread) => thread.id === aiThreadId);
      if (threadIdx > -1) {
        draft[threadIdx] = { ...draft[threadIdx]!, ...payload };

        if (incrementMessageCount) {
          draft[threadIdx]!.messageCount += 1;
        }
      }
    }),
  );
};

export const handleWebsocketMessage =
  (
    hermesAiMessageThreadId: HermesAIMessageThreadId,
    updateCachedData: (updateRecipe: Recipe<HermesAIMessageThreadMessages>) => PatchCollection,
  ) =>
  // eslint-disable-next-line sonarjs/cognitive-complexity
  (data: WebSocketMessage) => {
    if (data.aiThreadId !== hermesAiMessageThreadId) {
      return;
    }

    const command = data.command;

    switch (command) {
      case 'message_finish':
        updateCachedData((draft) => {
          draft.messages = draft.messages.filter((message) =>
            message.role === 'assistant' ? Boolean(message.id) : true,
          );

          draft.messages.unshift(data.message);
        });
        updateCachedThreadList(data.aiThreadId, { lastMessageAt: data.message.createdAt });
        break;
      case 'message_chunk':
        updateCachedData((draft) => {
          const lastAssistantMessageWithoutId = draft.messages.find(
            (message) => !message.id && message.role === 'assistant',
          );
          if (lastAssistantMessageWithoutId) {
            lastAssistantMessageWithoutId.content = data.content;
            if (data.toolCalls && data.toolCalls.length > 0) {
              lastAssistantMessageWithoutId.toolCalls = [
                ...(lastAssistantMessageWithoutId.toolCalls || []),
                ...data.toolCalls,
              ];
            }
          } else {
            draft.messages.unshift({
              role: 'assistant',
              content: data.content,
              toolCalls: data.toolCalls,
            });
          }
        });
        break;
      case 'conversation_title':
        updateCachedData((draft) => {
          draft.conversationTitle = data.content;
        });

        updateCachedThreadList(data.aiThreadId, { conversationTitle: data.content });
        break;
      case 'user_message':
        if (data.message.extras?.userSnippets && data.message.extras.userSnippets.length > 0) {
          getCommonStore().dispatch(
            hermesAiApi.util.invalidateTags([{ type: 'UserSnippets', id: data.aiThreadId }]),
          );
        }

        updateCachedThreadList(data.aiThreadId, { lastMessageAt: data.message.createdAt }, true);

        if (!data.message.clientId) {
          updateCachedData((draft) => {
            draft.messages.unshift(data.message);
          });
          break;
        }

        updateCachedData((draft) => {
          const messageIndex = draft.messages.findIndex(
            (message) => message.clientId === data.message.clientId,
          );
          if (messageIndex > -1) {
            draft.messages[messageIndex] = data.message;
          } else {
            draft.messages.unshift(data.message);
          }
        });
        break;
      case 'update_message':
        updateCachedData((draft) => {
          const messageIndex = draft.messages.findIndex((message) => message.id === data.id);
          if (messageIndex > -1) {
            draft.messages[messageIndex]!.content = data.content;
          }
        });
        break;
      case 'error':
        break;
      default:
        checkForUnhandledValue(command);
    }
  };
