import type React from 'react';

import activeProject from 'src/components/static/icons/active-project.svg';
import blockQuote from 'src/components/static/icons/block-quote.svg';
import book from 'src/components/static/icons/book.svg';
import brush from 'src/components/static/icons/brush.svg';
import bullhorn from 'src/components/static/icons/bullhorn.svg';
import celebration from 'src/components/static/icons/celebration.svg';
import check from 'src/components/static/icons/check.svg';
import codeBlock from 'src/components/static/icons/code-block.svg';
import colab from 'src/components/static/icons/colab.svg';
import completedProject from 'src/components/static/icons/completed-project.svg';
import cross from 'src/components/static/icons/cross.svg';
import cup from 'src/components/static/icons/cup.svg';
import django from 'src/components/static/icons/django.svg';
import document from 'src/components/static/icons/document.svg';
import enrolledProject from 'src/components/static/icons/enrolled-project.svg';
import flag from 'src/components/static/icons/flag.svg';
import glasses from 'src/components/static/icons/glasses.svg';
import globe from 'src/components/static/icons/globe.svg';
import googleDrive from 'src/components/static/icons/google-drive.svg';
import graduationHat from 'src/components/static/icons/graduation-hat.svg';
import human from 'src/components/static/icons/human.svg';
import information from 'src/components/static/icons/information.svg';
import lightbulb from 'src/components/static/icons/lightbulb.svg';
import link from 'src/components/static/icons/link.svg';
import loading from 'src/components/static/icons/loading.svg';
import magnifierIn from 'src/components/static/icons/magnifier-in.svg';
import magnifierOut from 'src/components/static/icons/magnifier-out.svg';
import money from 'src/components/static/icons/money.svg';
import mortarboard from 'src/components/static/icons/mortarboard.svg';
import numberedList from 'src/components/static/icons/numbered-list.svg';
import outArrow from 'src/components/static/icons/out-arrow.svg';
import pen from 'src/components/static/icons/pen.svg';
import pencil from 'src/components/static/icons/pencil.svg';
import plus from 'src/components/static/icons/plus.svg';
import presentation from 'src/components/static/icons/presentation.svg';
import quote from 'src/components/static/icons/quote.svg';
import rocket from 'src/components/static/icons/rocket.svg';
import scholar from 'src/components/static/icons/scholar.svg';
import setting from 'src/components/static/icons/setting.svg';
import sixDots from 'src/components/static/icons/six-dots.svg';
import spreadsheet from 'src/components/static/icons/spreadsheet.svg';
import strikethrough from 'src/components/static/icons/strikethrough.svg';
import suitcase from 'src/components/static/icons/suitcase.svg';
import tick from 'src/components/static/icons/tick.svg';
import trash from 'src/components/static/icons/trash.svg';
import userX from 'src/components/static/icons/user-x.svg';
import starSharp from 'src/components/static/images/icons/star_sharp.svg';

const localIcons = {
  activeProject,
  blockQuote,
  book,
  brush,
  bullhorn,
  celebration,
  check,
  codeBlock,
  colab,
  completedProject,
  cross,
  cup,
  django,
  document,
  enrolledProject,
  flag,
  glasses,
  globe,
  googleDrive,
  graduationHat,
  human,
  information,
  lightbulb,
  link,
  loading,
  magnifierIn,
  magnifierOut,
  money,
  mortarboard,
  numberedList,
  outArrow,
  pen,
  pencil,
  plus,
  presentation,
  rocket,
  quote,
  scholar,
  setting,
  sixDots,
  spreadsheet,
  starSharp,
  strikethrough,
  suitcase,
  tick,
  trash,
  userX,
} as const;

interface IconProps {
  color?: string;
  size?: string;
  width?: string;
  height?: string;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler;
  className?: string;
}

interface LocalIconProps {
  local: true;
  name: keyof typeof localIcons;
}

interface RemoteIconProps {
  local?: false;
  name: string;
}

export const Icon = ({
  name,
  color,
  size = '24px',
  local,
  ...props
}: IconProps & (LocalIconProps | RemoteIconProps)) => {
  const { width = props.width || size, height = props.height || size, style, ...rest } = props;
  const url = local
    ? `url(${localIcons[name]})`
    : `url(https://dpl6hyzg28thp.cloudfront.net/icons/${name}.svg)`;
  const iconStyle = color
    ? {
        backgroundColor: color,
        maskImage: url,
        WebkitMaskImage: url,
        maskSize: 'cover',
        WebkitMaskSize: 'cover',
      }
    : {
        backgroundImage: url,
        backgroundSize: 'cover',
      };

  return (
    <i
      // eslint-disable-next-line react/forbid-dom-props
      style={{
        display: 'inline-block',
        width,
        height,
        ...iconStyle,
        ...style,
      }}
      {...rest}
    />
  );
};
