import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { ProjectId } from '../../types/common';
import type { Modal, Nullable } from '../../types/utils';

interface ShowcasingAddonState {
  showcasingPurchaseModal: Modal<{ projectId: Nullable<ProjectId> }>;
  showcasingMatchingModal: Modal<{ projectId: Nullable<ProjectId> }>;
}

const defaults: ShowcasingAddonState = {
  showcasingPurchaseModal: { open: false, projectId: null },
  showcasingMatchingModal: { open: false, projectId: null },
};

const showcasingAddon = createSlice({
  name: 'showcasingAddon',
  initialState: {
    ...defaults,
  },
  reducers: {
    openPurchaseModal: (state, { payload }: PayloadAction<ProjectId>) => {
      state.showcasingPurchaseModal.open = true;
      state.showcasingPurchaseModal.projectId = payload;
    },
    closePurchaseModal: (state) => {
      state.showcasingPurchaseModal.open = false;
      state.showcasingPurchaseModal.projectId = null;
    },
    openMatchingModal: (state, { payload }: PayloadAction<ProjectId>) => {
      state.showcasingMatchingModal.open = true;
      state.showcasingMatchingModal.projectId = payload;
    },
    closeMatchingModal: (state) => {
      state.showcasingMatchingModal.open = false;
      state.showcasingMatchingModal.projectId = null;
    },
  },
});

const { actions, reducer } = showcasingAddon;

export const showcasingAddonActions = actions;

export const showcasingAddonReducer = reducer;
