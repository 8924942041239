import { Heading, usePager } from '@polygence/components';
import { Container } from 'react-bootstrap';

export const RequiredPodInformationHeader = () => {
  const { activePage, numberOfPages } = usePager();
  const steps = numberOfPages - activePage;
  const secureYourSpotText =
    steps === 1 ? 'Last step to secure your spot!' : `${steps} steps to secure your spot!`;

  return (
    <Container>
      <Heading size="h4" className="mb-5">
        {secureYourSpotText}
      </Heading>
    </Container>
  );
};
