import { useCommonSelector } from '../../store/store';
import type { Meeting } from '../../types/hermes';
import { isMeetingJoinableByTime } from '../../utils/zoomJoinAvailability';

import useSelectedWorkspaceId from './useSelectedWorkspaceId';

export function useCurrentMeeting(): Meeting | undefined {
  const workspaceId = useSelectedWorkspaceId();
  const meetings = useCommonSelector((state) => {
    return Object.values(state.hermes.meetings.byId);
  });
  return meetings
    .filter((meeting) => {
      return meeting.workspaceId === workspaceId;
    })
    .find(isMeetingJoinableByTime);
}
