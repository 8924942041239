import {
  illustrationBySection,
  PathfindersExpectations,
} from 'src/components/student/pathfinders/illustrations';
import { ApplicationFieldTypes } from 'src/constants/application-field-types';
import { ApplicationSectionId, ApplicationSectionIds } from 'src/constants/application-sections';
import {
  ApplicationComponentDescription,
  ApplicationComponentSection,
  studentApplicationStudentPaysWorkflowSections,
} from 'src/students/student-application';
import {
  headingProps,
  illustrationSize,
} from 'src/students/student-application/common-section-components';

export const PATHFINDERS_PROFILE_SECTION_IDS = [
  ApplicationSectionIds.SCHOOL_SECTION_ID,
  ApplicationSectionIds.COUNSELOR_SECTION_ID,
  ApplicationSectionIds.PARENT_SECTION_ID,
  ApplicationSectionIds.REFERRAL_SECTION_ID,
] as readonly ApplicationSectionId[];

const pathfinderApplicationSections = Object.freeze<ApplicationComponentSection[]>([
  {
    id: ApplicationSectionIds.PATHFINDER_INTEREST_1,
    elements: [
      {
        component: 'ThreeColumnLayout',
        props: {
          children: [
            null,
            [
              {
                component: 'PathfinderInterests',
                tag: ApplicationFieldTypes.PATHFINDER_APPLICATION,
                props: {
                  name: 'interests',
                  interestIndex: 0,
                },
                validation: {
                  pathfinderInterest: true,
                },
              },
            ],
          ],
        },
      },
    ],
  },
  {
    id: ApplicationSectionIds.PATHFINDER_INTEREST_2,
    elements: [
      {
        component: 'ThreeColumnLayout',
        props: {
          children: [
            null,
            [
              {
                component: 'PathfinderInterests',
                tag: ApplicationFieldTypes.PATHFINDER_APPLICATION,
                props: {
                  name: 'interests',
                  interestIndex: 1,
                },
                validation: {
                  pathfinderInterest: true,
                },
              },
            ],
          ],
        },
      },
    ],
  },
  {
    id: ApplicationSectionIds.PATHFINDER_INTEREST_3,
    elements: [
      {
        component: 'ThreeColumnLayout',
        props: {
          children: [
            null,
            [
              {
                component: 'PathfinderInterests',
                tag: ApplicationFieldTypes.PATHFINDER_APPLICATION,
                props: {
                  name: 'interests',
                  interestIndex: 2,
                },
                validation: {
                  pathfinderInterest: true,
                },
              },
            ],
          ],
        },
      },
    ],
  },
  {
    id: ApplicationSectionIds.EXPECTATIONS_SECTION_ID,
    elements: [
      {
        component: 'ThreeColumnLayout',
        props: {
          children: [
            null,
            [
              {
                component: 'CenteredIllustration',
                props: {
                  illustration: PathfindersExpectations,
                  size: illustrationSize,
                },
              },
              {
                component: 'Spacer',
                props: {
                  size: {
                    base: 9,
                    lg: 10,
                  },
                },
              },
              {
                component: 'ComponentsHeading',
                props: {
                  children: 'How do you expect this program will help you?',
                  ...headingProps,
                },
              },
              {
                component: 'Spacer',
                props: {
                  size: {
                    base: 9,
                    lg: 10,
                  },
                },
              },
              {
                component: 'ComponentsText',
                props: {
                  children: 'Complete the following two sentences:',
                  size: 'large',
                  alignment: 'center',
                },
              },
              {
                component: 'Spacer',
                props: {
                  size: 6,
                },
              },
              {
                component: 'ComponentsText',
                props: {
                  children:
                    'This helps both Polygence and your future mentors have more context on where you’re at right now and what you are looking for',
                  size: 'medium',
                  alignment: 'center',
                },
              },
              {
                component: 'Spacer',
                props: {
                  size: 6,
                },
              },
              {
                component: 'ComponentsText',
                props: {
                  children: 'I am coming into this program...',
                  size: {
                    base: 'medium',
                    lg: 'large',
                  },
                  fontWeight: 'bold',
                },
              },
              {
                component: 'Spacer',
                props: {
                  size: 6,
                },
              },
              {
                component: 'InputField',
                tag: ApplicationFieldTypes.PATHFINDER_APPLICATION,
                props: {
                  type: 'textarea',
                  name: 'programExpectation',
                  placeholder:
                    'Feeling curious about what a day in the life of XYZ is like\nThinking I want to go into XYZ field\nCompletely unsure about my career options',
                  showWordCount: true,
                },
                validation: {
                  required: true,
                  wordCount: {
                    minimum: 8,
                    maximum: 100,
                  },
                },
              },
              {
                component: 'Spacer',
                props: { size: 9 },
              },
              {
                component: 'ComponentsText',
                props: {
                  children: 'I hope to leave this program...',
                  size: {
                    base: 'medium',
                    lg: 'large',
                  },
                  fontWeight: 'bold',
                },
              },
              {
                component: 'Spacer',
                props: { size: 6 },
              },
              {
                component: 'InputField',
                tag: ApplicationFieldTypes.PATHFINDER_APPLICATION,
                props: {
                  type: 'textarea',
                  name: 'outputExpectation',
                  placeholder:
                    'Feeling clearer about what the possibilities of XYZ field are\nKnowing what my college major will likely be\nMore informed on what I don’t want to do',
                  showWordCount: true,
                },
                validation: {
                  required: true,
                  wordCount: {
                    minimum: 8,
                    maximum: 100,
                  },
                },
              },
            ],
          ],
        },
      },
    ],
  },
]);

export const PATHFINDERS_PROFILE_SECTIONS = studentApplicationStudentPaysWorkflowSections.filter(
  ({ id }) => PATHFINDERS_PROFILE_SECTION_IDS.includes(id),
);

const pathfinderSections = [...pathfinderApplicationSections, ...PATHFINDERS_PROFILE_SECTIONS];

const changeBackground = (section: ApplicationComponentSection): ApplicationComponentSection => {
  const newSection = {
    ...section,
    elements: [...section.elements],
  };

  if (newSection.elements[0]) {
    // eslint-disable-next-line fp/no-mutation
    newSection.elements[0] = { ...newSection.elements[0], background: 'background-triangle' };
  }

  return newSection;
};

const filterParentDisclaimer = (
  section: ApplicationComponentSection,
): ApplicationComponentSection => {
  if (section.id === ApplicationSectionIds.PARENT_SECTION_ID) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const page = section.elements[0]!;

    const newPageChildren = (page.props.children as ApplicationComponentDescription[][])[1]?.filter(
      (element) =>
        element.component !== 'ComponentsText' ||
        !(element.props.children as string).startsWith('If you are accepted to the program'),
    );

    const newPage = { ...page, props: { ...page.props, children: [null, newPageChildren, null] } };

    return { ...section, elements: [newPage] };
  }

  return section;
};

const changeIcons = (section: ApplicationComponentSection) => {
  const illustration = illustrationBySection[section.id];

  if (illustration) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const page = section.elements[0]!;

    const newPageChildren = (page.props.children as ApplicationComponentDescription[][])[1]?.map(
      (element) => {
        if (element.component === 'CenteredIllustration') {
          return {
            ...element,
            props: {
              ...element.props,
              name: undefined,
              illustration,
            },
          };
        }

        return element;
      },
    );

    const newPage = { ...page, props: { ...page.props, children: [null, newPageChildren, null] } };

    return { ...section, elements: [newPage] };
  }

  return section;
};

const removeAdmissionAdvisorModalInfo = (
  section: ApplicationComponentSection,
): ApplicationComponentSection => {
  return {
    ...section,
    elements: section.elements.map((element) => {
      if (element.component !== 'ThreeColumnLayout' || !Array.isArray(element.props.children)) {
        return element;
      }

      const children = (element.props.children as ApplicationComponentDescription[][]).map(
        (child) => {
          return child
            ? child.map((c) =>
                c.component === 'AdmissionAdvisorModalInfo'
                  ? (null as unknown as ApplicationComponentDescription)
                  : c,
              )
            : child;
        },
      );

      return {
        ...element,
        props: {
          ...element.props,
          children,
        },
      };
    }),
  };
};

export const PATHFINDERS_APPLICATION_SECTIONS = pathfinderSections
  .map(changeBackground)
  .map(filterParentDisclaimer)
  .map(changeIcons)
  .map(removeAdmissionAdvisorModalInfo);
