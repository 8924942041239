import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { PublicCollegeAdmissionSurvey } from 'src/components/CollegeAdmissionSurvey/PublicCollegeAdmissionSurvey';
import { WorldStridesParentToChildInvitationPage } from 'src/components/WorldStrides/WorldStridesParentToChildInvitationPage';
import { PrivateRoute } from 'src/components/authenticatedRoutes';
import FinalMentorSurvey from 'src/components/hermes/FinalMentorSurvey';
import { AdmissionAdvisorCalendlyRedirect } from 'src/components/specialURLs/AdmissionAdvisorCalendlyRedirect';
import ContinuedInterest from 'src/components/specialURLs/ContinuedInterest';
import { ReviewStudent } from 'src/components/specialURLs/ReviewStudent';
import { ShowcasingSupportMatchingAnswer } from 'src/components/specialURLs/ShowcasingSupportMatchingAnswer';
import StudentURLResponse from 'src/components/specialURLs/StudentURLResponse';
import WaitlistInvitation from 'src/components/specialURLs/WaitlistInvitation';
import { WritingFeedbackMentorReply } from 'src/components/specialURLs/WritingFeedbackMentorReply';
import { WritingFeedbackSubmit } from 'src/components/specialURLs/WritingFeedbackSubmit';

export const SignedURLs = () => {
  const match = useRouteMatch();
  return (
    <div>
      <Switch>
        <Route
          path={`${match.url}/continued-interest`}
          render={(props) => {
            return <ContinuedInterest {...props} />;
          }}
        />
        <Route
          path={`${match.url}/student-url-response`}
          render={(props) => {
            return <StudentURLResponse {...props} />;
          }}
        />
        <Route
          path={[
            `${match.url}/review-student/:uuid/accept`,
            `${match.url}/review-student/:uuid/reject`,
          ]}
          render={(props) => {
            return <ReviewStudent {...props} />;
          }}
        />
        <Route
          path={[
            `${match.url}/mentor-requests/:uuid/waitlist/yes`,
            `${match.url}/mentor-requests/:uuid/waitlist/no`,
          ]}
          render={(props) => {
            return <WaitlistInvitation {...props} />;
          }}
        />
        <PrivateRoute
          path={[`${match.url}/premium-showcasing-support/:uuid/:action(accept|decline)`]}
          component={ShowcasingSupportMatchingAnswer}
        />
        <PrivateRoute
          path={[`${match.url}/final-mentor-survey/:uuid`]}
          component={FinalMentorSurvey}
        />
        <Route
          path={[`${match.url}/admission_advisor_calendly/:uuid`]}
          component={AdmissionAdvisorCalendlyRedirect}
        />
        <PrivateRoute
          component={WritingFeedbackMentorReply}
          path={`${match.url}/writing-feedback/:uuid/mentor-reply`}
        />
        <Route path={[`${match.url}/writing-feedback/:uuid`]} component={WritingFeedbackSubmit} />
        <Route
          path={[`${match.url}/college-admission-survey/:uuid`]}
          component={PublicCollegeAdmissionSurvey}
        />
        <Route
          path={[`${match.url}/worldstrides/parent-to-child-invitation/:uuid`]}
          component={WorldStridesParentToChildInvitationPage}
        />
      </Switch>
    </div>
  );
};
