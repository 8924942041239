import { FLAGSHIP_NAME } from '@polygence/common/constants/productNames';
import { useMemo } from 'react';
import { useParams } from 'react-router';

import { PaymentSuccessDefaultMessage } from 'src/payment/PaymentSuccess/PaymentSuccessDefaultMessage';
import { PaymentSuccessTokenWorkflowMessage } from 'src/payment/PaymentSuccess/PaymentSuccessTokenWorkflowMessage';
import { useGetCartQuery } from 'src/reducers/paymentReducer';

export const PaymentSuccessPage = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const { data: cart } = useGetCartQuery(uuid);

  const containsFlagship = useMemo(
    () => !!cart?.addedProducts.some(({ name }) => name === FLAGSHIP_NAME),
    [cart],
  );

  return (
    <div className="tw-mx-5 tw-mt-24 tw-flex tw-flex-col tw-items-center tw-justify-center">
      {cart?.tokenWorkflowEnabled ? (
        <PaymentSuccessTokenWorkflowMessage />
      ) : (
        <PaymentSuccessDefaultMessage containsFlagship={containsFlagship} cartUser={cart?.user} />
      )}
    </div>
  );
};
