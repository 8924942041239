import TimeMe from 'timeme.js';

import createInputTracker from 'src/components/aux/inputTracker';
import type { ApplicationSectionId } from 'src/constants/application-sections';
import { getTracker } from 'src/utils/tracking/factory';

export const trackingCategory = 'pathfinder_student_application';

const tracker = getTracker();

const trackPathfinderApplicationStep = ({
  action,
  pageIndex,
  sectionName,
  applicationId,
}: {
  action: string;
  pageIndex: number;
  sectionName: string;
  applicationId: string;
}) => {
  tracker.track(action, {
    category: trackingCategory,
    value: pageIndex,
    sectionName,
    applicationId,
  });
};

const trackActiveTimeSpentOnPage = ({
  action,
  pageIndex,
  sectionName,
  applicationId,
}: {
  action: string;
  pageIndex: number;
  sectionName: string;
  applicationId: string;
}) => {
  const activeTime = Math.round(TimeMe.getTimeOnPageInSeconds(`Section-${pageIndex}`));
  tracker.track(action, {
    category: trackingCategory,
    pageIndex,
    time: activeTime,
    sectionName,
    applicationId,
  });
};

export const trackPathfinderApplicationGoToNextPage =
  (completedSections: string[] = [], applicationId: string) =>
  ({ index }: { index: number }, sectionName: ApplicationSectionId) => {
    if (!completedSections.includes(sectionName)) {
      trackPathfinderApplicationStep({
        action: `Pathfinder Section ${sectionName} Completed`,
        pageIndex: index,
        sectionName: sectionName,
        applicationId,
      });
    }

    trackPathfinderApplicationStep({
      action: 'Pathfinder Go to next page',
      pageIndex: index,
      sectionName: sectionName,
      applicationId,
    });

    trackActiveTimeSpentOnPage({
      action: `Active time spent On Pathfinder Section ${sectionName}`,
      pageIndex: index,
      sectionName: sectionName,
      applicationId,
    });
  };

export const trackPathfinderApplicationGoToPreviousPage =
  (applicationId: string) =>
  ({ index }: { index: number }, sectionName: ApplicationSectionId) => {
    trackPathfinderApplicationStep({
      action: 'Pathfinder Go to previous page',
      pageIndex: index,
      sectionName: sectionName,
      applicationId,
    });
  };

export const trackPathfinderApplicationStarted = (applicationId: string) => {
  trackPathfinderApplicationStep({
    action: 'Pathfinder application started',
    pageIndex: 0,
    sectionName: '',
    applicationId,
  });
};

export const trackPathfinderApplicationCompleted = (
  { index }: { index: number },
  sectionName: ApplicationSectionId,
  applicationId: string,
) => {
  trackPathfinderApplicationStep({
    action: 'Pathfinder application completed',
    pageIndex: index,
    sectionName: sectionName,
    applicationId,
  });
};

export const trackPathfinderInterimPageOpened = (
  applicationId: number,
  applicationUuid: string,
) => {
  tracker.track('Pathfinder interim page opened', {
    category: trackingCategory,
    applicationId,
    applicationUuid,
  });
};

export const trackPathfinderInterimPageCheckout = (
  applicationId: number,
  applicationUuid: string,
) => {
  tracker.track('Pathfinder interim page checkout', {
    category: trackingCategory,
    applicationId,
    applicationUuid,
  });
};

export const trackPathfinderApplicationInput = createInputTracker(trackingCategory, 'form_field');
