import * as siteContentApi from '@polygence/common/api/site-content';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';

import { ReactMarkdown } from 'src/components/aux/markdown/ReactMarkdown';
import { HeadingRenderer, LinkRenderer } from 'src/components/aux/markdown/markdownHelperFunctions';
import Logo from 'src/components/static/images/polygence_logo.png';

export const Announcement = ({ guidebook_tag: guidebookTag }) => {
  const [state, setState] = useState({
    bookLoaded: false,
    guidebook: {},
  });
  const { guidebook, bookLoaded } = state;

  const fetchGuidebook = useCallback(() => {
    siteContentApi
      .getLegacyMarkdownPublicGuidebook(guidebookTag)
      .then((result) => {
        const { data } = result;
        if (data) {
          setState((prevState) => {
            return {
              ...prevState,
              guidebook: data === undefined || data.length === 0 ? {} : data[0],
              bookLoaded: true,
            };
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [guidebookTag]);

  useEffect(() => {
    fetchGuidebook();
  }, [fetchGuidebook]);

  if (Object.keys(guidebook).length === 0 && guidebook.constructor === Object) {
    return (
      // eslint-disable-next-line react/jsx-filename-extension -- autodisabled
      <div className="col-md-6 offset-md-3 text-center">
        This page is empty or you do not have access to this resource.
      </div>
    );
  }

  let markdownText;
  if (bookLoaded) {
    // eslint-disable-next-line fp/no-mutation -- autodisabled
    markdownText = guidebook?.body;
  }

  return (
    <div className="col-md-6 offset-md-3 mb-10 text-justify">
      <div className="text-center mt-5">
        <img
          alt="Polygence logo"
          src={Logo}
          // eslint-disable-next-line react/forbid-dom-props -- autodisabled
          style={{
            height: '60px',
          }}
        />
      </div>
      <h5 className="my-7 text-center">
        <ReactMarkdown components={{ heading: HeadingRenderer, link: LinkRenderer }}>
          {guidebook?.title}
        </ReactMarkdown>
      </h5>
      <div>
        <ReactMarkdown components={{ heading: HeadingRenderer, link: LinkRenderer }}>
          {markdownText}
        </ReactMarkdown>
      </div>
    </div>
  );
};

Announcement.propTypes = {
  guidebook_tag: PropTypes.string,
};
