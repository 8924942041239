import { OwnMentorProfileSerializer } from '@polygence/common';
import { useEffect } from 'react';

import { FormInputWithValidation as FormInput } from 'src/components/FormInputWithValidation';
import { generateRange, isNullString } from 'src/utils/formHelpFunctions';

interface ExperienceInputProps {
  number: 1 | 2 | 3 | 4;
  profile: OwnMentorProfileSerializer;
  onChange: ({ target: { name, value } }: { target: { name: string; value: string } }) => void;
}
const convertNumbersToStrings = (numbers: number[]) => numbers.map((number) => number.toString());
const dates = generateRange(1920, new Date().getFullYear()).reverse();
const datesPresent = ['Current'].concat(convertNumbersToStrings(dates));

export const ExperienceInput = ({
  number,
  profile,
  onChange: handleChange,
}: ExperienceInputProps) => {
  const experienceStart =
    isNullString(profile[`experience_${number}_start`]) || new Date().getFullYear();
  const experienceEnd = isNullString(profile[`experience_${number}_end`]) || 'Current';

  useEffect(() => {
    handleChange({
      target: { name: `experience_${number}_start`, value: experienceStart.toString() },
    });
    handleChange({
      target: { name: `experience_${number}_end`, value: experienceEnd },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <FormInput
        type="text"
        name={`experience_institution_${number}`}
        placeholder=""
        label="Institution or company"
        value={isNullString(profile[`experience_institution_${number}`])}
        onChange={handleChange}
        onError={['']}
        required={false}
      />
      <FormInput
        type="text"
        name={`experience_${number}`}
        placeholder=""
        label="Your position"
        value={isNullString(profile[`experience_${number}`])}
        onChange={handleChange}
        onError={['']}
        required={false}
      />
      <FormInput
        inputType="select"
        name={`experience_${number}_start`}
        placeholder=""
        label="Start"
        value={experienceStart}
        onChange={handleChange}
        onError={['']}
        required={false}
        options={convertNumbersToStrings(dates)}
      />
      <FormInput
        inputType="select"
        name={`experience_${number}_end`}
        placeholder=""
        label="End"
        value={experienceEnd}
        onChange={handleChange}
        onError={['']}
        required={false}
        options={datesPresent}
      />
    </>
  );
};
