import { Combobox } from '@headlessui/react';
import type { UserType } from '@polygence/common';
import * as guidebooksApi from '@polygence/common/api/guidebooks';
import type { GuidebookPage } from '@polygence/common/types/guidebooks';
import { Icon } from '@polygence/components';
import classNames from 'classnames';
import { debounce } from 'lodash';
import { useEffect, useState, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { SpinningWheel } from 'src/components/SpinningWheel';
import { isMacOs } from 'src/utils/isiOS';
import 'src/components/Guidebook/GuidebookSearch/guidebookSearch.scss';

export const GuidebookSearch = ({ userTypes = [] }: { userTypes: UserType[] }) => {
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState<GuidebookPage[]>([]);
  const [loading, setLoading] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const getGuidebooks = (search: string) => {
    setSearchTerm(search);
    if (!search) {
      setLoading(false);
      return;
    }
    guidebooksApi
      .getGuidebookPages({ search, userTypes })
      .then(({ data }) => {
        setResults(data);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedGetGuidebooks = useCallback(debounce(getGuidebooks, 500), []);

  useEffect(() => {
    const onKeydown = (event: WindowEventMap['keydown']) => {
      if (event.key === 'k' && (event.metaKey || event.ctrlKey) && inputRef.current != null) {
        event.preventDefault();
        inputRef.current.focus();
      }
    };

    window.addEventListener('keydown', onKeydown);

    return () => {
      window.removeEventListener('keydown', onKeydown);
    };
  }, []);

  const icon = loading ? (
    <SpinningWheel width={24} height={24} />
  ) : (
    <Icon id="search" size="lg" color="var(--grayscale-5)" />
  );

  return (
    <div className={classNames('d-flex justify-content-center my-11')}>
      <Combobox
        as="div"
        onChange={(path: string) => {
          history.push(path);
        }}
        className="guidebookSearch__combobox"
      >
        {({ open: comboboxIsOpen }) => (
          <>
            <div className="d-flex align-items-center guidebookSearch__search-box">
              <div className="guidebookSearch__icon">{icon}</div>
              <Combobox.Input
                ref={inputRef}
                className={classNames('px-10 pt-5 pb-4 guidebookSearch__input', {
                  comboboxIsOpen: comboboxIsOpen && searchTerm !== '',
                })}
                onChange={(event) => {
                  if (event.target.value) {
                    setLoading(true);
                  }
                  debouncedGetGuidebooks(event.target.value);
                }}
                placeholder="Search keyword like showcase, writing, comp sci..."
              />
              <kbd className="guidebookSearch__kbd">
                <abbr title={isMacOs() ? 'Command' : 'Control'}>{isMacOs() ? '⌘' : 'Ctrl+'}</abbr>K
              </kbd>
            </div>
            {comboboxIsOpen && searchTerm !== '' && (
              <Combobox.Options
                static
                className="d-flex flex-column gap-3 p-5 guidebookSearch__combobox-options"
              >
                {results.length === 0 && !loading && (
                  <div className="guidebookSearch__no-results">No results for "{searchTerm}"</div>
                )}
                {results.length > 0 &&
                  results.map((result) => {
                    return (
                      <Combobox.Option key={result.id} value={result.url}>
                        {({ active }) => (
                          <div
                            className={classNames('px-5 py-3 guidebookSearch__combobox-option', {
                              'guidebookSearch__active-option': active,
                            })}
                          >
                            {result.notionPageTitle}
                          </div>
                        )}
                      </Combobox.Option>
                    );
                  })}
              </Combobox.Options>
            )}
          </>
        )}
      </Combobox>
    </div>
  );
};
