import { UserType, commonHooks } from '@polygence/common';
import { Button, Heading, Text } from '@polygence/components';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import styles from 'src/components/Tiles/Counselor/ProjectIdeaGeneratorTile.module.scss';
import tileStyles from 'src/components/Tiles/tilesDashboard.module.scss';
import { urls } from 'src/urls';

export const ProjectIdeaGeneratorTile = () => {
  const currentUser = commonHooks.useCurrentUser();
  const heading =
    currentUser.userType === UserType.STUDENT
      ? 'Explore unique project ideas that match your interests using the PolyGPT tool'
      : 'Help your students explore project ideas with the help of the PolyGPT tool';
  const subHeading =
    currentUser.userType === UserType.STUDENT
      ? 'Start brainstorming and create your own list of project ideas!'
      : 'Brainstorm and create a list of project ideas for your students!';
  return (
    <div className={classNames('tw-p-6', styles['projectIdeaGeneratorTile'], tileStyles['tile'])}>
      <Heading
        size="h4"
        className={classNames('tw-mb-3 tw-w-5/6 tw-p-0', styles['tileTitle'])}
        alignment="left"
      >
        {heading}
      </Heading>
      <Text size="medium" className={classNames('tw-text-gray-10 tw-mb-3 tw-text-gray-100')}>
        {subHeading}
      </Text>
      <Button
        variant="primary"
        // @ts-expect-error see BSH-5057
        as={Link}
        className={styles['ideaGeneratorTileButton']}
        to={urls.projectIdeaGenerator()}
      >
        <Text size="medium" fontWeight="bold">
          Use PolyGPT
        </Text>
      </Button>
      <img
        className={styles['illustration']}
        src="https://dpl6hyzg28thp.cloudfront.net/media/poly-gpt.jpeg"
        alt="Illustration"
      />
    </div>
  );
};
