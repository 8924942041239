import { Icon } from '@polygence/components';
import type { DropdownTriggerProps } from '@reach/dropdown';
import { MenuButton } from '@reach/menu-button';
import { ForwardedRef, forwardRef, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

import { DropdownMenu } from 'src/components/DropdownMenu';
import type { NavbarNavigationItem } from 'src/utils/navigation';

export interface NavbarDropdownMenuProps {
  label: string;
  items?: NavbarNavigationItem[];
}

export interface NavbarDropdownMenuButtonProps extends DropdownTriggerProps {
  children: ReactNode;
  isExpanded: boolean;
  items: NavbarNavigationItem[];
}

const NavbarDropdownMenuButton = forwardRef(
  (
    { children, isExpanded, items, ...props }: NavbarDropdownMenuButtonProps,
    ref: ForwardedRef<HTMLLIElement>,
  ) => {
    return (
      <li ref={ref} {...props}>
        <NavLink to="#" isActive={() => isExpanded}>
          {children}
        </NavLink>
      </li>
    );
  },
);

export const NavbarDropdownMenu = ({ items = [], label }: NavbarDropdownMenuProps) => {
  return (
    <DropdownMenu items={items}>
      {({ isExpanded }) => (
        <MenuButton as={NavbarDropdownMenuButton} isExpanded={isExpanded} items={items}>
          {label}
          <Icon id="chevron-down" size="sm" className="ms-2" />
        </MenuButton>
      )}
    </DropdownMenu>
  );
};
