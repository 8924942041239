import { ApplicationSectionIds } from 'src/constants/application-sections';
import type { ApplicationComponentSection } from 'src/students/student-application';
import {
  contentSpacer,
  headingProps,
  headingSpacer,
  illustrationSize,
} from 'src/students/student-application/common-section-components';
import { generalInterestOptions } from 'src/students/student-application/general-interest-options';

export const interestSection: ApplicationComponentSection = {
  id: ApplicationSectionIds.INTERESTS_SECTION_ID,
  elements: [
    {
      component: 'ThreeColumnLayout',
      props: {
        layoutId: ApplicationSectionIds.INTERESTS_SECTION_ID,
        children: [
          [{ component: 'AdmissionAdvisorModalInfo' }],
          [
            {
              component: 'CenteredIllustration',
              props: {
                name: 'SpaceSpaceship',
                size: illustrationSize,
              },
            },
            headingSpacer,
            {
              component: 'ComponentsHeading',
              props: {
                children: 'What are you primarily interested in?',
                ...headingProps,
              },
            },
            contentSpacer,
            {
              component: 'TileRadioButtonGroup',
              tag: 'application',
              props: {
                name: 'generalInterest',
                helperText: 'Choose one field that you are most interested in',
                options: generalInterestOptions,
              },
              validation: {
                required: true,
              },
            },
          ],
        ],
      },
    },
  ],
};
