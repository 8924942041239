import { Text, ToggleSwitch, DividerWithText } from '@polygence/components';

interface NotificationSettingToggleProps {
  id: string;
  label: string;
  description: string;
  toggleAriaLabel: string;
  checked: boolean;
  disabled?: boolean;
  onChange: (checked: boolean, id: string) => void;
}

export const NotificationSettingToggle = ({
  id,
  label,
  description,
  toggleAriaLabel,
  checked,
  disabled = false,
  onChange,
}: NotificationSettingToggleProps) => {
  return (
    <div>
      <label
        htmlFor={id}
        className="d-flex align-items-center justify-content-between gap-6 p-4 user-select-none"
      >
        <div className="d-flex flex-column gap-3">
          <Text size="medium" fontWeight="semibold">
            {label}
          </Text>
          <Text size="medium" className="text-muted">
            {description}
          </Text>
        </div>
        <ToggleSwitch
          id={id}
          aria-label={toggleAriaLabel}
          onCheckedChange={(checked) => onChange(checked, id)}
          checked={checked}
          disabled={disabled}
        />
      </label>
      <DividerWithText />
    </div>
  );
};
