import { externalLink, getLandingUrl } from '@polygence/common';
import { Button, Heading, Text } from '@polygence/components';
import classNames from 'classnames';

import styles from 'src/components/Tiles/Counselor/EventsTile.module.scss';
import tileStyles from 'src/components/Tiles/tilesDashboard.module.scss';

export const EventsTile = () => {
  return (
    <div className={classNames(tileStyles['tile'], 'd-flex flex-column p-8')}>
      <Text size="medium" fontWeight="semibold" className="text-primary mb-5">
        We'd love to see you!
      </Text>
      <Heading size="h4" as="h4" fontWeight="semibold" alignment="left" className="mb-6">
        Events and Webinars
      </Heading>
      <Button
        href="https://calendar.google.com/calendar/u/0/embed?src=c_5da7d59ae32370fcdde1c9dda7f4ddbc9bf59dbb02f1dc93fb7ca903c5dcb288@group.calendar.google.com&ctz=America/Los_Angeles"
        {...externalLink}
        variant="primary"
        className={classNames(styles['upcomingEvents'], 'align-self-start mb-5')}
      >
        See Upcoming Events for Counselors
      </Button>
      <Button
        href={getLandingUrl('/webinars')}
        {...externalLink}
        className="mb-8 text-wrap align-self-start"
      >
        See Past Events Library
      </Button>
      <img src="https://dpl6hyzg28thp.cloudfront.net/media/zoom-meeting.png" alt="zoom-meeting" />
    </div>
  );
};
