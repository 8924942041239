import { externalLink, getLandingUrl } from '@polygence/common';
import { Icon, Text } from '@polygence/components';

import { STUDENT_TEAM_EMAIL } from 'src/constants';
import { PaymentIntentSecuredByStripe } from 'src/payment/PaymentIntent/PaymentIntentSecuredByStripe';

export const PaymentPageTerms = () => {
  return (
    <div className="tw-mx-5 tw-my-6 tw-flex tw-flex-wrap tw-justify-center tw-gap-4">
      <Text
        size="small"
        fontWeight="bold"
        className="tw-inline-flex tw-items-center tw-justify-center tw-gap-1 tw-text-gray-400"
      >
        <Icon id="external-link" size="sm" />
        <a
          href={getLandingUrl('/terms-of-use')}
          className="tw-text-gray-400 tw-underline"
          {...externalLink}
        >
          Polygence Terms of Service
        </a>
      </Text>
      <PaymentIntentSecuredByStripe />
      <Text
        size="small"
        fontWeight="bold"
        className="tw-inline-flex tw-items-center tw-justify-center tw-gap-1 tw-text-gray-400"
      >
        <Icon id="help-circle" size="sm" />
        Have more questions? Reach out to{' '}
        <a href={`mailto:${STUDENT_TEAM_EMAIL}`} className="tw-text-gray-400 tw-underline">
          {STUDENT_TEAM_EMAIL}
        </a>
      </Text>
    </div>
  );
};
