import { Nullable, ProjectId, commonReducers, useCommonSelector } from '@polygence/common';
import * as hermesApi from '@polygence/common/api/hermes';
import { Button, Modal, ModalBody } from '@polygence/components';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { RedirectNotification } from 'src/components/RedirectNotification';

export const PurchaseShowcasingAddonModal = () => {
  const { open, projectId }: { open: boolean; projectId: Nullable<ProjectId> } = useCommonSelector(
    (state) => {
      return state.showcasingAddon.showcasingPurchaseModal;
    },
  );
  const [loading, setLoading] = useState(false);
  const [invoiceUrl, setInvoiceUrl] = useState<string | null>(null);

  const dispatch = useDispatch();
  const closeModal = () => {
    dispatch(commonReducers.showcasingAddonActions.closePurchaseModal());
  };

  const handlePurchasePremiumShowcasingSupport = () => {
    if (!projectId) {
      return;
    }
    setLoading(true);
    hermesApi
      .postPurchaseShowcasingSupportAddon(projectId)
      .then(({ data }) => {
        toast.success('Redirecting to payment page...');
        if ('invoiceUrl' in data) {
          setInvoiceUrl(data.invoiceUrl);
        }
      })
      .catch(() => {
        toast.error('Could not process this request.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal show={open} onHide={closeModal} closeButton>
      <ModalBody>
        {invoiceUrl && <RedirectNotification url={invoiceUrl} />}
        {!invoiceUrl && (
          <>
            <h1 className="text-center">Premium Showcasing Support</h1>
            <p>
              This addition to your program is designed to provide more structured guidance on
              showcasing your project above and beyond your sessions with your research mentor. As
              part of this three session program add-on, you will be matched with a Showcasing
              Specialist who is trained to help you identify relevant showcasing opportunities for
              your research, and format your work for submission. If you're looking to submit your
              paper for publication, compete in a science fair, turn your research into a podcast,
              or create a website from your findings, Premium Showcasing Support may be right for
              you!
            </p>
            <div className="text-center">
              <Button
                variant="primary"
                disabled={loading}
                onClick={handlePurchasePremiumShowcasingSupport}
              >
                Purchase Premium Showcasing support!
              </Button>
            </div>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};
