/* eslint-disable sonarjs/no-duplicate-string */
import { ApplicationSectionIds } from 'src/constants/application-sections';
import {
  contentSpacer,
  headingProps,
  headingSpacer,
  illustrationSize,
  textFieldSpacer,
} from 'src/students/student-application/common-section-components';
import type { ApplicationComponentSection } from 'src/students/student-application/student-application-components';

export const POST_PROJECT_IDEA_SECTIONS_A: ApplicationComponentSection[] = [
  {
    id: ApplicationSectionIds.COMPUTER_SCIENCE_SECTION_ID,
    display: { $is: { field: 'applicationRevampEnabled', value: false } },
    elements: [
      {
        component: 'ThreeColumnLayout',
        props: {
          children: [
            [{ component: 'AdmissionAdvisorModalInfo' }],
            [
              {
                component: 'CenteredIllustration',
                props: {
                  name: 'SpaceSatellite',
                  size: illustrationSize,
                },
              },
              headingSpacer,
              {
                component: 'ComponentsHeading',
                props: {
                  children: 'For the project you are envisioning, will you need to do any coding?',
                  ...headingProps,
                },
              },
              contentSpacer,
              {
                component: 'ComponentsRadioButton',
                validation: {
                  required: true,
                },
                tag: 'application',
                props: {
                  name: 'intendToDoCs',
                  label: "Yes, I'd need to do some coding to conduct my project.",
                  value: true,
                },
              },
              {
                component: 'ComponentsRadioButton',
                validation: {
                  required: true,
                },
                tag: 'application',
                props: {
                  name: 'intendToDoCs',
                  label: "No, I don't need to do any coding to conduct my project.",
                  value: false,
                },
              },
            ],
          ],
        },
      },
    ],
  },
  {
    id: ApplicationSectionIds.COMPUTER_SCIENCE_DETAIL_SECTION_ID,
    display: {
      $and: [
        { $is: { field: 'applicationRevampEnabled', value: false } },
        {
          $is: {
            field: 'intendToDoCs',
            value: true,
          },
        },
      ],
    },
    elements: [
      {
        component: 'ThreeColumnLayout',
        props: {
          children: [
            [{ component: 'AdmissionAdvisorModalInfo' }],
            [
              {
                component: 'CenteredIllustration',
                props: {
                  name: 'SpaceSatellite',
                  size: illustrationSize,
                },
              },
              headingSpacer,
              {
                component: 'ComponentsHeading',
                props: {
                  children: 'Tell us about your computer science experience.',
                  ...headingProps,
                },
              },
              contentSpacer,
              {
                component: 'ComponentsText',
                props: {
                  children: '1. Which of the following programs have you completed?',
                  size: {
                    base: 'medium',
                    lg: 'large',
                  },
                },
              },
              {
                component: 'Spacer',
                props: { size: 6 },
              },
              {
                component: 'Checkbox',
                tag: 'application',
                props: {
                  label: 'AP Comp Sci Principles',
                  name: 'computerScienceCompletedProgramsApCompSciPrinciples',
                },
              },
              {
                component: 'Checkbox',
                tag: 'application',
                props: {
                  label: 'AP Comp Sci A',
                  name: 'computerScienceCompletedProgramsApCompSciA',
                },
              },
              {
                component: 'Checkbox',
                tag: 'application',
                props: {
                  label: 'USACO',
                  name: 'computerScienceCompletedProgramsUsaco',
                },
              },
              {
                component: 'Box',
                display: {
                  $is: {
                    field: 'computerScienceCompletedProgramsUsaco',
                    value: true,
                  },
                },
                props: {
                  children: [
                    {
                      component: 'ComponentsText',
                      props: {
                        children: 'What was the contest result? (bronze, silver, gold, platinum)',
                        size: {
                          base: 'medium',
                          lg: 'large',
                        },
                      },
                    },
                    {
                      component: 'Spacer',
                      props: { size: 2 },
                    },
                    {
                      component: 'InputField',
                      tag: 'application',
                      props: {
                        name: 'computerScienceCompletedProgramsUsacoDetail',
                      },
                      validation: {
                        required: true,
                      },
                    },
                    textFieldSpacer,
                  ],
                },
              },
              {
                component: 'Checkbox',
                tag: 'application',
                props: {
                  label: 'Enrichment program in AI',
                  name: 'computerScienceCompletedProgramsAi',
                },
              },
              {
                component: 'Box',
                display: {
                  $is: {
                    field: 'computerScienceCompletedProgramsAi',
                    value: true,
                  },
                },
                props: {
                  children: [
                    {
                      component: 'ComponentsText',
                      props: {
                        children: 'Specify program (e.g. InspiritAI, Coursera)',
                        size: {
                          base: 'medium',
                          lg: 'large',
                        },
                      },
                    },
                    {
                      component: 'Spacer',
                      props: { size: 2 },
                    },
                    {
                      component: 'InputField',
                      tag: 'application',
                      props: {
                        name: 'computerScienceCompletedProgramsAiDetail',
                      },
                      validation: {
                        required: true,
                      },
                    },
                  ],
                },
              },
              {
                component: 'Spacer',
                props: { size: 9 },
              },
              {
                component: 'LabelWithTooltip',
                props: {
                  children:
                    '2. What programming languages do you know, and at what level of proficiency?',
                  tooltip: 'e.g. Java (proficient), C++ (somewhat familiar)',
                },
              },
              {
                component: 'Spacer',
                props: { size: 6 },
              },
              {
                component: 'InputField',
                tag: 'application',
                props: {
                  name: 'computerScienceLanguageProficiencies',
                },
                validation: {
                  required: true,
                },
              },
              {
                component: 'Spacer',
                props: { size: 9 },
              },
              {
                component: 'ComponentsText',
                props: {
                  children:
                    "3. Describe your most advanced program you've coded to date independently (minimum 100 words)",
                  size: {
                    base: 'medium',
                    lg: 'large',
                  },
                },
              },
              {
                component: 'Spacer',
                props: { size: 6 },
              },
              {
                component: 'InputField',
                tag: 'application',
                props: {
                  type: 'textarea',
                  name: 'relevantExperience',
                  showWordCount: true,
                  rows: 10,
                },
                validation: {
                  required: true,
                  wordCount: {
                    minimum: 100,
                    maximum: 300,
                  },
                },
              },
              {
                component: 'Spacer',
                props: { size: 9 },
              },
              {
                component: 'ComponentsText',
                props: {
                  children:
                    '4. For the best chance to be accepted and matched with a computer science mentor, we highly encourage you to link an example of code that you’ve written independently. This can be in the form of a GitHub repository, public Google Colab notebook, a previous competition entry, etc.',
                  size: {
                    base: 'medium',
                    lg: 'large',
                  },
                },
              },
              {
                component: 'Spacer',
                props: { size: 6 },
              },
              {
                component: 'InputField',
                tag: 'application',
                props: {
                  type: 'text',
                  placeholder: 'Add link here...',
                  name: 'relevantExperienceExample',
                },
              },
            ],
          ],
        },
      },
    ],
  },
  {
    id: ApplicationSectionIds.EXPERIENCE_SECTION_ID,
    display: {
      $and: [
        { $is: { field: 'applicationRevampEnabled', value: false } },
        {
          $is: {
            field: 'intendToDoCs',
            value: false,
          },
        },
      ],
    },
    elements: [
      {
        component: 'ThreeColumnLayout',
        props: {
          children: [
            [{ component: 'AdmissionAdvisorModalInfo' }],
            [
              {
                component: 'CenteredIllustration',
                props: {
                  name: 'SpaceTelescope',
                  size: illustrationSize,
                },
              },
              {
                component: 'Spacer',
                props: {
                  size: {
                    base: 9,
                    lg: 10,
                  },
                },
              },
              {
                component: 'ComponentsHeading',
                props: {
                  children: 'What experience do you have that could be relevant to your project?',
                  ...headingProps,
                },
              },
              contentSpacer,
              {
                component: 'ComponentsText',
                props: {
                  children:
                    "You can list extracurriculars, books you've read, documentaries you've watched, school classes, online courses, summer programs, etc.",
                  className: 'mb-3',
                  size: 'medium',
                },
              },
              {
                component: 'InputField',
                tag: 'application',
                props: {
                  type: 'textarea',
                  name: 'relevantExperience',
                  showWordCount: true,
                  rows: 10,
                },
                validation: {
                  required: true,
                  wordCount: {
                    minimum: 25,
                    maximum: 150,
                  },
                },
              },
            ],
            [
              {
                component: 'Spacer',
                props: { size: { base: 2, md: 8, lg: 10, xl: 13 } },
              },
              {
                component: 'Note',
                props: {
                  title: 'Examples:',
                  text: '- 12 week CS program\n- AP psychology: grade A\n- Currently taking modern world history\n- December 2 2017 14th NASA JPL Invention Challenge\n- Ongoing AP Calculus BC (self study)\n- Books I’ve Read:\n\t- The Selfish Gene\n\t- The Gene: An Intimate History',
                },
              },
            ],
          ],
        },
      },
    ],
  },
  {
    id: ApplicationSectionIds.TIMELINE_SECTION_ID,
    display: {
      $is: { field: 'applicationRevampEnabled', value: false },
    },
    elements: [
      {
        component: 'ThreeColumnLayout',
        props: {
          children: [
            [{ component: 'AdmissionAdvisorModalInfo' }],
            [
              {
                component: 'CenteredIllustration',
                props: {
                  name: 'SpaceRocketLaunch',
                  size: illustrationSize,
                },
              },
              {
                component: 'Spacer',
                props: {
                  size: {
                    base: 9,
                    lg: 10,
                  },
                },
              },
              {
                component: 'ComponentsHeading',
                props: {
                  children: "When you'd like to start and finish your project?",
                  ...headingProps,
                },
              },
              contentSpacer,
              {
                component: 'DateRangeSelector',
                tag: 'application',
                props: {
                  name: 'timeline',
                  startAt: 'timelineStartAt',
                  endAt: 'timelineEndAt',
                },
                validation: {
                  timeline: { minimum: 3 },
                },
              },
              {
                component: 'Spacer',
                props: { size: { base: 10, md: 12 } },
              },
              {
                component: 'ComponentsHeading',
                props: {
                  children: 'How many hours on average can you devote to your project each week?',
                  ...headingProps,
                },
              },
              contentSpacer,
              {
                component: 'ComponentsRadioButton',
                validation: {
                  required: true,
                },
                tag: 'application',
                props: {
                  name: 'timeCommitment',
                  label: '3-5 hours per week',
                  value: '3_to_5',
                },
              },
              {
                component: 'ComponentsRadioButton',
                validation: {
                  required: true,
                },
                tag: 'application',
                props: {
                  name: 'timeCommitment',
                  label: '6-10 hours per week',
                  value: '6_to_10',
                },
              },
              {
                component: 'ComponentsRadioButton',
                validation: {
                  required: true,
                },
                tag: 'application',
                props: {
                  name: 'timeCommitment',
                  label: '11-15 hours per week',
                  value: '10_to_15',
                },
              },
              {
                component: 'ComponentsRadioButton',
                validation: {
                  required: true,
                },
                tag: 'application',
                props: {
                  name: 'timeCommitment',
                  label: '16-20 hours per week',
                  value: '16_to_20',
                },
              },
              {
                component: 'ComponentsRadioButton',
                validation: {
                  required: true,
                },
                tag: 'application',
                props: {
                  name: 'timeCommitment',
                  label: '21-40 hours per week',
                  value: '20_to_40',
                },
              },
            ],
            [
              {
                component: 'Spacer',
                props: { size: { base: 2, md: 8, lg: 10, xl: 13 } },
              },
              {
                component: 'Note',
                props: {
                  title: 'What does project period mean?',
                  text: 'Our standard program is 10 sessions that are scheduled at your convenience. You can meet with your mentor every week or every other week (meaning the full program would be between 3-5 months).\n\n##### How will this information be used?\n\nThe timeline you select (start & end) will be presented to potential mentors for your project. A mentor will be matched with you based on their availability during this time frame, in addition to their expertise. Please select the most accurate timeline based on your availability to start and end your project.',
                },
              },
            ],
          ],
        },
      },
    ],
  },
];

export const POST_PROJECT_IDEA_SECTIONS_B: ApplicationComponentSection[] = [
  {
    id: ApplicationSectionIds.COMPUTER_SCIENCE_SECTION_ID,
    display: {
      $and: [
        { $is: { field: 'applicationRevampEnabled', value: true } },
        { $is: { field: 'hasProjectIdea', value: true } },
      ],
    },
    elements: [
      {
        component: 'ThreeColumnLayout',
        props: {
          children: [
            [{ component: 'AdmissionAdvisorModalInfo' }],
            [
              {
                component: 'CenteredIllustration',
                props: {
                  name: 'SpaceSatellite',
                  size: illustrationSize,
                },
              },
              headingSpacer,
              {
                component: 'ComponentsHeading',
                props: {
                  children: 'For the project you are envisioning, will you need to do any coding?',
                  ...headingProps,
                },
              },
              contentSpacer,
              {
                component: 'ComponentsRadioButton',
                validation: {
                  required: true,
                },
                tag: 'application',
                props: {
                  name: 'intendToDoCs',
                  label: "Yes, I'd need to do some coding to conduct my project.",
                  value: true,
                },
              },
              {
                component: 'ComponentsRadioButton',
                validation: {
                  required: true,
                },
                tag: 'application',
                props: {
                  name: 'intendToDoCs',
                  label: "No, I don't need to do any coding to conduct my project.",
                  value: false,
                },
              },
            ],
          ],
        },
      },
    ],
  },
  {
    id: ApplicationSectionIds.COMPUTER_SCIENCE_DETAIL_SECTION_ID,
    display: {
      $and: [
        { $is: { field: 'applicationRevampEnabled', value: true } },
        { $is: { field: 'hasProjectIdea', value: true } },
        {
          $is: {
            field: 'intendToDoCs',
            value: true,
          },
        },
      ],
    },
    elements: [
      {
        component: 'ThreeColumnLayout',
        props: {
          children: [
            [{ component: 'AdmissionAdvisorModalInfo' }],
            [
              {
                component: 'CenteredIllustration',
                props: {
                  name: 'SpaceSatellite',
                  size: illustrationSize,
                },
              },
              headingSpacer,
              {
                component: 'ComponentsHeading',
                props: {
                  children: 'Tell us about your computer science experience.',
                  ...headingProps,
                },
              },
              contentSpacer,
              {
                component: 'ComponentsText',
                props: {
                  children: '1. Which of the following programs have you completed?',
                  size: {
                    base: 'medium',
                    lg: 'large',
                  },
                },
              },
              {
                component: 'Spacer',
                props: { size: 6 },
              },
              {
                component: 'Checkbox',
                tag: 'application',
                props: {
                  label: 'AP Comp Sci Principles',
                  name: 'computerScienceCompletedProgramsApCompSciPrinciples',
                },
              },
              {
                component: 'Checkbox',
                tag: 'application',
                props: {
                  label: 'AP Comp Sci A',
                  name: 'computerScienceCompletedProgramsApCompSciA',
                },
              },
              {
                component: 'Checkbox',
                tag: 'application',
                props: {
                  label: 'USACO',
                  name: 'computerScienceCompletedProgramsUsaco',
                },
              },
              {
                component: 'Box',
                display: {
                  $is: {
                    field: 'computerScienceCompletedProgramsUsaco',
                    value: true,
                  },
                },
                props: {
                  children: [
                    {
                      component: 'ComponentsText',
                      props: {
                        children: 'What was the contest result? (bronze, silver, gold, platinum)',
                        size: {
                          base: 'medium',
                          lg: 'large',
                        },
                      },
                    },
                    {
                      component: 'Spacer',
                      props: { size: 2 },
                    },
                    {
                      component: 'InputField',
                      tag: 'application',
                      props: {
                        name: 'computerScienceCompletedProgramsUsacoDetail',
                      },
                      validation: {
                        required: true,
                      },
                    },
                    textFieldSpacer,
                  ],
                },
              },
              {
                component: 'Checkbox',
                tag: 'application',
                props: {
                  label: 'Enrichment program in AI',
                  name: 'computerScienceCompletedProgramsAi',
                },
              },
              {
                component: 'Box',
                display: {
                  $is: {
                    field: 'computerScienceCompletedProgramsAi',
                    value: true,
                  },
                },
                props: {
                  children: [
                    {
                      component: 'ComponentsText',
                      props: {
                        children: 'Specify program (e.g. InspiritAI, Coursera)',
                        size: {
                          base: 'medium',
                          lg: 'large',
                        },
                      },
                    },
                    {
                      component: 'Spacer',
                      props: { size: 2 },
                    },
                    {
                      component: 'InputField',
                      tag: 'application',
                      props: {
                        name: 'computerScienceCompletedProgramsAiDetail',
                      },
                      validation: {
                        required: true,
                      },
                    },
                  ],
                },
              },
              {
                component: 'Spacer',
                props: { size: 9 },
              },
              {
                component: 'LabelWithTooltip',
                props: {
                  children:
                    '2. What programming languages do you know, and at what level of proficiency?',
                  tooltip: 'e.g. Java (proficient), C++ (somewhat familiar)',
                },
              },
              {
                component: 'Spacer',
                props: { size: 6 },
              },
              {
                component: 'InputField',
                tag: 'application',
                props: {
                  name: 'computerScienceLanguageProficiencies',
                },
                validation: {
                  required: true,
                },
              },
              {
                component: 'Spacer',
                props: { size: 9 },
              },
              {
                component: 'ComponentsText',
                props: {
                  children:
                    "3. Describe your most advanced program you've coded to date independently (minimum 100 words)",
                  size: {
                    base: 'medium',
                    lg: 'large',
                  },
                },
              },
              {
                component: 'Spacer',
                props: { size: 6 },
              },
              {
                component: 'InputField',
                tag: 'application',
                props: {
                  type: 'textarea',
                  name: 'relevantExperience',
                  showWordCount: true,
                  rows: 10,
                },
                validation: {
                  required: true,
                  wordCount: {
                    minimum: 100,
                    maximum: 300,
                  },
                },
              },
              {
                component: 'Spacer',
                props: { size: 9 },
              },
              {
                component: 'ComponentsText',
                props: {
                  children:
                    '4. For the best chance to be accepted and matched with a computer science mentor, we highly encourage you to link an example of code that you’ve written independently. This can be in the form of a GitHub repository, public Google Colab notebook, a previous competition entry, etc.',
                  size: {
                    base: 'medium',
                    lg: 'large',
                  },
                },
              },
              {
                component: 'Spacer',
                props: { size: 6 },
              },
              {
                component: 'InputField',
                tag: 'application',
                props: {
                  type: 'text',
                  placeholder: 'Add link here...',
                  name: 'relevantExperienceExample',
                },
              },
            ],
          ],
        },
      },
    ],
  },
  {
    id: ApplicationSectionIds.TIMELINE_SECTION_ID,
    display: {
      $and: [
        { $is: { field: 'applicationRevampEnabled', value: true } },
        { $is: { field: 'hasProjectIdea', value: true } },
      ],
    },
    elements: [
      {
        component: 'ThreeColumnLayout',
        props: {
          children: [
            [{ component: 'AdmissionAdvisorModalInfo' }],
            [
              {
                component: 'CenteredIllustration',
                props: {
                  name: 'SpaceRocketLaunch',
                  size: illustrationSize,
                },
              },
              {
                component: 'Spacer',
                props: {
                  size: {
                    base: 9,
                    lg: 10,
                  },
                },
              },
              {
                component: 'ComponentsHeading',
                props: {
                  children: "When you'd like to start and finish your project?",
                  ...headingProps,
                },
              },
              contentSpacer,
              {
                component: 'DateRangeSelector',
                tag: 'application',
                props: {
                  name: 'timeline',
                  startAt: 'timelineStartAt',
                  endAt: 'timelineEndAt',
                },
                validation: {
                  timeline: { minimum: 3 },
                },
              },
              {
                component: 'Spacer',
                props: { size: { base: 10, md: 12 } },
              },
              {
                component: 'ComponentsHeading',
                props: {
                  children: 'How many hours on average can you devote to your project each week?',
                  ...headingProps,
                },
              },
              contentSpacer,
              {
                component: 'ComponentsRadioButton',
                validation: {
                  required: true,
                },
                tag: 'application',
                props: {
                  name: 'timeCommitment',
                  label: '3-5 hours per week',
                  value: '3_to_5',
                },
              },
              {
                component: 'ComponentsRadioButton',
                validation: {
                  required: true,
                },
                tag: 'application',
                props: {
                  name: 'timeCommitment',
                  label: '6-10 hours per week',
                  value: '6_to_10',
                },
              },
              {
                component: 'ComponentsRadioButton',
                validation: {
                  required: true,
                },
                tag: 'application',
                props: {
                  name: 'timeCommitment',
                  label: '11-15 hours per week',
                  value: '10_to_15',
                },
              },
              {
                component: 'ComponentsRadioButton',
                validation: {
                  required: true,
                },
                tag: 'application',
                props: {
                  name: 'timeCommitment',
                  label: '16-20 hours per week',
                  value: '16_to_20',
                },
              },
              {
                component: 'ComponentsRadioButton',
                validation: {
                  required: true,
                },
                tag: 'application',
                props: {
                  name: 'timeCommitment',
                  label: '21-40 hours per week',
                  value: '20_to_40',
                },
              },
            ],
            [
              {
                component: 'Spacer',
                props: { size: { base: 2, md: 8, lg: 10, xl: 13 } },
              },
              {
                component: 'Note',
                props: {
                  title: 'What does project period mean?',
                  text: 'Our standard program is 10 sessions that are scheduled at your convenience. You can meet with your mentor every week or every other week (meaning the full program would be between 3-5 months).\n\n##### How will this information be used?\n\nThe timeline you select (start & end) will be presented to potential mentors for your project. A mentor will be matched with you based on their availability during this time frame, in addition to their expertise. Please select the most accurate timeline based on your availability to start and end your project.',
                },
              },
            ],
          ],
        },
      },
    ],
  },
];

export const POST_PROJECT_IDEA_SECTIONS_C: ApplicationComponentSection[] = [
  {
    id: ApplicationSectionIds.TIMELINE_SECTION_ID,
    display: {
      $and: [
        { $is: { field: 'applicationRevampEnabled', value: true } },
        { $is: { field: 'hasProjectIdea', value: false } },
      ],
    },
    elements: [
      {
        component: 'ThreeColumnLayout',
        props: {
          children: [
            [{ component: 'AdmissionAdvisorModalInfo' }],
            [
              {
                component: 'CenteredIllustration',
                props: {
                  name: 'SpaceRocketLaunch',
                  size: illustrationSize,
                },
              },
              {
                component: 'Spacer',
                props: {
                  size: {
                    base: 9,
                    lg: 10,
                  },
                },
              },
              {
                component: 'ComponentsHeading',
                props: {
                  children: "When you'd like to start and finish your project?",
                  ...headingProps,
                },
              },
              contentSpacer,
              {
                component: 'DateRangeSelector',
                tag: 'application',
                props: {
                  name: 'timeline',
                  startAt: 'timelineStartAt',
                  endAt: 'timelineEndAt',
                },
                validation: {
                  timeline: { minimum: 3 },
                },
              },
              {
                component: 'Spacer',
                props: { size: { base: 10, md: 12 } },
              },
              {
                component: 'ComponentsHeading',
                props: {
                  children: 'How many hours on average can you devote to your project each week?',
                  ...headingProps,
                },
              },
              contentSpacer,
              {
                component: 'ComponentsRadioButton',
                validation: {
                  required: true,
                },
                tag: 'application',
                props: {
                  name: 'timeCommitment',
                  label: '3-5 hours per week',
                  value: '3_to_5',
                },
              },
              {
                component: 'ComponentsRadioButton',
                validation: {
                  required: true,
                },
                tag: 'application',
                props: {
                  name: 'timeCommitment',
                  label: '6-10 hours per week',
                  value: '6_to_10',
                },
              },
              {
                component: 'ComponentsRadioButton',
                validation: {
                  required: true,
                },
                tag: 'application',
                props: {
                  name: 'timeCommitment',
                  label: '11-15 hours per week',
                  value: '10_to_15',
                },
              },
              {
                component: 'ComponentsRadioButton',
                validation: {
                  required: true,
                },
                tag: 'application',
                props: {
                  name: 'timeCommitment',
                  label: '16-20 hours per week',
                  value: '16_to_20',
                },
              },
              {
                component: 'ComponentsRadioButton',
                validation: {
                  required: true,
                },
                tag: 'application',
                props: {
                  name: 'timeCommitment',
                  label: '21-40 hours per week',
                  value: '20_to_40',
                },
              },
            ],
            [
              {
                component: 'Spacer',
                props: { size: { base: 2, md: 8, lg: 10, xl: 13 } },
              },
              {
                component: 'Note',
                props: {
                  title: 'What does project period mean?',
                  text: 'Our standard program is 10 sessions that are scheduled at your convenience. You can meet with your mentor every week or every other week (meaning the full program would be between 3-5 months).\n\n##### How will this information be used?\n\nThe timeline you select (start & end) will be presented to potential mentors for your project. A mentor will be matched with you based on their availability during this time frame, in addition to their expertise. Please select the most accurate timeline based on your availability to start and end your project.',
                },
              },
            ],
          ],
        },
      },
    ],
  },
];
