import jwtDecode from 'jwt-decode';

import type { UserInfoToken } from '../types/user';
import type { Nullable } from '../types/utils';

export enum Token {
  Access = 'token',
  Refresh = 'refresh_token',
  OriginalAccess = 'original_access_token',
  OriginalRefresh = 'original_refresh_token',
}

export const tokenExpiresSoon = (token: UserInfoToken): boolean =>
  token.exp - Date.now() / 1000 < 2 * 24 * 3600;

export const getAccessTokenPayload = (token: Nullable<string>): UserInfoToken | null => {
  if (token) {
    return jwtDecode(token);
  } else {
    return null;
  }
};
