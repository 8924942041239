import { axiosBaseQuery } from '@polygence/common/api/fetch';
import type { ProjectId, UUID } from '@polygence/common/types/common';
import type { EmptyResponse } from '@polygence/common/types/data/common';
import type { FeedbackRequestPublic } from '@polygence/common/types/hermes';
import { createApi } from '@reduxjs/toolkit/query/react';
import type { CamelCasedPropertiesDeep } from 'type-fest';

export const feedbackRequestApi = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: 'feedbackRequest',
  endpoints: (build) => {
    return {
      publicFeedbackRequest: build.query<FeedbackRequestPublic, { uuid: UUID }>({
        query: ({ uuid }) => {
          return `/hermes/projects/feedback_request/${uuid}/`;
        },
      }),
      feedbackRequestSubmit: build.mutation<void, { uuid: UUID; feedbackFileUrl: string }>({
        query: ({ uuid, feedbackFileUrl }) => {
          return {
            url: `/hermes/projects/feedback_request/${uuid}/submit/`,
            method: 'POST',
            data: {
              feedbackFileUrl,
            },
          };
        },
      }),
      feedbackRequestReply: build.mutation<
        EmptyResponse,
        { uuid: UUID; action: 'accept' | 'decline' }
      >({
        query: ({ uuid, action }) => {
          return {
            url: `/hermes/projects/feedback_request/${uuid}/${action}/`,
            method: 'POST',
          };
        },
      }),
      addAdditionalWritingFeedback: build.mutation<
        CamelCasedPropertiesDeep<{ payment_intent_uuid: UUID }>,
        { projectId: ProjectId }
      >({
        query: ({ projectId }) => {
          return {
            url: `/hermes/projects/${projectId}/additional-writing-feedback/`,
            method: 'POST',
          };
        },
      }),
    };
  },
});

export const {
  usePublicFeedbackRequestQuery,
  useFeedbackRequestSubmitMutation,
  useFeedbackRequestReplyMutation,
  useAddAdditionalWritingFeedbackMutation,
} = feedbackRequestApi;
