import { ApplicationSectionIds } from 'src/constants/application-sections';
import { headingSpacer } from 'src/students/student-application/common-section-components';
import { ApplicationComponentSection } from 'src/students/student-application/student-application-components';

export const launchpadPitchSection: ApplicationComponentSection = {
  id: ApplicationSectionIds.LAUNCHPAD_PITCH_ID,
  display: {
    $is: {
      field: 'applicationRevampEnabled',
      value: false,
    },
  },
  elements: [
    {
      component: 'ThreeColumnLayout',
      props: {
        children: [
          [{ component: 'AdmissionAdvisorModalInfo' }],
          [
            headingSpacer,
            {
              component: 'LaunchpadPitchWithoutProjectIdea',
              tag: 'application',
              props: {
                name: 'interestedInLaunchpad',
              },
              validation: {
                required: true,
              },
            },
          ],
          null,
        ],
      },
    },
  ],
};
