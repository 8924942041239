import { Illustration, TileSelect } from '@polygence/components';
import PropTypes from 'prop-types';
import React from 'react';

import styles from 'src/components/common/TileCheckboxGroup/TileCheckboxGroup.module.sass';
import { HELPER_TEXTS } from 'src/components/constants';
import { HelperText } from 'src/components/mentor/FormElements';

const TileCheckboxGroup = ({
  options,
  onChange,
  data,
  helperText = HELPER_TEXTS.CHECKBOX,
  disabled,
}) => {
  return (
    <>
      <HelperText text={helperText} />
      <div className={styles.gridWrapper}>
        {options.map(({ value, label, illustration = '' }) => {
          return (
            <TileSelect
              key={value}
              value={value}
              type="checkbox"
              onChange={onChange}
              checked={data[value] === true}
              disabled={disabled}
            >
              {illustration && <Illustration name={illustration} size={50} />}
              {label}
            </TileSelect>
          );
        })}
      </div>
    </>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default TileCheckboxGroup;

TileCheckboxGroup.propTypes = {
  options: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  onChange: PropTypes.func,
  data: PropTypes.objectOf(PropTypes.any),
  helperText: PropTypes.string,
};
