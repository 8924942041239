import type { StudentJourneyPSSProgram } from '@polygence/common';
import { Skeleton } from '@polygence/components';
import type { ElementType } from 'react';

import { ProjectActiveTile } from 'src/components/Tiles/StudentJourney/ProjectActiveTile';
import { StudentJourneyProduct } from 'src/components/Tiles/StudentJourney/StudentJourneyProduct';
import { UnderMatchingTile } from 'src/components/Tiles/StudentJourney/UnderMatchingTile';
import { PitchPSSJourneyTile } from 'src/components/Tiles/StudentJourney/pss/PitchPSSJourneyTile';
import { PurchasablePSSJourneyTile } from 'src/components/Tiles/StudentJourney/pss/PurchasablePSSJourneyTile';
import { SurveyPSSJourneyTile } from 'src/components/Tiles/StudentJourney/pss/SurveyPSSJourneyTile';
import { WaitUntilPSSJourneyTile } from 'src/components/Tiles/StudentJourney/pss/WaitUntilPSSJourneyTile';
import { useStudentJourney } from 'src/components/Tiles/StudentJourney/useStudentJourney';

const SkeletonPSSJourneyTile = () => {
  return <Skeleton className="w-100" height="75px" />;
};

const PSSActiveTile = () => <ProjectActiveTile projectType="pss" />;

const components: { [key in StudentJourneyPSSProgram['status']]: ElementType } = {
  pitchable: PitchPSSJourneyTile,
  purchasable: PurchasablePSSJourneyTile,
  purchased: WaitUntilPSSJourneyTile,
  under_matching: UnderMatchingTile,
  survey_to_be_filled: SurveyPSSJourneyTile,
  active: PSSActiveTile,
  completed: PSSActiveTile,
  terminated: PSSActiveTile,
};

export const PSSJourneyTile = () => {
  const { data } = useStudentJourney();

  if (!data || !data.pss) {
    return null;
  }

  const status = data.pss.status;

  const Component = components[status];

  return (
    <StudentJourneyProduct
      loader={<SkeletonPSSJourneyTile />}
      title="Premium Showcasing"
      dashed={['pitchable', 'purchasable'].includes(status)}
    >
      <Component />
    </StudentJourneyProduct>
  );
};
