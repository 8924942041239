import type { User } from '../../types/backend';

import useCurrentUser from './useCurrentUser';
import useSelectedRoom from './useSelectedRoom';

export function useOtherParticipants(): User[] {
  const selectedRoom = useSelectedRoom();
  const currentUser = useCurrentUser();
  if (selectedRoom == null || currentUser == null) {
    return [];
  }
  return selectedRoom.participants.filter(({ id }) => {
    return id !== currentUser.id;
  });
}
