import { Disclosure } from '@headlessui/react';
import useCurrentUser from '@polygence/common/hooks/selectors/useCurrentUser';
import type { Nullable } from '@polygence/common/types/utils';
import { getBaseSocketUrl } from '@polygence/common/utils/baseUrls';
import { Button, Heading, Icon, InputField, Text, Tooltip } from '@polygence/components';
import classNames from 'classnames';
import { WebSocket } from 'partysocket';
import type { ChangeEvent, FormEvent } from 'react';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

import styles from 'src/components/common/form/GptWidget/GptProjectIdeaWidget.module.scss';
import { useStudentApplication } from 'src/students/useStudentApplication';

const PROJECT_IDEA_CHUNK = 'project_idea.chunk';
const PROJECT_IDEA_CREATED = 'project_idea.created';
const PROJECT_IDEA_ERROR = 'project_idea.error';

const useOpenAiWebsocket = () => {
  const currentUser = useCurrentUser();
  const rws = useRef<Nullable<WebSocket>>(null);

  useEffect(() => {
    rws.current = new WebSocket(
      `${getBaseSocketUrl()}/openai/stream/?token=${currentUser.token ?? ''}`,
      [],
      {
        maxRetries: 3,
      },
    );

    return () => {
      if (rws.current) {
        rws.current.close();
      }
    };
  }, [currentUser.token]);
  return rws.current;
};

interface PolyGptProjectIdeaCardProps {
  idea: string;
  count: number;
}

const PolyGptProjectIdeaCard = ({ idea, count }: PolyGptProjectIdeaCardProps) => {
  const { updateStudentApplicationState } = useStudentApplication();
  const self = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (self.current) {
      self.current.scrollIntoView();
    }
  }, []);

  const copyPasteIdea = () => {
    updateStudentApplicationState({ interests: idea });
    toast.success('Idea copied to input field.');
    window.location.hash = '#interests';
  };

  return (
    <div ref={self} className={classNames('d-flex flex-column gap-5', styles['ideaWrapper'])}>
      <Heading as="h2" size="h3" alignment="left">
        Project idea {count}
      </Heading>
      <Text size="medium">{idea}</Text>
      <Tooltip
        tip="This will copy and paste this idea to your project idea input field."
        placement="top"
      >
        <Button
          variant="link-secondary"
          endIcon={<Icon id="copy" />}
          className="align-self-start ms-n7 mb-n3"
          onClick={copyPasteIdea}
        >
          Pick this idea!
        </Button>
      </Tooltip>
    </div>
  );
};

interface GptProjectIdeaWidgetProps {
  openWidgetTitle?: string;
}

export const GptProjectIdeaWidget = ({ openWidgetTitle }: GptProjectIdeaWidgetProps) => {
  const [loading, setLoading] = useState(false);
  const [hobbies, setHobbies] = useState('');
  const [projectIdeas, setProjectIdeas] = useState<{ id: string; content: string }[]>([]);
  const { studentApplication } = useStudentApplication();
  const rws = useOpenAiWebsocket();

  useEffect(() => {
    if (!rws) {
      return;
    }

    rws.addEventListener('message', ({ data }: { data: string }) => {
      const parsedData = JSON.parse(data) as { type: string; message?: string };
      const { message, type } = parsedData;
      if (type === PROJECT_IDEA_CREATED) {
        setProjectIdeas((previous) => [...previous, { id: uuidv4(), content: '' }]);
      }
      if (type === PROJECT_IDEA_CHUNK && message) {
        setProjectIdeas((previous) => {
          const last = previous[previous.length - 1];
          if (last) {
            return [
              ...previous.slice(0, -1),
              {
                ...last,
                content: last.content + message,
              },
            ];
          }
          return previous;
        });
      }
      if (type === PROJECT_IDEA_ERROR && message) {
        toast.error(message);
      }
    });
  }, [rws]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setHobbies(event.target.value);
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    setLoading(true);

    if (rws) {
      rws.send(
        JSON.stringify({
          data: {
            general_interest:
              studentApplication.generalInterest?.replace('subject_', '') ?? 'unknown',
            interests: hobbies,
          },
        }),
      );
    }
  };

  return (
    <div className="py-8">
      <Disclosure>
        {({ open }) => (
          <div className={styles['disclosureWrapper']}>
            <Disclosure.Button
              className={classNames('d-flex flex-column', styles['disclosureButton'], {
                'pb-0': open,
              })}
            >
              <Heading
                size="h4"
                as="h4"
                fontWeight="bold"
                alignment="left"
                className={classNames(styles['textWrapper'], 'mb-5')}
              >
                {open ? openWidgetTitle : 'Need help defining your project idea? Try PolyGPT!'}
              </Heading>
              <Text size="medium" fontWeight="normal" className="mb-7">
                Polygence’s AI advisor, PolyGPT is here to help you with ideas!
              </Text>
              {!open && <Button className="align-self-start">Explore PolyGPT</Button>}
              <img
                className={open ? styles['illustrationOpen'] : styles['illustrationClosed']}
                src="https://dpl6hyzg28thp.cloudfront.net/media/poly-gpt.jpeg"
                alt="Illustration"
              />
            </Disclosure.Button>
            <Disclosure.Panel
              className={classNames(
                styles['disclosurePanel'],
                'pe-8 d-flex flex-column gap-8 align-items-center',
              )}
            >
              <form onSubmit={handleSubmit} className="d-flex flex-column gap-5 align-items-center">
                <InputField
                  required
                  name="hobbies"
                  label="Tell us about 2-3 subjects or hobbies you enjoy. To help PolyGPT tailor project ideas to your specific interests, please tell us if there's a particular aspect of these subjects that you're most passionate about."
                  placeholder="I am interested in these 2-3 subjects and hobbies... In particular, I am most passionate about these aspects of the subjects..."
                  type="textarea"
                  rows={4}
                  floating={false}
                  value={hobbies}
                  onChange={handleChange}
                  disabled={loading}
                />
                <Button type="submit" disabled={loading} className="align-self-start">
                  Let's see PolyGPT's ideas
                </Button>
              </form>
              {projectIdeas.length > 0 && (
                <div className="d-flex flex-column gap-5">
                  <>
                    <Text
                      size="large"
                      fontWeight="bold"
                      className="text-white mb-2"
                      alignment="center"
                    >
                      Here are 3 project ideas suggested by{' '}
                      <span className="text-primary">PolyGPT</span>.
                    </Text>
                    {projectIdeas
                      .filter((idea) => idea.content)
                      .map((idea, idx) => {
                        return (
                          <PolyGptProjectIdeaCard
                            key={idea.id}
                            idea={idea.content}
                            count={idx + 1}
                          />
                        );
                      })}
                  </>
                </div>
              )}
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>
    </div>
  );
};
