import type { GuidebookPage } from '@polygence/common/types/guidebooks';

export const GuidebookPageCategories = {
  PROJECT_RESOURCES: 'project_resources',
  POLICIES_AND_GUIDELINES: 'policies_and_guidelines',
  COMMUNITY: 'community',
} as const;

export type GuidebookPageCategory =
  (typeof GuidebookPageCategories)[keyof typeof GuidebookPageCategories];

export const GuidebookCategoryStyles = {
  STACKED: 'stacked',
  HORIZONTAL_SCROLL: 'horizontal_scroll',
} as const;

export type GuidebookCategoryStyle =
  (typeof GuidebookCategoryStyles)[keyof typeof GuidebookCategoryStyles];

export interface ProjectSupportVideo {
  videoUrl: string;
  description?: string;
  title: string;
  slug: string;
  relatedGuidebookPages?: GuidebookPage[];
}
