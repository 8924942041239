import { useCommonSelector } from '../../store/store';
import type { Lounge } from '../../types/hermes';

export const useLoungeIdByWorkspaceId = (workspaceId: number): number | undefined => {
  return useCommonSelector((state) => {
    return state.hermes.workspaces.byId[workspaceId]?.loungeId;
  });
};

export const useLoungeByWorkspaceId = (workspaceId: number): Lounge | undefined => {
  const loungeId = useLoungeIdByWorkspaceId(workspaceId);
  return useCommonSelector((state) => {
    if (loungeId == null) {
      return undefined;
    }
    return state.hermes.lounges.byId[loungeId];
  });
};
