import { useCommonSelector } from '../../store/store';
import type { Room } from '../../types/hermes';
import { hookUtils } from '../utils';

export function useProjectRooms(): Room[] {
  return useCommonSelector((state) => {
    return state.hermes.workspaces.projectIds.map((id) =>
      hookUtils.getRoomByWorkspaceId(id, state),
    );
  });
}

export const useRoomById = (roomId: number) => {
  return useCommonSelector((state) => {
    return state.hermes.rooms.byId[roomId];
  });
};

export const useWorkspacesLoaded = () => {
  return useCommonSelector((state) => state.hermes.workspacesLoaded);
};
