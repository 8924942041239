import { cn } from '@polygence/components';
import type { ReactNode } from 'react';

interface PaymentIntentPageLayoutProps {
  children: ReactNode;
  className?: string;
}

export const PaymentIntentPageLayout = ({ children, className }: PaymentIntentPageLayoutProps) => {
  return (
    <div
      className={cn(
        'tw-flex tw-min-h-[calc(100vh-var(--header-height))] tw-flex-col tw-bg-gray-100',
        className,
      )}
    >
      {children}
    </div>
  );
};
