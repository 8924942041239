import { Button, Icon, Skeleton, usePager } from '@polygence/components';

import { ApplicationSectionIds } from 'src/constants/application-sections';
import { useTagsQuery } from 'src/reducers/marketplaceReducer';
import { usePathfinderApplicationInterestQuery } from 'src/reducers/pathfinderApplicationInterestReducer';
import { PATHFINDERS_APPLICATION_SECTIONS } from 'src/students/pathfinder-application/pathfinders-student-application-sections';

const getSectionForIndex = (index: number) => {
  switch (index) {
    case 0:
      return ApplicationSectionIds.PATHFINDER_INTEREST_1;
    case 1:
      return ApplicationSectionIds.PATHFINDER_INTEREST_2;
    case 2:
      return ApplicationSectionIds.PATHFINDER_INTEREST_3;
    default:
      return undefined;
  }
};

export const PathfinderApplicationInterestTab = ({
  interestId,
  index,
  activeIndex,
}: {
  interestId: number;
  index: number;
  activeIndex: number;
}) => {
  const { setActivePage } = usePager();
  const { currentData: interest = { subjectTag: [] }, isLoading: isInterestLoading } =
    usePathfinderApplicationInterestQuery(interestId, { skip: interestId <= 0 });
  const { currentData: parentTags = [], isLoading: isParentTagsLoading } = useTagsQuery({
    category: 'subject_tag',
    type: 'parent',
  });
  const { currentData: childTags = [], isLoading: isChildTagsLoading } = useTagsQuery({
    category: 'subject_tag',
    type: 'child',
  });

  const isSubjectsLoading = isParentTagsLoading || isChildTagsLoading;
  const tags = [...parentTags, ...childTags];
  const tag = tags.find((tag) => tag.value === interest.subjectTag[0]);
  const isActive = activeIndex === index;

  const getButtonText = () => {
    if (isActive && (!interestId || interestId <= 0)) {
      return 'Choose below';
    }

    if (!tag) {
      return `Topic #${index + 1}`;
    }

    return tag.label;
  };

  const goToPage = () => {
    const pageIndex = PATHFINDERS_APPLICATION_SECTIONS.findIndex(
      (section) => section.id === getSectionForIndex(index),
    );

    if (pageIndex > -1) {
      setActivePage(pageIndex);
    }
  };

  if (isInterestLoading || isSubjectsLoading) {
    return <Skeleton variant="polygence-rounded" width="130px" height="40px" />;
  }

  return (
    <Button
      variant={index === activeIndex ? 'tertiary' : 'secondary'}
      disabled={index > activeIndex}
      {...(tag && index < activeIndex ? { endIcon: <Icon id="edit" /> } : {})}
      {...(isActive && (!interestId || interestId <= 0)
        ? { endIcon: <Icon id="arrow-down" /> }
        : {})}
      {...(isActive ? { href: '#selectTopic' } : { onClick: goToPage })}
    >
      {getButtonText()}
    </Button>
  );
};
