import { Button } from '@polygence/components';

import { usePolyGptProjectIdeaGenerator } from 'src/components/projectIdeaGenerator/useProjectIdeaGenerator';

export const ProjectIdeaStartApplicationCTA = ({ index }: { index: number }) => {
  const { likeIdea } = usePolyGptProjectIdeaGenerator();

  return (
    <Button
      variant="link-primary"
      className="-tw-mb-2 -tw-ms-6 tw-self-start tw-text-wrap tw-text-left"
      onClick={() => {
        likeIdea(index).catch(console.error);
      }}
    >
      Start application with this project idea
    </Button>
  );
};
