import { Heading } from '@polygence/components';
import { toast } from 'react-toastify';

import { NotificationSettingToggle } from 'src/components/usersettings/CounselorNotificationSettings/NotificationSettingToggle';
import {
  useNotificationSettingsQuery,
  useUpdateCounselorProfileMutation,
  useUpdateEmailSubscriptionMutation,
} from 'src/reducers/marketplaceReducer';

export const CounselorNotificationSettings = () => {
  const { data: counselorNotifications } = useNotificationSettingsQuery();
  const [updateCounselorProfile] = useUpdateCounselorProfileMutation();
  const [updateEmailSubscription, { isLoading }] = useUpdateEmailSubscriptionMutation();

  const handleSwitchChange = (checked: boolean, id: string) => {
    const payload =
      id === 'subscribe_to_marketing_emails'
        ? {
            subscribeToMarketingEmails: checked,
            subscribeToPartnerBenefitOpportunities: checked,
          }
        : { [id]: checked };

    updateCounselorProfile({ payload })
      .unwrap()
      .catch(() => {
        toast.error('Failed to update notification settings');
      });
  };

  const handleEmailSubscriptionChange = (checked: boolean, id: string) => {
    updateEmailSubscription({ payload: { subscribed: checked, hubspotEmailTemplateEmailId: id } })
      .unwrap()
      .catch(() => {
        toast.error('Failed to update email subscription');
      });
  };

  return (
    <div className="d-flex flex-column gap-10 mb-8">
      <Heading size="h3" alignment="left">
        Notification settings
      </Heading>
      <div className="d-flex flex-column gap-4">
        <Heading size="h4" alignment="left">
          Emails in general
        </Heading>
        <div className="d-flex flex-column gap-5">
          <NotificationSettingToggle
            id="subscribe_to_partner_benefit_opportunities"
            label="Partner benefit opportunities"
            description="Subscribe to partner benefit opportunities"
            toggleAriaLabel="Partner benefit opportunities notifications enabled"
            checked={Boolean(
              counselorNotifications?.notificationSettings?.subscribeToPartnerBenefitOpportunities,
            )}
            onChange={handleSwitchChange}
          />
          <NotificationSettingToggle
            id="subscribe_to_marketing_emails"
            label="All marketing emails"
            description="Subscribe to all marketing emails"
            toggleAriaLabel="Subscribe to all marketing emails enabled"
            checked={Boolean(
              counselorNotifications?.notificationSettings?.subscribeToMarketingEmails,
            )}
            onChange={handleSwitchChange}
          />
        </div>
      </div>
      {!!counselorNotifications?.emails?.length && (
        <div className="d-flex flex-column gap-4">
          <Heading size="h4" alignment="left">
            Specific emails
          </Heading>
          <div className="d-flex flex-column gap-5">
            {counselorNotifications.emails.map(
              ({ publicLabel, publicDescription, emailId, subscribed }) => (
                <NotificationSettingToggle
                  key={emailId}
                  id={emailId}
                  label={publicLabel}
                  description={publicDescription}
                  toggleAriaLabel={`${publicLabel} enabled`}
                  checked={subscribed}
                  onChange={handleEmailSubscriptionChange}
                  disabled={isLoading}
                />
              ),
            )}
          </div>
        </div>
      )}
    </div>
  );
};
