import { MentorProfileId } from '@polygence/common';
import type { ShowcasingSpecialty } from '@polygence/common/types/marketplace';
import { Heading } from '@polygence/components';
import { Select } from '@polygence/components';

import { useShowcasingSpecialties } from 'src/components/usersettings/ShowcasingSpecialties/useShowcasingSpecialties';
import { usePSSPublishingMentorAvailability } from 'src/hooks/useMentorAvailability';

interface ShowcasingSpecialtiesProps {
  mentorProfileId: MentorProfileId;
  showcasingSpecialties: ShowcasingSpecialty[];
}

export const ShowcasingSpecialties = ({
  mentorProfileId,
  showcasingSpecialties,
}: ShowcasingSpecialtiesProps) => {
  const pssMentorAvailability = usePSSPublishingMentorAvailability({ mentorProfileId });
  const { handleChange, initialValues, options } = useShowcasingSpecialties(showcasingSpecialties);

  if (!pssMentorAvailability || !pssMentorAvailability.available) {
    return null;
  }

  return (
    <div className="my-8">
      <Heading size="p" alignment="left" id="showcasing-specialty" className="mb-5">
        Showcasing Specialty
      </Heading>
      <Select
        isMulti
        isClearable
        placeholder="Select Specialties..."
        options={options}
        onChange={(newValues) => void handleChange(newValues)}
        defaultValue={initialValues}
      />
    </div>
  );
};
