import * as marketplaceApi from '@polygence/common/api/marketplace';
import { UserType } from '@polygence/common/types/backend';
import { UserProfileResponse } from '@polygence/common/types/marketplace';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { Switch } from 'react-router-dom';

import { PageLoad } from 'src/components/PageLoad';
import { getMyInfo } from 'src/components/getMyInfo';
import UserSettings from 'src/components/usersettings/UserSettings';

interface SettingsRoutesProps {
  popup: boolean;
  missing_desired_fields_list: string[];
}

export const SettingsRoutes = ({
  popup,
  missing_desired_fields_list: missingDesiredFieldsList,
}: SettingsRoutesProps) => {
  const [profile, setProfile] = useState<UserProfileResponse<UserType>>();

  useEffect(() => {
    const userType = getMyInfo().user_type;
    marketplaceApi
      .getUserProfile(userType)
      .then(({ data }) => {
        setProfile(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  if (!profile) {
    return <PageLoad />;
  }

  return (
    <div
      className={classNames({
        'col-sm-8 offset-sm-2 col-md-6 offset-md-3': !popup,
      })}
    >
      <Switch>
        <UserSettings profile={profile} missingDesiredFieldsList={missingDesiredFieldsList} />
      </Switch>
    </div>
  );
};
