import { externalLink, getLandingUrl } from '@polygence/common';
import { Icon, Text, cn } from '@polygence/components';

import { STUDENT_TEAM_EMAIL } from 'src/constants';

export const PaymentIntentFooter = () => {
  return (
    <div className="xl:tw-fixed xl:tw-bottom-0 xl:tw-left-0 xl:tw-z-[1000] xl:tw-w-full xl:tw-border-0 xl:tw-border-t xl:tw-border-solid xl:tw-border-t-gray-200 xl:tw-bg-gray-50 xl:tw-py-4 xl:tw-shadow-2xl">
      <div className="container tw-flex tw-flex-col tw-items-center tw-justify-between tw-gap-3 xl:tw-flex-row">
        <Text
          size="small"
          fontWeight="bold"
          className="tw-inline-flex tw-items-center tw-justify-center tw-gap-1 tw-text-gray-400"
        >
          <Icon id="external-link" size="sm" />
          <a
            href={getLandingUrl('/terms-of-use')}
            className="tw-text-gray-400 tw-underline"
            {...externalLink}
          >
            Polygence Terms of Service
          </a>
        </Text>
        <Text
          size="small"
          fontWeight="bold"
          className="tw-inline-flex tw-flex-wrap tw-items-center tw-justify-center tw-gap-1 tw-text-gray-400"
        >
          <Icon id="help-circle" size="sm" />
          Have more questions? Reach out to{' '}
          <a href={`mailto:${STUDENT_TEAM_EMAIL}`} className="tw-text-gray-400 tw-underline">
            {STUDENT_TEAM_EMAIL}
          </a>
        </Text>
        <Text
          size="medium"
          fontWeight="bold"
          alignment="center"
          className={cn('tw-inline-flex tw-text-xs tw-text-primary-800', 'xl:tw-text-base')}
        >
          5000+ completed research projects and counting!
        </Text>
      </div>
    </div>
  );
};
