import { ProjectType } from '@polygence/common';
import type { Certificate as CertificateType } from '@polygence/common/types/certificates';
import { Text } from '@polygence/components';
import classNames from 'classnames';
import { useLayoutEffect, useState, useRef } from 'react';
import type { CSSProperties } from 'react';
import { Textfit } from 'react-textfit';

import PolygenceLogo from 'src/components/PolygenceLogo';
import CertificateRectLower from 'src/components/static/icons/certificate_rect_lower.svg?react';
import CertificateRectUpper from 'src/components/static/icons/certificate_rect_upper.svg?react';
import signatureJin from 'src/components/static/icons/signature_jc.svg';
import signatureJanos from 'src/components/static/icons/signature_jp.svg';
import styles from 'src/components/student/Certificate/Certificate.module.scss';
import { dayjs } from 'src/utils/dayjsCustom';

const EM_TO_CONTAINER_WIDTH_RATIO = 20;

const Certificate = ({
  certificateId,
  issuedToDisplayName,
  mentoredByDisplayName,
  createdAt,
  projectType,
  podShortTitle,
}: CertificateType) => {
  const [emValue, setEmValue] = useState(10);
  const containerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const updateEmValue = () => {
      containerRef.current &&
        setEmValue(containerRef.current.offsetWidth / EM_TO_CONTAINER_WIDTH_RATIO);
    };
    window.addEventListener('resize', updateEmValue);
    updateEmValue();

    // react-textfit does not render correctly on initial render so we should trigger a 'resize' event
    window.requestAnimationFrame(() => window.dispatchEvent(new Event('resize')));

    return () => window.removeEventListener('resize', updateEmValue);
  }, []);

  const getCertificateDescription = (projectType: string) => {
    if (projectType === ProjectType.PODS) {
      return `This certificate of completion certifies that ${issuedToDisplayName} has completed a ${podShortTitle} Polygence Pod with expert mentor ${mentoredByDisplayName}.`;
    }
    return `This certificate of completion certifies that ${issuedToDisplayName} has completed a rigorous, personalized research project under the guidance of expert mentor ${mentoredByDisplayName}.`;
  };

  return (
    <div className={styles['aspectRatioBox']}>
      <div
        className={styles['wrapper']}
        ref={containerRef}
        // eslint-disable-next-line react/forbid-dom-props
        style={{ '--em-value': `${emValue}px` } as CSSProperties}
      >
        <CertificateRectUpper className={classNames(styles['decorationBox'], styles['upper'])} />
        <PolygenceLogo className={styles['logo']} />
        <div className={styles['leftBox']}>
          <h1 className={classNames('d-flex flex-column align-items-center', styles['title'])}>
            <span className="d-block">Certificate </span>
            <span>of Completion</span>
          </h1>
          <div className={classNames('d-flex align-items-center', styles['date'])}>
            <span>{dayjs(createdAt).format('MMMM')}</span>
            <span>{dayjs(createdAt).year()}</span>
          </div>
        </div>
        <div className={classNames('d-flex flex-column', styles['rightBox'])}>
          <h4 className={classNames('text-uppercase', styles['nameTitle'])}>
            Proudly presented to
          </h4>
          <Textfit mode="single">
            <div className={classNames('d-flex justify-content-center', styles['issuedToName'])}>
              {issuedToDisplayName}
            </div>
          </Textfit>
          <p className={classNames(styles['description'])}>
            {getCertificateDescription(projectType)}
          </p>
        </div>
        <div className={classNames('d-flex justify-content-between', styles['signatures'])}>
          <Signature
            name="Jin Chow"
            title="Co-Founder and COO"
            signature={signatureJin}
            marginBottom={-0.5 * emValue}
            signatureWidth={1.5 * emValue}
          />
          <Signature
            name="Janos Perczel"
            title="Co-Founder and CEO"
            signature={signatureJanos}
            marginBottom={-0.3 * emValue}
            signatureWidth={3.2 * emValue}
          />
        </div>
        <Text
          size="small"
          fontWeight="light"
          className={classNames(styles['certificateId'], 'text-muted')}
        >
          Certificate ID: {certificateId}
        </Text>
        <CertificateRectLower className={classNames(styles['decorationBox'], styles['lower'])} />
        <span className={styles['motto']}>
          Polygence is an online research academy transforming education by helping curious minds
          unleash their passions through personalized projects.
        </span>
      </div>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default Certificate;

interface SignatureProps {
  name: string;
  title: string;
  signature: string;
  signatureWidth: number;
  marginBottom: number;
}
const Signature = ({ name, title, signature, marginBottom, signatureWidth }: SignatureProps) => {
  return (
    <div
      className={classNames(
        'd-flex flex-column align-items-center justify-content-end',
        styles['signatureWrapper'],
      )}
    >
      <img
        src={signature}
        alt="Signature"
        width={`${signatureWidth}px`}
        height="auto"
        // eslint-disable-next-line react/forbid-dom-props
        style={{
          marginBottom: `${marginBottom}px`,
        }}
      />
      <div
        className={classNames(
          'd-flex flex-column justify-content-center align-items-center text-center',
          styles['signatureContainer'],
        )}
      >
        <div>
          <span className="d-block">{name}</span>
          <span>{title}</span>
        </div>
      </div>
    </div>
  );
};
