import { commonHooks } from '@polygence/common/hooks';
import type { TimeSlot } from '@polygence/common/types/hermes';
import { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';

import { useUpdateMentorProfileMutation } from 'src/reducers/marketplaceReducer';

export const useAutoScheduleSetting = (isAutoAccepting: boolean, timeSlots?: TimeSlot[]) => {
  const currentUser = commonHooks.useCurrentUser();
  const [updateMentorProfile] = useUpdateMentorProfileMutation();

  const [isAutoScheduleChecked, setIsAutoScheduleChecked] = useState(isAutoAccepting);
  const [isAutoScheduleEnabled, setIsAutoScheduleEnabled] = useState(false);
  const [isAutoScheduleLoading, setIsAutoScheduleLoading] = useState(false);

  const handleAutoScheduleChange = useCallback(
    (checked: boolean) => {
      const autoAcceptingSessionProposalsAt = checked ? new Date().toISOString() : null;

      setIsAutoScheduleLoading(true);
      updateMentorProfile({ autoAcceptingSessionProposalsAt })
        .unwrap()
        .then(() => {
          const message = checked
            ? 'Session proposals will be accepted automatically.'
            : 'Session proposals should be accepted manually.';

          setIsAutoScheduleChecked(checked);
          toast.success(message);
        })
        .catch(() => toast.error('Something went wrong please try again.'))
        .finally(() => setIsAutoScheduleLoading(false));
    },
    [updateMentorProfile],
  );

  useEffect(() => {
    if (timeSlots?.length) {
      const canEnableAutoSchedule = !!timeSlots.length && currentUser.calendarConnected;
      setIsAutoScheduleEnabled(canEnableAutoSchedule);

      if (!canEnableAutoSchedule && isAutoScheduleChecked) {
        setIsAutoScheduleChecked(false);
        handleAutoScheduleChange(false);
      }
    }
  }, [
    timeSlots?.length,
    currentUser.calendarConnected,
    isAutoScheduleChecked,
    handleAutoScheduleChange,
  ]);

  return {
    isAutoScheduleChecked,
    isAutoScheduleEnabled: isAutoScheduleEnabled && !isAutoScheduleLoading,
    handleAutoScheduleChange,
  };
};
