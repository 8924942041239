import { Button } from '@polygence/components';
import classnames from 'classnames';
import React, { Fragment } from 'react';

import CredentialsSection from 'src/components/mentor/MentorOverview/CredentialsSection';
import styles from 'src/components/mentor/MentorOverview/MentorOverview.module.sass';
import MentorOverviewSection from 'src/components/mentor/MentorOverview/MentorOverviewSection';

export const MentorOverview = ({
  profile,
  displayApplyButton = false,
  applyButtonHref = 'https://app.polygence.org/user/register',
}) => {
  const DEFAULT_CREDENTIAL_STATUS = 'Industry expert';

  const renderProjects = (projects) => {
    return projects.map((project, index, arr) => {
      return (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={`project-${index}`}>
          <h4 className={classnames('text-bold', styles['fs-1'])}>
            {project.title ? project.title : `Project ${arr.length < 2 ? null : index + 1}`}
          </h4>
          <p className={styles.projectDescription}>{project.description}</p>
        </Fragment>
      );
    });
  };

  const hasExperience = (profile) => {
    const EXPERIENCE_LIMIT = 4;
    return (
      Array.from({ length: EXPERIENCE_LIMIT }, (_, i) => i + 1).filter(
        (index) => profile[`experience_${index}`],
      ).length > 0
    );
  };
  const renderExperience = (profile) => {
    const EXPERIENCE_LIMIT = 4;
    return Array.from({ length: EXPERIENCE_LIMIT }, (_, i) => i + 1).map((index) => {
      return (
        profile[`experience_${index}`] && (
          <div key={`experience-${index}`} className={classnames('col mb-5', styles['fs-0_9'])}>
            {profile[`experience_institution_${index}`] && (
              <div>
                <span>{profile[`experience_institution_${index}`]}</span>
                {profile[`experience_${index}_start`] && (
                  <span>
                    {/* eslint-disable-next-line sonarjs/no-nested-template-literals */}
                    {` (${profile[`experience_${index}_start`]} - ${
                      // eslint-disable-next-line sonarjs/no-nested-template-literals
                      profile[`experience_${index}_end`] || 'Current'
                    })`}
                  </span>
                )}
              </div>
            )}
            <span
              className={classnames(
                // eslint-disable-next-line sonarjs/no-duplicate-string
                'font-italic font-weight-light',
                styles.blueText,
                styles['fs-1'],
              )}
            >
              {profile[`experience_${index}`]}
            </span>
          </div>
        )
      );
    });
  };

  const fixEducationPropMisnaming = (property) => {
    return (
      {
        masters_2_degree: 'masters_degree_2',
        masters_2_obtained: 'masters_obtained_2',
        masters_2_award_year: 'masters_award_year_2',
        masters_2_field: 'masters_field_2',
      }[property] || property
    );
  };
  const hasEducation = (profile) => {
    const EDUCATIONS = ['college', 'masters', 'masters_2', 'phd'];
    return EDUCATIONS.filter((education) => profile[education]).length > 0;
  };
  const renderEducation = (profile) => {
    const EDUCATIONS = ['college', 'masters', 'masters_2', 'phd'];
    return EDUCATIONS.map((education) => {
      const institute = profile[education];
      const degree = profile[fixEducationPropMisnaming(`${education}_degree`)];
      const obtained = profile[fixEducationPropMisnaming(`${education}_obtained`)];
      const awardYear = profile[fixEducationPropMisnaming(`${education}_award_year`)];
      const field = profile[fixEducationPropMisnaming(`${education}_field`)];

      return (
        institute && (
          <div className={classnames('col mb-5', styles['fs-0_9'])} key={`education-${institute}`}>
            <span className="text-bold">{institute}</span>
            <div>
              {degree && <span>{degree}</span>}
              {obtained === false && <span> candidate</span>}
              {obtained && awardYear && <span>{` (${awardYear})`}</span>}
            </div>
            {field && (
              <span
                className={classnames(
                  'font-italic font-weight-light',
                  styles.blueText,
                  styles['fs-1'],
                )}
              >
                {field}
              </span>
            )}
          </div>
        )
      );
    });
  };

  const renderReviews = (reviews) => {
    return reviews.map((review, index) => {
      return (
        // eslint-disable-next-line react/no-array-index-key
        <div className="d-flex flex-column mb-10" key={`review-${index}`}>
          <p>{`"${review.review}"`}</p>
          <div className="align-self-end d-flex">
            <div className="d-flex align-items-center">
              <span
                className={classnames(
                  'font-italic font-weight-light',
                  styles.authorMarker,
                  styles.blueText,
                  styles['fs-1'],
                )}
              >
                {review.author}
              </span>
            </div>
            {review.profile_picture && (
              <div className={classnames('ms-3', styles.authorPicture)}>
                {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                <img src={review.profile_picture} alt={`${review.author} profile image`} />
              </div>
            )}
          </div>
        </div>
      );
    });
  };

  return (
    <div className={classnames('container px-0', styles['container'])}>
      <section className={classnames('row mx-0 mb-7', styles['mt-sm-6'], styles['mb-md-10'])}>
        <div className="col-md-3 me-md-7 mb-5 mb-md-0 p-0">
          <img src={profile.profile_picture} className="card-img rounded-0" alt="profile pic" />
        </div>
        <div className="d-flex flex-column col p-md-0">
          <h1>
            {profile.first_name} {profile.last_name_initial}{' '}
            <span className={styles['headingAddon']}>- Research Program Mentor</span>
          </h1>
          <h2 className={classnames('mb-10', styles.credentialStatus, styles.elegantSeparator)}>
            {`${profile.credential_status_label || DEFAULT_CREDENTIAL_STATUS} at ${
              profile.highest_institution
            }`}
          </h2>
          <div className="d-flex flex-row align-items-baseline mb-3">
            <div className={classnames('me-3', styles['fs-0_9'], styles.label)}>Expertise</div>
            <div className={classnames(styles['fs-0_9'])}>{profile.subject}</div>
          </div>
        </div>
      </section>
      {profile.bio && <MentorOverviewSection title="Bio">{profile.bio}</MentorOverviewSection>}
      {profile.projects_v2 && (
        <MentorOverviewSection title="Project ideas">
          <div className={classnames('d-flex', styles.infoBox)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#3366FF"
              strokeWidth={2.5}
              strokeLinecap="round"
              strokeLinejoin="round"
              className="flex-shrink-0"
            >
              <circle cx={12} cy={12} r={10} />
              <line x1={12} y1={16} x2={12} y2={12} />
              <line x1={12} y1={8} x2="12.01" y2={8} />
            </svg>
            <div className={styles.infoBoxText}>
              Project ideas are meant to help inspire students thinking about their own project.
              Students are in the driver seat of their research and are free to use any or none of
              the ideas shared by their mentors.
            </div>
          </div>
          {renderProjects(profile.projects_v2)}
        </MentorOverviewSection>
      )}
      {profile.coding_skills && (
        <MentorOverviewSection title="Coding skills">{profile.coding_skills}</MentorOverviewSection>
      )}
      {profile.language_skills && (
        <MentorOverviewSection title="Languages I know">
          {profile.language_skills}
        </MentorOverviewSection>
      )}
      {profile.teaching_details && (
        <MentorOverviewSection title="Teaching experience">
          {profile.teaching_details}
        </MentorOverviewSection>
      )}
      {(hasExperience(profile) || hasEducation(profile)) && (
        <MentorOverviewSection title="Credentials">
          {hasExperience(profile) && (
            <CredentialsSection title="Work experience">
              {renderExperience(profile)}
            </CredentialsSection>
          )}
          {hasEducation(profile) && (
            <CredentialsSection title="Education">{renderEducation(profile)}</CredentialsSection>
          )}
        </MentorOverviewSection>
      )}
      {profile.mentor_review?.length > 0 && (
        <MentorOverviewSection title="Reviews">
          {renderReviews(profile.mentor_review)}
        </MentorOverviewSection>
      )}
      {displayApplyButton && (
        <div className="d-flex flex-column align-items-center mt-11">
          <h4>{`Interested in working with expert mentors like ${profile.first_name}?`}</h4>
          <Button
            variant="primary"
            as="a"
            href={applyButtonHref}
            className="mt-5"
            id="apply-now-from-mentor"
          >
            <span className="px-md-5 px-3">Apply now</span>
          </Button>
        </div>
      )}
    </div>
  );
};
