import { Select } from '@polygence/components';
import type { OptionType } from '@polygence/components';
import type { ActionMeta, OnChangeValue } from 'react-select';

import { useTagsQuery } from 'src/reducers/marketplaceReducer';

interface TagSelectorProps<IsMulti extends boolean> {
  value?: IsMulti extends true ? number[] : number;
  isMulti: IsMulti;
  onChange: (
    newValue: OnChangeValue<OptionType, IsMulti>,
    actionMeta: ActionMeta<OptionType>,
  ) => void;
  label?: string;
  isDisabled?: boolean;
  mapOptions?: (option: OptionType) => OptionType;
  type?: 'child' | 'parent';
  showPrivate?: boolean;
  isInvalid?: boolean;
}

export const TagSelector = <IsMulti extends boolean>({
  value,
  onChange,
  label,
  isMulti,
  mapOptions,
  type,
  isDisabled = false,
  showPrivate = false,
  isInvalid,
}: TagSelectorProps<IsMulti>) => {
  const { data, isLoading } = useTagsQuery({
    category: 'subject_tag',
    type,
    showPrivate,
  });
  const tags = data || [];
  const options = mapOptions ? tags.map(mapOptions) : tags;

  const selectedOption = Array.isArray(value)
    ? (value.map((v) => options?.find((tag) => tag.value === v)).filter(Boolean) as OptionType[])
    : tags?.find((tag) => tag.value === value);

  return (
    <>
      {label && <label className="form-label">{label}</label>}
      <Select
        options={options}
        onChange={onChange}
        isMulti={isMulti}
        value={selectedOption}
        isLoading={isLoading}
        isDisabled={isDisabled}
        isInvalid={isInvalid}
      />
    </>
  );
};
