import type { CartUserDataVerification } from '@polygence/common/types/payment';
import { Text, Card, Button } from '@polygence/components';

import { urls } from 'src/urls';

export interface PaymentSuccessConfirmationMessageProps {
  cartUser: CartUserDataVerification;
}

export const PaymentSuccessConfirmationMessage = ({
  cartUser,
}: PaymentSuccessConfirmationMessageProps) => {
  return (
    <>
      <Text size="large" alignment="center" className="tw-mt-5">
        Confirm your information below to ensure we can find the best match possible.
      </Text>
      <Card className="flex-row gap-9 p-9 tw-mt-8 tw-flex tw-bg-gray-100">
        <div>
          <Text size="medium" fontWeight="bold">
            Email
          </Text>
          <Text size="medium" className="tw-mb-3">
            {cartUser.email}
          </Text>
          <Text size="medium" fontWeight="bold">
            Phone number
          </Text>
          <Text size="medium" className="tw-mb-3">
            {cartUser.phoneNumber}
          </Text>
          <Text size="medium" fontWeight="bold">
            Time Zone
          </Text>
          <Text size="medium">{cartUser.timeZone}</Text>
        </div>
        <div>
          <Text size="medium" fontWeight="bold" className="tw-mb-3">
            Information incorrect?
          </Text>
          <Button variant="primary" href={urls.settingsPage()}>
            Go to Settings
          </Button>
        </div>
      </Card>
    </>
  );
};
