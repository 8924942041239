import { useMediaQuery } from '@polygence/common/hooks/useMediaQuery';
import { Button, Icon } from '@polygence/components';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';

import { setCssVariable } from 'src/utils/common';

const MotionButton = motion(Button);
const MotionIcon = motion(Icon);

interface PaymentIntentProceedButtonMobileProps {
  v3Enabled: boolean;
  disabled: boolean;
  onProceed: VoidFunction;
}

export const PaymentIntentProceedButtonMobile = ({
  v3Enabled,
  disabled,
  onProceed,
}: PaymentIntentProceedButtonMobileProps) => {
  const [hasScrolled, setHasScrolled] = useState(false);

  const isMobile = useMediaQuery('(max-width: 767px)');

  useEffect(() => {
    if (document.documentElement.clientHeight >= document.documentElement.scrollHeight) {
      setTimeout(() => setHasScrolled(true), 500);
      return;
    }

    const handleScroll = () => {
      const minScroll = Math.min(
        document.documentElement.scrollHeight - document.documentElement.clientHeight,
        100,
      );

      if (window.scrollY > minScroll) {
        setHasScrolled(true);
        document.removeEventListener('scroll', handleScroll);
      }
    };

    document.addEventListener('scroll', handleScroll);
    return () => document.removeEventListener('scroll', handleScroll);
  }, [v3Enabled, isMobile]);

  useEffect(() => {
    if (!isMobile) {
      setCssVariable('--scroll-to-top-button-bottom', '1rem');
    } else {
      setCssVariable('--scroll-to-top-button-bottom', v3Enabled ? '5.25rem' : '6rem');
    }
  }, [v3Enabled, isMobile]);

  if (v3Enabled) {
    return (
      <div className="tw-fixed tw-bottom-0 tw-z-[1000] tw-flex tw-w-full tw-border-0 tw-border-t tw-border-solid tw-border-t-gray-200 tw-bg-white tw-px-4 tw-py-3 tw-shadow-2xl md:tw-hidden">
        <Button
          variant="tertiary"
          endIcon={<Icon id="chevron-right" />}
          disabled={disabled}
          className="tw-h-12 tw-w-full tw-bg-primary-900 tw-font-bold tw-text-white"
          onClick={onProceed}
        >
          Proceed with payment
        </Button>
      </div>
    );
  }

  return (
    <div className="tw-mt-24 md:tw-mt-0 md:tw-hidden">
      <MotionButton
        variant="primary"
        size="lg"
        layoutId="proceed"
        transition={{ duration: 0.8, type: 'spring' }}
        className="tw-fixed tw-bottom-6 tw-right-3 tw-z-[1000] tw-p-4"
        endIcon={<MotionIcon id="chevron-right" layout="position" />}
        disabled={disabled}
        onClick={onProceed}
      />
      {hasScrolled && (
        <MotionButton
          variant="primary"
          size="lg"
          layoutId="proceed"
          transition={{ duration: 0.8, type: 'spring' }}
          className="tw-fixed tw-bottom-6 tw-right-3 tw-z-[1000] tw-p-4"
          endIcon={<MotionIcon id="chevron-right" layout="position" />}
          disabled={disabled}
          onClick={onProceed}
        >
          <motion.span
            layout="position"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 0.2, duration: 0.4 } }}
          >
            Proceed with payment
          </motion.span>
        </MotionButton>
      )}
    </div>
  );
};
