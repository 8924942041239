import { commonHooks, Nullable } from '@polygence/common';
import * as certificatesApi from '@polygence/common/api/certificates';
import type { Certificate as CertificateType } from '@polygence/common/types/certificates';
import type { CertificateUUID } from '@polygence/common/types/common';
import { Button, Icon } from '@polygence/components';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { PageLoad } from 'src/components/PageLoad';
import { ErrorRedirect } from 'src/components/common/ErrorRedirect';
import Certificate from 'src/components/student/Certificate';

const CertificatePage = () => {
  const [componentState, setComponentState] = useState<'LOADING' | 'OK' | 'ERRORED'>('LOADING');
  const { certificateUuid } = useParams<{ certificateUuid: CertificateUUID }>();
  const [certificate, setCertificate] = useState<Nullable<CertificateType>>(null);
  const currentUser = commonHooks.useCurrentUser();
  const isOwnCertificate = certificate && currentUser && currentUser.id === certificate.issuedTo;

  useEffect(() => {
    certificatesApi
      .getCertificateOfProjectCompletion(certificateUuid)
      .then(({ data }) => {
        setCertificate(data);
        setComponentState('OK');
      })
      .catch((err) => {
        console.error(err);
        setComponentState('ERRORED');
      });
  }, [certificateUuid]);

  if (componentState === 'LOADING') {
    return <PageLoad />;
  } else if (componentState === 'OK' && certificate) {
    return (
      <>
        <Certificate {...certificate} />
        {isOwnCertificate && (
          <div className="hide-in-print">
            <div className="d-flex justify-content-center">
              <Button
                variant="primary"
                startIcon={<Icon id="printer" />}
                onClick={() => window.print()}
                size="lg"
              >
                Print certificate
              </Button>
            </div>
          </div>
        )}
      </>
    );
  } else {
    return <ErrorRedirect />;
  }
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default CertificatePage;
