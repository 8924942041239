import { RootState } from '@polygence/common/store/store';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { safeParse, string, object, Input, picklist } from 'valibot';

import { HermesAIMessageThreadId, OpenAIMessageId, UserSnippetId } from '../../types/common';

export const CitationSchema = object({
  title: string(),
  url: string(),
  type: picklist(['polygence', 'web']),
});

export type Citation = Input<typeof CitationSchema>;

interface CitationState {
  citationLinks: string[];
  parsedCitations: Record<string, Citation>;
}

export interface HermesAiState {
  selectedThreadId: HermesAIMessageThreadId | null;
  unsentSnippets: UserSnippetId[];
  isThreadSelectorOpen: boolean;
  citationsForMessage: Record<OpenAIMessageId, CitationState>;
}

const initialState: HermesAiState = {
  selectedThreadId: null,
  unsentSnippets: [],
  isThreadSelectorOpen: false,
  citationsForMessage: {},
};

const hermesAiSlice = createSlice({
  name: 'hermesAi',
  initialState,
  reducers: {
    addUnsentSnippet: (state, { payload }: { payload: UserSnippetId }) => {
      state.unsentSnippets.push(payload);
    },
    removeUnsentSnippet: (state, { payload }: { payload: UserSnippetId }) => {
      state.unsentSnippets = state.unsentSnippets.filter((id) => id !== payload);
    },
    clearUnsentSnippets: (state) => {
      state.unsentSnippets = [];
    },
    toggleThreadSelector: (state) => {
      state.isThreadSelectorOpen = !state.isThreadSelectorOpen;
    },
    closeThreadSelector: (state) => {
      state.isThreadSelectorOpen = false;
    },
    setSelectedThreadId: (state, { payload }: { payload: HermesAIMessageThreadId | null }) => {
      state.selectedThreadId = payload;
      state.isThreadSelectorOpen = false;
    },
    registerCitation: (
      state,
      {
        payload,
      }: { payload: { messageId: OpenAIMessageId; title: string; type: string; url: string } },
    ) => {
      const { success, output: citation } = safeParse(CitationSchema, {
        title: payload.title,
        url: payload.url,
        type: payload.type,
      });

      if (success) {
        if (!state.citationsForMessage[payload.messageId]) {
          state.citationsForMessage[payload.messageId] = {
            citationLinks: [],
            parsedCitations: {},
          };
        }

        if (!state.citationsForMessage[payload.messageId]?.citationLinks.includes(payload.url)) {
          state.citationsForMessage[payload.messageId]!.citationLinks.push(payload.url);
          state.citationsForMessage[payload.messageId]!.parsedCitations[payload.url] = citation;
        }
      }
    },
    removeCitation: (
      state,
      { payload }: { payload: { messageId: OpenAIMessageId; url: string } },
    ) => {
      const citationsForMessage = state.citationsForMessage[payload.messageId];
      if (citationsForMessage) {
        state.citationsForMessage[payload.messageId]!.citationLinks =
          citationsForMessage.citationLinks.filter((link) => link !== payload.url);
      }
    },
  },
});

const { actions, reducer } = hermesAiSlice;

const selectHermesAiState = (state: RootState) => state.hermesAi;
const selectUnsentSnippets = (state: HermesAiState) => state.unsentSnippets;
const selectCitationsForMessage = (state: HermesAiState) => state.citationsForMessage;
const unsentSnippetsSelector = createSelector(selectHermesAiState, selectUnsentSnippets);
const isThreadSelectorOpenSelector = createSelector(
  selectHermesAiState,
  (state) => state.isThreadSelectorOpen,
);
const selectedThreadIdSelector = createSelector(
  selectHermesAiState,
  (state) => state.selectedThreadId,
);
const citationsForMessageStateSelector = createSelector(
  selectHermesAiState,
  selectCitationsForMessage,
);

const citationsLinksForMessageSelector = createSelector(
  [citationsForMessageStateSelector, (_, messageId: OpenAIMessageId | undefined) => messageId],
  (state, messageId) => {
    if (!messageId) {
      return [];
    }
    return state[messageId]?.citationLinks ?? [];
  },
);

const citationsForMessageSelector = createSelector(
  [citationsForMessageStateSelector, (_, messageId: OpenAIMessageId | undefined) => messageId],
  (state, messageId) => {
    if (!messageId) {
      return [];
    }
    return (state[messageId]?.citationLinks ?? [])
      .map((link) => state[messageId]!.parsedCitations[link])
      .filter((citation): citation is Citation => !!citation);
  },
);

export const hermesAiSelectors = {
  selectedThreadId: selectedThreadIdSelector,
  unsentSnippets: unsentSnippetsSelector,
  isThreadSelectorOpen: isThreadSelectorOpenSelector,
  citationsForMessage: citationsForMessageSelector,
  citationsLinksForMessage: citationsLinksForMessageSelector,
};

export const hermesAiActions = actions;

export const hermesAiReducer = reducer;
