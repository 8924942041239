import { useCallback, useEffect, useState } from 'react';

import type { FetchPaginatedData } from '../types/data/common';

const PAGE_SIZE = 10;
const PAGINATION = 7;

// eslint-disable-next-line import/no-default-export -- autodisabled
export default function usePaging<T>(
  fetchPaginatedData: FetchPaginatedData<T>,
  {
    prefetchedPages = {},
    prefetchedCount = 1,
    pageSize = PAGE_SIZE,
    pagination = PAGINATION,
    search = '',
  } = {},
) {
  const [data, setData] = useState<Record<string, T[]>>(prefetchedPages);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(prefetchedCount);
  const [dataFor, setDataFor] = useState('');
  const [loading, setLoading] = useState(false);
  const [trigger, setTrigger] = useState(false);

  const lastPage = Math.ceil(count / pageSize);
  const firstPageButton = Math.min(
    Math.max(page - Math.floor(pagination / 2), 1),
    Math.max(lastPage - pagination + 1, 1),
  );
  const pages = Array.from(
    { length: Math.min(pagination, lastPage) },
    (_, i) => i + firstPageButton,
  );
  const currentName = `page${page}`;
  const currentPageData = data[currentName];

  const goNext = () => setPage((page % lastPage) + 1);
  const goPrevious = () => setPage(((lastPage + page - 2) % lastPage) + 1);

  const validSearch = search !== dataFor && (search.length >= 3 || search === '');

  const memoizedFetch = useCallback(() => {
    fetchPaginatedData({ page, pageSize }, (fetchedPages, fetchedCount) => {
      if (fetchedPages) {
        setData((previousData) => ({ ...previousData, [currentName]: fetchedPages }));
        setCount(fetchedCount);
        setLoading(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize, search, currentName, trigger]);

  useEffect(() => {
    if (validSearch) {
      setData({});
    }
    if (!currentPageData || validSearch) {
      setLoading(true);
      setDataFor(search);
      memoizedFetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoizedFetch]);

  const forceReload = ({
    page: currentPage = 1,
    data: currentData = {},
    count: currentCount = 1,
  } = {}) => {
    setPage(currentPage);
    setData(currentData);
    setCount(currentCount);
    setTrigger((prevState) => {
      return !prevState;
    });
  };

  return {
    currentPageData,
    page,
    pages,
    setPage,
    lastPage,
    forceReload,
    loading,
    data,
    count,
    setData,
    setCount,
    goNext,
    goPrevious,
  };
}
