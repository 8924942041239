import type { ProductId } from '@polygence/common/types/common';
import type { CartItem } from '@polygence/common/types/payment';
import type { Product } from '@polygence/common/types/studentApplication';
import { Checkbox, Icon, Text, cn } from '@polygence/components';

import { PaymentIntentAmountOrPlaceholder } from 'src/payment/PaymentIntent/PaymentIntentAmountOrPlaceholder';
import { PaymentIntentProductDetails } from 'src/payment/PaymentIntent/PaymentIntentProductDetails';
import { getCartItemPriceInDollars } from 'src/payment/utils';

const FLAGSHIP_ID = 1;
const UNREMOVABLE_PRODUCT_IDS = [FLAGSHIP_ID];

export const GROUP_HEADERS = {
  pss: 'Premium Showcasing Support',
  pods: 'Pods',
  '': '',
};

interface PaymentIntentProductProps {
  product: Product;
  cartItem?: CartItem;
  isSelected: boolean;
  isRemovable?: boolean;
  onChange: (productId: ProductId, selected: boolean) => void;
}

export const PaymentIntentProduct = ({
  product,
  cartItem,
  isSelected,
  isRemovable,
  onChange,
}: PaymentIntentProductProps) => {
  const quantity = cartItem?.quantity ?? 1;
  const isUnRemovable =
    isRemovable == null ? UNREMOVABLE_PRODUCT_IDS.includes(product.id) : !isRemovable;

  const productPrice = getCartItemPriceInDollars(cartItem) ?? product.basePriceInDollars;

  const publicName = product.group
    ? `${GROUP_HEADERS[product.group]} - ${product.publicName}`
    : product.publicName;

  return (
    <div
      className={cn(
        'tw-flex tw-items-center *:tw-text-sm [&_.custom-polygence-checkbox]:tw-bg-gray-300',
        !isSelected && 'tw-line-through tw-opacity-40',
      )}
    >
      {isUnRemovable && isSelected ? (
        <>
          <Icon id="check" size="md" className="tw-ml-0.5 tw-mr-2.5" />
          <div>
            <Text
              as="span"
              size="medium"
              alignment="left"
              fontWeight="bold"
              className="tw-mr-2 tw-inline tw-text-sm"
            >
              {quantity > 1 ? `${quantity} x ${publicName}` : publicName}
            </Text>
            <PaymentIntentProductDetails details={product.publicDetails} />
          </div>
        </>
      ) : (
        <Checkbox
          name={product.name}
          label={
            <>
              <span data-cy={`check-${product.publicName}`} className="tw-mr-2">
                {publicName}
              </span>
              <PaymentIntentProductDetails details={product.publicDetails} />
            </>
          }
          size="medium"
          checked={isSelected}
          onChange={(e) => onChange(product.id, e.currentTarget.checked)}
        />
      )}
      <div className="tw-ms-auto tw-pl-4">
        <PaymentIntentAmountOrPlaceholder amount={productPrice} className="tw-text-sm" />
      </div>
    </div>
  );
};
