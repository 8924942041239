import type { ApplicationComponentDescription } from 'src/students/student-application';
import {
  displayForConnectedCounselor,
  displayIfNoCounselor,
  textFieldSpacer,
} from 'src/students/student-application/common-section-components';

export const counselorDetailsFollowup = Object.freeze<ApplicationComponentDescription[]>([
  {
    component: 'ComponentsText',
    props: {
      children: 'Your account is connected to your counselor’s account.',
      size: 'medium',
    },
    display: displayForConnectedCounselor,
  },
  textFieldSpacer,
  {
    component: 'InputField',
    tag: 'profile',
    props: {
      label: 'Counselor first name',
      name: 'counselorFirst',
    },
    validation: {
      required: true,
    },
    display: displayIfNoCounselor,
  },
  {
    component: 'InputField',
    tag: 'profile',
    props: {
      label: 'Counselor first name',
      name: 'connectedCounselorFirstName',
      disabled: true,
    },
    validation: {
      required: true,
    },
    display: displayForConnectedCounselor,
  },
  textFieldSpacer,
  {
    component: 'InputField',
    tag: 'profile',
    props: {
      label: 'Counselor last name',
      name: 'counselorLast',
    },
    validation: {
      required: true,
    },
    display: displayIfNoCounselor,
  },
  {
    component: 'InputField',
    tag: 'profile',
    props: {
      label: 'Counselor last name',
      name: 'connectedCounselorLastName',
      disabled: true,
    },
    validation: {
      required: true,
    },
    display: displayForConnectedCounselor,
  },
  textFieldSpacer,
  {
    component: 'InputField',
    tag: 'profile',
    props: {
      label: 'Counselor email address',
      name: 'counselorEmail',
    },
    validation: {
      required: true,
      email: true,
    },
    display: {
      $is: {
        field: 'connectedCounselorFirstName',
        value: null,
      },
    },
  },
  {
    component: 'InputField',
    tag: 'profile',
    props: {
      label: 'Counselor email address',
      name: 'connectedCounselorEmail',
      disabled: true,
    },
    display: displayForConnectedCounselor,
  },
  textFieldSpacer,
  {
    component: 'InputField',
    tag: 'profile',
    props: {
      label: 'Counselor organization (optional)',
      name: 'counselorOrganization',
    },
    display: displayIfNoCounselor,
  },
  {
    component: 'InputField',
    tag: 'profile',
    props: {
      label: 'Counselor organization',
      name: 'connectedCounselorOrganization',
      disabled: true,
    },
    display: displayForConnectedCounselor,
  },
  textFieldSpacer,
]);
