import type { Store } from '@reduxjs/toolkit';

import { commonAxios } from './api/fetch';
import type { CommonStore } from './store/store';
import type { Environment } from './types/utils';

let commonSettings: Common = {
  internalStore: null,
  storageManager: null,
  environment: null,
  baseDomain: null,
};

interface Common {
  internalStore: CommonStore | null;
  storageManager: PersistentStorageManager | null;
  environment: Environment | null;
  baseDomain: string | null;
}

interface CommonProps {
  internalStore: Store | null;
  storageManager: PersistentStorageManager | null;
  environment: Environment | null;
  baseDomain: string | null;
}

export interface PersistentStorageManager {
  get: <T>(key: string) => Promise<T | null>;
  set: <T>(key: string, value: T) => void;
  remove: (key: string) => void;
}

const voidStorageManager: PersistentStorageManager = {
  get: () => new Promise((resolve) => resolve(null)),
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  set: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  remove: () => {},
};

export const configureCommon = (options: CommonProps) => {
  // eslint-disable-next-line fp/no-mutation
  commonSettings = { ...options };
};

export const getRestClient = () => {
  return commonAxios;
};

export const getCommonStore = () => {
  if (commonSettings.internalStore == null) {
    throw new Error('Internal store is null. Did you forget to call `configureCommon`?');
  }
  return commonSettings.internalStore;
};

export const getCommonStorage = () => {
  if (commonSettings.storageManager == null) {
    // eslint-disable-next-line no-console
    console.warn('No persistentStorageManager found!');
    return voidStorageManager;
  }
  return commonSettings.storageManager;
};

export const getEnvironment = () => {
  if (commonSettings.environment == null) {
    throw new Error('Environment is null. Did you forget to call `configureCommon`?');
  }

  return commonSettings.environment;
};

export const getBaseDomain = () => {
  if (commonSettings.baseDomain == null) {
    throw new Error('BaseDomain is null. Did you forget to call `configureCommon`?');
  }

  return commonSettings.baseDomain;
};
