import { useDispatch } from 'react-redux';

import { commonReducers } from '../store/reducers/index';
import type { Session } from '../types/hermes';
import type { AtLeast } from '../types/utils';
import { getActionForSession } from '../utils/hermes/getActionForSession';
import { permissions } from '../utils/permissions';

import useCurrentUser from './selectors/useCurrentUser';
import useMeetingById from './selectors/useMeetingById';
import useSelectedProject from './selectors/useSelectedProject';
import useSelectedRoom from './selectors/useSelectedRoom';
import useSelectedWorkspace from './selectors/useSelectedWorkspace';
import { usePermissions } from './usePermissions';

type SessionStates =
  | 'NoActionState'
  | 'DisabledScheduleState'
  | 'ScheduleClickableState'
  | 'ProposeClickableState'
  | 'ConfirmProposalClickableState'
  | 'WaitingForResponseState'
  | 'OfficiallyScheduledState'
  | 'SubmitSummaryClickableState'
  | 'DisabledSubmitSummaryState'
  | 'SubmitFeedbackClickableState'
  | 'NoShowState'
  | 'MeetingInsightState'
  | 'MeetingVideoAvailableState'
  | 'DoneState'
  | 'LateCancelledState';

export function useSession(session: AtLeast<Session, 'sessionNumber'>) {
  const { id, meetingId, proposedBy, feedbacks, proposing, sessionNumber, completedAt } = session;
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const userPermissions = usePermissions();
  const isWorkspaceDisabled = useSelectedWorkspace()?.disabled ?? false;
  const project = useSelectedProject();
  const meeting = useMeetingById(meetingId);
  const selectedRoom = useSelectedRoom();
  const feedback = feedbacks?.find((fb) => {
    return fb.user === currentUser.id;
  });
  const sessionAction: SessionStates = getActionForSession(
    session,
    feedback,
    meeting,
    userPermissions,
    isWorkspaceDisabled,
    project,
    currentUser,
  );
  const canEdit =
    !completedAt &&
    (((permissions.canSchedule(userPermissions) ||
      permissions.canScheduleLimited(userPermissions)) &&
      !proposing) ||
      permissions.canPropose(userPermissions));
  const proposedByUser = selectedRoom?.participants?.find(({ id: userId }) => {
    return userId === proposedBy;
  });

  const openEditor = () => {
    dispatch(
      commonReducers.hermesActions.openScheduler({
        sessionId: id,
        proposing: !(
          permissions.canSchedule(userPermissions) ||
          permissions.canScheduleLimited(userPermissions)
        ),
      }),
    );
  };

  const isLastSession = () => {
    const sessionCount = project?.sessions?.length ?? 0;
    const schedulableSessionCount = Math.ceil(project?.sessionsSchedulable ?? 0);
    return schedulableSessionCount + sessionCount === sessionNumber;
  };

  const isBeforePenultSession = () => {
    const sessionCount = project?.sessions?.length ?? 0;
    const schedulableSessionCount = Math.ceil(project?.sessionsSchedulable ?? 0);
    return schedulableSessionCount + sessionCount - 1 === sessionNumber;
  };

  const actionRequired = ['SubmitSummaryClickableState', 'SubmitFeedbackClickableState'].includes(
    sessionAction,
  );

  return {
    openEditor,
    isLastSession,
    isBeforePenultSession,
    feedback,
    proposedByUser,
    sessionAction,
    canEdit,
    actionRequired,
  };
}
