import { getCommonStorage } from '../commonSettings';
import type { Feature } from '../types/featuretoggle';
import { dayjs } from '../utils/dayjsCustom';

export const featureDecisionLocalStorageHandler = {
  keys: ['features', 'featureExpirationDate'],
  set: (features: Feature[], validityInHours: number): void => {
    const commonStorage = getCommonStorage();
    commonStorage.set('features', features);
    commonStorage.set('featureExpirationDate', dayjs().add(validityInHours, 'hour').format());
  },
  get: async (): Promise<Feature[]> => {
    const commonStorage = getCommonStorage();

    const features = await commonStorage.get<Feature[]>('features');
    return features ? features : [];
  },
  isValid: async (): Promise<boolean> => {
    const commonStorage = getCommonStorage();
    const expirationDate = await commonStorage.get<string>('featureExpirationDate');

    return dayjs(expirationDate).isAfter(dayjs());
  },
  remove: (): void => {
    const commonStorage = getCommonStorage();

    commonStorage.remove('features');
    commonStorage.remove('featureExpirationDate');
  },
};
