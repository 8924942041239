import type { TagCategories } from '@polygence/common/types/data/marketplace';
import type { SimpleTag, Tag } from '@polygence/common/types/marketplace';
import { Button } from '@polygence/components';
import { useState } from 'react';

import { EditTag } from 'src/components/admin/components/aux/EditTag';
import { TagEditModal } from 'src/components/admin/components/aux/TagEditModal';

export const ShowTags = ({
  tags,
  label,
  color = 'info',
}: {
  tags: SimpleTag[];
  label?: string;
  color?: string;
}) => {
  return (
    <>
      <span className="text-muted small me-3 mb-3">{label}</span>
      {tags.map(({ parent, name, id }) => {
        return (
          <span key={id} className={`badge rounded-pill bg-${color} m-2`}>
            {parent && parent.name ? `${parent.name} - ${name}` : name}
          </span>
        );
      })}
    </>
  );
};

export interface DisplayTagsProps {
  tags?: Tag[];
  category: TagCategories;
  label?: string;
  color?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'light'
    | 'dark'
    | 'white';
  editable?: '' | 'popup' | 'inline';
  changeUrl: string;
  className?: string;
  minTagCount?: number;
  maxTagCount?: number;
  forceReload?: VoidFunction;
}

export const DisplayTags = ({
  tags = [],
  category,
  label,
  color = 'info',
  editable = '',
  changeUrl,
  className = 'mb-5',
  minTagCount,
  maxTagCount,
  forceReload,
}: DisplayTagsProps) => {
  const [editing, setEditing] = useState(false);

  const handleChange = () => {
    setEditing(false);
    forceReload?.();
  };

  const editProps = {
    tags,
    label,
    category,
    changeUrl,
    minTagCount,
    maxTagCount,
    onSubmit: handleChange,
  };

  return (
    <div className={className}>
      {editable === 'popup' && <TagEditModal open={editing} setOpen={setEditing} {...editProps} />}
      {editable === 'inline' && <EditTag display={editing} {...editProps} />}
      {(editable !== 'inline' || !editing) && <ShowTags tags={tags} label={label} color={color} />}
      {editable && !editing && (
        <Button size="sm" variant="secondary" onClick={() => setEditing(true)}>
          Edit
        </Button>
      )}
    </div>
  );
};
