import type { StudentJourneyLaunchpadReflection } from '@polygence/common';
import { Avatar, Badge, Icon, Text } from '@polygence/components';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { CompletedAvatarOverlay } from 'src/components/Tiles/StudentJourney/CompletedAvatarOverlay';
import { dayjs } from 'src/utils/dayjsCustom';
import { getTracker } from 'src/utils/tracking/factory';

export const ActiveLaunchpadReflectionJourneyTile = ({
  data,
}: {
  data: StudentJourneyLaunchpadReflection;
}) => {
  const history = useHistory();
  const tracker = getTracker();

  if (!data || data.status === 'inactive') {
    return null;
  }

  const { status, workspaceId, mentor, upcomingSession } = data;

  const handleGoToWorkspace = () => {
    tracker.track('Go To Workspace', {
      category: 'student_journey_tile',
      workspaceId: workspaceId,
    });
    history.push(`/dashboard/hermes/${workspaceId}`);
  };

  return (
    <div className="w-100 d-flex flex-row flex-wrap gap-3 align-items-center justify-content-evenly">
      {mentor && (
        <div className="d-flex flex-column align-items-center gap-2">
          <CompletedAvatarOverlay show={status === 'completed'}>
            <Avatar
              size={36}
              user={{
                firstName: mentor.firstName,
                lastName: mentor.lastName,
                profilePicture: mentor.profilePicture,
              }}
            />
          </CompletedAvatarOverlay>
          <Text size="small">{`${mentor.firstName} ${mentor.lastName}`}</Text>
        </div>
      )}
      {status === 'completed' && <Badge variant="success">COMPLETED</Badge>}
      {status === 'terminated' && <Badge variant="danger">TERMINATED</Badge>}
      {status === 'active' && upcomingSession && (
        <div className="d-flex flex-column align-items-center gap-1">
          {upcomingSession.scheduledAt && (
            <>
              <div className="hstack align-self-center">
                <Text size="small" alignment="center" fontWeight="normal">
                  <Icon id="calendar" size="xs" className="me-1" />
                  {dayjs(upcomingSession.scheduledAt).format('ddd, MMM D')}
                </Text>
              </div>
              <Text size="small" alignment="center" fontWeight="normal">
                {dayjs(upcomingSession.scheduledAt).format('LT')}
              </Text>
            </>
          )}
        </div>
      )}
      {workspaceId && (
        <Button
          size="sm"
          variant={status === 'active' ? 'primary' : 'secondary'}
          onClick={handleGoToWorkspace}
        >
          Workspace
        </Button>
      )}
    </div>
  );
};
