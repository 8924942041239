import {
  DEVELOPMENT,
  getEnvironment,
  PRODUCTION,
  STAGING,
  TEST,
  ONDEMAND,
} from '@polygence/common';

export function getCookie(name: string) {
  const cookie = document.cookie.split('; ').find((row) => {
    return row.startsWith(name);
  });
  return cookie ? cookie.split('=')[1] : null;
}

function getCookieDomain() {
  const byEnv = {
    [DEVELOPMENT]: 'localhost',
    [TEST]: 'localhost',
    [STAGING]: 'polygence.dev',
    [PRODUCTION]: 'polygence.org',
    [ONDEMAND]: 'polygence.co',
  };
  return byEnv[getEnvironment()] || byEnv[DEVELOPMENT];
}

export function setGlobalCookie(name: string, value: string) {
  // eslint-disable-next-line fp/no-mutation
  document.cookie = `${name}=${value};domain=${getCookieDomain()};path=/;`;
}

export function deleteGlobalCookie(name: string) {
  // eslint-disable-next-line fp/no-mutation
  document.cookie = `${name}= ;expires=${new Date(
    0,
  ).toUTCString()};domain=${getCookieDomain()};path=/;`;
}
