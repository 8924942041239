import { InputField, Text } from '@polygence/components';
import { ChangeEvent, useState } from 'react';
import { Form } from 'react-bootstrap';

interface ConcernsProps extends Record<string, boolean> {
  no_concerns: boolean;
  price: boolean;
  time_commitment: boolean;
  program_fit: boolean;
  not_the_right_time: boolean;
  alternative_program: boolean;
  other: boolean;
}

const EMPTY_CONCERN_STATE: ConcernsProps = {
  no_concerns: false,
  price: false,
  time_commitment: false,
  program_fit: false,
  not_the_right_time: false,
  alternative_program: false,
  other: false,
};

interface AdmissionAdvisorConcernsProps {
  aaConcerns: string[];
  aaConcernAltProgram: string;
  aaConcernOther: string;
  onChange: (_: Record<string, unknown>) => void;
}

export const AdmissionAdvisorConcerns = ({
  aaConcerns = [],
  aaConcernAltProgram,
  aaConcernOther,
  onChange,
}: AdmissionAdvisorConcernsProps) => {
  const [concerns, setConcerns] = useState(() => {
    const parsedObj = { ...EMPTY_CONCERN_STATE };
    for (const value of aaConcerns) {
      // eslint-disable-next-line fp/no-mutation
      parsedObj[value] = true;
    }
    return parsedObj;
  });

  const [altProgramConcern, setAltProgramConcern] = useState(aaConcernAltProgram);
  const [otherConcern, setOtherConcern] = useState(aaConcernOther);

  const submitChange = (updatedConcerns: ConcernsProps) => {
    const concernsArray = Object.keys(updatedConcerns).filter((key) => updatedConcerns[key]);
    onChange({
      target: { name: 'admission_advisor_concerns', value: concernsArray },
    });
  };

  const handleAltProgramChange = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
    setAltProgramConcern(value);
    onChange({ target: { name, value } });
  };

  const handleOtherConcernChange = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
    setOtherConcern(value);
    onChange({ target: { name, value } });
  };

  const handleCheckboxChange = ({ target: { name, checked } }: ChangeEvent<HTMLInputElement>) => {
    const defaultConcerns = { ...EMPTY_CONCERN_STATE, no_concerns: true };
    if (name === 'no_concerns' && checked) {
      setConcerns(defaultConcerns);
      submitChange(defaultConcerns);
    } else {
      setConcerns((prevState) => {
        const updatedConcerns: ConcernsProps = {
          ...prevState,
          no_concerns: false,
          [name]: checked,
        };
        submitChange(updatedConcerns);
        return updatedConcerns;
      });
    }
  };

  return (
    <div className="mb-5">
      <Text alignment="left" size="medium" fontWeight="bold" className="mb-5">
        What concerns did the student share that might prevent them from enrolling? (Select all that
        apply.)
      </Text>
      <Form.Check
        type="checkbox"
        label="No concerns"
        id="no-concerns"
        name="no_concerns"
        checked={concerns['no_concerns']}
        onChange={handleCheckboxChange}
      />
      <Form.Check
        type="checkbox"
        label="Price"
        id="_price"
        name="price"
        checked={concerns['price']}
        onChange={handleCheckboxChange}
      />
      <Form.Check
        type="checkbox"
        label="Time commitment"
        id="time-commitment"
        name="time_commitment"
        checked={concerns['time_commitment']}
        onChange={handleCheckboxChange}
      />
      <Form.Check
        type="checkbox"
        label="Program fit"
        id="program-fit"
        name="program_fit"
        checked={concerns['program_fit']}
        onChange={handleCheckboxChange}
      />
      <Form.Check
        type="checkbox"
        label="Not the right time"
        id="not-the-right-time"
        name="not_the_right_time"
        checked={concerns['not_the_right_time']}
        onChange={handleCheckboxChange}
      />
      <Form.Check
        type="checkbox"
        label="Alternative program"
        id="alternative-program"
        name="alternative_program"
        checked={concerns['alternative_program']}
        onChange={handleCheckboxChange}
      />
      {concerns['alternative_program'] && (
        <InputField
          label="Please specify"
          name="admission_advisor_concern_alternative_program"
          type="textarea"
          onChange={handleAltProgramChange}
          value={altProgramConcern}
        />
      )}
      <Form.Check
        type="checkbox"
        label="Other"
        id="other"
        name="other"
        checked={concerns['other']}
        onChange={handleCheckboxChange}
      />
      {concerns.other && (
        <InputField
          label="Please specify"
          name="admission_advisor_concern_other"
          type="textarea"
          onChange={handleOtherConcernChange}
          value={otherConcern}
        />
      )}
    </div>
  );
};
