import { createSlice } from '@reduxjs/toolkit';

const defaults = {
  modal: { isOpen: false },
};

const additionalSessionSlice = createSlice({
  name: 'additionalSession',
  initialState: {
    ...defaults,
  },
  reducers: {
    openAdditionalSessionModal: (state) => {
      state.modal.isOpen = true;
    },
    closeAdditionalSessionModal: (state) => {
      state.modal.isOpen = false;
    },
  },
});

const { actions, reducer } = additionalSessionSlice;

export const additionalSessionActions = actions;

export const additionalSessionReducer = reducer;
