import {
  DEVELOPMENT,
  configureCommon,
  Environment,
  getBaseDomainFallback,
} from '@polygence/common';

import { store } from 'src/store';
import { localStorageManager } from 'src/utils/localStorageManager';

const environment = (import.meta.env.VITE_APP_ENVIRONMENT as Environment) || DEVELOPMENT;

configureCommon({
  environment,
  internalStore: store,
  storageManager: localStorageManager,
  baseDomain: import.meta.env.VITE_BASE_DOMAIN || getBaseDomainFallback(environment),
});
