import { Button } from '@polygence/components';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

/* eslint-disable-next-line react/function-component-definition, import/no-default-export -- autodisabled, autodisabled */
export default function NumberSelector({
  startFrom = 1,
  numberCount = 10,
  defaultValue,
  onChange,
  className,
  name,
  ...props
}) {
  const [selected, setSelected] = useState(defaultValue);
  const numbers = [
    ...Array.from({ length: numberCount }, (_, i) => {
      return i + startFrom;
    }),
  ];

  const selectValue = (value) => {
    setSelected(value);
    if (onChange) {
      onChange(value);
    }
  };

  const classes = `number-selector ${className || ''}`;

  return (
    // eslint-disable-next-line react/jsx-filename-extension -- autodisabled
    <div className={classes} {...props}>
      {numbers.map((number) => {
        return (
          <Button
            key={number}
            size="sm"
            variant={selected === number ? 'primary' : 'secondary'}
            onClick={() => {
              selectValue(number);
            }}
          >
            {number}
          </Button>
        );
      })}
      {name && <input type="hidden" name={name} value={selected} />}
    </div>
  );
}

NumberSelector.propTypes = {
  startFrom: PropTypes.number,
  numberCount: PropTypes.number,
  defaultValue: PropTypes.number,
  onChange: PropTypes.func,
  className: PropTypes.string,
  name: PropTypes.string,
};
