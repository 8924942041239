/* eslint-disable fp/no-mutation */
import { dayjs } from 'src/utils/dayjsCustom';

export const times = [
  '00:00:00',
  '00:30:00',
  '01:00:00',
  '01:30:00',
  '02:00:00',
  '02:30:00',
  '03:00:00',
  '03:30:00',
  '04:00:00',
  '04:30:00',
  '05:00:00',
  '05:30:00',
  '06:00:00',
  '06:30:00',
  '07:00:00',
  '07:30:00',
  '08:00:00',
  '08:30:00',
  '09:00:00',
  '09:30:00',
  '10:00:00',
  '10:30:00',
  '11:00:00',
  '11:30:00',
  '12:00:00',
  '12:30:00',
  '13:00:00',
  '13:30:00',
  '14:00:00',
  '14:30:00',
  '15:00:00',
  '15:30:00',
  '16:00:00',
  '16:30:00',
  '17:00:00',
  '17:30:00',
  '18:00:00',
  '18:30:00',
  '19:00:00',
  '19:30:00',
  '20:00:00',
  '20:30:00',
  '21:00:00',
  '21:30:00',
  '22:00:00',
  '22:30:00',
  '23:00:00',
  '23:30:00',
  '00:00:00',
] as const;

export const timeOptions = times.map((timeslot) => {
  const [hour, minute] = timeslot.split(':');
  const label = dayjs()
    .hour(parseInt(hour as string))
    .minute(parseInt(minute as string))
    .format('LT');
  return { value: timeslot, label };
});

export const modifyEndTime = (newStartTime: string, endTime: string) => {
  let modifiedEndTime = endTime;

  const startAtIndex = times.findIndex((time) => time === newStartTime);
  let endAtIndex = times.findIndex((time) => time === endTime);
  endAtIndex = endAtIndex === 0 ? times.length : endAtIndex;

  if (startAtIndex >= endAtIndex) {
    const halfHourLater = times[startAtIndex + 1];

    if (startAtIndex >= 0 && startAtIndex < times.length - 2 && halfHourLater) {
      modifiedEndTime = halfHourLater;
    } else if (newStartTime === '00:00:00') {
      modifiedEndTime = '01:00:00';
    } else {
      modifiedEndTime = '00:00:00';
    }
  }

  return modifiedEndTime;
};

export const modifyStartTime = (startTime: string, newEndTime: string) => {
  let mofidiedStartTime = startTime;

  const startAtIndex = times.findIndex((time) => time === startTime);
  let endAtIndex = times.findIndex((time) => time === newEndTime);
  endAtIndex = endAtIndex === 0 ? times.length : endAtIndex;

  if (endAtIndex <= startAtIndex) {
    const halfHourBefore = times[endAtIndex - 1];

    if (endAtIndex > 1 && halfHourBefore) {
      mofidiedStartTime = halfHourBefore;
    } else if (newEndTime === '00:00:00') {
      mofidiedStartTime = '23:00:00';
    } else {
      mofidiedStartTime = '00:00:00';
    }
  }
  return mofidiedStartTime;
};
