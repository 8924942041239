import * as featuretoggleApi from '../api/featuretoggles';
import type { Feature } from '../types/featuretoggle';

import { featureDecisionLocalStorageHandler } from './featureLocalStorageManager';

interface ToggleRouter {
  setFeature: (featureName: string, isEnabled: boolean) => void;
  isEnabled: (featureName: string) => boolean;
}

/* eslint-disable no-param-reassign, fp/no-mutation */
function createToggleRouter(featureConfig: Record<string, boolean>): ToggleRouter {
  return {
    setFeature(featureName: string, isEnabled: boolean) {
      featureConfig[featureName] = isEnabled;
    },
    isEnabled(featureName: string): boolean {
      return featureConfig[featureName] ?? false;
    },
  };
}

function createFeatureDecisions(features: ToggleRouter) {
  return {
    searchInMessengerRooms() {
      return features.isEnabled('messenger-room-search');
    },
    opportunityDashboardEnabled() {
      return features.isEnabled('opportunity-dashboard');
    },
    hermesEnabled() {
      return features.isEnabled('hermes-enabled');
    },
    schedulingInitialGreetingEnabled() {
      return features.isEnabled('schedule_in_initial_greeting_room');
    },
    scholarPageEnabled() {
      return features.isEnabled('scholar-page');
    },
    mentorBonusPayment() {
      return features.isEnabled('mentor-bonus-payment');
    },
    devFeaturesEnabled() {
      return features.isEnabled('dev-features');
    },
    studentApplicationV2() {
      return features.isEnabled('student-profile-v2');
    },
    manualPaymentAdjustmentEnabled() {
      return features.isEnabled('manual-payment-adjustment');
    },
    writingFeedbackEnabled() {
      return features.isEnabled('writing-feedback-enabled');
    },
    externalCalendarSync() {
      return features.isEnabled('external-calendar-sync');
    },
    selfProposable() {
      return features.isEnabled('self-propose');
    },
    pathfindersEnabled() {
      return features.isEnabled('pathfinders-enabled');
    },
    applicationJourneyEnabled() {
      return features.isEnabled('application-journey-enabled');
    },
    applicationRevampEnabled() {
      return features.isEnabled('application-revamp-enabled');
    },
    polyGptEnabled() {
      return features.isEnabled('polygpt-enabled');
    },
    wordleEnabled() {
      return features.isEnabled('wordle-enabled');
    },
    todoEnabled() {
      return features.isEnabled('todo-enabled');
    },
    projectPlanEnabled() {
      return features.isEnabled('project-plan-enabled');
    },
    hermesAIMessageThread() {
      return features.isEnabled('hermes-ai-message-thread');
    },
    aiSessionSummaryEnabled() {
      return features.isEnabled('ai-session-summary-enabled');
    },
  };
}

async function fetchFeatureTogglesFromAPI() {
  return await featuretoggleApi.fetchFeatures();
}

async function getFeatures(): Promise<Feature[]> {
  const cachedFeatures = await featureDecisionLocalStorageHandler.get();
  const isCacheValid = await featureDecisionLocalStorageHandler.isValid();
  if (cachedFeatures.length && isCacheValid) {
    return cachedFeatures;
  } else {
    const features = await fetchFeatureTogglesFromAPI();
    featureDecisionLocalStorageHandler.set(features, 1);
    return features;
  }
}

export type FeatureDecisions =
  | 'searchInMessengerRooms'
  | 'opportunityDashboardEnabled'
  | 'hermesEnabled'
  | 'schedulingInitialGreetingEnabled'
  | 'scholarPageEnabled'
  | 'mentorBonusPayment'
  | 'devFeaturesEnabled'
  | 'wordleEnabled'
  | 'studentApplicationV2'
  | 'manualPaymentAdjustmentEnabled'
  | 'writingFeedbackEnabled'
  | 'externalCalendarSync'
  | 'selfProposable'
  | 'pathfindersEnabled'
  | 'applicationJourneyEnabled'
  | 'applicationRevampEnabled'
  | 'polyGptEnabled'
  | 'todoEnabled'
  | 'projectPlanEnabled'
  | 'hermesAIMessageThread'
  | 'aiSessionSummaryEnabled';

type FeatureDecisionsInstance = Record<FeatureDecisions, () => boolean>;

async function createInstance(): Promise<FeatureDecisionsInstance> {
  const features = createToggleRouter({});
  const featureToggles = await getFeatures();
  featureToggles.forEach((feature: Feature) => {
    features.setFeature(feature.name, true);
  });
  return createFeatureDecisions(features);
}

let instance: FeatureDecisionsInstance | null = null;

export const featureDecisions = {
  async getInstance() {
    await getFeatures();
    if (instance) {
      return instance;
    }
    instance = await createInstance();
    return instance;
  },
  clearInstance() {
    featureDecisionLocalStorageHandler.remove();
    instance = null;
  },
};
