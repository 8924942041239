import { Button } from '@polygence/components';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { FormInputWithValidation as FormInput } from 'src/components/FormInputWithValidation';
import { Icon } from 'src/components/Icon';
import defaultCover from 'src/components/static/images/default-cover.png';
import EditableImage from 'src/components/student/ScholarPage/EditableImage';
import { checkForMissingError } from 'src/utils/formHelpFunctions';

import 'src/components/mentor/projectItem.sass';

const ProjectItem = ({
  inputField: {
    pk,
    cover_image: coverImage,
    title,
    description,
    dont_feature_project: dontFeatureProject,
  },
  index,
  missingFields,
  handleChange,
  removeFields,
}) => {
  const [editing, setEditing] = useState(false);
  const [cover, setCover] = useState(coverImage);

  return (
    <div className="project-item-wrapper">
      <div className="project-cover-wrapper mb-5">
        <EditableImage
          editing={editing}
          src={cover || defaultCover}
          name="cover_image"
          uploadUrl={`/api/homeprofile/projects/${pk}/`}
          removeUrl={`/api/homeprofile/${pk}/image`}
          alt={`${title} cover`}
          className="project-image mx-auto rounded"
          reload={(res) => {
            return setCover(res?.cover_image);
          }}
          width="100%"
          label="Edit image"
        />
        {!editing && (
          <Button
            variant="secondary"
            size="sm"
            onClick={() => {
              setEditing(true);
            }}
          >
            <Icon name="pencil" local color size="16px" /> {cover ? 'Edit' : 'Upload'} image
          </Button>
        )}
        {editing && (
          <div className="editing-actions">
            <Button
              variant="primary"
              size="sm"
              onClick={() => {
                setEditing(false);
              }}
            >
              Save
            </Button>
          </div>
        )}
      </div>
      <div className="text-wrapper">
        <FormInput
          name="title"
          placeholder="Title..."
          label="Project title"
          value={title}
          onChange={(event) => {
            return handleChange(index, event);
          }}
          onError={[checkForMissingError('projects_v2', missingFields, title)]}
          required
        />
        <div>
          <FormInput
            inputType="textarea"
            rows={3}
            name="description"
            placeholder="Short Description..."
            label="Project description"
            value={description}
            onChange={(event) => {
              return handleChange(index, event);
            }}
            onError={[checkForMissingError('projects_v2', missingFields, description)]}
            required
          />
        </div>
        <div>
          <div className="small">
            We occasionally feature mentor projects on our website and elsewhere. If you don&apos;t
            want us to feature your project idea publicly, please check below.
          </div>
          <div className="form-group form-check ms-5">
            <span>
              <input
                className="form-check-input"
                type="checkbox"
                id={`dont_feature_project_${index}`}
                name="dont_feature_project"
                onChange={(event) => {
                  return handleChange(index, event);
                }}
                checked={Boolean(dontFeatureProject)}
              />
            </span>
            <label className="form-check-label small" htmlFor={`dont_feature_project_${index}`}>
              {' '}
              <span className="py-2">Do not feature my project publicly.</span>
            </label>
          </div>
        </div>
        <Button
          variant="danger"
          onClick={() => {
            removeFields(index);
          }}
        >
          Delete project #{index + 1}
        </Button>
      </div>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default ProjectItem;

ProjectItem.propTypes = {
  inputField: PropTypes.shape({
    cover_image: PropTypes.string,
    description: PropTypes.string,
    dont_feature_project: PropTypes.bool,
    other_comments: PropTypes.string,
    pk: PropTypes.number.isRequired,
    prerequisites: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
  missingFields: PropTypes.arrayOf(PropTypes.string),
  handleChange: PropTypes.func.isRequired,
  removeFields: PropTypes.func.isRequired,
};
