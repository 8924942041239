import { createApi } from '@reduxjs/toolkit/query/react';

import type { MentorAvailabilityId, MentorProfileId } from '../types/common';
import type {
  MentorAvailabilityList,
  MentorAvailabilityCreate,
  MentorAvailabilityRetrieveUpdateDestroy,
} from '../types/mentor-availability';

import { axiosBaseQuery } from './fetch';

export const mentorAvailabilityApi = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: 'mentorAvailabilityApi',
  tagTypes: ['MentorAvailabilities', 'MentorAvailability'],
  endpoints: (build) => {
    return {
      getMentorAvailabilities: build.query<
        MentorAvailabilityList[],
        { mentorProfileId: MentorProfileId }
      >({
        query: ({ mentorProfileId }) => {
          return { url: `/mentor/availability/${mentorProfileId}/availabilities/`, method: 'GET' };
        },
        providesTags: (_, __, { mentorProfileId }) => {
          return [{ type: 'MentorAvailabilities', id: mentorProfileId }];
        },
      }),
      getMentorAvailability: build.query<
        MentorAvailabilityRetrieveUpdateDestroy,
        { mentorAvailabilityId: MentorAvailabilityId }
      >({
        query: ({ mentorAvailabilityId }) => {
          return { url: `/mentor/availability/${mentorAvailabilityId}/`, method: 'GET' };
        },
        providesTags: (_, __, { mentorAvailabilityId }) => {
          return [{ type: 'MentorAvailability', id: mentorAvailabilityId }];
        },
      }),
      createMentorAvailability: build.mutation<
        MentorAvailabilityCreate,
        { mentorProfileId: MentorProfileId; payload: Partial<MentorAvailabilityCreate> }
      >({
        query: ({ payload }) => {
          return { url: '/mentor/availability/', method: 'POST', data: payload };
        },
        invalidatesTags: (_, __, { mentorProfileId }) => {
          return [{ type: 'MentorAvailabilities', id: mentorProfileId }];
        },
      }),
      deleteMentorAvailability: build.mutation<
        MentorAvailabilityRetrieveUpdateDestroy,
        {
          mentorProfileId: MentorProfileId;
          mentorAvailabilityId: MentorAvailabilityId;
        }
      >({
        query: ({ mentorAvailabilityId }) => {
          return { url: `/mentor/availability/${mentorAvailabilityId}/`, method: 'DELETE' };
        },
        invalidatesTags: (_, __, { mentorProfileId, mentorAvailabilityId }) => {
          return [
            { type: 'MentorAvailabilities', id: mentorProfileId },
            { type: 'MentorAvailability', id: mentorAvailabilityId },
          ];
        },
      }),
      updateMentorAvailability: build.mutation<
        MentorAvailabilityRetrieveUpdateDestroy,
        {
          mentorProfileId: MentorProfileId;
          mentorAvailabilityId: MentorAvailabilityId;
          payload: Partial<MentorAvailabilityRetrieveUpdateDestroy>;
        }
      >({
        query: ({ mentorAvailabilityId, payload }) => {
          return {
            url: `/mentor/availability/${mentorAvailabilityId}/`,
            method: 'PATCH',
            data: payload,
          };
        },
        onQueryStarted: (
          { mentorAvailabilityId, mentorProfileId, ...patch },
          { dispatch, queryFulfilled },
        ) => {
          const patchResult = dispatch(
            mentorAvailabilityApi.util.updateQueryData(
              'getMentorAvailabilities',
              { mentorProfileId },
              (draft) => {
                const draftMentorAvailability = draft.find(
                  (draftMentorAvailability) => draftMentorAvailability.id === mentorAvailabilityId,
                );
                if (draftMentorAvailability) {
                  Object.assign(draftMentorAvailability, patch.payload);
                }
              },
            ),
          );
          queryFulfilled.catch(patchResult.undo);
        },

        // eslint-disable-next-line sonarjs/no-identical-functions
        invalidatesTags: (_, __, { mentorProfileId, mentorAvailabilityId }) => {
          return [
            { type: 'MentorAvailabilities', id: mentorProfileId },
            { type: 'MentorAvailability', id: mentorAvailabilityId },
          ];
        },
      }),
    };
  },
});

export const {
  useGetMentorAvailabilitiesQuery,
  useGetMentorAvailabilityQuery,
  useCreateMentorAvailabilityMutation,
  useDeleteMentorAvailabilityMutation,
  useUpdateMentorAvailabilityMutation,
} = mentorAvailabilityApi;
