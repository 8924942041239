import { externalLink } from '@polygence/common/utils/externalLink';
import { Button, Heading, Illustration, Text } from '@polygence/components';
import type { IllustrationName } from '@polygence/components';
import classNames from 'classnames';
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import styles from 'src/components/Tiles/tilesDashboard.module.scss';

import 'swiper/swiper-bundle.css';

interface CollegeAdmissionsResultsSwiperProps {
  percentage: string;
  text: string;
  highlightedText: string;
  illustrationName: IllustrationName;
}

const tiles: CollegeAdmissionsResultsSwiperProps[] = [
  {
    percentage: '89%',
    text: 'of Polygence Seniors are accepted to',
    highlightedText: 'R1 Universities',
    illustrationName: 'AcademicCapGlobal',
  },
  {
    percentage: '58%',
    text: 'of Polygence alums are accepted to a',
    highlightedText: 'Top 25 University',
    illustrationName: 'CelebratePolygenceLogo',
  },
  {
    percentage: '30%',
    text: 'of Polygence alums are accepted to',
    highlightedText: 'Ivy League Schools',
    illustrationName: 'Institution',
  },
  {
    percentage: '27%',
    text: 'of Polygence alums are accepted to',
    highlightedText: 'Top 10 Liberal Arts Colleges',
    illustrationName: 'PathToSuccess',
  },
];

export const CollegeAdmissionsResultsTile = () => {
  SwiperCore.use([Autoplay, Pagination]);

  return (
    <div className={classNames(styles['tile'], 'd-flex flex-column p-8 gap-3')}>
      <Heading size="h4">College Admissions Results</Heading>
      <Swiper
        centeredSlides
        autoplay={{
          delay: 7000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        pagination={{
          clickable: true,
          dynamicBullets: true,
        }}
        modules={[Autoplay, Pagination]}
        className="h-100 w-100"
        loop
      >
        {tiles.map(({ percentage, text, highlightedText, illustrationName }) => (
          <SwiperSlide key={percentage}>
            <SwiperContent
              percentage={percentage}
              text={text}
              highlightedText={highlightedText}
              illustrationName={illustrationName}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <Button
        variant="primary"
        href="https://dpl6hyzg28thp.cloudfront.net/media/Polygence_College_Admissions_Report_2024.pdf"
        {...externalLink}
      >
        Download Full Report
      </Button>
    </div>
  );
};

const SwiperContent = ({
  percentage,
  text,
  highlightedText,
  illustrationName,
}: CollegeAdmissionsResultsSwiperProps) => {
  return (
    <div className="d-flex flex-column gap-1">
      <Text size="large" fontWeight="normal">
        <Text size="large" as="span" fontWeight="bold" style={{ fontSize: 42 }}>
          {percentage}
        </Text>{' '}
        {text}{' '}
        <Text size="large" as="span" fontWeight="bold">
          {highlightedText}
        </Text>
      </Text>
      <div className="d-flex justify-content-end pb-6">
        <Illustration name={illustrationName} size={160} />
      </div>
    </div>
  );
};
