export const ADDITIONAL_SESSION = 'Additional Session';
export const ADDITIONAL_WRITING_FEEDBACK = 'Additional Writing Feedback';
export const ADVANCED_SCHOLAR_PROGRAM = 'Advanced Scholar Program';
export const ELEMENTS = 'Elements';
export const FLAGSHIP_NAME = 'Flagship project';
export const INTERNSHIP_PLACEMENT_CONSULTING = 'Internship Placement Consulting';
export const MASTERY_LEARNING_RECORD = 'Mastery Learning Record';
export const PATHFINDER_LAUNCHPAD = 'Pathfinder Launchpad';
export const PATHFINDER_STANDALONE = 'Pathfinder Standalone';
export const PODS = 'Pods';
export const PODS_695 = 'Pods for $695';
export const POLYPILOT = 'Poly Pilot';
export const PREMIUM_SHOWCASING_COMPETING = 'Premium Showcasing - Competing';
export const PREMIUM_SHOWCASING_MULTIMEDIA = 'Premium Showcasing - Multimedia';
export const PREMIUM_SHOWCASING_PRESENTING = 'Premium Showcasing - Presenting';
export const PREMIUM_SHOWCASING_PUBLISHING = 'Premium Showcasing';
export const PUBLIC_SPEAKING = 'Public Speaking & Interview Coaching';
export const UCI_GATI = 'UCI x GATI';
