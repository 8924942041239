import * as siteContentApi from '@polygence/common/api/site-content';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { PageLoad } from 'src/components/PageLoad';
import { ReactMarkdown } from 'src/components/aux/markdown/ReactMarkdown';
import { HeadingRenderer, LinkRenderer } from 'src/components/aux/markdown/markdownHelperFunctions';

const LegacyMarkdownGuidebook = ({
  switch_off_modal: switchOffModal,
  guidebook_tag: guidebookTag,
  full_width: fullWidth,
  selectable,
}) => {
  const [state, setState] = useState({
    bookLoaded: false,
    guidebook: {},
  });
  const { guidebook, bookLoaded } = state;

  useEffect(() => {
    siteContentApi
      .getLegacyMarkdownGuidebooks(guidebookTag)
      .then((res) => {
        const { data } = res;
        if (data) {
          setState((prevState) => {
            return {
              ...prevState,
              guidebook: data === undefined || data.length === 0 ? {} : data[0],
              bookLoaded: true,
            };
          });
        }
      })
      .catch(console.error);

    if (typeof switchOffModal === 'function') {
      switchOffModal();
    }
  }, [guidebookTag, switchOffModal]);

  if (!bookLoaded) {
    // eslint-disable-next-line react/jsx-filename-extension -- autodisabled
    return <PageLoad />;
  }

  if (Object.keys(guidebook).length === 0 && guidebook.constructor === Object) {
    return <p>The resource does not exist or you do not have permission to view the resource.</p>;
  }

  return (
    <div
      className={classnames({
        'col-md-6 offset-md-3': !fullWidth,
        'read-only-div': !selectable,
      })}
    >
      <h1 className="my-5 text-center">{guidebook.title}</h1>
      <div>
        <ReactMarkdown components={{ heading: HeadingRenderer, link: LinkRenderer }}>
          {bookLoaded ? guidebook.body : null}
        </ReactMarkdown>
      </div>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default LegacyMarkdownGuidebook;

LegacyMarkdownGuidebook.propTypes = {
  switch_off_modal: PropTypes.func,
  guidebook_tag: PropTypes.string,
  full_width: PropTypes.string,
  selectable: PropTypes.bool,
};
