import * as marketplaceApi from '@polygence/common/api/marketplace';
import PropTypes from 'prop-types';
import React from 'react';

import { customFetch } from 'src/components/customFetch';
import OptOutFeedback from 'src/components/specialURLs/OptOutFeedback';
import { getBaseApiUrl } from 'src/utils';

class ContinuedInterest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      responseRecorded: false,
      errorOccured: false,
      detailsProvided: false,
      feedbackId: null,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const searchParams = new URLSearchParams(location.search);
    const email = searchParams.get('email');
    const interested = searchParams.get('interested');

    marketplaceApi
      .updateContinuedInterest(email, interested)
      .then(({ data }) => {
        if (data) {
          this.setState({
            responseRecorded: data.recorded,
            feedbackId: data.id,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          errorOccured: true,
        });
      });
  }

  onDetailedFeedbackSubmit = ({ items, other, similarProgram }) => {
    const { location } = this.props;
    const { feedbackId } = this.state;
    const searchParams = new URLSearchParams(location.search);
    const email = searchParams.get('email');

    const payload = {
      checked_items: items,
      email,
      other,
      similar_program: similarProgram,
    };

    customFetch(`${getBaseApiUrl()}/survey/opt-out-feedback/${feedbackId}/`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then(() => {
        this.setState({ detailsProvided: true });
      })
      // eslint-disable-next-line sonarjs/no-identical-functions -- autodisabled
      .catch((error) => {
        console.error(error);
        this.setState({
          errorOccured: true,
        });
      });
  };

  render() {
    const { location } = this.props;
    const { detailsProvided, errorOccured, responseRecorded } = this.state;
    const searchParams = new URLSearchParams(location.search);
    const interested = searchParams.get('interested');

    let responseHtml;

    if (errorOccured) {
      // eslint-disable-next-line fp/no-mutation -- autodisabled
      responseHtml = (
        /* eslint-disable-next-line react/jsx-filename-extension, react/forbid-dom-props -- autodisabled, autodisabled */
        <div style={{ color: 'red' }}>
          <p>Sorry, an error occurred and your response could not be recorded.</p>
          <p>
            Please contact <a href="mailto:admissions@polygence.org">admissions@polygence.org</a>.
          </p>
        </div>
      );
    }
    if (interested === 'yes') {
      // eslint-disable-next-line fp/no-mutation -- autodisabled
      responseHtml = (
        <div>
          <div>
            <h4 className="mt-5 text-center">Thank you for the update!</h4>
            <hr />
            <div className="mt-3" />
            <div>
              <p>Great to hear you are still interested in Polygence mentorship!</p>
              <p>We will be in touch as soon as we have a mentor match for you!</p>
            </div>
          </div>
          <div>
            {responseRecorded ? (
              // eslint-disable-next-line react/forbid-dom-props -- autodisabled
              <div style={{ color: 'green' }}>Your response has been recorded.</div>
            ) : null}
          </div>
        </div>
      );
    } else if (interested === 'no' && !detailsProvided) {
      // eslint-disable-next-line fp/no-mutation -- autodisabled
      responseHtml = (
        <div>
          <p>We are sorry to hear that you are no longer interested in Polygence.</p>
          <p>
            Please select all that apply and we will close your application until we hear from you
            again.
          </p>
          <OptOutFeedback onSubmit={this.onDetailedFeedbackSubmit} />
        </div>
      );
    } else if (interested === 'no' && detailsProvided) {
      // eslint-disable-next-line fp/no-mutation -- autodisabled
      responseHtml = (
        <div>
          <h4 className="mt-5 text-center">Thank you for the update!</h4>
          <hr />
          <div className="mt-3" />
          <p>We are sorry to hear that you are no longer interested in Polygence mentorship.</p>
          <p>Please feel free to reach out to us if you become interested again in the future!</p>
          <div>
            {responseRecorded ? (
              // eslint-disable-next-line react/forbid-dom-props -- autodisabled
              <div style={{ color: 'green' }}>Your response has been recorded.</div>
            ) : null}
          </div>
        </div>
      );
    } else {
      // eslint-disable-next-line fp/no-mutation -- autodisabled
      responseHtml = (
        <div>
          <p>We are sorry, but an error occured and your response could not be recorded.</p>
          <p>
            Please contact <a href="mailto:admissions@polygence.org">admissions@polygence.org</a>.
          </p>
        </div>
      );
    }

    return (
      <div className="col-8 offset-2 col-md-6 offset-md-3 mb-7 text-center text-muted">
        <div>{responseHtml}</div>
      </div>
    );
  }
}

ContinuedInterest.propTypes = {
  location: PropTypes.shape(PropTypes.any),
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default ContinuedInterest;
