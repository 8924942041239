import { useHistory } from 'react-router';

const useRefreshPage = (navigateTo = '') => {
  const history = useHistory();
  return () => {
    if (navigateTo) {
      history.push(navigateTo);
    }
    history.go(0);
  };
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default useRefreshPage;
