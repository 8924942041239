import type { OptionType } from '@polygence/components';
import { isEqual } from 'lodash';

import {
  FEATURED_US_AS_NOT_FEATURED_OPTION,
  FEATURED_US_AS_NOT_FEATURED_VALUE,
  FEATURED_US_AS_OPTIONS,
  POST_COLLEGE_ADMISSION,
  POST_COLLEGE_APPLICATION,
  POST_COLLEGE_SELECTION,
} from 'src/components/CollegeAdmissionSurvey/constants';

export const getSurveyDescription = (stage: string, name = 'you') => {
  switch (stage) {
    case POST_COLLEGE_SELECTION:
      return `Please take a few minutes to answer a few questions about college applications, including where ${name} applied, been admitted to, and will ultimately enroll. This information is crucial to understanding how Polygence factors in the admissions process and how we can improve the program for future Polygencers.`;
    case POST_COLLEGE_ADMISSION:
      return `Please take a few minutes to answer a few questions about college applications, including where ${name} applied to and where ${name} been admitted to. This information is crucial to understanding how Polygence factors in the admissions process and how we can improve the program for future Polygencers.`;
    case POST_COLLEGE_APPLICATION:
    default:
      return `Please take a few minutes to answer a few questions about college applications, including the schools where ${name} applied. This information is crucial to understanding how Polygence factors in the admissions process and how we can improve the program for future Polygencers.`;
  }
};

export const getSurveyCollegesQuestion = (stage: string, name = 'you') => {
  switch (stage) {
    case POST_COLLEGE_ADMISSION:
      return `Where did ${name} apply to college? Please select all schools and indicate if ${name} applied there Early Decision or Early Action. If ${name} were admitted to a school, please update the status to 'Accepted'`;
    case POST_COLLEGE_SELECTION:
      return `Where did ${name} apply to college? Please select all schools and indicate if ${name} applied there Early Decision or Early Action. If ${name} were admitted to a school, please update the status to 'Accepted' If ${name} know where you are attending, please update the status to 'Attending'`;
    case POST_COLLEGE_APPLICATION:
    default:
      return `Where did ${name} apply to college? Please select all schools and indicate if ${name} applied there Early Decision or Early Action.`;
  }
};

export const getCalculatedFeaturedUsAsOptions = (options: OptionType[]): OptionType[] => {
  const notFeaturedOptionIndex = options.findIndex(
    (option) => option.value === FEATURED_US_AS_NOT_FEATURED_VALUE,
  );

  const notFeaturedOptionSelectedNow =
    notFeaturedOptionIndex !== -1 && notFeaturedOptionIndex === options.length - 1;
  const notFeaturedOptionSelectedAlready = notFeaturedOptionIndex !== -1;

  if (notFeaturedOptionSelectedNow) {
    return [FEATURED_US_AS_NOT_FEATURED_OPTION];
  } else if (notFeaturedOptionSelectedAlready) {
    return options.filter((option) => {
      return !isEqual(option, FEATURED_US_AS_NOT_FEATURED_OPTION);
    });
  }
  return options;
};

export const getCurrentFeaturedUsAsOption = (featuredUsAs: string[]) => {
  return featuredUsAs.map(
    (currentOption) =>
      FEATURED_US_AS_OPTIONS.find((option) => option.value === currentOption) ?? {
        label: '',
        value: '',
      },
  );
};
