/* eslint-disable react/forbid-dom-props */

interface ProgressBarProps {
  height?: string;
  backgroundColor?: string;
  indicatorGradient?: string;
  indicatorColor?: string;
  completed?: number;
  margin?: string;
}

export const ProgressBar = ({
  height = '20px',
  backgroundColor = 'var(--h-grey-2)',
  indicatorColor = 'var(--h-blue-mid)',
  indicatorGradient = '',
  completed = 0,
  margin = '10px 0px',
}: ProgressBarProps) => {
  const containerStyles = {
    height,
    width: '100%',
    backgroundColor,
    borderRadius: 50,
    margin,
  };

  const fillerStyles = {
    height: '100%',
    width: `${completed}%`,
    backgroundColor: indicatorColor || 'var(--h-blue-mid)',
    backgroundImage: indicatorGradient,
    borderRadius: 'inherit',
  };

  const labelStyles = {
    padding: 5,
    color: 'white',
    fontWeight: 'bold',
  };

  return (
    <div style={containerStyles}>
      <div style={fillerStyles} className="text-right">
        <span style={labelStyles} />
      </div>
    </div>
  );
};
