import { Heading, Text } from '@polygence/components';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import styles from 'src/components/Tiles/Mentors/MentorTiles.module.scss';
import tileIllustrations from 'src/components/Tiles/tile-illustrations';
import tileStyles from 'src/components/Tiles/tilesDashboard.module.scss';

export const OnboardingHub = ({ experience = false }: { experience: boolean }) => {
  return (
    <Link to="/n/mentor-guidebook/52ff5fea-4595-4e42-adfe-4984880e4ff0">
      <div
        className={classNames(
          tileStyles['tile'],
          styles['onboardingHubTile'],
          'd-flex flex-column',
        )}
      >
        <Heading size="h4" alignment="left" className="mb-6">
          Onboarding Hub
        </Heading>
        {!experience && (
          <Text size="medium" className={styles['description']}>
            Get started here with Polygence tips and tricks
          </Text>
        )}
        {experience && (
          <Text size="medium" className={styles['description']}>
            Quick reminders of our tools, policies, and newly launched features
          </Text>
        )}
        <img
          className={classNames(styles['illustration'])}
          src={tileIllustrations['onboarding-hub']}
          alt="Onboarding Hub"
        />
      </div>
    </Link>
  );
};
