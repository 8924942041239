import { commonHooks, commonReducers, featureDecisions } from '@polygence/common';
import * as externalCalendarSyncApi from '@polygence/common/api/external-calendar-sync';
import { Button, Heading, Spacer } from '@polygence/components';
import { useState, useEffect, forwardRef } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { navigateTo } from 'src/utils/navigateTo';

const navigateToGoogleAuthorization = async () => {
  const { data } = await externalCalendarSyncApi.getGoogleAuthorizationUrl();
  const { authorizationUrl } = data;
  navigateTo(authorizationUrl);
};

const handleError = () => toast.error('Something went wrong.');

export const useExternalCalendarSyncEnabled = () => {
  const [enabled, setEnabled] = useState(false);
  useEffect(() => {
    void featureDecisions.getInstance().then((features) => {
      setEnabled(features.externalCalendarSync());
    });
  });
  return enabled;
};

export const ExternalCalendars = forwardRef<HTMLDivElement>((_props, calendarRef) => {
  const { calendarConnected } = commonHooks.useCurrentUser();
  const dispatch = useDispatch();
  const authorizeGoogleCalendar = () => {
    navigateToGoogleAuthorization().catch(handleError);
  };

  const revokeGoogleCalendarAuthorization = () => {
    externalCalendarSyncApi
      .revokeGoogleCalendarAuthorization()
      .then(() => {
        toast.success('Successfully disconnected your calendar.');
        dispatch(commonReducers.userActions.updateUser({ calendarConnected: false }));
      })
      .catch(handleError);
  };

  return (
    <div ref={calendarRef}>
      <Heading size="p" alignment="left">
        Connect your calendars
      </Heading>
      <Spacer size={4} />
      {calendarConnected ? (
        <Button onClick={revokeGoogleCalendarAuthorization}>Disconnect Google Calendar</Button>
      ) : (
        <Button onClick={authorizeGoogleCalendar}>Connect Google Calendar</Button>
      )}
    </div>
  );
});
