import { AdmissionAdvisorModal } from '@polygence/common';

export const addInviteeInformationToCalendlyUrl = (
  calendlyUrl: string,
  inviteeInformation: Partial<{ name: string; email: string }>,
) => {
  const url = new URL(calendlyUrl);
  const entries = Object.entries(inviteeInformation);
  for (const [key, value] of entries) {
    url.searchParams.append(key, value);
  }
  return url.toString();
};

export const getAdmissionAdvisorCalendlyUrl = (
  advisor: AdmissionAdvisorModal,
  studentEmail: string,
  studentFirstName: string,
  studentLastName: string,
  isPartnerPaysWorkflow: boolean,
) => {
  const baseUrl =
    isPartnerPaysWorkflow && advisor.calendlyUrlPartnerPays
      ? advisor.calendlyUrlPartnerPays
      : advisor.calendlyUrl;
  return addInviteeInformationToCalendlyUrl(baseUrl, {
    name: `${studentFirstName} ${studentLastName}`,
    email: studentEmail,
  });
};
