import * as marketplaceApi from '@polygence/common/api/marketplace';
import { SelectOption } from '@polygence/common/types/common';
import { Select, cn } from '@polygence/components';
import { useState, useEffect } from 'react';

import InputWrapper from 'src/components/mentor/InputWrapper';

interface CountrySelectProps {
  name: string;
  value: number;
  label?: string;
  placeholder?: string;
  required?: boolean;
  errors?: string[];
  onChange: (event: { target: { name: string; value: number } }) => void;
}

export const CountrySelect = ({
  name,
  value,
  label,
  placeholder,
  required,
  errors = [],
  onChange,
}: CountrySelectProps) => {
  const [options, setOptions] = useState<SelectOption<number>[]>([]);
  const showError = !!errors?.[0];

  useEffect(() => {
    void marketplaceApi.getCountries().then((countries) => {
      setOptions(countries.data.map(({ id, name }) => ({ label: name, value: id })));
    });
  }, []);

  return (
    <InputWrapper required={required} value={value}>
      <div className="tw-mb-2">
        {label && (
          <label
            htmlFor={name}
            className={cn('tw-mb-[-5px] tw-cursor-pointer', required && 'required')}
          >
            {label}
          </label>
        )}
        <Select
          id={name}
          name={name}
          value={options.find((option) => option.value === value)}
          placeholder={placeholder}
          className={cn({
            'is-invalid': showError,
            'react-select-required': showError && required,
          })}
          options={options}
          onChange={(newValue) => {
            if (newValue) {
              onChange({ target: { name, value: newValue.value as number } });
            }
          }}
        />
        {showError && errors.length === 1 && <div className="text-danger small">{errors}</div>}
      </div>
    </InputWrapper>
  );
};
