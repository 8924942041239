import type { UserInfo } from '@polygence/common';
import jwtDecode from 'jwt-decode';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { PageLoad } from 'src/components/PageLoad';
import { setAccessToken, getAccessToken } from 'src/utils/auth';
import { getQueryParams } from 'src/utils/request';

interface QueryParam {
  access?: string;
  redirectUrl?: string;
}
export const MobileRedirect = () => {
  const [setupComplete, setSetupComplete] = useState(false);
  const queryParams = getQueryParams(window.location.search) as QueryParam;
  const access = queryParams.access;
  const redirectUrl = queryParams.redirectUrl;
  const dispatch = useDispatch();

  useEffect(() => {
    const originalAccess = getAccessToken();
    if (access) {
      if (originalAccess) {
        const originalUserData: UserInfo = jwtDecode(originalAccess);
        const newUserData: UserInfo = jwtDecode(access);
        if (newUserData.email === originalUserData.email) {
          setSetupComplete(true);
          return;
        }
      }
      setAccessToken(access);
      window.location.reload();
    }
    setSetupComplete(true);
  }, [access, dispatch]);

  if (!access) {
    return <Redirect to="/error-page" />;
  }

  if (!setupComplete) {
    return <PageLoad />;
  }

  return <Redirect to={redirectUrl ?? '/'} />;
};
