import { Spacer, Text } from '@polygence/components';

import { LookingForMentorAvatar } from 'src/components/Tiles/StudentJourney/LookingForMentorAvatar';

export const UnderMatchingTile = () => {
  return (
    <div className="d-flex flex-column align-items-center">
      <LookingForMentorAvatar />
      <Spacer size={3} />
      <Text fontWeight="bold" size="small">
        Get excited!
      </Text>
      <Text fontWeight="light" size="small" alignment="center" textWrap="balance">
        We're currently working to match you with the mentor that's right for you and your project.
      </Text>
    </div>
  );
};
