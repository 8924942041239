import type { DayOfWeek } from '@polygence/common/types/common';
import type { TimeSlot } from '@polygence/common/types/hermes';
import { Button, Heading, Text } from '@polygence/components';
import { Fragment } from 'react';

import { MentorWorkingDay } from 'src/components/usersettings/MentorWorkingHours/MentorWorkingDay';
import styles from 'src/components/usersettings/MentorWorkingHours/MentorWorkingHours.module.scss';
import { daysOfWeek } from 'src/hooks/useMentorWorkingHours';

interface MentorWorkingHoursProps {
  isDayEnabled: (day: DayOfWeek) => boolean;
  toggleTimeSlotsFor: (day: DayOfWeek) => void;
  getTimeSlotsFor: (day: DayOfWeek) => TimeSlot[];
  loading: boolean;
  changeStartAt: (newTime: string, slotId: number) => void;
  changeEndAt: (newTime: string, slotId: number) => void;
  createNextTimeSlot: (currentTimeSlot: TimeSlot) => void;
  deleteTimeSlot: (id: number) => void;
}

export const MentorWorkingHours = ({
  isDayEnabled,
  toggleTimeSlotsFor,
  getTimeSlotsFor,
  loading,
  changeStartAt,
  changeEndAt,
  createNextTimeSlot,
  deleteTimeSlot,
}: MentorWorkingHoursProps) => {
  return (
    <div className="my-5">
      <Heading id="working-hours" alignment="left" size="p">
        Working Hours
      </Heading>
      <Text size="medium" className="text-muted">
        Enable any day to let students know when you're available for sessions.
      </Text>
      <div className={styles['workingHours']}>
        {daysOfWeek.map((day) => {
          const isEnabled = isDayEnabled(day);

          return (
            <Button
              className="round-button"
              key={day}
              variant={isEnabled ? 'primary' : 'secondary'}
              onClick={() => toggleTimeSlotsFor(day)}
              title={day}
            >
              <Text size="medium" fontWeight="bold">
                {day.charAt(0).toUpperCase()}
              </Text>
            </Button>
          );
        })}
        {loading && (
          <Text size="small" style={{ color: 'var(--grayscale-6)' }}>
            Saving...
          </Text>
        )}
      </div>
      <div className="d-flex flex-column">
        {daysOfWeek.map((day) => {
          const slots = getTimeSlotsFor(day);

          return (
            <Fragment key={day}>
              {isDayEnabled(day) && slots.length > 0 && (
                <MentorWorkingDay
                  day={day}
                  slots={slots}
                  isDisabled={loading}
                  changeStartAt={changeStartAt}
                  changeEndAt={changeEndAt}
                  createNextTimeSlot={createNextTimeSlot}
                  deleteTimeSlot={deleteTimeSlot}
                />
              )}
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};
