/* eslint-disable sonarjs/no-identical-functions */
import type { GPTProjectIdea, UUID } from '@polygence/common';
import { axiosBaseQuery } from '@polygence/common/api/fetch';
import { createApi } from '@reduxjs/toolkit/query/react';

export const projectIdeaGeneratorApi = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: 'projectIdeaGenerator',
  tagTypes: ['ProjectDetails'],
  endpoints: (build) => {
    return {
      startProjectIdeaGeneration: build.mutation<
        { uuid: UUID },
        { generalInterest: string; interests: string }
      >({
        query: ({ generalInterest, interests }) => ({
          url: '/api/project-idea-generator/',
          method: 'POST',
          data: {
            general_interest: generalInterest,
            interests,
          },
        }),
      }),
      getProjectIdea: build.query<GPTProjectIdea, { projectIdeaUuid: UUID }>({
        query: ({ projectIdeaUuid }) => {
          return {
            url: `/landing/project-idea/${projectIdeaUuid}`,
            method: 'GET',
          };
        },
      }),
      likeProjectIdea: build.mutation<
        void,
        { projectIdeaUuid: UUID; likedProjectIdeaIndex: number }
      >({
        query: ({ projectIdeaUuid, likedProjectIdeaIndex }) => {
          return {
            url: `/landing/project-idea/${projectIdeaUuid}`,
            method: 'PATCH',
            data: {
              likedProjectIdeaIndex: likedProjectIdeaIndex,
            },
          };
        },
      }),
    };
  },
});

export const {
  useStartProjectIdeaGenerationMutation,
  useLazyGetProjectIdeaQuery,
  useLikeProjectIdeaMutation,
} = projectIdeaGeneratorApi;
