import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';

import { isVideoAskMessage } from 'src/components/common/VideoAsk/isVideoAskMessage';

const VIDEOASK_SUBMITTED = 'videoask_submitted';

const VideoAsk = ({ onSubmit, src, title }) => {
  const handleMessage = useCallback(
    (message) => {
      if (!isVideoAskMessage(message)) {
        return;
      }

      const { data: { type } = {} } = message;

      if (type === VIDEOASK_SUBMITTED) {
        onSubmit();
      }
    },
    [onSubmit],
  );

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  });

  return (
    // eslint-disable-next-line react/jsx-filename-extension -- autodisabled
    <iframe
      title={title}
      src={src}
      allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
      width="100%"
      height="600px"
      // eslint-disable-next-line react/forbid-dom-props -- autodisabled
      style={{ border: 'none', borderRadius: 'var(--base-border-radius)' }}
    />
  );
};

VideoAsk.propTypes = {
  onSubmit: PropTypes.func,
  src: PropTypes.string,
  title: PropTypes.string,
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default VideoAsk;
