import { externalLink } from '@polygence/common';
import { Button, Heading, Icon, Text, Tooltip } from '@polygence/components';
import classNames from 'classnames';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import styles from 'src/components/Tiles/Counselor/ProgramResourcesTile.module.scss';
import tileStyles from 'src/components/Tiles/tilesDashboard.module.scss';

const RESOURCES = [
  {
    name: 'Compare Polygence Programs',
    link: 'https://dpl6hyzg28thp.cloudfront.net/media/Polygence_Programs_Comparison_Page_2024_July.pdf',
  },
  {
    name: 'Polygence Programs Pitch Deck for Families',
    link: 'https://dpl6hyzg28thp.cloudfront.net/media/Polygence_Family_Pitch_Deck_2024.pdf',
  },
  {
    name: 'Polygence Educators Brochure 2024',
    link: 'https://dpl6hyzg28thp.cloudfront.net/media/Polygence_Educator_Brochure_2024_July.pdf',
  },
];

const TEXT_TO_COPY =
  "Top 3 reasons to do Polygence’s Core Program:\n1. Boost your odds with a project that shows your initiative & self-discipline. Polygence grads see up to 5x higher college acceptance rates.\n2. Be unique - showcase your originality & passion through your research. Choose your subject, topic, & outcome to make a lasting impression\n3. Connect with your ideal mentor from Polygence's network of 3,000+ experts from top universities in more than 150+ different academic fields";

export const ProgramResourcesTile = () => {
  return (
    <div className={classNames(tileStyles['tile'], 'd-flex flex-column gap-6 p-10')}>
      <Heading as="h4" size="h4" alignment="left" fontWeight="semibold">
        Learn about Our Programs
      </Heading>
      <Text size="medium" fontWeight="normal" className={classNames(styles['description'])}>
        Here you can find some downloadable resources on what Polygence can offer to your students:
      </Text>
      {RESOURCES.map(({ name, link }) => (
        <Button
          key={name}
          className={styles['link']}
          href={link}
          size="sm"
          endIcon={
            <Icon id="download-cloud" size="sm" className="ms-auto" color="var(--secondary)" />
          }
          {...externalLink}
        >
          {name}
        </Button>
      ))}
      <div className="d-flex flex-row gap-3 mt-8 align-items-center">
        <Text size="medium" fontWeight="normal">
          Top 3 reasons to do Polygence’s Core Program:
        </Text>
        <Tooltip placement="auto" tip="Copied to clipboard!" trigger={['click', 'focus']}>
          <CopyToClipboard text={TEXT_TO_COPY}>
            <Button
              variant="link"
              startIcon={<Icon id="copy" color="var(--secondary)" />}
              size="sm"
              className={styles['clickToCopy']}
            />
          </CopyToClipboard>
        </Tooltip>
      </div>
      <ol>
        <Text as="li" size="medium" fontWeight="light" className="mb-6">
          <strong>Boost your odds</strong> with a project that shows your initiative &
          self-discipline. Polygence grads see up to 5x higher college acceptance rates.
        </Text>
        <Text as="li" size="medium" fontWeight="light" className="mb-6">
          <strong>Be unique</strong> - showcase your originality & passion through your research.
          Choose your subject, topic, & outcome to make a lasting impression
        </Text>
        <Text as="li" size="medium" fontWeight="light">
          <strong>Connect with your ideal mentor</strong> from Polygence's network of 3,000+ experts
          from top universities in more than 150+ different academic fields
        </Text>
      </ol>
    </div>
  );
};
