import { ApplicationSectionIds } from 'src/constants/application-sections';
import {
  contentSpacer,
  headingProps,
  headingSpacer,
  illustrationSize,
} from 'src/students/student-application/common-section-components';
import { ApplicationComponentSection } from 'src/students/student-application/student-application-components';

export const financialAidSection: ApplicationComponentSection = {
  id: ApplicationSectionIds.FINANCIAL_AID_SECTION_ID,
  display: {
    $and: [
      {
        $is: {
          field: 'partnerPays',
          value: false,
        },
      },
      {
        $is: {
          field: 'showScholarship',
          value: true,
        },
      },
    ],
  },
  elements: [
    {
      component: 'ThreeColumnLayout',
      props: {
        children: [
          [{ component: 'AdmissionAdvisorModalInfo' }],
          [
            {
              component: 'StudentReferralBanner',
            },
            {
              component: 'CenteredIllustration',
              props: {
                name: 'SpaceSaturn',
                size: illustrationSize,
              },
            },
            headingSpacer,
            {
              component: 'ComponentsHeading',
              props: {
                children: 'Would you like to apply for financial aid?',
                ...headingProps,
              },
            },
            contentSpacer,
            {
              component: 'ComponentsText',
              props: {
                children:
                  'Application for financial aid is highly selective and may result in delays compared to standard admissions timelines.',
                className: 'mb-8',
                size: 'medium',
              },
            },
            {
              component: 'ComponentsRadioButton',
              tag: 'application',
              props: {
                name: 'scholarshipApplicant',
                label:
                  'I would like to continue with the standard application process without applying for financial aid.',
                value: false,
              },
              validation: {
                required: true,
              },
            },
            {
              component: 'ComponentsRadioButton',
              tag: 'application',
              props: {
                name: 'scholarshipApplicant',
                label:
                  'I can confirm that I meet the criteria (family income below $90K USD) and would like to apply for financial aid.',
                value: true,
              },
            },
            {
              component: 'Box',
              display: {
                $is: {
                  field: 'scholarshipApplicant',
                  value: true,
                },
              },
              props: {
                helpColumn: false,
                children: [
                  {
                    component: 'ComponentsText',
                    props: {
                      children:
                        'Use this section to elaborate on your request for financial aid. Are there any special circumstances we should be made aware of? Please give relevant information in complete sentences, at least 150 words.',
                    },
                    display: {
                      $is: {
                        field: 'scholarshipApplicant',
                        value: true,
                      },
                    },
                  },
                  {
                    component: 'Spacer',
                    props: {
                      size: 9,
                    },
                  },
                  {
                    component: 'InputField',
                    tag: 'application',
                    props: {
                      type: 'textarea',
                      placeholder: 'Type here...',
                      name: 'financialAidElaboration',
                      showWordCount: true,
                      rows: 10,
                    },
                    validation: {
                      required: true,
                      wordCount: {
                        minimum: 150,
                        maximum: 200,
                      },
                    },
                  },
                  {
                    component: 'Spacer',
                    props: {
                      size: 9,
                    },
                  },
                  {
                    component: 'ComponentsText',
                    props: {
                      children:
                        "Please provide your parent's tax document below. (e.g., 1040, W-2s) This will be considered as part of your application for financial aid.",
                    },
                  },
                  {
                    component: 'Spacer',
                    props: {
                      size: 9,
                    },
                  },
                  {
                    component: 'FileUploadInput',
                    tag: 'profile',
                    props: {
                      name: 'financialAidDocuments',
                      options: {
                        uploadAction: 'Upload your parents’ tax document',
                      },
                      onUpload: 'uploadFinancialAidDocuments',
                      onDelete: 'deleteAllFinancialAidDocuments',
                    },
                    validation: {
                      required: true,
                    },
                  },
                  {
                    component: 'Spacer',
                    props: {
                      size: 9,
                    },
                  },
                ],
              },
            },
          ],
          [
            {
              component: 'Spacer',
              props: { size: { base: 2, md: 8, lg: 10, xl: 13 } },
            },
            {
              component: 'Note',
              props: {
                title: 'Please note...',
                text: 'Polygence has limited financial aid available for students with a combined annual household income of $90,000 USD or less.\n\nThe maximum financial aid a student may currently receive will be a tuition discount of $1600. Tuition for Polygence starts at $2795 USD and can be paid in monthly installments or as a single payment.',
              },
            },
          ],
        ],
      },
    },
  ],
};
