import { InputField } from '@polygence/components';

export const ReadOnlyTextInput = ({
  label,
  name,
  value,
}: {
  label: string;
  name: string;
  value: string;
}) => {
  return (
    <InputField type="text" disabled label={label} floating={false} name={name} value={value} />
  );
};
