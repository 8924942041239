import type {
  Nullable,
  PathfinderStudentApplication,
  StudentApplication,
  StudentProfileWithApplication,
} from '@polygence/common';
import type { PersistentStorageManager } from '@polygence/common/commonSettings';
import type { StudentProfileId, StudentApplicationId } from '@polygence/common/types/common';

import { urls } from 'src/urls';

const pathfinderStudentApplicationKey = 'pathfinder_student_application_cache';
const studentApplicationKey = 'student_application_cache';
const googleLoginRedirectPathKey = 'google-login-redirect-path';
const adminNavOpenCategoriesKey = 'admin-nav-open-categories';

interface ApplicationLocalStorageData<T> {
  studentProfile: Partial<StudentProfileWithApplication>;
  application: Partial<T>;
}

export const getApplicationLocalStorageHandler = <T>(key: string) => ({
  set: (formData: ApplicationLocalStorageData<T>, applicationId: string): void => {
    // eslint-disable-next-line eqeqeq
    if (applicationId == 'undefined') {
      return;
    }

    window.localStorage.setItem(`${key}_${applicationId}`, JSON.stringify(formData));
  },
  get: (applicationId: string) => {
    return JSON.parse(
      window.localStorage.getItem(`${key}_${applicationId}`) ?? '{}',
    ) as ApplicationLocalStorageData<T>;
  },
  remove: (applicationId: string): void => {
    window.localStorage.removeItem(`${key}_${applicationId}`);
  },
});

export const studentApplicationLocalStorageHandler =
  getApplicationLocalStorageHandler<StudentApplication>(studentApplicationKey);

export const pathfinderStudentApplicationLocalStorageHandler =
  getApplicationLocalStorageHandler<PathfinderStudentApplication>(pathfinderStudentApplicationKey);

export const adminNavOpenCategoriesLocalStorageHandler = {
  toggle: (category: string): void => {
    const openTabs = JSON.parse(
      window.localStorage.getItem(adminNavOpenCategoriesKey) ?? '{}',
    ) as Record<string, unknown>;
    // eslint-disable-next-line fp/no-mutation
    openTabs[category] = !openTabs[category];
    window.localStorage.setItem(adminNavOpenCategoriesKey, JSON.stringify(openTabs));
  },
  get: (): Record<string, unknown> => {
    return JSON.parse(window.localStorage.getItem(adminNavOpenCategoriesKey) ?? '{}') as Record<
      string,
      unknown
    >;
  },
};

export const googleLoginLocalStorageHandler = {
  set: (path: string): void => {
    window.localStorage.setItem(googleLoginRedirectPathKey, path);
  },
  get: (): Nullable<string> => {
    return window.localStorage.getItem(googleLoginRedirectPathKey);
  },
  remove: (): void => {
    window.localStorage.removeItem(googleLoginRedirectPathKey);
  },
};

export const UnverifiedUserLocalStorageHandler = {
  unverifiedApplicationSubmit: (
    studentProfileId: StudentProfileId,
    applicationId: StudentApplicationId,
  ): void => {
    window.localStorage.setItem(
      'verificationRedirectUrl',
      urls.studentApplicationById(studentProfileId, applicationId),
    );
  },
  verificationRedirectUrl: () => {
    return window.localStorage.getItem('verificationRedirectUrl');
  },
  verify: (): void => {
    window.localStorage.removeItem('verificationRedirectUrl');
  },
};

export const localStorageManager: PersistentStorageManager = {
  get: async <T>(key: string) => {
    return new Promise<T | null>((resolve) => {
      const value = localStorage.getItem(key);
      if (value === null) {
        return resolve(null);
      }

      const result = JSON.parse(value) as T;

      return resolve(result);
    });
  },
  set: <T>(key: string, value: T) => {
    if (!value) {
      localStorage.removeItem(key);
      return;
    }

    localStorage.setItem(key, JSON.stringify(value));
  },
  remove: (key: string) => {
    localStorage.removeItem(key);
  },
};
