import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

import { FormInputWithValidation as FormInput } from 'src/components/FormInputWithValidation';
import { emojis, loadEmoji } from 'src/components/admin/components/aux/emojis';
import { countWords } from 'src/utils';
import 'src/components/textAreaWithWordCount.sass';

const TextareaWithWordCount = ({
  name,
  placeholder = null,
  label = null,
  smallFont = null,
  value,
  onChange,
  disabled = false,
  onError = [],
  required = false,
  htmlRequired = false,
  wordCountRequired = false,
  recommendedWordCount = 0,
  requiredMinWordCount = 0,
  requiredMaxWordCount = 0,
  noRequiredStyle,
  onBlur,
  rows,
}) => {
  const wordCount = countWords(value);
  const enough = wordCount >= recommendedWordCount;
  const requiredWordCount = wordCount >= requiredMinWordCount && wordCount <= requiredMaxWordCount;
  const inputRef = useRef();

  useEffect(() => {
    if (!inputRef.current) {
      return;
    }
    if (wordCountRequired && wordCount > 0 && !enough) {
      inputRef.current.setCustomValidity(
        `You haven't reached the minimum word count. (${recommendedWordCount} words)`,
      );
    } else {
      inputRef.current.setCustomValidity('');
    }
  }, [enough, wordCount, wordCountRequired, recommendedWordCount]);

  return (
    // eslint-disable-next-line react/jsx-filename-extension -- autodisabled
    <>
      <FormInput
        ref={inputRef}
        inputType="textarea"
        type="textarea"
        name={name}
        placeholder={placeholder}
        label={label}
        small_font={smallFont}
        value={value}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        onError={onError}
        required={required}
        htmlRequired={htmlRequired}
        containerClassName="mb-0"
        noRequiredStyle={noRequiredStyle}
        rows={rows}
      />

      <p className="text-end text-muted small text-green word-count-wrapper">
        {wordCountRequired &&
          (!requiredWordCount ? (
            <>
              <span role="img" aria-label="red cross mark" className="emoji">
                {loadEmoji(emojis.redCrossMark)}
              </span>
              <span className="error-message">
                {requiredMinWordCount} - {requiredMaxWordCount} words are required
              </span>
            </>
          ) : (
            <>
              <span role="img" aria-label="check mark" className="emoji">
                {loadEmoji(emojis.checkMark)}
              </span>
              <span className="success-message">You have enough words</span>
            </>
          ))}
        <span className="word-count">
          {wordCount} {requiredMaxWordCount > 0 && `/${requiredMaxWordCount} `}
          words
        </span>
      </p>
    </>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default TextareaWithWordCount;

TextareaWithWordCount.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  smallFont: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  onError: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  required: PropTypes.bool,
  htmlRequired: PropTypes.bool,
  wordCountRequired: PropTypes.bool,
  recommendedWordCount: PropTypes.number,
  requiredMinWordCount: PropTypes.number,
  requiredMaxWordCount: PropTypes.number,
  noRequiredStyle: PropTypes.bool,
  onBlur: PropTypes.func,
  rows: PropTypes.number,
};
