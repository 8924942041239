import type { CamelCasedPropertiesDeep } from 'type-fest';

import { getRestClient } from '../commonSettings';
import type {
  DeclineReasons,
  MentorMatch,
  MentorProfileStaff,
  Profile,
  ProspectiveMentorUserAdmin,
  StudentDetailsAdmin,
} from '../types/analytics';
import type {
  CounselorProfileId,
  Date,
  FeedbackRequestId,
  MentorProfileId,
  MentorRequestId,
  NoteId,
  ProspectiveUserId,
  SilentRoomUserReportId,
  StudentProfileId,
  UserId,
  UUID,
  WorkspaceId,
} from '../types/common';
import type { ProspectiveUserStatusType } from '../types/data/backend';
import type { GoogleCalendarEvent } from '../types/google-api';
import type { ProjectFeeTypes } from '../types/hermes';
import type { Note, NoteType, ProposedMentor, StudentDetails } from '../types/marketplace';
import { NoteTypes } from '../types/marketplace';
import type { StringUserType } from '../types/user';
import type { Nullable } from '../types/utils';

export const getMentorRequest = (mentorRequestId: MentorRequestId) => {
  const internalFetch = getRestClient();
  return internalFetch.get<StudentDetails>(`/analytics/mentor_requests/${mentorRequestId}/`);
};

export const updateMentorRequest = (
  mentorRequestId: MentorRequestId,
  payload: Partial<StudentDetails>,
) => {
  const internalFetch = getRestClient();
  return internalFetch.patch<StudentDetails>(
    `/analytics/mentor_requests/${mentorRequestId}/`,
    payload,
  );
};

export const getProposedMentors = (mentorRequestId: MentorRequestId) => {
  const internalFetch = getRestClient();

  return internalFetch.get<ProposedMentor[]>(
    `/analytics/mentor_requests/${String(mentorRequestId)}/proposed_mentors/`,
  );
};

export const updateProposedMentor = (
  mentorRequestId: MentorRequestId,
  mentorProfileId: MentorProfileId,
) => {
  const internalFetch = getRestClient();

  return internalFetch.post<ProposedMentor>(
    `/analytics/mentor_requests/${String(mentorRequestId)}/proposed_mentors/${mentorProfileId}/`,
  );
};

export const getInterviewRecordings = (mentorRequestId: MentorRequestId) => {
  const internalFetch = getRestClient();

  return internalFetch.get<
    CamelCasedPropertiesDeep<{
      student_interviews: GoogleCalendarEvent[];
      default_interview_recording_id: Nullable<UUID>;
    }>
  >(`/analytics/mentor_requests/${String(mentorRequestId)}/recordings/`);
};

export const getFeedbackNotes = (feedbackRequestId: FeedbackRequestId) => {
  const internalFetch = getRestClient();

  return internalFetch.get<Note[]>(`/analytics/feedback_notes/${feedbackRequestId}/`);
};

export const updateNote = (noteId: NoteId, payload: Partial<Note>) => {
  const internalFetch = getRestClient();

  return internalFetch.patch<Note>(`/analytics/notes/${noteId}/`, payload);
};

export const getMentorNotes = (mentorProfileId: MentorProfileId, type = '') => {
  const internalFetch = getRestClient();

  return internalFetch.get<Note[]>(`/analytics/mentors/${mentorProfileId}/notes/`, {
    params: { type },
  });
};

export const createCounselorNote = (counselorProfileId: CounselorProfileId, note: string) => {
  const internalFetch = getRestClient();
  const type = NoteTypes.COUNSELOR_NOTE;

  return internalFetch.post<Note>(`/analytics/counselors/${counselorProfileId}/notes/`, {
    note,
    type,
  });
};

export const getCounselorNotes = (counselorProfileId: CounselorProfileId) => {
  const internalFetch = getRestClient();
  const type = NoteTypes.COUNSELOR_NOTE;

  return internalFetch.get<Note[]>(`/analytics/counselors/${counselorProfileId}/notes/`, {
    params: { type },
  });
};

export const createStudentNote = (
  studentProfileId: StudentProfileId,
  note: string,
  type: NoteType,
) => {
  const internalFetch = getRestClient();

  return internalFetch.post<Note>(`/analytics/students/${studentProfileId}/notes/`, {
    note,
    type,
  });
};

export const getPartnerCompanies = (partnerPaysWorkflow = false) => {
  const internalFetch = getRestClient();

  return internalFetch.get<CamelCasedPropertiesDeep<{ list_of_partners: string[] }>>(
    '/analytics/list_of_partners/',
    {
      params: { partner_pays_workflow: partnerPaysWorkflow },
    },
  );
};

export const getUsherers = () => {
  const internalFetch = getRestClient();

  return internalFetch.get<{ label: string; value: UserId }[]>('/analytics/usherers/');
};

export const getFeeTypes = () => {
  const internalFetch = getRestClient();

  return internalFetch.get<ProjectFeeTypes[]>('/analytics/project_fee_types/');
};

export const markReviewMentor = (mentorProfileId: MentorProfileId) => {
  const internalFetch = getRestClient();

  return internalFetch.patch<{ success: true }>(
    `/analytics/mentors/${mentorProfileId}/mark_reviewed/`,
  );
};

export const getStudentFromMentorRequest = (mentorRequestId: MentorRequestId) => {
  const internalFetch = getRestClient();

  return internalFetch.get<{ student: Profile; blurb: string }>(
    `/analytics/mentor_requests/${mentorRequestId}/student/`,
  );
};

export const getDeclineReasons = () => {
  const internalFetch = getRestClient();

  return internalFetch.get<DeclineReasons>('/analytics/mentor_requests/decline_reasons/');
};

export const declineMentorRequest = (
  mentorRequestId: MentorRequestId,
  payload: { note: string; reason: string },
) => {
  const internalFetch = getRestClient();

  return internalFetch.post<{ success: boolean }>(
    `/analytics/mentor_requests/${mentorRequestId}/decline/`,
    payload,
  );
};

export const submitMentorMatchForm = (mentorRequestId: MentorRequestId, payload: MentorMatch) => {
  const internalFetch = getRestClient();

  return internalFetch.put(`/analytics/mentor_requests/${mentorRequestId}/match/`, payload);
};

export const rematchStudent = (mentorRequestId: MentorRequestId) => {
  const internalFetch = getRestClient();

  return internalFetch.post<CamelCasedPropertiesDeep<{ new_mentor_request_id: MentorRequestId }>>(
    `/analytics/mentor_requests/${mentorRequestId}/rematch/`,
  );
};

export const toggleWaitlistExclusion = (mentorRequestId: MentorRequestId) => {
  const internalFetch = getRestClient();

  return internalFetch.post<CamelCasedPropertiesDeep<{ excluded: boolean }>>(
    `/analytics/mentor_requests/${String(mentorRequestId)}/toggle-waitlist/`,
  );
};

export const proposedMentorAction = (
  mentorRequestId: MentorRequestId,
  mentorId: MentorProfileId,
  action: 'send_student_proposal',
) => {
  const internalFetch = getRestClient();

  return internalFetch.post<{ success: true }>(
    `/analytics/mentor_requests/${mentorRequestId}/proposed_mentors/${mentorId}/${action}/`,
  );
};

export const removeProposedMentor = (
  mentorRequestId: MentorRequestId,
  mentorProfileId: MentorProfileId,
) => {
  const internalFetch = getRestClient();

  return internalFetch.delete<Record<string, never>>(
    `/analytics/mentor_requests/${String(mentorRequestId)}/proposed_mentors/${mentorProfileId}/`,
  );
};

export const updateInterviewRecordings = (
  mentorRequestId: MentorRequestId,
  payload: CamelCasedPropertiesDeep<{ recording_id: UUID }>,
) => {
  const internalFetch = getRestClient();

  return internalFetch.post<{ success: true }>(
    `/analytics/mentor_requests/${String(mentorRequestId)}/recordings/`,
    payload,
  );
};

export const updateChannel = (userId: UserId, payload: { channel: string }) => {
  const internalFetch = getRestClient();

  return internalFetch.patch<{ success: true }>(
    `/analytics/users/${userId}/change_channel/`,
    payload,
  );
};

export const approveMentor = (mentorId: MentorProfileId) => {
  const internalFetch = getRestClient();

  return internalFetch.patch<{ success: true; message?: string }>(
    `/analytics/mentors/${mentorId}/approve/`,
  );
};

export const getStudentSignUps = (aggregation: 'daily' | 'weekly' | 'monthly', days: number) => {
  const internalFetch = getRestClient();

  return internalFetch.get<
    CamelCasedPropertiesDeep<{
      student_signups: unknown;
      profile_completions: unknown;
      interview_list: unknown;
      mentor_request_list: unknown;
      intros: unknown;
      matches_list: unknown;
      payments: unknown;
      scholarship_list: unknown;
    }>
  >('/analytics/student-signups/', {
    params: { aggregation, days },
  });
};

export const getMentorSignUps = (aggregation: 'daily' | 'weekly' | 'monthly', days: number) => {
  const internalFetch = getRestClient();

  return internalFetch.get<
    CamelCasedPropertiesDeep<{
      mentor_signups: unknown;
      mentor_screen_apps: unknown;
      mentor_profile_approvals: unknown;
    }>
  >('/analytics/mentor-signups/', { params: { aggregation, days } });
};

export const getEmailBounces = () => {
  const internalFetch = getRestClient();

  return internalFetch.get<
    CamelCasedPropertiesDeep<{
      own_email_bounces: {
        user: string;
        user_email: string;
        pk: UserId;
        user_type: StringUserType;
        reasons: unknown;
      }[];
      parent_email_bounces: {
        user: string;
        pk: UserId;
        reason: unknown;
        parent_email: string;
      }[];
    }>
  >('/analytics/email_bounces/');
};

export const updateProspectiveMentor = (
  id: ProspectiveUserId,
  action: 'activate' | 'update' | 'no-show',
) => {
  const internalFetch = getRestClient();

  return internalFetch.post<{ success: true; message: string }>(
    `/analytics/prospective_mentors/${id}/${action}/`,
  );
};

export const dismissSilentRoomUserReport = (reportId: SilentRoomUserReportId) => {
  const internalFetch = getRestClient();

  return internalFetch.patch<{ success: true }>(
    `/analytics/silent_room_user_reports/${reportId}/dismiss/`,
  );
};

export const getStudentOverview = (studentProfileId: StudentProfileId) => {
  const internalFetch = getRestClient();
  return internalFetch.get<StudentDetailsAdmin>(`/analytics/students/${studentProfileId}/`);
};

export const getMentor = (mentorProfileId: MentorProfileId) => {
  const internalFetch = getRestClient();
  return internalFetch.get<MentorProfileStaff>(`/analytics/mentors/${mentorProfileId}/`);
};

export const updateMentor = (mentorId: MentorProfileId, payload: Partial<MentorProfileStaff>) => {
  const internalFetch = getRestClient();
  return internalFetch.patch<MentorProfileStaff>(`/analytics/mentors/${mentorId}/`, payload);
};

export const getExtensionsAndCompletions = () => {
  const internalFetch = getRestClient();
  return internalFetch.get<
    CamelCasedPropertiesDeep<{
      paid_extensions: unknown[];
      scholarship_extensions: unknown[];
      completions: unknown[];
    }>
  >('/analytics/extensions_and_completions/');
};

export const fetchContactFromHubspot = (email: string) => {
  const internalFetch = getRestClient();
  return internalFetch.get<ProspectiveMentorUserAdmin>(
    `/analytics/prospective_mentors/import/${email}/`,
  );
};

export const partialUpdateProspectiveMentor = (
  id: ProspectiveUserId,
  payload: Partial<ProspectiveMentorUserAdmin>,
) => {
  const internalFetch = getRestClient();
  return internalFetch.patch<ProspectiveMentorUserAdmin>(
    `/analytics/prospective_mentors/${id}/`,
    payload,
  );
};

export const listProspectiveMentorsByDay = (status: ProspectiveUserStatusType, day: Date) => {
  const internalFetch = getRestClient();
  return internalFetch.get<{ mentors: ProspectiveMentorUserAdmin[] }>(
    '/analytics/prospective_mentors/by_day/',
    { params: { status, day } },
  );
};

export const getContactOwners = () => {
  const internalFetch = getRestClient();
  return internalFetch.get<unknown>('/analytics/contact_owners/');
};

export const sendTestEmail = (payload: {
  type: 'simple' | 'transactional';
  internalId: string;
  recipient: string;
  [key: string]: string;
}) => {
  const internalFetch = getRestClient();
  return internalFetch.post<{ success: boolean }>('/analytics/send_test_email/', payload);
};

export const rematchPremiumShowcasing = (workspaceId: WorkspaceId) => {
  const internalFetch = getRestClient();
  return internalFetch.post(`/analytics/premium-showcasing-support/${workspaceId}/rematch/`);
};
