import { useCommonSelector } from '../../store/store';
import type { Project } from '../../types/hermes';

// eslint-disable-next-line import/no-default-export -- autodisabled
export default function useSelectedProject(): Project | undefined {
  return useCommonSelector((state) => {
    const workspaceId = state.hermes.selectedWorkspaceId;
    if (workspaceId == null) {
      return undefined;
    }
    const workspace = state.hermes.workspaces.byId[workspaceId];
    if (workspace == null) {
      return undefined;
    }
    const projectId = workspace.projectId;
    if (projectId == null) {
      return undefined;
    }
    return state.hermes.projects.byId[projectId];
  });
}
