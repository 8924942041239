const words: Record<string, Record<string, string>> = {
  have: {
    one: 'has',
    more: 'have',
  },
  be: {
    zero: 'are',
    one: 'is',
    more: 'are',
  },
};

/* eslint-disable fp/no-mutation */
export const pluralize = (word: string, count = 2): string => {
  if (!Object.prototype.hasOwnProperty.call(words, word)) {
    return count === 1 ? word : `${word}s`;
  }
  let key = '';

  if (count === 0) {
    key = 'zero';
  } else if (count <= 1) {
    key = 'one';
  } else {
    key = 'more';
  }

  return words[word]?.[key] ?? '';
};
/* eslint-enable fp/no-mutation */
