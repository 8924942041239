import type { ProjectOutcomeType } from '@polygence/common';
import * as marketplaceApi from '@polygence/common/api/marketplace';
import {
  Alert,
  Button,
  Heading,
  Icon,
  InputField,
  ModalFooter,
  OptionType,
  Select,
  Spacer,
  Text,
} from '@polygence/components';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { ProjectOutcome, validate } from 'src/components/student/ScholarPage/ProjectLink';
import style from 'src/students/Pods/PodsFinalSurvey.module.scss';
import podProjectOutcome from 'src/students/Pods/assets/pod-project-outcome.svg';
import type { ProjectLinkModalValues } from 'src/types/marketplace';

export const PodProjectOutcome = ({
  portfolioItemId,
  handleSubmit,
  outcomeType,
}: {
  portfolioItemId: number;
  handleSubmit: () => void;
  outcomeType: ProjectOutcomeType;
}) => {
  const [showValidation, setShowValidation] = useState<boolean>(false);
  const [validationError, setValidationError] = useState<string>('');
  const [options, setOptions] = useState<OptionType[] | []>([]);
  const [modalValues, setModalValues] = useState<Partial<ProjectLinkModalValues>>({
    outcomeType: { label: outcomeType.name, value: outcomeType.id },
  });
  const [projectOutcome, setProjectOutcome] = useState<string>('');
  const projectShowcase = 'no';

  useEffect(() => {
    marketplaceApi
      .getProjectOutcomeTypes()
      .then(({ data }) => {
        const _options = data.map(({ name, id }: { name: string; id: string | number }) => ({
          label: name,
          value: id,
        }));
        setOptions(_options);
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  const handleChange = (name: string, value: string | OptionType | string[]) => {
    setModalValues((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleOutcomeChange: React.ChangeEventHandler<HTMLInputElement> = ({
    target: { value },
  }) => {
    setProjectOutcome(value);
  };

  const handleSave = () => {
    marketplaceApi
      .createProjectLink({
        ...modalValues,
        outcomeType: modalValues?.outcomeType?.value,
        portfolioItem: portfolioItemId,
      })
      .then(() => handleSubmit())
      .catch(() => toast.error('Something went wrong, please try again!'));
  };

  useEffect(() => {
    validate(modalValues, setValidationError, projectOutcome, projectShowcase);
  }, [modalValues, projectOutcome]);

  return (
    <div className={style['box']}>
      <div className="d-flex flex-column align-items-center">
        <img src={podProjectOutcome} alt="Pod project outcome" width={168} height={130} />
        <Spacer size={7} />
        <Heading size="h4">Share your project</Heading>
      </div>
      <Spacer size={7} />

      <Text size="medium">
        We hope you feel proud to share your new accomplishment! Provide your project outcome, or it
        didn’t happen. 😉
      </Text>
      <Spacer size={8} />
      <Text size="medium" fontWeight="bold">
        What is your project outcome?
      </Text>
      <Spacer size={6} />
      <Select
        name="outcomeType"
        options={options}
        value={modalValues.outcomeType}
        onChange={(newValue) => {
          handleChange('outcomeType', newValue as OptionType);
        }}
      />
      {modalValues?.outcomeType?.label === 'Other' && (
        <>
          <Spacer size={4} />
          <InputField
            name="outcomeTypeOther"
            placeholder="Describe your outcome in a few words here..."
            onChange={(text) => {
              handleChange('outcomeTypeOther', text.target.value);
            }}
          />
        </>
      )}
      <Spacer size={8} />
      <Text size="medium" fontWeight="bold">
        Upload your work here:
      </Text>
      <Spacer size={5} />
      <ProjectOutcome
        modalValues={modalValues}
        projectOutcome={projectOutcome}
        handleChange={handleChange}
        handleOutcomeChange={handleOutcomeChange}
      />
      {showValidation && validationError && (
        <Alert variant="danger">
          <div className="d-flex align-items-center gap-5">
            <Icon id="alert-circle" />
            {validationError}
          </div>
        </Alert>
      )}
      <Spacer size={4} />
      <ModalFooter className="justify-content-center">
        <Button
          variant="primary"
          size="lg"
          onClick={() => {
            setShowValidation(true);
            if (!validationError) {
              handleSave();
            }
          }}
        >
          Submit survey
        </Button>
      </ModalFooter>
    </div>
  );
};
