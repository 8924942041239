import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import type { LexicalEditor } from 'lexical';
import { createContext, useContext } from 'react';

export enum BlockType {
  paragraph = 'paragraph',
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  quote = 'quote',
  code = 'code',
  ul = 'ul',
  ol = 'ol',
}
export interface ToolbarContextValue {
  isBold: boolean;
  isItalic: boolean;
  isStrikethrough: boolean;
  isCode: boolean;
  blockType: string;
}

const defaultContextValue: ToolbarContextValue = {
  isBold: false,
  isItalic: false,
  isStrikethrough: false,
  isCode: false,
  blockType: BlockType.paragraph,
};

export const ToolbarContext = createContext<ToolbarContextValue>(defaultContextValue);

export const useToolbarContext = (): ToolbarContextValue & { editor: LexicalEditor } => {
  const [editor] = useLexicalComposerContext();
  const context = useContext(ToolbarContext);

  return { ...context, editor };
};
