import { $createLinkNode, $isLinkNode } from '@lexical/link';
import type { TextMatchTransformer } from '@lexical/markdown';
import { $createTextNode, $isTextNode } from 'lexical';

const MAX_URL_LENGTH = 110;
const SHORTENED_MARKER = '[...]';
const CHARS_TO_KEEP_AT_THE_END = 35;

const shortenUrlText = (url: string) => {
  if (url.length < MAX_URL_LENGTH) {
    return url;
  }

  return `${url.substring(
    0,
    MAX_URL_LENGTH - CHARS_TO_KEEP_AT_THE_END,
  )}${SHORTENED_MARKER}${url.substring(
    url.length - (CHARS_TO_KEEP_AT_THE_END - SHORTENED_MARKER.length),
    url.length,
  )}`;
};

// based on LINK transformer from @lexical/markdown, only export function is modified
export const SHORT_LINK: TextMatchTransformer = {
  export: (node, _, exportFormat) => {
    if (!$isLinkNode(node)) {
      return null;
    }

    const linkText = shortenUrlText(node.getTextContent());
    const linkUrl = node.getURL();

    const linkContent = `[${linkText}](${linkUrl})`;
    const firstChild = node.getFirstChild();
    // Add text styles only if link has single text node inside. If it's more
    // then one we ignore it as markdown does not support nested styles for links
    if (node.getChildrenSize() === 1 && $isTextNode(firstChild)) {
      return exportFormat(firstChild, linkContent);
    } else {
      return linkContent;
    }
  },
  importRegExp: /(?:\[([^[]+)\])(?:\(([^(]+)\))/,
  regExp: /(?:\[([^[]+)\])(?:\(([^(]+)\))$/,
  replace: (textNode, match) => {
    const [, linkText, linkUrl] = match;
    const linkNode = $createLinkNode(linkUrl || '');
    const linkTextNode = $createTextNode(linkText);
    linkTextNode.setFormat(textNode.getFormat());
    linkNode.append(linkTextNode);
    textNode.replace(linkNode);
  },
  trigger: ')',
  type: 'text-match',
};
