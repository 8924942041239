import React, { useState } from 'react';

import { Icon } from 'src/components/Icon';
import { authFetch, authFileUploadFetch } from 'src/components/customFetch';

/* eslint-disable react/prop-types, arrow-body-style */
// eslint-disable-next-line react/function-component-definition
export function AttachmentList({ files = [], handleRemove }) {
  return files.length > 0 ? (
    // eslint-disable-next-line react/jsx-filename-extension
    <ul className="uploaded-files label-before tw-break-all" aria-label="Attached file(s):">
      {files.map(({ name, content, id, loading }, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={idx}>
          {loading ? (
            <Icon
              className="rotate"
              name="loading"
              color="#6c757d"
              // eslint-disable-next-line sonarjs/no-duplicate-string
              style={{ verticalAlign: 'text-bottom' }}
            />
          ) : (
            <Icon name="file" color="#6c757d" style={{ verticalAlign: 'text-bottom' }} />
          )}
          <a href={content} target="_blank" rel="noopener noreferrer">
            {' '}
            {name}
          </a>
          {!loading && typeof handleRemove === 'function' && (
            <Icon
              name="delete"
              color="#6c757d"
              style={{ verticalAlign: 'text-bottom' }}
              className="clickable"
              onClick={() => handleRemove(id)}
            />
          )}
        </li>
      ))}
    </ul>
  ) : null;
}

/* eslint-disable no-shadow */
/**
 *
 * @param {string} uploadUrl
 * @param {string} removeUrl
 * @param {object} extraData
 * @returns {[({ target }: { target: { files: unknown } }) => void, unknown,{ loading: boolean; name: string, id:number, content: string }[], boolean, () => void,]}
 */
export function useMultipleFileUpload(uploadUrl, removeUrl = uploadUrl, extraData = {}) {
  const [files, setFiles] = useState([]);
  const uploading = files.some(({ loading }) => loading);

  const uploadFiles = ({ target: { files } }) => {
    if (files && files.length > 0) {
      Array.from(files).map((file) => {
        setFiles((files) => [...files, { id: null, loading: true, name: file.name }]);
        const formData = new FormData();
        formData.append('file', file);

        Object.keys(extraData).forEach((key) => {
          formData.append(key, extraData[key]);
        });

        authFileUploadFetch(uploadUrl, {
          body: formData,
          method: 'POST',
        }).then(({ id, content }) => {
          setFiles((files) => {
            const fileIndex = files.findIndex(({ name }) => name === file.name);

            return [
              ...files.slice(0, fileIndex),
              { id, content, loading: false, name: file.name },
              ...files.slice(fileIndex + 1, files.length),
            ];
          });
        });
        return true;
      });
    }
  };

  const removeFile = async (idToRemove) => {
    await authFetch(`${removeUrl}${idToRemove}/`, {
      method: 'DELETE',
    });
    setFiles((files) => files.filter(({ id }) => id !== idToRemove));
  };

  const clearFiles = () => setFiles([]);

  return [uploadFiles, removeFile, files, uploading, clearFiles];
}
/* eslint-enable no-shadow */

export function useFileUploadWithCallback(
  uploadUrl,
  removeUrl = uploadUrl,
  name = 'file',
  method = 'POST',
) {
  const [uploading, setUploading] = useState(false);

  const uploadFile = ({ target: { files }, callback, errorHandler }) => {
    if (files[0]) {
      setUploading(true);
      const formData = new FormData();
      formData.append(name, files[0]);

      authFileUploadFetch(uploadUrl, {
        body: formData,
        method,
      })
        .then((res) => callback(res))
        .catch((e) => errorHandler(e))
        .finally(() => setUploading(false));
    }
  };

  const removeFile = ({ idToRemove, callback }) => {
    authFetch(`${removeUrl}${idToRemove}/`, {
      method: 'DELETE',
    }).then(callback());
  };

  return [uploadFile, removeFile, uploading];
}
