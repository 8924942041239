import { PaymentIntentInvoice } from '@polygence/common/types/payment';
import { Heading, Text } from '@polygence/components';

import { PaymentIntentFooter } from 'src/payment/PaymentIntent/PaymentIntentFooter';
import { PaymentIntentPageLayout } from 'src/payment/PaymentIntent/PaymentIntentPageLayout';
import { PaymentPageTerms } from 'src/payment/PaymentPageTerms';

interface PaymentIntentAlreadyPaidProps {
  paymentIntent: PaymentIntentInvoice;
  subscription?: boolean;
}

export const PaymentIntentAlreadyPaid = ({
  paymentIntent,
  subscription,
}: PaymentIntentAlreadyPaidProps) => {
  return (
    <PaymentIntentPageLayout className="tw-pt-8">
      <Heading as="h1" size="h3" alignment="center" fontWeight="bold">
        {subscription
          ? 'This invoice is an ongoing subscription.'
          : 'This invoice is already paid.'}
      </Heading>
      <Text size="medium" alignment="center" className="tw-my-4">
        {paymentIntent.description}
      </Text>
      {paymentIntent.featurePaymentIntentPageV3 ? <PaymentIntentFooter /> : <PaymentPageTerms />}
    </PaymentIntentPageLayout>
  );
};
