import { getBoshiClientUrl } from '@polygence/common';

import { deleteGlobalCookie, getCookie, setGlobalCookie } from 'src/utils/cookie';

const REDIRECT_URL_FROM_REGISTRATION = 'redirect-url-from-registration';

export const getRedirectUrlFromCookie = () => {
  const cookie = getCookie(REDIRECT_URL_FROM_REGISTRATION);
  const baseUrl = getBoshiClientUrl().replace(/^https?:\/\//, '');
  if (!cookie || !cookie.includes(baseUrl)) {
    return null;
  }
  return cookie.split(baseUrl)[1];
};

export const deleteRedirectUrlCookie = () => {
  deleteGlobalCookie(REDIRECT_URL_FROM_REGISTRATION);
};

export const setRedirectUrlCookie = (value: string) => {
  setGlobalCookie(REDIRECT_URL_FROM_REGISTRATION, value);
};
