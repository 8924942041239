import type { UUID } from '@polygence/common';
import { Nullable } from '@polygence/common';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';

import { GPT_PROJECT_IDEA_UUID_LOCAL_STORAGE_KEY } from 'src/components/constants';
import {
  useLazyGetProjectIdeaQuery,
  useLikeProjectIdeaMutation,
  useStartProjectIdeaGenerationMutation,
} from 'src/reducers/projectIdeaGeneratorReducer';
import { localStorageManager } from 'src/utils/localStorageManager';

const POLLING_MAX = 10;
const POLLING_DELAY_IN_MS = 3000;

type GptProjectIdeaError = 'generic';

export const usePolyGptProjectIdeaGenerator = () => {
  const history = useHistory();
  const [projectIdeaUuid, setProjectIdeaUuid] = useState<Nullable<string>>(null);

  const [getProjectIdea] = useLazyGetProjectIdeaQuery();
  const [startProjectIdeaGeneration] = useStartProjectIdeaGenerationMutation();
  const [likeProjectIdea] = useLikeProjectIdeaMutation();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<string[]>([]);
  const [error, setError] = useState<GptProjectIdeaError | null>(null);
  const [retryCount, setRetryCount] = useState(0);

  const startIdeaGeneration = useCallback(
    (generalInterest: string, interests: string) => {
      setLoading(true);
      setError(null);
      startProjectIdeaGeneration({ generalInterest, interests })
        .unwrap()
        .then(({ uuid }) => {
          setProjectIdeaUuid(uuid);
          localStorageManager.set(GPT_PROJECT_IDEA_UUID_LOCAL_STORAGE_KEY, uuid);
        })
        .catch(() => {
          setError('generic');
          setLoading(false);
        });
    },
    [startProjectIdeaGeneration],
  );

  const getIdea = useCallback(
    (uuid: string) => {
      getProjectIdea({ projectIdeaUuid: uuid })
        .unwrap()
        .then((data) => {
          switch (data.status) {
            case 'pending':
              return setRetryCount((count) => count + 1);
            case 'finished':
              setData(data.projectIdeas);
              return setLoading(false);
            case 'failed':
              setError('generic');
              return setLoading(false);
          }
        })
        .catch(() => {
          setError('generic');
          setLoading(false);
        });
    },
    [getProjectIdea],
  );

  const likeIdea = useCallback(
    async (index: number) => {
      const uuid = await localStorageManager.get<UUID>(GPT_PROJECT_IDEA_UUID_LOCAL_STORAGE_KEY);

      if (uuid) {
        likeProjectIdea({
          projectIdeaUuid: uuid,
          likedProjectIdeaIndex: index,
        })
          .then(() => {
            toast.success('Idea copied to relevant application question');
            history.push('/student/profile');
          })
          .catch(() => {
            toast.error('We were unable to save this idea preference. Please try again.');
          });
      } else {
        toast.error('Allow localStorage to be able to use this feature.');
      }
    },
    [likeProjectIdea, history],
  );

  useEffect(() => {
    if (!projectIdeaUuid) {
      return;
    }

    setLoading(true);

    if (retryCount === POLLING_MAX - 1) {
      setError('generic');
      setLoading(false);
      return;
    }

    if (retryCount === 0) {
      getIdea(projectIdeaUuid);
      return;
    }

    const timerId = setTimeout(() => {
      getIdea(projectIdeaUuid);
    }, POLLING_DELAY_IN_MS);
    return () => clearTimeout(timerId);
  }, [projectIdeaUuid, retryCount, getIdea]);

  return { loading, data, error, startIdeaGeneration, likeIdea };
};
