import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import * as studentApplicationApi from '../../api/student-application';
import { StudentApplicationStatuses } from '../../types/studentApplication';
import type { StudentApplication } from '../../types/studentApplication';
import type { RootState } from '../store';

export interface StudentApplicationState {
  data: Partial<StudentApplication>;
  changedFields: Array<keyof StudentApplication>;
}

const getUpdatePayload = (state: StudentApplicationState): Partial<StudentApplication> => {
  return state.changedFields.reduce((updatable, field) => {
    return {
      ...updatable,
      [field]: state.data[field],
    };
  }, {});
};

const initialState: StudentApplicationState = {
  data: {},
  changedFields: [],
};

export const getLatestStudentApplication = createAsyncThunk(
  'application/',
  async ({ profileId }: { profileId: number }) => {
    const response = await studentApplicationApi.getLatestApplication(profileId);
    return response.data;
  },
);

export const getStudentApplication = createAsyncThunk(
  'application/getStudentApplication',
  async ({ profileId, applicationId }: { profileId: number; applicationId: number }) => {
    const response = await studentApplicationApi.getApplication(profileId, applicationId);
    return response.data;
  },
);

export const updateApplication = createAsyncThunk<
  StudentApplication | null,
  { profileId: number; applicationId: number },
  { state: RootState }
>('application/updateApplication', async ({ profileId, applicationId }, { getState, dispatch }) => {
  const { studentApplication, user } = getState();
  const isPartnerPaysWorkflow = (user.otherInfo['isPartnerPaysWorkflow'] as boolean) ?? false;

  const updatePayload = getUpdatePayload(studentApplication);

  if (!updatePayload || Object.keys(updatePayload).length === 0) {
    return Promise.resolve(null);
  }

  const apiCall = isPartnerPaysWorkflow
    ? studentApplicationApi.updateApplicationPartnerPays
    : studentApplicationApi.updateApplication;

  const response = await apiCall(profileId, applicationId, updatePayload);

  dispatch(actions.resetChangedFields());

  return response.data;
});

export const submitApplication = createAsyncThunk<
  StudentApplication,
  { profileId: number; applicationId: number },
  { state: RootState }
>('application/submitApplication', async ({ profileId, applicationId }, { getState, dispatch }) => {
  const { studentApplication, user } = getState();
  const isPartnerPaysWorkflow = (user.otherInfo['isPartnerPaysWorkflow'] as boolean) ?? false;

  const apiCall = isPartnerPaysWorkflow
    ? studentApplicationApi.updateApplicationPartnerPays
    : studentApplicationApi.updateApplication;

  const updatePayload = getUpdatePayload(studentApplication);
  const response = await apiCall(profileId, applicationId, {
    ...updatePayload,
    status: StudentApplicationStatuses.COMPLETED,
  });

  dispatch(actions.resetChangedFields());

  return response.data;
});

const studentApplicationSlice = createSlice({
  name: 'studentApplication',
  initialState,
  reducers: {
    updateApplicationData: (
      state,
      { payload }: { payload: { studentApplicationUpdate: Partial<StudentApplication> } },
    ) => {
      state.data = {
        ...state.data,
        ...payload.studentApplicationUpdate,
      };

      const changedKeys = Object.keys(payload.studentApplicationUpdate) as Array<
        keyof StudentApplication
      >;
      state.changedFields = [...new Set([...state.changedFields, ...changedKeys])];
    },
    resetChangedFields: (state) => {
      state.changedFields = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(submitApplication.fulfilled, (state, { payload }) => {
      state.data = payload;
    });
    builder.addCase(getLatestStudentApplication.fulfilled, (state, { payload }) => {
      state.data = payload;
    });
    builder.addCase(getLatestStudentApplication.rejected, (state) => {
      state.data = initialState.data;
    });
    builder.addCase(getStudentApplication.fulfilled, (state, { payload }) => {
      state.data = payload;
    });
    builder.addCase(getStudentApplication.rejected, (state) => {
      state.data = initialState.data;
    });
  },
});

const { actions, reducer } = studentApplicationSlice;

export const studentApplicationActions = actions;
export const studentApplicationReducer = reducer;
