import NiceModal, { useModal, bootstrapDialog } from '@ebay/nice-modal-react';
import { Heading, Modal, ModalBody, Text } from '@polygence/components';

import pssJourneyImage from 'src/components/static/images/pss-project-journey.png';

export const PSSInfoModal = NiceModal.create(() => {
  const modal = useModal();

  return (
    <Modal {...bootstrapDialog(modal)}>
      <ModalBody>
        <div className="m-5">
          <Heading size="h4" as="h4" fontWeight="semibold" className="mb-6">
            What is Premium Showcasing Support?
          </Heading>
          <Text alignment="center" size="medium" textWrap="wrap">
            Premium Showcasing Support is a 3 session add-on at the end of your Core Program
            designed to provide more structure in your showcasing and publishing journey.
          </Text>
          <img src={pssJourneyImage} alt="PSS Project Journey" className="m-5" />
          <Text alignment="left" size="medium" fontWeight="bold" className="m-6">
            In this program, you will:
          </Text>
          <ul className="m-6">
            <li>Work one-on-one with a Showcasing Specialist</li>
            <li>Develop a showcasing plan for your research project</li>
            <li>Revise elements of your work with an eye towards submission requirements</li>
            <li>Submit your work!</li>
          </ul>
          <Text alignment="left" size="medium" fontWeight="bold" className="m-6">
            How do I enroll?
          </Text>
          <Text alignment="left" size="medium" textWrap="wrap" className="m-6">
            After you submit your application, you'll have an interview with our Associate Director
            of Admissions. Talk to them about your interest in this program and they can get you
            signed up.
          </Text>
        </div>
      </ModalBody>
    </Modal>
  );
});
