import type { GuidebookPage } from '@polygence/common/types/guidebooks';
import { Heading, Text, Card } from '@polygence/components';
import classnames from 'classnames';
import { Container, Row, Col, Image } from 'react-bootstrap';

import { GuidebookLink } from 'src/components/Guidebook/GuidebookLink';
import styles from 'src/components/Guidebook/guidebook.module.sass';

interface CardListProps {
  cards: GuidebookPage[];
}

export const CardList = ({ cards }: CardListProps) => {
  return (
    <>
      {cards.map(({ id, title, url }) => {
        return (
          <Card
            key={id}
            as={GuidebookLink}
            className={classnames('p-5 justify-content-center', styles['text-card'])}
            href={url}
          >
            <Text size="medium" fontWeight="bold">
              {title}
            </Text>
          </Card>
        );
      })}
    </>
  );
};

interface GuidebookStackedCardSectionProps {
  title: string;
  categoryImage: string;
  cards: GuidebookPage[];
}

export const GuidebookStackedCardSection = ({
  title,
  categoryImage,
  cards,
}: GuidebookStackedCardSectionProps) => {
  return (
    <>
      <div className="my-10">
        <Heading size="h3" className="mb-8">
          {title}
        </Heading>
        <Container>
          <Row>
            <Col xs={12} md={4}>
              <Image
                alt={title}
                src={categoryImage}
                className={classnames('mb-1 mb-sm-10', styles['cardImage'])}
              />
            </Col>
            <Col
              xs={12}
              md={8}
              className={classnames('gap-3 d-grid', styles['text-card-container'])}
            >
              <CardList cards={cards} />
            </Col>
            {/* <Col xs={12} md={4} className="flex-row">
              <CardList
                cards={cards.filter((_, idx) => {
                  return idx % 2 === 1;
                })}
              />
            </Col> */}
          </Row>
        </Container>
      </div>
    </>
  );
};
