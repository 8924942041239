import { UUID } from '@polygence/common';
import { ProjectType } from '@polygence/common/types/hermes';
import { Alert } from '@polygence/components';
import { useEffect } from 'react';
import { useParams } from 'react-router';

import { PageLoad } from 'src/components/PageLoad';
import { FeedbackForm } from 'src/components/specialURLs/MatchingFeedbackForm';
import { STUDENT_TEAM_EMAIL } from 'src/constants/emails';
import {
  usePremiumShowcasingSupportMatchingRequestQuery,
  useAcceptPremiumShowcasingSupportMatchingRequestMutation,
  useDeclinePremiumShowcasingSupportMatchingRequestMutation,
} from 'src/reducers/marketplaceReducer';

const ShowcasingSupportMatchingContainer = ({ children }: { children: React.ReactNode }) => {
  return <div className="container my-5">{children}</div>;
};

const ShowcasingSupportMatchingAccept = ({ uuid }: { uuid: UUID }) => {
  const [acceptPremiumShowcasingSupportMatchingRequest, acceptResponse] =
    useAcceptPremiumShowcasingSupportMatchingRequestMutation();

  useEffect(() => {
    acceptPremiumShowcasingSupportMatchingRequest({ uuid }).catch(console.error);
  }, [uuid, acceptPremiumShowcasingSupportMatchingRequest]);

  if (acceptResponse.isLoading) {
    return <PageLoad />;
  }
  if (acceptResponse.isSuccess) {
    return (
      <ShowcasingSupportMatchingContainer>
        <Alert variant="success" className="text-center">
          Thank you for your answer! Your response has been recorded.
        </Alert>
      </ShowcasingSupportMatchingContainer>
    );
  }
  return <ShowcasingSupportMatchingGenericError />;
};

const ShowcasingSupportMatchingDecline = ({ uuid }: { uuid: UUID }) => {
  const [declinePremiumShowcasingSupportMatchingRequest] =
    useDeclinePremiumShowcasingSupportMatchingRequestMutation();
  return (
    <ShowcasingSupportMatchingContainer>
      <FeedbackForm
        uuid={uuid}
        response="decline"
        projectType={ProjectType.PREMIUM_SHOWCASING_PUBLISHING}
        updateResponse={(uuid, _response, payload) => {
          return declinePremiumShowcasingSupportMatchingRequest({ uuid, payload }).unwrap();
        }}
      />
    </ShowcasingSupportMatchingContainer>
  );
};

const ShowcasingSupportMatchingNotAvailable = () => {
  return (
    <ShowcasingSupportMatchingContainer>
      <Alert variant="danger" className="text-center">
        This matching request is no longer available.
      </Alert>
    </ShowcasingSupportMatchingContainer>
  );
};

const ShowcasingSupportMatchingGenericError = () => {
  return (
    <ShowcasingSupportMatchingContainer>
      <Alert variant="danger" className="text-center">
        Something went wrong. Please contact us at{' '}
        <a href={`mailto:${STUDENT_TEAM_EMAIL}`}>{STUDENT_TEAM_EMAIL}</a>.
      </Alert>
    </ShowcasingSupportMatchingContainer>
  );
};

export const ShowcasingSupportMatchingAnswer = () => {
  const { uuid, action } = useParams<{ uuid: string; action: 'accept' | 'decline' }>();
  const response = usePremiumShowcasingSupportMatchingRequestQuery({ uuid });

  if (response.isLoading || response.isUninitialized) {
    return <PageLoad />;
  }
  if (response.isError) {
    return <ShowcasingSupportMatchingNotAvailable />;
  }
  if (response.isSuccess && action === 'accept') {
    return <ShowcasingSupportMatchingAccept uuid={uuid} />;
  }
  if (response.isSuccess && action === 'decline') {
    return <ShowcasingSupportMatchingDecline uuid={uuid} />;
  }
  return <ShowcasingSupportMatchingGenericError />;
};
