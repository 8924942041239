import { CaseStudy } from '@polygence/common/types/site-content';
import { externalLink } from '@polygence/common/utils/externalLink';
import { Avatar, Chip, Spacer, Text } from '@polygence/components';
import classNames from 'classnames';

import styles from 'src/components/Tiles/Counselor/CaseStudyTile.module.scss';
import PolygenceLogoNew from 'src/components/static/images/logo/PolygenceLogoNew.svg';
import { urls } from 'src/urls';

type CaseStudyTileProps = Pick<
  CaseStudy,
  'url' | 'projectTitle' | 'projectImage' | 'projectImageAlt' | 'mentorDetails' | 'student'
>;

export const CaseStudyTile = ({
  url,
  projectTitle,
  projectImage: image,
  projectImageAlt: imageAlt,
  mentorDetails: mentor,
  student,
}: CaseStudyTileProps) => {
  const projectImage = image || PolygenceLogoNew;
  const projectImageAlt = imageAlt || 'Polygence Logo';
  return (
    <div className={classNames(styles['container'])}>
      <div className={classNames('position-relative', styles['imageContainer'])}>
        <img src={projectImage} alt={projectImageAlt} className={classNames(styles['image'])} />
      </div>
      <Spacer size={8} />
      <div className={classNames(styles['body'], 'd-flex flex-column justify-content-between')}>
        <Text size="medium" className={classNames(styles['title'], 'mb-7')}>
          {projectTitle}
        </Text>
        <div className={classNames(styles['chip-row'], 'd-flex justify-content-around')}>
          <Chip
            className={styles['chip']}
            addon={
              <Avatar
                imageOptions={{ props: { width: 40, height: 40 } }}
                size={40}
                user={{
                  firstName: student.name,
                  lastName: '',
                  profilePicture: student.image,
                }}
              />
            }
            dark={false}
            active={false}
          >
            {`${student.name}'s Project`}
          </Chip>
          {mentor && (
            <Chip
              className={styles['chip']}
              addon={
                <Avatar
                  imageOptions={{ props: { width: 40, height: 40 } }}
                  size={40}
                  user={{
                    firstName: mentor.firstName,
                    lastName: '',
                    profilePicture: mentor.profilePicture,
                  }}
                />
              }
              dark={false}
              active={false}
            >
              {`with ${mentor.firstName}`}
            </Chip>
          )}
        </div>
        <a
          href={urls.projectsReadMore(url)}
          {...externalLink}
          className="d-flex align-items-center gap-3 mt-6 justify-content-end"
        >
          See more
        </a>
      </div>
    </div>
  );
};
