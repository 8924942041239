import { Heading, Text } from '@polygence/components';

interface PaymentIntentHeadingProps {
  description: string;
}

export const PaymentIntentHeading = ({ description }: PaymentIntentHeadingProps) => {
  return (
    <div className="tw-my-7 tw-flex tw-flex-col tw-gap-1.5">
      <Heading as="h1" size="h4" alignment="center" fontWeight="bold">
        Please verify your payment amount below
      </Heading>
      <div className="tw-flex tw-justify-center">
        <Text size="medium" alignment="center" fontWeight="normal" className="tw-w-3/4">
          {description}
        </Text>
      </div>
    </div>
  );
};
