import { getPhoneInputErrorMessage } from '@polygence/components';
import * as EmailValidator from 'email-validator';
import { isNil } from 'lodash';

import { countWords } from 'src/utils';
import { dayjs } from 'src/utils/dayjsCustom';
import evaluateFormElementsOperations from 'src/utils/evaluateFormElementsOperations';

const fieldRequiredMessage = 'This field is required';

const defaultValidState = { valid: true, message: '' };

function isDefinedAndNotEmpty(value) {
  if (Array.isArray(value)) {
    return value.length > 0;
  }

  if (typeof value === 'string') {
    return value.trim() !== '';
  }

  return value != null;
}

function requiredValidator(data, field, required) {
  if (!required) {
    return defaultValidState;
  }
  const value = data?.[field.props?.name];
  return {
    valid: isDefinedAndNotEmpty(value),
    message: fieldRequiredMessage,
  };
}

function selectWithOtherValidator(data, field, required) {
  const value = data?.[field.props?.name];
  const otherValue = data?.[field.props?.otherName];

  if (otherValue) {
    return defaultValidState;
  }

  if (!value) {
    return {
      valid: false,
      message: fieldRequiredMessage,
    };
  }

  if (value === null && !otherValue) {
    return {
      valid: false,
      message: fieldRequiredMessage,
    };
  }

  return defaultValidState;
}

function atLeastOneOptionSelectedValidator(data, field, on) {
  if (!on) {
    return defaultValidState;
  }

  const valid = (field.props?.options || []).some((option) => {
    const value = data[option?.value];
    return value !== false;
  });

  return {
    valid,
    message: 'At least one option has to be selected',
  };
}

function emailValidator(data, field, on) {
  const value = data?.[field.props?.name];
  if (!on || !value) {
    return defaultValidState;
  }

  return {
    valid: EmailValidator.validate(value),
    message: 'Email address is not valid',
  };
}

function phoneInputValidator(data, field, { required = true } = {}) {
  if (!window) {
    return defaultValidState;
  }

  if (!required && !data[field.props?.name]) {
    return defaultValidState;
  }

  // abusing that every elements with an ID is stored on window 🤷
  const inputElement = window[`phoneInput__${field.props?.name}`];
  if (!inputElement) {
    console.warn(`Could not find PhoneInput for field ${field.props?.name}`);
    return defaultValidState;
  }

  // get intl-tel-input instance for the input element
  const iti = window.intlTelInputGlobals.getInstance(inputElement);
  if (iti) {
    if (iti.getNumber() !== data[field.props?.name]) {
      console.warn('Data mismatch in PhoneInput, failed to validate.');
    } else {
      return {
        valid: iti.isValidNumber(),
        message: getPhoneInputErrorMessage(iti.getValidationError()),
      };
    }
  }

  return defaultValidState;
}

function wordCountValidator(data, field, options) {
  const { minimum, maximum } = options;

  if (minimum == null && maximum == null) {
    return defaultValidState;
  }

  const value = data?.[field.props?.name];

  const wordCount = countWords(value);

  if (minimum != null && wordCount < minimum) {
    return { valid: false, message: `Please write at least ${minimum} words.` };
  }

  if (maximum != null && wordCount > maximum) {
    return {
      valid: false,
      message: `Please write no more than ${maximum} words.`,
    };
  }

  return defaultValidState;
}

function integerValidator(data, field, options) {
  const value = data?.[field.props?.name];
  const integerValue = parseInt(data?.[field.props?.name], 10);
  const isInteger = /^\d+$/.test(value);
  const { min, max } = options;

  if (min == null && max == null) {
    return {
      valid: isInteger,
      message: 'This must be a number',
    };
  }

  if (min == null) {
    return {
      valid: isInteger && integerValue <= max,
      message: `This must be a number lower than or equal to ${max}`,
    };
  }

  if (max == null) {
    return {
      valid: isInteger && min <= integerValue,
      message: `This must be a number greater than or equal to ${min}`,
    };
  }

  return {
    valid: isInteger && min <= integerValue && integerValue <= max,
    message: `The value must be a number between ${min} and ${max}`,
  };
}

function timelineValidator(data, field, options) {
  const { minimum } = options;
  const startDate = data?.[field.props?.startAt];
  const endDate = data?.[field.props?.endAt];

  if (isNil(startDate) || isNil(endDate)) {
    return {
      valid: false,
      message: `Please choose a time range of ${minimum} months!`,
    };
  }

  const monthDifference = dayjs(endDate).diff(dayjs(startDate), 'M');

  if (monthDifference + 1 < minimum) {
    return {
      valid: false,
      message: `Minimum duration has to be ${minimum} months!`,
    };
  }
  return defaultValidState;
}

function correctOptionSelectedValidator(data, field, options) {
  const { correctValue } = options;

  const value = data?.[field.props?.name];

  if (value !== correctValue) {
    return {
      valid: false,
    };
  }

  return defaultValidState;
}

function cantBeSameAsValidator(data, field, options) {
  const value = data?.[field.props?.name];
  const { dataField, errorMsg } = options;
  const otherFieldValue = data[dataField];

  if (otherFieldValue == null || otherFieldValue === '') {
    return defaultValidState;
  }

  if (value === otherFieldValue) {
    return { valid: false, message: errorMsg };
  }

  return defaultValidState;
}

function eitherRequiredValidator(data, field, fieldNames) {
  const isValid = fieldNames.some((name) => isDefinedAndNotEmpty(data[name]));
  if (!isValid) {
    return {
      valid: false,
      message: `This field is required!`,
    };
  }
  return defaultValidState;
}

function pathfinderInterestValidator(data, field) {
  const index = field.props?.interestIndex;

  if (index === undefined) {
    return defaultValidState;
  }

  const interests = data?.[field.props?.name] ?? [];
  const isValid = interests[index] && interests[index] > 0;

  if (!isValid) {
    return {
      valid: false,
      message: `Interest field cannot be empty!`,
    };
  }

  return defaultValidState;
}

function socialMediaValidator(data, field) {
  const value = data?.[field.props?.name];

  const isValid = value !== 'social_media';

  if (!isValid) {
    return {
      valid: false,
    };
  }

  return defaultValidState;
}

export const validators = Object.freeze({
  required: requiredValidator,
  atLeastOneOptionSelected: atLeastOneOptionSelectedValidator,
  selectWithOther: selectWithOtherValidator,
  email: emailValidator,
  integer: integerValidator,
  phoneInput: phoneInputValidator,
  wordCount: wordCountValidator,
  timeline: timelineValidator,
  correctOptionSelected: correctOptionSelectedValidator,
  cantBeSameAs: cantBeSameAsValidator,
  eitherRequired: eitherRequiredValidator,
  pathfinderInterest: pathfinderInterestValidator,
  socialMedia: socialMediaValidator,
});

const validateField = (field, data) => {
  const validationResult = {};

  if (!field.validation) {
    return validationResult;
  }

  const shouldRun =
    field.validation?.shouldRun ||
    (() => {
      return true;
    });

  if (shouldRun(data)) {
    Object.entries(field.validation).forEach(([validatorKey, validatorOptions]) => {
      const validator = validators[validatorKey];

      if (validator) {
        // eslint-disable-next-line fp/no-mutation
        validationResult[validatorKey] = validator(data, field, validatorOptions);
      }
    });
  }

  return validationResult;
};

const isObjectValid = (validationResult) => {
  return Object.values(validationResult).every((result) => {
    return result.valid;
  });
};

const collectFieldsToValidate = (section, fieldsToValidate = [], inheritedDisplayRules = []) => {
  section.forEach((field) => {
    if (!field) {
      return;
    }

    if (field.validation) {
      const shouldRun = (data) => {
        return evaluateFormElementsOperations(
          { $and: [...inheritedDisplayRules, field.display] },
          data,
        );
      };
      fieldsToValidate.push({
        ...field,
        validation: { ...field.validation, shouldRun },
      });
    }

    if (Array.isArray(field?.props?.children)) {
      field.props.children.forEach((child) => {
        let childFieldArray = Array.isArray(child) ? child : [child];
        collectFieldsToValidate(childFieldArray, fieldsToValidate, [
          ...inheritedDisplayRules,
          ...(field.display ? [field.display] : []),
        ]);
      });
    }
  });

  return fieldsToValidate;
};

const validateFields = (fields, data) => {
  return fields.reduce((acc, field) => {
    if (!field?.props?.name) {
      console.error('Trying to validate field without a name prop');
      return acc;
    }

    const validationResult = validateField(field, data);

    // no validators have run, ignore field
    if (Object.keys(validationResult).length === 0) {
      return acc;
    }

    return {
      ...acc,
      [field.props.name]: validationResult,
    };
  }, {});
};

const simplifyFieldResult = (validationResult) => {
  const result = { valid: true, message: '' };
  Object.keys(validationResult).forEach((validator) => {
    if (!validationResult[validator].valid) {
      // eslint-disable-next-line fp/no-mutation
      result.valid = false;
      // eslint-disable-next-line fp/no-mutation
      result.message = validationResult[validator].message;
    }
  });
  return result;
};

const isSectionValid = (fields, data) => {
  const fieldResults = validateFields(fields, data);
  const valid = Object.keys(fieldResults).every((key) => {
    return isObjectValid(fieldResults[key]);
  });
  Object.keys(fieldResults).forEach((field) => {
    // eslint-disable-next-line fp/no-mutation
    fieldResults[field] = simplifyFieldResult(fieldResults[field]);
  });
  return { valid, fieldResults };
};

export { collectFieldsToValidate, isSectionValid, validateFields };
