import { commonHooks } from '@polygence/common';
import * as marketplaceApi from '@polygence/common/api/marketplace';
import type { StudentProfileWithApplication } from '@polygence/common/types/marketplace';
import { useState } from 'react';

import type { ApplicationSectionId } from 'src/constants/application-sections';

type PartialStudentProfileWithApplication = Partial<StudentProfileWithApplication>;

export const useStudentProfileForApplication = () => {
  const [loading, setLoading] = useState(false);
  const [studentProfile, setStudentProfile] = useState<PartialStudentProfileWithApplication>({});
  const [changedFields, setChangedFields] = useState<Array<keyof StudentProfileWithApplication>>(
    [],
  );
  const currentUser = commonHooks.useCurrentUser();

  const resetChangedFields = () => {
    setChangedFields([]);
  };

  const getUpdatePayload = (
    state: PartialStudentProfileWithApplication,
  ): PartialStudentProfileWithApplication => {
    return changedFields.reduce((updatable, field) => {
      return {
        ...updatable,
        [field]: state[field],
      };
    }, {});
  };

  const getStudentProfile = () => {
    setLoading(true);
    return marketplaceApi.getStudentProfileForCoreApp().then(({ data }) => {
      setStudentProfile(data);
      setLoading(false);
    });
  };

  const updateStudentProfileState = (updatedState: PartialStudentProfileWithApplication) => {
    setStudentProfile((previousState) => {
      return {
        ...previousState,
        ...updatedState,
      };
    });
    const changedKeys = Object.keys(updatedState) as Array<keyof StudentProfileWithApplication>;
    setChangedFields((prevState) => {
      return [...new Set([...prevState, ...changedKeys])];
    });
  };

  const calculateProfileCompletionStage = (
    { completedApplicationStages }: PartialStudentProfileWithApplication,
    sectionId: ApplicationSectionId,
  ) => {
    const completedSections = completedApplicationStages ?? [];
    return completedSections.includes(sectionId)
      ? completedSections.length
      : completedSections.length + 1;
  };

  const calculateCompletedApplicationStages = (
    { completedApplicationStages }: PartialStudentProfileWithApplication,
    sectionId: ApplicationSectionId,
  ) => {
    return [...new Set([...(completedApplicationStages ?? []), sectionId])];
  };

  const updateStudentProfileForCoreApp = (sectionId: ApplicationSectionId) => {
    const updatePayload = getUpdatePayload(studentProfile);
    const apiCall =
      (currentUser?.otherInfo['isPartnerPaysWorkflow'] as boolean) ?? false
        ? marketplaceApi.updateStudentProfileForCoreAppPartnerPaysWorkflow
        : marketplaceApi.updateStudentProfileForCoreAppStudentPaysWorkflow;

    return apiCall({
      ...updatePayload,
      completedApplicationStages: calculateCompletedApplicationStages(studentProfile, sectionId),
      profileCompletionStage: calculateProfileCompletionStage(studentProfile, sectionId),
    }).then(() => {
      setStudentProfile((profile) => ({
        ...profile,
        completedApplicationStages: calculateCompletedApplicationStages(profile, sectionId),
        profileCompletionStage: calculateProfileCompletionStage(profile, sectionId),
      }));

      resetChangedFields();
    });
  };

  const partialUpdateStudentProfile = () => {
    const updatePayload = getUpdatePayload(studentProfile);
    // Currently this is being used for pods. Ideally it should use a separate endpoint (and this should be a ticket)
    return marketplaceApi
      .updateStudentProfileForCoreAppStudentPaysWorkflow({
        ...updatePayload,
      })
      .then(() => resetChangedFields());
  };

  const partialUpdateStudentProfileForPathfinders = () => {
    const updatePayload = getUpdatePayload(studentProfile);
    return marketplaceApi
      .updateStudentProfileForPathfinders(studentProfile.id ?? 0, {
        ...updatePayload,
      })
      .then(() => resetChangedFields());
  };

  const submitStudentProfile = (applicationId: number, sectionId: ApplicationSectionId) => {
    const updatePayload = getUpdatePayload(studentProfile);
    const apiCall =
      (currentUser?.otherInfo['isPartnerPaysWorkflow'] as boolean) ?? false
        ? marketplaceApi.updateStudentProfileForCoreAppPartnerPaysWorkflow
        : marketplaceApi.updateStudentProfileForCoreAppStudentPaysWorkflow;

    return apiCall({
      ...updatePayload,
      completedApplicationStages: calculateCompletedApplicationStages(studentProfile, sectionId),
      profileCompletionStage: calculateProfileCompletionStage(studentProfile, sectionId),
    }).then(() => marketplaceApi.submitStudentApplicationLegacy({ applicationId }));
  };

  return {
    loading,
    studentProfile,
    getStudentProfile,
    updateStudentProfileState,
    updateStudentProfileForCoreApp,
    partialUpdateStudentProfile,
    partialUpdateStudentProfileForPathfinders,
    submitStudentProfile,
    getUpdatePayload,
  };
};
