import type { Nullable } from '@polygence/common';
import * as marketplaceApi from '@polygence/common/api/marketplace';
import { AsyncCreatableSelect, OptionType } from '@polygence/components';
import debounce from 'lodash/debounce';

import type { SelectorChangeTarget } from 'src/types/common';

interface InstitutionSelectorProps {
  label: string;
  name: string;
  value: Nullable<OptionType>;
  placeholder?: string;
  defaultOptions?: boolean;
  required?: boolean;
  isInvalid?: boolean;
  onChange: (event: SelectorChangeTarget<string>) => void | null;
}

const getListOfInstitutions = (search: string) => {
  return marketplaceApi
    .getInstitutions({ search: search, limit: 50 })
    .then(({ data: { results } }) => results);
};

const loadInstitutionOptions = debounce(
  (search: string, callback: (options: OptionType[]) => void) => {
    getListOfInstitutions(search)
      .then((data) => callback(data.map(({ value, label }) => ({ value, label }))))
      .catch(console.error);
  },
  500,
);

export const InstitutionSelector = ({
  label,
  name,
  value,
  placeholder = 'Write here...',
  defaultOptions = true,
  required = false,
  isInvalid = false,
  onChange,
}: InstitutionSelectorProps) => {
  return (
    <AsyncCreatableSelect
      id="school-select"
      name={name}
      label={label}
      placeholder={placeholder}
      value={value}
      required={required}
      isInvalid={isInvalid}
      defaultOptions={defaultOptions}
      loadOptions={loadInstitutionOptions}
      onChange={(newValue) =>
        onChange({
          target: {
            name,
            value: newValue ? newValue.value : null,
          },
        })
      }
    />
  );
};
