import { getRestClient } from '../commonSettings';
import type { ProjectId } from '../types/common';
import type {
  EditableForm,
  PremiumShowcasingMatchingSurveyCreate,
  SupportWidgetSubmissionCreate,
  PremiumShowcasingMatchingSurvey,
} from '../types/survey';

export const getPremiumShowcasingMatchingForm = () => {
  const internalFetch = getRestClient();
  return internalFetch.get<EditableForm>('/survey/forms/pss-application/');
};

export const getSupportWidgetForm = (formName: string) => {
  const internalFetch = getRestClient();
  return internalFetch.get<EditableForm>(`/survey/forms/${formName}/`);
};

export const postPremiumShowcasingMatchingSurvey = ({
  data,
  projectId,
}: {
  data: Record<string, unknown>;
  projectId: ProjectId;
}) => {
  const internalFetch = getRestClient();
  return internalFetch.post<PremiumShowcasingMatchingSurveyCreate>(
    '/survey/premium-showcasing-matching-survey/',
    {
      data,
      project: projectId,
    },
  );
};

export const postSupportWidgetForm = ({
  data,
  projectId,
}: {
  data: Record<string, unknown>;
  projectId: ProjectId | undefined;
}) => {
  const internalFetch = getRestClient();
  return internalFetch.post<SupportWidgetSubmissionCreate>('/survey/support-widget-submission/', {
    data,
    project: projectId,
  });
};

export const getPremiumShowcasingMatchingSurvey = (projectId: ProjectId) => {
  const internalFetch = getRestClient();
  return internalFetch.get<PremiumShowcasingMatchingSurvey>(
    `/survey/premium-showcasing-matching-survey/${projectId}/`,
  );
};
