import * as marketplaceApi from '@polygence/common/api/marketplace';
import React from 'react';
import { Link } from 'react-router-dom';

import { authFetch } from 'src/components/customFetch';
import OptOutFeedback from 'src/components/specialURLs/OptOutFeedback';
import { navigateTo } from 'src/utils/navigateTo';

/* eslint-disable react/prop-types */
class StudentURLResponse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      response_recorded: false,
      error_occured: false,
      detailsProvided: false,
      feedbackId: null,
    };
  }

  componentDidMount() {
    const {
      location: { search },
      showFooter,
    } = this.props;
    const searchParams = new URLSearchParams(search);

    const requestParams = new URLSearchParams(search);
    requestParams.set('url_subject', searchParams.get('subject'));
    requestParams.delete('subject');
    const urlEncodedEmail = encodeURIComponent(searchParams.get('email'));

    marketplaceApi
      .partialUpdateStudentURLResponse(requestParams.toString(), {
        email: urlEncodedEmail,
        pk: searchParams.get('pk'),
        response: searchParams.get('response'),
        subject: searchParams.get('subject'),
        uuid: searchParams.get('uuid'),
      })
      .then(({ data: result }) => {
        if (result) {
          if (result.enrollment) {
            const { projectUuid, studentPk } = result.enrollment;
            navigateTo(
              `/signed-urls/enrollment-response/?response=enroll&uuid=${projectUuid}&pk=${studentPk}`,
            );
          } else {
            this.setState({
              response_recorded: true,
              feedbackId: result.id,
            });
            showFooter(false);
          }
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          error_occured: true,
        });
      });
  }

  onDetailedFeedbackSubmit = ({ items, other, similarProgram }) => {
    const {
      location: { search },
    } = this.props;
    const { feedbackId } = this.state;
    const searchParams = new URLSearchParams(search);
    const email = searchParams.get('email');

    const payload = {
      checked_items: items,
      similar_program: similarProgram,
      other,
      email,
    };
    authFetch(`/survey/opt-out-feedback/${feedbackId}/`, {
      method: 'PATCH',
      body: JSON.stringify(payload),
    })
      .then(() => {
        this.setState({ detailsProvided: true });
      })
      // eslint-disable-next-line sonarjs/no-identical-functions -- autodisabled
      .catch((error) => {
        console.error(error);
        this.setState({
          error_occured: true,
        });
      });
  };

  render() {
    const {
      location: { search },
    } = this.props;
    const {
      error_occured: errorOccured,
      response_recorded: responseRecorded,
      detailsProvided,
    } = this.state;
    const searchParams = new URLSearchParams(search);
    const response = searchParams.get('response');

    return (
      // eslint-disable-next-line react/jsx-filename-extension -- autodisabled
      <div className="col-8 offset-2 col-md-6 offset-md-3 mb-7 text-center text-muted">
        <div>
          {errorOccured ||
            (!['yes', 'no'].includes(response) && (
              // eslint-disable-next-line react/forbid-dom-props -- autodisabled
              <div style={{ color: 'red' }}>
                <p>Sorry, an error occured and your response could not be recorded.</p>
                <p>
                  Please contact us at{' '}
                  <a href="mailto:students@polygence.org">students@polygence.org</a>.
                </p>
              </div>
            ))}
          {response === 'yes' && (
            <div>
              <h4 className="mt-5 text-center">Thank you for your response!</h4>
              <hr />
              <div className="mt-3">
                <div>
                  <p>We are delighted that you are happy with your mentor match!</p>
                  <Link className="btn btn-primary mb-3" to="/dashboard/messages">
                    Say Hi! to your mentor
                  </Link>
                </div>
              </div>
              <div>
                {responseRecorded && (
                  <div>
                    {/* eslint-disable-next-line react/forbid-dom-props -- autodisabled */}
                    <div style={{ color: 'green' }}>Your response has been recorded.</div>
                    <p className="small mt-2">
                      If you clicked this link by mistake please contact us at{' '}
                      <a href="mailto:students@polygence.org">students@polygence.org</a>{' '}
                      immediately.
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
          {response === 'no' && !detailsProvided && (
            <div>
              <p>We are sorry to hear that you are no longer interested in Polygence.</p>
              <p>
                Please select all that apply and we will close your application until we hear from
                you again.
              </p>
              <OptOutFeedback onSubmit={this.onDetailedFeedbackSubmit} />
              <p className="small mt-2">
                If you clicked this link by mistake please contact us at{' '}
                <a href="mailto:students@polygence.org">students@polygence.org</a> immediately.
              </p>
            </div>
          )}
          {response === 'no' && detailsProvided && (
            <div>
              <h4 className="mt-5 text-center">Thank you for your response!</h4>
              <hr />
              <div className="mt-3">
                <div>
                  <p>
                    We are sorry to hear that you are no longer interested in Polygence mentorship.
                  </p>
                  <p>
                    Please feel free to reach out to us if you become interested again in the
                    future!
                  </p>
                </div>
              </div>
              <div>
                {responseRecorded && (
                  <div>
                    {/* eslint-disable-next-line react/forbid-dom-props -- autodisabled */}
                    <div style={{ color: 'green' }}>Your response has been recorded.</div>
                    <p className="small mt-2">
                      If you clicked this link by mistake please contact us at{' '}
                      <a href="mailto:students@polygence.org">students@polygence.org</a>{' '}
                      immediately.
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

// eslint-disable-next-line import/no-default-export -- autodisabled
export default StudentURLResponse;
