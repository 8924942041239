import classNames from 'classnames';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ProjectIdeaCard } from 'src/components/projectIdeaGenerator/ProjectIdeaCard';
import styles from 'src/components/projectIdeaGenerator/ProjectIdeaSuggestionsResult.module.scss';

interface ProjectIdeaSuggestionsResultProps {
  ideas: string[];
}

export const ProjectIdeaSuggestionsResult = ({ ideas }: ProjectIdeaSuggestionsResultProps) => {
  return (
    <div className="tw-z-[1] tw-flex tw-flex-col">
      <Swiper
        navigation
        pagination={{ type: 'fraction' }}
        modules={[Pagination, Navigation]}
        spaceBetween={30}
        className={classNames(styles['projectIdeaSuggestionsSwiper'], 'tw-pb-12')}
      >
        {ideas.map((idea, index) => (
          <SwiperSlide key={idea}>
            <ProjectIdeaCard index={index} title={`Project idea #${index + 1}`} idea={idea} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
