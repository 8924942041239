/* eslint-disable fp/no-mutation */
import { useCallback, useEffect, useLayoutEffect } from 'react';

export const useScrollLock = (shouldLock: boolean) => {
  const lockScroll = useCallback(() => {
    document.body.dataset['scrollLock'] = 'true';
    document.body.style.overflow = 'hidden';
    document.body.style.paddingRight = 'var(--scrollbar-compensation)';
  }, []);

  const unlockScroll = useCallback(() => {
    document.body.style.overflow = '';
    document.body.style.paddingRight = '';
    delete document.body.dataset['scrollLock'];
  }, []);

  useEffect(() => {
    if (shouldLock) {
      lockScroll();
    } else {
      unlockScroll();
    }

    return () => {
      unlockScroll();
    };
  }, [shouldLock, lockScroll, unlockScroll]);

  useLayoutEffect(() => {
    const scrollBarCompensation = window.innerWidth - document.body.offsetWidth;
    document.body.style.setProperty('--scrollbar-compensation', `${scrollBarCompensation}px`);
  }, []);
};
