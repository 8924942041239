import { EditableChannel } from 'src/components/common/EditableChannel';
import { useAppSelector } from 'src/store';

interface EditableChannelWithCounselorDetailsProps {
  channel: {
    value: string;
    label: string;
  };
  userId: number;
  label: string;
  counselorDetails?: {
    partnershipArrangementName: string;
  };
}

interface PartnershipArrangment {
  displayName: string;
}

const EditableChannelWithCounselorDetails = ({
  channel: initChannel,
  userId,
  counselorDetails,
  label,
}: EditableChannelWithCounselorDetailsProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const partnershipArrangementName =
    useAppSelector((state) => {
      if (state.channel.partnershipArrangement) {
        return (state.channel.partnershipArrangement as PartnershipArrangment)?.displayName;
      }
      return false;
    }) || counselorDetails?.partnershipArrangementName;

  return (
    <>
      <div className="form-group">
        <EditableChannel userId={userId} channel={initChannel} label={label} />
      </div>
      {partnershipArrangementName && (
        <div className="form-group">
          <h6>Partner Arrangement</h6>
          <span>{partnershipArrangementName}</span>
        </div>
      )}
    </>
  );
};

export { EditableChannelWithCounselorDetails };
