import { ApplicationSectionIds } from 'src/constants/application-sections';
import { ApplicationComponentSection } from 'src/students/student-application/student-application-components';

export const visualInterviewSection: ApplicationComponentSection = {
  id: ApplicationSectionIds.VIRTUAL_INTERVIEW_SECTION_ID,
  display: {
    $is: {
      field: 'virtualInterviewRequired',
      value: true,
    },
  },
  elements: [
    {
      component: 'ThreeColumnLayout',
      props: {
        children: [
          [{ component: 'AdmissionAdvisorModalInfo' }],
          [
            {
              component: 'Spacer',
              props: { size: 2 },
            },
            {
              component: 'StudentVideoInterview',
              tag: 'application',
              props: {
                name: 'virtualInterviewSubmittedAt',
                // videoAskBaseSrc: 'https://www.videoask.com/fynyk89dd', // left here for easier testing
                videoAskBaseSrc: 'https://www.videoask.com/fudtoriv7',
              },
              validation: {
                required: true,
              },
            },
          ],
        ],
      },
    },
  ],
};
