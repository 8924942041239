import { ensureProtocol } from '@polygence/common';
import type { ProjectLink } from '@polygence/common/types/marketplace';
import { Card } from '@polygence/components';

import styles from 'src/components/student/ScholarPage/ProjectLink/projectLink.module.sass';

export const ProjectLinkCard = ({
  url,
  outcomeTypeDetails: { buttonText, tileImage },
}: Pick<ProjectLink, 'url' | 'outcomeTypeDetails'>) => {
  const defaultTileImage = 'https://dpl6hyzg28thp.cloudfront.net/media/project-link-fallback.png';
  return (
    <Card
      className={styles['projectLinkArticle']}
      onClick={() => {
        if (url) {
          window.open(ensureProtocol(url), '_blank');
        }
      }}
    >
      <img src={tileImage ?? defaultTileImage} alt="Card's background illustration" />
      <div className={styles['actions']}>{buttonText}</div>
    </Card>
  );
};
