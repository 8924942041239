import { useCommonSelector } from '../../store/store';

export function useAssignmentsByIds(ids: number[] | undefined) {
  return useCommonSelector((state) => {
    if (!ids) {
      return [];
    }

    return ids.flatMap((id) => {
      const assignment = state.hermes.assignments.byId[id];
      return assignment ? [assignment] : [];
    });
  });
}
