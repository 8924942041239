import { Icon, Spacer, Text } from '@polygence/components';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Dropzone from 'react-dropzone';

import { SpinningWheel } from 'src/components/SpinningWheel';
import { useFileUploadWithCallback } from 'src/components/hermes/FileUpload';

const EditableImage = ({
  src,
  name = 'image',
  label = '',
  alt,
  editing,
  uploadUrl,
  removeUrl,
  inlineImage = false,
  acceptedFileFormat = '.jpg, .jpeg, .png',
  reload = () => {},
  ...imageProps
}) => {
  const [imageSrc, setImageSrc] = useState(src);
  const [uploadFile, removeFile, uploading] = useFileUploadWithCallback(
    uploadUrl,
    removeUrl,
    name,
    'PATCH',
  );

  const handleDelete = (event) => {
    event.preventDefault();
    setImageSrc(null);
    removeFile({
      idToRemove: '',
      callback: () => {
        reload();
      },
    });
  };

  const handleUpload = (acceptedFiles) => {
    uploadFile({
      target: { files: acceptedFiles },
      callback: (res) => {
        setImageSrc(res[name]);
        reload(res);
      },
      errorHandler: console.error,
    });
  };

  return (
    // eslint-disable-next-line react/jsx-filename-extension -- autodisabled
    <>
      {!inlineImage && src && <img src={src} alt={alt} {...imageProps} />}
      {editing && (
        <>
          <Spacer size={4} />
          <Dropzone multiple={false} accept={acceptedFileFormat} onDrop={handleUpload}>
            {({ getRootProps, getInputProps }) => {
              return (
                <div className="image-upload clickable slide-down-350ms">
                  {label}
                  <div
                    className="p-6 position-relative base-border-radius dashed-gray-border"
                    {...getRootProps()}
                  >
                    {uploading && <SpinningWheel />}
                    {!uploading && (!imageSrc || !inlineImage) && (
                      <div className="d-flex flex-column align-items-center">
                        <Icon id="activity" color="blue" />
                        <Spacer size={4} />
                        <Text className="text-primary" size="small">
                          Drop or browse image to represent your project
                        </Text>
                      </div>
                    )}
                    {!uploading && inlineImage && imageSrc && (
                      <div>
                        <img
                          src={imageSrc}
                          height={120}
                          className="mw-100 base-border-radius"
                          alt="profile"
                        />
                        <button
                          onClick={handleDelete}
                          type="button"
                          className="close"
                          aria-label="Delete"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                    )}
                    <input multiple={false} accept={acceptedFileFormat} {...getInputProps()} />
                  </div>
                </div>
              );
            }}
          </Dropzone>
        </>
      )}
    </>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default EditableImage;

EditableImage.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  editing: PropTypes.bool.isRequired,
  uploadUrl: PropTypes.string.isRequired,
  removeUrl: PropTypes.string.isRequired,
  inlineImage: PropTypes.bool,
  acceptedFileFormat: PropTypes.string,
  reload: PropTypes.func,
};
