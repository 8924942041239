import type { Dispatch, SetStateAction } from 'react';

import type { FetchPaginatedData } from '../types/data/common';

import usePaging from './usePaging';

// eslint-disable-next-line import/no-default-export -- autodisabled
export default function usePagination<T>(
  fetchPaginatedData: FetchPaginatedData<T>,
  config: {
    prefetchedPages?: Record<string, T>;
    prefetchedCount?: number;
    pageSize?: number;
    pagination?: number;
    search?: string;
    restorePage?: boolean;
  },
): [
  T[] | undefined,
  JSX.Element | null,
  () => void,
  () => void,
  boolean,
  unknown,
  number,
  Dispatch<SetStateAction<number>>,
] {
  const { currentPageData, page, pages, setPage, lastPage, forceReload, loading, data, count } =
    usePaging<T>(fetchPaginatedData, config);

  const loadNextPage = () => {
    if (page < lastPage) {
      setPage(page + 1);
    }
  };

  const paginator =
    lastPage <= 1 ? null : (
      <div className="pagination">
        <button
          disabled={page === 1}
          type="button"
          className="btn btn-sm"
          onClick={() => setPage(1)}
        >
          First
        </button>
        <button
          disabled={page === 1}
          type="button"
          className="btn btn-sm"
          onClick={() => setPage(page - 1)}
        >
          Previous
        </button>
        {pages.map((p) => (
          <button
            key={`page-button-${p}`}
            type="button"
            className={`btn btn-sm btn-${page === p ? 'primary' : ''}`}
            onClick={() => setPage(p)}
          >
            {p}
          </button>
        ))}
        <button
          disabled={page === lastPage}
          type="button"
          className="btn btn-sm"
          onClick={() => setPage(page + 1)}
        >
          Next
        </button>
        <button
          disabled={page === lastPage}
          type="button"
          className="btn btn-sm"
          onClick={() => setPage(lastPage)}
        >
          Last
        </button>
      </div>
    );

  return [currentPageData, paginator, forceReload, loadNextPage, loading, data, count, setPage];
}
