const emojis = {
  mentor: [0x1f9d1, 0x200d, 0x1f3eb],
  parent: [0x1f468, 0x200d, 0x1f469, 0x200d, 0x1f466],
  student: [0x1f9d1, 0x200d, 0x1f393],
  email: [0x1f4e8],
  phone: [0x1f4de],
  redCircle: [0x1f534],
  greenCircle: [0x1f7e2],
  whiteCircle: [0x26aa],
  eyes: [0x1f440],
  checkMark: [0x2705],
  family: [0x1f46a],
  redCrossMark: [0x274c],
  arrowDown: [0x2b07],
  arrowUp: [0x2b06],
  playButton: [0x25b6, 0xfe0f],
  downButton: [0x1f53d],
  detective: [0x1f575],
  womensRoom: [0x1f6ba],
  mensRoom: [0x1f6b9],
  goldMedal: [0x1f947],
  silverMedal: [0x1f948],
  bronzeMedal: [0x1f949],
  hourglassNotDone: [0x23f3],
} as const;

const loadEmoji = (emojiArray: (typeof emojis)[keyof typeof emojis]) => {
  return emojiArray.map((emoji) => {
    return String.fromCodePoint(emoji);
  });
};

export { emojis, loadEmoji };
