import { useEffect, useState } from 'react';

/**
 * Tracks the state of the provided media query.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia
 * @example useMediaQuery('(max-width: 600px)');
 */
export function useMediaQuery(query: string) {
  const [matches, setMatches] = useState(
    typeof window !== 'undefined' && window.matchMedia(query).matches,
  );

  useEffect(() => {
    const handleChange = () => setMatches(window.matchMedia(query).matches);

    const mediaQuery = window.matchMedia(query);

    // Trigger at the first client-side load
    handleChange();

    mediaQuery.addEventListener('change', handleChange);
    return () => mediaQuery.removeEventListener('change', handleChange);
  }, [query]);

  return matches;
}
