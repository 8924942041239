import { Text } from '@polygence/components';

export const PitchPSSJourneyTile = () => {
  return (
    <Text size="small" alignment="center" textWrap="balance" className="pt-4 pb-5">
      Do you want support showcasing and publishing your Core project? If so, Premium Showcasing is
      for you! When you are in the Core Program, you'll be able to purchase and enroll in this
      program.
    </Text>
  );
};
