import { Fragment } from 'react';

interface RenderIfAnyValueProps<T> {
  children: React.ReactNode;
  values?: T[];
  as?: React.ElementType;
}

export const RenderIfAnyValue = <T,>({
  children,
  values = [],
  as: Component = Fragment,
}: RenderIfAnyValueProps<T>) => {
  return values.some((value) => {
    return value != null && value !== '';
  }) ? (
    <Component>{children}</Component>
  ) : null;
};
