import * as marketplaceApi from '@polygence/common/api/marketplace';
import React, { useEffect, useState } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';

import { SpinningWheel } from 'src/components/SpinningWheel';

const WaitlistInvitation = ({
  match: {
    params: { uuid },
  },
  location: { pathname },
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [responseRecorded, setResponseRecorded] = useState(false);

  const response = pathname.includes('yes') ? 'yes' : 'no';

  useEffect(() => {
    setLoading(true);
    marketplaceApi
      .updateWaitlistInvitationResponse(uuid, response)
      .then(() => {
        return setResponseRecorded(true);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        return setLoading(false);
      });
  }, [uuid, response]);

  return (
    // eslint-disable-next-line react/jsx-filename-extension -- autodisabled
    <div className="container my-5">
      {loading && <SpinningWheel />}
      {error && (
        <div className="alert alert-danger">
          Something went wrong. Please contact us at{' '}
          <a href="mailto:students@polygence.org">students@polygence.org</a>.
        </div>
      )}
      {responseRecorded && (
        <div className="alert alert-success">Your response has been recorded.</div>
      )}
    </div>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default WaitlistInvitation;

WaitlistInvitation.propTypes = {
  match: ReactRouterPropTypes.match,
  location: ReactRouterPropTypes.location,
};
