import { clientTimezoneOffset } from 'src/utils/clientTimezoneOffest';
import { profileTimezoneOffset } from 'src/utils/profileTimezoneOffset';

export const getTimezoneOffsetFromMinutes = (minutes: number): string => {
  if (minutes < 0) {
    return `${minutes / 60}`;
  }
  return `+${minutes / 60}`;
};

export const isClientTimezoneDifferent = (profileTimezone: string | undefined): boolean => {
  return clientTimezoneOffset() !== profileTimezoneOffset(profileTimezone);
};
