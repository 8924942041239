import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import * as hermesApi from '../../api/hermes';
import type { AttachmentDetailSerializer } from '../../types/data/hermes';
import type { ByIds } from '../../types/utils';

export interface AttachmentState {
  workspaces: ByIds<AttachmentDetailSerializer[]>;
}

const defaults: AttachmentState = {
  workspaces: {
    byId: {},
    allIds: [],
  },
};

export const getAttachmentsForWorkspace = createAsyncThunk<
  {
    attachments: AttachmentDetailSerializer[];
    workspaceId: number;
  },
  { workspaceId: number }
>('attachments/getAttachmentsForWorkspace', async ({ workspaceId }) => {
  const response = await hermesApi.getAttachmentsForWorkspace(workspaceId);
  const attachments = response.data;
  return { attachments, workspaceId };
});

const attachmentsSlice = createSlice({
  name: 'attachments',
  initialState: defaults,
  extraReducers: (builder) => {
    builder.addCase(
      getAttachmentsForWorkspace.fulfilled,
      (state, { payload: { workspaceId, attachments } }) => {
        state.workspaces.byId[workspaceId] = attachments;
        if (!state.workspaces.allIds.includes(workspaceId)) {
          state.workspaces.allIds.push(workspaceId);
        }
      },
    );
  },
  reducers: {},
});

const { actions, reducer } = attachmentsSlice;

export const attachmentsActions = actions;
export const attachmentsReducer = reducer;
