import { getRestClient } from '../commonSettings';
import type { ProjectId } from '../types/common';
import type { EmptyResponse } from '../types/data/common';
import { getBaseUrl } from '../utils/baseUrls';

export interface FreebusyTimeSlot {
  start: string;
  end: string;
}

interface CalendarFreeBusyResponse {
  busy: Record<number, FreebusyTimeSlot[]>;
}

export const connectGoogleCalendar = (code: string, state: string) => {
  const internalFetch = getRestClient();
  return internalFetch.post<EmptyResponse>('/external-calendar-sync/connect/google/', {
    code,
    state,
  });
};

export const revokeGoogleCalendarAuthorization = () => {
  const internalFetch = getRestClient();
  return internalFetch.post<EmptyResponse>('/external-calendar-sync/revoke/google/');
};

export const getGoogleAuthorizationUrl = () => {
  const internalFetch = getRestClient();
  return internalFetch.get<{ authorizationUrl: string }>('/external-calendar-sync/auth/google/', {
    params: { redirect_uri: getBaseUrl('/user/calendar-connect/google') },
  });
};

export function getCalendarFreebusy(projectId: ProjectId, timeMin: string, timeMax: string) {
  const internalFetch = getRestClient();
  return internalFetch.get<CalendarFreeBusyResponse>(
    `/external-calendar-sync/projects/${projectId}/freebusy/`,
    { params: { time_min: timeMin, time_max: timeMax } },
  );
}
