import type { ProjectIdea } from '@polygence/common';
import * as marketplaceApi from '@polygence/common/api/marketplace';
import { Accordion } from '@polygence/components';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css/bundle';
import 'src/components/student/ProjectIdeas.sass';

interface ProjectIdeasProps {
  generalInterest: string;
}

export const ProjectIdeas = ({ generalInterest }: ProjectIdeasProps) => {
  const [ideas, setIdeas] = useState([
    {
      id: -1,
      title: 'Project Idea examples',
      description:
        '“I would like to better understand today’s social media consumption pattern and related privacy concerns. I am specifically interested in examining the right to be forgotten, facial recognition technology, international privacy norms, and the regulatory powers of governments as opposed to those of private actors.”',
    },
  ]);

  useEffect(() => {
    marketplaceApi
      .getApplicationProjectIdea(generalInterest)
      .then(({ data }) => {
        if (data.length) {
          setIdeas(data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [generalInterest]);

  return (
    <Accordion
      className="project-idea"
      summary="Project Idea examples"
      detail={
        <Swiper
          spaceBetween={30}
          centeredSlides
          autoHeight
          pagination={{
            clickable: true,
            dynamicBullets: true,
          }}
          modules={[Pagination]}
        >
          {ideas.map((idea: ProjectIdea) => {
            return (
              <SwiperSlide key={idea.id}>
                <div className={classNames({ 'pagination-shown': ideas.length > 1 })}>
                  {idea.description}
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      }
    />
  );
};
