import type { FileUpload } from '@polygence/common/types/marketplace';
import { FileUploadInput as CFileUploadInput } from '@polygence/components';
import type {
  FileUploadInputProps,
  UploadedFile,
  ProgressEventHandler,
} from '@polygence/components';

import { getFilenameFromPath } from 'src/components/hermes/utils';

export const mapDocuments = (documents: FileUpload[]): UploadedFile[] => {
  return documents.map(({ url, uuid, type }) => {
    const filename = getFilenameFromPath(url) || '';
    return { uuid, type, url, filename };
  });
};

interface CustomFileUploadInputProps extends Omit<FileUploadInputProps, 'onUpload'> {
  data: Record<string, FileUpload[]>;

  onChange: ({
    target: { name, value },
  }: {
    target: { name: string; value: UploadedFile[] };
  }) => void;

  onUpload: ({
    formData,
    onProgressChange,
  }: {
    formData: FormData;
    onProgressChange: ProgressEventHandler;
  }) => Promise<{ data: FileUpload[] }>;
}

export const FileUploadInput = ({
  multiple = true,
  onChange,
  onUpload,
  onDelete,
  data,
  name,
  options,
  disabled = false,
  accept = ['*'],
  required = false,
}: CustomFileUploadInputProps) => {
  const documents = data[name] || [];
  const alreadyUploadedDocuments = mapDocuments(documents);

  return (
    <CFileUploadInput
      multiple={multiple}
      name={name}
      options={options}
      uploadedFiles={alreadyUploadedDocuments}
      disabled={disabled}
      accept={accept}
      required={required}
      onUpload={async ({ onProgressChange, files }) => {
        const formData = new FormData();
        // eslint-disable-next-line fp/no-mutation -- autodisabled
        for (let i = 0; i < files.length; i += 1) {
          const file = files[i];
          if (file) {
            formData.append('files', file);
          }
        }
        // @ts-expect-error https://polygence.atlassian.net/browse/BSH-7657
        formData.append('student_id', data['id']);

        const uploadedDocuments = await onUpload({
          formData,
          onProgressChange,
        });

        onChange({
          target: {
            name,
            value: [...alreadyUploadedDocuments, ...mapDocuments(uploadedDocuments.data)],
          },
        });
      }}
      onDelete={() => {
        onDelete();
        onChange({ target: { name, value: [] } });
      }}
    />
  );
};
