import { Text } from '@polygence/components';

export const WaitUntilPSSJourneyTile = () => {
  return (
    <Text size="small" alignment="center" textWrap="balance" className="pt-4 pb-5">
      This workspace is available when you have two Core Program sessions left, have completed the
      "Get Matched" form, and have been matched with a Showcasing Specialist. Pro tip: Make sure to
      use all your Writing Feedback in your Core Program before meeting your Specialist.
    </Text>
  );
};
