import { commonReducers, useCommonSelector } from '@polygence/common';
import { Modal, ModalBody } from '@polygence/components';
import { useDispatch } from 'react-redux';

import { ShowcasingMatchingSurvey } from 'src/components/hermes/premium-showcasing-support/ShowcasingMatchingSurvey';

export const ShowcasingMatchingSurveyModal = () => {
  const { open, projectId } = useCommonSelector((state) => {
    return state.showcasingAddon.showcasingMatchingModal;
  });
  const dispatch = useDispatch();
  const closeModal = () => {
    dispatch(commonReducers.showcasingAddonActions.closeMatchingModal());
  };

  if (!projectId) {
    return null;
  }

  return (
    <Modal show={open} onHide={closeModal}>
      <ModalBody>
        <ShowcasingMatchingSurvey projectId={projectId} />
      </ModalBody>
    </Modal>
  );
};
