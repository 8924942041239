import { Button, Icon } from '@polygence/components';
import { Link } from 'react-router-dom';

export const PayNowButton = ({ disabled, link }: { disabled: boolean; link: string }) => {
  return (
    <Link to={link}>
      <Button variant="tertiary" endIcon={<Icon id="chevron-right" />} disabled={disabled}>
        Pay now
      </Button>
    </Link>
  );
};
