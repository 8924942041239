import * as backendApi from '@polygence/common/api/backend';
import { commonHooks } from '@polygence/common/hooks';
import { useRefetchCurrentUser } from '@polygence/common/hooks/useRefetchCurrentUser';
import { toast } from 'react-toastify';

import { GoogleButton } from 'src/components/auth/GoogleAuth';
import { navigateTo } from 'src/utils/navigateTo';

export const SocialAuthConnect = () => {
  const { refetch } = useRefetchCurrentUser();
  const googleConnect = async () => {
    try {
      const result = await backendApi.getGoogleAuthorizationUrl('/user/google-connect');
      navigateTo(result.data.authorizationUrl);
    } catch {
      toast.error('Something went wrong.');
    }
  };

  const googleDisconnect = async () => {
    await backendApi.deleteGoogleConnection();
    refetch();
  };

  const currentUser = commonHooks.useCurrentUser();

  if (currentUser.socialAuthGoogleConnected) {
    return (
      <GoogleButton text="Disconnect account from Google" onClick={() => void googleDisconnect()} />
    );
  }

  return <GoogleButton text="Connect account to Google" onClick={() => void googleConnect()} />;
};
