import type { TagCategories } from '@polygence/common/types/data/marketplace';
import type { Tag, TagCreatePayload } from '@polygence/common/types/marketplace';
import { useMemo } from 'react';
import Select, { createFilter, MultiValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';

const filterOption = createFilter<BaseTagInputOption>({
  stringify: (option) => `${option.label} ${option.value} ${option.data.terms}`,
});

interface BaseTagInputCreateOption {
  label: string;
  value: string;
  __isNew__?: boolean;
}

interface BaseTagInputOption {
  label: string;
  value: number;
  terms: string;
}

interface BaseTagInputChangeEvent {
  target: {
    name: string;
    value: number[];
    fullValue: Tag[];
  };
}

export interface BaseTagInputProps {
  name: string;
  value: BaseTagInputOption['value'][];
  category: TagCategories;
  tags: Tag[];
  creatable?: boolean;
  className?: string;
  setTags: (tags: Partial<Tag>[]) => void;
  createTag: (tag: TagCreatePayload) => Promise<Tag>;
  onChange: (e: BaseTagInputChangeEvent) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
}

export const BaseTagInput = ({
  name,
  value,
  category,
  tags,
  creatable,
  className,
  setTags,
  createTag = () => Promise.reject('createTag function was not provided.'),
  onChange,
  onBlur,
}: BaseTagInputProps) => {
  const options = useMemo(() => {
    return tags.map<BaseTagInputOption>(({ id, name, parent, keyTerms = '' }) => ({
      label: parent?.name ? `${parent.name} - ${name}` : name,
      value: id,
      terms: keyTerms,
    }));
  }, [tags]);

  const selectedValue = useMemo(
    () => options.filter((option) => value.includes(option.value)),
    [options, value],
  );

  const handleChange = (selected: MultiValue<BaseTagInputCreateOption | BaseTagInputOption>) => {
    const newValue = selected.map((option) => {
      if (!('__isNew__' in option)) {
        // For some reason intellisense cannot pick up this type narrowing.
        return (option as BaseTagInputOption).value;
      }

      const newTag: TagCreatePayload = {
        name: option.value,
        category,
      };

      const tempTagId = -Math.floor(Math.random() * Date.now());
      const newTagWithId = { ...newTag, id: tempTagId };

      setTags([...tags, newTagWithId]);

      createTag(newTag)
        .then((createdTag) => {
          const newTags = [...tags.filter((tag) => tag.id !== tempTagId), createdTag];
          setTags(newTags);
          const newValue = [...value.filter((tagId) => tagId !== tempTagId), createdTag.id];

          onChange({
            target: {
              name,
              value: newValue,
              fullValue: newTags.filter(({ id }) => newValue.includes(id)),
            },
          });
        })
        .catch(console.error);

      return tempTagId;
    });

    onChange({
      target: {
        name,
        value: newValue,
        fullValue: tags.filter(({ id }) => newValue.includes(id)),
      },
    });
  };

  if (creatable) {
    return (
      <CreatableSelect
        id={name}
        name={name}
        options={options}
        value={selectedValue}
        placeholder="Add tags..."
        isMulti
        className={className}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        menuPortalTarget={document.body}
        filterOption={filterOption}
        formatCreateLabel={(inputValue) => `Create a tag for "${inputValue}"...`}
        onChange={handleChange}
        onBlur={onBlur}
      />
    );
  }

  return (
    <Select
      id={name}
      name={name}
      options={options}
      value={selectedValue}
      placeholder="Add tags..."
      isMulti
      className={className}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      menuPortalTarget={document.body}
      filterOption={filterOption}
      onChange={handleChange}
      onBlur={onBlur}
    />
  );
};
