import { useCommonSelector } from '../../store/store';
import type { Meeting } from '../../types/hermes';

// eslint-disable-next-line import/no-default-export -- autodisabled
export default function useMeetingById(id: number | undefined): Meeting | undefined {
  return useCommonSelector((state) => {
    if (id == null) {
      return undefined;
    }
    return state.hermes.meetings.byId[id];
  });
}
