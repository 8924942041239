import { Icon } from '@polygence/components';

interface TogglerProps {
  id: string;
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  label: string;
  checkedLabel?: string;
}

export const Toggler = ({
  id,
  name,
  onChange,
  checked,
  label,
  checkedLabel = label,
}: TogglerProps) => {
  return (
    <>
      <input
        type="checkbox"
        className="form-check-input hidden"
        id={id}
        name={name}
        onChange={onChange}
        checked={checked}
      />
      <label className="form-check-label tw-my-5 tw-flex" htmlFor={id}>
        <div className="tw-z-[100] tw-flex tw-h-full tw-cursor-pointer tw-justify-center">
          <Icon id={checked ? 'plus-circle' : 'minus-circle'} />
        </div>
        <div className="tw-box tw-ms-5 tw-h-full tw-justify-center tw-font-bold">
          {checked ? checkedLabel : `Add additional ${label}`}
        </div>
      </label>
    </>
  );
};
