import { usePage } from '@polygence/components';
import classnames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import TimeMe from 'timeme.js';

import { FormSection } from 'src/components/mentor/FormSection';
import styles from 'src/students/form/PagedFormSection.module.sass';
import { collectFieldsToValidate, isSectionValid } from 'src/students/validation';

const PagedFormSection = ({
  fields,
  data,
  handleChange,
  beforeForm,
  afterForm,
  fullScreen = true,
}) => {
  const { setIsValid, index } = usePage();
  const [validationResult, setValidationResult] = useState({});

  const fieldsToValidate = useMemo(() => {
    return collectFieldsToValidate(fields);
  }, [fields]);

  TimeMe.stopTimer();
  TimeMe.setCurrentPageName(`Section-${index}`);
  TimeMe.startTimer();

  useEffect(() => {
    const sectionValidationResult = isSectionValid(fieldsToValidate, data);
    setIsValid(sectionValidationResult.valid);
    setValidationResult(sectionValidationResult.fieldResults);
  }, [data, fieldsToValidate, setIsValid]);

  const background = fields[0]?.background ?? 'background';
  return (
    <div className={classnames(fullScreen && styles['container'], styles[background])}>
      {beforeForm}
      <FormSection
        fields={fields}
        profile={data}
        onChange={handleChange}
        helpColumn={false}
        validationResult={validationResult}
      />
      {afterForm}
    </div>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default PagedFormSection;
