import { INSERT_ORDERED_LIST_COMMAND, REMOVE_LIST_COMMAND } from '@lexical/list';
import classNames from 'classnames';
import type { FC } from 'react';

import { Icon } from 'src/components/Icon';
import {
  useToolbarContext,
  BlockType,
} from 'src/components/LexicalEditor/plugins/toolbar/ToolbarContext';

const OrderedList: FC = () => {
  const { editor, blockType } = useToolbarContext();

  const formatNumberedList = () => {
    if (blockType !== BlockType.ol) {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  return (
    <button
      type="button"
      title="Numbered List"
      onClick={formatNumberedList}
      className={classNames('toolbar-item', {
        active: blockType === BlockType.ol,
      })}
      aria-label="Format Numbered List"
    >
      <Icon name="numberedList" local color="currentColor" size="24px" />
    </button>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default OrderedList;
