import { getAttachmentsForWorkspace } from '../reducers/attachmentsReducer';
import {
  getWorkspaceById,
  getSessionsForProject,
  getMeetingsForWorkspace,
  saveSessionForProject,
  saveMeeting,
  cancelMeeting,
  cancelSession,
  updateProject,
  getProject,
  getMessagesForRoom,
  submitSessionSummary,
  submitStudentFeedback,
  submitStudentFeedbackRequest,
  getAssignmentBlueprints,
  uploadAssignment,
  toggleRoomNotification,
  patchAssignmentSolution,
  getWorkspaceDisplayList,
  getTodoItems,
  updateTodoItem,
  markCompletedTodoItemsAsSeen,
} from '../reducers/hermesReducer';
import {
  getPathfinderApplication,
  getLatestPathfinderApplication,
  updatePathfinderApplication,
  submitPathfinderApplication,
} from '../reducers/pathfinderStudentApplicationReducer';
import {
  getSelfProposableMentorRequests,
  getSelfProposedMentorRequests,
  selfProposeForMentorRequest,
} from '../reducers/selfProposeReducer';
import {
  getSharedResources,
  refreshGoogleFiles,
  updateSharedResource,
} from '../reducers/sharedResourcesReducer';
import {
  getLatestStudentApplication,
  getStudentApplication,
  updateApplication,
  submitApplication,
} from '../reducers/studentApplicationReducer';

export const commonThunks = {
  applicationThunks: {
    getLatestStudentApplication,
    getStudentApplication,
    updateApplication,
    submitApplication,
  },
  attachmentThunks: {
    getAttachmentsForWorkspace,
  },
  sharedResourcesThunks: {
    getSharedResources,
    refreshGoogleFiles,
    updateSharedResource,
  },
  hermesThunks: {
    getAttachmentsForWorkspace,
    getWorkspaceDisplayList,
    getWorkspaceById,
    getSessionsForProject,
    getMeetingsForWorkspace,
    saveSessionForProject,
    saveMeeting,
    cancelMeeting,
    cancelSession,
    updateProject,
    getProject,
    getMessagesForRoom,
    submitSessionSummary,
    submitStudentFeedback,
    submitStudentFeedbackRequest,
    getAssignmentBlueprints,
    uploadAssignment,
    toggleRoomNotification,
    patchAssignmentSolution,
    getTodoItems,
    updateTodoItem,
    markCompletedTodoItemsAsSeen,
  },
  selfProposeThunks: {
    getSelfProposableMentorRequests,
    selfProposeForMentorRequest,
    getSelfProposedMentorRequests,
  },
  pathfinderApplicationThunks: {
    getPathfinderApplication,
    getLatestPathfinderApplication,
    updatePathfinderApplication,
    submitPathfinderApplication,
  },
};
