export const toOptions = (stringArray: readonly string[]) => {
  return stringArray.map(toOption);
};

export const toOption = (option: string) => {
  if (!option) {
    return undefined;
  }

  return {
    value: option,
    label: option,
  };
};
