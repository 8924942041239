import { useCommonSelector } from '../../store/store';
import type { Workspace } from '../../types/hermes';

import useSelectedWorkspaceId from './useSelectedWorkspaceId';

// eslint-disable-next-line import/no-default-export -- autodisabled
export default function useSelectedWorkspace(): Workspace | undefined {
  const id = useSelectedWorkspaceId();
  return useCommonSelector((state) => {
    if (id == null) {
      return undefined;
    }
    return state.hermes.workspaces.byId[id];
  });
}

export function useSelectedWorkspaceOrThrow(): Workspace {
  const workspace = useSelectedWorkspace();
  if (workspace == null) {
    throw Error('Workspace is not selected.');
  }
  return workspace;
}
