/* eslint-disable sonarjs/no-duplicate-string */

import {
  PodsFinalSurveySectionId,
  PodsFinalSurveySectionIds,
} from 'src/constants/pods-final-survey-sections';
import styles from 'src/students/Pods/PodsFinalSurvey.module.scss';
import { FinalSurveyIllustration } from 'src/students/Pods/PodsFinalSurveyIllustrations';
import type { ComponentSection } from 'src/students/student-application';
import {
  headingProps,
  headingSpacer,
  contentSpacer,
  textFieldSpacer,
} from 'src/students/student-application/common-section-components';

export type PodsFinalSurveyComponentSection = ComponentSection<PodsFinalSurveySectionId>;

export interface PodsFinalSurvey {
  recommendToFriendRating: number;
  doAnotherPodRating: number;
  currentPodRating: number;
  mostValuablePodExperience: string;
  recommendationsForFuturePods: string;
  nextStepsForStudent: string;
  otherFeedback: string;
  mentorHelpfulness: string;
  currentPodMentorRating: number;
  permissionToPublishReview: boolean;
}

export const PODS_INPUTS = {
  QUESTION_1: {
    ID: 'recommendToFriendRating',
    WORDING:
      'On a scale of 1-10, how likely are you to recommend Polygence Pods to a friend? (1 is terrible and 10 is amazing)',
  },
  QUESTION_2: {
    ID: 'doAnotherPodRating',
    WORDING:
      'On a scale of 1-10, how likely are you to join another Polygence Pod? (1 is "definitely not" and 10 is "can\'t wait to start another pod")',
  },
  QUESTION_3: {
    ID: 'currentPodRating',
    WORDING:
      'On a scale of 1-5, how would you rate your specific Pod? (1 is terrible and 5 is amazing)',
  },
  QUESTION_4: {
    ID: 'mostValuablePodExperience',
    WORDING: 'What was the most valuable part of your Pod experience?',
  },
  QUESTION_5: {
    ID: 'recommendationsForFuturePods',
    WORDING: 'What would you want to see changed for future Pods?',
  },
  QUESTION_6: {
    ID: 'nextStepsForStudent',
    WORDING:
      'What are you planning to do next after this Pod? Both with your project and with your learning?',
  },
  QUESTION_7: {
    ID: 'otherFeedback',
    WORDING: 'Any other feedback?',
  },
  QUESTION_8: {
    ID: 'mentorHelpfulness',
    WORDING:
      'How helpful was your mentor in guiding you through the process of completing the project?',
  },
  QUESTION_9: {
    ID: 'currentPodMentorRating',
    WORDING:
      'On a scale of 1-5, how would you rate the mentor of your Pod? (1 is terrible and 5 is amazing)',
  },
  QUESTION_10: {
    ID: 'permissionToPublishReview',
    WORDING: 'Do you give permission to publish your review on our website?',
  },
} as const;

const finalSurveyTitle = 'Pods Program Feedback';

export const PODS_FINAL_SURVEY_SECTIONS = Object.freeze<PodsFinalSurveyComponentSection[]>([
  {
    id: PodsFinalSurveySectionIds.FINAL_SURVEY_INTRO,
    elements: [
      {
        component: 'Box',
        background: 'background-white',
        props: {
          containerClassName: styles['box'],
          children: [
            {
              component: 'CenteredIllustration',
              props: {
                illustration: FinalSurveyIllustration,
              },
            },
            headingSpacer,
            {
              component: 'ComponentsHeading',
              props: {
                children: finalSurveyTitle,
                ...headingProps,
              },
            },
            contentSpacer,
            {
              component: 'ComponentsText',
              props: {
                children:
                  'We love to learn as well! Share your thoughts and help us improve Pods to make it the best possible experience for students in the future. It takes 5 minutes to fill out the survey.',
                size: {
                  base: 'medium',
                },
              },
            },
          ],
        },
      },
    ],
  },
  {
    id: PodsFinalSurveySectionIds.FINAL_SURVEY_1,
    elements: [
      {
        component: 'Box',
        background: 'background-white',
        props: {
          containerClassName: styles['box'],
          children: [
            {
              component: 'CenteredIllustration',
              props: {
                illustration: FinalSurveyIllustration,
              },
            },
            headingSpacer,
            {
              component: 'ComponentsHeading',
              props: {
                children: finalSurveyTitle,
                ...headingProps,
              },
            },
            contentSpacer,
            {
              component: 'NumberSelector',
              props: {
                label: PODS_INPUTS.QUESTION_1.WORDING,
                name: PODS_INPUTS.QUESTION_1.ID,
                startFrom: 1,
                numberCount: 10,
                className: styles['numberSelector'],
              },
              validation: {
                required: true,
              },
            },
            textFieldSpacer,
            {
              component: 'NumberSelector',
              props: {
                label: PODS_INPUTS.QUESTION_2.WORDING,
                name: PODS_INPUTS.QUESTION_2.ID,
                startFrom: 1,
                numberCount: 10,
                className: styles['numberSelector'],
              },
              validation: {
                required: true,
              },
            },
          ],
        },
      },
    ],
  },
  {
    id: PodsFinalSurveySectionIds.FINAL_SURVEY_2,
    elements: [
      {
        component: 'Box',
        background: 'background-white',
        props: {
          containerClassName: styles['box'],
          children: [
            {
              component: 'CenteredIllustration',
              props: {
                illustration: FinalSurveyIllustration,
              },
            },
            headingSpacer,
            {
              component: 'ComponentsHeading',
              props: {
                children: finalSurveyTitle,
                ...headingProps,
              },
            },
            contentSpacer,
            {
              component: 'NumberSelector',
              props: {
                label: PODS_INPUTS.QUESTION_3.WORDING,
                name: PODS_INPUTS.QUESTION_3.ID,
                startFrom: 1,
                numberCount: 5,
                className: styles['numberSelector'],
              },
              validation: {
                required: true,
              },
            },
            textFieldSpacer,
            {
              component: 'ComponentsText',
              props: {
                children: PODS_INPUTS.QUESTION_4.WORDING,
                size: {
                  base: 'medium',
                },
              },
            },
            textFieldSpacer,
            {
              component: 'InputField',
              props: {
                type: 'textarea',
                name: PODS_INPUTS.QUESTION_4.ID,
                placeholder: '',
                showWordCount: true,
              },
              validation: {
                required: true,
                wordCount: {
                  minimum: 1,
                  maximum: 100,
                },
              },
            },
            textFieldSpacer,
            {
              component: 'ComponentsText',
              props: {
                children: PODS_INPUTS.QUESTION_5.WORDING,
                size: {
                  base: 'medium',
                },
              },
            },
            textFieldSpacer,
            {
              component: 'InputField',
              props: {
                type: 'textarea',
                name: PODS_INPUTS.QUESTION_5.ID,
                placeholder: '',
                showWordCount: true,
              },
              validation: {
                required: true,
                wordCount: {
                  minimum: 1,
                  maximum: 100,
                },
              },
            },
          ],
        },
      },
    ],
  },
  {
    id: PodsFinalSurveySectionIds.FINAL_SURVEY_3,
    elements: [
      {
        component: 'Box',
        background: 'background-white',
        props: {
          containerClassName: styles['box'],
          children: [
            {
              component: 'CenteredIllustration',
              props: {
                illustration: FinalSurveyIllustration,
              },
            },
            headingSpacer,
            {
              component: 'ComponentsHeading',
              props: {
                children: finalSurveyTitle,
                ...headingProps,
              },
            },
            contentSpacer,
            {
              component: 'ComponentsText',
              props: {
                children: PODS_INPUTS.QUESTION_6.WORDING,
                size: {
                  base: 'medium',
                },
              },
            },
            textFieldSpacer,
            {
              component: 'InputField',
              props: {
                type: 'textarea',
                name: PODS_INPUTS.QUESTION_6.ID,
                placeholder: '',
                showWordCount: true,
              },
              validation: {
                required: true,
                wordCount: {
                  minimum: 1,
                  maximum: 100,
                },
              },
            },
            textFieldSpacer,
            {
              component: 'ComponentsText',
              props: {
                children: PODS_INPUTS.QUESTION_7.WORDING,
                size: {
                  base: 'medium',
                },
              },
            },
            textFieldSpacer,
            {
              component: 'InputField',
              props: {
                type: 'textarea',
                name: PODS_INPUTS.QUESTION_7.ID,
                placeholder: '',
                showWordCount: true,
              },
              validation: {
                required: true,
                wordCount: {
                  minimum: 1,
                  maximum: 100,
                },
              },
            },
          ],
        },
      },
    ],
  },
  {
    id: PodsFinalSurveySectionIds.FINAL_SURVEY_4,
    elements: [
      {
        component: 'Box',
        background: 'background-white',
        props: {
          containerClassName: styles['box'],
          children: [
            {
              component: 'CenteredIllustration',
              props: {
                illustration: FinalSurveyIllustration,
              },
            },
            headingSpacer,
            {
              component: 'ComponentsHeading',
              props: {
                children: finalSurveyTitle,
                ...headingProps,
              },
            },
            contentSpacer,
            {
              component: 'ComponentsText',
              props: {
                children: PODS_INPUTS.QUESTION_8.WORDING,
                size: {
                  base: 'medium',
                },
              },
            },
            textFieldSpacer,
            {
              component: 'InputField',
              props: {
                type: 'textarea',
                name: PODS_INPUTS.QUESTION_8.ID,
                placeholder: '',
                showWordCount: true,
              },
              validation: {
                required: true,
                wordCount: {
                  minimum: 1,
                  maximum: 100,
                },
              },
            },
            textFieldSpacer,
            {
              component: 'NumberSelector',
              props: {
                label: PODS_INPUTS.QUESTION_9.WORDING,
                name: PODS_INPUTS.QUESTION_9.ID,
                startFrom: 1,
                numberCount: 5,
                className: styles['numberSelector'],
              },
              validation: {
                required: true,
              },
            },
            textFieldSpacer,
            {
              component: 'ComponentsText',
              props: {
                children: PODS_INPUTS.QUESTION_10.WORDING,
                size: {
                  base: 'medium',
                },
              },
            },
            textFieldSpacer,
            {
              component: 'ComponentsRadioButton',
              tag: 'profile',
              props: {
                name: PODS_INPUTS.QUESTION_10.ID,
                label: 'Yes',
                value: true,
              },
              validation: {
                required: true,
              },
            },
            {
              component: 'ComponentsRadioButton',
              tag: 'profile',
              props: {
                name: PODS_INPUTS.QUESTION_10.ID,
                label: 'No',
                value: false,
              },
            },
          ],
        },
      },
    ],
  },
]);
