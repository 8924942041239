import type { ReactElement } from 'react';

import { Icon } from 'src/components/Icon';

interface ScholarBadgeProps {
  polygenceGraduationYear: number;
}

export const ScholarBadge = ({ polygenceGraduationYear }: ScholarBadgeProps): ReactElement => {
  return (
    <div className="badge black-text green-outline d-flex flex-row">
      <Icon name="scholar" local color="black" size="22px" />
      <strong>Polygence Scholar</strong>
      <span>{polygenceGraduationYear}</span>
    </div>
  );
};
