import { useEffect } from 'react';

const DisableNumberInputScroll = () => {
  const handleScrollEvent = () => {
    if (document.activeElement.type === 'number') {
      document.activeElement.blur();
    }
  };
  useEffect(() => {
    document.addEventListener('wheel', handleScrollEvent);
    return () => {
      document.removeEventListener('wheel', handleScrollEvent);
    };
  }, []);
  return null;
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default DisableNumberInputScroll;
