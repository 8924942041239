import { Button, Icon, Tooltip } from '@polygence/components';
import { toast } from 'react-toastify';

export const ProjectIdeaCopyToClipboardCTA = ({ idea }: { idea: string }) => {
  const copyPasteIdea = () => {
    navigator.clipboard.writeText(idea).catch(console.error);
    toast.success('Idea copied to clipboard.');
  };

  return (
    <Tooltip tip="Copy this idea to your clipboard." placement="top">
      <Button
        variant="link-primary"
        endIcon={<Icon id="copy" />}
        className="-tw-mb-2 -tw-ms-6 tw-self-start"
        onClick={copyPasteIdea}
      >
        Pick this idea!
      </Button>
    </Tooltip>
  );
};
