import camelcase from 'camelcase-keys';
import WebSocket from 'reconnecting-websocket';
import snakecase from 'snakecase-keys';

import { getCommonStore } from '../commonSettings';
import { commonReducers } from '../store/reducers';
import { commonThunks } from '../store/thunks';
import { getWebsocketClientUrl } from '../utils/baseUrls';

let rws = null;

const callbacks = {
  chat: {
    message: {
      success: ({
        room_id: roomId,
        local_message_id: localMessageId,
        workspace_id: workspaceId,
        message,
        shared_resources: sharedResources = [],
      }) => {
        getCommonStore().dispatch(
          commonReducers.hermesActions.messageSentSuccess({
            roomId,
            workspaceId,
            localMessageId,
            message: camelcase(message, { deep: true }),
            sharedResources: camelcase(sharedResources, { deep: true }),
          }),
        );
      },
    },
    messages_seen: {
      success: ({ room_id: roomId, user_id: userId, workspace_id: workspaceId }) => {
        const currentUserId = getCommonStore().getState().user.id;
        getCommonStore().dispatch(
          commonReducers.hermesActions.messagesSeen({ roomId, userId, currentUserId, workspaceId }),
        );
      },
    },
  },
  project: {
    updated: {
      success: ({ id }) => {
        getCommonStore().dispatch(commonThunks.hermesThunks.getProject({ id }));
      },
    },
  },
  session: {
    updated: {
      success: ({ project_id: projectId }) => {
        getCommonStore().dispatch(commonThunks.hermesThunks.getSessionsForProject({ projectId }));
      },
    },
  },
  meeting: {
    updated: {
      success: ({ workspace_id: workspaceId }) => {
        getCommonStore().dispatch(
          commonThunks.hermesThunks.getMeetingsForWorkspace({ workspaceId }),
        );
      },
    },
  },
  todo: {
    completed: {
      success: ({ todo_id: todoId }) => {
        getCommonStore().dispatch(commonReducers.hermesActions.todoItemCompleted({ id: todoId }));
      },
    },
  },
  aiMessageThread: {
    updated: {
      /**
       *
       * @param {Object} params
       * @param {import('../types/common').RoomId} params.roomId
       * @param {import('../types/common').HermesAIMessageThreadId} params.aiMessageThreadId
       * @param {string} params.title
       */
      success: ({ roomId, title, aiMessageThreadId }) => {
        getCommonStore().dispatch(
          commonReducers.hermesActions.updateAIMessageThread({
            roomId,
            aiMessageThreadId,
            title,
          }),
        );
      },
    },
  },
};

const getCallback = (command) => {
  const commandParts = command.split('.');
  let callback = callbacks;

  commandParts.forEach((part) => {
    if (callback[part]) {
      // eslint-disable-next-line fp/no-mutation
      callback = callback[part];
    }
  });

  if (typeof callback === 'function') {
    return callback;
  }

  throw new Error('Unknown websocket message command');
};

export const getConnection = () => {
  if (!rws || [WebSocket.CLOSING, WebSocket.CLOSED].includes(rws.readyState)) {
    const token = getCommonStore().getState().user.token;
    getCommonStore().dispatch(commonReducers.hermesActions.connecting());

    // eslint-disable-next-line fp/no-mutation
    rws = new WebSocket(getWebsocketClientUrl(token));

    rws.addEventListener('open', () => {
      getCommonStore().dispatch(commonReducers.hermesActions.connected());
    });

    rws.addEventListener('message', ({ data }) => {
      const parsedData = JSON.parse(data);
      const { command, type, ...payload } = parsedData;
      const callback = getCallback(command);
      callback(payload);
    });

    rws.addEventListener('error', (error) => {
      if (rws.retryCount >= rws._options.maxRetries) {
        getCommonStore().dispatch(commonReducers.hermesActions.connectionDied());
        rws.close();
      }
    });
  }
  return rws;
};

export const closeConnection = () => {
  if (rws) {
    getCommonStore().dispatch(commonReducers.hermesActions.closed());
    rws.close();
  }
};

function sendOnSocket(message) {
  const connection = getConnection();
  const messageString = JSON.stringify(snakecase(message, { deep: true }));
  connection.send(messageString);
}

export function sendMessage({ roomId, message, localMessageId, messageType, extra }) {
  sendOnSocket({
    roomId,
    message,
    localMessageId,
    extra,
    messageType,
    type: 'message',
  });
}

export function sendMessagesSeen({ roomId }) {
  sendOnSocket({ roomId, type: 'messages_seen' });
}
