import * as marketplaceApi from '@polygence/common/api/marketplace';
import React from 'react';

import createInputTracker from 'src/components/aux/inputTracker';
import ProjectItem from 'src/components/mentor/ProjectItem';

const trackInput = createInputTracker('mentor_profile', 'profile');

/* eslint-disable react/prop-types */
// eslint-disable-next-line import/no-default-export -- autodisabled
export default class ProjectsSection extends React.Component {
  constructor(props) {
    const { profile: { projects_v2: projectsV2 } = {} } = props;
    super(props);
    this.state = {
      projects_v2: projectsV2,
    };
  }

  componentDidMount() {
    const { projects_v2: projectsV2 } = this.state;
    if (projectsV2.length === 0) {
      this.handleAddProject();
    }
  }

  handleProjectChange = (index, event) => {
    const { projects_v2: projectsV2 } = this.state;
    const { removeError, profileCompletionErrorOff, submitForm } = this.props;
    const { value, name } = event.target;

    if (name === 'description') {
      removeError('projects_v2');
      profileCompletionErrorOff();
    }
    const values = [...projectsV2];
    // eslint-disable-next-line fp/no-mutation
    values[index][name] = value;
    if (name === 'dont_feature_project') {
      // eslint-disable-next-line fp/no-mutation
      values[index].dont_feature_project = event.target.checked;
    }

    const payload = this.removeCoverImageField(values);

    this.setState({ projects_v2: values }, () => {
      submitForm(null, { projects_v2: payload }, true);
      trackInput('project');
    });
  };

  handleAddProject = () => {
    const { projects_v2: projectsV2 } = this.state;
    const payload = this.removeCoverImageField(projectsV2);
    marketplaceApi
      .updateHomeProfile({
        projects_v2: [...payload, {}],
        partial: true,
      })
      .then(({ data: result }) => {
        if (result) {
          this.setState({ projects_v2: result.updated_fields.projects_v2 });
        }
      });
  };

  handleRemoveFields = (index) => {
    const { projects_v2: projectsV2 } = this.state;
    const { submitForm } = this.props;
    const values = [...projectsV2];
    values.splice(index, 1);
    const payload = this.removeCoverImageField(values);
    this.setState({ projects_v2: values }, () => {
      submitForm(null, { projects_v2: payload }, true);
    });
  };

  removeCoverImageField = (projects) => {
    return projects.map((project) => {
      const updatedProject = { ...project };
      delete updatedProject.cover_image;
      return updatedProject;
    });
  };

  render() {
    const { projects_v2: projectsV2 } = this.state;
    const { missingFields } = this.props;

    return (
      // eslint-disable-next-line react/jsx-filename-extension
      <>
        <div>
          <span>
            Please write one project idea as an example that can give prospective students a sense
            of appropriate scope of a project. You are not obligated to work on this project with
            any of your future students. However, most students are inexperienced at formulating
            research ideas — your project idea can serve as inspiration!
          </span>
        </div>
        {projectsV2.map((inputField, index) => {
          return (
            <ProjectItem
              key={inputField.pk}
              inputField={inputField}
              index={index}
              missingFields={missingFields}
              handleChange={this.handleProjectChange}
              removeFields={this.handleRemoveFields}
            />
          );
        })}
        <div className="my-5">
          <button
            type="button"
            className="btn btn-success"
            onClick={() => {
              return this.handleAddProject();
            }}
          >
            Add project #{projectsV2.length + 1}
          </button>
        </div>
      </>
    );
  }
}
