import type { StudentProject } from '@polygence/common/types/data/marketplace';
import type { DetailedShowcasingOutcome } from '@polygence/common/types/showcasing';
import { ShowCasingOutcomeStatusOptions } from '@polygence/common/types/showcasing';
import { Button, Heading, Modal, ModalBody, ModalFooter, ModalTitle } from '@polygence/components';
import { useEffect, useState } from 'react';
import type { CamelCasedPropertiesDeep } from 'type-fest/source/camel-cased-properties-deep';

import { FormURLInput } from 'src/components/FormURLInput';
import DateWrapper from 'src/components/aux/dateWrapper';
import { DatePickerWithLabel } from 'src/components/common/DatePickerWithLabel';
import ShowcasingOpportunitiesSelector from 'src/components/overviews/student/editable/ShowcasingOpportunitiesSelector';
import ShowcasingProjectsSelector from 'src/components/overviews/student/editable/ShowcasingProjectsSelector';
import { ShowcasingStatusSelector } from 'src/components/overviews/student/editable/ShowcasingStatusSelector';

export type ShowcasingOutcome = Pick<
  DetailedShowcasingOutcome,
  'outlet' | 'project' | 'publicationDate' | 'url' | 'mentors' | 'students' | 'status'
>;

interface ShowcasingOutcomeProps {
  projects: CamelCasedPropertiesDeep<StudentProject>[];
  isOpened: boolean;
  submitLabel: 'Create' | 'Update';
  onSubmit: (_: ShowcasingOutcome) => Promise<void>;
  onClose: () => void;
  defaultOutcome: Partial<DetailedShowcasingOutcome>;
}

export const DEFAULT_MIN_DATE = new DateWrapper().date.year(1900).toDate();
export const DEFAULT_MAX_DATE = new DateWrapper().date.add(100, 'year').toDate();
const DEFAULT_MODAL_STATE = {
  outlet: null,
  project: null,
  publicationDate: null,
  url: '',
  students: [],
  mentors: [],
  status: ShowCasingOutcomeStatusOptions.PLANS_TO_SUBMIT,
};

export const ShowcasingOutcomeModal = ({
  submitLabel,
  onSubmit,
  onClose,
  projects = [],
  isOpened = false,
  defaultOutcome = {},
}: ShowcasingOutcomeProps) => {
  const [loading, setLoading] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const [modalOpened, setModalOpened] = useState(isOpened);
  const [outcome, setOutcome] = useState<ShowcasingOutcome>({
    ...DEFAULT_MODAL_STATE,
    ...defaultOutcome,
  });

  const isDisabled =
    loading ||
    !outcome.outlet ||
    !outcome.project ||
    !outcome.status ||
    (outcome.status === 'published' && outcome.publicationDate === null);

  const closeModal = () => {
    setModalOpened(false);
    onClose();
    setOutcome({
      ...DEFAULT_MODAL_STATE,
      ...defaultOutcome,
    });
  };

  const handleSubmit = () => {
    if (validationError) {
      return;
    }
    setLoading(true);
    onSubmit(outcome)
      .catch(console.error)
      .finally(() => {
        setLoading(false);
        closeModal();
      });
  };

  useEffect(() => {
    setModalOpened(isOpened);
  }, [isOpened]);

  return (
    <Modal
      centered
      closeButton
      contentClassName="p-7 p-md-10"
      onHide={closeModal}
      show={modalOpened}
    >
      <ModalTitle>
        <Heading size="h2" as="h2">
          Showcasing outcome
        </Heading>
      </ModalTitle>
      <ModalBody>
        <ShowcasingStatusSelector
          label="Status"
          defaultOption={outcome.status}
          onSelect={(status) => {
            setOutcome((prevState) => {
              return { ...prevState, status };
            });
          }}
        />
        {outcome.status === 'published' && (
          <DatePickerWithLabel
            id="showcasing_outcome_publication_date"
            label="Publication date"
            name="showcasing_outcome_publication_date"
            value={outcome.publicationDate}
            minDate={DEFAULT_MIN_DATE}
            maxDate={DEFAULT_MAX_DATE}
            onChange={({ target }) => {
              setOutcome((prevState) => {
                return { ...prevState, publicationDate: (target as { value: string }).value };
              });
            }}
            showMonthDropdown
          />
        )}
        <ShowcasingOpportunitiesSelector
          defaultOption={outcome.outlet}
          onSelect={(outlet) => {
            setOutcome((prevState) => {
              return { ...prevState, outlet };
            });
          }}
        />
        <ShowcasingProjectsSelector
          projects={projects}
          defaultOption={outcome.project}
          onSelect={(project) => {
            setOutcome((prevState) => {
              return { ...prevState, project };
            });
          }}
        />
        <FormURLInput
          label="URL"
          name="showcasing_outcome_url"
          value={outcome.url}
          onChange={({ target }) => {
            setOutcome((prevState) => {
              return { ...prevState, url: target.value };
            });
          }}
          updateValidationError={(isError) => {
            setValidationError(isError);
          }}
        />
      </ModalBody>
      <ModalFooter className="justify-content-center">
        <Button
          type="button"
          variant="primary"
          className="mt-5"
          onClick={handleSubmit}
          disabled={isDisabled}
        >
          {submitLabel}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
