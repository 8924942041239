import { Heading } from '@polygence/components';
import classnames from 'classnames';
import Linkify from 'react-linkify';

import styles from 'src/components/mentor/MentorOverview/MentorOverview.module.sass';

/* eslint-disable-next-line react/function-component-definition, import/no-default-export -- autodisabled */
export default function MentorOverviewSection({
  children,
  title,
}: {
  children: React.ReactElement;
  title: string;
}) {
  return (
    <section className={classnames('mb-7 mx-5 mx-md-0', styles['mb-md-10'])}>
      <Heading
        size="h4"
        as="h3"
        alignment="left"
        className={classnames('mb-7', styles['sectionSeparator'])}
      >
        {title}
      </Heading>
      <div className={classnames(styles['fs-0_9'])}>
        <Linkify>{children}</Linkify>
      </div>
    </section>
  );
}
