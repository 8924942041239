import { Icon, Text, cn } from '@polygence/components';

interface PaymentIntentSecuredByStripeProps {
  v3Enabled?: boolean;
  className?: string;
}

export const PaymentIntentSecuredByStripe = ({
  v3Enabled,
  className,
}: PaymentIntentSecuredByStripeProps) => {
  return (
    <Text
      size="small"
      fontWeight="bold"
      className={cn(
        'tw-inline-flex tw-items-center tw-justify-center tw-gap-1 tw-text-gray-400',
        className,
      )}
    >
      {v3Enabled ? <Icon id="shield" size="lg" /> : <Icon id="lock" size="sm" />}
      <span>Secure payment by Stripe</span>
    </Text>
  );
};
