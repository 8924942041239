import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import LegacyMarkdownGuidebook from 'src/components/EditableContent/LegacyMarkdownGuidebook';
import { PrivateRoute } from 'src/components/authenticatedRoutes';

// eslint-disable-next-line react/prefer-stateless-function -- autodisabled
class ResourceDocument extends React.Component {
  render() {
    return (
      // eslint-disable-next-line react/jsx-filename-extension -- autodisabled
      <PrivateRoute
        component={LegacyMarkdownGuidebook}
        // eslint-disable-next-line react/destructuring-assignment -- autodisabled
        logged_in={this.props.logged_in}
        // eslint-disable-next-line react/destructuring-assignment -- autodisabled
        guidebook_tag={this.props.match.params.mid}
      />
    );
  }
}

/* eslint-disable-next-line react/prefer-stateless-function, react/no-multi-comp -- autodisabled, autodisabled */
class Resources extends React.Component {
  render() {
    return (
      <div>
        <Switch>
          <Route
            // eslint-disable-next-line react/destructuring-assignment -- autodisabled
            path={`${this.props.match.url}/parent-guidebook`}
            render={(props) => (
              <LegacyMarkdownGuidebook
                {...props}
                // eslint-disable-next-line react/destructuring-assignment -- autodisabled
                logged_in={this.props.logged_in}
                guidebook_tag="parent-guidebook"
              />
            )}
          />
          <PrivateRoute
            // eslint-disable-next-line react/destructuring-assignment -- autodisabled
            path={`${this.props.match.url}/student-guidebook`}
            component={LegacyMarkdownGuidebook}
            // eslint-disable-next-line react/destructuring-assignment -- autodisabled
            logged_in={this.props.logged_in}
            guidebook_tag="student"
          />
          <Route
            // eslint-disable-next-line react/destructuring-assignment -- autodisabled
            path={`${this.props.match.url}/:mid`}
            render={({ match }, props) => (
              // eslint-disable-next-line react/destructuring-assignment -- autodisabled
              <ResourceDocument match={match} {...props} logged_in={this.props.logged_in} />
            )}
          />
        </Switch>
      </div>
    );
  }
}

// eslint-disable-next-line import/no-default-export -- autodisabled
export default withRouter(Resources);
