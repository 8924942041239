import { Text, cn } from '@polygence/components';
import { useEffect, useState } from 'react';
import { ReactTyped } from 'react-typed';

import styles from 'src/components/projectIdeaGenerator/ProjectIdeaSuggestions.module.scss';
import { ProjectIdeaSuggestionsResult } from 'src/components/projectIdeaGenerator/ProjectIdeaSuggestionsResult';
import PolyGptProjectIdeaGenericError from 'src/components/projectIdeaGenerator/poly-gpt-project-idea-generic-error.svg';
import PolyGptProjectIdeaImage from 'src/components/projectIdeaGenerator/poly-gpt-project-idea.svg';

type GptProjectIdeaError = 'generic';

const PROJECT_IDEA_GENERATOR_LOADING_TEXTS = [
  '(Insert elevator music here)',
  'Remembering where I put that answer..',
  'Asking a fellow AI..',
  'Robots need a moment too..',
  'Even robots need to brainstorm..',
  'Ensuring no robot revolts today..',
  'Sketching out an award-winning project..',
  'Brewing some digital coffee..',
  'Stuck in a metaphorical traffic jam..',
  'Counting all the binary sheep..',
  'Finding the internet’s good side..',
  'Dusting off my circuits..',
  'Channeling my inner Shakespeare..',
  'Untangling virtual spaghetti..',
  'Rebooting my sense of humor..',
  'Did you bring snacks?',
  'Polishing my pixels..',
  'Trying to outsmart my autocorrect..',
  'Consulting the data crystal ball..',
  'Feeding the hamsters powering the server..',
];

interface ProjectIdeaSuggestionsProps {
  loading: boolean;
  ideas: string[];
  error: GptProjectIdeaError | null;
}

const getRandomLoadingText = () => {
  return PROJECT_IDEA_GENERATOR_LOADING_TEXTS[
    Math.floor(Math.random() * PROJECT_IDEA_GENERATOR_LOADING_TEXTS.length)
  ];
};

export const ProjectIdeaSuggestions = ({ loading, ideas, error }: ProjectIdeaSuggestionsProps) => {
  const [loadingText, setLoadingText] = useState(getRandomLoadingText());

  useEffect(() => {
    if (loading) {
      const interval = setInterval(() => setLoadingText(getRandomLoadingText()), 5000);

      return () => {
        clearInterval(interval);
      };
    }
    return () => {};
  }, [loading]);

  return (
    <div
      className={cn(
        'tw-relative tw-flex tw-items-center tw-justify-center',
        styles['projectIdeaSuggestions'],
      )}
    >
      {loading ? (
        <>
          <div className={styles['loadingBubbleImageWrapper']}>
            <img
              src="https://dpl6hyzg28thp.cloudfront.net/media/poly-gpt.jpeg"
              alt="Illustration"
            />
          </div>
          <Text
            size="large"
            fontWeight="bold"
            alignment="center"
            className={styles['projectIdeaSuggestionsGptText']}
          >
            <ReactTyped strings={[loadingText ?? '']} typeSpeed={60} />
          </Text>
        </>
      ) : (
        <>
          {error ? (
            <img
              src={PolyGptProjectIdeaGenericError}
              alt="Something went wrong"
              aria-label="Something went wrong"
              className="sm:tw-size-[400px tw-size-[400px]"
            />
          ) : (
            <>
              {!ideas.length && (
                <img
                  src={PolyGptProjectIdeaImage}
                  alt="Poly GPT Project Idea Placeholder"
                  aria-label="Poly GPT Project Idea Placeholder"
                  className="tw-size-[300px] sm:tw-size-[400px]"
                />
              )}
              {!!ideas.length && <ProjectIdeaSuggestionsResult ideas={ideas} />}
            </>
          )}
        </>
      )}
    </div>
  );
};
