export const STUDENT_PROFILE_ABOUT_DEFAULT = `
  Hello! My name is _____and my Polygence project is on ______.
  I chose to work on this project because __________.
  After my project is complete, I would like to_________.
`;

export const TIME_ZONE_INSTRUCTIONS = `
  We use the time zone you provide here to send you notifications and session reminders.
  Please make sure this information is always up-to-date.
`;

export const LOCATION_INSTRUCTIONS = `
  We use location data to understand how well we are serving students around the world.
`;

export const GRADUATION_INSTRUCTIONS = `
  We use graduation year to help us understand when you are likely to be applying to
  colleges to ensure your project can be finished on time.
`;

export const DEFAULT_TOP_COLLEGES_QUESTION =
  'Where did you apply to college? Please select all schools and indicate if you applied there Early Decision or Early Action';
