import type { Nullable, ProjectId } from '@polygence/common';
import * as surveyApi from '@polygence/common/api/survey';
import { Alert, Heading, Spacer } from '@polygence/components';
import { useEffect, useState } from 'react';

import { SpinningWheel } from 'src/components/SpinningWheel';
import { ShowcasingMatchingForm } from 'src/components/hermes/premium-showcasing-support/ShowcasingMatchingForm';

interface ShowcasingMatchingSurveyProps {
  projectId: Nullable<ProjectId>;
}

export const ShowcasingMatchingSurvey = ({ projectId }: ShowcasingMatchingSurveyProps) => {
  const [status, setStatus] = useState<'loading' | 'pending' | 'submitted'>('loading');

  useEffect(() => {
    if (!projectId) {
      return;
    }

    surveyApi
      .getPremiumShowcasingMatchingSurvey(projectId)
      .then(() => {
        setStatus('submitted');
      })
      .catch(() => {
        setStatus('pending');
      });
  }, [projectId]);

  return (
    <>
      <Heading as="h1" size="h3" alignment="left">
        Showcasing Support Matching Form
      </Heading>
      <Spacer size={4} />
      {status === 'loading' && <SpinningWheel />}
      {status === 'pending' && <ShowcasingMatchingForm projectId={projectId} />}
      {status === 'submitted' && (
        <Alert variant="success" className="text-center my-3">
          Request already submitted
        </Alert>
      )}
    </>
  );
};
