import { isProduction } from '@polygence/common/utils/environment';
import { useEffect } from 'react';

const GOOGLE_TAG_MANAGER_ID = 'G-74H4VH59ZG';

export const useGoogleTagManager = () => {
  useEffect(() => {
    if (!isProduction()) {
      console.warn("Google Tag Manager isn't loaded in non-production environment.");
      return;
    }

    const script = document.createElement('script');
    script.setAttribute('type', 'text/javascript');
    script.toggleAttribute('async', true);
    script.setAttribute(
      'src',
      `https://www.googletagmanager.com/gtag/js?id=${GOOGLE_TAG_MANAGER_ID}`,
    );
    document.head.appendChild(script);

    const initScript = document.createElement('script');
    initScript.append('window.dataLayer = window.dataLayer || [];');
    initScript.append('function gtag(){dataLayer.push(arguments);}');
    initScript.append(`gtag('js', new Date());`);
    initScript.append(`gtag('config', '${GOOGLE_TAG_MANAGER_ID}');`);
    document.head.appendChild(initScript);

    return () => {
      document.head.removeChild(initScript);
    };
  }, []);
};
