import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { SpinningWheel } from 'src/components/SpinningWheel';
import { ReactMarkdown } from 'src/components/aux/markdown/ReactMarkdown';
import { LinkRenderer } from 'src/components/aux/markdown/markdownHelperFunctions';
import { Box } from 'src/components/common/Box';
import { ThreeColumnLayout } from 'src/components/common/ThreeColumnLayout';
import TwoColumnLayout from 'src/components/common/TwoColumnLayout';
import { components } from 'src/components/mentor/FormElements';
import evaluateFormElementsOperations from 'src/utils/evaluateFormElementsOperations';

const Help = ({ help, profile }) => {
  return (
    <div className="px-2 px-lg-5">
      {typeof help === 'string' && (
        <ReactMarkdown className="profile-side-information" components={{ a: LinkRenderer }}>
          {help}
        </ReactMarkdown>
      )}
      {Array.isArray(help) &&
        help.map(({ text, display }, idx) => {
          return (
            evaluateFormElementsOperations(display, profile) && (
              /* eslint-disable react/no-array-index-key */
              <ReactMarkdown
                key={idx}
                className="profile-side-information"
                components={{ a: LinkRenderer }}
              >
                {text}
              </ReactMarkdown>
              /* eslint-enable react/no-array-index-key */
            )
          );
        })}
    </div>
  );
};

Help.propTypes = {
  help: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  profile: PropTypes.objectOf(PropTypes.any),
};

function replaceVariables(content, contentVariables, profile) {
  let newContent = content;
  // eslint-disable-next-line no-restricted-syntax
  for (const variable of contentVariables) {
    // eslint-disable-next-line fp/no-mutation
    newContent = newContent.replaceAll(`{{${variable}}}`, profile[variable]);
  }
  return newContent;
}

const DisplayFieldError = ({ messages }) => {
  return <div className="alert alert-danger">{messages.join(' ')}</div>;
};

DisplayFieldError.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.any),
};

const layouts = {
  Box: {
    Component: Box,
    getDerivedProps: (props) => {
      return props;
    },
  },
  TwoColumnLayout: {
    Component: TwoColumnLayout,
    getDerivedProps: ({ ...rest }) => {
      return rest;
    },
  },
  ThreeColumnLayout: {
    Component: ThreeColumnLayout,
    getDerivedProps: ({ ...rest }) => {
      return rest;
    },
  },
};

export const FormSection = ({
  className = 'col-12',
  containerClassName = '',
  fields,
  saving = [],
  onChange,
  onBlur,
  profile,
  missingFields,
  fieldErrors = {},
  helpColumn = true,
  forceHelpSectionDisplay,
  readOnly = false,
  validationResult = {},
  dynamicComponentProps = {},
}) => {
  const secondColumnClassName = 'd-none d-sm-block col-sm-4 profile-help';
  return (
    <div className={classnames({ 'container-fluid': helpColumn }, containerClassName)}>
      {fields.map(
        (
          {
            component,
            labelVariables,
            props: { label: labelFromConfig } = {},
            props = {},
            tag,
            help,
            display,
            name,
            disabled,
          },
          idx,
        ) => {
          const {
            Component,
            getDerivedProps = () => {
              return null;
            },
          } = Object.keys(layouts).includes(component) ? layouts[component] : components[component];

          const label = labelVariables
            ? replaceVariables(labelFromConfig, labelVariables, profile)
            : labelFromConfig;
          const fieldSaving = saving.includes(name);
          const isDisabled = readOnly || evaluateFormElementsOperations(disabled, profile, false);

          return (
            evaluateFormElementsOperations(display, profile) && (
              // eslint-disable-next-line react/no-array-index-key
              <div className={classnames({ row: helpColumn })} key={idx}>
                <div className={classnames({ 'col-sm-8': helpColumn }, className)}>
                  <Component
                    disabled={fieldSaving || isDisabled}
                    {...props}
                    {...getDerivedProps({
                      profile,
                      props,
                      missingFields,
                      onChange: (...args) => onChange(...args, tag),
                      onBlur,
                      validationResult,
                      dynamicComponentProps,
                    })}
                    {...dynamicComponentProps[component]}
                    label={label}
                  />
                  {fieldSaving && <SpinningWheel />}
                  {fieldErrors[name] && <DisplayFieldError messages={fieldErrors[name]} />}
                </div>
                {helpColumn && (help || forceHelpSectionDisplay) && (
                  <div className={secondColumnClassName}>
                    <Help help={help} profile={profile} />
                  </div>
                )}
              </div>
            )
          );
        },
      )}
    </div>
  );
};

FormSection.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      component: PropTypes.string.isRequired,
      props: PropTypes.objectOf(PropTypes.any),
      help: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(
          PropTypes.shape({
            text: PropTypes.string,
            display: PropTypes.objectOf(PropTypes.any),
          }),
        ),
      ]),
      display: PropTypes.objectOf(PropTypes.any),
    }),
  ),
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  dynamicComponentProps: PropTypes.objectOf(PropTypes.any),
  saving: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  profile: PropTypes.objectOf(PropTypes.any),
  missingFields: PropTypes.arrayOf(PropTypes.any),
  fieldErrors: PropTypes.objectOf(PropTypes.any),
  validationResult: PropTypes.objectOf(PropTypes.any),
  helpColumn: PropTypes.bool,
  forceHelpSectionDisplay: PropTypes.bool,
  readOnly: PropTypes.bool,
};
