import * as marketplaceApi from '@polygence/common/api/marketplace';
import type { Tag, TagCreatePayload } from '@polygence/common/types/marketplace';
import { useEffect, useState } from 'react';

import {
  BaseTagInput,
  BaseTagInputProps,
} from 'src/components/admin/components/reusable/BaseTagInput';

const createTag = (tag: TagCreatePayload): Promise<Tag> => {
  return marketplaceApi.createTag(tag).then(({ data }) => data);
};

interface TagInputChangeEvent {
  target: {
    name: string;
    value: Tag[];
  };
}

interface TagInputProps extends Omit<TagInputWithIdProps, 'value' | 'onChange'> {
  value: Tag[];
  onChange: (e: TagInputChangeEvent) => void;
}

export const TagInput = ({ value, onChange, ...props }: TagInputProps) => {
  return (
    <TagInputWithId
      value={value.map(({ id }) => id)}
      onChange={({ target: { name, fullValue: value } }) => onChange({ target: { name, value } })}
      {...props}
    />
  );
};

type TagInputWithIdProps = Omit<BaseTagInputProps, 'tags' | 'setTags' | 'createTag'>;

export const TagInputWithId = ({ category, ...props }: TagInputWithIdProps) => {
  const [tags, setTags] = useState<Tag[]>([]);

  useEffect(() => {
    marketplaceApi
      .getTags(category)
      .then(({ data }) => setTags(data))
      .catch(console.error);
  }, [category]);

  return (
    <BaseTagInput
      category={category}
      tags={tags}
      setTags={(newTags) => setTags(newTags as Tag[])}
      createTag={createTag}
      {...props}
    />
  );
};
