import { Text } from '@polygence/components';

import { PaymentIntentAmountOrPlaceholder } from 'src/payment/PaymentIntent/PaymentIntentAmountOrPlaceholder';

interface PaymentIntentDiscountProps {
  name: string;
  amount: number;
}

export const PaymentIntentDiscount = ({ name, amount }: PaymentIntentDiscountProps) => {
  return (
    <div className="tw-flex">
      <Text
        size="medium"
        alignment="left"
        fontWeight="bold"
        className="tw-inline tw-text-sm tw-text-inherit"
      >
        {name}
      </Text>
      <div className="tw-ms-auto tw-flex">
        <span className="tw-text-sm">-</span>
        <PaymentIntentAmountOrPlaceholder amount={amount} className="tw-text-sm tw-text-inherit" />
      </div>
    </div>
  );
};
