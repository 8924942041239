import { createSlice } from '@reduxjs/toolkit';

const defaults = {
  modal: {
    isOpen: false,
  },
};

const projectExtensionSlice = createSlice({
  name: 'projectExtension',
  initialState: {
    ...defaults,
  },
  reducers: {
    openProjectExtensionModal: (state) => {
      state.modal.isOpen = true;
    },
    closeProjectExtensionModal: (state) => {
      state.modal.isOpen = false;
    },
  },
});

const { actions, reducer } = projectExtensionSlice;

export const projectExtensionActions = actions;
export const projectExtensionReducer = reducer;
