export const HELPER_TEXTS = {
  RADIO_BUTTON: 'Choose one that applies',
  CHECKBOX: 'Choose as many as you like',
};

export const REGEX_VALIDATORS = {
  URL: /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/gi,
  SLUG: /^[a-zA-Z0-9-]+$/,
};

export const PROFILE_MAIN = 'profile-main';

export const GPT_PROJECT_IDEA_UUID_LOCAL_STORAGE_KEY = 'gpt-project-idea-uuid';

export const WIRE_TRANSFER_FEATURE = 'stripe-wire-transfer-enabled';
